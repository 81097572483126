import {
  CompanyStructureReducedDto,
  CustomerContactDto,
  CustomerDto,
  DealerDto,
  KemaroDashboardEconomyCostCalculationReducedDto,
  KemaroRobotFilterCriteriaDeploymentStatus,
  KemaroRobotFilterCriteriaErrorStatus,
  KemaroRobotFilterCriteriaStatus,
  RobotModelReducedDto,
} from "../../../../../../robotcloud-shared/resource-models";

export interface kemaroRobotsFilter {
  ids: string[];
  serialNumbers: string[];
  names: string[];
  companyStructures: CompanyStructureReducedDto[];
  robotModels: RobotModelReducedDto[];
  dealers: DealerDto[];
  customers: CustomerDto[];
  customerContacts: CustomerContactDto[];
  kemaroDashboardEconomyCostCalculations: KemaroDashboardEconomyCostCalculationReducedDto[];
  textToSearch: string | null;
  status: KemaroRobotFilterCriteriaStatus;
  errorStatus: KemaroRobotFilterCriteriaErrorStatus;
  deploymentStatus: KemaroRobotFilterCriteriaDeploymentStatus;
  tags: string[];
}

export const kemaroRobotsFilterDefault: kemaroRobotsFilter = {
  ids: [],
  serialNumbers: [],
  names: [],
  companyStructures: [],
  robotModels: [],
  dealers: [],
  customers: [],
  customerContacts: [],
  kemaroDashboardEconomyCostCalculations: [],
  textToSearch: "",
  status: "All",
  errorStatus: "All",
  deploymentStatus: "All",
  tags: [],
};
