import { KemaroDashboardEconomyCostCalculationListDto } from "../../../../../../robotcloud-shared/resource-models";
import { TableGenericColumn } from "../../../../components/news/TableGeneric/models";
import StarRateIcon from "@mui/icons-material/StarRate";
import { Theme } from "@mui/material/styles";
import i18n from "i18next";

export function KemaroCostCalculationColumnDefinition(
  theme: Theme
): TableGenericColumn[] {
  return [
    {
      title: " ",
      canSort: false,
      itemClassName: "text-center start-td ps-2 pe-0",
      cellTemplate: (item: KemaroDashboardEconomyCostCalculationListDto) => (
        <>
          {item.isDefault && (
            <StarRateIcon className="default-icon" color="primary" />
          )}
        </>
      ),
    },
    {
      title: i18n.t("common.name")!,
      property: "name",
      cellTemplate: (item: KemaroDashboardEconomyCostCalculationListDto) => (
        <>
          <span>{item.name}</span>
          {item.isDefault && (
            <span
              className="ps-2 default-text"
              style={{
                color:
                  theme.palette.swarm?.kemaroCostCalculation
                    ?.bodyListItemsDefaultText,
              }}
            >
              <>{i18n.t("costCalculation.default")}</>
            </span>
          )}
        </>
      ),
    },
    {
      title: i18n.t("common.currency")!,
      property: "currency",
      itemClassName: "text-center currency-td",
    },
    {
      title: i18n.t("customer.entitiesName")!,
      property: "customers",
      itemClassName: "text-end customers-td",
      canSort: false,
    },
    {
      title: i18n.t("robot.entitiesName")!,
      property: "robots",
      itemClassName: "text-end robots-td",
      canSort: false,
    },
  ];
}
