import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ToastHelper } from "../../../../../components/news";
import { Button, Dialog } from "@mui/material";
import { Loading } from "../../../../../components/Loading";
import KemaroRobotContext from "../../KemaroRobotContext";
import { KemaroRobotRequests } from "../../../../../data/kemaro/kemaroRobot.request";
import { ErrorHelper } from "../../../../../uiHelpers/errors.helper";
import { CredentialDisplayer } from "./CredentialDisplayer";
import { isPasswordValid } from "./funtions";
import { KemaroRobotCredentialsDto } from "../../../../../../../robotcloud-shared/resource-models";
import AuthorizationContext from "../../../../auth/authorizationContext";
import { PermissionsHelper } from "../../../../../uiHelpers/permissionsHelper";
import useTheme from "@mui/material/styles/useTheme";
import { useCredentialManagement } from "../../../../../hooks";

export const LocalCredentialModal = (props: LocalCredentialModalProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { me } = useContext(AuthorizationContext);

  const [isWorking, setIsWorking] = useState(true);
  const { currentItem } = useContext(KemaroRobotContext);
  const [canEditUser, setEditUser] = useState(false);
  const [canEditServiceAdministrator, setEditServiceAdministrator] =
    useState(false);
  const [isReadyForSave, setIsReadyForSave] = useState(false);
  const {
    credentialContainer: userCredential,
    setCredentialContainer: setUserCredential,
    hasChanges: userHasChanges,
  } = useCredentialManagement("User");

  const {
    credentialContainer: serviceAdministratorCredential,
    setCredentialContainer: setServiceAdministratorCredential,
    hasChanges: serviceHasChanges,
  } = useCredentialManagement("ServiceAdministrator");

  useEffect(() => {
    if (!currentItem) return;

    setIsWorking(true);
    if (!!userCredential || !!serviceAdministratorCredential) {
      setIsWorking(false);
    }
  }, [userCredential, serviceAdministratorCredential, currentItem]);

  useEffect(() => {
    setEditUser(
      PermissionsHelper.allow(
        "KemaroRobots",
        "Edit",
        "LocalCredential-User",
        "Edit"
      )
    );

    setEditServiceAdministrator(
      PermissionsHelper.allow(
        "KemaroRobots",
        "Edit",
        "LocalCredential-ServiceAdministrator",
        "Edit"
      )
    );
  }, [me]);

  useEffect(() => {
    setIsReadyForSave(
      (!userCredential ||
        isPasswordValid(
          userCredential.credential.password,
          userCredential.config
        )) &&
        (!serviceAdministratorCredential ||
          isPasswordValid(
            serviceAdministratorCredential.credential.password,
            serviceAdministratorCredential.config
          ))
    );
  }, [userCredential, serviceAdministratorCredential]);

  const handleLocalCredentialModalSave = async () => {
    if (!currentItem!.id) return;

    const credentials: KemaroRobotCredentialsDto[] = [];
    addCredential(credentials, userCredential?.credential);
    addCredential(credentials, serviceAdministratorCredential?.credential);

    if (credentials.length === 0) return;

    try {
      await KemaroRobotRequests.putLocalCredential(
        currentItem!.id,
        credentials
      );
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    props.onCancel();
  };

  return (
    <Dialog
      className="application-modal"
      open={props.openPopup}
      maxWidth="md"
      onClose={props.onCancel}
    >
      <div className="modal-content">
        <div className="application-modal-header">
          <h3>{t("robot.localCredential.title")}</h3>
        </div>

        <div className="application-modal-body kemaro-local-access-management-modal-body py-2 ">
          <div className="row">
            {isWorking && <Loading isLoading={isWorking} />}

            {!isWorking && (
              <>
                {userCredential && (
                  <CredentialDisplayer
                    roleName={t("robot.localCredential.roleUser")}
                    canEdit={canEditUser}
                    credentalContainer={userCredential}
                    setCredentalContainer={setUserCredential}
                  />
                )}

                {userCredential && serviceAdministratorCredential && (
                  <div
                    className="col-12 divider"
                    style={{
                      borderBottom:
                        theme.palette.swarm?.kemaroRobots
                          ?.accessManagementModalBodyDividerBorderBottom,
                    }}
                  ></div>
                )}

                {serviceAdministratorCredential && (
                  <CredentialDisplayer
                    roleName={t(
                      "robot.localCredential.roleServiceAdministrator"
                    )}
                    canEdit={canEditServiceAdministrator}
                    credentalContainer={serviceAdministratorCredential}
                    setCredentalContainer={setServiceAdministratorCredential}
                  />
                )}
              </>
            )}
          </div>
        </div>
        <div className="application-modal-footer">
          {isReadyForSave && !isWorking && (
            <>
              {(canEditUser || canEditServiceAdministrator) && (
                <Button
                  variant="contained"
                  className="button-with-text"
                  disabled={!isReadyForSave}
                  color="primary"
                  onClick={handleLocalCredentialModalSave}
                >
                  {t("common.buttons.save")}
                </Button>
              )}

              <Button
                className="modal-cancel-button button-with-text"
                variant="outlined"
                color="primary"
                onClick={props.onCancel}
              >
                {t("common.buttons.cancel")}
              </Button>
            </>
          )}

          {!userHasChanges && !serviceHasChanges && !isWorking && (
            <Button
              className="modal-cancel-button button-with-text"
              variant="outlined"
              color="primary"
              onClick={props.onCancel}
            >
              {t("common.buttons.ok")}
            </Button>
          )}
        </div>
      </div>
    </Dialog>
  );
};

interface LocalCredentialModalProps {
  openPopup: boolean;
  onCancel: () => void;
}

const addCredential = (
  credentialList: KemaroRobotCredentialsDto[],
  credential: KemaroRobotCredentialsDto | undefined
) => {
  if (credential) credentialList.push(credential);
};
