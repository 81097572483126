import { Stack } from "@mui/material";
import { YesNoOkDialog } from "../../components/news/YesNoOkDialog";
import { useEffect, useState } from "react";
import { TitleWithAction } from "../../components/news/TitleWithAction";
import { TableGeneric } from "../../components/news/TableGeneric/TableGeneric";
import { OrderDto } from "../../data/common";
import { TableGenericHelper } from "../../components/news";
import { ContactsModal } from "../shared/contacts/ContactsModal";
import { ToastHelper } from "../../components/news/Toast.helper";
import { ErrorHelper } from "../../uiHelpers/errors.helper";
import { ContactColumnDefinition } from "../shared/contacts/ContactColumnDefinition";
import {
  ManufacturerCompleteDto,
  ManufacturerContactReducedDto,
} from "../../../../robotcloud-shared/resource-models";
import { ManufacturersContactsRequests } from "../../data/manufacturersContacts.request";
import { PermissionsHelper } from "../../uiHelpers/permissionsHelper";
import { useTranslation } from "react-i18next";

export const ManufacturerViewContacts = (
  props: manufacturerViewContactsProps
) => {
  const { t } = useTranslation();

  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [contactModalTitle, setContactModalTitle] = useState("");
  const [contactModalContact, setContactModalContact] = useState<
    ManufacturerContactReducedDto | undefined
  >(undefined);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteModalItem, setDeleteModalItem] = useState<
    ManufacturerContactReducedDto | undefined
  >(undefined);
  const [dataOrder, setDataOrder] = useState<OrderDto>({
    orderColumns: "completeName",
    order: "Asc",
  });
  const [isWorking, setIsWorking] = useState(false);
  const [canAdd, setCanAdd] = useState(false);
  const [canModify, setCanModify] = useState(false);
  const [canDelete, setCanDelete] = useState(false);

  useEffect(() => {
    setCanAdd(
      PermissionsHelper.allow("Manufacturers", "Edit", "Contacts", "Add") &&
        props.canEdit
    );
    setCanModify(
      PermissionsHelper.allow("Manufacturers", "Edit", "Contacts", "Edit") &&
        props.canEdit
    );
    setCanDelete(
      PermissionsHelper.allow("Manufacturers", "Edit", "Contacts", "Delete") &&
        props.canEdit
    );

    TableGenericHelper.localOrder(dataOrder, props.contacts!);
  }, [props, dataOrder]);

  const handleContactSave = async (item: ManufacturerContactReducedDto) => {
    let result = false;

    try {
      if (contactModalContact) {
        await ManufacturersContactsRequests.modify(contactModalContact, item);
      } else {
        await ManufacturersContactsRequests.create({
          ...item,
          idManufacturer: props.item.id,
        });
      }

      setContactModalOpen(false);
      result = true;
      props.update();
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    return result;
  };

  const handleContactDelete = async (item: ManufacturerContactReducedDto) => {
    setDeleteModalOpen(false);
    setIsWorking(true);

    try {
      await ManufacturersContactsRequests.delete(item.id);
      props.update();
      setIsWorking(false);
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
      setIsWorking(false);
    }
  };

  const handleDeleteClick = async (item: ManufacturerContactReducedDto) => {
    setDeleteModalItem(item);
    setDeleteModalOpen(true);
  };

  return (
    <>
      <Stack className="w-100">
        <TitleWithAction
          title={t("contact.listTitle")}
          titleContainerClassName="child-sub-title"
          tooltip={t("contact.create.tooltip")}
          showButton={canAdd}
          onClick={() => {
            setContactModalTitle(t("contact.create.title"));
            setContactModalContact(undefined);
            setContactModalOpen(true);
          }}
        />

        {!props.contacts || props.contacts.length === 0 ? (
          <></>
        ) : (
          <TableGeneric
            tableClassName="w-100"
            columns={ContactColumnDefinition(
              canModify,
              canDelete,
              setContactModalTitle,
              setContactModalContact,
              setContactModalOpen,
              handleDeleteClick,
              props.contacts
            )}
            items={props.contacts!}
            idProperty="id"
            headerClick={(header, itemsOrder) =>
              TableGenericHelper.localOrder(
                itemsOrder,
                props.contacts!,
                setDataOrder
              )
            }
            itemsOrder={dataOrder}
          ></TableGeneric>
        )}

        {contactModalOpen && (
          <ContactsModal
            title={contactModalTitle}
            isOpenModal={contactModalOpen}
            contact={contactModalContact}
            onCancel={() => setContactModalOpen(false)}
            onSave={(contact) => handleContactSave(contact)}
          />
        )}

        <YesNoOkDialog
          open={deleteModalOpen}
          size="md"
          type="yesNo"
          title={t("contact.delete.title")}
          message={`${t("contact.delete.warning")} ${
            deleteModalItem?.completeName
          }?`}
          onYes={() => handleContactDelete(deleteModalItem!)}
          onNo={() => setDeleteModalOpen(false)}
          isWorking={isWorking}
        />
      </Stack>
    </>
  );
};

export interface manufacturerViewContactsProps {
  contacts: ManufacturerContactReducedDto[];
  item: ManufacturerCompleteDto;
  canEdit: boolean;
  update: () => void;
}
