import { Stack } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import {
  ManufacturerCompleteDto,
  ManufacturerContactReducedDto,
  UserReducedDto,
} from "../../../../robotcloud-shared/resource-models";
import { Loading } from "../../components/Loading";
import { ToastHelper } from "../../components/news/Toast.helper";
import { ErrorHelper } from "../../uiHelpers/errors.helper";
import { ManufacturersContactsRequests } from "../../data/manufacturersContacts.request";
import { UsersRequests } from "../../data/users.requests";
import { ManufacturerViewContacts } from "./ManufacturerViewContacts";
import { ManufacturerViewTitle } from "./ManufacturerViewTitle";
import { ManufacturerViewUsers } from "./ManufacturerViewUsers";
import { PermissionsHelper } from "../../uiHelpers/permissionsHelper";
import AuthorizationContext from "../auth/authorizationContext";

export const ManufacturerView = (props: manufacturerViewProps) => {
  const [contacts, setContacts] = useState<ManufacturerContactReducedDto[]>([]);
  const [users, setUsers] = useState<UserReducedDto[]>([]);
  const [isEditor, setIsEditor] = useState(false);

  const { me } = useContext(AuthorizationContext);

  /*
  me is used to get the right permissions
  */
  useEffect(() => {
    setContacts(props.item?.manufacturerContacts ?? []);
    setUsers(props.item?.users ?? []);

    setIsEditor(
      props.item != null &&
        PermissionsHelper.editorOfManufacturer(props.item.id)
    );
  }, [props.item, me]);

  if (props.isFetching) {
    return <Loading isLoading={props.isFetching} />;
  }

  if (!props.item) {
    return <></>;
  }

  const updateContacts = async () => {
    try {
      setContacts(
        await ManufacturersContactsRequests.getFromManufacturer(props.item!.id)
      );
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }
  };

  const updateUsers = async () => {
    try {
      setUsers(await UsersRequests.getFromManufacturer(props.item!.id));
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }
  };

  return (
    <Stack className="manufacturer-view-container">
      <ManufacturerViewTitle
        item={props.item}
        canEdit={isEditor}
        onEdit={props.onEdit}
        onDelete={props.onDelete}
      />

      <ManufacturerViewUsers
        item={props.item}
        users={users}
        canEdit={isEditor}
        update={updateUsers}
      />

      <ManufacturerViewContacts
        item={props.item}
        contacts={contacts}
        canEdit={isEditor}
        update={updateContacts}
      />
    </Stack>
  );
};

export interface manufacturerViewProps {
  item: ManufacturerCompleteDto | undefined;
  onEdit: (item: ManufacturerCompleteDto) => void;
  onDelete: (item: ManufacturerCompleteDto) => void;
  isFetching: boolean;
}
