import { Subject } from "rxjs";

export class SubjectManager<T> {
  private subject = new Subject<T>();

  getObservable() {
    return this.subject.asObservable();
  }

  setData(value: T) {
    this.subject.next(value);
  }
}
