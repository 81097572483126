import { CurrencyDto } from "../../../../robotcloud-shared/resource-models";
import { CurrencyRequest } from "../../data/currency.request";
import { BaseSearch } from "./BaseSearch";
import { useTextSearch } from "../../hooks";

export const CurrencySearch = (props: currencySearchProps) => {
  const {
    items,
    currentItem,
    searchText,
    setSearchText,
    handleSelectionChange,
  } = useTextSearch<
    CurrencyDto,
    { searchedText: string | null; items: CurrencyDto[] }
  >(
    CurrencyRequest.getSearchText,
    [],
    !!props.clearAfterSelect,
    undefined,
    props.blackList,
    props.currentId,
    props.selectionChange,
    undefined
  );

  return (
    <BaseSearch
      disableCloseOnSelect={props.clearAfterSelect}
      value={currentItem?.name}
      options={items.map((x) => x.name)}
      searchText={searchText}
      setSearchText={(x) => setSearchText(x)}
      onChange={(x) => handleSelectionChange(x, "name")}
    />
  );
};

interface currencySearchProps {
  currentId?: string;
  clearAfterSelect?: boolean;
  blackList?: string[] | undefined;
  selectionChange: (select: CurrencyDto | undefined) => void;
}
