import { useTranslation } from "react-i18next";
import { GeneralFunctions } from "../../../generalFunctions";
import { KemaroMissionErrorSummaryDto } from "../../../../../../robotcloud-shared/resource-models";

export const KemaroRobotErrorSummaryModalDataSection = (
  props: KemaroRobotErrorSummaryModalDataSectionProps
) => {
  const { t } = useTranslation();

  let conter = 0;
  return (
    <div>
      <div className={`col-12 ${props.titleContainerClass}`}>
        <h6 className="fw-bold mb-1">
          {t("robot.errorSummary.errorsInDays")}{" "}
          {props.days > 1
            ? t("robot.errorSummary.recentDays", { recentDays: props.days })
            : t("robot.errorSummary.lastDay")}
        </h6>
        <hr className="my-0" />
      </div>
      {props.items.map((x) => (
        <div key={x.idMission} className="col-12 mt-3 error-container">
          <p className="mb-0">
            {t("robot.errorSummary.startTime")}
            <span className="ms-2 when">
              {GeneralFunctions.dateToTimeDateString(x.startTime)}
            </span>
          </p>

          {x.errors.map((y) => {
            conter++;
            return (
              <div className="col-12 mt-1" key={`${x.idMission}_${conter}`}>
                <p className="mb-0">
                  <strong>{y.errorCode}</strong>
                </p>
                <p className="mb-0">{y.message}</p>
              </div>
            );
          })}
          <hr className="mb-0 mt-1" />
        </div>
      ))}
    </div>
  );
};

interface KemaroRobotErrorSummaryModalDataSectionProps {
  days: number;
  items: KemaroMissionErrorSummaryDto[];
  titleContainerClass: string;
}
