import { useEffect, useState } from "react";
import {
  CustomerDto,
  CustomerFilterCriteriaDto,
} from "../../../../robotcloud-shared/resource-models";
import { CustomersRequests } from "../../data/customers.request";
import { ErrorHelper } from "../../uiHelpers/errors.helper";
import { ToastHelper } from "../news/Toast.helper";
import { BaseSearch } from "./BaseSearch";

export const CustomerSearch = (props: CustomerSearchProps) => {
  const [dataSource, setDataSource] = useState<CustomerSearchDataSource>({
    ...defaulCustomerSearchDataSource,
  });
  const [itemsToShow, setItemsToShow] = useState<CustomerDto[]>([]);
  const [currentItem, setCurrentItem] = useState<CustomerDto | undefined>(
    undefined
  );
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    getAllPaginatedItems(dataSource, props.dealerId);
  }, [dataSource, props]);

  useEffect(() => {
    if (!searchText) setItemsToShow(dataSource.items);
    else
      setItemsToShow(
        dataSource.items.filter((x) =>
          x.name.toUpperCase().includes(searchText.toUpperCase())
        )
      );
  }, [dataSource, searchText]);

  useEffect(() => {
    if (!props.currentId) {
      setCurrentItem(undefined);
      return;
    }

    const newCurrentItem = dataSource.items.find(
      (x) => x.id === props.currentId
    );

    if (!newCurrentItem) return;

    setCurrentItem(newCurrentItem);
  }, [dataSource, props.currentId]);

  const getAllPaginatedItems = async (
    ds: CustomerSearchDataSource,
    dealerId: string | undefined | null
  ) => {
    let nextPage = ds.currentPage + 1;

    if (ds.dealerId !== dealerId) nextPage = 1;
    else if (nextPage > ds.totalPages) return;

    const itemsByPage = 10;

    try {
      const filterCriteria: CustomerFilterCriteriaDto = {
        textToSearch: null,
        idKemaroDashboardEconomyCostCalculations: null,
        id: null,
        page: nextPage,
        maxItemCount: itemsByPage,
        orderBy: null,
        orderColumns: "name",
        idDealer: dealerId ?? null,
      };

      const oldItem: CustomerDto[] = ds.dealerId === dealerId ? ds.items : [];

      const result = await CustomersRequests.getList(filterCriteria);
      setDataSource({
        ...ds,
        currentPage: nextPage,
        dealerId: dealerId,
        totalPages: Math.ceil(result.totalItems / itemsByPage),
        items: [
          ...oldItem,
          ...result.items.filter((x) => !oldItem.some((y) => y.id === x.id)),
        ],
      });
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }
  };

  const selectionChange = (newValue: string | null) => {
    if (!newValue) {
      setCurrentItem(undefined);
      props.selectionChange(undefined);
      return;
    }

    const selected = itemsToShow.find((x) => x.name === newValue);
    if (!props.clearAfterSelect) {
      setCurrentItem(selected);
    }

    props.selectionChange(selected);

    if (props.clearAfterSelect) {
      setCurrentItem(undefined);
      setSearchText("");
    }
  };

  return (
    <BaseSearch
      disableCloseOnSelect={props.clearAfterSelect}
      value={currentItem?.name}
      options={itemsToShow
        .filter((x) => !props.blackList || !props.blackList.includes(x.id))
        .map((x) => x.name)}
      searchText={searchText}
      disabled={props.disabled}
      setSearchText={(x) => setSearchText(x)}
      onChange={(x) => selectionChange(x)}
    />
  );
};

interface CustomerSearchProps {
  currentId?: string | undefined | null;
  clearAfterSelect?: boolean;
  disabled?: boolean;
  dealerId?: string | undefined | null;
  blackList?: string[] | undefined;
  selectionChange: (select: CustomerDto | undefined) => void;
}

interface CustomerSearchDataSource {
  currentPage: number;
  totalPages: number;
  items: CustomerDto[];
  dealerId?: string | undefined | null;
}

const defaulCustomerSearchDataSource: CustomerSearchDataSource = {
  currentPage: 0,
  totalPages: 1,
  items: [],
  dealerId: null,
};
