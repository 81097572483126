import { useEffect, useState } from "react";
import { ToastHelper } from "../../news/Toast.helper";
import { ErrorHelper } from "../../../uiHelpers/errors.helper";
import { KemaroRobotRequests } from "../../../data/kemaro/kemaroRobot.request";
import { BaseSearch } from "../BaseSearch";

export const KemaroRobotTagSearch = (props: KemaroRobotTagSearchProps) => {
  const [items, setItems] = useState<string[]>([]);
  const [currentItem, setCurrentItem] = useState<string>("");
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    try {
      setItems(await KemaroRobotRequests.getTagList());
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }
  };

  const keyDownHandler = (key: string) => {
    if (key !== "Enter") return;
    addText();
  };

  const addText = () => {
    const item = items.filter(
      (x) => x.toLowerCase() === currentItem.toLowerCase()
    )[0];
    if (!props.canAdd && !item) return;

    props.selected(item ?? currentItem);
    setCurrentItem("");
  };

  const selectionChange = (newValue: string | null) => {
    if (newValue) props.selected(newValue);
    setSearchText("");
  };

  return (
    <BaseSearch
      disableCloseOnSelect={true}
      value={currentItem}
      options={items.filter((x) => !props.usedTags.includes(x)).map((x) => x)}
      searchText={searchText}
      setSearchText={setSearchText}
      placeHolderText={props.placeHolderText}
      onChange={selectionChange}
      onKeyDown={keyDownHandler}
    />
  );
};

interface KemaroRobotTagSearchProps {
  usedTags: string[];
  canAdd: boolean;
  placeHolderText?: string;
  selected: (tag: string) => void;
}
