import { Box } from "@mui/material";
import {
  ApplicationBar,
  TooltipButton,
  applicationBarRef,
} from "../../../components/news";
import { useTranslation } from "react-i18next";
import { useContext, useRef } from "react";
import KemaroRobotContext from "./KemaroRobotContext";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { KemaroRobotsFilter } from "./KemaroRobotsFilter";

export const KemaroRobotsHeader = (props: KemaroRobotsHeaderProps) => {
  const { filter, setFilter, pagination, setPagination, setCurrentItem } =
    useContext(KemaroRobotContext);

  const { t } = useTranslation();

  const refAapplicationBar = useRef<applicationBarRef>(null);

  const textSearchChange = (newTextToSearch: string) => {
    if (newTextToSearch === filter.textToSearch) return;
    setFilter({
      ...filter,
      textToSearch: newTextToSearch,
    });
    setCurrentItem(undefined);
    setPagination({
      ...pagination,
      currentPage: 1,
    });
  };

  return (
    <>
      <Box className="page-header">
        <ApplicationBar
          ref={refAapplicationBar}
          title={t("robot.title")}
          search={textSearchChange}
        >
          <TooltipButton
            className="button"
            tooltip={t("robot.filter.tooltip")}
            onClick={() => props.setIsFilterDialogOpen(true)}
          >
            <FilterAltOutlinedIcon
              sx={{ color: "swarm.applicationBar.button" }}
            />
          </TooltipButton>
        </ApplicationBar>
      </Box>

      <KemaroRobotsFilter
        open={props.isFilterDialogOpen}
        filter={filter}
        cancel={() => props.setIsFilterDialogOpen(false)}
        apply={(x) => {
          setFilter({ ...x });
          setCurrentItem(undefined);
          props.setIsFilterDialogOpen(false);
          setPagination({
            ...pagination,
            currentPage: 1,
          });
        }}
      />
    </>
  );
};

interface KemaroRobotsHeaderProps {
  isFilterDialogOpen: boolean;
  setIsFilterDialogOpen: (isFilterDialogOpen: boolean) => void;
}
