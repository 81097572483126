import { Button, Dialog, Radio, Stack, Switch, TextField } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import {
  CompanyStructureReducedDto,
  CustomerContactDto,
  CustomerDto,
  DealerDto,
  KemaroDashboardEconomyCostCalculationListDto,
  KemaroRobotListDto,
  MissionStatus,
} from "../../../../../robotcloud-shared/resource-models";
import { Multiselect } from "../../../components/news/Multiselect";
import { ToastHelper } from "../../../components/news/Toast.helper";
import { CustomerContactSearch } from "../../../components/search/CustomerContactSearch";
import { CustomerSearch } from "../../../components/search/CustomerSearch";
import { DealerSearch } from "../../../components/search/DealerSearch";
import { KemaroRobotSearch } from "../../../components/search/kemaro/KemaroRobotSearch";
import { BadgeHelper } from "../../badgeHelper";
import { GeneralFunctions } from "../../generalFunctions";
import { useTranslation } from "react-i18next";
import { CompanyStructureSearch } from "../../../components/search/CompanyStructureSearch";
import { KemaroDashboardEconomyCostCalculationSearch } from "../../../components/search/kemaro/KemaroDashboardEconomyCostCalculationSearch";
import { tableGenericHandleKeyboardSubjectManager } from "../../../components/news";
import { PermissionsHelper } from "../../../uiHelpers/permissionsHelper";
import AuthorizationContext from "../../auth/authorizationContext";
import { kemaroTagsRegExp, kemaroTagsRegExpInvalid } from "../KemaroContants";
import { KemaroRobotTagsList } from "../../../components/search/kemaro/KemaroRobotTagsList";
import { CustomFieldAutocomplete } from "../../../components/search";
import { CustomFieldAutocompleteDto } from "../../../data/common";
import { KemaroZoneNamesSearch } from "../../../components/search/kemaro/KemaroZoneNamesSearch";

export const KemaroMissionsFilterModal = (
  props: kemaroMissionsFilterModalProps
) => {
  const [filter, setFilter] = useState<kemaroMissionsFilter>(
    kemaroMissionsFilterEmpty
  );
  const [isShowTag, setIsShowTag] = useState(false);

  const { me } = useContext(AuthorizationContext);

  const { t } = useTranslation();

  useEffect(() => {
    setIsShowTag(PermissionsHelper.allow("KemaroRobots", "View", "Tags"));
  }, [me]);

  useEffect(() => {
    setFilter(props.filter);
  }, [props.filter]);

  useEffect(() => {
    tableGenericHandleKeyboardSubjectManager.setData({
      caller: "KemaroMissionsFilterModal",
      handleKeyboard: props.open,
    });
  }, [props.open]);

  const cancelHandle = () => {
    setFilter(props.filter);
    props.cancel();
  };

  return (
    <Dialog
      className="application-modal"
      open={props.open}
      maxWidth={"md"}
      onClose={() => props.cancel()}
    >
      <div className="modal-content">
        <Stack>
          <div className="application-modal-header">
            <h3 className="modal-title">{t("mission.filter.title")}</h3>
          </div>
          <div className="application-modal-body">
            <div className="container">
              <div className="row">
                <div className="mb-3 col-3 input-label">
                  {t("mission.filter.robots")}
                </div>

                <div className="mb-3 col-9">
                  <Multiselect
                    items={BadgeHelper.kemaroRobotsToBadgeItem(
                      filter.kemaroRobots
                    )}
                    itemRemove={(x) =>
                      setFilter({
                        ...filter,
                        kemaroRobots: GeneralFunctions.arrayRemove(
                          filter.kemaroRobots,
                          x.source as KemaroRobotListDto
                        ),
                      })
                    }
                  >
                    <KemaroRobotSearch
                      clearAfterSelect
                      blackList={filter.kemaroRobots.map((x) => x.id)}
                      selectionChange={(x) =>
                        setFilter({
                          ...filter,
                          kemaroRobots: GeneralFunctions.arrayAdd(
                            filter.kemaroRobots,
                            x
                          ),
                        })
                      }
                    />
                  </Multiselect>
                </div>

                <div className="mb-3 col-3 input-label">
                  {t("mission.filter.dealers")}
                </div>

                <div className="mb-3 col-9">
                  <Multiselect
                    items={BadgeHelper.dealersToBadgeItem(filter.dealers)}
                    itemRemove={(x) =>
                      setFilter({
                        ...filter,
                        dealers: GeneralFunctions.arrayRemove(
                          filter.dealers,
                          x.source as DealerDto
                        ),
                      })
                    }
                  >
                    <DealerSearch
                      clearAfterSelect
                      blackList={filter.dealers.map((x) => x.id)}
                      selectionChange={(x) =>
                        setFilter({
                          ...filter,
                          dealers: GeneralFunctions.arrayAdd(filter.dealers, x),
                        })
                      }
                    />
                  </Multiselect>
                </div>

                <div className="mb-3 col-3 input-label">
                  {t("mission.filter.customers")}
                </div>

                <div className="mb-3 col-9">
                  <Multiselect
                    items={BadgeHelper.customersToBadgeItem(filter.customers)}
                    itemRemove={(x) =>
                      setFilter({
                        ...filter,
                        customers: GeneralFunctions.arrayRemove(
                          filter.customers,
                          x.source as CustomerDto
                        ),
                      })
                    }
                  >
                    <CustomerSearch
                      clearAfterSelect
                      blackList={filter.customers.map((x) => x.id)}
                      selectionChange={(x) =>
                        setFilter({
                          ...filter,
                          customers: GeneralFunctions.arrayAdd(
                            filter.customers,
                            x
                          ),
                        })
                      }
                    />
                  </Multiselect>
                </div>

                <div className="mb-3 col-3 input-label">
                  {t("mission.filter.location")}
                </div>

                <div className="mb-3 col-9">
                  <Multiselect
                    items={BadgeHelper.companyStructuresToBadgeItem(
                      filter.companyStructures
                    )}
                    itemRemove={(x) =>
                      setFilter({
                        ...filter,
                        companyStructures: GeneralFunctions.arrayRemove(
                          filter.companyStructures,
                          x.source as CompanyStructureReducedDto
                        ),
                      })
                    }
                  >
                    <CompanyStructureSearch
                      clearAfterSelect
                      customerId={null}
                      blackList={filter.companyStructures.map((x) => x.id)}
                      selectionChange={(x) =>
                        setFilter({
                          ...filter,
                          companyStructures: GeneralFunctions.arrayAdd(
                            filter.companyStructures,
                            x
                          ),
                        })
                      }
                    />
                  </Multiselect>
                </div>

                <div className="mb-3 col-3 input-label">
                  {t("mission.filter.locationIncludeChildren")}
                </div>

                <div className="mb-3 col-9">
                  <Switch
                    color="primary"
                    checked={filter.includeChildrenNodes}
                    onChange={(event) =>
                      setFilter({
                        ...filter,
                        includeChildrenNodes: !filter.includeChildrenNodes,
                      })
                    }
                  />
                </div>

                <div className="mb-3 col-3 input-label">
                  {t("mission.filter.customerContacts")}
                </div>

                <div className="mb-3 col-9">
                  <Multiselect
                    items={BadgeHelper.customerContactsToBadgeItem(
                      filter.customerContacts
                    )}
                    itemRemove={(x) =>
                      setFilter({
                        ...filter,
                        customerContacts: GeneralFunctions.arrayRemove(
                          filter.customerContacts,
                          x.source as CustomerContactDto
                        ),
                      })
                    }
                  >
                    <CustomerContactSearch
                      customerId={null}
                      clearAfterSelect
                      blackList={filter.customerContacts.map((x) => x.id)}
                      selectionChange={(x) =>
                        setFilter({
                          ...filter,
                          customerContacts: GeneralFunctions.arrayAdd(
                            filter.customerContacts,
                            x
                          ),
                        })
                      }
                    />
                  </Multiselect>
                </div>

                <div className="mb-3 col-3 input-label">
                  {t("mission.filter.costCalculation")}
                </div>

                <div className="mb-3 col-9">
                  <Multiselect
                    items={BadgeHelper.kemaroDashboardEconomyCostCalculationToBadgeItem(
                      filter.kemaroDashboardEconomyCostCalculations
                    )}
                    itemRemove={(x) =>
                      setFilter({
                        ...filter,
                        kemaroDashboardEconomyCostCalculations:
                          GeneralFunctions.arrayRemove(
                            filter.kemaroDashboardEconomyCostCalculations,
                            x.source as KemaroDashboardEconomyCostCalculationListDto
                          ),
                      })
                    }
                  >
                    <KemaroDashboardEconomyCostCalculationSearch
                      clearAfterSelect
                      blackList={filter.kemaroDashboardEconomyCostCalculations.map(
                        (x) => x.id
                      )}
                      selectionChange={(x) =>
                        setFilter({
                          ...filter,
                          kemaroDashboardEconomyCostCalculations:
                            GeneralFunctions.arrayAdd(
                              filter.kemaroDashboardEconomyCostCalculations,
                              x
                            ),
                        })
                      }
                    />
                  </Multiselect>
                </div>

                <div className="mb-3 col-3 input-label">
                  {t("mission.filter.startTime.label")}
                </div>

                <div className="mb-3 col-4">
                  <TextField
                    className="w-100 pt-0"
                    type="date"
                    label=""
                    fullWidth
                    variant="standard"
                    margin="dense"
                    hiddenLabel
                    value={GeneralFunctions.dateToAnsiiDate(filter.startDate)}
                    onChange={(event) =>
                      setFilter({
                        ...filter,
                        startDate: event.target.value
                          ? new Date(event.target.value)
                          : undefined,
                      })
                    }
                  />
                </div>

                <div className="mb-3 offset-1 col-4">
                  <TextField
                    className="w-100 pt-0"
                    type="date"
                    label=""
                    fullWidth
                    variant="standard"
                    margin="dense"
                    hiddenLabel
                    value={GeneralFunctions.dateToAnsiiDate(filter.stopDate)}
                    onChange={(event) =>
                      setFilter({
                        ...filter,
                        stopDate: event.target.value
                          ? new Date(event.target.value)
                          : undefined,
                      })
                    }
                  />
                </div>

                <div className="mb-3 col-3 input-label">
                  {t("mission.filter.status.title")}
                </div>

                <div className="mb-3 col-9">
                  <Radio
                    className="px-0"
                    checked={filter.status === null}
                    onChange={() =>
                      setFilter({
                        ...filter,
                        status: null,
                      })
                    }
                    name="status-radio-buttons"
                    color="primary"
                  />
                  <span className="ms-1 me-3 align-middle">
                    {t("mission.filter.status.all")}
                  </span>

                  <Radio
                    className="px-0"
                    checked={filter.status === "Successful"}
                    onChange={() =>
                      setFilter({
                        ...filter,
                        status: "Successful",
                      })
                    }
                    name="status-radio-buttons"
                    color="primary"
                  />
                  <span className="ms-1 me-3 align-middle">
                    {t("mission.filter.status.successful")}
                  </span>

                  <Radio
                    className="px-0"
                    checked={filter.status === "Failed"}
                    onChange={() =>
                      setFilter({
                        ...filter,
                        status: "Failed",
                      })
                    }
                    name="status-radio-buttons"
                    color="primary"
                  />
                  <span className="ms-1 me-3 align-middle">
                    {t("mission.filter.status.failed")}
                  </span>
                </div>

                {isShowTag && (
                  <>
                    <div className="mb-3 col-3 input-label">
                      {t("tags.title")}
                    </div>

                    <div className="mb-3 col-9">
                      <KemaroRobotTagsList
                        addToolTip={t("tags.add")}
                        removeTootTip={t("tags.remove")}
                        tags={filter.tags}
                        itemsShowAs="InLine"
                        regularExpression={kemaroTagsRegExp}
                        invalidText={kemaroTagsRegExpInvalid}
                        onChange={(x) =>
                          setFilter({
                            ...filter,
                            tags: x,
                          })
                        }
                        canAddTag={false}
                      />
                    </div>
                  </>
                )}

                <div className="mb-3 col-3 input-label">
                  {t("mission.add.cleaningMode")}
                </div>

                <div className="mb-3 col-9">
                  <Multiselect
                    items={BadgeHelper.cleaningModeToBadgeItem(
                      filter.cleaningModes
                    )}
                    itemRemove={(x) =>
                      setFilter({
                        ...filter,
                        cleaningModes:
                          GeneralFunctions.arrayCustomFieldAutocompleteDtoRemove(
                            filter.cleaningModes,
                            x.source as CustomFieldAutocompleteDto
                          ),
                      })
                    }
                  >
                    <CustomFieldAutocomplete
                      type="CleaningMode"
                      clearAfterSelect
                      blackList={filter.cleaningModes.map((x) => x.code)}
                      onChange={(x) => {
                        setFilter({
                          ...filter,
                          cleaningModes:
                            GeneralFunctions.arrayCustomFieldAutocompleteDtoAdd(
                              filter.cleaningModes,
                              x
                            ),
                        });
                      }}
                    />
                  </Multiselect>
                </div>

                <div className="mb-3 col-3 input-label">
                  {t("mission.filter.errorCodes")}
                </div>

                <div className="mb-3 col-9">
                  <Multiselect
                    items={BadgeHelper.errorCodeToBadgeItem(filter.errorCodes)}
                    itemRemove={(x) =>
                      setFilter({
                        ...filter,
                        errorCodes:
                          GeneralFunctions.arrayCustomFieldAutocompleteDtoRemove(
                            filter.errorCodes,
                            x.source as CustomFieldAutocompleteDto
                          ),
                      })
                    }
                  >
                    <CustomFieldAutocomplete
                      type="ErrorCode"
                      clearAfterSelect
                      blackList={filter.errorCodes.map((x) => x.code)}
                      onChange={(x) => {
                        setFilter({
                          ...filter,
                          errorCodes:
                            GeneralFunctions.arrayCustomFieldAutocompleteDtoAdd(
                              filter.errorCodes,
                              x
                            ),
                        });
                      }}
                    />
                  </Multiselect>
                </div>

                <div className="mb-3 col-3 input-label">
                  {t("mission.add.startReason")}
                </div>

                <div className="mb-3 col-9">
                  <Multiselect
                    items={BadgeHelper.startReasonToBadgeItem(
                      filter.startReasons
                    )}
                    itemRemove={(x) =>
                      setFilter({
                        ...filter,
                        startReasons:
                          GeneralFunctions.arrayCustomFieldAutocompleteDtoRemove(
                            filter.startReasons,
                            x.source as CustomFieldAutocompleteDto
                          ),
                      })
                    }
                  >
                    <CustomFieldAutocomplete
                      type="MissionStartStopReason"
                      clearAfterSelect
                      blackList={filter.startReasons.map((x) => x.code)}
                      onChange={(x) => {
                        setFilter({
                          ...filter,
                          startReasons:
                            GeneralFunctions.arrayCustomFieldAutocompleteDtoAdd(
                              filter.startReasons,
                              x
                            ),
                        });
                      }}
                    />
                  </Multiselect>
                </div>

                <div className="mb-3 col-3 input-label">
                  {t("mission.add.stopReason")}
                </div>

                <div className="mb-3 col-9">
                  <Multiselect
                    items={BadgeHelper.stopReasonToBadgeItem(
                      filter.stopReasons
                    )}
                    itemRemove={(x) =>
                      setFilter({
                        ...filter,
                        stopReasons:
                          GeneralFunctions.arrayCustomFieldAutocompleteDtoRemove(
                            filter.stopReasons,
                            x.source as CustomFieldAutocompleteDto
                          ),
                      })
                    }
                  >
                    <CustomFieldAutocomplete
                      type="MissionStartStopReason"
                      clearAfterSelect
                      blackList={filter.stopReasons.map((x) => x.code)}
                      onChange={(x) => {
                        setFilter({
                          ...filter,
                          stopReasons:
                            GeneralFunctions.arrayCustomFieldAutocompleteDtoAdd(
                              filter.stopReasons,
                              x
                            ),
                        });
                      }}
                    />
                  </Multiselect>
                </div>

                <div className="mb-3 col-3 input-label">
                  {t("mission.add.zoneName")}
                </div>

                <div className="mb-3 col-9">
                  <Multiselect
                    items={BadgeHelper.zoneNamesToBadgeItem(filter.zoneNames)}
                    itemRemove={(x) =>
                      setFilter({
                        ...filter,
                        zoneNames: GeneralFunctions.arrayStringRemove(
                          filter.zoneNames,
                          x.source as string
                        ),
                      })
                    }
                  >
                    <KemaroZoneNamesSearch
                      clearAfterSelect
                      blackList={filter.zoneNames}
                      selectionChange={(x) => {
                        setFilter({
                          ...filter,
                          zoneNames: GeneralFunctions.arrayStringAdd(
                            filter.zoneNames,
                            x
                          ),
                        });
                      }}
                    />
                  </Multiselect>
                </div>

                {/* Remo 2024 03 15
                The room name is not yet shown in the mission UI, this is why the element is disabled until we change this */}
                {/* <div className="mb-3 col-3 input-label">
                  {t("mission.add.roomName")}
                </div>

                <div className="mb-3 col-9">
                  <Multiselect
                    items={BadgeHelper.roomNamesToBadgeItem(filter.roomNames)}
                    itemRemove={(x) =>
                      setFilter({
                        ...filter,
                        roomNames: GeneralFunctions.arrayStringRemove(
                          filter.roomNames,
                          x.source as string
                        ),
                      })
                    }
                  >
                    <KemaroRoomNamesSearch
                      clearAfterSelect
                      blackList={filter.roomNames}
                      selectionChange={(x) => {
                        setFilter({
                          ...filter,
                          roomNames: GeneralFunctions.arrayStringAdd(
                            filter.roomNames,
                            x
                          ),
                        });
                      }}
                    />
                  </Multiselect>
                </div> */}
              </div>
            </div>
          </div>

          <div className="application-modal-footer">
            <Button
              variant="contained"
              className="button-with-text"
              color="primary"
              onClick={() => {
                if (
                  filter.startDate &&
                  filter.stopDate &&
                  filter.startDate > filter.stopDate
                ) {
                  ToastHelper.error(t("mission.filter.fromDateBiggerMessage"));
                  return;
                }
                props.apply(filter);
              }}
            >
              {t("common.buttons.apply")}
            </Button>

            <Button
              className="modal-cancel-button button-with-text"
              variant="outlined"
              color="primary"
              onClick={cancelHandle}
            >
              {t("common.buttons.cancel")}
            </Button>
          </div>
        </Stack>
      </div>
    </Dialog>
  );
};

export interface kemaroMissionsFilterModalProps {
  open: boolean;
  filter: kemaroMissionsFilter;
  apply: (filter: kemaroMissionsFilter) => void;
  cancel: () => void;
}

export interface kemaroMissionsFilter {
  startDate: Date | undefined;
  stopDate: Date | undefined;
  status: MissionStatus | null;
  dealers: DealerDto[];
  customers: CustomerDto[];
  customerContacts: CustomerContactDto[];
  kemaroRobots: KemaroRobotListDto[];
  textToSearch: string;
  errorCodes: CustomFieldAutocompleteDto[];
  companyStructures: CompanyStructureReducedDto[];
  includeChildrenNodes: boolean;
  kemaroDashboardEconomyCostCalculations: KemaroDashboardEconomyCostCalculationListDto[];
  cleaningModes: CustomFieldAutocompleteDto[];
  startReasons: CustomFieldAutocompleteDto[];
  stopReasons: CustomFieldAutocompleteDto[];
  zoneNames: string[];
  roomNames: string[];
  tags: string[];
}

export const kemaroMissionsFilterEmpty: kemaroMissionsFilter = {
  startDate: undefined,
  stopDate: undefined,
  status: null,
  dealers: [],
  customers: [],
  customerContacts: [],
  kemaroRobots: [],
  textToSearch: "",
  errorCodes: [],
  companyStructures: [],
  includeChildrenNodes: false,
  kemaroDashboardEconomyCostCalculations: [],
  tags: [],
  cleaningModes: [],
  startReasons: [],
  stopReasons: [],
  zoneNames: [],
  roomNames: [],
};
