import { toast } from "react-toastify";
import { ErrorData } from "../../uiHelpers/errors.helper";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";

export class ToastHelper {
  static errors = (errors: ErrorData[]) => {
    errors.map((x) => ToastHelper.error(x.message, x.property));
  };

  static error = (message: string, title?: string) => {
    toast.error(
      messageTemplate({
        title: title,
        message: message,
        type: "Error",
      }),
      { icon: false }
    );
  };

  static success = (message: string, title?: string) => {
    toast.success(
      messageTemplate({
        title: title,
        message: message,
        type: "Success",
      }),
      { icon: false }
    );
  };

  static warning = (message: string, title?: string) => {
    toast.warning(
      messageTemplate({
        title: title,
        message: message,
        type: "Warning",
      }),
      { icon: false }
    );
  };

  static info = (message: string, title?: string) => {
    toast.info(
      messageTemplate({
        title: title,
        message: message,
        type: "Info",
      }),
      { icon: false }
    );
  };
}

const messageTemplate = (props: messageTemplateProps) => {
  return (
    <table className="w-100 mt-1 mb-2">
      <tbody>
        {props.title && props.title.length > 0 && (
          <tr>
            <td className="Toastify__toast-title-container" colSpan={2}>
              {props.title}
            </td>
          </tr>
        )}

        <tr>
          <td className="Toastify__toast-icon-container">
            {props.type === "Error" ? (
              <ErrorOutlineOutlinedIcon />
            ) : props.type === "Success" ? (
              <TaskAltOutlinedIcon />
            ) : props.type === "Warning" ? (
              <InfoOutlinedIcon />
            ) : (
              <WarningAmberOutlinedIcon />
            )}
          </td>
          <td className="Toastify__toast-text-container">{props.message}</td>
        </tr>
      </tbody>
    </table>
  );
};

interface messageTemplateProps {
  title?: string;
  message: string;
  type: "Error" | "Info" | "Warning" | "Success";
}
