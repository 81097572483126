import { ApplicationBar } from "../../../../components/news/ApplicationBar";
import { CrudItemsPanel } from "../../../../components/news/CrudItemsPanel";
import { Pagination } from "../../../../components/news/Pagination";
import { TableGeneric } from "../../../../components/news/TableGeneric/TableGeneric";
import { KemaroAdministrationTabsNav } from "../KemaroAdministrationTabsNav";
import { KemaroCostCalculationModal } from "./KemaroCostCalculationModal";
import { KemaroCostCalculationView } from "./KemaroCostCalculationView";
import { useTranslation } from "react-i18next";
import { KemaroCostCalculationColumnDefinition } from "./KemaroCostCalculationColumnDefinition";
import { RecalculateDashboardsButton } from "./components/RecalculateDashboardsButton";
import { useKemaroCostCalculation } from "../../../../hooks";
import "./kemaroCostCalculation.scss";
import useTheme from "@mui/material/styles/useTheme";
import { useContext } from "react";
import AuthorizationContext from "../../../auth/authorizationContext";
import { ExecutionHistoryButton } from "./components/ExecutionHistoryButton";

export const KemaroCostCalculation = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { me } = useContext(AuthorizationContext);

  const {
    // Fetching states
    isFetchingList,
    isFetchingItem,
    // Data entities and their manipulation
    items,
    currentItem,
    addParameter,
    editParameter,
    deleteParameter,
    setCurrentId,
    // Popup states and interactions
    openCreatePopup,
    setOpenCreatePopup,
    createPopupIsWorking,
    // UI control states
    canAdd,
    canRecalculateDashboards,
    // Pagination and ordering
    pagination,
    pageChange,
    dataOrder,
    setDataOrder,
    // Search and filtering
    textSearchChange,
    // Event handlers
    handleCreateSave,
    handleEditCalculation,
    handleDeleteCalculation,
    handleCopyCalculation,
  } = useKemaroCostCalculation();

  return (
    <div className="page-content">
      <div className="page-header">
        <ApplicationBar
          title={t("applicationBar.administration.title")}
          search={textSearchChange}
        />
      </div>

      <KemaroAdministrationTabsNav currentTab="kemaro-cost-calculation" />

      <div className="page-body d-flex kemaro-cost-calculation-body">
        <div className="kemaro-cost-calculation-left-side">
          <CrudItemsPanel
            title={t("costCalculation.listTitle")}
            createToolTip={t("costCalculation.create.tooltip")}
            containerClassName="kemaro-cost-calculation-list-items"
            isFetching={isFetchingList}
            items={items}
            canCreate={canAdd}
            onCreateClick={() => setOpenCreatePopup(true)}
            titleRightSide={
              <>
                {canRecalculateDashboards && <RecalculateDashboardsButton />}
                {me?.userAccessType === "Earlybyte" && (
                  <ExecutionHistoryButton />
                )}
              </>
            }
          >
            <TableGeneric
              columns={KemaroCostCalculationColumnDefinition(theme)}
              items={items ?? []}
              idProperty="id"
              headerClick={(header, itemsOrder) => setDataOrder(itemsOrder)}
              selectionChanged={(item) => setCurrentId(item.id)}
              itemsOrder={dataOrder}
              selectId={currentItem?.id}
              isAwaiting={isFetchingItem}
              captureKeyboard
            />
          </CrudItemsPanel>

          <Pagination
            containerClassName="kemaro-cost-calculation-list-pager"
            pagination={pagination}
            isFetching={isFetchingList}
            onChange={pageChange}
          />

          {openCreatePopup && (
            <KemaroCostCalculationModal
              title={t("costCalculation.create.title")}
              isOpenModal={openCreatePopup}
              onSave={handleCreateSave}
              onCancel={() => setOpenCreatePopup(false)}
              isWorking={createPopupIsWorking}
              dto={undefined}
            />
          )}
        </div>

        <div className="view-container">
          {currentItem && (
            <KemaroCostCalculationView
              item={currentItem}
              isFetching={isFetchingItem}
              copyCalculation={handleCopyCalculation}
              editCalculation={handleEditCalculation}
              deleteCalculation={handleDeleteCalculation}
              addParameter={addParameter}
              editParameter={editParameter}
              deleteParameter={deleteParameter}
            />
          )}
        </div>
      </div>
    </div>
  );
};
