import { AxiosRequests } from "./axios.requests";
import { diff, jsonPatchPathConverter } from "just-diff";
import {
  ManufacturerContactDto,
  ManufacturerContactPostDto,
  ManufacturerContactReducedDto,
  SearchTextDtoOfGuid,
  SearchTextResultDtoOfManufacturerContactDto,
} from "../../../robotcloud-shared/resource-models";
import { BaseRequest } from "./BaseRequest";
import { prepareSearchTextDto } from "./functions";

export class ManufacturersContactsRequests extends BaseRequest {
  static controller = "api/webapp/v1.0/Manufacturers/contacts";

  static create = async (postDto: ManufacturerContactPostDto) => {
    const { data } = await AxiosRequests.post(
      ManufacturersContactsRequests.controller,
      postDto
    );
    return data as ManufacturerContactDto;
  };

  static modify = async (
    original: ManufacturerContactReducedDto,
    modified: ManufacturerContactReducedDto
  ) => {
    const allowProperties = [
      "/firstName",
      "/sureName",
      "/address",
      "/city",
      "/zipCode",
      "/phone",
      "/email",
      "/idCountry",
      "/description",
    ];

    const jsonPatch = diff(original, modified, jsonPatchPathConverter).filter(
      (x) => allowProperties.includes(x.path)
    );

    const { data } = await AxiosRequests.patch(
      `${ManufacturersContactsRequests.controller}/${original.id}`,
      jsonPatch
    );
    return data as ManufacturerContactDto;
  };

  static delete = async (id: string) => {
    return await AxiosRequests.delete(
      `${ManufacturersContactsRequests.controller}/${id}`
    );
  };

  static getFromManufacturer = async (manufacturerId: string) => {
    const { data } = await AxiosRequests.get(
      `${ManufacturersContactsRequests.controller}/from-manufacturer/${manufacturerId}`
    );
    return data as ManufacturerContactReducedDto[];
  };

  static getSearchText = async (
    manufacturerId: string,
    searchTextDto: SearchTextDtoOfGuid
  ) => {
    if (!manufacturerId) {
      const result: SearchTextResultDtoOfManufacturerContactDto = {
        items: [],
        searchedText: null,
        totalItems: 0,
      };

      return result;
    }

    const { data } = await AxiosRequests.post(
      `${ManufacturersContactsRequests.controller}/${manufacturerId}/search-text`,
      prepareSearchTextDto(searchTextDto)
    );

    return data as SearchTextResultDtoOfManufacturerContactDto;
  };
}
