import {
  FeatureFlagDto,
  FeatureFlagFilterCriteriaDto,
  FeatureFlagName,
  PaginatedListDtoOfFeatureFlagDto,
} from "../../../robotcloud-shared/resource-models";
import { AxiosRequests } from "./axios.requests";
import { BaseRequest } from "./BaseRequest";

export class FeatureFlagsRequests extends BaseRequest {
  static controller = "/api/webapp/v1.0/organizations/featureflags";

  static getList = async (filterCriteria: FeatureFlagFilterCriteriaDto) => {
    const { data } = await AxiosRequests.get(
      `${
        FeatureFlagsRequests.controller
      }?${FeatureFlagsRequests.objectToQueryString(filterCriteria)}`
    );
    return data as PaginatedListDtoOfFeatureFlagDto;
  };

  static getByIdComplete = async (id: string) => {
    const { data } = await AxiosRequests.get(
      `${FeatureFlagsRequests.controller}/${id}/complete`
    );
    return data as FeatureFlagDto;
  };

  static getByName = async (featureFlagName: FeatureFlagName) => {
    const filterCriteria: FeatureFlagFilterCriteriaDto = {
      page: 1,
      maxItemCount: 1,
      name: featureFlagName,
      isEnabled: null,
      id: null,
      orderBy: null,
      orderColumns: null,
    };
    return await FeatureFlagsRequests.getList(filterCriteria);
  };
}
