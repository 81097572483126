import { useTranslation } from "react-i18next";
import { TooltipButton } from "../../../../components/news";
import { useContext, useState } from "react";
import KemaroRobotContext from "../KemaroRobotContext";
import CodeOutlinedIcon from "@mui/icons-material/CodeOutlined";
import { KemaroRobotAccessSettings } from "../KemaroRobotAccessSettings";

export const AccessSettingsButton = () => {
  const { t } = useTranslation();
  const { currentItem, setViewSideModalOpenChange } =
    useContext(KemaroRobotContext);
  const [openAccessSettingsPopup, setOpenAccessSettingsPopup] = useState(false);

  const openAccessSettingsPopupChange = (value: boolean) => {
    setOpenAccessSettingsPopup(value);
    setViewSideModalOpenChange(value);
  };

  return (
    <>
      <TooltipButton
        aria-label={t("robot.accessSettings.tooltip")}
        tooltip={t("robot.accessSettings.tooltip")}
        onClick={() => openAccessSettingsPopupChange(true)}
      >
        <CodeOutlinedIcon color="primary" />
      </TooltipButton>

      {currentItem!.id && (
        <KemaroRobotAccessSettings
          openPopup={openAccessSettingsPopup}
          robotId={currentItem!.id}
          onClose={() => openAccessSettingsPopupChange(false)}
        />
      )}
    </>
  );
};
