import useTheme from "@mui/material/styles/useTheme";
import { KemaroDashboardEconomyCostStatisticsDiagramPointDto } from "../../../../../../robotcloud-shared/resource-models";
import { PointTooltipProps, ResponsiveLine } from "@nivo/line";
import { GeneralFunctions } from "../../../generalFunctions";
import { Theme } from "@mui/material/styles/createTheme";
import { useTranslation } from "react-i18next";
import { KemaroDashboardPointSymbol } from "./KemaroDashboardPointSymbol";

export interface kemaroDashboardEconomyStatisticsChartProps {
  values: KemaroDashboardEconomyCostStatisticsDiagramPointDto[];
  title: string;
  subTitle: string;
  isTime?: boolean;
  unit?: string;
}

export const KemaroDashboardEconomyStatisticsChart = (
  props: kemaroDashboardEconomyStatisticsChartProps
) => {
  const { t } = useTranslation();

  const data = [
    {
      id: props.title,
      data: props.values.map((item) => {
        return { x: item.text, y: item.value };
      }),
    },
  ];

  const theme = useTheme();
  const labels = generateLabels(props);

  return (
    <>
      <p className="w-100 sub-title">{props.title}</p>
      <ResponsiveLine
        {...commonProperties}
        data={data}
        margin={{ top: 10, right: 30, bottom: 70, left: 80 }}
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
          stacked: true,
          reverse: false,
        }}
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        pointSize={10}
        pointBorderWidth={2}
        pointLabelYOffset={-12}
        useMesh={true}
        animate={true}
        colors={[
          theme.palette.swarm?.kemaroDashboardStatisticsChart?.responsiveLine!,
        ]}
        lineWidth={1}
        isInteractive={true}
        axisBottom={{
          tickRotation: -40,
          tickValues: labels,
          legendOffset: 60,
          legend: <>{t("performance.calendarWeek")}</>,
          legendPosition: "middle",
        }}
        axisLeft={{
          tickValues: getAxisLeftTickValues(props),
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: props.subTitle,
          legendOffset: -60,
          legendPosition: "middle",
        }}
        theme={{
          axis: {
            legend: {
              text: {
                fontSize: "1rem",
              },
            },
          },
          markers: {
            lineColor:
              theme.palette.swarm?.kemaroDashboardStatisticsChart
                ?.responsiveLine!,
          },
        }}
        gridXValues={labels}
        tooltip={(item) =>
          toolTipHandel(item, props, theme, t("performance.week"))
        }
        layers={["grid", "axes", "lines", "points", "legends", "mesh"]}
        curve="linear"
        enableGridX={true}
        enableGridY={true}
        enablePoints={true}
        pointColor={{
          theme: "markers.lineColor",
        }}
        pointSymbol={KemaroDashboardPointSymbol}
        pointBorderColor={"markers.lineColor"}
        enablePointLabel={false}
        pointLabel={""}
        enableArea={true}
        areaOpacity={0.1}
        areaBlendMode="normal"
        areaBaselineValue={0}
        legends={[]}
        debugMesh={false}
        enableSlices={false}
        debugSlices={false}
        sliceTooltip={() => ""}
        enableCrosshair={false}
        crosshairType="bottom"
        role="img"
        defs={[]}
        fill={[]}
      />
    </>
  );
};

const commonProperties = {
  height: 400,
};

const generateLabels = (props: kemaroDashboardEconomyStatisticsChartProps) => {
  const gapSize =
    props.values.length > 60 ? 5 : props.values.length > 30 ? 2 : 1;

  return props.values
    .filter((_, index) => index % gapSize === 0)
    .map((item) => item.text);
};

const toolTipHandel = (
  item: PointTooltipProps,
  props: kemaroDashboardEconomyStatisticsChartProps,
  theme: Theme,
  week: string
) => {
  const value = props.values.find((x) => x.text === item.point.data.x);
  const valueToShow = props.isTime
    ? GeneralFunctions.minutosToHourDotMins((value?.value ?? 0) * 60)
    : (value?.value ?? 0).toString();

  return (
    <div className="bg-white px-2 py-1 tooltip-container">
      {
        <div
          className="py-1 px-2 rounded-1"
          style={{
            border: "1px solid gray",
            borderColor:
              theme.palette.swarm?.kemaroDashboardStatisticsChart
                ?.responsiveLine!,
          }}
        >
          <p className="mb-0">
            <span>{week}</span>
            <span className="ps-2 fw-bold">{value?.text}</span>
          </p>

          <p className="mb-0">
            {props.unit && <span>{props.unit}</span>}
            <span className="ps-2 fw-bold">{valueToShow}</span>
          </p>
        </div>
      }
    </div>
  );
};

const getAxisLeftTickValues = (
  props: kemaroDashboardEconomyStatisticsChartProps
) => {
  let maxValue = Math.max(...props.values.map((x) => x.value)) + 1;
  if (maxValue > 10) return undefined;

  const values: number[] = [];

  for (let index = 0; index <= maxValue; index++) {
    values.push(index);
  }

  return values[values.length - 1] > 10 ? undefined : values;
};
