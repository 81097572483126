import { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import "./dealers.scss";
import { TableGeneric } from "../../components/news/TableGeneric/TableGeneric";
import { CrudItemsPanel } from "../../components/news/CrudItemsPanel";
import { Pagination } from "../../components/news/Pagination";
import { DealerView } from "./DealerView";
import { DealerCreateModal } from "./DealerCreateModal";
import { CustomerDto } from "../../../../robotcloud-shared/resource-models";
import { ApplicationBar } from "../../components/news/ApplicationBar";
import { OrganizationTabsNav } from "../../components/news/OrganizationTabsNav";
import { PermissionsHelper } from "../../uiHelpers/permissionsHelper";
import AuthorizationContext from "../auth/authorizationContext";
import { UserRoleIcon } from "../../components/news/UserRoleIcon";
import { useTranslation } from "react-i18next";
import { TableGenericColumn } from "../../components/news/TableGeneric/models";
import useTheme from "@mui/material/styles/useTheme";
import { useDealers } from "../../hooks";

export const Dealers = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [canAdd, setCanAdd] = useState(false);
  const { me } = useContext(AuthorizationContext);
  const columnsDefinition: TableGenericColumn[] = [
    {
      title: t("common.name"),
      property: "name",
    },
    {
      canSort: false,
      itemClassName: "user-role-column",
      cellTemplate: (item: CustomerDto) => (
        <UserRoleIcon keyValue={`Icon_${item.id}`} role={item.role} />
      ),
    },
  ];

  useEffect(() => {
    setCanAdd(PermissionsHelper.allow("Dealers", "Create"));
  }, []);

  const {
    // State variables
    isFetchingList,
    isFetchingItem,
    pagination,
    dataOrder,
    items,
    currentItem,
    openCreatePopup,
    createPopupIsWorking,
    // Set state functions
    setDataOrder,
    setOpenCreatePopup,
    setCurrentId,
    // Handler functions
    handleEdit,
    handleDelete,
    handleCreateSave,
    textSearchChange,
    pageChange,
  } = useDealers();

  return (
    <Box className="page-content">
      <Box className="page-header">
        <ApplicationBar
          title={t("applicationBar.organization.title")}
          canSearch={me?.userAccessType !== "Dealer"}
          search={textSearchChange}
        />
      </Box>

      <OrganizationTabsNav currentTab="dealers" />

      <Box className="page-body d-flex dealers-body">
        {me?.userAccessType !== "Dealer" && (
          <div
            className="dealers-left-side"
            style={{
              borderRight:
                theme.palette.swarm?.dealers?.bodyLeftSideBorderRight,
            }}
          >
            <CrudItemsPanel
              title={t("dealer.listTitle")}
              createToolTip={t("dealer.create.tooltip")}
              containerClassName="dealers-list-items"
              isFetching={isFetchingList}
              items={items}
              canCreate={canAdd}
              onCreateClick={() => setOpenCreatePopup(true)}
            >
              <TableGeneric
                tableClassName=""
                showHeader={true}
                columns={columnsDefinition}
                items={items ?? []}
                idProperty="id"
                headerClick={(header, itemsOrder) => setDataOrder(itemsOrder)}
                selectionChanged={(item) => setCurrentId(item.id)}
                itemsOrder={dataOrder}
                selectId={currentItem?.id}
                isAwaiting={isFetchingItem}
                captureKeyboard
              />
            </CrudItemsPanel>
            <Pagination
              containerClassName="dealers-list-pager"
              pagination={pagination}
              isFetching={isFetchingList}
              onChange={pageChange}
            />
            {openCreatePopup && (
              <DealerCreateModal
                title={t("dealer.create.title")}
                openPopup={openCreatePopup}
                isWorking={createPopupIsWorking}
                onSave={handleCreateSave}
                onCancel={() => setOpenCreatePopup(false)}
              />
            )}
          </div>
        )}

        <Box className="view-container">
          <DealerView
            item={currentItem}
            isFetching={isFetchingItem}
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
        </Box>
      </Box>
    </Box>
  );
};
