import { useEffect, useRef, useState } from "react";
import {
  KemaroCompanyStructureDto,
  KemaroCompanyStructurePostDto,
} from "../../../../../robotcloud-shared/resource-models";
import { Loading } from "../../../components/Loading";
import { TitleWithAction } from "../../../components/news/TitleWithAction";
import { ToastHelper } from "../../../components/news/Toast.helper";
import { KemaroCompanyStructureRequests } from "../../../data/kemaro/KemaroCompanyStructure.request";
import { ErrorHelper } from "../../../uiHelpers/errors.helper";
import { PermissionsHelper } from "../../../uiHelpers/permissionsHelper";
import { ManufacturersType } from "../../../uiHelpers/manufacturersType";
import "./companyStructureView.scss";
import { CompanyStructureTreeView } from "./CompanyStructureTreeView";
import { CompanyStructureKemaroView } from "./CompanyStructureKemaroView";
import { updateTreeSubscriptionManager } from "./subscriptionManager";
import { useTranslation } from "react-i18next";

export const CompanyStructureView = (props: CompanyStructureViewProps) => {
  const { t } = useTranslation();

  const [isFirstTime, setIsFirstTime] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const [modalWorking, setModalWorking] = useState(false);

  const [canAdd, setCanAdd] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canRemove, setCanRemove] = useState(false);

  const [manufacturer, setManufacturer] = useState<ManufacturersType>("none");
  const [kemaroCompanyStructure, setKemaroCompanyStructure] = useState<
    KemaroCompanyStructureDto[]
  >([]);
  const [kemaroCompanyStructureCurrent, setKemaroCompanyStructureCurrent] =
    useState<KemaroCompanyStructureDto | undefined>(undefined);
  const [nodeList, setNodeList] = useState<CompanyStructure[]>([]);

  const bottomRef = useRef(null);

  useEffect(() => {
    const updateTreeSubscription = updateTreeSubscriptionManager
      .getObservable()
      .subscribe(() => {
        if (PermissionsHelper.currentManufacturer() === "kemaro")
          getKemaroList();
      });

    return () => {
      updateTreeSubscription.unsubscribe();
    };
  });

  useEffect(() => {
    setManufacturer(PermissionsHelper.currentManufacturer());
    setCanAdd(
      props.canEdit &&
        PermissionsHelper.allow("Customers", "Edit", "CompanyStructure", "Add")
    );
    setCanEdit(
      props.canEdit &&
        PermissionsHelper.allow("Customers", "Edit", "CompanyStructure", "Edit")
    );

    setCanRemove(
      props.canEdit &&
        PermissionsHelper.allow(
          "Customers",
          "Edit",
          "CompanyStructure",
          "Delete"
        )
    );
    updateTreeSubscriptionManager.setData(true);
  }, [props.canEdit]);

  const treeToList = (nodes: CompanyStructure[], nesting: number) => {
    let nodeList: CompanyStructure[] = [];

    nodes.forEach((x) => {
      nodeList.push(x);
      if (x.childNodes.length > 0)
        nodeList = nodeList.concat(treeToList(x.childNodes, nesting + 1));
    });

    return nodeList;
  };

  const getKemaroList = async () => {
    setIsFetching(true);

    try {
      const data = await KemaroCompanyStructureRequests.getStructure(
        props.idCompany
      );

      setKemaroCompanyStructure(data);
      const localNodeList = treeToList(data, 0);
      setNodeList(localNodeList);

      if (kemaroCompanyStructureCurrent) {
        setKemaroCompanyStructureCurrent(
          localNodeList.find(
            (x) => x.id === kemaroCompanyStructureCurrent.id
          ) as KemaroCompanyStructureDto
        );
      }

      if (isFirstTime && props.startIdLocation) {
        const node =
          localNodeList.filter((x) => x.id === props.startIdLocation)[0] ??
          undefined;

        if (node) {
          setKemaroCompanyStructureCurrent(node as KemaroCompanyStructureDto);

          if (bottomRef.current) {
            const divToShow = bottomRef.current as any;
            divToShow.scrollIntoView({ behavior: "smooth" });
          }
        }
      }

      setIsFirstTime(false);
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsFetching(false);
  };

  const nodeSelected = (item: KemaroCompanyStructureDto | undefined) => {
    setKemaroCompanyStructureCurrent(item);
  };

  const createKemaroCompanyStructure = async (
    dto: KemaroCompanyStructurePostDto
  ) => {
    setModalWorking(true);
    let result = false;

    try {
      await KemaroCompanyStructureRequests.create(dto);
      result = true;
      updateTreeSubscriptionManager.setData(true);
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setModalWorking(false);
    return result;
  };

  const modifyKemaroCompanyStructure = async (
    origin: KemaroCompanyStructurePostDto,
    modified: KemaroCompanyStructurePostDto,
    migrateAllMissions: boolean,
    migrateNewerMissions: boolean,
    migrateNewerFromMissions: Date | undefined
  ) => {
    let result = false;

    try {
      await KemaroCompanyStructureRequests.modified(
        props.idCompany,
        kemaroCompanyStructureCurrent!.id,
        origin,
        modified,
        migrateAllMissions,
        migrateNewerMissions,
        migrateNewerFromMissions
      );

      result = true;
      updateTreeSubscriptionManager.setData(true);
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    return result;
  };

  const deleteKemaroCompanyStructure = async (
    kemaroCompanyStructure: KemaroCompanyStructureDto
  ) => {
    let result = false;

    try {
      await KemaroCompanyStructureRequests.delete(
        props.idCompany,
        kemaroCompanyStructure!.id
      );

      updateTreeSubscriptionManager.setData(true);
      result = true;
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    return result;
  };

  const robotToolTip = (item: KemaroCompanyStructureDto) => {
    let text = "";

    if (item.robotsInNode > 0) {
      text += `${t("company.robotsInLocation")}: ${item.robotsInNode}`;
    }

    if (item.robotsInNode > 0 && item.robotsInBranch > 0) {
      text += ", ";
    }

    if (item.robotsInBranch > 0) {
      text += `${t("company.robotsInSublocation")}: ${item.robotsInBranch}`;
    }

    return text;
  };

  return (
    <div className="col-12 company-structure">
      <TitleWithAction
        title={t("company.listTitle")}
        titleContainerClassName="child-sub-title"
        tooltip={t("company.createLocation.tooltip")}
        showButton={canAdd}
        onClick={() => setModalOpen(true)}
      />

      {isFetching && <Loading isLoading={isFetching} />}

      {!isFetching && (
        <div className="row">
          <div className="col-4">
            <CompanyStructureTreeView
              idCompany={props.idCompany}
              kemaroCompanyStructure={kemaroCompanyStructure}
              kemaroCompanyStructureCurrent={kemaroCompanyStructureCurrent}
              manufacturer={manufacturer}
              nodeList={nodeList}
              modalOpen={modalOpen}
              modalWorking={modalWorking}
              onSelect={nodeSelected}
              toolTip={robotToolTip}
              onCreate={createKemaroCompanyStructure}
              onCancel={() => setModalOpen(false)}
            />
          </div>

          <div className="col-8" ref={bottomRef}>
            {kemaroCompanyStructureCurrent && (
              <CompanyStructureKemaroView
                data={kemaroCompanyStructureCurrent}
                idCompany={props.idCompany}
                canEdit={canEdit}
                canDelete={canRemove}
                nodeList={nodeList as KemaroCompanyStructureDto[]}
                toolTip={robotToolTip}
                onModify={modifyKemaroCompanyStructure}
                onDelete={deleteKemaroCompanyStructure}
                refresh={() => updateTreeSubscriptionManager.setData(true)}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

interface CompanyStructureViewProps {
  idCompany: string;
  canEdit: boolean;
  startIdLocation: string;
}

export interface CompanyStructure {
  id: string;
  name: string;
  childNodes: CompanyStructure[];
}
