import { PointSymbolProps } from "@nivo/line";

export const KemaroDashboardPointSymbol = ({
  size,
  color,
  borderWidth,
}: PointSymbolProps) => {
  return (
    <circle
      r={size / 2}
      fill={color}
      stroke={color}
      strokeWidth={borderWidth}
      style={{ pointerEvents: "none" }}
    />
  );
};
