import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import { Theme } from "@mui/material";

export const LocationIconDisplayer = ({
  isChild,
  isCurrentLocation,
  theme,
}: LocationIconDisplayerProps) => {
  if (!isChild) return <></>;

  if (isCurrentLocation) {
    return (
      <PlaceOutlinedIcon
        className="me-2 location-icon"
        color="primary"
        style={{ verticalAlign: "text-top" }}
      />
    );
  } else {
    return (
      <PlaceOutlinedIcon
        className="me-2 location-icon"
        style={{
          color: theme.palette.swarm?.general?.secondaryTitle,
          verticalAlign: "text-top",
        }}
      />
    );
  }
};

interface LocationIconDisplayerProps {
  isChild?: boolean;
  isCurrentLocation?: boolean;
  theme: Theme;
}
