import { useEffect, useState } from "react";
import { ApplicationBar } from "../../components/news/ApplicationBar";
import { Pagination } from "../../components/news/Pagination";
import { KemaroAdministrationTabsNav } from "../kemaro/administration/KemaroAdministrationTabsNav";
import { CrudItemsPanel } from "../../components/news/CrudItemsPanel";
import { TableGeneric } from "../../components/news/TableGeneric/TableGeneric";
import "./robotModels.scss";
import { PermissionsHelper } from "../../uiHelpers/permissionsHelper";
import { RobotModelsView } from "./RobotModelsView";
import { defaultModalState } from "../../uiHelpers/dtos/modalState";
import { RobotModelsModal } from "./RobotModelsModal";
import { useTranslation } from "react-i18next";
import { TableGenericColumn } from "../../components/news/TableGeneric/models";
import useTheme from "@mui/material/styles/useTheme";
import { useRobotModels } from "../../hooks";

export const RobotModels = () => {
  const { t } = useTranslation();
  const [canAdd, setCanAdd] = useState(false);
  useEffect(() => {
    setCanAdd(PermissionsHelper.allow("RobotModels", "Create"));
  }, []);
  const theme = useTheme();
  const {
    textSearchChange,
    createRobotModel,
    modifyEntity,
    deleteEntity,
    pageChange,
    items,
    currentItem,
    currentItemImage,
    isFetchingList,
    isFetchingItem,
    pagination,
    dataOrder,
    setDataOrder,
    setCurrentId,
    createModalState,
    setCreateModalState,
  } = useRobotModels();

  const columnsDefinition: TableGenericColumn[] = [
    {
      title: t("common.name"),
      property: "name",
    },
    {
      title: t("robotModel.linkedRobots"),
      headerClassName: "text-end",
      property: "robotsQuantity",
      itemClassName: "text-end",
      canSort: false,
    },
  ];

  return (
    <div className="page-content">
      <div className="page-header">
        <ApplicationBar
          title={t("applicationBar.administration.title")}
          search={textSearchChange}
        />
      </div>

      <KemaroAdministrationTabsNav currentTab="robot-models" />

      <div className="page-body d-flex robot-models-body">
        {
          <div
            className="robot-models-left-side"
            style={{
              borderRight:
                theme.palette.swarm?.robotModels?.bodyLeftSideBorderRight,
            }}
          >
            <CrudItemsPanel
              title={t("robotModel.listTitle")}
              createToolTip={t("robotModel.create.tooltip")}
              containerClassName="robot-models-list-items"
              isFetching={isFetchingList}
              items={items}
              canCreate={canAdd}
              onCreateClick={() =>
                setCreateModalState({ ...createModalState, isOpen: true })
              }
            >
              <TableGeneric
                columns={columnsDefinition}
                items={items ?? []}
                idProperty="id"
                headerClick={(header, itemsOrder) => setDataOrder(itemsOrder)}
                selectionChanged={(item) => setCurrentId(item.id)}
                itemsOrder={dataOrder}
                selectId={currentItem?.id}
                isAwaiting={isFetchingItem}
                captureKeyboard
              />
            </CrudItemsPanel>

            <Pagination
              containerClassName="robot-models-list-pager"
              pagination={pagination}
              isFetching={isFetchingList}
              onChange={pageChange}
            />

            {createModalState.isOpen && (
              <RobotModelsModal
                title={t("robotModel.create.title")}
                isOpenModal={createModalState.isOpen}
                onSave={(dto) => createRobotModel(dto)}
                onCancel={() => setCreateModalState(defaultModalState)}
                isWorking={createModalState.isWorking}
                dto={undefined}
                image={undefined}
              />
            )}
          </div>
        }

        <div className="view-container">
          {currentItem && (
            <RobotModelsView
              item={currentItem}
              image={currentItemImage}
              isFetching={isFetchingItem}
              onModify={modifyEntity}
              onDelete={deleteEntity}
            />
          )}
        </div>
      </div>
    </div>
  );
};
