import { Dialog, useTheme } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Loading } from "../../../../components/Loading";
import {
  DashboardEconomyFilterDto,
} from "../../../../../../robotcloud-shared/resource-models";
import {
  NavigateTo,
  tableGenericHandleKeyboardSubjectManager,
} from "../../../../components/news";
import { NumericFormat } from "react-number-format";
import { useKemaroRobotData } from "../../../../hooks";

export const KemaroDashboardEconomyRobotsModal = (
  props: KemaroDashboardEconomyRobotsModalProps
) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { isWorking, robots } = useKemaroRobotData(props.isCurrentWeek, props.filter);

  useEffect(() => {
    tableGenericHandleKeyboardSubjectManager.setData({
      caller: "KemaroDashboardEconomyRobotsModal",
      handleKeyboard: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeHandle = () => {
    tableGenericHandleKeyboardSubjectManager.setData({
      caller: "KemaroDashboardEconomyRobotsModal",
      handleKeyboard: false,
    });

    props.onCancel();
  };

  return (
    <Dialog
      className="application-modal"
      open={true}
      maxWidth="lg"
      onClose={closeHandle}
    >
      <div className="modal-content kemaro-dashboard-economy-robots-modal">
        <div className="application-modal-header">
          <table className="w-100">
            <tbody>
              <tr>
                <td>
                  {props.isCurrentWeek && (
                    <h3>{t("dashboard.robotsModal.titleCurrentWeek")}</h3>
                  )}
                  {!props.isCurrentWeek && (
                    <h3>{t("dashboard.robotsModal.titleLifetime")}</h3>
                  )}
                </td>
                <td className=" text-end"></td>
              </tr>
            </tbody>
          </table>
        </div>
        {isWorking && (
          <div className="application-modal-body">
            <Loading isLoading={isWorking} />
          </div>
        )}

        {!isWorking && (
          <div className="application-modal-body pb-3">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th></th>
                  <th className="px-3">{t("performance.robot")}</th>
                  <th className="px-3">{t("performance.location")}</th>
                  <th className="px-3">{t("performance.totalAreaCleaned")}</th>
                  <th className="px-3">
                    {t("performance.totalTimeInCleaning")}
                  </th>
                  <th className="px-3">
                    {t("performance.cleaningPerformance")}
                  </th>
                  <th className="px-3">{t("performance.totalMissions")}</th>
                </tr>
              </thead>
              <tbody>
                {robots.map((robot) => (
                  <tr>
                    <td className="px-3 py-0 pt-2">
                      {!robot.isDecommissioned && (
                        <NavigateTo
                          text=""
                          toolTipIsSingular={true}
                          shorCut="robots"
                          paramters={[{ name: "id", value: robot.id }]}
                        />
                      )}
                    </td>
                    <td className="px-3">
                      {robot.name}{" "}
                      {robot.isDecommissioned && (
                        <span
                          className="ps-2"
                          style={{
                            color:
                              theme.palette.swarm?.kemaroDashboard
                                ?.robotsModalDecommissioned,
                          }}
                        >
                          (Decommissioned)
                        </span>
                      )}
                    </td>
                    <td className="px-3">{robot.locationName}</td>
                    <td className="text-end px-3">
                      <NumericFormat
                        value={robot.totalAreaCleaned}
                        displayType={"text"}
                        thousandSeparator={"'"}
                      />{" "}
                      m<sup>2</sup>
                    </td>
                    <td className="text-end px-3">
                      {Math.round(robot.totalTimeInCleaning / 60)}
                      {" h"}
                    </td>
                    <td className="text-end px-3">
                      {robot.cleaningPerformance} m<sup>2</sup>/h
                    </td>
                    <td className="text-end px-3">{robot.totalMissions}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </Dialog>
  );
};

interface KemaroDashboardEconomyRobotsModalProps {
  onCancel: () => void;
  isCurrentWeek: boolean;
  filter: DashboardEconomyFilterDto;
}

