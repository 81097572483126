import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import KemaroRobotContext from "../KemaroRobotContext";
import {
  ToastHelper,
  TooltipButton,
  YesNoOkDialog,
} from "../../../../components/news";
import DeleteForever from "@mui/icons-material/DeleteForever";
import { KemaroRobotRequests } from "../../../../data/kemaro/kemaroRobot.request";
import { ErrorHelper } from "../../../../uiHelpers/errors.helper";

export const DeleteButton = () => {
  const { t } = useTranslation();
  const {
    currentItem,
    refreshItems,
    setViewSideModalOpenChange,
    getNextItem,
    getById,
    setCurrentItem,
  } = useContext(KemaroRobotContext);

  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [isWorking, setIsWorking] = useState(false);

  const openEditPopupPopupChange = (value: boolean) => {
    setOpenDeletePopup(value);
    setViewSideModalOpenChange(value);
  };

  const handleDeleteYes = async () => {
    setIsWorking(true);

    let selectFirstItem = false;

    try {
      await KemaroRobotRequests.delete(currentItem!.id!);
      const nextItem = getNextItem(currentItem!.id!);
      if (nextItem && nextItem.id !== currentItem!.id!) {
        getById(nextItem.id!);
      } else if (!nextItem) {
        setCurrentItem(undefined);
        selectFirstItem = true;
      }
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    refreshItems(null, false, selectFirstItem);

    openEditPopupPopupChange(false);
    setIsWorking(false);
  };

  return (
    <>
      <TooltipButton
        aria-label={t("robot.delete.tooltip")}
        tooltip={t("robot.delete.tooltip")}
        onClick={() => openEditPopupPopupChange(true)}
      >
        <DeleteForever color="error" />
      </TooltipButton>

      <YesNoOkDialog
        open={openDeletePopup}
        size="md"
        type="yesNo"
        title={t("robot.delete.title")}
        message={`${t("robot.delete.warning")} ${currentItem!.name}?`}
        onYes={handleDeleteYes}
        onNo={() => openEditPopupPopupChange(false)}
        isWorking={isWorking}
      />
    </>
  );
};
