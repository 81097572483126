import { Button, Dialog, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { DealerPostDto } from "../../../../robotcloud-shared/resource-models";
import { Loading } from "../../components/Loading";
import { TextInput } from "../../components/news/TextInput";
import { GeneralFunctions } from "../generalFunctions";
import { useTranslation } from "react-i18next";
import { tableGenericHandleKeyboardSubjectManager } from "../../components/news";
import { SupRemark } from "../../uiHelpers/SupRemark";

export const DealerCreateModal = (props: dealerCreateModalProps) => {
  const { t } = useTranslation();

  const [postDto, setPostDto] = useState<DealerPostDto>({ name: "" });

  useEffect(() => {
    if (!props.isWorking && !props.openPopup && postDto.name.length > 0) {
      setPostDto({ name: "" });
    }
  }, [props, postDto]);

  useEffect(() => {
    tableGenericHandleKeyboardSubjectManager.setData({
      caller: "DealerCreateModal",
      handleKeyboard: props.openPopup,
    });
  }, [props.openPopup]);

  const handleCancel = () => {
    setPostDto({ name: "" });
    props.onCancel();
  };

  const editName = (newName: string) => {
    const newPostDto = {
      ...postDto,
      name: newName,
    };

    setPostDto(newPostDto);
  };

  return (
    <Dialog
      className="application-modal"
      open={props.openPopup}
      maxWidth="md"
      onClose={handleCancel}
    >
      <div className="modal-content">
        <Stack>
          <Box className="application-modal-header">
            <h3>{props.title}</h3>
          </Box>
          {props.isWorking ? (
            <Box className="application-modal-body">
              <Loading isLoading={props.isWorking} />
            </Box>
          ) : (
            <>
              <Box className="application-modal-body">
                <Grid container>
                  <Grid className="input-label" item xs={4}>
                    {t("common.name")}
                    <SupRemark />
                  </Grid>
                  <Grid item xs={8}>
                    <TextInput value={postDto.name} ret={editName} />
                  </Grid>
                </Grid>
              </Box>
              <Box className="application-modal-footer">
                <Button
                  variant="contained"
                  className="button-with-text"
                  disabled={GeneralFunctions.isBlank(postDto.name)}
                  color="primary"
                  onClick={() => props.onSave(postDto)}
                >
                  {t("common.buttons.save")}
                </Button>

                <Button
                  className="modal-cancel-button button-with-text"
                  variant="outlined"
                  color="primary"
                  onClick={handleCancel}
                >
                  {t("common.buttons.cancel")}
                </Button>
              </Box>
            </>
          )}
        </Stack>
      </div>
    </Dialog>
  );
};

export interface dealerCreateModalProps {
  title: string;
  openPopup: boolean;
  isWorking: boolean;
  onSave: (postDto: DealerPostDto) => void;
  onCancel: () => void;
}
