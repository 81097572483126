export interface ChartSize {
  height: number;
  width: number;
  XlWidth: number;
  XxlWidth: number;
}

export const defaultChartSize: ChartSize = {
  height: 185,
  width: 900,
  XlWidth: 2500,
  XxlWidth: 2300,
};
