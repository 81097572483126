import { useContext } from "react";
import { KemaroRobotViewInformation } from "./KemaroRobotViewInformation";
import { KemaroRobotViewTitle } from "./KemaroRobotViewTitle";
import KemaroRobotContext from "./KemaroRobotContext";

export const KemaroRobotView = ({
  connectionModalOpen,
  setConnectionModalOpen,
}: {
  connectionModalOpen: boolean;
  setConnectionModalOpen: (open: boolean) => void;
}) => {
  const { currentItem } = useContext(KemaroRobotContext);

  if (!currentItem) {
    return <></>;
  }

  return (
    <div className="kemaro-robot-view-container">
      <KemaroRobotViewTitle
        connectionModalOpen={connectionModalOpen}
        setConnectionModalOpen={setConnectionModalOpen}
      />

      <KemaroRobotViewInformation />
    </div>
  );
};
