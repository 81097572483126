import { TooltipButton } from "./TooltipButton";
import InsertDriveFileOutlined from "@mui/icons-material/InsertDriveFileOutlined";
import { useTranslation } from "react-i18next";

export const TooltipCopyButton = ({
  textToCopy,
  className,
}: CopyButtonProps) => {
  const { t } = useTranslation();

  const copyToClipboard = async () => {
    navigator.clipboard.writeText(textToCopy);
  };

  return (
    <TooltipButton
      aria-label={t("common.buttons.copyToclipboard")}
      tooltip={t("common.buttons.copyToclipboard")}
      onClick={copyToClipboard}
      className={className}
    >
      <InsertDriveFileOutlined color="primary" />
    </TooltipButton>
  );
};

interface CopyButtonProps {
  textToCopy: string;
  className?: string;
}
