import { Button, Dialog } from "@mui/material";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Loading } from "../../../../../components/Loading";
import { TextInput, ToastHelper } from "../../../../../components/news";
import { KemaroRobotPostDto } from "../../../../../../../robotcloud-shared/resource-models";
import { RobotModelSearch } from "../../../../../components/search/RobotModelSearch";
import { DealerSearch } from "../../../../../components/search/DealerSearch";
import { GeneralFunctions } from "../../../../generalFunctions";
import { KemaroRobotRequests } from "../../../../../data/kemaro/kemaroRobot.request";
import { ErrorHelper } from "../../../../../uiHelpers/errors.helper";
import KemaroRobotContext from "../../KemaroRobotContext";
import { SupRemark } from "../../../../../uiHelpers/SupRemark";

export const KemaroRobotModal = (props: KemaroRobotModalProps) => {
  const [isWorking, setIsWorking] = useState(false);
  const [item, setItem] = useState<KemaroRobotPostDto>({
    ...kemaroRobotPostDtoDefault,
  });

  const { t } = useTranslation();

  const { getById, refreshItems } = useContext(KemaroRobotContext);

  const handleSave = async () => {
    setIsWorking(true);

    try {
      const result = await KemaroRobotRequests.create(item);
      await getById(result.id);
      refreshItems(undefined, false, false);

      handleClose();
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsWorking(false);
  };

  const handleClose = () => {
    props.onClose();
  };

  const updateSerialNumberAndName = (newText: string) => {
    if (item.serialNumber === item.name)
      setItem({ ...item, serialNumber: newText, name: newText });
    else setItem({ ...item, serialNumber: newText });
  };

  return (
    <Dialog
      className="application-modal"
      open={true}
      maxWidth="md"
      onClose={handleClose}
    >
      <div className="modal-content">
        <div className="application-modal-header">
          <h3>{t("robot.create.title")}</h3>
        </div>
        <div className="application-modal-body kemro-robot-modal">
          {isWorking && (
            <div className="row mt-2 mb-5">
              <Loading isLoading={true} />
            </div>
          )}

          {!isWorking && (
            <div className="row">
              <div className="data-div input-label col-4">
                {t("robot.serialNumber")}
                <SupRemark />
              </div>
              <div className="data-div col-8">
                <TextInput
                  value={item.serialNumber}
                  ret={(x) => updateSerialNumberAndName(x)}
                />
              </div>

              <div className="data-div input-label col-4">
                {t("robot.name")}
                <SupRemark />
              </div>
              <div className="data-div col-8">
                <TextInput
                  value={item.name}
                  ret={(x) => setItem({ ...item, name: x })}
                />
              </div>

              <div className="data-div input-label col-4">
                {t("robot.model")}
                <SupRemark />
              </div>
              <div className="data-div col-8">
                <RobotModelSearch
                  manufacturerId={""}
                  currentId={item.idRobotModel ?? ""}
                  selectionChange={(x) =>
                    setItem({ ...item, idRobotModel: x?.id ?? "" })
                  }
                />
              </div>
              <div className="data-div input-label col-4">
                {t("robot.dealer")}
              </div>
              <div className="data-div col-8">
                <DealerSearch
                  currentId={item.idDealer ?? ""}
                  selectionChange={(x) =>
                    setItem({ ...item, idDealer: x?.id ?? "" })
                  }
                />
              </div>
            </div>
          )}
        </div>

        {!isWorking && (
          <div className="application-modal-footer">
            <Button
              variant="contained"
              className="button-with-text me-3"
              disabled={
                GeneralFunctions.isBlank(item.name) ||
                GeneralFunctions.isBlank(item.serialNumber) ||
                GeneralFunctions.isBlank(item.idRobotModel)
              }
              color="primary"
              onClick={handleSave}
            >
              {t("common.buttons.save")}
            </Button>

            <Button
              className="modal-cancel-buttonv button-with-text"
              variant="outlined"
              color="primary"
              onClick={handleClose}
            >
              {t("common.buttons.cancel")}
            </Button>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export interface KemaroRobotModalProps {
  onClose: () => void;
}

const kemaroRobotPostDtoDefault: KemaroRobotPostDto = {
  name: "",
  serialNumber: "",
  idRobotModel: "",
  idDealer: null,
};
