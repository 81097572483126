import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import KemaroRobotContext from "../../KemaroRobotContext";
import { KemaroRobotRequests } from "../../../../../data/kemaro/kemaroRobot.request";
import { TextInput, ToastHelper } from "../../../../../components/news";
import { ErrorHelper } from "../../../../../uiHelpers/errors.helper";
import { Button, Dialog } from "@mui/material";
import { Loading } from "../../../../../components/Loading";
import { GeneralFunctions } from "../../../../generalFunctions";
import { SupRemark } from "../../../../../uiHelpers/SupRemark";

export const KemaroRobotNameModal = (props: KemaroRobotNameModalProps) => {
  const [isWorking, setIsWorking] = useState(false);
  const [name, setName] = useState("");

  const { t } = useTranslation();

  const { refreshItems, refreshItem, currentItem } =
    useContext(KemaroRobotContext);

  useEffect(() => {
    setName(currentItem?.name ?? "");
  }, [currentItem]);

  const handleSave = async () => {
    setIsWorking(true);

    try {
      await KemaroRobotRequests.editName(currentItem!, name);

      refreshItem();
      refreshItems(undefined, false, false);

      props.onClose();
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsWorking(false);
  };

  const handleClose = () => {
    props.onClose();
  };

  return (
    <Dialog
      className="application-modal"
      open={true}
      maxWidth="md"
      onClose={handleClose}
    >
      <div className="modal-content">
        <div className="application-modal-header">
          <h3>{t("robot.editName.title")}</h3>
        </div>
        <div className="application-modal-body kemro-robot-modal">
          {isWorking && (
            <div className="row mt-2 mb-5">
              <Loading isLoading={true} />
            </div>
          )}

          {!isWorking && (
            <div className="row">
              <div className="data-div input-label col-4">
                {t("robot.name")}
                <SupRemark />
              </div>
              <div className="data-div col-8">
                <TextInput value={name} ret={(x) => setName(x)} />
              </div>
            </div>
          )}
        </div>

        {!isWorking && (
          <div className="application-modal-footer">
            <Button
              variant="contained"
              className="button-with-text me-3"
              disabled={GeneralFunctions.isBlank(name)}
              color="primary"
              onClick={handleSave}
            >
              {t("common.buttons.save")}
            </Button>

            <Button
              className="modal-cancel-buttonv button-with-text"
              variant="outlined"
              color="primary"
              onClick={handleClose}
            >
              {t("common.buttons.cancel")}
            </Button>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export interface KemaroRobotNameModalProps {
  onClose: () => void;
}
