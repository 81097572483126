import { useTheme } from "@mui/material";

export const RobotConnectionServiceUrlLink = ({
  url,
}: RobotConnectionServiceUrlLinkProps) => {
  const theme = useTheme();
  return (
    <p>
      <a
        href={url}
        target="_blank"
        rel="noreferrer"
        style={{ textDecoration: "none" }}
      >
        {url}
      </a>
    </p>
  );
};

interface RobotConnectionServiceUrlLinkProps {
  url: string;
}
