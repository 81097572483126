import { KemaroRobotListDto } from "../../../../../robotcloud-shared/resource-models";
import { TooltipContainer } from "../../../components/news";
import i18n from "i18next";
import WifiIcon from "@mui/icons-material/Wifi";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import BugReportOutlinedIcon from "@mui/icons-material/BugReportOutlined";
import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";
import NewReleasesOutlinedIcon from "@mui/icons-material/NewReleasesOutlined";
import { PermissionsHelper } from "../../../uiHelpers/permissionsHelper";
import { TableGenericColumn } from "../../../components/news/TableGeneric/models";

export const columnsDefinition = (
  errorSummaryTotalDaysToAnalyze: number,
  errorSummaryRecentDaysToAnalyze: number
) => {
  const showTags = PermissionsHelper.allow("KemaroRobots", "View", "Tags");
  const showHasActivationDate = PermissionsHelper.allow(
    "KemaroRobots",
    "View",
    "ListViewHasActivationDate"
  );

  let result: TableGenericColumn[] = [
    {
      itemClassName: "ps-0",
      headerClassName: "ps-0",
      title: i18n.t("robot.name")!,
      property: "name",
    },
    {
      title: i18n.t("robot.companyStructure")!,
      property: "location",
    },
    {
      title: i18n.t("robot.customer")!,
      property: "customerName",
    },
  ];

  if (showTags) {
    result = [
      {
        title: "",
        headerClassName: "px-2 td-1-icon text-center",
        titleTemplate: (
          <LabelOutlinedIcon
            className="primary-text"
            sx={{ color: "swarm.general.primaryText" }}
          />
        ),
        property: "tags",
        itemClassName: "td-1-icon px-1 text-center ",
        cellTemplate: (item: KemaroRobotListDto) => (
          <>
            {item.tags.length > 0 && (
              <TooltipContainer
                text={item.tags.toString().replaceAll(",", ", ")}
              >
                <LabelOutlinedIcon
                  className="out-threshold"
                  sx={{ color: "swarm.kemaroRobots.outThreshold" }}
                />
              </TooltipContainer>
            )}
          </>
        ),
      },
      ...result,
    ];
  }

  if (showHasActivationDate) {
    result = [
      {
        title: "",
        headerClassName: "px-2 td-1-icon text-center",
        titleTemplate: (
          <NewReleasesOutlinedIcon
            className="primary-text"
            sx={{ color: "swarm.general.primaryText" }}
          />
        ),
        property: "hasActivationDate",
        itemClassName: "td-1-icon px-1 text-center ",
        cellTemplate: (item: KemaroRobotListDto) => (
          <>
            {!item.hasActivationDate && (
              <TooltipContainer
                text={i18n.t("robot.list.activationDateDisabled")!}
              >
                <NewReleasesOutlinedIcon
                  className="out-threshold"
                  sx={{ color: "swarm.kemaroRobots.outThreshold" }}
                />
              </TooltipContainer>
            )}
          </>
        ),
      },
      ...result,
    ];
  }

  result = [
    {
      title: "",
      titleTemplate: (
        <BugReportOutlinedIcon
          className="primary-text"
          sx={{ color: "swarm.general.primaryText" }}
        />
      ),
      headerClassName: "px-2 td-1-icon text-center",
      property: "errorSummary",
      itemClassName: "td-1-icon px-1 text-center",
      cellTemplate: (item: KemaroRobotListDto) => (
        <>
          {item.errorSummary.state === "Old" && (
            <TooltipContainer
              content={
                <table className="tool-tip-text">
                  <tbody>
                    <tr>
                      <td className="no-wrap text-center">
                        {
                          i18n.t("robot.errorSummary.oldQuantity", {
                            oldQuantity: item.errorSummary.oldQuantity,
                          }) as String
                        }
                      </td>
                    </tr>
                    <tr>
                      <td className="no-wrap text-center">
                        {
                          i18n.t("robot.errorSummary.totalDays", {
                            totalDays: errorSummaryTotalDaysToAnalyze,
                          }) as String
                        }
                      </td>
                    </tr>
                  </tbody>
                </table>
              }
            >
              <BugReportOutlinedIcon
                className="bug-old"
                sx={{ color: "swarm.kemaroRobots.bugOld" }}
              />
            </TooltipContainer>
          )}
          {item.errorSummary.state === "Recent" && (
            <TooltipContainer
              content={
                <table className="tool-tip-text">
                  <tbody>
                    <tr>
                      <td className="no-wrap text-center">
                        {
                          i18n.t("robot.errorSummary.totalQuantity", {
                            totalQuantity:
                              item.errorSummary.recentQuantity +
                              item.errorSummary.oldQuantity,
                            totalDays: errorSummaryTotalDaysToAnalyze,
                          }) as String
                        }
                      </td>
                    </tr>
                    <tr>
                      <td className="no-wrap text-center">
                        <strong>
                          {
                            i18n.t("robot.errorSummary.recentQuantity", {
                              recentQuantity: item.errorSummary.recentQuantity,
                            }) as String
                          }
                          {errorSummaryRecentDaysToAnalyze > 1
                            ? (i18n.t("robot.errorSummary.recentDays", {
                                recentDays: errorSummaryRecentDaysToAnalyze,
                              }) as String)
                            : (i18n.t("robot.errorSummary.lastDay") as String)}
                        </strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              }
            >
              <BugReportOutlinedIcon
                className="bug-recent"
                sx={{ color: "swarm.kemaroRobots.bugRecent" }}
              />
            </TooltipContainer>
          )}
        </>
      ),
    },
    ...result,
  ];

  result = [
    {
      title: "",
      titleTemplate: (
        <WifiIcon
          className="primary-text"
          sx={{ color: "swarm.general.primaryText" }}
        />
      ),
      property: "isOnline",
      headerClassName: "px-2 td-1-icon text-center",
      itemClassName: "td-1-icon px-1 text-center",
      cellTemplate: (item: KemaroRobotListDto) => (
        <>
          {item.isOnline ? (
            <TooltipContainer text={i18n.t("robot.isOnline.yes")!}>
              <WifiIcon sx={{ color: "swarm.kemaroRobots.onThreshold" }} />
            </TooltipContainer>
          ) : (
            <TooltipContainer text={i18n.t("robot.isOnline.no")!}>
              <WifiOffIcon sx={{ color: "swarm.kemaroRobots.outThreshold" }} />
            </TooltipContainer>
          )}
        </>
      ),
    },
    ...result,
  ];

  return result;
};
