import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import { useEffect, useState } from "react";
import {
  UserCompleteDto,
  UserRoles,
} from "../../../../../robotcloud-shared/resource-models";
import { Loading } from "../../../components/Loading";
import { TextInput } from "../../../components/news/TextInput";
import { TimeZoneSearch } from "../../../components/search/TimeZoneSearch";
import { GeneralFunctions } from "../../generalFunctions";
import "./userModals.scss";
import { UserAccessTypeSelector } from "./UserAccessTypeSelector";
import { useTranslation } from "react-i18next";
import { tableGenericHandleKeyboardSubjectManager } from "../../../components/news";
import { SupRemark } from "../../../uiHelpers/SupRemark";

export const UsersCurrentModifyModal = (
  props: usersCurrentModifyModalProps
) => {
  const { t } = useTranslation();

  const [user, setUser] = useState<UserCompleteDto>(props.user);

  useEffect(() => {
    setUser(props.user);
  }, [props.user]);

  useEffect(() => {
    tableGenericHandleKeyboardSubjectManager.setData({
      caller: "UsersCurrentModifyModal",
      handleKeyboard: props.isOpenModal,
    });
  }, [props.isOpenModal]);

  const handleCancel = () => {
    setUser(props.user);
    props.onCancel();
  };

  const isExternalUser = () => {
    return !user.userName?.endsWith("@eb8.ch");
  };

  return (
    <Dialog
      className="application-modal"
      open={props.isOpenModal}
      maxWidth="md"
      onClose={handleCancel}
    >
      <Box className="modal-content">
        <Stack>
          <div className="application-modal-header">
            <table className="table table-borderless mb-0">
              <tbody>
                <tr>
                  <td className="w-100">
                    <h3 className="modal-title px-0">{t("user.edit.title")}</h3>
                  </td>
                  <td className="no-wrap default-font-size  px-0">
                    {!props.isWorking && (
                      <>
                        <span className="me-2">{t("user.enabled")}</span>
                        <Switch
                          color="primary"
                          checked={user.enabled}
                          onChange={(event) =>
                            setUser({ ...user, enabled: event.target.checked })
                          }
                        />
                      </>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="application-modal-body">
            {props.isWorking || !user ? (
              <Loading isLoading={props.isWorking} />
            ) : (
              <div className="row">
                <div className="col-4 input-label">
                  {t("user.firstName")}
                  <SupRemark />
                </div>
                <div className="col-8">
                  <TextInput
                    value={user.firstName}
                    ret={(x) => setUser({ ...user, firstName: x })}
                  />
                </div>

                <div className="col-4 input-label">
                  {t("user.lastName")}
                  <SupRemark />
                </div>
                <div className="col-8">
                  <TextInput
                    value={user.lastName}
                    ret={(x) => setUser({ ...user, lastName: x })}
                  />
                </div>

                {isExternalUser() && (
                  <>
                    <div className="col-4 input-label">
                      {t("user.externalId")}
                      <SupRemark />
                    </div>
                    <div className="col-8">
                      <TextInput
                        value={user.externalId ?? undefined}
                        ret={(x) => setUser({ ...user, externalId: x })}
                      />
                    </div>
                  </>
                )}

                <div className="col-4 input-label">
                  {t("common.role")}
                  <SupRemark />
                </div>
                <div className="col-8">
                  <Autocomplete
                    size="small"
                    freeSolo
                    onChange={(event, newValue) =>
                      setUser({
                        ...user,
                        role: (newValue as UserRoles) ?? "Member",
                      })
                    }
                    value={user.role}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label=""
                        placeholder={t("common.typeToSearch")}
                        fullWidth
                        variant="standard"
                        margin="dense"
                        hiddenLabel
                      />
                    )}
                    options={["Owner", "Administrator", "Member"]}
                  />
                </div>

                {!isExternalUser() && (
                  <>
                    <div className="col-4 input-label">{t("user.email")}</div>
                    <div className="col-8">
                      <TextInput
                        value={user.passwordResetEmailAddress}
                        ret={(x) =>
                          setUser({ ...user, passwordResetEmailAddress: x })
                        }
                      />
                    </div>
                  </>
                )}

                <div className="col-4 input-label">{t("user.timeZone")}</div>
                <div className="col-8">
                  <TimeZoneSearch
                    currentId={user.windowsTimeZoneId}
                    selectionChange={(x) =>
                      setUser({
                        ...user,
                        windowsTimeZoneId: x?.timeZoneInfoId ?? "",
                      })
                    }
                  />
                </div>

                <UserAccessTypeSelector
                  accessTypes={[
                    "Manufacturer",
                    "Dealer",
                    "Customer",
                    "Unassigned",
                    "Earlybyte",
                  ]}
                  accessType={user.accessType}
                  accessTypeChange={(x) => setUser({ ...user, accessType: x })}
                  accessTypeId={user.accessId}
                  accessTypeIdChange={(x) => setUser({ ...user, accessId: x })}
                />
              </div>
            )}
          </div>
          {props.isWorking || !user ? (
            <div className="application-modal-footer"></div>
          ) : (
            <div className="application-modal-footer">
              <Button
                variant="contained"
                className="button-with-text"
                disabled={
                  GeneralFunctions.isBlank(user.firstName) ||
                  GeneralFunctions.isBlank(user.lastName)
                }
                color="primary"
                onClick={() => props.onSaved(user)}
              >
                {t("common.buttons.save")}
              </Button>

              <Button
                className="modal-cancel-button button-with-text"
                variant="outlined"
                color="primary"
                onClick={handleCancel}
              >
                {t("common.buttons.cancel")}
              </Button>
            </div>
          )}
        </Stack>
      </Box>
    </Dialog>
  );
};

export interface usersCurrentModifyModalProps {
  user: UserCompleteDto;
  isOpenModal: boolean;
  isWorking: boolean;
  onCancel: () => void;
  onSaved: (user: UserCompleteDto) => void;
}
