import { useState, useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { ProcessLinkDto } from "../../../robotcloud-shared/resource-models";
import AuthorizationContext from "../views/auth/authorizationContext";
import {
  Process,
  getProcessesFromLink,
  getPendingProcesses,
  getPendingCompanyComplianceProcesses,
} from "../views/onBoarding/onBoardingService";
import { ToastHelper } from "../components/news";
import { ErrorHelper } from "../uiHelpers/errors.helper";

interface UseOnboardingProcessesReturn {
  pendingProcesses: Process[];
  processLink?: ProcessLinkDto;
}

export const useOnboardingProcesses = (): UseOnboardingProcessesReturn => {
  const [pendingProcesses, setPendingProcesses] = useState<Process[]>([]);
  const [processLink, setProcessLink] = useState<ProcessLinkDto | undefined>();
  const { me } = useContext(AuthorizationContext);
  const [searchParams] = useSearchParams();
  const processLinkIdKey = "ProcessLinkId";

  useEffect(() => {
    const processLinkId =
      searchParams.get(processLinkIdKey) ||
      localStorage.getItem(processLinkIdKey);

    if (me && processLinkId) {
      getProcessesFromLink(processLinkId, me.language)
        .then((result) => {
          setPendingProcesses([result]);
          const link = result.processLinks.find(
            (link) => link.id === processLinkId
          );
          setProcessLink(link);
        })
        .catch((error) => ToastHelper.errors(ErrorHelper.process(error)));
    } else if (me) {
      getPendingProcesses(me.language)
        .then((result) => {
          if (me.role === "Member") {
            getPendingCompanyComplianceProcesses(me)
              .then((complianceResult) => {
                setPendingProcesses([...result, ...complianceResult]);
              })
              .catch((error) =>
                console.error(
                  "Failed to load company compliance processes",
                  error
                )
              );
          } else {
            setPendingProcesses(result);
          }
        })
        .catch((error) =>
          console.error("Failed to load pending processes", error)
        );
    }
  }, [me, searchParams]);

  return { pendingProcesses, processLink };
};
