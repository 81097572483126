import { useContext, useState, useEffect } from "react";
import {
  CustomerDto,
  CustomerCompleteDto,
  CustomerFilterCriteriaDto,
  ApiLoginCompleteDto,
  CustomerPostDto,
} from "../../../robotcloud-shared/resource-models";
import { ToastHelper } from "../components/news";
import { PaginationDto, paginationDtoDefault, OrderDto } from "../data/common";
import { CustomersRequests } from "../data/customers.request";
import { ErrorHelper } from "../uiHelpers/errors.helper";
import { QueryParamHelper } from "../uiHelpers/queryParamHelper";
import AuthorizationContext from "../views/auth/authorizationContext";
import { GeneralFunctions } from "../views/generalFunctions";

export const useCustomers = () => {
  const { me } = useContext(AuthorizationContext);
  const [isFetchingList, setIsFetchingList] = useState(false);
  const [isFetchingItem, setIsFetchingItem] = useState(false);
  const [pagination, setPagination] = useState<PaginationDto>({
    ...paginationDtoDefault,
  });
  const [dataOrder, setDataOrder] = useState<OrderDto>({
    orderColumns: "name",
    order: "Asc",
  });
  const [items, setItems] = useState<CustomerDto[] | undefined>(undefined);
  const [currentId, setCurrentId] = useState<string | undefined>(undefined);
  const [currentItem, setCurrentItem] = useState<
    CustomerCompleteDto | undefined
  >(undefined);
  const [openCreatePopup, setOpenCreatePopup] = useState(false);
  const [textToSearch, setTextToSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [lastSearch, setLastSearch] = useState<string>("");
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [idLocation, setIdLocation] = useState("");

  useEffect(() => {
    let filterCriteria: CustomerFilterCriteriaDto = {
      page: pagination.currentPage,
      maxItemCount: pagination.maxItemCount,
      orderBy: dataOrder.order,
      orderColumns: dataOrder.orderColumns,
      textToSearch: textToSearch,
      id: null,
      idKemaroDashboardEconomyCostCalculations: null,
      idDealer: null,
    };

    const strFilterCriteria = JSON.stringify(filterCriteria);
    if (lastSearch !== strFilterCriteria) {
      if (isFirstTime) {
        const qph = new QueryParamHelper(window.location.search);
        filterCriteria.id = qph.getValue("id") ?? null;
        if (qph.getValue("idKemaroDashboardEconomyCostCalculation")) {
          filterCriteria.idKemaroDashboardEconomyCostCalculations = [
            qph.getValue("idKemaroDashboardEconomyCostCalculation")!,
          ];
        }

        setIdLocation(qph.getValue("idLocation") ?? "");
      }

      setLastSearch(strFilterCriteria);
      getList(filterCriteria, pagination, me, currentItem, false);
      setIsFirstTime(false);
    }
  }, [
    pagination,
    dataOrder,
    textToSearch,
    me,
    lastSearch,
    isFirstTime,
    currentItem,
  ]);

  useEffect(() => {
    if (currentId) {
      getCustomer(isFetchingItem, currentItem, currentId);
    }
  }, [currentId, currentItem, isFetchingItem]);

  const getCustomer = async (
    isFetching: boolean,
    ci: CustomerCompleteDto | undefined,
    id: string
  ) => {
    if (isFetching || ci?.id === id) return;

    setIsFetchingItem(true);

    try {
      const item = await CustomersRequests.getByIdComplete(id!);
      setCurrentItem(item);
      setCurrentId(item.id);
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsFetchingItem(false);
  };

  const callGetList = async (selectFirstItem: boolean) => {
    let filterCriteria: CustomerFilterCriteriaDto = {
      page: pagination.currentPage,
      maxItemCount: pagination.maxItemCount,
      orderBy: dataOrder.order,
      orderColumns: dataOrder.orderColumns,
      textToSearch: textToSearch,
      id: null,
      idKemaroDashboardEconomyCostCalculations: null,
      idDealer: null,
    };

    await getList(filterCriteria, pagination, me, currentItem, selectFirstItem);
  };

  const getList = async (
    filterCriteria: CustomerFilterCriteriaDto,
    page: PaginationDto,
    me: ApiLoginCompleteDto | undefined,
    ci: CustomerCompleteDto | undefined,
    selectFirstItem: boolean
  ) => {
    setIsFetchingList(true);

    try {
      const data = await CustomersRequests.getList(filterCriteria);
      setItems(data.items);

      if (data.items.length > 0) {
        if (selectFirstItem || !ci || !data.items.some((x) => x.id === ci.id)) {
          setCurrentId(data.items[0].id);
        }
      }

      GeneralFunctions.ChangePagination(page, data, setPagination);

      if (filterCriteria.id) {
        setCurrentId(filterCriteria.id);
      } else if (me?.userAccessType === "Customer") {
        setCurrentId(me.idCustomer!);
      }
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsFetchingList(false);
  };

  const handleEdit = async (item: CustomerCompleteDto) => {
    setIsFetchingItem(true);

    try {
      await CustomersRequests.modify(currentItem!, item);
      setCurrentItem(await CustomersRequests.getByIdComplete(currentId!));
      callGetList(false);
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsFetchingItem(false);
  };

  const handleDelete = async (item: CustomerCompleteDto) => {
    setIsFetchingItem(true);
    let selectFirstItem = false;

    try {
      await CustomersRequests.softDelete(item.id);

      const nextItem = GeneralFunctions.nextItemFromArray<CustomerDto>(
        items,
        item.id
      );
      setCurrentId(nextItem?.id ?? undefined);
      if (nextItem) await getCustomer(false, undefined, nextItem.id);
      else {
        selectFirstItem = true;
        setCurrentItem(undefined);
      }

      callGetList(selectFirstItem);
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsFetchingItem(false);
  };

  const handleCreateSave = async (postDto: CustomerPostDto) => {
    setIsLoading(true);
    setIsFetchingList(true);

    try {
      const customerDto = await CustomersRequests.create(postDto);

      setCurrentId(customerDto.id);
      setCurrentItem(undefined);
      callGetList(false);
      setOpenCreatePopup(false);
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsLoading(false);
    setIsFetchingList(false);
  };

  const pageChange = (pagination: PaginationDto) => {
    setCurrentId(undefined);
    setCurrentItem(undefined);
    setPagination(pagination);
  };
  const textSearchChange = (newTextToSearch: string) => {
    if (newTextToSearch === textToSearch) return;
    setTextToSearch(newTextToSearch);
    pageChange({
      ...pagination,
      currentPage: 1,
    });
  };

  return {
    // State variables
    me,
    idLocation,
    isLoading,
    isFetchingList,
    isFetchingItem,
    items,
    currentItem,
    dataOrder,
    pagination,
    openCreatePopup,

    // Set state functions
    setCurrentId,
    setDataOrder,
    setOpenCreatePopup,

    // Handler functions
    textSearchChange,
    pageChange,
    handleCreateSave,
    handleEdit,
    handleDelete,
  };
};
