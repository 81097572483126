import { alpha, createTheme, darken } from "@mui/material/styles";
import { AppTheme } from "..";

const main_theme_color = "#118ff3"; // kemaro_sky_blue
const color_01 = alpha(main_theme_color, 0.1);
const color_13 = darken(main_theme_color, 0.3);
const current_row_background_color = alpha(main_theme_color, 0.15);

const line_color = "rgba(0, 0, 0, 0.12)";
const line_style = `1px solid ${line_color}`;

const menu_item_current_background = color_01;
const menu_item_current_background_hover = alpha(main_theme_color, 0.15);

const button_icon_primary_color = main_theme_color;
const robot_model_border = `2px solid ${main_theme_color}`;

const button_color = "white";
const button_background = main_theme_color;
const button_color_hover = "white";
const button_background_hover = "#1565c0";
const button_color_disabled = "white";
const button_background_disabled = "#a4a4a4";

const white = "#fff";

const { palette } = createTheme();

export const theme: AppTheme = {
  palette: {
    mode: "light",

    swarm: {
      // generate <light>, <dark> and <contrastText> tokens for <swarm> color
      ...palette.augmentColor({ color: { main: "#ff0000" } }),
      name: "kemaro",

      applicationBar: {
        containerBackground: main_theme_color,
      },

      auth: {
        loginButton: white,
        loginButtonBackground: "#0244a9",
        loginButtonHover: white,
        loginButtonHoverBackground: "#2a6dd4",
        warning: color_13,
        warningBackground: "rgb(255, 244, 229)",
        warningBorder: "1px solid rgb(255, 152, 0)",
        warningIcon: "rgb(255, 152, 0)",
      },

      companyStructureView: {
        currentNodeIcon: main_theme_color,
      },

      emailSend: {
        button: button_color,
        buttonBackground: button_background,
        hover: button_color_hover,
        hoverBackground: button_background_hover,
        disabled: button_color_disabled,
        disabledBackground: button_background_disabled,
      },

      general: {
        primaryText: main_theme_color,
        currentRowBacground: current_row_background_color,
        iconButtonPrimary: button_icon_primary_color,
      },

      kemaroDashboard: {
        robotsModalDotColor: main_theme_color,
      },

      kemaroDashboardStatisticsChart: {
        responsiveLine: main_theme_color,
      },

      kemaroDashboardCostChartStack: {
        color0: "#063b5f",
        color1: "#095084",
        color2: "#0c65a9",
        color3: main_theme_color,
      },

      kemaroMissions: {
        sendReportBorderTop: line_style,
        sendReportActionButton: button_color,
        sendReportActionButtonBackground: button_background,
        sendReportActionButtonHover: button_color_hover,
        sendReportActionButtonBackgroundHover: button_background_hover,
      },

      kemaroRobots: {
        robotModel: main_theme_color,
        robotModelBorder: robot_model_border,
        childParameter: main_theme_color,
      },

      kemaroCostCalculation: {
        bodyListItemsDefaultText: main_theme_color,
      },

      inputTextList: {
        bagde: main_theme_color,
        badgeBorder: `1px solid ${main_theme_color}`,
      },

      loading: {
        puffLoader: main_theme_color,
      },

      mainPanel: {
        menuCurrentOptionBackground: menu_item_current_background,
        menuCurrentOptionA: main_theme_color,
        menuCurrentOptionLiIcon: main_theme_color,
        menuCurrentOptionHoverBackground: menu_item_current_background_hover,
      },

      navigateTo: {
        to: main_theme_color,
        icon: main_theme_color,
        hover: main_theme_color,
      },

      permissionsMatrix: {
        containerTheadBackground: "rgb(196, 210, 229)",
        sectionTitleBackground: "#d1eafd",
      },

      tagsDisplayer: {
        bagde: main_theme_color,
        badgeBackground: alpha(button_background, 0.15),
      },

      treeView: {
        nodeHeaderSelectedBackground: alpha(main_theme_color, 0.1),
        acccordeonButton: "invert(35%) sepia(97%) saturate(1566%) hue-rotate(189deg) brightness(104%) contrast(90%)",
        //above property generated in https://codepen.io/sosuke/pen/Pjoqqp
      },

      onBoardingAssistant: {
        currentWorkflowLabel: main_theme_color,
        currentWorkflowLabelBackground: color_01,
      },
    },

    primary: {
      ...palette.augmentColor({ color: { main: main_theme_color } }),
    },
  },
};
