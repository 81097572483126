import { useContext } from "react";
import {
  RobotModelListDto,
  SearchTextDtoOfGuid,
} from "../../../../robotcloud-shared/resource-models";
import { RobotModelRequest } from "../../data/robotModel.request";
import AuthorizationContext from "../../views/auth/authorizationContext";
import { BaseSearch } from "./BaseSearch";
import { useTextSearch } from "../../hooks";

export const RobotModelSearch = (props: robotModelSearchProps) => {
  const { me } = useContext(AuthorizationContext);
  const getSearchTextWithOptionalManufacturer = (
    searchTextDto: SearchTextDtoOfGuid,
    manufacturerId?: string | null
  ) => {
    return RobotModelRequest.getSearchText(manufacturerId || "", searchTextDto);
  };
  const {
    items,
    currentItem,
    searchText,
    setSearchText,
    handleSelectionChange,
  } = useTextSearch<
    RobotModelListDto,
    { searchedText: string | null; items: RobotModelListDto[] }
  >(
    getSearchTextWithOptionalManufacturer,
    [],
    !!props.clearAfterSelect,
    undefined,
    props.blackList,
    props.currentId,
    props.selectionChange,
    me?.currentManufacturerId ?? ""
  );

  return (
    <BaseSearch
      disableCloseOnSelect={props.clearAfterSelect}
      value={currentItem?.name}
      options={items
        .filter((x) => !props.blackList || !props.blackList.includes(x.id))
        .map((x) => x.name)}
      searchText={searchText}
      setSearchText={(x) => setSearchText(x)}
      onChange={(x) => handleSelectionChange(x, "name")}
    />
  );
};

interface robotModelSearchProps {
  manufacturerId: string;
  currentId?: string;
  clearAfterSelect?: boolean;
  blackList?: string[] | undefined;
  selectionChange: (select: RobotModelListDto | undefined) => void;
}
