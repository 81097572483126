import { Button, Dialog } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import {
  RobotModelCompleteDto,
  RobotModelPostDto,
} from "../../../../robotcloud-shared/resource-models";
import { Loading } from "../../components/Loading";
import { TextInput } from "../../components/news/TextInput";
import { GeneralFunctions } from "../generalFunctions";
import CloudUploadRoundedIcon from "@mui/icons-material/CloudUploadRounded";
import placeholderImage from "../../assets/Placeholder_view.png";
import { Switch } from "@mui/material";
import { useTranslation } from "react-i18next";
import { tableGenericHandleKeyboardSubjectManager } from "../../components/news";
import useTheme from "@mui/material/styles/useTheme";
import { SupRemark } from "../../uiHelpers/SupRemark";

export const RobotModelsModal = (props: robotModelsModalProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [dto, setDto] = useState<RobotModelCompleteDto>({
    ...robotModelCompleteDtoDefault,
  });
  const [image, setImage] = useState("");

  useEffect(() => {
    if (props.dto) setDto(props.dto);

    if (props.image) setImage(props.image);
  }, [props]);

  useEffect(() => {
    tableGenericHandleKeyboardSubjectManager.setData({
      caller: "RobotModelsModal",
      handleKeyboard: props.isOpenModal,
    });
  }, [props.isOpenModal]);

  const hiddenFileInput = useRef(null);

  const handleSave = async () => {
    const robotModelPostDto: RobotModelPostDto = {
      name: dto.name,
      showImage: dto.showImage,
      image: image,
    };

    const result = await props.onSave(robotModelPostDto);
    if (result) {
      setDto(props.dto ?? { ...robotModelCompleteDtoDefault });
    }
  };

  const clearData = () => {
    setDto({ ...robotModelCompleteDtoDefault });
  };

  const readFile = (event: any) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      const fileContent = e.target!.result;
      setImage(!fileContent ? "" : fileContent.toString());
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  const handleCancel = () => {
    clearData();
    props.onCancel();
  };

  return (
    <Dialog
      className="application-modal robot-models-modal"
      open={props.isOpenModal}
      maxWidth="md"
      onClose={handleCancel}
    >
      <div className="modal-content">
        <div className="application-modal-header">
          <h3>{props.title}</h3>
        </div>
        {props.isWorking ? (
          <div className="application-modal-body">
            <Loading isLoading={props.isWorking} />
          </div>
        ) : (
          <>
            <div className="application-modal-body">
              <div className="row">
                <div className="col-4 input-label mb-3">
                  {t("common.name")}
                  <SupRemark />
                </div>
                <div className="col-8 mb-3">
                  <TextInput
                    value={dto.name}
                    ret={(x) => setDto({ ...dto, name: x })}
                  />
                </div>
                <div className="col-4 input-label mb-3">
                  {t("robotModel.showImage")}
                </div>
                <div className="col-8 mb-3">
                  <Switch
                    color="primary"
                    checked={dto.showImage}
                    onChange={(event) =>
                      setDto({ ...dto, showImage: event.target.checked })
                    }
                  />
                </div>

                <div className="col-4">
                  <input
                    type="file"
                    name="myFile"
                    style={{ display: "none" }}
                    ref={hiddenFileInput}
                    onChange={readFile}
                  />
                </div>

                <div className="col-8 img_container">
                  <div className="align-self-center">
                    {image ? (
                      <img
                        className="img-fluid img-robot-mode"
                        src={image}
                        alt="Robot model"
                      />
                    ) : (
                      <img
                        className="img-fluid img-robot-mode"
                        src={placeholderImage}
                        alt="Robot model"
                      />
                    )}
                  </div>

                  <div
                    className="float-button-container px-2"
                    data-hover={"floatbutton"}
                  >
                    <style>{`[data-hover="floatbutton"]:hover {
                        .float-button {
                        display: initial;
                        background: ${theme.palette.swarm?.robotModels?.modalFloatButtonHoverBackground};
                      }}`}</style>
                    <Button
                      className="float-button"
                      variant="outlined"
                      color="primary"
                      onClick={() => (hiddenFileInput.current! as any).click()}
                    >
                      <CloudUploadRoundedIcon fontSize="inherit" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="application-modal-footer">
              <Button
                variant="contained"
                className="button-with-text"
                disabled={GeneralFunctions.isBlank(dto.name)}
                color="primary"
                onClick={handleSave}
              >
                {t("common.buttons.save")}
              </Button>

              <Button
                className="modal-cancel-button button-with-text"
                variant="outlined"
                color="primary"
                onClick={handleCancel}
              >
                {t("common.buttons.cancel")}
              </Button>
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
};

export interface robotModelsModalProps {
  title: string;
  isOpenModal: boolean;
  isWorking: boolean;
  dto: RobotModelCompleteDto | undefined;
  image: string | undefined;
  onSave: (postDto: RobotModelPostDto) => Promise<boolean>;
  onCancel: () => void;
}

const robotModelCompleteDtoDefault: RobotModelCompleteDto = {
  id: "",
  name: "",
  defaultSystemDetailsJson: "",
  robotsQuantity: 0,
  showImage: true,
};
