import {
  SearchTextDto,
  SearchTextResultDtoOfCountryDto,
} from "../../../robotcloud-shared/resource-models";
import { AxiosRequests } from "./axios.requests";
import { BaseRequest } from "./BaseRequest";
import { prepareSearchTextDto } from "./functions";

export class CountriesRequest extends BaseRequest {
  static controller = "/api/webapp/v1.0/countries";

  static getList = async (searchTextDto: SearchTextDto) => {
    const { data } = await AxiosRequests.post(
      `${CountriesRequest.controller}/search-text`,
      prepareSearchTextDto(searchTextDto)
    );
    return data as SearchTextResultDtoOfCountryDto;
  };
}
