import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import KemaroRobotContext from "../KemaroRobotContext";
import {
  TooltipButton,
  tableGenericHandleKeyboardSubjectManager,
} from "../../../../components/news";
import PlayForWorkIcon from "@mui/icons-material/PlayForWork";
import { KemaroRobotDeploymentModal } from "../Modals/KemaroRobotDeploymentModal";

export const RobotDeploymentsButton = () => {
  const { t } = useTranslation();
  const { setViewSideModalOpenChange, currentItem } =
    useContext(KemaroRobotContext);

  const [openRobotAssignmentModal, setRobotAssignmentModal] = useState(false);

  const openEditPopupPopupChange = (value: boolean) => {
    tableGenericHandleKeyboardSubjectManager.setData({
      caller: "KemaroRobotNameModal",
      handleKeyboard: value,
    });

    setRobotAssignmentModal(value);
    setViewSideModalOpenChange(value);
  };

  return (
    <>
      <TooltipButton
        aria-label={t("robot.assignments.tooltip")}
        tooltip={t("robot.assignments.tooltip")}
        onClick={() => openEditPopupPopupChange(true)}
      >
        <PlayForWorkIcon color="primary" />
      </TooltipButton>

      {openRobotAssignmentModal && (
        <KemaroRobotDeploymentModal
          kemaroRobotId={currentItem!.id!}
          onClose={() => openEditPopupPopupChange(false)}
        />
      )}
    </>
  );
};
