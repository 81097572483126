import { Switch } from "@mui/material";
import { RobotModelCompleteDto } from "../../../../robotcloud-shared/resource-models";
import placeholderImage from "../../assets/Placeholder_view.png";
import { NavigateTo } from "../../components/news/NavigateTo";
import { useTranslation } from "react-i18next";

export const RobotModelsViewBody = (props: robotModelsViewBodyProps) => {
  const { t } = useTranslation();

  return (
    <div className="row mt-4">
      <div className="col-12 col-lg-4">
        <table className="table w-100 table-borderless">
          <tbody>
            <tr>
              <td>
                <strong>{t("robotModel.linkedRobots")}</strong>
              </td>
              <td>
                <NavigateTo
                  text={props.item.robotsQuantity.toString()}
                  shorCut="robots"
                  isAvailable={props.item.robotsQuantity > 0}
                  paramters={[{ name: "idRobotModel", value: props.item.id }]}
                />
              </td>
            </tr>
            <tr>
              <td>
                <strong>{t("robotModel.showImage")}</strong>
              </td>
              <td>
                <Switch
                  disabled={true}
                  color="primary"
                  checked={props.item.showImage}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="col-12 col-lg-4 offset-lg-1">
        <div className="align-self-center">
          {props.image ? (
            <img
              className="img-fluid img-robot-mode"
              src={props.image}
              alt="Robot model"
            />
          ) : (
            <img
              className="img-fluid img-robot-mode"
              src={placeholderImage}
              alt="Robot model"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export interface robotModelsViewBodyProps {
  item: RobotModelCompleteDto;
  image: string;
}
