import { useState, useEffect } from "react";
import {
  UserMaxbrainInfoDto,
  UserMaxbrainConfigDto,
} from "../../../robotcloud-shared/resource-models";
import { ToastHelper } from "../components/news";
import { UsersRequests } from "../data/users.requests";
import { ErrorHelper } from "../uiHelpers/errors.helper";
import { defaultUserMaxbrainConfigDto } from "../views/shared/userMaxbrainConfig/UserMaxbrainConfig";

export const useMaxbrainUserInfo = (
  userId: string,
  isOpenModal: boolean,
  onCancel: () => void
) => {
  const [isWorking, setIsWorking] = useState(true);
  const [userMaxbrain, setUserMaxbrain] = useState<
    UserMaxbrainInfoDto | undefined
  >(undefined);
  const [userMaxbrainConfig, setUserMaxbrainConfig] =
    useState<UserMaxbrainConfigDto>(defaultUserMaxbrainConfigDto);
  const [openModalLanguageChange, setOpenModalLanguageChange] = useState(false);
  const [userMaxbrainGroup, setUserMaxbrainGroup] = useState("");
  const [userMaxbrainLanguage, setUserMaxbrainLanguage] = useState("");
  useEffect(() => {
    const getUserMaxbrainInfo = async () => {
      setIsWorking(true);

      try {
        const userMaxbrainTmp = await UsersRequests.getUserMaxbrainInfo(userId);

        setUserMaxbrain(userMaxbrainTmp);
        setUserMaxbrainConfig((x) => ({
          ...x,
          group: userMaxbrainTmp.userGroupConfig,
          language: userMaxbrainTmp?.language ?? "en",
        }));

        setUserMaxbrainGroup(userMaxbrainTmp.userGroupConfig);
        setUserMaxbrainLanguage(userMaxbrainTmp?.language ?? "en");
      } catch (error) {
        setUserMaxbrain(undefined);
        ToastHelper.errors(ErrorHelper.process(error));
      }

      setIsWorking(false);
    };

    if (isOpenModal && userId) {
      getUserMaxbrainInfo();
    }
  }, [userId, isOpenModal]);

  const handleModify = async () => {
    if (userMaxbrainLanguage !== userMaxbrainConfig.language) {
      setOpenModalLanguageChange(true);
      return;
    }

    await onModify();
  };

  const onModify = async () => {
    if (
      userMaxbrainConfig.group === userMaxbrainGroup &&
      userMaxbrainConfig.language === userMaxbrainLanguage
    ) {
      onCancel();
      return;
    }

    setIsWorking(true);

    try {
      const userMaxbrainTmp = await UsersRequests.putUserMaxbrainInfo(
        userId,
        userMaxbrainConfig
      );

      setUserMaxbrain(userMaxbrainTmp);

      onCancel();
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsWorking(false);
  };

  const handleCreate = async () => {
    setIsWorking(true);

    try {
      const userMaxbrainTmp = await UsersRequests.postUserMaxbrainInfo(
        userId,
        userMaxbrainConfig
      );

      setUserMaxbrain(userMaxbrainTmp);

      onCancel();
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsWorking(false);
  };
  return {
    isWorking,
    userMaxbrain,
    userMaxbrainConfig,
    userMaxbrainLanguage,
    setUserMaxbrainConfig,
    openModalLanguageChange,
    setOpenModalLanguageChange,
    onModify,
    handleModify,
    handleCreate,
  };
};
