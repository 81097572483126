import { useContext, useEffect, useState } from "react";
import { CustomFieldTypeDto } from "../../../../robotcloud-shared/resource-models";
import { NativeSelect } from "@mui/material";
import { CustomFieldContext } from "./CustomFieldContext";
import { CustomFieldRequests } from "../../data/customField.request";

export const CustomFieldTypeSelector = () => {
  const [current, setCurrent] = useState<CustomFieldTypeDto | undefined>(
    undefined
  );
  const [types, setTypes] = useState<CustomFieldTypeDto[]>([]);

  const { setCurrentType, setCurrentId, setCurrentItem } =
    useContext(CustomFieldContext);

  useEffect(() => {
    const getTypes = async () => {
      const types = await CustomFieldRequests.getTypeList();
      setTypes(types);
      if (types.length > 0) {
        setCurrent(types[0]);
        setCurrentType(types[0]);
      }
    };

    getTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <NativeSelect
      className="w-50 drop-down-title"
      value={current?.type ?? " "}
      onChange={(event) => {
        const selected = types.find((x) => x.type === event.target.value);

        setCurrent(selected);
        setCurrentType(selected);
        setCurrentId(undefined);
        setCurrentItem(undefined);
      }}
    >
      {types?.map((x) => (
        <option key={x.type} value={x.type}>
          {x.displayText}
        </option>
      ))}
    </NativeSelect>
  );
};
