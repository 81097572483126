import {
  RobotModelCompleteDto,
  RobotModelPostDto,
} from "../../../../robotcloud-shared/resource-models";
import { Loading } from "../../components/Loading";
import { RobotModelsViewBody } from "./RobotModelsViewBody";
import { RobotModelsViewTitle } from "./RobotModelsViewTitle";

export const RobotModelsView = (props: robotModelsViewProps) => {
  return (
    <>
      {props.isFetching ? (
        <Loading isLoading={props.isFetching} />
      ) : (
        <>
          <RobotModelsViewTitle
            item={props.item}
            image={props.image}
            onModify={props.onModify}
            onDelete={props.onDelete}
          />

          <RobotModelsViewBody item={props.item} image={props.image} />
        </>
      )}
    </>
  );
};

export interface robotModelsViewProps {
  item: RobotModelCompleteDto;
  isFetching: boolean;
  image: string;
  onModify: (
    original: RobotModelCompleteDto,
    modified: RobotModelPostDto
  ) => Promise<boolean>;
  onDelete: (item: RobotModelCompleteDto) => Promise<boolean>;
}
