import {
  CompanyStructureAddressDto,
  KemaroCompanyStructureDto,
  KemaroCompanyStructurePostDto,
} from "../../../../../robotcloud-shared/resource-models";
import {
  NavigateTo,
  TooltipButton,
  YesNoOkDialog,
} from "../../../components/news";
import { useState, useEffect } from "react";
import { CompanyStructureKemaroModal } from "./CompanyStructureKemaroModal";
import { useTranslation } from "react-i18next";
import StarRateIcon from "@mui/icons-material/StarRate";
//import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import Edit from "@mui/icons-material/Edit";
import DeleteForever from "@mui/icons-material/DeleteForever";
import useTheme from "@mui/material/styles/useTheme";
import { LocationOn } from "@mui/icons-material";
import { CompanyStructureAddressModal } from "./CompanyStructureAddressModal";

export const CompanyStructureKemaroView = (
  props: CompanyStructureKemaroViewProps
) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [modalWorking, setModalWorking] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalDeleteWorking, setModalDeleteWorking] = useState(false);
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [deleteDisabled, setDeleteDisabled] = useState(true);
  const [modalAddressOpen, setModalAddressOpen] = useState(false);

  useEffect(() => {
    setDeleteDisabled(props.data.hasDependentData || !props.canDelete);
  }, [props]);

  const modifyHandle = async (
    origin: KemaroCompanyStructurePostDto,
    modified: KemaroCompanyStructurePostDto,
    migrateAllMissions: boolean,
    migrateNewerMissions: boolean,
    migrateNewerFromMissions: Date | undefined
  ) => {
    setModalWorking(true);
    const result = await props.onModify(
      origin,
      modified,
      migrateAllMissions,
      migrateNewerMissions,
      migrateNewerFromMissions
    );
    setModalWorking(false);

    return result;
  };

  const deleteHandle = async (
    kemaroCompanyStructure: KemaroCompanyStructureDto
  ) => {
    setModalDeleteWorking(true);
    const result = await props.onDelete(kemaroCompanyStructure);
    setModalDeleteWorking(false);
    setModalDeleteOpen(false);

    return result;
  };

  const getFullAddress = () => {
    return (
      <>
        <p>{`${props.data.address?.name ?? ""}`}</p>
        <p>{`${props.data.address?.address ?? ""}`}</p>
        <p>{`${props.data.address?.zipCode ?? ""} ${
          props.data.address?.city ?? ""
        }`}</p>
        <p>{`${props.data.address?.country?.name ?? ""}`}</p>
      </>
    );
  };
  return (
    <>
      <h4>
        {props.data.name}
        {props.canEdit && (
          <>
            <TooltipButton
              className="ms-2"
              aria-label="edit"
              tooltip={t("company.editLocation.tooltip")}
              onClick={() => setModalOpen(true)}
              disabled={!props.canEdit}
            >
              <Edit color="primary" />
            </TooltipButton>
            <TooltipButton
              aria-label="edit"
              tooltip={t("company.editLocationAddress.tooltip")}
              onClick={() => setModalAddressOpen(true)}
              disabled={!props.canEdit}
            >
              <LocationOn color="primary" />
            </TooltipButton>
            {!props.data.isRoot && (
              <TooltipButton
                aria-label="delete"
                tooltip={t("company.deleteLocation.tooltip")}
                tooltipDisable={t("company.deleteLocation.tooltipDisable")}
                disabled={deleteDisabled}
                onClick={() => setModalDeleteOpen(true)}
              >
                <DeleteForever color={deleteDisabled ? "disabled" : "error"} />
              </TooltipButton>
            )}
          </>
        )}
      </h4>

      <table className="table table-borderless">
        <tbody>
          <tr>
            <td className="row-header-td no-wrap">
              {t("company.locationDescription")}
            </td>
            <td>
              <span>{props.data.description}</span>
            </td>
          </tr>
          <tr>
            <td className="row-header-td no-wrap">{t("contact.entityName")}</td>
            <td>
              {!!props.data.customerContact && (
                <span>{props.data.customerContact}</span>
              )}
              {!!!props.data.customerContact &&
                !!props.data.customerContactInherited && (
                  <>
                    <span
                      className="data-inherited"
                      style={{
                        color:
                          theme.palette.swarm?.companyStructureView
                            ?.dataInherited,
                      }}
                    >
                      {props.data.customerContactInherited}
                    </span>
                    <span
                      className="data-inherited ms-2"
                      style={{
                        color:
                          theme.palette.swarm?.companyStructureView
                            ?.dataInherited,
                      }}
                    >
                      [
                      {props.data
                        .kemaroDashboardEconomyCostCalculationDefault ? (
                        <StarRateIcon className="original-node-icon-start" />
                      ) : (
                        <PlaceOutlinedIcon className="original-node-icon-pin" />
                      )}
                      {props.data.customerContactInheritedNode}]
                    </span>
                  </>
                )}
            </td>
          </tr>
          <tr>
            <td className="row-header-td no-wrap">
              {t("company.costCalculation")}
            </td>
            <td>
              {!!props.data.kemaroDashboardEconomyCostCalculation && (
                <NavigateTo
                  text={props.data.kemaroDashboardEconomyCostCalculation ?? ""}
                  shorCut="cost-calculation"
                  isAvailable={
                    props.data.idKemaroDashboardEconomyCostCalculation !== null
                  }
                  paramters={[
                    {
                      name: "id",
                      value:
                        props.data.idKemaroDashboardEconomyCostCalculation ??
                        "",
                    },
                  ]}
                />
              )}
              {!!!props.data.kemaroDashboardEconomyCostCalculation &&
                !!props.data.kemaroDashboardEconomyCostCalculationInherited && (
                  <>
                    <NavigateTo
                      shorCut="cost-calculation"
                      isAvailable={
                        props.data
                          .idKemaroDashboardEconomyCostCalculationInherited !==
                        null
                      }
                      paramters={[
                        {
                          name: "id",
                          value:
                            props.data
                              .idKemaroDashboardEconomyCostCalculationInherited ??
                            "",
                        },
                      ]}
                    >
                      <>
                        <span className="ms-2">
                          {
                            props.data
                              .kemaroDashboardEconomyCostCalculationInherited
                          }
                        </span>
                        <span className="ms-2">
                          [
                          {props.data
                            .kemaroDashboardEconomyCostCalculationDefault ? (
                            <StarRateIcon className="original-node-icon-start" />
                          ) : (
                            <PlaceOutlinedIcon className="original-node-icon-pin" />
                          )}
                          {
                            props.data
                              .kemaroDashboardEconomyCostCalculationInheritedNode
                          }
                          ]
                        </span>
                      </>
                    </NavigateTo>
                  </>
                )}
            </td>
          </tr>
          <tr>
            <td className="row-header-td no-wrap">{t("robot.entitiesName")}</td>
            <td>
              {(props.data.robotsInBranch > 0 ||
                props.data.robotsInNode > 0) && (
                <NavigateTo
                  text={props.toolTip(props.data)}
                  shorCut="robots"
                  paramters={[
                    { name: "idCompanyStructureBranch", value: props.data.id },
                  ]}
                />
              )}
            </td>
          </tr>
          <tr>
            <td className="row-header-td no-wrap">
              {t("company.locationAddress.address")}
            </td>
            <td>{getFullAddress()}</td>
          </tr>
        </tbody>
      </table>

      {modalOpen && (
        <CompanyStructureKemaroModal
          modalOpen={modalOpen}
          modalWorking={modalWorking}
          idCompany={props.idCompany}
          nodeList={props.nodeList}
          kemaroCompanyStructure={props.data}
          onCancel={() => setModalOpen(false)}
          onModify={modifyHandle}
        />
      )}

      {modalAddressOpen && (
        <CompanyStructureAddressModal
          modalOpen={modalAddressOpen}
          idCompanyStructure={props.data.id}
          initialAddressDto={props.data.address}
          onCancel={() => setModalAddressOpen(false)}
          onSave={props.refresh}
          onDelete={props.refresh}
        />
      )}

      <YesNoOkDialog
        open={modalDeleteOpen}
        size="md"
        type="yesNo"
        title={t("company.deleteLocation.title")}
        message={`${t("company.deleteLocation.warning")} ${props.data?.name}?`}
        onYes={() => deleteHandle(props.data!)}
        onNo={() => setModalDeleteOpen(false)}
        isWorking={modalDeleteWorking}
      />
    </>
  );
};

interface CompanyStructureKemaroViewProps {
  data: KemaroCompanyStructureDto;
  canEdit: Boolean;
  canDelete: Boolean;
  idCompany: string;
  nodeList: KemaroCompanyStructureDto[];
  toolTip: (item: KemaroCompanyStructureDto) => string;
  onModify: (
    origin: KemaroCompanyStructurePostDto,
    modified: KemaroCompanyStructurePostDto,
    migrateAllMissions: boolean,
    migrateNewerMissions: boolean,
    migrateNewerFromMissions: Date | undefined
  ) => Promise<boolean>;
  onModifyAddress?: (
    origin: CompanyStructureAddressDto,
    modified: CompanyStructureAddressDto
  ) => Promise<boolean>;

  onDelete: (dto: KemaroCompanyStructureDto) => Promise<boolean>;
  refresh: () => void;
}
