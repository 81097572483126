import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { Autocomplete, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import {
  UserListDto,
  UserRoles,
} from "../../../../../robotcloud-shared/resource-models";
import { Loading } from "../../../components/Loading";
import { TextInput } from "../../../components/news/TextInput";
import { GeneralFunctions } from "../../generalFunctions";
import "./userModals.scss";
import { useTranslation } from "react-i18next";
import { tableGenericHandleKeyboardSubjectManager } from "../../../components/news";
import { SupRemark } from "../../../uiHelpers/SupRemark";

export const UsersModifyModal = (props: usersModifyModalProps) => {
  const [user, setUser] = useState<UserListDto | undefined>(undefined);
  const { t } = useTranslation();

  useEffect(() => {
    setUser(props.user);
  }, [props.user]);

  useEffect(() => {
    tableGenericHandleKeyboardSubjectManager.setData({
      caller: "UsersModifyModal",
      handleKeyboard: props.isOpenModal,
    });
  }, [props.isOpenModal]);

  return (
    <Dialog
      className="application-modal"
      open={props.isOpenModal}
      maxWidth="md"
      onClose={() => props.onCancel()}
    >
      <Box className="modal-content">
        <Stack>
          <Box className="application-modal-header">
            <h3 className="modal-title">{t("user.edit.title")}</h3>
          </Box>
          <Box className="application-modal-body">
            {props.isWorking || !user ? (
              <Loading isLoading={props.isWorking} />
            ) : (
              <Grid container>
                <Grid className="input-label" item xs={4}>
                  {t("user.firstName")}
                  <SupRemark />
                </Grid>
                <Grid item xs={8}>
                  <TextInput
                    value={user.firstName}
                    ret={(x) => setUser({ ...user, firstName: x })}
                  />
                </Grid>
                <Grid className="input-label" item xs={4}>
                  {t("user.lastName")}
                  <SupRemark />
                </Grid>
                <Grid item xs={8}>
                  <TextInput
                    value={user.lastName}
                    ret={(x) => setUser({ ...user, lastName: x })}
                  />
                </Grid>
                <Grid className="input-label" item xs={4}>
                  {t("common.role")}
                  <SupRemark />
                </Grid>
                <Grid item xs={8}>
                  <Autocomplete
                    size="small"
                    freeSolo
                    onChange={(event, newValue) =>
                      setUser({
                        ...user,
                        role: (newValue as UserRoles) ?? "Member",
                      })
                    }
                    value={user.role}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label=""
                        placeholder={t("common.typeToSearch")}
                        fullWidth
                        variant="standard"
                        margin="dense"
                        hiddenLabel
                      />
                    )}
                    options={["Owner", "Administrator", "Member"]}
                  />
                </Grid>
                <Grid className="input-label mt-2" item xs={4}>
                  {t("user.enabled")}
                </Grid>
                <Grid className="mt-2" item xs={1}>
                  <Switch
                    color="primary"
                    checked={user.enabled}
                    onChange={(event) =>
                      setUser({ ...user, enabled: event.target.checked })
                    }
                  />
                </Grid>
                <Grid item xs={7}></Grid>
              </Grid>
            )}
          </Box>
          {props.isWorking || !user ? (
            <Box className="application-modal-footer"></Box>
          ) : (
            <Box className="application-modal-footer">
              <Button
                variant="contained"
                className="button-with-text"
                disabled={
                  GeneralFunctions.isBlank(user.firstName) ||
                  GeneralFunctions.isBlank(user.lastName)
                }
                color="primary"
                onClick={() => props.onSaved(user)}
              >
                {t("common.buttons.save")}
              </Button>

              <Button
                className="modal-cancel-button button-with-text"
                variant="outlined"
                color="primary"
                onClick={() => props.onCancel()}
              >
                {t("common.buttons.cancel")}
              </Button>
            </Box>
          )}
        </Stack>
      </Box>
    </Dialog>
  );
};

export interface usersModifyModalProps {
  user: UserListDto;
  isOpenModal: boolean;
  isWorking: boolean;
  onCancel: () => void;
  onSaved: (user: UserListDto) => void;
}
