import { AxiosResponse } from "axios";
import { diff, jsonPatchPathConverter } from "just-diff";
import {
  PaginatedListDtoOfWorkflowTranslatedDataDto,
  WorkflowTranslatedDataDto,
  WorkflowTranslatedDataFilterCriteriaDto,
  WorkflowTranslatedDataPostDto,
} from "../../../robotcloud-shared/resource-models";
import { AxiosRequests } from "./axios.requests";
import { BaseRequest } from "./BaseRequest";

export class WorkflowTranslatedDatasRequests extends BaseRequest {
  static controller = "/api/webapp/v1.0/workflowTranslatedDatas";

  static getList = async (filter: WorkflowTranslatedDataFilterCriteriaDto) => {
    const { data } = await AxiosRequests.get(
      `${
        WorkflowTranslatedDatasRequests.controller
      }?${WorkflowTranslatedDatasRequests.objectToQueryString(filter)}`
    );
    return data as PaginatedListDtoOfWorkflowTranslatedDataDto;
  };

  static getById = async (id: string) => {
    const { data } = await AxiosRequests.get(
      `${WorkflowTranslatedDatasRequests.controller}/${id}`
    );
    return data as WorkflowTranslatedDataDto;
  };

  static getPdfContent = async (
    workflowTranslatedDataId: string,
    abortController?: AbortController
  ): Promise<AxiosResponse> => {
    return await AxiosRequests.getBlob(
      `${WorkflowTranslatedDatasRequests.controller}/${workflowTranslatedDataId}/pdfFile`,
      abortController
    );
  };

  static setPdf = async (
    workflowTranslatedDataId: string,
    file: File
  ): Promise<AxiosResponse> => {
    const formData = new FormData();
    formData.append("file", file);
    return await AxiosRequests.put(
      `${WorkflowTranslatedDatasRequests.controller}/${workflowTranslatedDataId}/pdfFile`,
      formData
    );
  };

  static create = async (dto: WorkflowTranslatedDataPostDto) => {
    const { data } = await AxiosRequests.post(
      WorkflowTranslatedDatasRequests.controller,
      dto
    );
    return data as WorkflowTranslatedDataDto;
  };

  static modify = async (
    originalDto: WorkflowTranslatedDataDto,
    modifiedDto: WorkflowTranslatedDataDto
  ) => {
    const allowProperties = [
      "/languageCode",
      "/title",
      "/instructions",
      "/agreement",
      "/linkUrl",
    ];

    const jsonPatch = diff(
      originalDto,
      modifiedDto,
      jsonPatchPathConverter
    ).filter((x) => allowProperties.includes(x.path));

    const { data } = await AxiosRequests.patch(
      `${WorkflowTranslatedDatasRequests.controller}/${originalDto.id}`,
      jsonPatch
    );
    return data as WorkflowTranslatedDataDto;
  };

  static delete = async (id: string) => {
    return await AxiosRequests.delete(
      `${WorkflowTranslatedDatasRequests.controller}/${id}`
    );
  };
}
