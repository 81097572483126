import { Theme } from "@mui/material";
import { PermissionMatrixUserTypeDto } from "../../../../robotcloud-shared/resource-models";

export const PermissionsMatrixColumnHeader = (
  ut: PermissionMatrixUserTypeDto,
  theme: Theme
) => {
  return (
    <th
      key={`ch_${ut.type}_${ut.role}`}
      style={{
        borderLeft:
          theme.palette.swarm?.permissionsMatrix?.containerThBorderLeft,
        padding: "8px",
      }}
    >
      <p>{ut.type}</p>
      <p>{ut.role}</p>
    </th>
  );
};
