import { Link } from "react-router-dom";
import LinkIcon from "@mui/icons-material/Link";
import { MenuHelper, shortCuts } from "../../uiHelpers/menu.helper";
import { StorageManagerService } from "../../data/storageManager.service";
import "./navigateTo.scss";
import { ReactElement } from "react";
import { TooltipContainer } from "./TooltipContainer";
import i18n from "i18next";
import useTheme from "@mui/material/styles/useTheme";

export const NavigateTo = (props: NavigateToProps) => {
  const me = StorageManagerService.getMe();
  var navigateRoute = MenuHelper.getByShortCut(me, props.shorCut);
  const theme = useTheme();

  if (!navigateRoute || !props.isAvailable) {
    return props.notAvailableChildren ?? <span>{props.text}</span>;
  }

  var containerClass = !!props.className ? props.className : "";

  return (
    <TooltipContainer text={generateToolTip(props)}>
      <Link
        className={`navigate-to ${containerClass}`}
        style={{ color: theme.palette.swarm?.navigateTo?.to }}
        to={generateLink(navigateRoute.url, props.paramters)}
        onClick={() => {
          if (props.onClick) props.onClick();
        }}
      >
        <table>
          <tbody>
            <tr>
              <td className="pe-1 icon">
                <LinkIcon
                  style={{ color: theme.palette.swarm?.navigateTo?.icon }}
                />
              </td>
              <td>
                {!!props.children && props.children}
                {!!!props.children && props.text}
              </td>
            </tr>
          </tbody>
        </table>
      </Link>
    </TooltipContainer>
  );
};

const generateLink = (url: string, paramters?: NavigateToParameters[]) => {
  let result = url;

  if (paramters) {
    let count = 0;
    paramters.forEach((p) => {
      result += `${count === 0 ? "?" : "&"}${p.name}=${p.value}`;
      count++;
    });
  }

  return result;
};

const generateToolTip = (props: NavigateToProps) => {
  let toolTip = props.toolTipText ?? getShorCutText(props.shorCut);

  if (props.toolTipIsSingular && toolTip.endsWith("s") && !props.toolTipText)
    toolTip = toolTip.substring(0, toolTip.length - 1);

  return `${i18n.t("common.navigation.general")} ${toolTip}`;
};

const getShorCutText = (shorCut: shortCuts) =>
  shorCut === "dashboard-economy"
    ? "economy dashboard"
    : shorCut.replace("-", " ");

interface NavigateToProps {
  text?: string;
  shorCut: shortCuts;
  className?: string;
  isAvailable?: boolean;
  paramters?: NavigateToParameters[];
  children?: ReactElement | never[];
  notAvailableChildren?: ReactElement | never[];
  toolTipIsSingular?: boolean;
  toolTipText?: string;
  onClick?: () => void;
}

NavigateTo.defaultProps = {
  isAvailable: true,
};

export interface NavigateToParameters {
  name: string;
  value: string;
}
