import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import "./kemaroRobots.scss";
import {
  OrderDto,
  PaginationDto,
  orderDtoDefault,
  paginationDtoDefault,
} from "../../../data/common";
import { ToastHelper } from "../../../components/news/Toast.helper";
import { ErrorHelper } from "../../../uiHelpers/errors.helper";
import { KemaroRobotRequests } from "../../../data/kemaro/kemaroRobot.request";
import {
  KemaroRobotCrudDto,
  KemaroRobotFilterCriteriaDto,
  KemaroRobotListDto,
  KemaroRobotPaginatedListDto,
} from "../../../../../robotcloud-shared/resource-models";
import { KemaroRobotView } from "./KemaroRobotView";

import { kemaroRobotsShowSystemInformationSubjectManager } from "./RxJS";

import { applicationBarFocusSubjectManager } from "../../../components/news";
import KemaroRobotContext from "./KemaroRobotContext";
import { KemaroRobotsHeader } from "./KemaroRobotsHeader";
import { kemaroRobotsFilter, kemaroRobotsFilterDefault } from "./Models";
import { filterToFilterCriteria } from "./Functions";
import { KemaroRobotsLeftSide } from "./KemaroRobotsLeftSide";
import { GeneralFunctions } from "../../generalFunctions";
import { modalOpenSubjectManager } from "../../shared/modalSubjectManagers";

export const KemaroRobots = () => {
  const [pagination, setPagination] = useState<PaginationDto>({
    ...paginationDtoDefault,
  });
  const [dataOrder, setDataOrder] = useState<OrderDto>({ ...orderDtoDefault });
  const [isFetchingList, setIsFetchingList] = useState(false);

  const [data, setData] = useState<KemaroRobotPaginatedListDto | undefined>(
    undefined
  );

  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);
  const [isFetchingItem, setIsFetchingItem] = useState(false);

  const [currentItem, setCurrentItem] = useState<
    KemaroRobotCrudDto | undefined
  >(undefined);
  const [openCreatePopup, setOpenCreatePopup] = useState(false);
  const [filter, setFilter] = useState<kemaroRobotsFilter>({
    ...kemaroRobotsFilterDefault,
  });

  const [viewSideModalOpenChange, setViewSideModalOpenChange] = useState(false);

  const [isTextSearchFocus, setIsTextSearchFocus] = useState(false);
  const [thereIsModelOpen, setThereIsModelOpen] = useState(false);

  useEffect(() => {
    const applicationBarFocusSubscription = applicationBarFocusSubjectManager
      .getObservable()
      .subscribe((x) => setIsTextSearchFocus(x));

    const modalOpenSubjectManagerSubscription = modalOpenSubjectManager
      .getObservable()
      .subscribe((x) => setThereIsModelOpen(x));

    return () => {
      applicationBarFocusSubscription.unsubscribe();
      modalOpenSubjectManagerSubscription.unsubscribe();
    };
  }, []);

  const [connectionModalOpen, setConnectionModalOpen] = useState(false);

  useEffect(() => {
    const handleSpaceBar = (ev: KeyboardEvent) => {
      if (
        !currentItem ||
        isFilterDialogOpen ||
        openCreatePopup ||
        viewSideModalOpenChange ||
        isTextSearchFocus ||
        connectionModalOpen ||
        thereIsModelOpen
      )
        return;

      if (ev.key === " " || ev.code === "Space") {
        ev.preventDefault();
        kemaroRobotsShowSystemInformationSubjectManager.setData();
      }
    };

    window.addEventListener("keydown", handleSpaceBar);

    return () => {
      window.removeEventListener("keydown", handleSpaceBar);
    };
  }, [
    currentItem,
    isFilterDialogOpen,
    openCreatePopup,
    viewSideModalOpenChange,
    isTextSearchFocus,
    connectionModalOpen,
  ]);

  const getList = async (
    conditions: KemaroRobotFilterCriteriaDto,
    page: PaginationDto,
    currentItem: KemaroRobotCrudDto | undefined,
    setPage: (page: PaginationDto) => void,
    pageChange: boolean
  ) => {
    try {
      setIsFetchingList(true);

      const data = await KemaroRobotRequests.getList(conditions);

      setPage(GeneralFunctions.getValidatedPage(page, data.totalItems));
      setData(data);

      if (data.items.length > 0) {
        if (
          pageChange ||
          !currentItem ||
          !data.items.some((x) => x.id === currentItem.id)
        ) {
          getKemaroRobot(data.items[0].id);
        }
      }
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsFetchingList(false);
  };

  const refreshItems = async (
    idToShow?: string | undefined | null,
    refreshCurrent: boolean = true,
    selectFirstItem: boolean = false
  ) => {
    const conditions = filterToFilterCriteria(filter, pagination, dataOrder);
    await getList(
      conditions,
      pagination,
      currentItem,
      setPagination,
      selectFirstItem
    );
    if (refreshCurrent) await refreshItem();
  };

  const getKemaroRobot = async (currentId: string, force: boolean = false) => {
    if (!force && (isFetchingItem || currentItem?.id === currentId)) return;

    setIsFetchingItem(true);

    try {
      setCurrentItem(await KemaroRobotRequests.getById(currentId));
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsFetchingItem(false);
  };

  const refreshItem = async () => {
    if (currentItem?.id) await getKemaroRobot(currentItem.id, true);
  };

  const getNextSelectableItem = (itemId: string) =>
    GeneralFunctions.nextItemFromArray<KemaroRobotListDto>(data?.items, itemId);

  return (
    <KemaroRobotContext.Provider
      value={{
        currentItem: currentItem,
        setCurrentItem: setCurrentItem,
        currentItemIsFeching: isFetchingItem,
        setCurrentItemIsFeching: setIsFetchingItem,
        refreshItem: refreshItem,
        refreshItems: refreshItems,
        filter: filter,
        setFilter: setFilter,
        pagination: pagination,
        setPagination: setPagination,
        dataOrder: dataOrder,
        setDataOrder: setDataOrder,
        getById: getKemaroRobot,
        getList: (conditions, page, currentItem, setPage) =>
          getList(conditions, page, currentItem, setPage, false),
        viewSideModalOpenChange: viewSideModalOpenChange,
        setViewSideModalOpenChange: setViewSideModalOpenChange,
        getNextItem: getNextSelectableItem,
      }}
    >
      <Box className="page-content">
        <KemaroRobotsHeader
          isFilterDialogOpen={isFilterDialogOpen}
          setIsFilterDialogOpen={setIsFilterDialogOpen}
        />
        <Box className="page-body d-flex kemaro-robot-body">
          <KemaroRobotsLeftSide
            filterCriteria={filterToFilterCriteria(
              filter,
              pagination,
              dataOrder
            )}
            filter={filter}
            setFilter={setFilter}
            isFetchingList={isFetchingList}
            setIsFetchingList={setIsFetchingList}
            data={data}
            setOpenCreatePopup={setOpenCreatePopup}
          />

          <Box className="view-container">
            <KemaroRobotView
              connectionModalOpen={connectionModalOpen}
              setConnectionModalOpen={setConnectionModalOpen}
            />
          </Box>
        </Box>
      </Box>
    </KemaroRobotContext.Provider>
  );
};
