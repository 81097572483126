import { useTranslation } from "react-i18next";
import { TooltipContainer } from "../../../../components/news";
import { GeneralFunctions } from "../../../generalFunctions";
import { useContext } from "react";
import KemaroRobotContext from "../KemaroRobotContext";

export const LastContactDisplayer = () => {
  const { t } = useTranslation();
  const { currentItem } = useContext(KemaroRobotContext);

  return (
    <TooltipContainer text="Last contact">
      <>
        <span className="pe-2">{t("robot.lastConnection")}</span>
        <span>
          {GeneralFunctions.dateToTimeDateString(currentItem!.lastContact)}
        </span>
      </>
    </TooltipContainer>
  );
};
