import "./applicationBar.scss";
import {
  forwardRef,
  useEffect,
  ReactElement,
  Ref,
  useContext,
  useImperativeHandle,
  useState,
} from "react";
import Box from "@mui/material/Box";
import { TooltipButton } from "../TooltipButton";
import { useTranslation } from "react-i18next";
import AuthorizationContext from "../../../views/auth/authorizationContext";
import { KemaroConfigurationDto } from "../../../../../robotcloud-shared/resource-models";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { applicationBarFocusSubjectManager } from "./ApplicationBarSubjectManager";
import ColorThemeSwitch from "../../../theme/components/ColorThemeSwitch";
import useTheme from "@mui/material/styles/useTheme";
import { PermissionsHelper } from "../../../uiHelpers/permissionsHelper";

export const ApplicationBar = forwardRef(
  (props: applicationBarProps, ref: Ref<applicationBarRef>) => {
    const [textToSearch, setTextToSearch] = useState("");
    const [showSupportEarlybyte, setShowSupportEarlybyte] = useState(false);
    const [supportManufacturerUrl, setSupportManufacturerUrl] = useState<
      string | null
    >(null);

    const { t } = useTranslation();
    const [canColorThemeSwitch, setCanColorThemeSwitch] = useState(false);
    const { me, configuration, currentManufacturer } =
      useContext(AuthorizationContext);

    useImperativeHandle(ref, () => ({ reset }));

    useEffect(() => {
      setShowSupportEarlybyte(
        me?.userAccessType === "Earlybyte" ||
          me?.userAccessType === "Manufacturer"
      );
      setCanColorThemeSwitch(
        me?.userAccessType === "Earlybyte" && PermissionsHelper.isDevelop()
      );
      if (currentManufacturer?.name.toLowerCase() === "kemaro") {
        const kemaroCnf = configuration as KemaroConfigurationDto;
        setSupportManufacturerUrl(kemaroCnf?.supportFormUrl);
      } else {
        setSupportManufacturerUrl(null);
      }
    }, [me, configuration, currentManufacturer]);

    const reset = () => {
      setTextToSearch("");
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setTextToSearch(event.target.value);
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (props.search && event.key === "Enter") {
        props.search(textToSearch);
      }
    };

    const handleClear = () => {
      setTextToSearch("");
      if (props.search) {
        props.search("");
      }
    };

    const theme = useTheme();

    return (
      <Box
        className="w-100 applicacion-bar-container px-4"
        color="swarm.applicationBar.container"
        bgcolor="swarm.applicationBar.containerBackground"
      >
        <span className="title">{props.title}</span>

        <Box flex={1} />

        {props.canSearch && (
          <div
            className="search-input-container"
            style={{
              backgroundColor:
                theme.palette.swarm?.applicationBar
                  ?.searchInputContainerBackground,
              color: theme.palette.swarm?.applicationBar?.container,
            }}
          >
            <table className="w-100">
              <tbody>
                <tr>
                  <td className="search-icon-td">
                    <SearchIcon className="search-input-icon" />
                  </td>
                  <td>
                    <input
                      value={textToSearch}
                      className="search-input w-100"
                      placeholder={`${t("applicationBar.search")}...`}
                      onFocus={() =>
                        applicationBarFocusSubjectManager.setData(true)
                      }
                      onBlur={() =>
                        applicationBarFocusSubjectManager.setData(false)
                      }
                      onChange={handleChange}
                      onKeyUp={handleKeyPress}
                      style={{
                        backgroundColor:
                          theme.palette.swarm?.applicationBar
                            ?.searchInputBackground,
                        color: theme.palette.swarm?.applicationBar?.searchInput,
                      }}
                    />
                  </td>
                  <td className="clear-td">
                    {textToSearch && (
                      <CloseIcon
                        className="clear-input-icon cursor-pointer"
                        onClick={() => handleClear()}
                      />
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}

        {props.children}

        {supportManufacturerUrl && (
          <TooltipButton
            className="button"
            tooltip={t("applicationBar.support")}
            onClick={() => support(supportManufacturerUrl)}
          >
            <SupportAgentIcon sx={{ color: "swarm.applicationBar.button" }} />
          </TooltipButton>
        )}

        {showSupportEarlybyte && (
          <TooltipButton
            className="button"
            tooltip={t("applicationBar.supportEarlybyte")}
            onClick={() => support(supportEarlyByteUrl)}
          >
            <ChatBubbleOutlineIcon
              sx={{ color: "swarm.applicationBar.button" }}
            />
          </TooltipButton>
        )}
        {canColorThemeSwitch && <ColorThemeSwitch />}
      </Box>
    );
  }
);

export interface applicationBarProps {
  title: string;
  canSearch?: boolean;
  search?: (textToSearch: string) => void;
  children?: ReactElement | never[];
}

export interface applicationBarRef {
  reset: () => void;
}

ApplicationBar.defaultProps = {
  canSearch: true,
};

const supportEarlyByteUrl =
  "https://forms.clickup.com/f/49xv3-1801/0CWJW7CK7LJD9T3X62";

const support = (url: string) => {
  const feedbackUrl = `${url}?Version=${process.env.REACT_APP_UI_VERSION}`;
  let nw = window.open(
    feedbackUrl,
    "",
    "width=500,height=" +
      window.innerHeight * 1.08 +
      ",left=" +
      (window.screenLeft + window.outerWidth) +
      ",top=" +
      window.screenTop
  );

  if (nw !== null) {
    let containerEl = nw.document.createElement("div");
    nw.document.body.appendChild(containerEl);
    nw.document.title = "Support";
  }
};
