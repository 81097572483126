import { useState } from "react";
import { NumericFormat } from "react-number-format";
import { KemaroDashboardEconomyCostCalculationParameterDto } from "../../../../../../robotcloud-shared/resource-models";
import { GeneralFunctions } from "../../../generalFunctions";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import Edit from "@mui/icons-material/Edit";
import DeleteForever from "@mui/icons-material/DeleteForever";
import { TooltipButton } from "../../../../components/news/TooltipButton";
import { useTranslation } from "react-i18next";

export const KemaroCostCalculationViewParameter = (
  props: kemaroCostCalculationViewParameterProps
) => {
  const { t } = useTranslation();

  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div
      className={`accordion-item accordion-icon-left-side ${
        isExpanded ? "accordion-item-expanded" : ""
      }`}
    >
      <h2 className="accordion-header" id={`heading_${props.parameter.id}`}>
        <table className="table table-borderless mb-0">
          <tbody>
            <tr>
              <td className="align-middle">
                <button
                  className="accordion-button collapsed py-0"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse_${props.parameter.id}`}
                  aria-expanded="false"
                  aria-controls={`collapse_${props.parameter.id}`}
                  onClick={() => setIsExpanded(!isExpanded)}
                >
                  {props.parameter.isTheFirstOne &&
                    t("costCalculation.parameters.default")}
                  {!props.parameter.isTheFirstOne && (
                    <>
                      {t("costCalculation.parameters.validFrom")}
                      <span className="ps-4">
                        {GeneralFunctions.dateToDateString(
                          props.parameter.from
                        )}
                      </span>
                    </>
                  )}
                </button>
              </td>
              <td className="button-header-th">
                <TooltipButton
                  tooltip={t("costCalculation.parameters.copy.tooltip")}
                  onClick={() => props.onCopy(props.parameter)}
                >
                  <FileCopyOutlinedIcon color="primary" />
                </TooltipButton>
              </td>
              <td className="button-header-th">
                <TooltipButton
                  tooltip={t("costCalculation.parameters.edit.tooltip")}
                  onClick={() => props.onEdit(props.parameter)}
                >
                  <Edit color="primary" />
                </TooltipButton>
              </td>
              <td className="button-header-th">
                <TooltipButton
                  tooltip={t("costCalculation.parameters.delete.tooltip")}
                  onClick={() => props.onDelete(props.parameter)}
                >
                  <DeleteForever color="error" />
                </TooltipButton>
              </td>
            </tr>
          </tbody>
        </table>
      </h2>
      <div
        id={`collapse_${props.parameter.id}`}
        className={`accordion-collapse ${isExpanded ? "" : "collapse"}`}
        aria-labelledby={`heading_${props.parameter.id}`}
      >
        <div className="accordion-body">
          <table className="table table-borderless pt-4">
            <thead>
              <tr>
                <th>{t("costCalculation.parameters.parameter")}</th>
                <th>{t("common.manual")}</th>
                <th>{t("robot.entityName")}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {t("cost.hourlyRate")} [{props.currency}/h]
                </td>
                <td>{props.parameter.manualHourlyRate}</td>
                <td>-</td>
              </tr>
              <tr>
                <td>
                  {t("performance.cleaningPerformance")} [m<sup>2</sup>/h]
                </td>
                <td>{props.parameter.manualCleaningPerformance}</td>
                <td>-</td>
              </tr>
              <tr>
                <td>
                  {t("cost.machineCost")} [{props.currency}]
                </td>
                <td>
                  <NumericFormat
                    value={props.parameter.manualMachineCost}
                    displayType={"text"}
                    thousandSeparator={"'"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                </td>
                <td>
                  <NumericFormat
                    value={props.parameter.robotMachineCost}
                    displayType={"text"}
                    thousandSeparator={"'"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  {t("cost.serviceCost")} [{props.currency}/y]
                </td>
                <td>
                  <NumericFormat
                    value={props.parameter.manualServiceCost}
                    displayType={"text"}
                    thousandSeparator={"'"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                </td>
                <td>
                  <NumericFormat
                    value={props.parameter.robotServiceCost}
                    displayType={"text"}
                    thousandSeparator={"'"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                </td>
              </tr>
              <tr>
                <td>{t("cost.machineLifetime")} [y]</td>
                <td>{props.parameter.manualMachineLifetime}</td>
                <td>{props.parameter.robotMachineLifetime}</td>
              </tr>
              <tr>
                <td>
                  {t("cost.cloudCost")} [{props.currency}/y]
                </td>
                <td>-</td>
                <td>
                  <NumericFormat
                    value={props.parameter.robotCloudCost}
                    displayType={"text"}
                    thousandSeparator={"'"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                </td>
              </tr>
              <tr>
                <td>{t("cost.inefficiencyFactor")} [x]</td>
                <td>-</td>
                <td>{props.parameter.robotInefficiencyFactor}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

interface kemaroCostCalculationViewParameterProps {
  currency: string;
  parameter: KemaroDashboardEconomyCostCalculationParameterDto;
  onCopy: (
    costCalculation: KemaroDashboardEconomyCostCalculationParameterDto
  ) => void;
  onEdit: (
    costCalculation: KemaroDashboardEconomyCostCalculationParameterDto
  ) => void;
  onDelete: (
    costCalculation: KemaroDashboardEconomyCostCalculationParameterDto
  ) => void;
}
