import { useLocation, useNavigate } from "react-router-dom";
import { GeneralFunctions } from "../generalFunctions";
import { Button, Dialog } from "@mui/material";
import { useState, useEffect } from "react";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { UsersRequests } from "../../data/users.requests";
import { ToastHelper } from "../../components/news/Toast.helper";
import { ErrorHelper } from "../../uiHelpers/errors.helper";
import { useTranslation } from "react-i18next";
import { tableGenericHandleKeyboardSubjectManager } from "../../components/news";
import useTheme from "@mui/material/styles/useTheme";

export const ForgotPasswordConfirm = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [isFetching, setIsFetching] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [alternativeKey, setAlternativeKey] = useState("");

  const { search } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const ak = GeneralFunctions.getQueryParamValue(search, "ak");
    if (!ak) {
      navigate("/");
    } else {
      setAlternativeKey(ak);
    }
  }, [navigate, search]);

  const errorMessage = t("loginScreen.forgotPassword.messageUsed");

  useEffect(() => {
    const validateAlternativeKey = async (alternativeKey: string) => {
      if (!alternativeKey) {
        return;
      }

      const isValid = await UsersRequests.resetPasswordByAlternativeKeyValidate(
        alternativeKey
      );
      if (!isValid) {
        ToastHelper.error(errorMessage);
        navigate("/");
      }
    };

    validateAlternativeKey(alternativeKey);
  }, [alternativeKey, navigate, errorMessage]);

  useEffect(() => {
    tableGenericHandleKeyboardSubjectManager.setData({
      caller: "ForgotPasswordConfirm",
      handleKeyboard: isModalOpen,
    });
  }, [isModalOpen]);

  const resetHandle = async () => {
    setIsFetching(true);

    try {
      await UsersRequests.resetPasswordByAlternativeKey(alternativeKey);
      ToastHelper.success(t("loginScreen.forgotPassword.messageSuccess"));
      setIsModalOpen(false);
      navigate("/");
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsFetching(false);
  };

  const cancelHandle = async () => {
    setIsFetching(true);

    try {
      await UsersRequests.resetPasswordByAlternativeKeyCancel(alternativeKey);
      ToastHelper.success(t("loginScreen.forgotPassword.messageCancelled"));
      setIsModalOpen(false);
      navigate("/");
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsFetching(false);
  };

  return (
    <Dialog
      className="application-modal"
      open={isModalOpen}
      maxWidth="md"
      onClose={cancelHandle}
    >
      <div className="modal-content">
        <div className="application-modal-header">
          <h3>{t("loginScreen.forgotPassword.title")}</h3>
        </div>

        <div className="application-modal-body">
          <div className="row">
            <div className="col-12 mt-4 ">
              <table
                className="table warning"
                style={{
                  color: theme.palette.swarm?.auth?.warning,
                  backgroundColor: theme.palette.swarm?.auth?.warningBackground,
                  border: theme.palette.swarm?.auth?.warningBorder,
                }}
              >
                <tbody>
                  <tr>
                    <td className="align-middle">
                      <WarningAmberOutlinedIcon
                        className="warning-icon"
                        style={{
                          color: theme.palette.swarm?.auth?.warningIcon,
                        }}
                      />
                    </td>
                    <td className="warning-text">
                      <p>{t("loginScreen.forgotPassword.warningReset")}</p>
                      <p>{t("loginScreen.forgotPassword.warningContinue")}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="application-modal-footer">
          {!isFetching && (
            <>
              <Button
                variant="contained"
                className="button-with-text"
                color="primary"
                onClick={resetHandle}
              >
                {t("common.buttons.reset")}
              </Button>

              <Button
                className="modal-cancel-button button-with-text"
                variant="outlined"
                color="primary"
                onClick={cancelHandle}
              >
                {t("common.buttons.cancel")}
              </Button>
            </>
          )}
        </div>
      </div>
    </Dialog>
  );
};
