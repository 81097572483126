import { AxiosResponse } from "axios";
import { diff, jsonPatchPathConverter } from "just-diff";
import {
  EncodedFileDto,
  PaginatedListDtoOfWorkflowCompleteDto,
  PaginatedListDtoOfWorkflowReducedDto,
  WorkflowCompleteDto,
  WorkflowFilterCriteriaDto,
  WorkflowPostDto,
  WorkflowReducedDto,
} from "../../../robotcloud-shared/resource-models";
import { AxiosRequests } from "./axios.requests";
import { BaseRequest } from "./BaseRequest";

export class WorkflowsRequests extends BaseRequest {
  static controller = "/api/webapp/v1.0/workflows";

  static completeWorkflow = async (
    workflowId: string,
    accepted: boolean,
    processLinkId?: string
  ): Promise<AxiosResponse> => {
    const processLinkIdParameter = processLinkId
      ? `?processLinkId=${processLinkId}`
      : "";

    return await AxiosRequests.put(
      `${WorkflowsRequests.controller}/${workflowId}/complete/${accepted}?${processLinkIdParameter}`
    );
  };

  static getPdf = async (
    workflowId: string,
    abortController?: AbortController
  ): Promise<AxiosResponse> => {
    return await AxiosRequests.getBlob(
      `${WorkflowsRequests.controller}/${workflowId}/pdfFile`,
      abortController
    );
  };

  static setPdf = async (
    workflowId: string,
    file: File
  ): Promise<AxiosResponse> => {
    const formData = new FormData();
    formData.append("file", file);
    return await AxiosRequests.put(
      `${WorkflowsRequests.controller}/${workflowId}/pdfFile`,
      formData
    );
  };

  static getList = async (filter: WorkflowFilterCriteriaDto) => {
    const { data } = await AxiosRequests.get(
      `${WorkflowsRequests.controller}?${WorkflowsRequests.objectToQueryString(
        filter
      )}`
    );
    return data as PaginatedListDtoOfWorkflowReducedDto;
  };

  static getListCompleteWokflows = async (
    filter: WorkflowFilterCriteriaDto
  ) => {
    const { data } = await AxiosRequests.get(
      `${
        WorkflowsRequests.controller
      }/complete?${WorkflowsRequests.objectToQueryString(filter)}`
    );
    return data as PaginatedListDtoOfWorkflowCompleteDto;
  };

  static getById = async (id: string) => {
    const { data } = await AxiosRequests.get(
      `${WorkflowsRequests.controller}/${id}`
    );
    return data as WorkflowCompleteDto;
  };

  static create = async (dto: WorkflowPostDto) => {
    const { data } = await AxiosRequests.post(
      WorkflowsRequests.controller,
      dto
    );
    return data as WorkflowCompleteDto;
  };

  static modify = async (
    originalDto: WorkflowReducedDto,
    modifiedDto: WorkflowReducedDto
  ) => {
    const allowProperties = [
      "/type",
      "/abortable",
      "/required",
      "/target",
      "/order",
      "/validFrom",
      "/displayInstructions",
      "/displayAgreement",
      "/displayLinkUrl",
      "/displayTitle",
      "/enabled",
      "/name",
    ];

    const jsonPatch = diff(
      originalDto,
      modifiedDto,
      jsonPatchPathConverter
    ).filter((x) => allowProperties.includes(x.path));

    const { data } = await AxiosRequests.patch(
      `${WorkflowsRequests.controller}/${originalDto.id}`,
      jsonPatch
    );
    return data as WorkflowCompleteDto;
  };

  static delete = async (id: string) => {
    return await AxiosRequests.delete(`${WorkflowsRequests.controller}/${id}`);
  };

  static csv = async (id: string) => {
    const url = `${WorkflowsRequests.controller}/${id}/csv`;

    const { data } = await AxiosRequests.get(url);
    return data as EncodedFileDto;
  };
}
