import { useTranslation } from "react-i18next";
import { ApplicationBar, ToastHelper } from "../../components/news";
import { CustomFieldContext } from "./CustomFieldContext";
import { KemaroAdministrationTabsNav } from "../kemaro/administration/KemaroAdministrationTabsNav";
import { CustomFieldLeftSide } from "./CustomFieldLeftSide";
import { useEffect, useState } from "react";
import {
  OrderDto,
  PaginationDto,
  paginationDtoDefault,
} from "../../data/common";
import "./CustomFields.scss";
import {
  CustomFieldDto,
  CustomFieldFilterCriteriaDto,
  CustomFieldListDto,
  CustomFieldTypeDto,
} from "../../../../robotcloud-shared/resource-models";
import { CustomFieldRequests } from "../../data/customField.request";
import { GeneralFunctions } from "../generalFunctions";
import { ErrorHelper } from "../../uiHelpers/errors.helper";
import { CustomFieldView } from "./CustomFieldView";

export const CustomFields = () => {
  const { t } = useTranslation();

  const [currentType, setCurrentType] = useState<
    CustomFieldTypeDto | undefined
  >(undefined);
  const [isFetchingList, setIsFetchingList] = useState(false);
  const [isFetchingItem, setIsFetchingItem] = useState(false);
  const [pagination, setPagination] = useState<PaginationDto>({
    ...paginationDtoDefault,
  });
  const [dataOrder, setDataOrder] = useState<OrderDto>({
    orderColumns: "title",
    order: "Asc",
  });
  const [items, setItems] = useState<CustomFieldListDto[]>([]);
  const [currentId, setCurrentId] = useState<string | undefined>(undefined);
  const [currentItem, setCurrentItem] = useState<CustomFieldDto | undefined>(
    undefined
  );
  const [lastSearch, setLastSearch] = useState("--");
  const [textToSearch, setTextToSearch] = useState("");

  useEffect(() => {
    if (!currentType) return;

    const filterCriteria: CustomFieldFilterCriteriaDto = {
      type: currentType.type,
      id: null,
      textToSearch: textToSearch,
      page: pagination.currentPage,
      maxItemCount: pagination.maxItemCount,
      orderBy: dataOrder.order,
      orderColumns: dataOrder.orderColumns,
    };

    const strFilterCriteria = JSON.stringify(filterCriteria);
    if (lastSearch === strFilterCriteria) {
      return;
    }

    setLastSearch(strFilterCriteria);
    getList(filterCriteria, currentItem, pagination, false);
  }, [
    currentType,
    pagination,
    dataOrder,
    lastSearch,
    currentItem,
    textToSearch,
  ]);

  useEffect(() => {
    if (currentId) {
      getItem(isFetchingItem, currentItem, currentId);
    }
  }, [currentId, currentItem, isFetchingItem]);

  const refreshList = async () => {
    const filterCriteria: CustomFieldFilterCriteriaDto = {
      type: currentType!.type,
      id: null,
      textToSearch: null,
      page: pagination.currentPage,
      maxItemCount: pagination.maxItemCount,
      orderBy: dataOrder.order,
      orderColumns: dataOrder.orderColumns,
    };

    await getList(filterCriteria, currentItem, pagination, false);
  };

  const refreshItem = async () => {
    getItem(isFetchingItem, undefined, currentItem!.id);
  };

  const getList = async (
    fc: CustomFieldFilterCriteriaDto,
    ci: CustomFieldDto | undefined,
    p: PaginationDto,
    selectFirstItem: boolean
  ) => {
    setIsFetchingList(true);

    try {
      const data = await CustomFieldRequests.getList(fc);
      setItems(data.items);

      GeneralFunctions.ChangePagination(p, data, setPagination);

      if (data.items.length > 0) {
        if (selectFirstItem || !ci || !data.items.some((x) => x.id === ci.id)) {
          setCurrentId(data.items[0].id);
        }
      }
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsFetchingList(false);
  };

  const getItem = async (
    fetching: boolean,
    ci: CustomFieldDto | undefined,
    id: string
  ) => {
    if (fetching || ci?.id === id) return;

    setIsFetchingItem(true);

    try {
      setCurrentItem(await CustomFieldRequests.getById(id));
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsFetchingItem(false);
  };

  const deleteCurrentItem = async () => {
    let result = false;

    try {
      await CustomFieldRequests.delete(currentItem!.id);
      const nextItem = GeneralFunctions.nextItemFromArray<CustomFieldListDto>(
        items,
        currentItem!.id
      );

      await refreshList();

      if (!nextItem) {
        setCurrentId(undefined);
        setCurrentItem(undefined);
      } else {
        setCurrentId(nextItem.id);
        await getItem(isFetchingItem, undefined, nextItem.id);
      }

      result = true;
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    return result;
  };

  const textSearchChange = (newTextToSearch: string) => {
    if (newTextToSearch === textToSearch) return;

    setTextToSearch(newTextToSearch);
    pageChange({
      ...pagination,
      currentPage: 1,
    });
  };

  const pageChange = (pagination: PaginationDto) => {
    setCurrentId(undefined);
    setCurrentItem(undefined);
    setPagination(pagination);
  };

  const changeType = (newType: CustomFieldTypeDto | undefined) => {
    setCurrentType(newType);
    setPagination({ ...pagination, currentPage: 1 });
  };

  return (
    <CustomFieldContext.Provider
      value={{
        isFetchingList,
        isFetchingItem,
        pagination,
        pageChange,
        currentType,
        setCurrentType: changeType,
        currentItem,
        setCurrentItem,
        refreshItem,
        currentItemIsFeching: isFetchingItem,
        dataOrder,
        setDataOrder,
        items,
        currentId,
        setCurrentId,
        refreshList,
        deleteCurrentItem,
      }}
    >
      <div className="page-content">
        <div className="page-header">
          <ApplicationBar
            title={t("applicationBar.administration.title")}
            search={textSearchChange}
          />
        </div>

        <KemaroAdministrationTabsNav currentTab="custom-fields" />
        <div className="page-body d-flex custom-fields-body">
          <CustomFieldLeftSide />
          <CustomFieldView />
        </div>
      </div>
    </CustomFieldContext.Provider>
  );
};
