import {
  KemaroMissionListDto,
  ApiLoginCompleteDto,
} from "../../../../../robotcloud-shared/resource-models";
import { GeneralFunctions } from "../../generalFunctions";
import { TooltipContainer } from "../../../components/news/TooltipContainer";
import { useTranslation } from "react-i18next";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import BuildCircleOutlinedIcon from "@mui/icons-material/BuildCircleOutlined";
import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";
import { TableGenericColumn } from "../../../components/news/TableGeneric/models";
import useTheme from "@mui/material/styles/useTheme";

export function KemaroMissionsColumnDefinition(
  showTags: boolean,
  me: ApiLoginCompleteDto | undefined
) {
  const { t } = useTranslation();
  const theme = useTheme();

  let columnsDefinition: TableGenericColumn[] = [
    {
      title: t("mission.statusColumn"),
      property: "status",
      itemClassName: "icon-container px-2",
      cellTemplate: (item: KemaroMissionListDto) => (
        <>
          {item.status === "Successful" ? (
            <CheckCircleOutlinedIcon
              className="success"
              style={{
                color: theme.palette.swarm?.kemaroMissions?.bodyLeftSideSuccess,
              }}
            />
          ) : (
            <CancelOutlinedIcon
              className="error"
              style={{
                color: theme.palette.swarm?.kemaroMissions?.bodyLeftSideError,
              }}
            />
          )}

          {(me?.userAccessType === "Earlybyte" ||
            me?.userAccessType === "Manufacturer") &&
            !item.hasCustomer && (
              <TooltipContainer text={t("mission.hasNoCustomer")}>
                <BuildCircleOutlinedIcon
                  className="internal ms-2"
                  style={{
                    color:
                      theme.palette.swarm?.kemaroMissions?.bodyLeftSideInternal,
                  }}
                />
              </TooltipContainer>
            )}
        </>
      ),
    },
  ];

  if (showTags) {
    columnsDefinition = [
      ...columnsDefinition,
      {
        title: "",
        headerClassName: "px-2 td-1-icon text-center",
        titleTemplate: (
          <LabelOutlinedIcon
            className="primary-text"
            sx={{ color: "swarm.general.primaryText" }}
          />
        ),
        property: "tags",
        itemClassName: "td-1-icon px-1 text-center ",
        cellTemplate: (item: KemaroMissionListDto) => (
          <>
            {item.tags.length > 0 && (
              <TooltipContainer
                text={item.tags.toString().replaceAll(",", ", ")}
              >
                <LabelOutlinedIcon
                  className="internal"
                  style={{
                    color:
                      theme.palette.swarm?.kemaroMissions?.bodyLeftSideInternal,
                  }}
                />
              </TooltipContainer>
            )}
          </>
        ),
      },
    ];
  }

  columnsDefinition = [
    ...columnsDefinition,
    {
      title: t("mission.robotColumn"),
      property: "robotName",
    },
    {
      title: t("mission.locationColumn"),
      property: "robotLocation",
    },
    {
      title: t("mission.startTimeColumn"),
      property: "startTime",
      cellTemplate: (item: KemaroMissionListDto) => (
        <>{GeneralFunctions.dateToTimeDateString(item.startTime)}</>
      ),
    },
    {
      title: t("mission.durationColumn"),
      canSort: false,
      cellTemplate: (item: KemaroMissionListDto) => (
        <>
          {GeneralFunctions.calculateDuration(item.startTime, item.stopTime) ===
          0
            ? "< 1 min"
            : GeneralFunctions.durationToDayHoursMinutesString(
                GeneralFunctions.calculateDuration(
                  item.startTime,
                  item.stopTime
                )
              )}
        </>
      ),
    },
  ];

  return columnsDefinition;
}
