import { SortOrder } from "../../../robotcloud-shared/resource-models";

export interface PaginationDto {
  currentPage: number;
  maxItemCount: number;
  totalItems: number;
}

export interface OrderDto {
  orderColumns: string;
  order: SortOrder;
}

export const paginationDtoDefault: PaginationDto = {
  currentPage: 1,
  maxItemCount: 25,
  totalItems: 0,
};

export const orderDtoDefault: OrderDto = {
  orderColumns: "name",
  order: "Asc",
};

export interface CustomFieldAutocompleteDto {
  text: string;
  code: string;
}
