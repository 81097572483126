import { useState, useEffect } from "react";
import { DashboardEconomyFilterDto, DashboardRobotInformationDto } from "../../../robotcloud-shared/resource-models";
import { ToastHelper } from "../components/news";
import { KemaroDashboardEconomyRequests } from "../data/kemaro/KemaroDashboardEconomy.request";
import { ErrorHelper } from "../uiHelpers/errors.helper";

export const  useKemaroRobotData = (isCurrentWeek: boolean, filter: DashboardEconomyFilterDto) => {
    const [isWorking, setIsWorking] = useState(true);
    const [robots, setRobots] = useState<DashboardRobotInformationDto[]>([]);

    useEffect(() => {
      const getData = async () => {
        try {
          const data = isCurrentWeek
            ? await KemaroDashboardEconomyRequests.getEconomyCostWeeklyWeekRobots(filter)
            : await KemaroDashboardEconomyRequests.getEconomyCostWeeklyLifetimeRobots(filter);
          setRobots(data);
        } catch (error) {
          ToastHelper.errors(ErrorHelper.process(error));
        }
        setIsWorking(false);
      };

      getData();
    }, [isCurrentWeek, filter]);

    return { isWorking, robots };
  }
