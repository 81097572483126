import { DashboardSpHistoryMessageLogsDto } from "../../../../../../../../robotcloud-shared/resource-models";
import { useState } from "react";
import { LeftSide } from "./LeftSide";
import { RightSide } from "./RightSide";

export const ExecutionHistoryModalContent = ({
  isGettingExecutionHistory,
  executionHistory,
}: ExecutionHistoryModalContentProps) => {
  const [currentItem, setCurrentItem] = useState<
    DashboardSpHistoryMessageLogsDto | undefined
  >(undefined);

  if (isGettingExecutionHistory) {
    return <></>;
  }

  return (
    <div className="d-flex pb-4">
      <div className="d-flex">
        <LeftSide
          executionHistory={executionHistory}
          currentItem={currentItem}
          setCurrentItem={setCurrentItem}
        />

        <RightSide currentItem={currentItem} />
      </div>
    </div>
  );
};

interface ExecutionHistoryModalContentProps {
  isGettingExecutionHistory: boolean;
  executionHistory: DashboardSpHistoryMessageLogsDto[];
}
