import { PermissionMatrixDto } from "../../../robotcloud-shared/resource-models";
import { AxiosRequests } from "./axios.requests";
import { BaseRequest } from "./BaseRequest";

export class PermissionsRequest extends BaseRequest {
  static controller = "api/webapp/v1.0/Permissions";

  static getList = async () => {
    const { data } = await AxiosRequests.get(PermissionsRequest.controller);
    return data as PermissionMatrixDto;
  };
}
