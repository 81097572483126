import { useState, useEffect, useContext } from "react";
import { DealersRequests } from "../data/dealers.request";
import AuthorizationContext from "../views/auth/authorizationContext";
import { ErrorHelper } from "../uiHelpers/errors.helper";
import { ToastHelper } from "../components/news";
import { GeneralFunctions } from "../views/generalFunctions";
import { OrderDto, PaginationDto, paginationDtoDefault } from "../data/common";
import {
  ApiLoginCompleteDto,
  DealerCompleteDto,
  DealerDto,
  DealerFilterCriteriaDto,
  DealerPostDto,
} from "../../../robotcloud-shared/resource-models";
import { QueryParamHelper } from "../uiHelpers/queryParamHelper";

export const useDealers = (
  paginationProp?: PaginationDto,
  idsToRemove?: string[]
) => {
  const { me } = useContext(AuthorizationContext);
  const [isFetchingList, setIsFetchingList] = useState(false);
  const [isFetchingItem, setIsFetchingItem] = useState(false);
  const [pagination, setPagination] = useState<PaginationDto>(
    paginationProp ?? {
      ...paginationDtoDefault,
    }
  );
  const [dataOrder, setDataOrder] = useState<OrderDto>({
    orderColumns: "name",
    order: "Asc",
  });
  const [items, setItems] = useState<DealerDto[]>([]);
  const [currentId, setCurrentId] = useState<string | undefined>(undefined);
  const [currentItem, setCurrentItem] = useState<DealerCompleteDto | undefined>(
    undefined
  );
  const [textToSearch, setTextToSearch] = useState("");
  const [lastSearch, setLastSearch] = useState<string>("");
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [openCreatePopup, setOpenCreatePopup] = useState(false);
  const [createPopupIsWorking, setCreatePopupIsWorking] = useState(false);
  const callGetList = async (selectFirstItem: boolean) => {
    let filterCriteria: DealerFilterCriteriaDto = {
      page: pagination.currentPage,
      maxItemCount: pagination.maxItemCount,
      orderBy: dataOrder.order,
      orderColumns: dataOrder.orderColumns,
      textToSearch: textToSearch,
      id: null,
    };

    await getList(filterCriteria, pagination, me, currentItem, selectFirstItem);
  };

  const getList = async (
    filterCriteria: DealerFilterCriteriaDto,
    page: PaginationDto,
    me: ApiLoginCompleteDto | undefined,
    ci: DealerCompleteDto | undefined,
    selectFirstItem: boolean
  ) => {
    setIsFetchingList(true);

    try {
      const data = await DealersRequests.getList(filterCriteria);
      if (idsToRemove) {
        data.items = data.items.filter((x) => !idsToRemove.includes(x.id));
      } else {
        setItems(data.items);
      }

      if (data.items.length > 0) {
        if (selectFirstItem || !ci || !data.items.some((x) => x.id === ci.id)) {
          setCurrentId(data.items[0].id);
        }
      }

      GeneralFunctions.ChangePagination(page, data, setPagination);

      if (filterCriteria.id) {
        setCurrentId(filterCriteria.id);
      } else if (me?.userAccessType === "Dealer") {
        setCurrentId(me.idDealer!);
      }
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsFetchingList(false);
  };
  useEffect(() => {
    const filterCriteria: DealerFilterCriteriaDto = {
      page: pagination.currentPage,
      maxItemCount: pagination.maxItemCount,
      orderBy: dataOrder.order,
      orderColumns: dataOrder.orderColumns,
      textToSearch: textToSearch,
      id: null,
    };

    const strFilterCriteria = JSON.stringify(filterCriteria);
    if (lastSearch !== strFilterCriteria) {
      if (isFirstTime) {
        const qph = new QueryParamHelper(window.location.search);
        filterCriteria.id = qph.getValue("id") ?? null;
      }

      setLastSearch(strFilterCriteria);
      getList(filterCriteria, pagination, me, currentItem, false);
      setIsFirstTime(false);
    }
  }, [
    pagination,
    dataOrder,
    textToSearch,
    me,
    lastSearch,
    isFirstTime,
    currentItem,
  ]);

  const handleEdit = async (item: DealerCompleteDto) => {
    setIsFetchingItem(true);

    try {
      setCurrentItem(await DealersRequests.modify(currentItem!, item));
      await callGetList(false);
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsFetchingItem(false);
  };

  const handleDelete = async (item: DealerCompleteDto) => {
    setIsFetchingItem(true);
    let selectFirstItem = false;

    try {
      await DealersRequests.softDelete(item.id);
      const nextItem = GeneralFunctions.nextItemFromArray<DealerDto>(
        items,
        item.id
      );

      setCurrentId(nextItem?.id ?? undefined);
      if (nextItem) await getDealer(false, undefined, nextItem.id);
      else {
        setCurrentItem(undefined);
        selectFirstItem = true;
      }

      await callGetList(selectFirstItem);
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsFetchingItem(false);
  };

  const handleCreateSave = async (postDto: DealerPostDto) => {
    setIsFetchingList(true);
    setCreatePopupIsWorking(true);

    try {
      const newDealer = await DealersRequests.create(postDto);
      setCurrentId(newDealer.id);
      setCurrentItem(newDealer);
      await callGetList(false);
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setOpenCreatePopup(false);
    setCreatePopupIsWorking(false);
    setIsFetchingList(false);
  };

  const textSearchChange = (newTextToSearch: string) => {
    if (newTextToSearch === textToSearch) return;
    setTextToSearch(newTextToSearch);
    pageChange({
      ...pagination,
      currentPage: 1,
    });
  };

  const pageChange = (pagination: PaginationDto) => {
    setCurrentId(undefined);
    setCurrentItem(undefined);
    setPagination(pagination);
  };

  useEffect(() => {
    if (currentId && (!currentItem || currentItem.id !== currentId)) {
      getDealer(isFetchingItem, currentItem, currentId);
    }
  }, [currentId, me, currentItem, isFetchingItem]);

  const getDealer = async (
    isFetching: boolean,
    ci: DealerCompleteDto | undefined,
    id: string
  ) => {
    if (isFetching || ci?.id === id) return;

    setIsFetchingItem(true);

    try {
      const item = await DealersRequests.getByIdComplete(id);
      setCurrentId(item.id);
      setCurrentItem(item);
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsFetchingItem(false);
  };
  return {
    // State variables
    isFetchingList,
    isFetchingItem,
    pagination,
    dataOrder,
    items,
    currentItem,
    openCreatePopup,
    createPopupIsWorking,

    // Set state functions
    setDataOrder,
    setItems,
    setOpenCreatePopup,
    setCurrentId,

    // Handler functions
    handleEdit,
    handleDelete,
    handleCreateSave,
    textSearchChange,
    pageChange,
  };
};
