import { TimezoneReducedDto } from "../../../robotcloud-shared/resource-models";
import { AxiosRequests } from "./axios.requests";
import { BaseRequest } from "./BaseRequest";

export class TimeZonesRequest extends BaseRequest {
  static controller = "api/webapp/v1.0/timezones";

  static getList = async () => {
    const { data } = await AxiosRequests.get(`${TimeZonesRequest.controller}`);
    return data as TimezoneReducedDto[];
  };

  static getIanaList = async () => {
    const { data } = await AxiosRequests.get(
      `${TimeZonesRequest.controller}/iana-list`
    );

    return data as string[];
  };
}
