import { useEffect, useState } from "react";
import { PermissionMatrixDto } from "../../../../robotcloud-shared/resource-models";
import { ToastHelper, ApplicationBar } from "../../components/news";
import { PermissionsRequest } from "../../data/permissions.request";
import { ErrorHelper } from "../../uiHelpers/errors.helper";
import { PermissionsMatrixColumnHeader } from "./PermissionsMatrixColumnHeader";
import { PermissionsMatrixSectionRow } from "./PermissionsMatrixSectionRow";
import "./permissions-matrix.scss";
import { useTranslation } from "react-i18next";
import { KemaroAdministrationTabsNav } from "../kemaro/administration/KemaroAdministrationTabsNav";
import useTheme from "@mui/material/styles/useTheme";

export const PermissionsMatrix = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [permissionMatrix, setPermissionMatrix] = useState<
    PermissionMatrixDto | undefined
  >(undefined);

  useEffect(() => {
    getMatrix();
  }, []);

  const getMatrix = async () => {
    try {
      setPermissionMatrix(await PermissionsRequest.getList());
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }
  };

  return (
    <>
      <div className="page-content">
        <div className="page-header">
          <ApplicationBar
            title={t("applicationBar.administration.title")}
            canSearch={false}
          />
        </div>
        <KemaroAdministrationTabsNav currentTab="permissions" />
        {permissionMatrix && (
          <div className="permissions-matrix-container ">
            <table>
              <thead
                style={{
                  backgroundColor:
                    theme.palette.swarm?.permissionsMatrix
                      ?.containerTheadBackground,
                }}
              >
                <tr style={{ height: "56px" }}>
                  <th>
                    <span
                      className="permissions-matrix-filler"
                      style={{
                        color: theme.palette.swarm?.permissionsMatrix?.filler,
                      }}
                    >
                      CompanyStructure.CustomerContact
                    </span>
                  </th>

                  {permissionMatrix.userTypes.map((x) =>
                    PermissionsMatrixColumnHeader(x, theme)
                  )}
                </tr>
              </thead>
              <tbody>
                {permissionMatrix.sections.map((x) => (
                  <PermissionsMatrixSectionRow
                    key={`secRow_${x.title}`}
                    section={x}
                    length={permissionMatrix.userTypes.length + 1}
                    theme={theme}
                  />
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
};
