import { diff, jsonPatchPathConverter } from "just-diff";
import {
  DealerCompleteDto,
  DealerDto,
  DealerFilterCriteriaDto,
  DealerPostDto,
  DealerReducedDto,
  PaginatedListDtoOfDealerDto,
  SearchTextDtoOfGuid,
  SearchTextResultDtoOfDealerDto,
} from "../../../robotcloud-shared/resource-models";
import { AxiosRequests } from "./axios.requests";
import { BaseRequest } from "./BaseRequest";
import { prepareSearchTextDto } from "./functions";

export class DealersRequests extends BaseRequest {
  static controller = "api/webapp/v1.0/organizations/Dealers";

  static getList = async (filterCriteria: DealerFilterCriteriaDto) => {
    const { data } = await AxiosRequests.get(
      `${DealersRequests.controller}?${DealersRequests.objectToQueryString(
        filterCriteria
      )}`
    );
    return data as PaginatedListDtoOfDealerDto;
  };

  static getByIdComplete = async (id: string) => {
    const { data } = await AxiosRequests.get(
      `${DealersRequests.controller}/${id}/complete`
    );
    return data as DealerCompleteDto;
  };

  static getById = async (id: string) => {
    const { data } = await AxiosRequests.get(
      `${DealersRequests.controller}/${id}`
    );
    return data as DealerDto;
  };

  static modify = async (
    originalDto: DealerCompleteDto,
    newDto: DealerCompleteDto
  ) => {
    const allowProperties = ["/name"];

    const jsonPatch = diff(originalDto, newDto, jsonPatchPathConverter).filter(
      (x) => allowProperties.includes(x.path)
    );

    const { data } = await AxiosRequests.patch(
      `${DealersRequests.controller}/${originalDto.id}`,
      jsonPatch
    );
    return data as DealerCompleteDto;
  };

  static create = async (postDto: DealerPostDto) => {
    const { data } = await AxiosRequests.post(
      DealersRequests.controller,
      postDto
    );
    return data as DealerCompleteDto;
  };

  static getFromCustomer = async (customerId: string) => {
    const { data } = await AxiosRequests.get(
      `${DealersRequests.controller}/from-customer/${customerId}`
    );
    return data as DealerReducedDto[];
  };

  static getSearchText = async (searchTextDto: SearchTextDtoOfGuid) => {
    const { data } = await AxiosRequests.post(
      `${DealersRequests.controller}/search-text`,
      prepareSearchTextDto(searchTextDto)
    );
    return data as SearchTextResultDtoOfDealerDto;
  };

  static softDelete = async (id: string) => {
    await AxiosRequests.delete(`${DealersRequests.controller}/${id}/soft`);
  };
}
