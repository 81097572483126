import { useState, useEffect } from "react";
import {
  RobotModelCompleteDto,
  RobotModelPostDto,
} from "../../../../robotcloud-shared/resource-models";
import { TooltipButton } from "../../components/news/TooltipButton";
import { ViewHeader } from "../../components/news/ViewHeader";
import Edit from "@mui/icons-material/Edit";
import DeleteForever from "@mui/icons-material/DeleteForever";
import { PermissionsHelper } from "../../uiHelpers/permissionsHelper";
import { defaultModalState } from "../../uiHelpers/dtos/modalState";
import { YesNoOkDialog } from "../../components/news/YesNoOkDialog";
import { RobotModelsModal } from "./RobotModelsModal";
import { useTranslation } from "react-i18next";

export const RobotModelsViewTitle = (props: robotModelsViewTitleProps) => {
  const { t } = useTranslation();

  const [canModify, setCanModify] = useState(true);
  const [canDelete, setCanDelete] = useState(true);
  const [editModalState, setEditModalState] = useState({
    ...defaultModalState,
  });
  const [deleteModalState, setDeleteModalState] = useState({
    ...defaultModalState,
  });

  useEffect(() => {
    setCanModify(PermissionsHelper.allow("RobotModels", "Edit"));
    setCanDelete(PermissionsHelper.allow("RobotModels", "Delete"));
  }, []);

  const handleSave = async (modified: RobotModelPostDto) => {
    setEditModalState({ ...editModalState, isWorking: true });

    const result = await props.onModify(props.item, modified);
    if (result) setEditModalState({ ...defaultModalState });
    else setEditModalState({ ...editModalState, isWorking: false });

    return result;
  };

  const handleDelete = async () => {
    setDeleteModalState({ ...defaultModalState, isWorking: true });

    await props.onDelete(props.item);
    setDeleteModalState({ ...defaultModalState, isWorking: false });
  };

  return (
    <ViewHeader
      title={props.item.name}
      copyLinkId={props.item.id}
      canEdit={false}
      canDelete={false}
    >
      <>
        {canModify && (
          <TooltipButton
            aria-label="edit"
            tooltip={t("viewHeaderButtons.edit")}
            onClick={() =>
              setEditModalState({ ...editModalState, isOpen: true })
            }
          >
            <Edit color="primary" />
          </TooltipButton>
        )}

        {canDelete && (
          <TooltipButton
            disabled={props.item.robotsQuantity > 0}
            aria-label="delete"
            tooltip={t("viewHeaderButtons.delete")}
            onClick={() =>
              setDeleteModalState({ ...deleteModalState, isOpen: true })
            }
          >
            {props.item.robotsQuantity > 0 ? (
              <DeleteForever color="disabled" />
            ) : (
              <DeleteForever color="error" />
            )}
          </TooltipButton>
        )}

        {editModalState.isOpen && (
          <RobotModelsModal
            title={t("robotModel.edit.title")}
            isOpenModal={editModalState.isOpen}
            onSave={(dto) => handleSave(dto)}
            onCancel={() => setEditModalState({ ...defaultModalState })}
            isWorking={editModalState.isWorking}
            dto={props.item}
            image={props.image}
          />
        )}

        <YesNoOkDialog
          open={deleteModalState.isOpen}
          size="md"
          type="yesNo"
          title={t("robotModel.delete.title")}
          message={`${t("robotModel.delete.warning")} ${props.item.name}?`}
          onYes={handleDelete}
          onNo={() => setDeleteModalState({ ...defaultModalState })}
          isWorking={deleteModalState.isWorking}
        />
      </>
    </ViewHeader>
  );
};

export interface robotModelsViewTitleProps {
  item: RobotModelCompleteDto;
  image: string;
  onModify: (
    original: RobotModelCompleteDto,
    modified: RobotModelPostDto
  ) => Promise<boolean>;
  onDelete: (item: RobotModelCompleteDto) => Promise<boolean>;
}
