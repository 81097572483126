import { useContext, useEffect, useState } from "react";
import { ManufacturerRequests } from "../../data/manufacturers.Requests";
import AuthorizationContext from "./authorizationContext";
import { useNavigate } from "react-router-dom";
import {
  ManufacturerConfigurationDto,
  ManufacturerReducedDto,
} from "../../../../robotcloud-shared/resource-models";
import { StorageManagerService } from "../../data/storageManager.service";
import { ApplicationService } from "../../data/application.service";
import { ToastHelper } from "../../components/news/Toast.helper";
import { ErrorHelper } from "../../uiHelpers/errors.helper";
import { KemaroManufacturersRequests } from "../../data/kemaro/kemaroManufacturers.Requests";

export const Authenticated = () => {
  const [retry, setRetry] = useState(0);
  const {
    setMe,
    setConfiguration,
    currentManufacturer,
    setCurrentManufacturer,
    allowedManufacturers,
    setAllowedManufacturers,
  } = useContext(AuthorizationContext);

  const [lookedForManufacturer, setLookedForManufacturer] = useState(false);

  useEffect(() => {
    const getAllowedManufacturers = async () => {
      try {
        const manufacturers = await ManufacturerRequests.getAllowed();
        if (manufacturers) {
          setAllowedManufacturers(manufacturers);
          setCurrentManufacturer(
            manufacturers.filter((x) => x.name.toLowerCase() === "kemaro")[0] ??
              manufacturers[0]
          );
        } else {
          setAllowedManufacturers([]);
          setCurrentManufacturer(undefined);
        }
        setLookedForManufacturer(true);
      } catch (error) {
        setRetry(retry + 1);
      }
    };

    if (
      (!currentManufacturer ||
        !allowedManufacturers ||
        allowedManufacturers.length === 0) &&
      retry < 10
    ) {
      // The first request has no delay, next ones have an increasing
      // delay from 1 to 9 seconds.
      setTimeout(() => {
        getAllowedManufacturers();
      }, 1000 * retry);
    }
  }, [
    allowedManufacturers?.length,
    currentManufacturer,
    retry,
    setAllowedManufacturers,
    setCurrentManufacturer,
  ]);

  const navigate = useNavigate();
  useEffect(() => {
    const getMe = async (currentManufacturer?: ManufacturerReducedDto) => {
      try {
        StorageManagerService.setApiTokenContainer(
          await ApplicationService.getApiTokenContainer(currentManufacturer?.id)
        );

        const me = await ApplicationService.getMe();
        setMe(me);
        StorageManagerService.setMe(me);
      } catch (error) {
        if (
          (error as any).response?.data?.innerMessages[0] ===
          "User does not exist"
        ) {
          navigate("/unassigned");
        } else if ((error as string) === "No token found") {
          // When token is not found, do nothing and let the user login
        } else {
          ToastHelper.errors(ErrorHelper.process(error));
        }
      }

      try {
        let configuration: ManufacturerConfigurationDto | undefined = undefined;
        if (currentManufacturer?.name.toLowerCase() === "kemaro") {
          configuration = await KemaroManufacturersRequests.getConfiguration();
        }

        setConfiguration(configuration);
      } catch (error) {
        ToastHelper.errors(ErrorHelper.process(error));
      }
    };

    if (lookedForManufacturer) {
      getMe(currentManufacturer);
    }
  }, [currentManufacturer, lookedForManufacturer]);

  return <></>;
};
