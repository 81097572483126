import { ReactElement } from "react";
import { BadgeItem, BadgesPresenter } from "./BadgesPresenter";
import "./multiselect.scss";

export function Multiselect(props: multiselectProps) {
  return (
    <div className="multi-select-container">
      {props.children && (
        <div className="search-container">{props.children}</div>
      )}

      <div className="chips-container">
        <BadgesPresenter
          className="mt-1"
          items={props.items}
          itemClick={(x) => props.itemRemove(x)}
        />
      </div>
    </div>
  );
}

interface multiselectProps {
  children?: ReactElement | never[];
  items: BadgeItem[];
  itemRemove: (item: BadgeItem) => void;
}
