import { useContext, useEffect, useState } from "react";
import { NotificationConfigurationDto, TestEmailDto, UserCompleteDto } from "../../../robotcloud-shared/resource-models";
import { ToastHelper } from "../components/news";
import { UsersRequests } from "../data/users.requests";
import { ErrorHelper } from "../uiHelpers/errors.helper";
import { PermissionsHelper } from "../uiHelpers/permissionsHelper";
import AuthorizationContext from "../views/auth/authorizationContext";
import { StorageManagerService } from "../data/storageManager.service";
import { ApplicationService } from "../data/application.service";
import i18n from "../i18n/config";

export const useUser = ( setIsLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
    const [user, setUser] = useState<UserCompleteDto | undefined>(undefined);
    const [isModifyModalOpen, setIsModifyModalOpen] = useState(false);
    const [isMigrateAccountModalOpen, setIsMigrateAccountModalOpen] =
      useState(false);
    const [isModifyModalChangePassword, setIsModifyModalChangePassword] =
      useState(false);
    const [canCopyApiToken, setCanCopyApiToken] = useState(false);
    const [canMigrateAccount, setCanMigrateAccount] = useState(false);

    const { currentManufacturer, me, isKemaroAcademySection } =
      useContext(AuthorizationContext);


    useEffect(() => {
      const getUser = async () => {
        setIsLoading(true);

        try {
          setUser(await UsersRequests.getById(me?.id!));
        } catch (error) {
          ToastHelper.errors(ErrorHelper.process(error));
        }

        setIsLoading(false);
      };

      if (!me?.id) {
        return;
      }

      getUser();
      setCanCopyApiToken(
        window.location.hostname === "localhost" ||
          me?.userAccessType === "Earlybyte"
      );
    }, [me]);

    useEffect(() => {
      if (user) {
        setCanMigrateAccount(
          // Todo: Remove isDevelop() check when migration is ready for production
          user?.userName?.endsWith("@eb8.ch") && PermissionsHelper.isDevelop()
        );
      }
    }, [user]);



    const handleNotificationConfigurationSave = async (
      item: NotificationConfigurationDto,
      isNew: boolean
    ) => {
      let result = false;

      try {
        let notificationConfigurations = [...user!.notificationConfigurations];

        if (isNew) {
          notificationConfigurations.push(item);
        } else {
          const idx = user!.notificationConfigurations.indexOf(item);
          notificationConfigurations.splice(idx - 1, 1, item);
        }

        await UsersRequests.putMeNotificationConfiguration(
          notificationConfigurations
        );

        result = true;

        setUser({
          ...user!,
          notificationConfigurations: notificationConfigurations,
        });
      } catch (error) {
        ToastHelper.errors(ErrorHelper.process(error));
      }

      return result;
    };

    const handleNotificationConfigurationDelete = async (
      item: NotificationConfigurationDto
    ) => {
      let result = false;

      try {
        let notificationConfigurations = [...user!.notificationConfigurations];
        const idx = user!.notificationConfigurations.indexOf(item);
        notificationConfigurations.splice(idx - 1, 1);

        await UsersRequests.putMeNotificationConfiguration(
          notificationConfigurations
        );

        result = true;

        setUser({
          ...user!,
          notificationConfigurations: notificationConfigurations,
        });
      } catch (error) {
        ToastHelper.errors(ErrorHelper.process(error));
      }

      return result;
    };

    const handleNotificationConfigurationTest = async (
      item: NotificationConfigurationDto
    ) => {
      try {
        const testEmail: TestEmailDto = {
          email: item.emailAddress,
        };

        await UsersRequests.postSendEmailTest(testEmail);
      } catch (error) {
        ToastHelper.errors(ErrorHelper.process(error));
      }
    };

    const modifyMeSave = async (item: UserCompleteDto) => {
        setIsLoading(true);

        try {
          await UsersRequests.modifyMe(user!, item);
          setUser(await UsersRequests.getById(item.id));

          StorageManagerService.setApiTokenContainer(
            await ApplicationService.getApiTokenContainer(currentManufacturer!.id)
          );

          i18n.changeLanguage(item?.language);
          setIsModifyModalOpen(false);
        } catch (error) {
          ToastHelper.errors(ErrorHelper.process(error));
        }
        setIsLoading(false);
      };

    return {
        user,isKemaroAcademySection,
        isModifyModalOpen,
        setIsModifyModalOpen,
        handleNotificationConfigurationSave,
        handleNotificationConfigurationDelete,
        handleNotificationConfigurationTest,
        isMigrateAccountModalOpen,
        setIsMigrateAccountModalOpen,
        isModifyModalChangePassword,
        setIsModifyModalChangePassword,
        canCopyApiToken,
        canMigrateAccount,modifyMeSave
    };

};
