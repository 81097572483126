import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { TooltipContainer } from "../../../../components/news";

export const CopyButton = (props: CopyButtonProps) => {
  return (
    <TooltipContainer text={props.text} enterDelay={1000} leaveDelay={200}>
      <span onClick={props.onClick} className="cursor-pointer">
        <ContentCopyIcon color="primary" />
      </span>
    </TooltipContainer>
  );
};

interface CopyButtonProps {
  text: string;
  disabled?: boolean;
  onClick: () => void;
}
