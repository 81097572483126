import { useContext } from "react";
import { CustomFieldContext } from "./CustomFieldContext";
import { CustomFieldViewBody } from "./CustomFieldViewBody";
import { CustomFieldViewTitle } from "./CustomFieldViewTitle";

export const CustomFieldView = () => {
  const { currentItem } = useContext(CustomFieldContext);

  return (
    <div className="view-container">
      {currentItem && (
        <>
          <CustomFieldViewTitle />
          <CustomFieldViewBody />
        </>
      )}
    </div>
  );
};
