import { KemaroMissionCompleteDto } from "../../../../../robotcloud-shared/resource-models";
import { Loading } from "../../../components/Loading";
import { KemaroMissionViewHeader } from "./KemaroMissionViewHeader";
import { KemaroMissionViewSummary } from "./KemaroMissionViewSummary";
import { KemaroMissionViewRuns } from "./KemaroMissionViewRuns";
import { TagsDisplayer } from "../../../components/news";
import { useEffect, useState, useContext } from "react";
import { PermissionsHelper } from "../../../uiHelpers/permissionsHelper";
import AuthorizationContext from "../../auth/authorizationContext";

export const KemaroMissionView = (props: kemaroRobotsViewProps) => {
  const [showTags, setShowTags] = useState(false);
  const [errorCodesAsButton, setErrorCodesAsButton] = useState(false);

  const { me } = useContext(AuthorizationContext);

  useEffect(() => {
    setShowTags(PermissionsHelper.allow("KemaroMissions", "View", "Tags"));
    setErrorCodesAsButton(
      PermissionsHelper.allow("KemaroMissions", "View", "ErrorCodesAsButton")
    );
  }, [me]);

  if (props.isFetching) {
    return <Loading isLoading={props.isFetching} />;
  }

  return (
    <>
      <KemaroMissionViewHeader
        item={props.item}
        onDelete={props.onDelete}
        onShowAllmissionsOfRobot={() =>
          props.onShowAllmissionsOfRobot(props.item.robotId)
        }
      />

      {showTags && props.item.tags.length > 0 && (
        <div className="col-12 mt-3">
          <TagsDisplayer tags={props.item.tags} />
        </div>
      )}

      <KemaroMissionViewSummary item={props.item} />

      <KemaroMissionViewRuns
        runs={props.item?.runs ?? []}
        reportId={props.item.id ?? ""}
        errorCodesAsButton={errorCodesAsButton}
      />
    </>
  );
};

export interface kemaroRobotsViewProps {
  item: KemaroMissionCompleteDto;
  isFetching: boolean;
  onDelete: (item: KemaroMissionCompleteDto) => Promise<boolean>;
  onShowAllmissionsOfRobot: (robotId: string) => void;
}
