import { useState, useContext, useEffect } from "react";
import { ToastHelper } from "../components/news";
import { RobotModelRequest } from "../data/robotModel.request";
import { ErrorHelper } from "../uiHelpers/errors.helper";
import { GeneralFunctions } from "../views/generalFunctions";
import KemaroRobotContext from "../views/kemaro/robots/KemaroRobotContext";


export const useRobotModelImage = () => {
  const [idRobotModel, setIdRobotModel] = useState<string | undefined>(undefined);
  const [imageRobotModel, setImageRobotModel] = useState<string | undefined>(undefined);
  const [imageRobotModelShow, setImageRobotModelShow] = useState(false);
  const [isFetchingRobotModel, setIsFetchingRobotModel] = useState(false);
  const { currentItem } = useContext(KemaroRobotContext);

  useEffect(() => {
    if (currentItem && currentItem.idRobotModel && currentItem.idRobotModel !== idRobotModel) {
      setIdRobotModel(currentItem.idRobotModel);
      setIsFetchingRobotModel(true);
      RobotModelRequest.getImage(currentItem.idRobotModel)
        .then((data) => {
          setImageRobotModelShow(data.showImage);
          setImageRobotModel(data.showImage ? GeneralFunctions.imageStringToUrl(data.image) : undefined);
        })
        .catch((error) => ToastHelper.errors(ErrorHelper.process(error)))
        .finally(() => setIsFetchingRobotModel(false));
    }
  }, [currentItem, idRobotModel]);

  return { imageRobotModel, imageRobotModelShow, isFetchingRobotModel };
};
