import {
  PaginatedListDtoOfKemaroMissionListDto,
  KemaroMissionFilterCriteriaDto,
  KemaroMissionCompleteDto,
  TargetAccountDto,
  EncodedFileDto,
  KemaroMissionErrorSummaryContainerDto,
  KemaroMissionPostDto,
  KemaroMissionFilterCriteriaTimezone,
  SearchTextDtoOfString,
  SearchTextResultDtoOfString,
} from "../../../../robotcloud-shared/resource-models";
import { AxiosRequests } from "../axios.requests";
import { BaseRequest } from "../BaseRequest";
import { prepareSearchTextDto } from "../functions";

export class KemaroMissionsRequests extends BaseRequest {
  static controller = "api/webapp/v1.0/kemaro/missions";

  static getList = async (filterCriteria: KemaroMissionFilterCriteriaDto) => {
    const { data } = await AxiosRequests.get(
      `${
        KemaroMissionsRequests.controller
      }?${KemaroMissionsRequests.objectToQueryString(filterCriteria)}`
    );
    return data as PaginatedListDtoOfKemaroMissionListDto;
  };

  static getById = async (
    id: string,
    currentTimezoneToShow: KemaroMissionFilterCriteriaTimezone
  ) => {
    const { data } = await AxiosRequests.get(
      `${KemaroMissionsRequests.controller}/${id}/${currentTimezoneToShow}`
    );
    return data as KemaroMissionCompleteDto;
  };

  static postSendByEmail = async (
    id: string,
    targetAccountDto: TargetAccountDto[]
  ) => {
    await AxiosRequests.post(
      `${KemaroMissionsRequests.controller}/${id}/report-email`,
      targetAccountDto
    );
  };

  static getPdf = async (id: string) => {
    const { data } = await AxiosRequests.get(
      `${KemaroMissionsRequests.controller}/${id}/report-pdf`
    );
    return data as EncodedFileDto;
  };

  static delete = async (id: string) => {
    await AxiosRequests.delete(`${KemaroMissionsRequests.controller}/${id}`);
  };

  static getErrorSummary = async (idKemaroRobot: string) => {
    const { data } = await AxiosRequests.get(
      `${KemaroMissionsRequests.controller}/error-summary/${idKemaroRobot}`
    );
    return data as KemaroMissionErrorSummaryContainerDto;
  };

  static postMission = async (
    idRobot: string,
    useDefaultMap: boolean,
    mission: KemaroMissionPostDto
  ) => {
    const { data } = await AxiosRequests.post(
      `${KemaroMissionsRequests.controller}/${idRobot}/${useDefaultMap}`,
      mission
    );

    return data as string;
  };

  static csv = async (filterCriteria: KemaroMissionFilterCriteriaDto) => {
    const { data } = await AxiosRequests.get(
      `${
        KemaroMissionsRequests.controller
      }/csv?${KemaroMissionsRequests.objectToQueryString(filterCriteria)}`
    );
    return data as EncodedFileDto;
  };

  static zip = async (filterCriteria: KemaroMissionFilterCriteriaDto) => {
    const { data } = await AxiosRequests.get(
      `${
        KemaroMissionsRequests.controller
      }/zip?${KemaroMissionsRequests.objectToQueryString(filterCriteria)}`
    );
    return data as EncodedFileDto;
  };

  static getSearchTextZoneNames = async (
    customerId: string | null,
    searchTextDto: SearchTextDtoOfString
  ) => {
    const routeParameter = customerId
      ? `/${customerId}/search-zone-names`
      : "/search-zone-names";

    const { data } = await AxiosRequests.post(
      `${KemaroMissionsRequests.controller}${routeParameter}`,
      prepareSearchTextDto(searchTextDto)
    );
    return data as SearchTextResultDtoOfString;
  };

  static getSearchTextRoomNames = async (
    customerId: string | null,
    searchTextDto: SearchTextDtoOfString
  ) => {
    const routeParameter = customerId
      ? `/${customerId}/search-room-names`
      : "/search-room-names";

    const { data } = await AxiosRequests.post(
      `${KemaroMissionsRequests.controller}${routeParameter}`,
      prepareSearchTextDto(searchTextDto)
    );
    return data as SearchTextResultDtoOfString;
  };
}
