import {
  PublicClientApplication,
  EventMessage,
  EventType,
  AccountInfo,
  AuthError,
} from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { useEffect, useState } from "react";
import {
  b2cPolicies,
  getMsalConfig,
  MsalConfigType,
  scopes,
  setAuthProvider,
} from "./data/auth";
import swarm_s_logo from "./assets/swarm_s_logo.png";
import earlybyteBrand from "./assets/brands/earlybyte.png";
import kemaroBrand from "./assets/brands/kemaro.png";
import { useTranslation } from "react-i18next";
import { StorageManagerService } from "./data/storageManager.service";
import { ForgotPasswordModal } from "./views/auth/forgotPasswordModal";
import { Navigate, Route, Routes } from "react-router-dom";
import { ForgotPasswordConfirm } from "./views/auth/forgotPasswordConfirm";
import useTheme from "@mui/material/styles/useTheme";
import { Login } from "./views/auth/login";

interface MsalProviderSelectorProps {
  children: React.ReactNode;
}

export const MsalProviderSelector = ({
  children,
}: MsalProviderSelectorProps) => {
  const [msalConfType, setMsalConfigType] = useState<
    MsalConfigType | undefined
  >();
  const [msalInstance, setMsalInstance] = useState<
    PublicClientApplication | undefined
  >();

  useEffect(() => {
    const createMsalInstance = async () => {
      if (msalConfType && !msalInstance) {
        /**
         * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
         * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
         */
        const newMsalInstance: PublicClientApplication =
          new PublicClientApplication(getMsalConfig(msalConfType));

        //https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/errors.md#uninitialized_public_client_application
        await newMsalInstance.initialize();

        setAuthProvider(newMsalInstance);
        setMsalInstance(newMsalInstance);
        StorageManagerService.setMsalConfigType(msalConfType as MsalConfigType);

        newMsalInstance.addEventCallback((event: EventMessage) => {
          if (
            (event.eventType === EventType.LOGIN_SUCCESS ||
              event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
              event.eventType === EventType.SSO_SILENT_SUCCESS) &&
            event.payload
          ) {
            newMsalInstance.setActiveAccount(event.payload as AccountInfo);
          }

          if (event.eventType === EventType.LOGOUT_SUCCESS) {
            StorageManagerService.clearInteractionStatus();
            StorageManagerService.removeMsalConfigType();
          }

          if (event.eventType === EventType.LOGIN_FAILURE) {
            // Check for forgot password error
            // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
            if (
              event.error &&
              (event.error as AuthError).errorMessage.includes("AADB2C90118")
            ) {
              const resetPasswordRequest = {
                authority: b2cPolicies.authorities.forgotPassword.authority,
                scopes: [...scopes.access_as_user],
              };
              newMsalInstance.loginRedirect(resetPasswordRequest);
            }
          }
        });

        // Default to using the first account if no account is active on page load
        if (
          !newMsalInstance.getActiveAccount() &&
          newMsalInstance.getAllAccounts().length > 0
        ) {
          // Account selection logic is app dependent. Adjust as needed for different use cases.
          newMsalInstance.setActiveAccount(newMsalInstance.getAllAccounts()[0]);
        }
      }
    };

    createMsalInstance();
  }, [msalConfType, msalInstance]);

  const { t } = useTranslation();

  const [isNewLogin, setIsNewLogin] = useState<boolean>(false);

  const isLoginRedirection = (): boolean => {
    const hash = window.location.hash ?? "";
    // shared parameters between entra ID and B2C redirections.
    return (
      hash.includes("code=") &&
      hash.includes("client_info=") &&
      hash.includes("state=")
    );
  };

  useEffect(() => {
    const token = localStorage.getItem(
      StorageManagerService.apiTokenContainerKey
    );

    if (token || isLoginRedirection()) {
      // In case of existing token (logged in) or going back during login process after selecting a login method.
      const storedConfigType = StorageManagerService.getMsalConfigType();
      if (storedConfigType && msalConfType !== storedConfigType) {
        setMsalConfigType(storedConfigType as MsalConfigType);
      }
    } else {
      // Not logged in or not in the process of logging in.
      setMsalConfigType(undefined);
      StorageManagerService.removeMsalConfigType();
    }

    const url = window.location.href;
    setIsNewLogin(url.endsWith("new-login"));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false);

  const theme = useTheme();

  return msalInstance ? (
    <MsalProvider instance={msalInstance}>{children}</MsalProvider>
  ) : (
    <>
      <div className="main-div">
        <table className="main-table">
          <tbody>
            <tr>
              <td className="td-first"></td>
            </tr>
            <tr>
              <td className="td-logo">
                <img src={swarm_s_logo} className="earlybyte" alt="Earlybyte" />
              </td>
            </tr>
            <tr>
              <td className="td-welcome">
                <p className="welcome-text">{t("loginScreen.title")} SWARM</p>
                {isNewLogin ? (
                  <>
                    <div className="pt-4">
                      <button
                        className="login-button"
                        style={{
                          color: theme.palette.swarm?.auth?.loginButton,
                          backgroundColor:
                            theme.palette.swarm?.auth?.loginButtonBackground,
                        }}
                        onClick={() => {
                          StorageManagerService.clearInteractionStatus();
                          setMsalConfigType("AzureAdB2C");
                          StorageManagerService.setMsalConfigType("AzureAdB2C");
                        }}
                      >
                        {t("loginScreen.loginButton")}
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="pt-4">
                    <button
                      className="login-button"
                      style={{
                        color: theme.palette.swarm?.auth?.loginButton,
                        backgroundColor:
                          theme.palette.swarm?.auth?.loginButtonBackground,
                      }}
                      onClick={() => {
                        // Current workaround to for ClientConfigurationError authority_mismatch, after
                        // updating the library to 2.0.0. We are clearing all informations in the storage manager service.
                        // TODO: Please remove the clearAll in the next release (after 1.0.0).
                        //StorageManagerService.clearInteractionStatus();
                        StorageManagerService.clearAll();
                        setMsalConfigType("AzureAdSingleTenant");
                        StorageManagerService.setMsalConfigType(
                          "AzureAdSingleTenant"
                        );
                      }}
                    >
                      {t("loginScreen.loginButton")}
                    </button>
                    <p className="login-paragaph pt-4">
                      <button
                        className="btn btn-link shadow-none"
                        style={{
                          color: theme.palette.swarm?.auth?.warning,
                        }}
                        onClick={() => setOpenResetPasswordModal(true)}
                      >
                        {t("loginScreen.forgotPassword.button")}
                      </button>
                    </p>
                  </div>
                )}
              </td>
            </tr>
            <tr>
              <td className="td-brands">
                <table className="table-brands">
                  <tbody>
                    <tr>
                      <td className="td-brand-left">
                        <img
                          src={earlybyteBrand}
                          className="logo-brand"
                          alt="Earlybyte"
                        />
                      </td>
                      <td className="td-brand-separtor"></td>
                      <td>
                        <img
                          src={kemaroBrand}
                          className="logo-brand"
                          alt="KEMARO"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td className="td-separator-2"></td>
            </tr>
            <tr>
              <td className="td-robot-cloud">
                <p>SWARM</p>
                <p>Made with ❤️ by Earlybyte</p>
              </td>
            </tr>

            <tr>
              <td className="td-last"></td>
            </tr>
          </tbody>
        </table>
        {openResetPasswordModal && (
          <ForgotPasswordModal
            openPopup={openResetPasswordModal}
            onCancel={() => setOpenResetPasswordModal(false)}
          />
        )}
      </div>

      <Routes>
        {
          // This routes are also configured in App.tsx, the login route is duplicated here to avoid
          // the redirect to the login page when the user is already logged in.
          // Password reset route is also shown on the login page with the same purpose.
          // The * route is to redirect to the login page when the user is not logged in (e.g. /kemaro-robots).
        }
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/new-login" element={<Login />} />
        <Route
          path="/forgot-password-confirm"
          element={<ForgotPasswordConfirm />}
        />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </>
  );
};

export default MsalProviderSelector;
