import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ToastHelper,
  TooltipButton,
  tableGenericHandleKeyboardSubjectManager,
} from "../../../components/news";
import RouterIcon from "@mui/icons-material/Router";
import { RobotConnectionModal } from "./RobotConnectionModal";
import { SwarmRemoteManufacturerConnectionDto } from "../../../../../robotcloud-shared/resource-models";
import { SwarmRemoteRequests } from "../../../data/swarmRemote.request";
import { ErrorHelper } from "../../../uiHelpers/errors.helper";

export const RobotConnectionButton = ({
  robotId,
  connectionModalOpen,
  setConnectionModalOpen,

}: RobotConnectionButtonProps) => {
  const { t } = useTranslation();

  const openPopupChange = (value: boolean) => {
    tableGenericHandleKeyboardSubjectManager.setData({
      caller: "RobotNameModal",
      handleKeyboard: value,
    });

    setConnectionModalOpen(value);
  };

  const [robotConnection, setRobotConnection] = useState<
    SwarmRemoteManufacturerConnectionDto | undefined
  >();

  useEffect(() => {
    const getRobotConnectionData = (kemaroRobotId: string) => {
      SwarmRemoteRequests.getConnectionByRobotId(kemaroRobotId)
        .then((result) => {
          setRobotConnection(result);
        })
        .catch((error) => {
          if ((error as any).response.status === 404) {
            setRobotConnection(undefined);
          } else {
            ToastHelper.errors(ErrorHelper.process(error));
          }
        });
    };

    setRobotConnection(undefined);
    if (robotId) {
      getRobotConnectionData(robotId);
    }
  }, [robotId]);
  const tooptipText = robotConnection
    ? t("robot.connection.tooltip")
    : t("robot.connection.tooltipNoConnection");
  return (
    <>
      <TooltipButton
        aria-label={tooptipText}
        tooltip={tooptipText}
        onClick={() => openPopupChange(true)}
        disabled={!robotConnection}
      >
        {robotConnection ? (
          <RouterIcon color="primary" />
        ) : (
          <RouterIcon color="disabled" />
        )}
      </TooltipButton>

      {connectionModalOpen && robotConnection && (
        <RobotConnectionModal
          robotConnection={robotConnection}
          onClose={() => openPopupChange(false)}
        />
      )}
    </>
  );
};

interface RobotConnectionButtonProps {
  robotId: string;
  connectionModalOpen: boolean;
  setConnectionModalOpen: (open: boolean) => void;
}
