import { Box, Button, Dialog, Grid, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { CompanyStructureAddressDto } from "../../../../../robotcloud-shared/resource-models";
import { Loading } from "../../../components/Loading";
import { TextInput } from "../../../components/news/TextInput";
import { GeneralFunctions } from "../../generalFunctions";
import { useTranslation } from "react-i18next";
import { SupRemark } from "../../../uiHelpers/SupRemark";
import { CountrySearch } from "../../../components/search";
import { ZipCodeInput } from "../../../components/ZipCodeInput";
import { CompanyStructureRequests } from "../../../data/companyStructure.request";
import {
  ToastHelper,
  TooltipButton,
  tableGenericHandleKeyboardSubjectManager,
} from "../../../components/news";
import { ErrorHelper } from "../../../uiHelpers/errors.helper";
import CloseIcon from "@mui/icons-material/Close";
import i18n from "../../../i18n/config";

const defaultCompanyAddressDto: CompanyStructureAddressDto = {
  name: "",
  address: "",
  zipCode: "",
  city: "",
  idCountry: "",
  country: null,
};

interface CompanyStructureAddressModalProps {
  modalOpen: boolean;
  idCompanyStructure: string;
  initialAddressDto: CompanyStructureAddressDto | null;
  onCancel: () => void;
  onSave: () => void;
  onDelete: () => void;
}

export const CompanyStructureAddressModal = ({
  modalOpen,
  idCompanyStructure,
  initialAddressDto,
  onCancel,
  onSave,
  onDelete,
}: CompanyStructureAddressModalProps) => {
  const { t } = useTranslation();
  const [address, setAddress] = useState<CompanyStructureAddressDto>(
    initialAddressDto
      ? initialAddressDto
      : {
          ...defaultCompanyAddressDto,
        }
  );
  const [isWorking, setIsWorking] = useState(false);
  const [zipCodeValid, setZipCodeValid] = useState(true);
  const [deleting, setDeleting] = useState(false);
  const saveAddress = async () => {
    setIsWorking(true);

    try {
      await CompanyStructureRequests.upsertAddress(idCompanyStructure, address);
      if (initialAddressDto) {
        ToastHelper.success(t("common.modifiedSuccessfully"));
      } else {
        ToastHelper.success(t("common.createdSuccessfully"));
      }
      setIsWorking(false);
      onSave();
    } catch (error) {
      setIsWorking(false);
      ToastHelper.errors(ErrorHelper.process(error));
    }
  };

  const deleteAddress = async () => {
    setIsWorking(true);
    try {
      await CompanyStructureRequests.deleteAddress(idCompanyStructure);

      ToastHelper.success(t("common.deletedSuccessfully"));
      setIsWorking(false);
      onDelete();
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
      setIsWorking(false);
    }
  };

  useEffect(() => {
    tableGenericHandleKeyboardSubjectManager.setData({
      caller: "ContactsModal",
      handleKeyboard: modalOpen,
    });
  }, [modalOpen]);

  return (
    <Dialog
      className="application-modal"
      open={modalOpen}
      maxWidth="md"
      onClose={() => onCancel()}
    >
      <div className="modal-content">
        <Stack>
          <Box className="application-modal-header">
            <h3>{t("company.editLocationAddress.title")}</h3>
          </Box>
          <Box className="application-modal-body">
            {isWorking ? (
              <Loading isLoading={isWorking} />
            ) : (
              <Grid container>
                <Grid className="input-label" item xs={3}>
                  {t("company.locationAddress.name")}
                  <SupRemark />
                </Grid>
                <Grid item xs={9} className="d-flex">
                  <div className={`${initialAddressDto ? "col-11" : "col-12"}`}>
                    <TextInput
                      disabled={deleting}
                      multiline={false}
                      value={address.name}
                      ret={(newValue) =>
                        setAddress({ ...address, name: newValue })
                      }
                    />
                  </div>
                  {initialAddressDto && (
                    <div className="col-1">
                      <TooltipButton
                        aria-label="delete"
                        tooltip={i18n.t("contact.delete.tooltip").toString()}
                        onClick={() => {
                          setDeleting(true);
                          setAddress({
                            ...defaultCompanyAddressDto,
                          });
                        }}
                      >
                        <CloseIcon color="error" />
                      </TooltipButton>
                    </div>
                  )}
                </Grid>
                <Grid className="input-label" item xs={3}>
                  {t("company.locationAddress.country")}
                  <SupRemark />
                </Grid>
                <Grid item xs={9}>
                  <CountrySearch
                    disabled={deleting}
                    currentId={address.idCountry}
                    selectionChange={(x) =>
                      setAddress({ ...address, idCountry: x?.id ?? "" })
                    }
                  />
                </Grid>
                <Grid className="input-label" item xs={3}>
                  {t("contact.address")}
                  <SupRemark />
                </Grid>
                <Grid item xs={9}>
                  <TextInput
                    disabled={deleting}
                    multiline={true}
                    value={address.address}
                    ret={(x) => setAddress({ ...address, address: x })}
                  />
                </Grid>
                <Grid className="input-label" item xs={3}>
                  {t("contact.zipcode")}
                  <SupRemark />
                </Grid>
                <Grid item xs={9}>
                  <ZipCodeInput
                    disabled={deleting}
                    countryId={address.idCountry}
                    zipCode={address.zipCode}
                    setZipCode={(x) => setAddress({ ...address, zipCode: x })}
                    isValid={zipCodeValid}
                    setIsValid={setZipCodeValid}
                  />
                </Grid>
                <Grid className="input-label" item xs={3}>
                  {t("contact.city")}
                  <SupRemark />
                </Grid>
                <Grid item xs={9}>
                  <TextInput
                    disabled={deleting}
                    value={address.city}
                    ret={(x) => setAddress({ ...address, city: x })}
                  />
                </Grid>
              </Grid>
            )}
          </Box>
          {isWorking ? (
            <Box className="application-modal-footer"></Box>
          ) : (
            <Box className="application-modal-footer">
              <Button
                variant="contained"
                className="button-with-text"
                disabled={
                  !deleting &&
                  (GeneralFunctions.isBlank(address.address) ||
                    GeneralFunctions.isBlank(address.city) ||
                    GeneralFunctions.isBlank(address.idCountry) ||
                    GeneralFunctions.isBlank(address.name) ||
                    !zipCodeValid)
                }
                color={deleting ? "error" : "primary"}
                onClick={deleting ? deleteAddress : saveAddress}
              >
                {deleting
                  ? t("common.buttons.confirmDeletion")
                  : t("common.buttons.save")}
              </Button>

              <Button
                className="modal-cancel-button button-with-text"
                variant="outlined"
                color="primary"
                onClick={() => onCancel()}
              >
                {t("common.buttons.cancel")}
              </Button>
            </Box>
          )}
        </Stack>
      </div>
    </Dialog>
  );
};
