import { RobotModelCompleteDto } from "../../../../../../robotcloud-shared/resource-models";
import { ToastHelper } from "../../../../components/news";
import { RobotModelRequest } from "../../../../data/robotModel.request";
import { ErrorHelper } from "../../../../uiHelpers/errors.helper";

export const getRobotModels = async (idRobotModel: string | null) => {
  const result: RobotModelCompleteDto[] = [];
  if (!idRobotModel) return result;

  try {
    const robotModel = await RobotModelRequest.getById(idRobotModel);
    result.push(robotModel);
  } catch (error) {
    ToastHelper.errors(ErrorHelper.process(error));
  }

  return result;
};
