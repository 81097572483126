import { useContext, useState } from "react";
import KemaroRobotContext from "../KemaroRobotContext";
import { TooltipButton } from "../../../../components/news";
import { ErrorSummaryTooltip } from "./ErrorSummaryTooltip";
import { useTranslation } from "react-i18next";
import BugReportOutlinedIcon from "@mui/icons-material/BugReportOutlined";
import { KemaroRobotErrorSummaryModal } from "../KemaroRobotErrorSummaryModal";

export const ErrorSummaryButton = () => {
  const { t } = useTranslation();
  const { currentItem, setViewSideModalOpenChange } =
    useContext(KemaroRobotContext);
  const [
    isOpenKemaroRobotErrorSummaryModal,
    setIsOpenKemaroRobotErrorSummaryModal,
  ] = useState(false);

  const errorSummaryIconClass =
    currentItem!.errorSummary.state === "No"
      ? "bug-old"
      : currentItem!.errorSummary.state === "Old"
      ? ""
      : "bug-recent";

  const clickHandle = () => {
    if (currentItem!.errorSummary.state === "No") return;
    setIsOpenKemaroRobotErrorSummaryModal(true);
    setViewSideModalOpenChange(true);
  };

  return (
    <>
      <TooltipButton
        tooltipContent={<ErrorSummaryTooltip />}
        aria-label={t("robot.errorSummary.errorResume")}
        tooltip={t("robot.errorSummary.errorResume")}
        onClick={clickHandle}
      >
        <BugReportOutlinedIcon
          color="primary"
          className={errorSummaryIconClass}
        />
      </TooltipButton>
      {isOpenKemaroRobotErrorSummaryModal && (
        <KemaroRobotErrorSummaryModal
          openPopup={isOpenKemaroRobotErrorSummaryModal}
          item={currentItem!}
          onCancel={() => {
            setIsOpenKemaroRobotErrorSummaryModal(false);
            setViewSideModalOpenChange(true);
          }}
        />
      )}
    </>
  );
};
