import { diff, jsonPatchPathConverter } from "just-diff";
import {
  KemaroDashboardEconomyCostCalculationParameterDto,
  KemaroDashboardEconomyCostCalculationParameterPostDto,
} from "../../../../robotcloud-shared/resource-models";
import { AxiosRequests } from "../axios.requests";
import { BaseRequest } from "../BaseRequest";

export class KemaroDashboardEconomyCostCalculationParametersRequests extends BaseRequest {
  static controller =
    "api/webapp/v1.0/Kemaro/dashboardEconomyCostCalculationParameters";

  static create = async (
    idKemaroDashboardEconomyCostCalculation: string,
    postDto: KemaroDashboardEconomyCostCalculationParameterPostDto
  ) => {
    await AxiosRequests.post(
      `${KemaroDashboardEconomyCostCalculationParametersRequests.controller}/${idKemaroDashboardEconomyCostCalculation}`,
      postDto
    );
  };

  static modify = async (
    originalDto: KemaroDashboardEconomyCostCalculationParameterDto,
    modifiedDto: KemaroDashboardEconomyCostCalculationParameterDto
  ) => {
    const notAllowedProperties = [
      "/id",
      "/idKemaroDashboardEconomyCostCalculation",
    ];

    const jsonPatch = diff(
      originalDto,
      modifiedDto,
      jsonPatchPathConverter
    ).filter((x) => !notAllowedProperties.includes(x.path));

    await AxiosRequests.patch(
      `${KemaroDashboardEconomyCostCalculationParametersRequests.controller}/${originalDto.id}`,
      jsonPatch
    );
  };

  static delete = async (id: string) => {
    await AxiosRequests.delete(
      `${KemaroDashboardEconomyCostCalculationParametersRequests.controller}/${id}`
    );
  };
}
