import { Button, Dialog, Switch } from "@mui/material";
import { useEffect, useState } from "react";
import { KemaroDashboardEconomyCostCalculationPostDto } from "../../../../../../robotcloud-shared/resource-models";
import { Loading } from "../../../../components/Loading";
import { TextInput } from "../../../../components/news/TextInput";
import { CurrencySearch } from "../../../../components/search/CurrencySearch";
import { useTranslation } from "react-i18next";
import { tableGenericHandleKeyboardSubjectManager } from "../../../../components/news";
import { GeneralFunctions } from "../../../generalFunctions";

export const KemaroCostCalculationModal = (
  props: kemaroCostCalculationModalProps
) => {
  const { t } = useTranslation();

  const [dto, setDto] = useState<KemaroDashboardEconomyCostCalculationPostDto>({
    ...defaultKemaroDashboardEconomyCostCalculationPostDto,
  });

  useEffect(() => {
    setDto(
      props.dto ?? { ...defaultKemaroDashboardEconomyCostCalculationPostDto }
    );
  }, [props.dto]);

  useEffect(() => {
    tableGenericHandleKeyboardSubjectManager.setData({
      caller: "KemaroCostCalculationModal",
      handleKeyboard: props.isOpenModal,
    });
  }, [props.isOpenModal]);

  const handleSave = async (
    dto: KemaroDashboardEconomyCostCalculationPostDto
  ) => {
    const result = await props.onSave(dto);
    if (result) {
      setDto({ ...defaultKemaroDashboardEconomyCostCalculationPostDto });
    }
  };

  const handleCancel = () => {
    setDto(
      props.dto ?? { ...defaultKemaroDashboardEconomyCostCalculationPostDto }
    );
    props.onCancel();
  };

  return (
    <Dialog
      className="application-modal"
      open={props.isOpenModal}
      maxWidth="md"
      onClose={handleCancel}
    >
      <div className="modal-content">
        <div className="application-modal-header">
          <h3 className="modal-title">{props.title}</h3>
        </div>
        <div className="application-modal-body">
          {props.isWorking ? (
            <Loading isLoading={props.isWorking} />
          ) : (
            <>
              <div className="row py-3">
                <div className="col-4 input-label">{t("common.name")}</div>
                <div className="col-8">
                  <TextInput
                    value={dto.name}
                    ret={(x) => setDto({ ...dto, name: x })}
                  />
                </div>

                <div className="col-4 mt-2 input-label">
                  {t("common.currency")}
                </div>
                <div className="col-8 mt-2">
                  <CurrencySearch
                    currentId={dto.idCurrency}
                    selectionChange={(x) =>
                      setDto({ ...dto, idCurrency: x?.id ?? "" })
                    }
                  />
                </div>

                {!props.dto && (
                  <>
                    <div className="col-4 mt-2 input-label">
                      {t("common.default")}
                    </div>
                    <div className="col-8 mt-2">
                      <Switch
                        color="primary"
                        disabled={props.dto !== undefined && !props.isCopy}
                        checked={dto.isDefault}
                        onChange={(event) =>
                          setDto({ ...dto, isDefault: event.target.checked })
                        }
                      />
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
        {props.isWorking ? (
          <div className="application-modal-footer"></div>
        ) : (
          <div className="application-modal-footer">
            <Button
              variant="contained"
              className="button-with-text"
              color="primary"
              onClick={() => handleSave(dto)}
              disabled={
                GeneralFunctions.isBlank(dto.name) ||
                GeneralFunctions.isBlank(dto.idCurrency)
              }
            >
              {t("common.buttons.save")}
            </Button>

            <Button
              className="modal-cancel-button button-with-text"
              variant="outlined"
              color="primary"
              onClick={handleCancel}
            >
              {t("common.buttons.cancel")}
            </Button>
          </div>
        )}
      </div>
    </Dialog>
  );
};

interface kemaroCostCalculationModalProps {
  isOpenModal: boolean;
  isWorking: boolean;
  title: string;
  dto: KemaroDashboardEconomyCostCalculationPostDto | undefined;
  isCopy?: boolean;
  onSave: (
    dto: KemaroDashboardEconomyCostCalculationPostDto
  ) => Promise<boolean>;
  onCancel: () => void;
}

const defaultKemaroDashboardEconomyCostCalculationPostDto: KemaroDashboardEconomyCostCalculationPostDto =
  {
    name: "",
    idCurrency: "",
    isDefault: false,
    parameters: [],
  };
