import { Button, Dialog } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Loading } from "../../../components/Loading";
import { useEffect } from "react";
import {
  Banner,
  tableGenericHandleKeyboardSubjectManager,
} from "../../../components/news";

export const KemaroMissionsCsvZipModal = (
  props: KemaroMissionsCsvZipModalProps
) => {
  const { t } = useTranslation();

  useEffect(() => {
    tableGenericHandleKeyboardSubjectManager.setData({
      caller: "KemaroMissionsCsvZipModal",
      handleKeyboard: true,
    });
  }, []);

  return (
    <Dialog
      className="application-modal"
      open={true}
      maxWidth="md"
      onClose={props.onClose}
    >
      <div className="modal-content">
        <div className="application-modal-header">
          {!props.isWorking && <h3>{t("mission.csvZipModal.title")}</h3>}
          {props.isWorking && <h3>{t("mission.csvZipModal.workingTitle")}</h3>}
        </div>

        <div className="application-modal-body">
          <div className="row">
            {!props.isWorking && (
              <>
                <p className="mb-3">{t("mission.csvZipModal.body")}</p>
                {props.mapsDisabled && (
                  <Banner
                    type={"warning"}
                    text={t("mission.csvZipModal.bodyNoMaps", {
                      maxQuantity: props.maxQuantity,
                    })}
                  />
                )}
              </>
            )}
            {props.isWorking && (
              <div className="py-4">
                <Loading isLoading={true} />
              </div>
            )}
          </div>
        </div>
        {!props.isWorking && (
          <div className="application-modal-footer">
            <Button
              className="modal-cancel-buttonv button-with-text me-3 line-height-one"
              variant="outlined"
              color="primary"
              onClick={props.onCsv}
            >
              {t("mission.csvZipModal.csv")}
            </Button>

            <Button
              className="modal-cancel-buttonv button-with-text line-height-one"
              variant="outlined"
              color="primary"
              onClick={props.onZip}
              disabled={props.mapsDisabled}
            >
              {t("mission.csvZipModal.zip")}
            </Button>
          </div>
        )}
      </div>
    </Dialog>
  );
};

interface KemaroMissionsCsvZipModalProps {
  isWorking: boolean;
  mapsDisabled: boolean;
  maxQuantity: number;
  onCsv: () => void;
  onZip: () => void;
  onClose: () => void;
}
