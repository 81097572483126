import { useEffect, useState } from "react";
import { SearchTextDtoOfString } from "../../../../../robotcloud-shared/resource-models";
import { KemaroRobotRequests } from "../../../data/kemaro/kemaroRobot.request";
import { ErrorHelper } from "../../../uiHelpers/errors.helper";
import { ToastHelper } from "../../news/Toast.helper";
import { BaseSearch } from "../BaseSearch";

export const KemaroRobotNameSearch = (props: kemaroRobotNameSearchProps) => {
  const [items, setItems] = useState<string[]>([]);
  const [currentItem, setCurrentItem] = useState<string | undefined>(undefined);
  const [searchText, setSearchText] = useState("");
  const [lastSearch, setLastSearch] = useState("-");

  useEffect(() => {
    const textSearch: SearchTextDtoOfString = {
      textToSearch: searchText,
      id: props.customerId ?? null,
      maxResults: null,
      blackList: props.blackList ?? null,
    };

    const strTextSearch = `${props.customerId ?? "--"}_${JSON.stringify(
      textSearch
    )}`;

    if (lastSearch === strTextSearch) {
      return;
    }

    setLastSearch(strTextSearch);
    getList(textSearch, props.customerId);
  }, [props, searchText, lastSearch]);

  useEffect(() => {
    const newCurrentItem = items.find((x) => x === props.currentId);
    if (newCurrentItem !== currentItem) {
      setCurrentItem(newCurrentItem);
    }
  }, [props.currentId, items, currentItem]);

  const getList = async (
    ts: SearchTextDtoOfString,
    customerId: string | undefined
  ) => {
    try {
      const result = await KemaroRobotRequests.getSearchTextNames(
        customerId ?? null,
        ts
      );

      if (result.searchedText === ts.textToSearch) {
        setItems(result.items);
      }
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }
  };

  const selectionChange = (newValue: string | null) => {
    if (!newValue) {
      setCurrentItem(undefined);
      props.selectionChange(undefined);
      return;
    }

    const selected = items.find((x) => x === newValue);
    if (!props.clearAfterSelect) {
      setCurrentItem(selected);
    }

    props.selectionChange(selected);

    if (props.clearAfterSelect) {
      setCurrentItem(undefined);
      setLastSearch("-");
      setSearchText("");
    }
  };

  return (
    <BaseSearch
      disableCloseOnSelect={props.clearAfterSelect}
      value={currentItem}
      options={items.map((x) => x)}
      searchText={searchText}
      setSearchText={(x) => setSearchText(x)}
      onChange={(x) => selectionChange(x)}
    />
  );
};

interface kemaroRobotNameSearchProps {
  currentId?: string;
  customerId?: string;
  clearAfterSelect?: boolean;
  blackList?: string[] | undefined;
  selectionChange: (select: string | undefined) => void;
}
