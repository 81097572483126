import { Autocomplete, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

export const AutocompleteCustomField = <T extends unknown>(
  props: AutocompleteCustomFieldProps<T>
) => {
  const { t } = useTranslation();

  return (
    <Autocomplete
      className="auto-complete"
      size="small"
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          label=""
          placeholder={t("common.typeToSearch")}
          fullWidth
          variant="standard"
          hiddenLabel
        />
      )}
      options={props.items}
      getOptionLabel={(option) =>
        String(autocompleteGenerateOption(option, props.propertyToShow))
      }
      onInputChange={(event, newValue) => props.onChange(newValue)}
      value={props.value}
    />
  );
};

interface AutocompleteCustomFieldProps<T> {
  items: T[];
  propertyToShow: keyof T;
  value: string;
  onChange: (newValue: string) => void;
}

function autocompleteGenerateOption<T>(
  item: T | undefined | string,
  propertyToShow: keyof T
) {
  if (typeof item === "string") {
    return item;
  }

  return !item ? " " : item[propertyToShow];
}
