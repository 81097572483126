import {
  DashboardCustomerDto,
  DashboardEconomyFilterDto,
  DashboardEconomyYearFilterDto,
  DashboardWeekDto,
  GuidTextDto,
  KemaroDashboardEconomyCostWeekResultDto,
  DashboardRobotInformationDto,
  DashboardEconomyUiConfigurationDto,
  KemaroDashboardEconomyCostStatisticsDto,
} from "../../../../robotcloud-shared/resource-models";
import { AxiosRequests } from "../axios.requests";
import { BaseRequest } from "../BaseRequest";

export class KemaroDashboardEconomyRequests extends BaseRequest {
  static controller = "api/webapp/v1.0/Kemaro/dashboardEconomy";

  static getUiConfig = async () => {
    const { data } = await AxiosRequests.get(
      `${KemaroDashboardEconomyRequests.controller}/ui-config`
    );
    return data as DashboardEconomyUiConfigurationDto;
  };

  static getCustomers = async () => {
    const { data } = await AxiosRequests.get(
      `${KemaroDashboardEconomyRequests.controller}/customers`
    );
    return data as DashboardCustomerDto[];
  };

  static getCostCalculations = async (idCustomer: string) => {
    const { data } = await AxiosRequests.get(
      `${KemaroDashboardEconomyRequests.controller}/${idCustomer}/cost-calculations`
    );
    return data as GuidTextDto[];
  };

  static getYears = async (
    customerId: string,
    costCalculationId: string,
    filter: DashboardEconomyYearFilterDto
  ) => {
    const params = KemaroDashboardEconomyRequests.objectToQueryString(filter);
    const { data } = await AxiosRequests.get(
      `${KemaroDashboardEconomyRequests.controller}/${customerId}/${costCalculationId}/years?${params}`
    );

    return data as number[];
  };

  static getWeeks = async (
    customerId: string,
    costCalculationId: string,
    year: number,
    filter: DashboardEconomyYearFilterDto
  ) => {
    const params = KemaroDashboardEconomyRequests.objectToQueryString(filter);
    const { data } = await AxiosRequests.get(
      `${KemaroDashboardEconomyRequests.controller}/${customerId}/${costCalculationId}/${year}/weeks?${params}`
    );
    return data as DashboardWeekDto[];
  };

  static getEconomyCostWeekly = async (filter: DashboardEconomyFilterDto) => {
    const params = KemaroDashboardEconomyRequests.objectToQueryString(filter);
    const { data } = await AxiosRequests.get(
      `${KemaroDashboardEconomyRequests.controller}/economy-cost-weekly?${params}`
    );
    return data as KemaroDashboardEconomyCostWeekResultDto;
  };

  static getEconomyCostWeeklyStatics = async (
    weeks: number,
    filter: DashboardEconomyFilterDto
  ) => {
    const params = KemaroDashboardEconomyRequests.objectToQueryString(filter);
    const { data } = await AxiosRequests.get(
      `${KemaroDashboardEconomyRequests.controller}/economy-cost-weekly-statics/${weeks}?${params}`
    );
    return data as KemaroDashboardEconomyCostStatisticsDto;
  };

  static getEconomyCostWeeklyWeekRobots = async (
    filter: DashboardEconomyFilterDto
  ) => {
    const params = KemaroDashboardEconomyRequests.objectToQueryString(filter);
    const { data } = await AxiosRequests.get(
      `${KemaroDashboardEconomyRequests.controller}/economy-cost-weekly/week-robots?${params}`
    );
    return data as DashboardRobotInformationDto[];
  };

  static getEconomyCostWeeklyLifetimeRobots = async (
    filter: DashboardEconomyFilterDto
  ) => {
    const params = KemaroDashboardEconomyRequests.objectToQueryString(filter);
    const { data } = await AxiosRequests.get(
      `${KemaroDashboardEconomyRequests.controller}/economy-cost-weekly/lifetime-robots?${params}`
    );
    return data as DashboardRobotInformationDto[];
  };
}
