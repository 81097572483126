import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { CompanyStructureReducedNodeDto } from "../../../../robotcloud-shared/resource-models";
import { CompanyStructureRequests } from "../../data/companyStructure.request";
import { ErrorHelper } from "../../uiHelpers/errors.helper";
import { ToastHelper } from "../news/Toast.helper";
import { useTranslation } from "react-i18next";
import "./search.scss";

export const CompanyStructureTreeSearch = (
  props: CompanyStructureTreeSearchProps
) => {
  const { t } = useTranslation();

  const [items, setItems] = useState<CompanyStructureReducedNodeDto[]>([]);
  const [currentItem, setCurrentItem] = useState<
    CompanyStructureReducedNodeDto | undefined
  >(undefined);
  const [customerId, setCustomerId] = useState("");
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const treeToList = (nodes: CompanyStructureReducedNodeDto[]) => {
      let nodeList: CompanyStructureReducedNodeDto[] = [];

      nodes.forEach((x) => {
        nodeList.push(x);
        if (x.childNodes.length > 0)
          nodeList = nodeList.concat(treeToList(x.childNodes));
      });

      return nodeList;
    };

    const getList = async () => {
      try {
        const result = await CompanyStructureRequests.getTreeSearchText(
          props.customerId
        );

        setItems(treeToList(result));
      } catch (error) {
        ToastHelper.errors(ErrorHelper.process(error));
      }
    };

    if (customerId === props.customerId) {
      return;
    }

    setCustomerId(props.customerId);
    getList();
  }, [props, customerId]);

  const selectionChange = (
    selected: CompanyStructureReducedNodeDto | undefined
  ) => {
    setCurrentItem(selected);
    props.selectionChange(selected);
  };

  if (props.currentId) {
    const newCurrentItem = items.find((x) => x.id === props.currentId);
    if (newCurrentItem?.id !== currentItem?.id) {
      setCurrentItem(newCurrentItem);
    }
  }

  return (
    <Autocomplete
      size="small"
      freeSolo
      options={items}
      getOptionLabel={generateOption}
      renderOption={(props, x) => (
        <div
          key={x.id}
          className={
            "w-100 autocomplete-template py-2 px-3 " +
            (currentItem?.idParentNode === x.id
              ? "autocomplete-template-select"
              : "")
          }
          onClick={() => selectionChange(x)}
        >
          <span style={{ paddingLeft: `${x.nestingLevel * 20}px` }}>
            {x.name}
          </span>
        </div>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label=""
          placeholder={t("common.typeToSearch")}
          fullWidth
          variant="standard"
          margin="dense"
          hiddenLabel
        />
      )}
      value={currentItem ?? defaultCompanyStructureReducedNodeDto}
      inputValue={searchText}
      onInputChange={(event, newInputValue) => setSearchText(newInputValue)}
      onChange={(event, newValue) => {
        const selected = items.find((x) => x.name === newValue);
        setCurrentItem(selected);
        props.selectionChange(selected);
      }}
    />
  );
};

interface CompanyStructureTreeSearchProps {
  customerId: string;
  currentId?: string;
  selectionChange: (select: CompanyStructureReducedNodeDto | undefined) => void;
}

const generateOption = (
  item: CompanyStructureReducedNodeDto | undefined | string
) => {
  if (typeof item === "string") {
    return item;
  }

  return item && item.id ? item.name : "";
};

const defaultCompanyStructureReducedNodeDto: CompanyStructureReducedNodeDto = {
  id: "",
  name: "",
  idParentNode: null,
  childNodes: [],
  nestingLevel: 0,
};
