import {
  DashboardEconomyFilterDto,
  KemaroDashboardEconomyCostWeekDto,
} from "../../../../../../robotcloud-shared/resource-models";
import { NumericFormat } from "react-number-format";
import { useTranslation } from "react-i18next";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import useTheme from "@mui/material/styles/useTheme";
import { TooltipButton } from "../../../../components/news";
import { useState } from "react";
import { KemaroDashboardEconomyRobotsModal } from "./KemaroDashboardEconomyRobotsModal";

export const KemaroDashboardEconomyPerformance = (
  props: KemaroDashboardEconomyPerformanceProps
) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [openRobotsModal, setOpenRobotsModal] = useState(false);

  return (
    <div
      className="kemaro-dashboard-economy-performance"
      style={{
        border: theme.palette.swarm?.kemaroDashboard?.economyPerformanceBorder,
        boxShadow:
          theme.palette.swarm?.kemaroDashboard?.economyPerformanceBoxShadow,
      }}
    >
      <table className="w-100">
        <tbody>
          <tr>
            <td colSpan={2} className="pb-3">
              <span className="title">{props.title}</span>
              {props.subtitle && (
                <span className="subtitle">{props.subtitle}</span>
              )}
            </td>
            <td></td>
          </tr>
          <tr>
            <td className="td-description pb-2">
              {t("performance.totalAreaCleaned")}
            </td>
            <td className="td-value pb-2">
              <NumericFormat
                value={props.data.totalAreaCleaned}
                displayType={"text"}
                thousandSeparator={"'"}
              />
              m<sup>2</sup>
            </td>
            <td></td>
          </tr>
          <tr>
            <td className="td-description pb-2">
              {t("performance.totalTimeInCleaning")}
            </td>
            <td className="td-value pb-2">
              {Math.round(props.data.totalTimeInCleaning / 60)} h
            </td>
            <td></td>
          </tr>
          <tr>
            <td className="td-description pb-2">
              {t("performance.cleaningPerformance")}
            </td>
            <td className="td-value pb-2">
              {props.data.cleaningPerformance} m<sup>2</sup>/h
            </td>
            <td></td>
          </tr>

          <tr>
            <td className="td-description pb-2">
              {t("performance.totalMissions")}
            </td>
            <td className="td-value pb-2">{props.data.totalMissions}</td>
            <td></td>
          </tr>

          <tr>
            <td className="td-description">
              {t("performance.numberOfRobots")}
            </td>
            <td className="td-value">{props.data.totalRobots}</td>
            <td className="td-button ps-2">
              {props.data.totalRobots > 0 && (
                <TooltipButton
                  type="link"
                  tooltip={t("mission.filters")}
                  onClick={() => setOpenRobotsModal(true)}
                >
                  <FormatListBulletedOutlinedIcon />
                </TooltipButton>
              )}
            </td>
          </tr>
        </tbody>
      </table>

      {openRobotsModal && (
        <KemaroDashboardEconomyRobotsModal
          onCancel={() => setOpenRobotsModal(false)}
          isCurrentWeek={props.isCurrentWeek}
          filter={props.filter}
        />
      )}
    </div>
  );
};

export interface KemaroDashboardEconomyPerformanceProps {
  title: string;
  subtitle?: string;
  data: KemaroDashboardEconomyCostWeekDto;
  isCurrentWeek: boolean;
  filter: DashboardEconomyFilterDto;
}
