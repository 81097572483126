import { useContext, useEffect, useState } from "react";
import {
  UserCompleteDto,
  WorkflowTranslatedDataDto,
} from "../../../../robotcloud-shared/resource-models";
import "./linkWorkflow.scss";
import { Box, Tooltip } from "@mui/material";
import { getWorkflowTranslatedData, Workflow } from "./onBoardingService";
import { WorkflowLanguageSelector } from "./WorkflowLanguageSelector";
import { t } from "i18next";
import { Banner, TextInput } from "../../components/news";
import AuthorizationContext from "../auth/authorizationContext";
import { UsersRequests } from "../../data/users.requests";
import { TimeZoneSearch } from "../../components/search/TimeZoneSearch";
import { GeneralFunctions } from "../generalFunctions";
import OnBoardingAssistantContext from "./OnBoardingAssistantContext";
import { SupRemark } from "../../uiHelpers/SupRemark";

export const UserDataWorkflow = ({
  workflow,
  initialLanguage,
  initialUser,
  onReady,
}: UserDataWorkflowProps) => {
  const [language, setLanguage] = useState<string>("--");
  const [translatedData, setTranslatedData] = useState<
    WorkflowTranslatedDataDto | undefined
  >(getWorkflowTranslatedData(workflow.workflowTranslatedDatas, language));
  const { me } = useContext(AuthorizationContext);
  const [user, setUser] = useState<UserCompleteDto | undefined>();
  const { setOriginalUser, setNewUser } = useContext(
    OnBoardingAssistantContext
  );

  useEffect(() => {
    const setLocalUser = async () => {
      const localUser = await UsersRequests.getById(me?.id!);
      setOriginalUser(localUser);
      setNewUser(localUser);
      setUser(localUser);
    };
    if (initialUser) {
      setOriginalUser(initialUser);
      setNewUser(initialUser);
      setUser(initialUser);
    } else {
      if (me) {
        setLocalUser();
      }
    }
  }, [initialUser, me, setNewUser, setOriginalUser]);

  useEffect(() => {
    if (
      user &&
      !GeneralFunctions.isBlank(user.firstName) &&
      !GeneralFunctions.isBlank(user.lastName) &&
      !GeneralFunctions.isBlank(user.windowsTimeZoneId)
    ) {
      setNewUser({
        ...user,
      });
      onReady(true);
    } else {
      onReady(false);
    }
  }, [onReady, setNewUser, user]);

  useEffect(() => {
    if (workflow) {
      const translation = getWorkflowTranslatedData(
        workflow.workflowTranslatedDatas,
        initialLanguage
      );
      setTranslatedData(translation);
      if (translation) {
        setLanguage(initialLanguage);
      } else {
        setLanguage("--");
      }
    }
  }, [workflow, initialLanguage]);

  useEffect(() => {
    if (workflow) {
      const translation = getWorkflowTranslatedData(
        workflow.workflowTranslatedDatas,
        language
      );
      setTranslatedData(translation);
    }
  }, [workflow, language]);

  return (
    <div className="col-12 workflow-component-container 5">
      <div className="d-flex col-12">
        <Tooltip
          title={translatedData?.title ?? workflow.displayTitle}
          placement="top"
          arrow
        >
          <h3 className="workflow-component-title col-12">
            {translatedData?.title ?? workflow.displayTitle}
          </h3>
        </Tooltip>
      </div>

      {workflow && workflow.workflowTranslatedDatas.length > 0 && (
        <>
          <div className="col-8"></div>
          <div className="col-4">
            <WorkflowLanguageSelector
              workflow={workflow}
              current={language}
              selectionChange={(select: string | undefined) => {
                if (select) {
                  setLanguage(select);
                }
              }}
            />
          </div>
        </>
      )}

      <div className="col-12 mt-2">
        <Banner type="info">
          <span id="link-workflow-component-message">
            {translatedData?.instructions ?? workflow.displayInstructions}
          </span>
        </Banner>
      </div>

      <div className="col-12" style={{ marginTop: "10vh" }}>
        {user && (
          <>
            <div className="col-12 d-flex">
              <div className="col-4 input-label">
                {t("user.firstName")}
                <SupRemark />
              </div>
              <div className="col-8">
                <TextInput
                  value={user.firstName}
                  ret={(x) => setUser({ ...user, firstName: x })}
                />
              </div>
            </div>
            <div className="col-12 d-flex">
              <div className="col-4 input-label">
                {t("user.lastName")}
                <SupRemark />
              </div>
              <div className="col-8">
                <TextInput
                  value={user.lastName}
                  ret={(x) => setUser({ ...user, lastName: x })}
                />
              </div>
            </div>
            <div className="col-12 d-flex">
              <div className="col-4 input-label">
                {t("user.primaryEmailAddress")}
              </div>
              <div className="col-8">
                <TextInput
                  value={user.primaryEmailAddress ?? ""}
                  ret={() => user.primaryEmailAddress}
                  disabled={true}
                />
              </div>
            </div>
            <div className="col-12 d-flex">
              <div className="col-4 data-div input-label">
                {t("profile.timezone")}
                <SupRemark />
              </div>
              <div className="col-8 data-div">
                <TimeZoneSearch
                  currentId={user.windowsTimeZoneId}
                  selectionChange={(x) =>
                    setUser({
                      ...user,
                      windowsTimeZoneId: x?.timeZoneInfoId ?? "",
                    })
                  }
                />
              </div>
            </div>
            <Box className="application-modal-footer mt-4"></Box>
          </>
        )}
      </div>
    </div>
  );
};

interface UserDataWorkflowProps {
  workflow: Workflow;
  initialLanguage: string;
  initialUser?: UserCompleteDto;
  onReady: (ready: boolean) => void;
}
