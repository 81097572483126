import { useEffect, useState } from "react";
import { Button, Grid, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import { CountrySearch } from "../../../components/search/CountrySearch";
import { TextInput } from "../../../components/news/TextInput";
import { CustomerContactReducedDto } from "../../../../../robotcloud-shared/resource-models";
import { Loading } from "../../../components/Loading";
import { GeneralFunctions } from "../../generalFunctions";
import { useTranslation } from "react-i18next";
import { SupRemark } from "../../../uiHelpers/SupRemark";
import { ZipCodeInput } from "../../../components/ZipCodeInput";

export const ContactsView = (props: contactsViewProps) => {
  const { t } = useTranslation();

  const [firstName, setFirstName] = useState("");
  const [sureName, setSureName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [idCountry, setIdCountry] = useState("");
  const [isWorking, setIsWorking] = useState(false);
  const [zipCode, setZipCode] = useState("");
  const [city, setCity] = useState("");

  const [zipCodeValid, setZipCodeValid] = useState(true);

  useEffect(() => {
    refreshUi(props.contact ?? null);
  }, [props.contact]);

  const refreshUi = (contact: ContactReducedDto | null) => {
    setFirstName(contact?.firstName ?? "");
    setSureName(contact?.sureName ?? "");
    setAddress(contact?.address ?? "");
    setPhone(contact?.phone ?? "");
    setEmail(contact?.email ?? "");
    setDescription(contact?.description ?? "");
    setIdCountry(contact?.idCountry ?? "");
    setZipCode(contact?.zipCode ?? "");
    setCity(contact?.city ?? "");
  };

  const handleClearState = () => {
    setFirstName("");
    setSureName("");
    setAddress("");
    setPhone("");
    setEmail("");
    setDescription("");
    setIdCountry("");
    setZipCode("");
    setCity("");
  };

  const handleSave = async () => {
    setIsWorking(true);
    const contact: CustomerContactReducedDto = {
      firstName,
      sureName,
      address,
      zipCode,
      city,
      phone,
      email,
      idCountry,
      description,
      id: "",
      country: "",
      completeName: "",
      inCompanyStructure: false,
    };
    if (zipCodeValid) {
      if (await props.onSave(contact)) {
        refreshUi(props.contact ?? null);
      }
    }

    setIsWorking(false);
  };

  const handleCancel = () => {
    handleClearState();
    props.onCancel && props.onCancel();
  };

  return (
    <div className="modal-content">
      <Stack>
        <Box className="application-modal-header">
          <h3>{props.title}</h3>
        </Box>
        <Box className="application-modal-body">
          {isWorking ? (
            <Loading isLoading={isWorking} />
          ) : (
            <Grid container className="onboarding-contact-container">
              <Grid className="input-label onboarding-inputlabel" item xs={3}>
                {t("contact.firstName")}
                <SupRemark />
              </Grid>
              <Grid item xs={9}>
                <TextInput value={firstName} ret={setFirstName} />
              </Grid>
              <Grid className="input-label onboarding-inputlabel" item xs={3}>
                {t("contact.lastName")}
                <SupRemark />
              </Grid>
              <Grid item xs={9}>
                <TextInput value={sureName} ret={setSureName} />
              </Grid>
              <Grid className="input-label onboarding-inputlabel" item xs={3}>
                {t("contact.country")}
                <SupRemark />
              </Grid>
              <Grid item xs={9}>
                <CountrySearch
                  currentId={idCountry}
                  selectionChange={(x) => setIdCountry(x?.id ?? "")}
                />
              </Grid>
              <Grid className="input-label onboarding-inputlabel" item xs={3}>
                {t("contact.address")}
                <SupRemark />
              </Grid>
              <Grid item xs={9}>
                <TextInput multiline={true} value={address} ret={setAddress} />
              </Grid>
              <Grid className="input-label onboarding-inputlabel" item xs={3}>
                {t("contact.zipcode")}
                <SupRemark />
              </Grid>
              <Grid item xs={9}>
                <ZipCodeInput
                  countryId={idCountry}
                  zipCode={zipCode}
                  setZipCode={setZipCode}
                  isValid={zipCodeValid}
                  setIsValid={setZipCodeValid}
                />
              </Grid>
              <Grid className="input-label onboarding-inputlabel" item xs={3}>
                {t("contact.city")}
                <SupRemark />
              </Grid>
              <Grid item xs={9}>
                <TextInput value={city} ret={setCity} />
              </Grid>
              <Grid className="input-label onboarding-inputlabel" item xs={3}>
                {t("contact.phone")}
                <SupRemark />
              </Grid>
              <Grid item xs={9}>
                <TextInput value={phone} ret={setPhone} />
              </Grid>
              <Grid className="input-label onboarding-inputlabel" item xs={3}>
                {t("contact.email")}
                <SupRemark />
              </Grid>
              <Grid item xs={9}>
                <TextInput value={email} ret={setEmail} />
              </Grid>
              <Grid className="input-label onboarding-inputlabel" item xs={3}>
                {t("contact.description")}
              </Grid>
              <Grid item xs={9}>
                <TextInput value={description} ret={setDescription} />
              </Grid>
            </Grid>
          )}
        </Box>
        {isWorking ? (
          <Box className="application-modal-footer"></Box>
        ) : (
          <Box className="application-modal-footer">
            <Button
              variant="contained"
              className="button-with-text"
              disabled={
                GeneralFunctions.isBlank(firstName) ||
                GeneralFunctions.isBlank(sureName) ||
                GeneralFunctions.isBlank(address) ||
                GeneralFunctions.isBlank(phone) ||
                GeneralFunctions.isBlank(email) ||
                GeneralFunctions.isBlank(idCountry) ||
                GeneralFunctions.isBlank(city) ||
                !zipCodeValid
              }
              color="primary"
              onClick={handleSave}
            >
              {t("common.buttons.save")}
            </Button>

            {props.onCancel && (
              <Button
                className="modal-cancel-button button-with-text"
                variant="outlined"
                color="primary"
                onClick={handleCancel}
              >
                {t("common.buttons.cancel")}
              </Button>
            )}
          </Box>
        )}
      </Stack>
    </div>
  );
};

export interface contactsViewProps {
  title: string;
  contact?: ContactReducedDto;
  onSave: (item: ContactReducedDto) => Promise<boolean>;
  onCancel?: () => void;
}

export interface ContactReducedDto extends CustomerContactReducedDto {}
