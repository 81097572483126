import { Stack } from "@mui/material";
import {
  CompanyRelationRoles,
  CustomerCompleteDto,
  ManufacturerReducedDto,
} from "../../../../robotcloud-shared/resource-models";
import { YesNoOkDialog } from "../../components/news/YesNoOkDialog";
import { useEffect, useState } from "react";
import { TitleWithAction } from "../../components/news/TitleWithAction";
import { TableGeneric } from "../../components/news/TableGeneric/TableGeneric";
import { OrderDto } from "../../data/common";
import { TableGenericHelper } from "../../components/news";
import { ToastHelper } from "../../components/news/Toast.helper";
import { ErrorHelper } from "../../uiHelpers/errors.helper";
import { ManufacturersSelectModal } from "../shared/manufacturers/ManufacturersSelectModal";
import { ManufacturersCustomersRequests } from "../../data/manufacturersCustomers.request";
import { ManufacturersColumnDefinition } from "../shared/manufacturers/ManufacturersColumnDefinition";
import { ManufacturerModifyModal } from "../shared/manufacturers/ManufacturerModifyModal";
import { PermissionsHelper } from "../../uiHelpers/permissionsHelper";
import { useTranslation } from "react-i18next";

export const CustomerViewManufacturers = (
  props: customerViewManufacturersProps
) => {
  const { t } = useTranslation();

  const [popupDto, setDto] = useState<ManufacturerReducedDto | undefined>(
    undefined
  );
  const [popupCloseRelationOpen, setPopupCloseRelationOpen] = useState(false);
  const [popupSelectManufacturersOpen, setPopupSelectManufacturersOpen] =
    useState(false);
  const [popupSelectManufacturersCurrent, setPopupSelectManufacturersCurrent] =
    useState<string[]>([]);
  const [popupModifyOpen, setPopupModifyOpen] = useState(false);
  const [dataOrder, setDataOrder] = useState<OrderDto>({
    orderColumns: "name",
    order: "Asc",
  });
  const [isWorking, setIsWorking] = useState(false);
  const [canAdd, setCanAdd] = useState(false);
  const [canModify, setCanModify] = useState(false);
  const [canDelete, setCanDelete] = useState(false);

  useEffect(() => {
    setCanAdd(
      PermissionsHelper.allow("Customers", "Edit", "Manufacturers", "Ask") &&
        props.canEdit
    );
    setCanModify(
      PermissionsHelper.allow("Customers", "Edit", "Manufacturers", "Edit") &&
        props.canEdit
    );
    setCanDelete(
      PermissionsHelper.allow("Customers", "Edit", "Manufacturers", "Remove") &&
        props.canEdit
    );

    TableGenericHelper.localOrder(dataOrder, props.manufacturers);
  }, [props, dataOrder]);

  const handleAddManufacturers = () => {
    setPopupSelectManufacturersCurrent(props.manufacturers.map((x) => x.id));
    setPopupSelectManufacturersOpen(true);
  };

  const handleCloseRelationYes = async (
    manufacturer: ManufacturerReducedDto
  ) => {
    setIsWorking(true);

    try {
      await ManufacturersCustomersRequests.delete(
        manufacturer.id,
        props.item.id
      );
      props.update();
      setPopupCloseRelationOpen(false);
      setIsWorking(false);
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
      setIsWorking(false);
    }
  };

  const addManufacturers = async (manufacturerIds: string[]) => {
    manufacturerIds.map(async (x) => {
      try {
        await ManufacturersCustomersRequests.create({
          idManufacturer: x,
          idCustomer: props.item.id,
          role: "Viewer",
        });
      } catch (error) {
        ToastHelper.errors(ErrorHelper.process(error));
      }

      props.update();
      setPopupSelectManufacturersOpen(false);
    });
  };

  const handleModifySave = async (role: CompanyRelationRoles) => {
    try {
      setPopupModifyOpen(false);
      await ManufacturersCustomersRequests.modify({
        idManufacturer: popupDto!.id,
        idCustomer: props.item.id,
        role: role,
      });

      props.update();
    } catch (error: any) {
      ToastHelper.errors(ErrorHelper.process(error));
    }
  };

  return (
    <Stack className="w-100">
      <TitleWithAction
        title={t("manufacturer.entitiesName")}
        titleContainerClassName="child-sub-title"
        tooltip={t("manufacturer.add.tooltip")}
        showButton={canAdd}
        onClick={handleAddManufacturers}
      />

      {!props.manufacturers || props.manufacturers.length === 0 ? (
        <></>
      ) : (
        <>
          <TableGeneric
            tableClassName="w-100"
            columns={ManufacturersColumnDefinition(
              canModify,
              canDelete,
              setDto,
              setPopupCloseRelationOpen,
              setPopupModifyOpen
            )}
            items={props.manufacturers}
            idProperty="id"
            headerClick={(header, itemsOrder) =>
              TableGenericHelper.localOrder(
                itemsOrder,
                props.manufacturers!,
                setDataOrder
              )
            }
            itemsOrder={dataOrder}
          ></TableGeneric>

          <YesNoOkDialog
            open={popupCloseRelationOpen}
            size="md"
            type="yesNo"
            title={t("manufacturer.unlink.title")}
            message={`${t("manufacturer.unlink.warning")} ${popupDto?.name}?`}
            onYes={() => handleCloseRelationYes(popupDto!)}
            onNo={() => setPopupCloseRelationOpen(false)}
            isWorking={isWorking}
          />

          {popupDto && popupModifyOpen && (
            <ManufacturerModifyModal
              isOpenModal={popupModifyOpen}
              role={popupDto.role}
              id={popupDto.id}
              onSave={handleModifySave}
              onCancel={() => setPopupModifyOpen(false)}
            />
          )}
        </>
      )}

      {popupSelectManufacturersOpen && (
        <ManufacturersSelectModal
          idsToRemove={popupSelectManufacturersCurrent}
          isOpenModal={popupSelectManufacturersOpen}
          onSave={async (manufacturerIds: string[]) =>
            await addManufacturers(manufacturerIds)
          }
          onCancel={() => setPopupSelectManufacturersOpen(false)}
        />
      )}
    </Stack>
  );
};

export interface customerViewManufacturersProps {
  manufacturers: ManufacturerReducedDto[];
  item: CustomerCompleteDto;
  canEdit: boolean;
  update: () => void;
}
