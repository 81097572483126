import { useEffect, useState } from "react";
import { WorkflowTranslatedDataDto } from "../../../../robotcloud-shared/resource-models";
import { getWorkflowTranslatedData, Workflow } from "./onBoardingService";
import "./fileWorkflow.scss";
import { Checkbox, Tooltip } from "@mui/material";
import { WorkflowsRequests } from "../../data/workflows.request";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { WorkflowTranslatedDatasRequests } from "../../data/workflowTranslatedDatas.request";
import { WorkflowLanguageSelector } from "./WorkflowLanguageSelector";
import { Loading } from "../../components/Loading";
import { Banner } from "../../components/news";

export const FileWorkflow = ({
  workflow,
  initialLanguage,
  onAccepting,
}: FileWorkflowProps) => {
  const [language, setLanguage] = useState<string>();
  const [translatedData, setTranslatedData] = useState<
    WorkflowTranslatedDataDto | undefined
  >();

  const [checked, setChecked] = useState<boolean>(false);
  const [pdfContent, setPdfContent] = useState<string>();

  useEffect(() => {
    onAccepting(checked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  useEffect(() => {
    if (workflow) {
      const translation = getWorkflowTranslatedData(
        workflow.workflowTranslatedDatas,
        initialLanguage
      );
      if (translation) {
        setLanguage(initialLanguage);
      } else {
        setLanguage("--");
      }
    }
  }, [workflow, initialLanguage]);

  useEffect(() => {
    const assignPdfContent = (
      translatedDataId: string | undefined,
      workflowId: string
    ) => {
      if (translatedDataId) {
        WorkflowTranslatedDatasRequests.getPdfContent(translatedDataId).then(
          (result) => {
            if (result.status === 200) {
              setPdfContent(URL.createObjectURL(result.data));
            }
          }
        );
      } else {
        WorkflowsRequests.getPdf(workflowId).then((result) => {
          if (result.status === 200) {
            setPdfContent(URL.createObjectURL(result.data));
          }
        });
      }
    };

    setPdfContent(undefined);
    if (workflow && language) {
      const translation = getWorkflowTranslatedData(
        workflow.workflowTranslatedDatas,
        language
      );
      assignPdfContent(translation?.id, workflow.id);
      setTranslatedData(translation);
      setChecked(false);
    }

    return () => {
      setPdfContent(undefined);
    };
  }, [workflow, language]);

  return (
    <div className="col-12 workflow-component-container 2">
      <div className="d-flex col-12">
        <Tooltip
          title={translatedData?.title ?? workflow.displayTitle}
          placement="top"
          arrow
        >
          <h3 className="workflow-component-title col-12">
            {translatedData?.title ?? workflow.displayTitle}
          </h3>
        </Tooltip>
      </div>
      {workflow && workflow.workflowTranslatedDatas.length > 0 && (
        <>
          <div className="col-8"></div>
          <div className="col-4">
            <WorkflowLanguageSelector
              workflow={workflow}
              current={language}
              selectionChange={(select: string | undefined) => {
                if (select) {
                  setLanguage(select);
                }
              }}
            />
          </div>
        </>
      )}

      <div className="col-12 mt-2">
        <Banner type="info">
          <span className="workflow-component-message">
            {translatedData?.instructions ?? workflow.displayInstructions}
          </span>
        </Banner>
      </div>

      {pdfContent ? (
        <object
          id="file-object"
          aria-label="PdfFile"
          className="border file-container"
          data={`${pdfContent}#toolbar=0`}
          type="application/pdf"
        ></object>
      ) : (
        <div id="file-object">
          <Loading isLoading={true} />
        </div>
      )}
      <p id="file-download">
        <a
          href={pdfContent}
          target="_blank"
          download={translatedData?.title ?? workflow.displayTitle}
          rel="noreferrer"
        >
          <CloudDownloadIcon color="primary" />
        </a>
      </p>
      <div id="file-accept">
        <Tooltip
          title={translatedData?.agreement ?? workflow.displayAgreement}
          placement="top"
          arrow
        >
          <div className="text-overflow-ellipsis accept-conditions col-11">
            {translatedData?.agreement ?? workflow.displayAgreement}
          </div>
        </Tooltip>
        <div className="col-1 text-end">
          <Checkbox
            className="pe-0"
            color="primary"
            disableRipple
            checked={checked}
            onChange={(event) => setChecked(event.target.checked)}
          />
        </div>
      </div>
    </div>
  );
};

interface FileWorkflowProps {
  workflow: Workflow;
  initialLanguage: string;
  onAccepting: (accept: boolean) => void;
}
