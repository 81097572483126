import {
  KemaroCleaningErrorCompleteDto,
  KemaroMissionRunDto,
} from "../../../../../robotcloud-shared/resource-models";
import { GeneralFunctions } from "../../generalFunctions";

import WatchLaterOutlined from "@mui/icons-material/WatchLaterOutlined";
import TabUnselectedOutlinedIcon from "@mui/icons-material/TabUnselectedOutlined";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import StopOutlinedIcon from "@mui/icons-material/StopOutlined";
import BatteryChargingFullOutlinedIcon from "@mui/icons-material/BatteryChargingFullOutlined";
import BatteryCharging60OutlinedIcon from "@mui/icons-material/BatteryCharging60Outlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTranslation } from "react-i18next";
import { TooltipButton } from "../../../components/news";
import { Theme, useTheme } from "@mui/material/styles";

let theme: Theme;

export const KemaroMissionViewRunDetail = (
  props: kemaroMissionViewRunDetailProps
) => {
  theme = useTheme();
  let currentError = 0;
  return (
    <>
      {props.hasErrors && (
        <div className="row">
          {props.run.cleaningErrors.map((error) => {
            currentError++;
            return (
              <RunError
                key={"error_" + currentError}
                error={error}
                errorCodesAsButton={props.errorCodesAsButton}
              />
            );
          })}
        </div>
      )}

      <div className="w-100 px-4">
        <table className="w-100">
          <tbody>
            <tr>
              <td className="v-top">
                <RunInfoLeft
                  current={props.current}
                  total={props.total}
                  run={props.run}
                  icon={props.icon}
                  status={props.status}
                  hasErrors={props.hasErrors}
                  errorCodesAsButton={props.errorCodesAsButton}
                />
              </td>
              <td style={{ width: "5em" }}>&nbsp;</td>
              <td className="v-top">
                <RunInfoRight
                  current={props.current}
                  total={props.total}
                  run={props.run}
                  icon={props.icon}
                  status={props.status}
                  hasErrors={props.hasErrors}
                  errorCodesAsButton={props.errorCodesAsButton}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

interface kemaroMissionViewRunDetailProps {
  current: number;
  total: number;
  run: KemaroMissionRunDto;
  icon: any;
  status: string;
  hasErrors: boolean;
  errorCodesAsButton: boolean;
}

interface kemaroMissionViewRunErrorProps {
  error: KemaroCleaningErrorCompleteDto;
  errorCodesAsButton: boolean;
}

const RunError = (props: kemaroMissionViewRunErrorProps) => {
  const { t } = useTranslation();

  return (
    <div
      className="mission-error d-flex"
      style={{
        color: theme.palette.swarm?.kemaroMissions?.missionError,
        border: theme.palette.swarm?.kemaroMissions?.missionErrorBorder,
      }}
    >
      <div className="d-flex">
        {props.errorCodesAsButton && (
          <TooltipButton
            aria-label={props.error.errorCode}
            tooltip={props.error.errorCode}
            onClick={() => navigator.clipboard.writeText(props.error.errorCode)}
          >
            <InfoOutlinedIcon
              className="mission-error-icon"
              style={{
                color: theme.palette.swarm?.kemaroMissions?.missionErrorIcon,
              }}
            />
          </TooltipButton>
        )}

        {!props.errorCodesAsButton && (
          <InfoOutlinedIcon className="mission-error-icon" />
        )}
      </div>
      <div className="flex-fill ps-3">
        <table className="w-100">
          <tbody>
            <tr>
              <td className="pt-1 pb-1">{props.error.title}</td>
              <td>
                <span className="mx-3 pt-1 pb-1">
                  {GeneralFunctions.dateToTimeDateString(props.error.timeStamp)}
                </span>
              </td>
              <td className="text-end pt-1 pb-1">
                {props.error.mapPosition2D && (
                  <span>
                    {t("mission.details.errorPosition", {
                      x: coordinateApplyStype(props.error.mapPosition2D.x),
                      y: coordinateApplyStype(props.error.mapPosition2D.y),
                    })}
                  </span>
                )}
              </td>
            </tr>
            <tr>
              <td className="pt-1 pb-1" colSpan={3}>
                {props.error.description}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

const RunInfoLeft = (props: kemaroMissionViewRunDetailProps) => {
  const { t } = useTranslation();

  return (
    <table
      className="run-detail-table w-100"
      style={{
        color: theme.palette.swarm?.kemaroMissions?.viewRunsRunDetailTable,
      }}
    >
      <tbody>
        <tr>
          <td className="run-detail-title-cell py-1">
            {t("mission.details.startTime")}
          </td>
          <td className="run-detail-value-cell py-1 pe-2">
            {GeneralFunctions.dateToTimeDateString(
              props.run.timestampCleaningStart ?? "2022-01-01"
            )}
          </td>
          <td className="run-detail-icon-cell py-1">
            <WatchLaterOutlined className="run-detail-icon-icon" />
          </td>
        </tr>

        {(props.run?.cleanedAreaM2 ?? 0) > 0 && (
          <tr>
            <td className="run-detail-title-cell py-1">
              {t("mission.details.totalCleanedArea")}
            </td>
            <td className="run-detail-value-cell py-1 pe-2">
              <>{props.run.cleanedAreaM2!.toString()}</> m<sup>2</sup>
            </td>
            <td className="run-detail-icon-cell py-1">
              <TabUnselectedOutlinedIcon className="run-detail-icon-icon" />
            </td>
          </tr>
        )}

        {props.run.cleaningPerformance > 0 && (
          <tr>
            <td className="run-detail-title-cell py-1">
              {t("mission.details.cleaningPerformance")}
            </td>
            <td className="run-detail-value-cell py-1 pe-2">
              {props.run.cleaningPerformance} m<sup>2</sup>/h
            </td>
            <td className="run-detail-icon-cell py-1">
              <AnalyticsOutlinedIcon className="run-detail-icon-icon" />
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

const RunInfoRight = (props: kemaroMissionViewRunDetailProps) => {
  const { t } = useTranslation();

  return (
    <table
      className="run-detail-table"
      style={{
        width: "100%",
        color: theme.palette.swarm?.kemaroMissions?.viewRunsRunDetailTable,
      }}
    >
      <tbody>
        {props.run.startReasonDisplayName.length > 0 && (
          <tr>
            <td className="run-detail-title-cell py-1">
              {t("mission.details.startReason")}
            </td>
            <td className="run-detail-value-cell py-1 pe-2">
              {props.run.startReasonDisplayName}
            </td>
            <td className="run-detail-icon-cell py-1">
              <PlayArrowOutlinedIcon className="run-detail-icon-icon" />
            </td>
          </tr>
        )}

        {props.run.stopReasonDisplayName.length > 0 && (
          <tr>
            <td className="run-detail-title-cell py-1">
              {t("mission.details.stopReason")}
            </td>
            <td className="run-detail-value-cell py-1 pe-2">
              {props.run.stopReasonDisplayName}
            </td>
            <td className="run-detail-icon-cell py-1">
              <StopOutlinedIcon className="run-detail-icon-icon" />
            </td>
          </tr>
        )}
        <tr>
          <td className="run-detail-title-cell py-1">
            {t("mission.details.startBattery")}
          </td>
          <td className="run-detail-value-cell py-1 pe-2">
            {`${props.run.batteryPercentageStart} %`}
          </td>
          <td className="run-detail-icon-cell py-1">
            <BatteryChargingFullOutlinedIcon className="run-detail-icon-icon" />
          </td>
        </tr>
        <tr>
          <td className="run-detail-title-cell py-1">
            {t("mission.details.endBattery")}
          </td>
          <td className="run-detail-value-cell py-1 pe-2">
            {`${props.run.batteryPercentageEnd} %`}
          </td>
          <td className="run-detail-icon-cell py-1">
            <BatteryCharging60OutlinedIcon className="run-detail-icon-icon" />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const coordinateApplyStype = (coordinate: number) => {
  const sections = coordinate.toString().split(".");
  return `${sections[0]}.${(
    (sections.length > 1 ? sections[1] : "") + "00"
  ).substring(0, 2)}m`;
};
