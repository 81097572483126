import { NativeSelect } from "@mui/material";
import { UserAccessType } from "../../../../../robotcloud-shared/resource-models";
import { CustomerSearch } from "../../../components/search/CustomerSearch";
import { DealerSearch } from "../../../components/search/DealerSearch";
import { ManufacturerSearch } from "../../../components/search/ManufacturerSearch";
import { useTranslation } from "react-i18next";

export const UserAccessTypeSelector = (props: UserAccessTypeSelectorProps) => {
  const { t } = useTranslation();

  return (
    <div className="col-12">
      <div className="row mt-2">
        <div className="col-4 input-label mt-2">{t("user.accessType")}</div>
        <div className="col-3 pt-13-px">
          <NativeSelect
            className="w-100"
            value={props.accessType}
            onChange={(event) =>
              props.accessTypeChange(
                (event.target.value as UserAccessType) ?? "Unassigned"
              )
            }
          >
            {props.accessTypes.map((x) => (
              <option key={x} value={x}>
                {x}
              </option>
            ))}
          </NativeSelect>
        </div>
        <div className="col-5 mt-2">
          {props.accessType === "Manufacturer" && (
            <ManufacturerSearch
              currentId={props?.accessTypeId ?? " "}
              selectionChange={(x) => props.accessTypeIdChange(x?.id ?? null)}
            />
          )}

          {props.accessType === "Dealer" && (
            <DealerSearch
              currentId={props?.accessTypeId ?? " "}
              selectionChange={(x) => props.accessTypeIdChange(x?.id ?? null)}
            />
          )}

          {props.accessType === "Customer" && (
            <CustomerSearch
              currentId={props?.accessTypeId ?? " "}
              selectionChange={(x) => props.accessTypeIdChange(x?.id ?? null)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

interface UserAccessTypeSelectorProps {
  accessTypes: UserAccessType[];
  accessType: UserAccessType;
  accessTypeChange: (accessType: UserAccessType) => void;
  accessTypeId: string | null;
  accessTypeIdChange: (accessTypeId: string | null) => void;
}
