import { useEffect, useState } from "react";
import { DashboardEconomyUiConfigurationDto } from "../../../../../../../robotcloud-shared/resource-models";
import { KemaroDashboardEconomyRequests } from "../../../../../data/kemaro/KemaroDashboardEconomy.request";
import { ToastHelper } from "../../../../../components/news";
import { ErrorHelper } from "../../../../../uiHelpers/errors.helper";

export const useKemaroDashboardEconomyConfigurationUi = (
  whenCurrentStaticWeeksChange: (x: number) => void
) => {
  const [uiConfiguration, setUiConfiguration] = useState<
    DashboardEconomyUiConfigurationDto | undefined
  >(undefined);

  useEffect(() => {
    getConfiguration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getConfiguration = async () => {
    try {
      const config = await KemaroDashboardEconomyRequests.getUiConfig();
      setUiConfiguration(config);
      currentStaticWeeksChange(config.staticWeekDefault);
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }
  };

  const currentStaticWeeksChange = (value: number) => {
    if (!uiConfiguration) return;

    const newValue = uiConfiguration.staticWeeks.some((x) => x === value)
      ? value
      : uiConfiguration.staticWeekDefault;

    whenCurrentStaticWeeksChange(newValue);
  };

  return { uiConfiguration, currentStaticWeeksChange };
};
