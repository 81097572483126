import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";
import { CompanyRelationRoles } from "../../../../robotcloud-shared/resource-models";
import { TooltipContainer } from "./TooltipContainer";
import "./userRoleIcon.scss";
import { useTranslation } from "react-i18next";

export const UserRoleIcon = (props: userRoleIconProps) => {
  const { t } = useTranslation();

  if (props.role === "Editor") {
    return (
      <TooltipContainer
        text={t("companyRole.canEditTooltip")}
        key={props.keyValue}
      >
        <CreateRoundedIcon
          className="user-role-icon"
          sx={{ color: "swarm.userRoleIcon" }}
        />
      </TooltipContainer>
    );
  }

  return (
    <TooltipContainer
      text={t("companyRole.canViewTooltip")}
      key={props.keyValue}
    >
      <RemoveRedEyeRoundedIcon
        className="user-role-icon"
        sx={{ color: "swarm.userRoleIcon" }}
      />
    </TooltipContainer>
  );
};

interface userRoleIconProps {
  keyValue: string;
  role: CompanyRelationRoles;
}
