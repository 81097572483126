import { ManufacturerDealersPostDto } from "../../../robotcloud-shared/resource-models";
import { AxiosRequests } from "./axios.requests";
import { BaseRequest } from "./BaseRequest";

export class ManufacturersDealersRequests extends BaseRequest {
  static controller = "api/webapp/v1.0/organizations/ManufacturersDealers";

  static create = async (postDto: ManufacturerDealersPostDto) => {
    await AxiosRequests.post(ManufacturersDealersRequests.controller, postDto);
  };

  static modify = async (postDto: ManufacturerDealersPostDto) => {
    await AxiosRequests.put(ManufacturersDealersRequests.controller, postDto);
  };

  static delete = async (manufacturerId: string, dealerId: string) => {
    await AxiosRequests.delete(
      `${ManufacturersDealersRequests.controller}/${manufacturerId}/${dealerId}`
    );
  };
}
