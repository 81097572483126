import { Stack, Switch } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import {
  NotificationConfigurationDto,
  TestEmailDto,
  UserCompleteDto,
} from "../../../../robotcloud-shared/resource-models";
import { Loading } from "../../components/Loading";
import { ViewHeader } from "../../components/news/ViewHeader";
import { UserNotificationConfigurations } from "../shared/users/UserNotificationConfigurations";
import { ToastHelper } from "../../components/news/Toast.helper";
import { ErrorHelper } from "../../uiHelpers/errors.helper";
import { UsersRequests } from "../../data/users.requests";
import Edit from "@mui/icons-material/Edit";
import DeleteForever from "@mui/icons-material/DeleteForever";
import KeyIcon from "@mui/icons-material/Key";
import MenuBookTwoToneIcon from "@mui/icons-material/MenuBookTwoTone";
import { YesNoOkDialog } from "../../components/news/YesNoOkDialog";
import { UsersCurrentModifyModal } from "../shared/users/UsersCurrentModifyModal";
import { TooltipButton } from "../../components/news/TooltipButton";
import AuthorizationContext from "../auth/authorizationContext";
import { useTranslation } from "react-i18next";
import { MaxbrainUserModal } from "../shared/maxbrainUserModal/MaxbrainUserModal";
import { tableGenericHandleKeyboardSubjectManager } from "../../components/news";

export const UserView = (props: userViewProps) => {
  const { t } = useTranslation();

  const [id, setId] = useState("");
  const [user, setUser] = useState<UserCompleteDto | undefined>(undefined);
  const [isResetPasswordSendEmail, setIsResetPasswordSendEmail] =
    useState(true);
  const [isOpenResetPasswordPopup, setIsOpenResetPasswordPopup] =
    useState(false);
  const [isOpenResetPasswordResultPopup, setIsOpenResetPasswordResultPopup] =
    useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [isOpenEditPopup, setIsOpenEditPopup] = useState(false);
  const [isOpenDeletePopup, setIsOpenDeletePopup] = useState(false);
  const [isWorking, setIsWorking] = useState(false);
  const [isOpenKemaroAcademyUserModal, setIsOpenKemaroAcademyUserModal] =
    useState(false);

  const { isKemaroAcademySection, isManufacturerAdmin } =
    useContext(AuthorizationContext);

  useEffect(() => {
    if (!props.item) {
      return;
    }

    setId(props.item.id);
    setUser(props.item);
  }, [props, id]);

  if (props.isFetching) {
    return <Loading isLoading={props.isFetching} />;
  }

  if (!props.item || !user) {
    return <></>;
  }

  const handleResetPassword = async () => {
    setIsWorking(true);

    try {
      const result = await UsersRequests.resetPassword(
        user.id,
        isResetPasswordSendEmail
      );

      setIsOpenResetPasswordPopup(false);
      setNewPassword(result.newPassword);
      setIsOpenResetPasswordResultPopup(true);
      setIsResetPasswordSendEmail(true);

      props.onUpdate(result.id);
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsWorking(false);
  };

  const modifyUserSave = async (item: UserCompleteDto) => {
    setIsWorking(true);

    if (await props.onEdit(item)) {
      setIsOpenEditPopup(false);
    }

    setIsWorking(false);
  };

  const handleDeleteUser = async () => {
    setIsWorking(true);

    if (await props.onDelete(user)) {
      setIsOpenDeletePopup(false);
    }

    setIsWorking(false);
  };

  const handleNotificationConfigurationSave = async (
    item: NotificationConfigurationDto,
    isNew: boolean
  ) => {
    let result = false;

    try {
      let notificationConfigurations = [...user.notificationConfigurations];

      if (isNew) {
        notificationConfigurations.push(item);
      } else {
        const idx = user.notificationConfigurations.indexOf(item);
        notificationConfigurations.splice(idx - 1, 1, item);
      }

      await UsersRequests.putNotificationConfiguration(
        user,
        notificationConfigurations
      );

      result = true;

      setUser({
        ...user,
        notificationConfigurations: notificationConfigurations,
      });
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    return result;
  };

  const handleNotificationConfigurationDelete = async (
    item: NotificationConfigurationDto
  ) => {
    let result = false;

    try {
      let notificationConfigurations = [...user.notificationConfigurations];
      const idx = user.notificationConfigurations.indexOf(item);
      notificationConfigurations.splice(idx - 1, 1);

      await UsersRequests.putNotificationConfiguration(
        user,
        notificationConfigurations
      );

      result = true;

      setUser({
        ...user,
        notificationConfigurations: notificationConfigurations,
      });
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    return result;
  };

  const handleNotificationConfigurationTest = async (
    item: NotificationConfigurationDto
  ) => {
    try {
      const testEmail: TestEmailDto = {
        email: item.emailAddress,
      };

      await UsersRequests.postSendEmailTest(testEmail);
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }
  };

  return (
    <Stack className="user-view-container">
      <ViewHeader
        title={props.item.userName}
        copyLinkId={props.item.id}
        canEdit={false}
        canDelete={false}
      >
        <>
          <TooltipButton
            aria-label="Reset password"
            tooltip={t("user.resetPassword.tooltip")}
            onClick={() => setIsOpenResetPasswordPopup(true)}
            disabled={!user.userName?.endsWith("@eb8.ch")}
          >
            <KeyIcon
              color={
                user.userName?.endsWith("@eb8.ch") ? "primary" : "disabled"
              }
            />
          </TooltipButton>

          <TooltipButton
            aria-label="edit"
            tooltip={t("user.edit.tooltip")}
            onClick={() => setIsOpenEditPopup(true)}
          >
            <Edit color="primary" />
          </TooltipButton>

          <TooltipButton
            aria-label="delete"
            tooltip={t("user.delete.tooltip")}
            onClick={() => setIsOpenDeletePopup(true)}
          >
            <DeleteForever color="error" />
          </TooltipButton>

          {isKemaroAcademySection && isManufacturerAdmin && (
            <>
              <TooltipButton
                aria-label={t("kemaroAcademy.configurationTooltip")}
                tooltip={t("kemaroAcademy.configurationTooltip")}
                onClick={() => {
                  setIsOpenKemaroAcademyUserModal(true);
                  tableGenericHandleKeyboardSubjectManager.setData({
                    caller: "UserView",
                    handleKeyboard: true,
                  });
                }}
              >
                <MenuBookTwoToneIcon color="primary" />
              </TooltipButton>

              {isOpenKemaroAcademyUserModal && (
                <MaxbrainUserModal
                  isOpenModal={isOpenKemaroAcademyUserModal}
                  userId={user.id}
                  onCancel={() => {
                    tableGenericHandleKeyboardSubjectManager.setData({
                      caller: "UserView",
                      handleKeyboard: false,
                    });
                    setIsOpenKemaroAcademyUserModal(false);
                  }}
                />
              )}
            </>
          )}
        </>
      </ViewHeader>

      <div className="row mt-3">
        <div className="col-4 py-2">{t("user.firstName")}</div>
        <div className="col-8 py-2">{user.firstName}</div>

        <div className="col-4 py-2">{t("user.lastName")}</div>
        <div className="col-8 py-2">{user.lastName}</div>

        <div className="col-4 py-2">{t("common.role")}</div>
        <div className="col-8 py-2">{user.role}</div>

        <div className="col-4 py-2">{t("user.email")}</div>
        <div className="col-8 py-2">{user.passwordResetEmailAddress}</div>

        <div className="col-4 py-2">{t("user.timeZone")}</div>
        <div className="col-8 py-2">{user.windowsTimeZoneId}</div>

        <div className="col-4 py-2">{t("user.enabled")}</div>
        <div className="col-8">
          <Switch color="primary" disabled={true} checked={user.enabled} />
        </div>

        <div className="col-4 py-2">{t("user.accessType")}</div>

        <div className="col-8 py-2">
          {user.accessType} {user.accessName}
        </div>
      </div>

      <UserNotificationConfigurations
        notifications={user.notificationConfigurations}
        onSave={handleNotificationConfigurationSave}
        onDelete={handleNotificationConfigurationDelete}
        onTest={handleNotificationConfigurationTest}
      />

      <YesNoOkDialog
        open={isOpenResetPasswordPopup}
        size="md"
        type="yesNo"
        title={t("user.resetPassword.title")}
        message={`${t("user.resetPassword.warningReset")} ${user.firstName} ${
          user.lastName
        }?`}
        onYes={() => handleResetPassword()}
        onNo={() => {
          setIsResetPasswordSendEmail(true);
          setIsOpenResetPasswordPopup(false);
        }}
        isWorking={isWorking}
      >
        {isWorking ? (
          <></>
        ) : (
          <div className="row">
            <div className="col-12 mb-2 mt-2">
              {`${t("user.resetPassword.warningReset")} ${user.firstName} ${
                user.lastName
              }?`}
            </div>
            <div className="col-12">
              <span className="me-2">{t("user.resetPassword.sendMail")}</span>

              <Switch
                color="primary"
                checked={isResetPasswordSendEmail}
                onChange={(event) =>
                  setIsResetPasswordSendEmail(event.target.checked)
                }
              />
            </div>
          </div>
        )}
      </YesNoOkDialog>

      <YesNoOkDialog
        open={isOpenResetPasswordResultPopup}
        size="md"
        type="ok"
        title={t("user.resetPassword.newPasswordTitle")}
        onOk={() => {
          setNewPassword("");
          setIsOpenResetPasswordResultPopup(false);
        }}
      >
        <div className="col-12 mt-4">
          <span>
            {t("user.resetPassword.newPasswordMessage", {
              firstName: user.firstName,
              lastName: user.lastName,
            })}
            {"  "} <strong>{newPassword}</strong>
          </span>
        </div>
      </YesNoOkDialog>

      {isOpenEditPopup && (
        <UsersCurrentModifyModal
          user={user}
          isOpenModal={isOpenEditPopup}
          isWorking={isWorking}
          onCancel={() => setIsOpenEditPopup(false)}
          onSaved={modifyUserSave}
        />
      )}

      <YesNoOkDialog
        open={isOpenDeletePopup}
        size="md"
        type="yesNo"
        title={t("user.delete.title")}
        onYes={handleDeleteUser}
        onNo={() => setIsOpenDeletePopup(false)}
        isWorking={isWorking}
      >
        <div className="col-12 mt-4">
          {t("user.delete.warningHard1")}
          <strong className="px-1">{t("user.delete.warningHard2")}</strong>
          {`${t("user.delete.warningHard3")} ${user.firstName} ${
            user.lastName
          }?`}
        </div>
      </YesNoOkDialog>
    </Stack>
  );
};

export interface userViewProps {
  item: UserCompleteDto | undefined;
  onEdit: (item: UserCompleteDto) => Promise<boolean>;
  onUpdate: (newId: string) => void;
  onDelete: (item: UserCompleteDto) => Promise<boolean>;
  isFetching: boolean;
}
