import { Button, Dialog } from "@mui/material";
import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { Loading } from "../../components/Loading";
import { PasswordInput } from "../../components/news/PasswordInput";
import { ToastHelper } from "../../components/news/Toast.helper";
import { ErrorHelper } from "../../uiHelpers/errors.helper";
import { UsersRequests } from "../../data/users.requests";
import { GeneralFunctions } from "../generalFunctions";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./profile.scss";
import { tableGenericHandleKeyboardSubjectManager } from "../../components/news";
import useTheme from "@mui/material/styles/useTheme";
import { SupRemark } from "../../uiHelpers/SupRemark";

export const ProfilePasswordModal = (props: profilePasswordModalProps) => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordRepeat, setNewPasswordRepeat] = useState("");
  const [isWorking, setIsWorking] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    tableGenericHandleKeyboardSubjectManager.setData({
      caller: "ProfilePasswordModal",
      handleKeyboard: props.isOpenModal,
    });
  }, [props.isOpenModal]);

  const handleSave = async () => {
    try {
      setIsWorking(true);

      await UsersRequests.putResetPassword({
        currentPassword: currentPassword,
        newPassword: newPassword,
      });

      resetUi();
      props.onSaved();

      navigate("/logout");
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
      setIsWorking(false);
    }
  };

  const handleCancel = () => {
    resetUi();
    props.onCancel();
  };

  const resetUi = () => {
    setCurrentPassword("");
    setNewPasswordRepeat("");
    setNewPassword("");
  };
  const theme = useTheme();

  return (
    <Dialog
      className="application-modal"
      open={props.isOpenModal}
      maxWidth="md"
      onClose={handleCancel}
    >
      <div className="modal-content profile-password-modal">
        <Stack>
          <div className="application-modal-header">
            <h3 className="modal-title">{t("profile.changePassword.title")}</h3>
          </div>
          <div className="application-modal-body">
            {isWorking ? (
              <Loading isLoading={isWorking} />
            ) : (
              <div className="row">
                <div className="col-4 input-label">
                  {t("profile.changePassword.currentPassword")}
                  <SupRemark />
                </div>
                <div className="col-8">
                  <PasswordInput
                    isShowPassword={isShowPassword}
                    setIsShowPassword={setIsShowPassword}
                    value={currentPassword}
                    setValue={setCurrentPassword}
                    minLenght={10}
                    validatePassword={false}
                    autogenerateShow={false}
                  />
                </div>

                <div className="col-4 input-label">
                  {t("profile.changePassword.newPassword")}
                  <SupRemark />
                </div>
                <div className="col-8">
                  <PasswordInput
                    isShowPassword={isShowPassword}
                    setIsShowPassword={setIsShowPassword}
                    value={newPassword}
                    setValue={setNewPassword}
                    minLenght={10}
                    autogenerateClick={(x) => setNewPasswordRepeat(x)}
                  />
                </div>

                <div className="col-4 input-label">
                  {t("profile.changePassword.repeatNewPassword")}
                  <SupRemark />
                </div>
                <div className="col-8">
                  <PasswordInput
                    isShowPassword={isShowPassword}
                    setIsShowPassword={setIsShowPassword}
                    value={newPasswordRepeat}
                    setValue={setNewPasswordRepeat}
                    minLenght={10}
                    validatePassword={false}
                    autogenerateShow={false}
                  />
                  {newPassword.length > 0 &&
                    newPasswordRepeat.length > 0 &&
                    newPassword !== newPasswordRepeat && (
                      <p
                        className="error-message"
                        style={{
                          color:
                            theme.palette.swarm?.profile
                              ?.passwordModalErrorMessage,
                        }}
                      >
                        {t("profile.changePassword.passwordDoesNotMatch")}
                      </p>
                    )}
                </div>
              </div>
            )}
          </div>
          {isWorking ? (
            <div className="application-modal-footer"></div>
          ) : (
            <div className="application-modal-footer">
              <Button
                variant="contained"
                className="button-with-text"
                disabled={
                  GeneralFunctions.isBlank(currentPassword) ||
                  GeneralFunctions.isBlank(newPassword) ||
                  GeneralFunctions.isBlank(newPasswordRepeat) ||
                  newPassword !== newPasswordRepeat
                }
                color="primary"
                onClick={handleSave}
              >
                {t("common.buttons.save")}
              </Button>

              <Button
                className="modal-cancel-button button-with-text"
                variant="outlined"
                color="primary"
                onClick={handleCancel}
              >
                {t("common.buttons.cancel")}
              </Button>
            </div>
          )}
        </Stack>
      </div>
    </Dialog>
  );
};

export interface profilePasswordModalProps {
  userId: string;
  isOpenModal: boolean;
  onCancel: () => void;
  onSaved: () => void;
}
