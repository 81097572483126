import { TooltipButton } from "./TooltipButton";
import LinkIcon from "@mui/icons-material/Link";
import { useTranslation } from "react-i18next";

export const ShareIdButton = (props: ShareIdButtonProps) => {
  const { t } = useTranslation();

  const copyLinkToItem = async () => {
    navigator.clipboard.writeText(
      `${window.location.href.split("?")[0]}?id=${props.id}`
    );
  };

  return (
    <TooltipButton
      aria-label="copy link"
      tooltip={t("viewHeaderButtons.shareId")}
      onClick={copyLinkToItem}
    >
      <LinkIcon color="primary" />
    </TooltipButton>
  );
};

interface ShareIdButtonProps {
  id: string;
}
