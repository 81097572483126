export class QueryParamHelper {
  parameters: parameter[] = [];

  constructor(queryParam: string) {
    if (!queryParam) {
      return;
    }

    this.parameters = queryParam
      .substring(1)
      .split("&")
      .map((x) =>
        x.indexOf("=") > 0
          ? {
              name: x.split("=")[0],
              value: x.split("=")[1],
            }
          : {
              name: x,
              value: undefined,
            }
      );
  }

  is(paramName: string) {
    return this.getValue(paramName) !== undefined;
  }

  getValue(paramName: string) {
    return (
      this.parameters.find((x) => x.name === paramName)?.value ?? undefined
    );
  }

  any() {
    return this.parameters.length > 0;
  }
}

interface parameter {
  name: string;
  value?: string;
}
