import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { YesNoOkDialog } from "../../../../components/news";

const KemaroDashboardCostComparisonModal = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const data = [
    { title: "cloudCost", value: "cloudCostValue" },
    { title: "serviceCost", value: "serviceCostValue" },
    { title: "machineCost", value: "machineCostValue" },
    { title: "cleaningCost", value: "cleaningCostValue" },
  ];
  const theme = useTheme();

  return (
    <>
      <InfoOutlinedIcon
        onClick={() => setOpen(true)}
        color="primary"
        className="ms-1"
        role="button"
      />
      <YesNoOkDialog
        open={open}
        size="md"
        type="ok"
        title={t("cost.modal.title")}
        onOk={() => setOpen(false)}
      >
        <div className="col-12 mt-2">
          <div className="p-4 container-with-background w-100">
            <table className="table table-borderless w-100 mb-0">
              <tbody>
                {data.map((item) => (
                  <tr key={item.title}>
                    <td>
                      {t(
                        `cost.modal.${item.title}` as unknown as TemplateStringsArray
                      )}
                    </td>
                    <td>
                      {t(
                        `cost.modal.${item.value}` as unknown as TemplateStringsArray
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </YesNoOkDialog>
    </>
  );
};

export default KemaroDashboardCostComparisonModal;
