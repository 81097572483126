import { Visibility, VisibilityOff } from "@mui/icons-material";
import { TooltipButton } from "./TooltipButton";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

export const TooltipToogleVisibilyButton = ({
  className,
  initialValue,
  onShowPasswordChanged,
}: CopyButtonProps) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(initialValue ?? false);
  const changeShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    onShowPasswordChanged && onShowPasswordChanged(showPassword);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPassword]);

  return (
    <TooltipButton
      aria-label="password visibility"
      tooltip={t("passwordInput.passwordVisibility")}
      onClick={changeShowPassword}
      className={className}
      edge="end"
    >
      {showPassword ? (
        <Visibility color="primary" />
      ) : (
        <VisibilityOff color="primary" />
      )}
    </TooltipButton>
  );
};

interface CopyButtonProps {
  className?: string;
  initialValue?: boolean;
  onShowPasswordChanged?: (isVisible: boolean) => void;
}
