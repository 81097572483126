import {
  ManufacturerDealersDto,
  ManufacturerCustomersPostDto,
} from "../../../robotcloud-shared/resource-models";
import { AxiosRequests } from "./axios.requests";
import { BaseRequest } from "./BaseRequest";

export class ManufacturersCustomersRequests extends BaseRequest {
  static controller = "api/webapp/v1.0/organizations/manufacturersCustomers";

  static create = async (postDto: ManufacturerCustomersPostDto) => {
    const { data } = await AxiosRequests.post(
      ManufacturersCustomersRequests.controller,
      postDto
    );
    return data as ManufacturerDealersDto;
  };

  static modify = async (postDto: ManufacturerCustomersPostDto) => {
    await AxiosRequests.put(ManufacturersCustomersRequests.controller, postDto);
  };

  static delete = async (manufacturerId: string, customerId: string) => {
    await AxiosRequests.delete(
      `${ManufacturersCustomersRequests.controller}/${manufacturerId}/${customerId}`
    );
  };
}
