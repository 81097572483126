import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import { useTranslation } from "react-i18next";

import { TextInput } from "../../../components/news/TextInput";
import { NotificationConfigurationDto } from "../../../../../robotcloud-shared/resource-models";
import { Loading } from "../../../components/Loading";
import { GeneralFunctions } from "../../generalFunctions";
import { tableGenericHandleKeyboardSubjectManager } from "../../../components/news";
import { SupRemark } from "../../../uiHelpers/SupRemark";

export const UserNotificationConfigurationsModal = (
  props: userconfigurationsModalProps
) => {
  const [notification, setNotification] =
    useState<NotificationConfigurationDto>(props.notification);
  const { t } = useTranslation();

  useEffect(() => {
    setNotification(props.notification);
  }, [props]);

  useEffect(() => {
    tableGenericHandleKeyboardSubjectManager.setData({
      caller: "UserNotificationConfigurationsModal",
      handleKeyboard: props.isOpenModal,
    });
  }, [props.isOpenModal]);

  const handleClearState = () => {
    setNotification(props.notification);
  };

  const handleCancel = () => {
    handleClearState();
    props.onCancel();
  };

  return (
    <Dialog
      className="application-modal"
      open={props.isOpenModal}
      maxWidth="md"
      onClose={handleCancel}
    >
      <div className="modal-content">
        <Stack>
          <Box className="application-modal-header">
            <h3>{props.title}</h3>
          </Box>
          <Box className="application-modal-body">
            {props.isWorking ? (
              <Loading isLoading={props.isWorking} />
            ) : (
              <div className="row mt-4">
                <div className="col-4 input-label mb-3">
                  {t(
                    "userNotificationConfigurations.configurations.mailAddress"
                  )}
                  {props.isNew && <SupRemark />}
                </div>
                <div
                  className={"col-8 mb-3 " + (props.isNew ? "" : "input-label")}
                >
                  {props.isNew ? (
                    <TextInput
                      value={notification.emailAddress}
                      ret={(x) =>
                        setNotification({ ...notification, emailAddress: x })
                      }
                    />
                  ) : (
                    notification.emailAddress
                  )}
                </div>

                <div className="col-4 input-label mb-3">
                  {t(
                    "userNotificationConfigurations.configurations.missionFail"
                  )}
                </div>
                <div className="col-8 mb-3">
                  <Switch
                    color="primary"
                    checked={notification.autoSendFail}
                    onChange={(event) =>
                      setNotification({
                        ...notification,
                        autoSendFail: event.target.checked,
                      })
                    }
                  />
                </div>

                <div className="col-4 input-label">
                  {t(
                    "userNotificationConfigurations.configurations.missionComplete"
                  )}
                </div>
                <div className="col-8">
                  <Switch
                    color="primary"
                    checked={notification.autoSendComplete}
                    onChange={(event) =>
                      setNotification({
                        ...notification,
                        autoSendComplete: event.target.checked,
                      })
                    }
                  />
                </div>
              </div>
            )}
          </Box>
          {props.isWorking ? (
            <Box className="application-modal-footer"></Box>
          ) : (
            <Box className="application-modal-footer">
              <Button
                variant="contained"
                className="button-with-text"
                disabled={
                  GeneralFunctions.isBlank(notification.emailAddress) ||
                  (props.isNew &&
                    props.notifications.some(
                      (x) =>
                        x.emailAddress.toLowerCase().trim() ===
                        notification.emailAddress.toLowerCase().trim()
                    )) ||
                  !GeneralFunctions.emailValidation(notification.emailAddress)
                }
                color="primary"
                onClick={async () => await props.onSave(notification)}
              >
                {t("common.buttons.save")}
              </Button>

              <Button
                className="modal-cancel-button button-with-text"
                variant="outlined"
                color="primary"
                onClick={handleCancel}
              >
                {t("common.buttons.cancel")}
              </Button>
            </Box>
          )}
        </Stack>
      </div>
    </Dialog>
  );
};

interface userconfigurationsModalProps {
  title: string;
  notification: NotificationConfigurationDto;
  notifications: NotificationConfigurationDto[];
  isNew: boolean;
  isOpenModal: boolean;
  isWorking: boolean;
  onSave: (item: NotificationConfigurationDto) => Promise<void>;
  onCancel: () => void;
}
