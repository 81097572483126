import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Autocomplete, NativeSelect, Switch, TextField } from "@mui/material";
import {
  DashboardCustomerDto,
  DashboardEconomyFilterDto,
  KemaroCompanyStructureDto,
} from "../../../../../../robotcloud-shared/resource-models";
import { Loading } from "../../../../components/Loading";
import { TooltipButton, TooltipContainer } from "../../../../components/news";
import "../kemaroDashboard.scss";
import LinkIcon from "@mui/icons-material/Link";
import { KemaroDashboardEconomyFiltersFilter } from "./KemaroDashboardEconomyFiltersFilter";
import { KemaroDashboardEconomyFiltersLinks } from "./KemaroDashboardEconomyFiltersLinks";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { useKemaroDashboardEconomyFilterData } from "./hooks/useKemaroDashboardEconomyFilterData";

export const KemaroDashboardEconomyFilters = (
  props: kemaroDashboardEconomyFiltersProps
) => {
  const [openCustomer, setOpenCustomer] = useState(false);
  const [openLocation, setOpenLocation] = useState(false);

  const { t } = useTranslation();

  const {
    isGettingCustomers,
    customer,
    customers,
    companyStructures,
    companyStructure,
    companyStructureChildren,
    calculationId,
    years,
    year,
    weeks,
    week,
    customerChange,
    companyStructureChange,
    companyStructureChildrenChange,
    yearChange,
    weekChange,
  } = useKemaroDashboardEconomyFilterData();

  useEffect(() => {
    if (
      !calculationId ||
      !customer?.id ||
      year === 0 ||
      !week?.week ||
      !companyStructure
    ) {
      props.filterChange(undefined);
      return;
    }

    const filter: DashboardEconomyFilterDto = {
      idCostCalculation: calculationId,
      idCustomer: customer.id,
      idCompanyStructure: companyStructure.id,
      includeChildren: companyStructureChildren,
      year: year,
      week: week.week,
    };

    props.filterChange(filter);
  }, [
    customer,
    calculationId,
    companyStructure,
    companyStructureChildren,
    year,
    week,
    props,
  ]);

  const copyLinkToItem = async () => {
    let queryParameters = "";

    if (customer?.id) queryParameters += `&customerId=${customer.id}`;

    if (companyStructure?.id)
      queryParameters += `&companyStructureId=${companyStructure.id}&incchild=${
        companyStructureChildren ? "Y" : "N"
      }`;

    if (year > 0) queryParameters += `&year=${year}`;

    if (week && week.week > 0) queryParameters += `&week=${week.week}`;

    if (!queryParameters) return;

    if (queryParameters.length > 0)
      queryParameters = queryParameters.substring(1);

    navigator.clipboard.writeText(
      window.location.href.split("?")[0] +
        (queryParameters.length > 0 ? "?" : "") +
        queryParameters
    );
  };

  const customerSelected = (customer: DashboardCustomerDto | undefined) => {
    customerChange(customer);
    setOpenCustomer(false);
  };

  const locationChange = (location: KemaroCompanyStructureDto | undefined) => {
    companyStructureChange(location);
    setOpenLocation(false);
  };

  const locationBlur = () => {
    if (companyStructure) return;
    locationChange(companyStructures[0]);
  };

  return (
    <div className="row kemaro-dashboard-economy-filter">
      <div className={props.hasNoData ? "col-12" : "col-6"}>
        <span className="pe-2 title">{t("dashboard.filter.title")}</span>
        {customer && (
          <TooltipButton
            aria-label="copy link"
            tooltip={t("viewHeaderButtons.shareId")}
            onClick={copyLinkToItem}
          >
            <LinkIcon color="primary" />
          </TooltipButton>
        )}
      </div>

      {props.hasNoData === false && (
        <div className="col-6 text-end filter-links-container">
          <KemaroDashboardEconomyFiltersLinks
            customer={customer}
            companyStructure={companyStructure}
            calculationId={calculationId}
            week={week}
            companyStructureChildren={companyStructureChildren}
          />
        </div>
      )}

      {isGettingCustomers && <Loading isLoading={true} />}

      {!isGettingCustomers && (
        <div className="col-12">
          <div className="row filter-row-container">
            <KemaroDashboardEconomyFiltersFilter
              cssContainerClass="col-12 col-xl-4 col-xxl-3"
              text={t("dashboard.filter.customer")}
            >
              <Autocomplete
                open={openCustomer}
                onOpen={() => setOpenCustomer(true)}
                onClose={() => setOpenCustomer(false)}
                disableCloseOnSelect={false}
                autoSelect
                className="customer-filter"
                size="small"
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=""
                    placeholder={t("common.typeToSearch")}
                    fullWidth
                    variant="standard"
                    hiddenLabel
                  />
                )}
                options={customers}
                getOptionLabel={customerGenerateOption}
                renderOption={(opt, item) => {
                  const x = item as DashboardCustomerDto;

                  let cssClass = "w-100 py-1 autocomplete-template";
                  if (customer?.id === x.id)
                    cssClass += " autocomplete-template-select";

                  return (
                    <div
                      key={x.id}
                      className={cssClass}
                      onClick={() => customerSelected(x)}
                    >
                      <span
                        className={`ps-2 ${x.hasData ? "" : "text-disabled"}`}
                      >
                        {x.name}
                      </span>
                    </div>
                  );
                }}
                value={customer?.name ?? ""}
                onChange={(event, newValue) =>
                  customerChange(customers.find((x) => x.name === newValue))
                }
                onInputChange={(event, newInputValue) => {
                  if (event?.type === "click") props.onClean();
                }}
              />
            </KemaroDashboardEconomyFiltersFilter>

            <KemaroDashboardEconomyFiltersFilter
              cssContainerClass="col-12 col-xl-8 col-xxl-5"
              text={t("dashboard.filter.location")}
            >
              <div className="row">
                <Autocomplete
                  open={openLocation}
                  onOpen={() => setOpenLocation(true)}
                  onClose={() => setOpenLocation(false)}
                  className="customer-filter"
                  size="small"
                  freeSolo
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      placeholder={t("common.typeToSearch")}
                      fullWidth
                      variant="standard"
                      hiddenLabel
                    />
                  )}
                  options={companyStructures}
                  getOptionLabel={companyStructureGenerateOption}
                  renderOption={(opt, item) => {
                    const x = item as KemaroCompanyStructureDto;

                    let cssClass = "w-100 py-1 autocomplete-template";
                    if (companyStructure?.id === x.id)
                      cssClass += " autocomplete-template-select";

                    return (
                      <div
                        key={x.id}
                        className={cssClass}
                        style={{
                          paddingLeft: `${x.nestingLevel * 15 + 5}px`,
                          paddingRight: "5px",
                        }}
                        onClick={() => locationChange(x)}
                      >
                        {companyStructureChildren &&
                        props.usedLocationIds.some((y) => y === x.id) ? (
                          <CheckRoundedIcon className="pe-2" color="primary" />
                        ) : (
                          <CheckRoundedIcon
                            className="pe-2"
                            style={{ color: "transparent" }}
                          />
                        )}

                        {x.name}
                      </div>
                    );
                  }}
                  value={companyStructure?.name ?? ""}
                  onInputChange={(event, newInputValue) => {
                    if (event?.type === "click") locationChange(undefined);
                  }}
                  onBlur={locationBlur}
                />

                <table className="table table-borderless mb-0">
                  <tbody>
                    <tr>
                      <td className="align-middle no-wrap py-0 ps-3">
                        {t("dashboard.filter.locationWithOutChild")}
                      </td>
                      <td className="py-0 pe-0 pb-0">
                        <Switch
                          color="primary"
                          checked={companyStructureChildren}
                          onChange={(event) =>
                            companyStructureChildrenChange(event.target.checked)
                          }
                        />
                      </td>
                      <td className="align-middle no-wrap py-0">
                        {t("dashboard.filter.locationWithChild")}
                      </td>
                      <td className="align-middle no-wrap py-0">
                        <TooltipContainer
                          text={t("dashboard.filter.locationChildToolTip")}
                        >
                          <InfoOutlinedIcon
                            className="pe-2"
                            sx={{ color: "swarm.general.iconButtonPrimary" }}
                          />
                        </TooltipContainer>
                      </td>
                      <td className="w-100">&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </KemaroDashboardEconomyFiltersFilter>

            <div className="col-12 col-xl-12 col-xxl-4">
              <div className="row">
                <KemaroDashboardEconomyFiltersFilter
                  cssContainerClass="col-8"
                  text={t("dashboard.filter.week")}
                >
                  <NativeSelect
                    className="w-100"
                    value={week?.week}
                    onChange={(event) => {
                      weekChange(
                        weeks?.find(
                          (x) => x.week === parseInt(event.target.value, 10)
                        )
                      );
                    }}
                  >
                    {weeks?.map((x) => (
                      <option
                        className={x.hasData ? "" : "text-disabled"}
                        key={x.week}
                        value={x.week}
                      >
                        {x.text}
                        {x.hasData ? "" : ` - ${t("dashboard.filter.noData")}`}
                      </option>
                    ))}
                  </NativeSelect>
                </KemaroDashboardEconomyFiltersFilter>

                <KemaroDashboardEconomyFiltersFilter
                  cssContainerClass="col-4"
                  text={t("dashboard.filter.year")}
                >
                  <NativeSelect
                    value={year}
                    onChange={(event) =>
                      yearChange(parseInt(event.target.value, 10))
                    }
                    className="w-100"
                  >
                    {years.map((x) => (
                      <option key={x} value={x}>
                        {x}
                      </option>
                    ))}
                  </NativeSelect>
                </KemaroDashboardEconomyFiltersFilter>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

interface kemaroDashboardEconomyFiltersProps {
  usedLocationIds: string[];
  staticWeeksToShow: number;
  filterChange: (filter: DashboardEconomyFilterDto | undefined) => void;
  onClean: () => void;
  hasNoData: boolean | undefined;
}

const companyStructureGenerateOption = (
  item: KemaroCompanyStructureDto | undefined | string
) => {
  if (typeof item === "string") {
    return item;
  }

  return item?.name ?? "";
};

const customerGenerateOption = (
  item: DashboardCustomerDto | undefined | string
) => {
  if (typeof item === "string") {
    return item;
  }

  return item?.name ?? "";
};
