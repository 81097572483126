import { MaxbrainLanguageDto } from "../../../robotcloud-shared/resource-models";
import { AxiosRequests } from "./axios.requests";
import { BaseRequest } from "./BaseRequest";

export class ApplicationConfigurationRequest extends BaseRequest {
  static controller = "/api/webapp/v1.0/organizations/ApplicationConfiguration";

  static getKemaroAcademyUsersEnable = async () => {
    const { data } = await AxiosRequests.get(
      `${ApplicationConfigurationRequest.controller}/kemaro-academy-users-enable`
    );
    return data as boolean;
  };

  static getKemaroAcademyGroups = async () => {
    const { data } = await AxiosRequests.get(
      `${ApplicationConfigurationRequest.controller}/kemaro-academy-groups`
    );
    return data as string[];
  };

  static getKemaroAcademyLanguages = async () => {
    const { data } = await AxiosRequests.get(
      `${ApplicationConfigurationRequest.controller}/kemaro-academy-languages`
    );
    return data as MaxbrainLanguageDto[];
  };
}
