import { useState } from "react";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  IconButton,
  TextField,
} from "@mui/material";
import { TooltipContainer } from "../news";
import { useTranslation } from "react-i18next";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import "./baseSearch.scss";

export function BaseSearch<T>(props: BaseSearchProps<T>) {
  const { t } = useTranslation();

  const [isfocused, setIsFocused] = useState(false);
  const [isHover, setIsHover] = useState(false);

  return (
    <div className="search-container">
      <Autocomplete
        size="small"
        disableCloseOnSelect={props.disableCloseOnSelect}
        freeSolo
        disableClearable={true}
        getOptionLabel={props.getOptionLabel}
        renderOption={props.renderOption}
        clearText={""}
        value={props.value ?? ""}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onKeyDown={(event) => {
          if (props.onKeyDown) props.onKeyDown(event.key);
        }}
        inputValue={props.searchText}
        onInputChange={(event, newInputValue) => {
          if (props.setSearchText) props.setSearchText(newInputValue);
        }}
        onChange={(event, newValue) => {
          if (props.onChange) props.onChange(newValue as T | null);
        }}
        renderInput={(params) => (
          <BaseSearchTemplate
            params={params}
            componentProps={props}
            isfocused={isfocused}
            isHover={isHover}
            explanation1={t("common.typeToSearchExplanationLin1")}
            explanation2={t("common.typeToSearchExplanationLin2")}
            typeToSearch={t("common.typeToSearch")}
            typeToSearchClear={t("common.typeToSearchClear")}
          />
        )}
        options={props.options}
        disabled={props.disabled}
      />
    </div>
  );
}

interface BaseSearchProps<T> {
  disabled?: boolean;
  disableCloseOnSelect?: boolean;
  options: T[];
  value: string | T | undefined;
  searchText?: string;
  onShowInformation?: boolean;
  placeHolderText?: string;
  setSearchText?: (searchText: string) => void;
  getOptionLabel?: (option: T | string) => string;
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: T,
    state: AutocompleteRenderOptionState
  ) => React.ReactNode;
  onChange?: (newValue: T | null) => void;
  onKeyDown?: (key: string) => void;
}

BaseSearch.defaultProps = {
  onShowInformation: true,
  disabled: false,
};

const BaseSearchTemplate = <T extends unknown>({
  params,
  componentProps,
  isfocused,
  isHover,
  explanation1,
  explanation2,
  typeToSearch,
  typeToSearchClear,
}: BaseSearchTemplateProps<T>) => {
  return (
    <div className="render-input-container">
      <TextField
        {...params}
        label=""
        placeholder={componentProps.placeHolderText ?? typeToSearch}
        fullWidth
        variant="standard"
        margin="dense"
        hiddenLabel
      />

      {(isfocused || isHover) && !componentProps.disabled && (
        <>
          {componentProps.onShowInformation && (
            <TooltipContainer
              content={
                <>
                  <p>{explanation1}</p>
                  <p>{explanation2}</p>
                </>
              }
            >
              <InfoOutlinedIcon
                className="search-explantion-icon"
                sx={{ color: "swarm.baseSearch.searchExplantionIcon" }}
              />
            </TooltipContainer>
          )}

          <TooltipContainer text={typeToSearchClear}>
            <IconButton
              className="clear-button"
              sx={{ color: "swarm.baseSearch.clearButtonIcon" }}
              onClick={() => {
                if (componentProps.setSearchText)
                  componentProps.setSearchText("");
                if (componentProps.onChange) componentProps.onChange(null);
              }}
            >
              <HighlightOffRoundedIcon className="icon" />
            </IconButton>
          </TooltipContainer>
        </>
      )}
    </div>
  );
};

interface BaseSearchTemplateProps<T> {
  params: AutocompleteRenderInputParams;
  componentProps: BaseSearchProps<T>;
  isfocused: boolean;
  isHover: boolean;
  explanation1: string;
  explanation2: string;
  typeToSearch: string;
  typeToSearchClear: string;
}
