import { diff, jsonPatchPathConverter } from "just-diff";
import {
  ManufacturerCompleteDto,
  ManufacturerFilterCriteriaDto,
  ManufacturerPostDto,
  ManufacturerReducedDto,
  PaginatedListDtoOfManufacturerReducedDto,
} from "../../../robotcloud-shared/resource-models";
import { AxiosRequests } from "./axios.requests";
import { BaseRequest } from "./BaseRequest";
import { StorageManagerService } from "./storageManager.service";
import { processLinkIdKey } from "../views/mainPanel/MainPanel";

export class ManufacturerRequests extends BaseRequest {
  static controller = "api/webapp/v1.0/organizations/Manufacturers";

  static getAllowed = async () => {
    const storedConfigType = StorageManagerService.getMsalConfigType();
    let url =
      storedConfigType && storedConfigType === "AzureAdB2C"
        ? `${ManufacturerRequests.controller}/allowedB2C`
        : `${ManufacturerRequests.controller}/allowed`;

    const processLinkId = localStorage.getItem(processLinkIdKey);
    if (processLinkId) {
      url += `?processLinkId=${processLinkId}`;
    }

    const { data } = await AxiosRequests.getWithAzureToken(url);
    return data as ManufacturerReducedDto[];
  };

  static getList = async (filter: ManufacturerFilterCriteriaDto) => {
    const { data } = await AxiosRequests.get(
      `${
        ManufacturerRequests.controller
      }?${ManufacturerRequests.objectToQueryString(filter)}`
    );
    return data as PaginatedListDtoOfManufacturerReducedDto;
  };

  static getById = async (id: string) => {
    const { data } = await AxiosRequests.get(
      `${ManufacturerRequests.controller}/${id}`
    );
    return data as ManufacturerCompleteDto;
  };

  static create = async (postDto: ManufacturerPostDto) => {
    const { data } = await AxiosRequests.post(
      ManufacturerRequests.controller,
      postDto
    );
    return data as ManufacturerCompleteDto;
  };

  static modify = async (
    originalDto: ManufacturerCompleteDto,
    newDto: ManufacturerCompleteDto
  ) => {
    const allowProperties = ["/name"];

    const jsonPatch = diff(originalDto, newDto, jsonPatchPathConverter).filter(
      (x) => allowProperties.includes(x.path)
    );

    const { data } = await AxiosRequests.patch(
      `${ManufacturerRequests.controller}/${originalDto.id}`,
      jsonPatch
    );
    return data as ManufacturerCompleteDto;
  };

  static softDelete = async (id: string) => {
    const { data } = await AxiosRequests.delete(
      `${ManufacturerRequests.controller}/${id}/soft`
    );
    return data as ManufacturerCompleteDto;
  };

  static getFromDealer = async (dealerId: string) => {
    const { data } = await AxiosRequests.get(
      `${ManufacturerRequests.controller}/from-dealer/${dealerId}`
    );
    return data as ManufacturerReducedDto[];
  };

  static getFromCustomer = async (customerId: string) => {
    const { data } = await AxiosRequests.get(
      `${ManufacturerRequests.controller}/from-customer/${customerId}`
    );
    return data as ManufacturerReducedDto[];
  };
}
