import { useContext } from "react";
import KemaroRobotContext from "../KemaroRobotContext";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import useTheme from "@mui/material/styles/useTheme";

export const LocationBreadcrumbs = () => {
  const { currentItem } = useContext(KemaroRobotContext);
  const theme = useTheme();

  return (
    <p className="mb-0 company-structure">
      {currentItem!.companyStructure!.parents.map((x) => (
        <span
          key={`${x}_span`}
          className="parents"
          style={{
            color: theme.palette.swarm?.kemaroRobots?.companyStructureParents,
          }}
        >
          {x !== currentItem!.companyStructure?.parents[0] && (
            <ArrowForwardIosRoundedIcon color="primary" className="icon mx-1" />
          )}

          {x}
        </span>
      ))}
    </p>
  );
};
