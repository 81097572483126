import { useEffect, useState } from "react";
import {
  ApiLoginDto,
  CustomerContactReducedDto,
  WorkflowTranslatedDataDto,
} from "../../../../robotcloud-shared/resource-models";
import { getWorkflowTranslatedData, Workflow } from "./onBoardingService";
import "./companyContactWorkflow.scss";
import {
  ContactReducedDto,
  ContactsView,
} from "../shared/contacts/ContactsView";
import { StorageManagerService } from "../../data/storageManager.service";
import { GeneralFunctions } from "../generalFunctions";
import { CustomersContactsRequests } from "../../data/customersContacts.request";
import { Banner, ToastHelper } from "../../components/news";
import { ErrorHelper } from "../../uiHelpers/errors.helper";
import { DealersContactsRequests } from "../../data/dealersContacts.request";
import { t } from "i18next";
import { Tooltip } from "@mui/material";
import { WorkflowLanguageSelector } from "./WorkflowLanguageSelector";

export const CompanyContactWorkflow = ({
  workflow,
  onReady,
  initialLanguage,
  demo,
}: CompanyContactWorkflowProps) => {
  const [language, setLanguage] = useState<string>("--");
  const [contactModalContact, setContactModalContact] = useState<
    ContactReducedDto | undefined
  >(undefined);
  const [translatedData, setTranslatedData] = useState<
    WorkflowTranslatedDataDto | undefined
  >(getWorkflowTranslatedData(workflow.workflowTranslatedDatas, language));
  const [apiLoginDto] = useState<ApiLoginDto | undefined>(
    StorageManagerService.getMe()
  );

  useEffect(() => {
    if (apiLoginDto) {
      getFirstContact().then((result) => {
        setContactModalContact(result);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflow, apiLoginDto]);

  const getFirstContact = async (): Promise<ContactReducedDto | undefined> => {
    if (apiLoginDto) {
      if (apiLoginDto.idCustomer) {
        return await getFirstCustomerContact(apiLoginDto.idCustomer);
      } else if (apiLoginDto.idDealer) {
        return await getFirstDealerContact(apiLoginDto.idDealer);
      }
    }

    return;
  };

  const getFirstDealerContact = async (
    dealerId: string
  ): Promise<ContactReducedDto | undefined> => {
    const dealerContactResult = await DealersContactsRequests.getSearchText(
      dealerId,
      {
        maxResults: 1,
        id: null,
        textToSearch: null,
        blackList: null,
      }
    );
    if (dealerContactResult.items.length > 0) {
      return dealerContactResult.items[0] as unknown as ContactReducedDto;
    }

    return;
  };

  const getFirstCustomerContact = async (
    dealerId: string
  ): Promise<ContactReducedDto | undefined> => {
    const customerContactResult = await CustomersContactsRequests.getSearchText(
      {
        maxResults: 1,
        id: null,
        textToSearch: null,
        blackList: null,
      },
      dealerId
    );
    if (customerContactResult.items.length > 0) {
      return customerContactResult.items[0] as unknown as ContactReducedDto;
    }

    return;
  };

  // We will user the CustomerId or DealerId of the current user.
  const handleContactSave = async (
    item: CustomerContactReducedDto
  ): Promise<boolean> => {
    if (demo) {
      const created = {
        ...item,
        idCustomer: "",
      };
      setContactModalContact(created as unknown as ContactReducedDto);
      return true;
    }

    let result = false;
    if (!apiLoginDto) return result;

    const companyId: string | null =
      apiLoginDto?.idCustomer ?? apiLoginDto?.idDealer;

    if (GeneralFunctions.isBlank(companyId)) return result;

    if (apiLoginDto.idCustomer) {
      result = await saveCustomerContact(apiLoginDto.idCustomer, item);
    }

    if (apiLoginDto.idDealer) {
      result = await saveDealerContact(apiLoginDto.idDealer, item);
    }

    return result;
  };

  const saveCustomerContact = async (
    customerId: string,
    item: ContactReducedDto
  ): Promise<boolean> => {
    try {
      if (contactModalContact) {
        const modified = await CustomersContactsRequests.modify(
          contactModalContact,
          { ...item, id: contactModalContact.id }
        );
        setContactModalContact(modified as unknown as ContactReducedDto);
        ToastHelper.success(
          `${t("contact.entityName")} ${t("common.modifiedSuccessfully")}`
        );
      } else {
        const created = await CustomersContactsRequests.create({
          ...item,
          idCustomer: customerId,
        });
        setContactModalContact(created as unknown as ContactReducedDto);
        ToastHelper.success(
          `${t("contact.entityName")} ${t("common.createdSuccessfully")}`
        );
      }
      return true;
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
      return false;
    }
  };

  const saveDealerContact = async (
    dealerId: string,
    item: ContactReducedDto
  ): Promise<boolean> => {
    try {
      if (contactModalContact) {
        const modified = await DealersContactsRequests.modify(
          contactModalContact,
          { ...item, id: contactModalContact.id }
        );
        setContactModalContact(modified as unknown as ContactReducedDto);
        ToastHelper.success(
          `${t("contact.entityName")} ${t("common.modifiedSuccessfully")}`
        );
      } else {
        const created = await DealersContactsRequests.create({
          ...item,
          idDealer: dealerId,
        });
        setContactModalContact(created as unknown as ContactReducedDto);
        ToastHelper.success(
          `${t("contact.entityName")} ${t("common.createdSuccessfully")}`
        );
      }
      return true;
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
      return false;
    }
  };

  useEffect(() => {
    onReady(!!contactModalContact);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactModalContact]);

  useEffect(() => {
    if (workflow) {
      const translation = getWorkflowTranslatedData(
        workflow.workflowTranslatedDatas,
        initialLanguage
      );
      setTranslatedData(translation);
      if (translation) {
        setLanguage(initialLanguage);
      } else {
        setLanguage("--");
      }
    }
  }, [workflow, initialLanguage]);

  useEffect(() => {
    if (workflow) {
      const translation = getWorkflowTranslatedData(
        workflow.workflowTranslatedDatas,
        language
      );
      setTranslatedData(translation);
    }
  }, [workflow, language]);

  return (
    <div className="col-12 workflow-component-container 1">
      <div className="d-flex col-12">
        <Tooltip
          title={translatedData?.title ?? workflow.displayTitle}
          placement="top"
          arrow
        >
          <h3 className="workflow-component-title col-12">
            {translatedData?.title ?? workflow.displayTitle}
          </h3>
        </Tooltip>
      </div>

      {workflow && workflow.workflowTranslatedDatas.length > 0 && (
        <>
          <div className="col-8"></div>
          <div className="col-4">
            <WorkflowLanguageSelector
              workflow={workflow}
              current={language}
              selectionChange={(select: string | undefined) => {
                if (select) {
                  setLanguage(select);
                }
              }}
            />
          </div>
        </>
      )}

      <div className="col-12 mt-2">
        <Banner type="info">
          <span className="workflow-component-message">
            {translatedData?.instructions ?? workflow.displayInstructions}
          </span>
        </Banner>
      </div>

      <div className="contactsView">
        <ContactsView
          title=""
          onSave={handleContactSave}
          contact={contactModalContact}
        />
      </div>
    </div>
  );
};

interface CompanyContactWorkflowProps {
  workflow: Workflow;
  onReady: (ready: boolean) => void;
  initialLanguage: string;
  demo?: boolean;
}
