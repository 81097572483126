import { KemaroRobotCleanedSurfaceChartDto } from "../../../../../robotcloud-shared/resource-models";
import SquareRoundedIcon from "@mui/icons-material/SquareRounded";

export const KemaroRobotViewCleanedSurfaceChartFooter = (
  props: KemaroRobotViewCleanedSurfaceChartFooterProps
) => {
  return (
    <div className="container ps-2 ms-0">
      <div className="row pe-4">
        {props.cleanedSurfaceChartDto?.colorRules.map((x) => (
          <div
            className="col-2 col-xxl ms-0 text-start"
            style={{ minWidth: "5rem" }}
            key={x.fromValue}
          >
            {/* <div className="col-3 col-xxl-2 ms-2 text-start" key={x.fromValue}> */}
            <span className="label">
              <SquareRoundedIcon
                className="py-0 pe-0 ms-1"
                style={{
                  width: "0.8rem",
                  color: x.color,
                  marginRight: "1px",
                }}
              />
              {x.title}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

interface KemaroRobotViewCleanedSurfaceChartFooterProps {
  cleanedSurfaceChartDto: KemaroRobotCleanedSurfaceChartDto | undefined;
}
