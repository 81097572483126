import {
  KemaroCleaningErrorDto,
  KemaroMissionRunPostDto,
} from "../../../../../../robotcloud-shared/resource-models";
import { GeneralFunctions } from "../../../generalFunctions";

export const defaultError: KemaroCleaningErrorDto = {
  timeStamp: new Date(),
  errorCode: "",
  mapPosition2D: { x: 0, y: 0 },
};

export const defaultRunV2: KemaroMissionRunPostDto = {
  roomId: null,
  zoneId: null,
  zoneName: null,
  cleaningMode: "DemoMode",
  startReason: "",
  stopReason: "",
  timestampCleaningStart: new Date(
    GeneralFunctions.dateToDateTimeUi(new Date())
  ), // Manual kemaro missions have minute precission.
  timestampCleaningEnd: new Date(GeneralFunctions.dateToDateTimeUi(new Date())),
  cleanedAreaM2: null,
  freeAreaM2: null,
  batteryPercentageStart: 0,
  batteryPercentageEnd: 0,
  cleaningErrors: [],
  roomName: null,
};
