import Box from "@mui/material/Box";
import { PuffLoader } from "react-spinners";
import "../styles/general.scss";
import useTheme from "@mui/material/styles/useTheme";

interface ILoadingProps {
  isLoading: boolean;
  size?: number;
  className?: string;
}

export const Loading: React.FC<ILoadingProps> = (props) => {
  const theme = useTheme();
  return (
    <Box className={`puffLoader-container ${props.className}`}>
      <PuffLoader
        loading={props.isLoading}
        color={theme.palette.swarm?.loading?.puffLoader}
        size={props.size ?? 100}
      />
    </Box>
  );
};
