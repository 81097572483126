import "./unassignedPanel.scss";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material";

import swarm_s_logo from "../../../assets/swarm_s_logo.png";
import earlybyteBrand from "../../../assets/brands/earlybyte.png";
import kemaroBrand from "../../../assets/brands/kemaro.png";
import { useEffect, useState } from "react";
import { Loading } from "../../../components/Loading";
import { useInterval } from "../../../hooks";
import { useTranslation } from "react-i18next";
import { StorageManagerService } from "../../../data/storageManager.service";
import { jwtDecode } from "jwt-decode";

export const UnassignedPanel = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [currentUser, setCurrentUser] = useState("");
  const [isTrying, setIsTrying] = useState(true);

  useInterval(() => setIsTrying(false), 5 * 1000);

  useEffect(() => {
    if (!isTrying) return;

    const apiToken = StorageManagerService.getApiTokenRaw();
    if (!apiToken) return;

    const apiLoginDto = jwtDecode(apiToken.token) as any;
    if (!apiLoginDto?.AzureTokenInfoDto) return;

    const azureTokenInfoDto = JSON.parse(apiLoginDto.AzureTokenInfoDto);
    if (!azureTokenInfoDto) return;

    setCurrentUser(azureTokenInfoDto.email);
  }, [isTrying]);

  return (
    <div className="main-div unassigned-panel">
      <table className="main-table">
        <tbody>
          <tr>
            <td className="td-first"></td>
          </tr>
          <tr>
            <td className="td-logo">
              <img src={swarm_s_logo} className="earlybyte" alt="Earlybyte" />
            </td>
          </tr>
          {isTrying ? (
            <tr>
              <td className="td-welcome">
                <p className="welcome-text mb-5">
                  {t("loginScreen.unassigned.title")}
                </p>
                <Loading isLoading={true} />
              </td>
            </tr>
          ) : (
            <tr>
              <td className="td-welcome">
                <p className="welcome-text">
                  {t("loginScreen.unassigned.unassigned1", {
                    user: currentUser,
                  })}
                </p>
                <p className="welcome-text">
                  {t("loginScreen.unassigned.unassigned2", {
                    user: currentUser,
                  })}
                </p>
                <p className="explanation-text mt-4">
                  {t("loginScreen.unassigned.explanation1")}
                </p>
                <p className="explanation-text mb-5">
                  {t("loginScreen.unassigned.explanation2")}
                </p>
                <p className="login-paragaph pt-5">
                  <Link
                    to="/logout"
                    className="login-button"
                    style={{
                      color: theme.palette.swarm?.auth?.loginButton,
                      backgroundColor:
                        theme.palette.swarm?.auth?.loginButtonBackground,
                    }}
                  >
                    {t("loginScreen.unassigned.button")}
                  </Link>
                </p>
              </td>
            </tr>
          )}
          <tr>
            <td className="td-separator-1"></td>
          </tr>
          <tr>
            <td className="td-brands">
              <table className="table-brands">
                <tbody>
                  <tr>
                    <td className="td-brand-left">
                      <img
                        src={earlybyteBrand}
                        className="logo-brand"
                        alt="Earlybyte"
                      />
                    </td>
                    <td className="td-brand-separtor"></td>
                    <td>
                      <img
                        src={kemaroBrand}
                        className="logo-brand"
                        alt="KEMARO"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td className="td-separator-2"></td>
          </tr>
          <tr>
            <td className="td-robot-cloud">
              <p>SWARM</p>
              <p>Made with ❤️ by Earlybyte</p>
            </td>
          </tr>

          <tr>
            <td className="td-last"></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
