export interface KemaroRobotDeploymentModalIds {
  idDealer: string | null;
  idCustomer: string | null;
  idLocation: string | null;
}

export const kemaroRobotDeploymentModalIdsdefault: KemaroRobotDeploymentModalIds =
  {
    idDealer: null,
    idCustomer: null,
    idLocation: null,
  };
