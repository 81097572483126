import { Table, TableRow, TableCell, TableBody } from "@mui/material";
import { OrderDto } from "../../../data/common";
import { ReactElement, useEffect, useState } from "react";
import { tableGenericHandleKeyboardSubjectManager } from "./TableGenericSubjectManager";
import { SelectionChangedTrigger, TableGenericColumn } from "./models";
import { TableGenericHead } from "./TableGenericHead";

export function TableGeneric<T>(props: tableGenericProps<T>) {
  const [stopHandleKeyboard, setStopHandleKeyboard] = useState(false);
  const [currentId, setCurrentId] = useState<
    string | number | undefined | null
  >(undefined);

  const executeScroll = (item: T) => {
    const cellId = cellKey(item, undefined, 0);
    const element = document.getElementById(cellId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const getItemId = (item: any) => {
    const value = item[props.idProperty as keyof T];
    return value as string;
  };

  useEffect(() => {
    const tableGenericHandleKeyboardSubscription =
      tableGenericHandleKeyboardSubjectManager
        .getObservable()
        .subscribe((x) => setStopHandleKeyboard(x.handleKeyboard));

    return () => {
      tableGenericHandleKeyboardSubscription.unsubscribe();
    };
  });

  useEffect(() => {
    const getCurrentIdx = () => {
      if (!props.items || props.items.length === 0) return null;

      if (!currentId) {
        return null;
      }

      const filterItem = props.items.filter(
        (x) => getItemId(x) === currentId
      )[0];

      if (!filterItem) return null;

      return props.items.indexOf(filterItem);
    };

    const selectPrevious = () => {
      const idx = getCurrentIdx();

      if (idx === null) selectItem(props.items[0]);
      else if (idx - 1 >= 0) selectItem(props.items[idx - 1]);
    };

    const selectNext = () => {
      const idx = getCurrentIdx();

      if (idx === null) selectItem(props.items[0]);
      else if (idx + 1 < props.items.length) selectItem(props.items[idx + 1]);
    };

    const handleKeydown = (ev: KeyboardEvent) => {
      if (
        !props.captureKeyboard ||
        stopHandleKeyboard ||
        props.items.length === 0 ||
        props.isAwaiting
      )
        return;

      if (ev.key === "ArrowUp") {
        ev.preventDefault();
        selectPrevious();
      } else if (ev.key === "ArrowDown") {
        ev.preventDefault();
        selectNext();
      }
    };

    const handleKeyup = (ev: KeyboardEvent) => {
      if (
        !props.captureKeyboard ||
        stopHandleKeyboard ||
        props.items.length === 0
      )
        return;

      if (ev.key === "ArrowUp" || ev.key === "ArrowDown") {
        ev.preventDefault();
        if (props.selectionChanged && currentId) {
          const currentItem = props.items.filter(
            (x) => getItemId(x) === currentId
          )[0];

          if (currentItem) props.selectionChanged(currentItem!, "KeyUp");
        }
      }
    };

    const selectItem = (item: T) => {
      setCurrentId(getItemId(item));
      executeScroll(item);
    };

    window.addEventListener("keydown", handleKeydown);
    window.addEventListener("keyup", handleKeyup);

    return () => {
      window.removeEventListener("keydown", handleKeydown);
      window.removeEventListener("keyup", handleKeyup);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, currentId, stopHandleKeyboard]);

  useEffect(() => {
    if (!props.items || !props.selectId) return;

    setCurrentId(props.selectId);

    const item = props.items.filter(
      (x) =>
        (x[props.idProperty as keyof T] as any).toString() === props.selectId!
    )[0];

    if (item) {
      handleSelectionChanged(item, "MouseClick");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.idProperty, props.items, props.selectId]);

  const handleSelectionChanged = (
    item: T,
    trigger: SelectionChangedTrigger
  ) => {
    executeScroll(item);

    if (props.selectId) {
      setCurrentId(props.selectId);
    }

    if (props.selectionChanged) {
      props.selectionChanged(item, trigger);
    }
  };

  const handleHeaderClick = (item: TableGenericColumn) => {
    if (!props.headerClick || !props.itemsOrder) {
      return;
    }

    if (props.itemsOrder.orderColumns === item.property) {
      let newOrder: OrderDto = {
        ...props.itemsOrder,
        order: props.itemsOrder.order === "Asc" ? "Desc" : "Asc",
      };

      props.headerClick(item, newOrder);
    } else {
      let newOrder: OrderDto = {
        ...props.itemsOrder,
        orderColumns: item.property!,
        order: props.itemsOrder.order === "Asc" ? "Desc" : "Asc",
      };

      props.headerClick(item, newOrder);
    }
  };

  let rowCounter = 0;
  const rowKey = (property: string, row: any) => {
    rowCounter++;
    return currentRowKey(property, row);
  };

  const currentRowKey = (property: string, row: any) => {
    return (property ? row[property] : undefined) ?? `row_${rowCounter}`;
  };

  const cellKey = (
    row: any,
    column: TableGenericColumn | undefined,
    idx: number | undefined = undefined
  ) => {
    const rowKey = currentRowKey(props.idProperty, row);
    const colNumber =
      idx !== undefined ? `${idx}` : `${props.columns.indexOf(column!)}`;
    return `${rowKey}_${colNumber}`;
  };

  const generateCells = (
    columns: TableGenericColumn[],
    row: any,
    isCurrentRow: boolean
  ) => {
    return (
      <>
        {columns.map((column: any, idx: number) => {
          const cellId = cellKey(row, column);

          const result = (
            <TableCell
              id={cellId}
              //ref={isCurrentRow && idx === 0 ? currentRowRef : undefined}
              key={cellId}
              className={`${column.itemClassName ?? ""} ${
                isCurrentRow ? props.currentRowCssClass : ""
              } table-cell`}
              sx={
                isCurrentRow
                  ? {
                      bgcolor: "swarm.general.currentRowBacground",
                    }
                  : {}
              }
            >
              {column.cellTemplate && column.cellTemplate(row)}

              {!column.cellTemplate && column.property && row[column.property]}

              {/* {column.cellTemplate
                ? column.cellTemplate(row)
                : column.property
                ? row[column.property]
                : ""} */}
            </TableCell>
          );

          return result;
        })}
      </>
    );
  };

  return (
    <Table stickyHeader className={props.tableClassName}>
      {props.showHeader && (
        <TableGenericHead
          columns={props.columns}
          onHeaderClick={handleHeaderClick}
          itemsOrder={props.itemsOrder}
        />
      )}

      <TableBody>
        {props.children
          ? props.children
          : props.items.map((row: any) => (
              <TableRow
                hover
                key={rowKey(props.idProperty, row)}
                onClick={(x) => handleSelectionChanged(row, "MouseClick")}
              >
                {generateCells(
                  props.columns,
                  row,
                  currentId && currentId === row[props.idProperty]
                    ? true
                    : false
                )}
              </TableRow>
            ))}
      </TableBody>
    </Table>
  );
}

interface tableGenericProps<T> {
  tableClassName?: string;
  showHeader?: boolean;
  columns: TableGenericColumn[];
  items: T[];
  idProperty?: any;
  itemsOrder?: OrderDto;
  headerClick?: (header: TableGenericColumn, itemsOrder: OrderDto) => void;
  selectionChanged?: (item: T, triggeredBy: SelectionChangedTrigger) => void;
  children?: ReactElement | never[] | ReactElement[];
  currentRowCssClass?: string;
  captureKeyboard?: boolean;
  selectId?: string | number | undefined | null;
  isAwaiting?: boolean | undefined | null;
}

TableGeneric.defaultProps = {
  showHeader: true,
  currentRowCssClass: " current-row ",
  captureKeyboard: false,
};
