import { Fragment } from "react";
import DoneIcon from "@mui/icons-material/Done";
import useTheme from "@mui/material/styles/useTheme";
import {
  PermissionMatrixSectionActionDto,
  PermissionMatrixSectionDto,
  PermissionMatrixSectionRuleDto,
} from "../../../../robotcloud-shared/resource-models";

export const PermissionsMatrixRulesCell = (
  idx: number,
  section: PermissionMatrixSectionDto,
  action: PermissionMatrixSectionActionDto,
  rule?: PermissionMatrixSectionRuleDto
) => {
  const theme = useTheme();
  return (
    <th
      key={`action_${section.title}_${action.action}_${idx}`}
      style={{
        borderLeft:
          theme.palette.swarm?.permissionsMatrix?.containerThBorderLeft,
      }}
    >
      {rule && (
        <Fragment key={`action_${section.title}_${action.action}_${idx}_data`}>
          <p>
            {rule.allow && (
              <DoneIcon
                className="permissions-matrix-icon-check"
                sx={{ color: "swarm.permissionsMatrix.iconCheck" }}
              />
            )}
          </p>
          <p>
            {rule.relations.map((relation) => (
              <span
                key={`action_${section.title}_${action.action}_${idx}_data_${relation}`}
              >
                ({relation})
              </span>
            ))}
          </p>
        </Fragment>
      )}
    </th>
  );
};
