import { useEffect, useState } from "react";
import {
  MaxbrainLogDto,
  MaxbrainLogFilterCriteriaDto,
} from "../../../../robotcloud-shared/resource-models";
import {
  ApplicationBar,
  CrudItemsPanel,
  Pagination,
  TableGeneric,
  ToastHelper,
} from "../../components/news";
import {
  OrderDto,
  PaginationDto,
  paginationDtoDefault,
} from "../../data/common";
import { MaxbrainLogRequest } from "../../data/maxbrainLog.request";
import { ErrorHelper } from "../../uiHelpers/errors.helper";
import { KemaroAdministrationTabsNav } from "../kemaro/administration/KemaroAdministrationTabsNav";
import { GeneralFunctions } from "../generalFunctions";
import { MaxbrainLogDetailModal } from "./MaxbrainLogDetailModal";
import { useTranslation } from "react-i18next";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import "./maxbrain-log.scss";
import {
  SelectionChangedTrigger,
  TableGenericColumn,
} from "../../components/news/TableGeneric/models";
import useTheme from "@mui/material/styles/useTheme";

export const MaxbrainLog = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [isFetchingList, setIsFetchingList] = useState(false);
  const [items, setItems] = useState<MaxbrainLogDto[] | undefined>(undefined);
  const [currentItem, setCurrentItem] = useState<MaxbrainLogDto | undefined>(
    undefined
  );
  const [pagination, setPagination] = useState<PaginationDto>({
    ...paginationDtoDefault,
  });
  const [dataOrder, setDataOrder] = useState<OrderDto>({
    orderColumns: "id",
    order: "Desc",
  });
  const [lastSearch, setLastSearch] = useState("--");
  const [isOpenDetailsModal, setIsOpenDetailsModal] = useState(false);

  const columnsDefinition: TableGenericColumn[] = [
    {
      title: "",
      property: "isSuccess",
      headerClassName: "success-td",
      itemClassName: "success-td",
      cellTemplate: (item: MaxbrainLogDto) => (
        <>
          {item.isSuccess ? (
            <CheckCircleOutlinedIcon
              className="success"
              style={{
                color: theme.palette.swarm?.maxbrain?.bodySuccess,
              }}
            />
          ) : (
            <CancelOutlinedIcon
              className="error"
              style={{
                color: theme.palette.swarm?.maxbrain?.bodyError,
              }}
            />
          )}
        </>
      ),
    },
    {
      title: t("kemaroAcademy.when"),
      property: "when",
      headerClassName: "when-td",
      itemClassName: "when-td",
      cellTemplate: (item: MaxbrainLogDto) => (
        <>{GeneralFunctions.dateToTimeDateString(item.when)}</>
      ),
    },

    {
      title: t("kemaroAcademy.actionUser"),
      property: "actionUserName",
      canSort: false,
    },
    {
      title: t("kemaroAcademy.action"),
      property: "action",
      headerClassName: "action-td",
      itemClassName: "action-td",
    },
    {
      title: t("kemaroAcademy.targetUser"),
      property: "targetUserName",
      canSort: false,
    },
    {
      title: t("kemaroAcademy.notes"),
      property: "notes",
    },
    {
      title: t("kemaroAcademy.status"),
      headerClassName: "status-td",
      itemClassName: "status-td",
      cellTemplate: (item: MaxbrainLogDto) => (
        <>
          {item.statusCode > 0 && (
            <span>
              {item.statusCode} - {item.statusCodeText}
            </span>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    const handleKeydown = (ev: KeyboardEvent) => {
      if (!currentItem) return;

      if (ev.key === " " || ev.code === "Space") {
        ev.preventDefault();
        setIsOpenDetailsModal((value) => !value);
      }
    };

    window.addEventListener("keydown", handleKeydown);

    return () => {
      window.removeEventListener("keydown", handleKeydown);
    };
  }, [currentItem]);

  useEffect(() => {
    const getList = async (filterCriteria: MaxbrainLogFilterCriteriaDto) => {
      setIsFetchingList(true);

      try {
        const data = await MaxbrainLogRequest.getList(filterCriteria);
        setItems(data.items);

        if (data.items.length > 0) {
          if (
            !currentItem ||
            !data.items.some((x) => x.id === currentItem.id)
          ) {
            setCurrentItem(data.items[0]);
          }
        }

        if (
          pagination.currentPage !== data.currentPage ||
          pagination.totalItems !== data.totalItems
        ) {
          const newPagination = {
            ...pagination,
            currentPage: data.currentPage,
            totalItems: data.totalItems,
          };

          setPagination(newPagination);
        }
      } catch (error) {
        ToastHelper.errors(ErrorHelper.process(error));
      }

      setIsFetchingList(false);
    };

    const filterCriteria: MaxbrainLogFilterCriteriaDto = {
      page: pagination.currentPage,
      maxItemCount: pagination.maxItemCount,
      orderBy: dataOrder.order,
      orderColumns: dataOrder.orderColumns,
      from: null,
      to: null,
      idsUsers: null,
    };

    const strFilterCriteria = JSON.stringify(filterCriteria);
    if (lastSearch === strFilterCriteria) {
      return;
    }

    setLastSearch(strFilterCriteria);

    getList(filterCriteria);
  }, [pagination, dataOrder, lastSearch, currentItem]);

  const itemClick = (
    item: MaxbrainLogDto,
    triggeredBy: SelectionChangedTrigger
  ) => {
    setCurrentItem(item);

    if (triggeredBy === "MouseClick") setIsOpenDetailsModal(true);
  };

  const pageChange = (pagination: PaginationDto) => {
    setCurrentItem(undefined);
    setPagination(pagination);
  };

  return (
    <div className="page-content">
      <div className="page-header">
        <ApplicationBar
          title={t("applicationBar.administration.title")}
          canSearch={false}
        />
      </div>

      <KemaroAdministrationTabsNav currentTab="kemaro-academy-log" />

      <div className="page-body d-flex maxbrain-log-body">
        <div className="maxbrain-log-left-side">
          <CrudItemsPanel
            title={t("kemaroAcademy.listTitle")}
            containerClassName="maxbrain-log-list-items"
            isFetching={isFetchingList}
            items={items}
            canCreate={false}
            onCreateClick={() => {}}
          >
            <TableGeneric
              tableClassName="mt-4"
              columns={columnsDefinition}
              items={items ?? []}
              idProperty="id"
              headerClick={(header, itemsOrder) => setDataOrder(itemsOrder)}
              selectionChanged={itemClick}
              itemsOrder={dataOrder}
              selectId={currentItem?.id}
              captureKeyboard
            />
          </CrudItemsPanel>

          <Pagination
            containerClassName="maxbrain-log-list-pager"
            pagination={pagination}
            isFetching={isFetchingList}
            onChange={pageChange}
          />
        </div>

        {currentItem && isOpenDetailsModal && (
          <MaxbrainLogDetailModal
            isOpenModal={isOpenDetailsModal}
            onCancel={() => setIsOpenDetailsModal(false)}
            item={currentItem}
          />
        )}
      </div>
    </div>
  );
};
