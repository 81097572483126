import PlayCircleOutline from "@mui/icons-material/PlayCircleOutline";
import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import { WorkflowReducedDto } from "../../../../../robotcloud-shared/resource-models";
import {
  ViewHeader,
  TooltipButton,
  YesNoOkDialog,
  ToastHelper,
  Banner,
} from "../../../components/news";
import { WorkflowsRequests } from "../../../data/workflows.request";
import { ErrorHelper } from "../../../uiHelpers/errors.helper";
import { PermissionsHelper } from "../../../uiHelpers/permissionsHelper";
import { OnBoardingAssistant } from "../OnBoardingAssistant";
import OnBoardingContext from "../OnBoardingContext";
import { getProcessFromReducedDto, Process } from "../onBoardingService";
import { WorkflowDialog } from "./WorkflowDialog";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";

export const WorkflowViewTitle = (): JSX.Element => {
  const [canModify, setCanModify] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const {
    currentSelectedItem,
    setCurrentSelectedItem,
    refreshItems,
    process,
    manufacturerName,
    currentSelectedItemTranslations,
  } = useContext(OnBoardingContext);
  const [openWorkflowDialog, setOpenWorkflowDialog] = useState(false);
  const [openDemo, setOpenDemo] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  useEffect(() => {
    setCanModify(PermissionsHelper.allow("OnBoarding", "Edit"));
    setCanDelete(PermissionsHelper.allow("OnBoarding", "Delete"));
  }, [currentSelectedItem]);

  const getDemoProcess = (): Process[] => {
    if (process && currentSelectedItem) {
      return [
        getProcessFromReducedDto(
          process,
          [currentSelectedItem],
          manufacturerName,
          currentSelectedItemTranslations
        ),
      ];
    } else {
      return [];
    }
  };

  const downloadCsv = async () => {
    try {
      const csv = await WorkflowsRequests.csv(currentSelectedItem!.id);
      const decodedData = window.atob(csv.content);
      const uInt8Array = new Uint8Array(decodedData.length);

      for (let i = 0; i < decodedData.length; ++i) {
        uInt8Array[i] = decodedData.charCodeAt(i);
      }

      let blob = new Blob([uInt8Array], { type: "application/csv" });

      const linkElement = document.createElement("a");
      linkElement.download = csv.name;
      linkElement.href = URL.createObjectURL(blob);
      linkElement.addEventListener("click", (e) => {
        setTimeout(() => URL.revokeObjectURL(linkElement.href), 30 * 1000);
      });
      linkElement.click();
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }
  };

  return (
    <>
      {currentSelectedItem && (
        <ViewHeader
          title={currentSelectedItem!.name}
          canEdit={canModify}
          canDelete={canDelete}
          copyLinkId={currentSelectedItem!.id ?? undefined}
          className="w-100"
          onEdit={() => {
            setOpenWorkflowDialog(true);
          }}
          onDelete={() => setOpenDeleteDialog(true)}
        >
          <>
            <TooltipButton
              tooltip={t("onBoarding.demo") ?? ""}
              onClick={() => setOpenDemo(true)}
            >
              <PlayCircleOutline color="primary" />
            </TooltipButton>

            <TooltipButton
              aria-label={t("robot.csv.tooltip")}
              tooltip={t("robot.csv.tooltip")}
              onClick={downloadCsv}
            >
              <DownloadOutlinedIcon
                className="primary-text"
                sx={{ color: "swarm.general.primaryText" }}
              />
            </TooltipButton>
          </>
        </ViewHeader>
      )}
      {openWorkflowDialog && currentSelectedItem && (
        <WorkflowDialog
          openPopup={openWorkflowDialog}
          target={currentSelectedItem.target}
          originalWorkflow={currentSelectedItem}
          onCancel={() => {
            setOpenWorkflowDialog(false);
          }}
          onSaved={(workflow?: WorkflowReducedDto) => {
            workflow && setCurrentSelectedItem(workflow);
            setOpenWorkflowDialog(false);
            refreshItems(currentSelectedItem.target);
          }}
        />
      )}
      {openDemo && currentSelectedItem && process && (
        <OnBoardingAssistant
          openPopup={openDemo}
          processLink={undefined}
          isWorking={false}
          demo={true}
          demoLanguage={"--"}
          processes={getDemoProcess()}
          onClose={() => setOpenDemo(false)}
        />
      )}
      {openDeleteDialog && currentSelectedItem && (
        <YesNoOkDialog
          open={openDeleteDialog}
          size="md"
          type="yesNo"
          title={t("onBoarding.workflow.delete.title")}
          onYes={async () => {
            try {
              const result = await WorkflowsRequests.delete(
                currentSelectedItem.id
              );
              if (result.status === 204) {
                setCurrentSelectedItem(undefined);
                refreshItems(currentSelectedItem.target);
              }
            } catch (error) {
              ToastHelper.errors(ErrorHelper.process(error));
            }
          }}
          onNo={() => setOpenDeleteDialog(false)}
          isWorking={false}
        >
          <Banner type="error">
            <>{t("onBoarding.workflow.delete.warning")}</>
          </Banner>
        </YesNoOkDialog>
      )}
    </>
  );
};
