import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useContext, useEffect, useState } from "react";
import {
  UserAccessType,
  UserCompleteDto,
  UserPostDto,
  UserRoles,
} from "../../../../../robotcloud-shared/resource-models";
import { InputUserName } from "../../../components/InputUserName";
import { Loading } from "../../../components/Loading";
import { TextInput } from "../../../components/news/TextInput";
import { ToastHelper } from "../../../components/news/Toast.helper";
import { ErrorHelper } from "../../../uiHelpers/errors.helper";
import { UsersRequests } from "../../../data/users.requests";
import { GeneralFunctions } from "../../generalFunctions";
import AuthorizationContext from "../../auth/authorizationContext";
import { UserAccessTypeSelector } from "./UserAccessTypeSelector";
import { useTranslation } from "react-i18next";
import { UserMaxbrainConfig } from "../userMaxbrainConfig/UserMaxbrainConfig";
import { tableGenericHandleKeyboardSubjectManager } from "../../../components/news";
import "./userModals.scss";
import { SupRemark } from "../../../uiHelpers/SupRemark";
import { Switch } from "@mui/material";

export const UsersCreateModal = (props: UsersCreateModalProps) => {
  const { t } = useTranslation();

  const [dto, setDto] = useState<UserPostDto>({ ...defaultUserPostDto });
  const [canChooseCompany, setCanChooseCompany] = useState(false);
  const [availableRoles, setAvailableRoles] = useState<UserRoles[]>([]);
  const [isWorking, setIsWorking] = useState(false);
  const [accessTypes, setAccessTypes] = useState<UserAccessType[]>([]);
  const [eb8User, setEb8User] = useState<boolean>(true);
  const { me, isKemaroAcademySection } = useContext(AuthorizationContext);

  useEffect(() => {
    setDto({
      ...defaultUserPostDto,
      accessType: props.accessType ?? defaultUserPostDto.accessType,
      accessId: props.accessId ?? defaultUserPostDto.accessId,
    });

    setCanChooseCompany(!props.accessType);
    setEb8User(true);
  }, [props]);

  useEffect(() => {
    if (!me) {
      return;
    }

    if (me.userAccessType === "Earlybyte" || me.role === "Owner") {
      setAvailableRoles(["Owner", "Administrator", "Member"]);
    } else if (me.role === "Administrator") {
      setAvailableRoles(["Administrator", "Member"]);
    } else {
      setAvailableRoles(["Member"]);
    }

    if (me.userAccessType === "Earlybyte") {
      setAccessTypes([
        "Manufacturer",
        "Dealer",
        "Customer",
        "Unassigned",
        "Earlybyte",
      ]);
    } else if (me.userAccessType === "Manufacturer") {
      setAccessTypes(["Manufacturer", "Dealer", "Customer", "Unassigned"]);
    } else if (me.userAccessType === "Dealer") {
      setAccessTypes(["Dealer", "Customer", "Unassigned"]);
    } else if (me.userAccessType === "Customer") {
      setAccessTypes(["Customer", "Unassigned"]);
    }
  }, [me]);

  useEffect(() => {
    tableGenericHandleKeyboardSubjectManager.setData({
      caller: "UsersCreateModal",
      handleKeyboard: props.isOpenModal,
    });
  }, [props.isOpenModal]);

  const handleCreate = async () => {
    setIsWorking(true);

    try {
      const result = await UsersRequests.create(dto);
      props.onSaved(result);
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsWorking(false);
  };

  const handleCancel = () => {
    props.onCancel();
  };

  return (
    <Dialog
      className="application-modal"
      open={props.isOpenModal}
      maxWidth="md"
      onClose={handleCancel}
    >
      <div className="modal-content">
        <div className="application-modal-header d-flex ">
          <h3 className="modal-title px-0 col-9">{t("user.create.title")}</h3>
          {me && me.userAccessType === "Earlybyte" && (
            <div className="pt-2">
              <span className="me-2">{"eb8 user"}</span>
              <Switch
                color="primary"
                checked={eb8User}
                onChange={(event) => {
                  setEb8User(event.target.checked);
                }}
              />
            </div>
          )}
        </div>
        <div className="application-modal-body">
          <div className="row">
            {isWorking && (
              <div className="col-12 py-5">
                <Loading isLoading={isWorking} />
              </div>
            )}
            {!isWorking && (
              <>
                <div className="col-4 input-label">
                  {t("user.firstName")}
                  <SupRemark />
                </div>
                <div className="col-8">
                  <TextInput
                    value={dto.firstName}
                    ret={(x) => setDto({ ...dto, firstName: x })}
                  />
                </div>

                <div className="col-4 input-label">
                  {t("user.lastName")}
                  <SupRemark />
                </div>
                <div className="col-8">
                  <TextInput
                    value={dto.lastName}
                    ret={(x) => setDto({ ...dto, lastName: x })}
                  />
                </div>
                {eb8User ? (
                  <>
                    <div className="col-4 input-label">
                      {t("user.userName")}
                      <SupRemark />
                    </div>
                    <div className="col-8">
                      <InputUserName
                        ret={(x) => setDto({ ...dto, userName: x })}
                        usernamePostfix={"@eb8.ch"}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-4 input-label">
                      {t("user.primaryEmailAddress")}
                      <SupRemark />
                    </div>
                    <div className="col-8">
                      <InputUserName
                        ret={(x) => setDto({ ...dto, primaryEmailAddress: x })}
                        usernamePostfix={""}
                      />
                    </div>
                  </>
                )}

                {!eb8User && (
                  <>
                    <div className="col-4 input-label">
                      {t("user.externalId")}
                    </div>
                    <div className="col-8">
                      <TextInput
                        value={dto.externalId ?? undefined}
                        ret={(x) => setDto({ ...dto, externalId: x })}
                      />
                    </div>
                  </>
                )}

                {eb8User && (
                  <>
                    <div className="col-4 input-label">
                      {t("user.email")}
                      <SupRemark />
                    </div>
                    <div className="col-8">
                      <TextInput
                        value={dto.passwordResetEmailAddress}
                        ret={(x) =>
                          setDto({ ...dto, passwordResetEmailAddress: x })
                        }
                      />
                    </div>
                  </>
                )}

                <div className="col-4 mt-2 input-label">
                  {t("common.role")}
                  <SupRemark />
                </div>
                <div className="col-8 mt-2">
                  <Autocomplete
                    size="small"
                    freeSolo
                    onChange={(event, newValue) =>
                      setDto({
                        ...dto,
                        role: (newValue as UserRoles) ?? "Member",
                      })
                    }
                    value={dto.role}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label=""
                        placeholder={t("common.typeToSearch")}
                        fullWidth
                        variant="standard"
                        margin="dense"
                        hiddenLabel
                      />
                    )}
                    options={availableRoles}
                  />
                </div>

                {canChooseCompany && (
                  <UserAccessTypeSelector
                    accessTypes={accessTypes}
                    accessType={dto.accessType}
                    accessTypeChange={(x) => setDto({ ...dto, accessType: x })}
                    accessTypeId={dto.accessId}
                    accessTypeIdChange={(x) => setDto({ ...dto, accessId: x })}
                  />
                )}

                {isKemaroAcademySection && (
                  <UserMaxbrainConfig
                    rowHeaderCols={4}
                    accessType={dto.accessType}
                    userMaxbrainConfig={dto.maxbrainConfig}
                    onChange={(x) => setDto({ ...dto, maxbrainConfig: x })}
                    showTitle={true}
                  />
                )}
              </>
            )}
          </div>
        </div>

        {!isWorking && (
          <div className="application-modal-footer pt-4">
            <Button
              variant="contained"
              className="button-with-text"
              disabled={
                GeneralFunctions.isBlank(dto.firstName) ||
                GeneralFunctions.isBlank(dto.lastName) ||
                (eb8User &&
                  (GeneralFunctions.isBlank(dto.passwordResetEmailAddress) ||
                    GeneralFunctions.isBlank(dto.userName))) ||
                (!eb8User && GeneralFunctions.isBlank(dto.primaryEmailAddress))
              }
              color="primary"
              onClick={handleCreate}
            >
              {t("common.buttons.create")}
            </Button>

            <Button
              className="modal-cancel-button button-with-text"
              variant="outlined"
              color="primary"
              onClick={handleCancel}
            >
              {t("common.buttons.cancel")}
            </Button>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export interface UsersCreateModalProps {
  isOpenModal: boolean;
  accessType?: UserAccessType;
  accessId?: string;
  onCancel: () => void;
  onSaved: (created: UserCompleteDto) => void;
}

const defaultUserPostDto: UserPostDto = {
  firstName: "",
  lastName: "",
  userName: "",
  passwordResetEmailAddress: "",
  accessId: "",
  accessType: "Unassigned",
  role: "Member",
  maxbrainConfig: {
    group: "",
    language: "en",
  },
  externalId: null,
  primaryEmailAddress: null,
};
