import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";
import { ThemeProvider, createTheme, ThemeOptions } from "@mui/material/styles";
import React from "react";
import { color as ThemeColors } from "./index";
import { storage } from "./utility/storage";

export const SwarmThemeContext = React.createContext({
  toggleColorTheme: () => {},
});

type SwarmThemeProviderProps = {
  children?: React.ReactNode;
};

export default function SwarmThemeProvider(props: SwarmThemeProviderProps) {
  const storedThemeIndex = storage.get<0 | 1 | undefined>("ThemeIndex") ?? 1;
  const [theme, setTheme] = React.useState<0 | 1>(storedThemeIndex);

  const colorMode = React.useMemo(
    () => ({
      toggleColorTheme: () => {
        setTheme((prevTheme) => ((prevTheme + 1) % 2) as 0 | 1);
      },
    }),
    []
  );

  const _theme = React.useMemo(() => {
    let result = createTheme(ThemeColors[0] as ThemeOptions); //default theme

    if (theme !== 0) {
      // override some colors in default theme with colors from selected theme
      result = createTheme(result, ThemeColors[theme] as ThemeOptions);
    }
    return result;
  }, [theme]);

  storage.set("ThemeIndex", theme);

  return (
    <SwarmThemeContext.Provider value={colorMode}>
      <ThemeProvider theme={_theme}>
        <GlobalStyles styles={{}} />
        <CssBaseline enableColorScheme />
        {props.children}
      </ThemeProvider>
    </SwarmThemeContext.Provider>
  );
}
