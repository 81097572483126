import { InputAdornment, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TooltipButton } from "./TooltipButton";

export const PasswordInput = (props: passwordInputProps) => {
  const { t } = useTranslation();

  const handleClickShowPassword = () => {
    props.setIsShowPassword(!props.isShowPassword);
  };

  const hasValue = props.value !== undefined && props.value !== null;

  const lengthRule = hasValue && props.value.length >= props.minLenght;
  const numberRule =
    !props.mustContainNumber ||
    (hasValue && props.value.search(/(?=.*[0-9])/) >= 0);
  const lowerCaseRule =
    !props.mustContainLowerCase ||
    (hasValue && props.value.search(/[a-z]/) >= 0);
  const upperCaseRule =
    !props.mustContainUpperCase ||
    (hasValue && props.value.search(/[A-Z]/) >= 0);
  const specialCharsRule =
    !props.mustContainSpecialChars ||
    (hasValue && /[-"'`!#*&=$.%()+,/:?@^_{}~[\]\\]/.test(props.value));
  const forbiddenCharsRule =
    !hasValue && /[^"'`a-zA-Z0-9!#*&=$.%()+,/:?@^_{}~[\]\\]/.test(props.value);

  useEffect(() => {
    if (props.value === generateNewPassword) {
      handleGeneratePassword(props);
    }
  }, [props]);

  return (
    <>
      <TextField
        type={props.isShowPassword ? "text" : "password"}
        label=""
        fullWidth
        variant="standard"
        margin="dense"
        hiddenLabel
        value={props.value}
        onChange={(event) => {
          props.setValue(event.target.value);
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {props.autogenerateShow && (
                <TooltipButton
                  aria-label="random password"
                  tooltip={t("passwordInput.randomPassword")}
                  onClick={() => handleGeneratePassword(props)}
                  edge="end"
                >
                  <AutoFixHighIcon />
                </TooltipButton>
              )}

              <TooltipButton
                aria-label="password visibility"
                tooltip={t("passwordInput.passwordVisibility")}
                onClick={handleClickShowPassword}
                edge="end"
              >
                {props.isShowPassword ? <Visibility /> : <VisibilityOff />}
              </TooltipButton>
            </InputAdornment>
          ),
        }}
      />

      {props.validatePassword && (
        <div className="row">
          <div className="col-12 col-lg-6 mt-2">
            <RequirementHelpText
              requirement={lengthRule}
              helpText={t("passwordInput.atLeastLong", {
                length: props.minLenght,
              })}
            />
          </div>
          <div className="col-12 col-lg-6 mt-2">
            <RequirementHelpText
              requirement={numberRule}
              helpText={t("passwordInput.includeNumbers")}
            />
          </div>
          <div className="col-12 col-lg-6 mt-2">
            <RequirementHelpText
              requirement={lowerCaseRule}
              helpText={t("passwordInput.includeLowerCase")}
            />
          </div>
          <div className="col-12 col-lg-6 mt-2">
            <RequirementHelpText
              requirement={upperCaseRule}
              helpText={t("passwordInput.includeUpperCase")}
            />
          </div>
          <div className="col-12 col-lg-6 mt-2">
            <RequirementHelpText
              requirement={specialCharsRule}
              helpText={t("passwordInput.includeSpecialCharacters")}
            />
          </div>
          {forbiddenCharsRule && (
            <div className="col-12 col-lg-6 mt-2">
              <RequirementHelpText
                requirement={!forbiddenCharsRule}
                helpText={t("passwordInput.removeProhibited")}
              />
            </div>
          )}
          {props.children && (
            <div
              className={`col-12 col-lg-12 ${props.chilContainerClass ?? ""}`}
            >
              {props.children}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export interface passwordInputProps {
  isShowPassword: boolean;
  setIsShowPassword: (value: boolean) => void;
  value: string;
  setValue: (value: string) => void;
  minLenght: number;
  mustContainNumber?: boolean;
  mustContainUpperCase?: boolean;
  mustContainLowerCase?: boolean;
  mustContainSpecialChars?: boolean;
  children?: ReactElement | never[] | ReactElement[];
  chilContainerClass?: string;
  validatePassword?: boolean;
  autogenerateShow?: boolean;
  autogenerateClick?: (newPassword: string) => void;
}

PasswordInput.defaultProps = {
  mustContainNumber: true,
  mustContainUpperCase: true,
  mustContainLowerCase: true,
  mustContainSpecialChars: true,
  validatePassword: true,
  autogenerateShow: true,
};

const handleGeneratePassword = (props: passwordInputProps) => {
  let allowChars = "";

  if (props.mustContainNumber) {
    allowChars += "0123456789";
  }

  if (props.mustContainLowerCase) {
    allowChars += "abcdefghijklmnopqrstuvwxyz";
  }

  if (props.mustContainUpperCase) {
    allowChars += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  }

  if (props.mustContainSpecialChars) {
    allowChars += ".,:_-!?()+$@/*=&";
  }

  const password = generatePassword(
    props.minLenght,
    allowChars,
    props.mustContainNumber!,
    props.mustContainLowerCase!,
    props.mustContainUpperCase!,
    props.mustContainSpecialChars!
  );

  props.setValue(password);

  if (props.autogenerateClick) {
    props.autogenerateClick(password);
  }
};

const RequirementHelpText = (props: requirementHelpTextProps) => (
  <Box
    margin={0}
    padding={0}
    fontSize="0.8rem"
    color={props.requirement ? "green" : "red"}
  >
    {props.requirement ? (
      <span>&#10003;&nbsp;</span>
    ) : (
      <span>&nbsp;&nbsp;&nbsp;</span>
    )}
    {props.helpText}
  </Box>
);

interface requirementHelpTextProps {
  requirement: boolean;
  helpText: string;
}

function generatePassword(
  length: number,
  allowChars: string,
  mustContainNumber: boolean,
  mustContainLowerCase: boolean,
  mustContainUpperCase: boolean,
  mustContainSpecialChars: boolean
) {
  let isOk = false;
  let result = "";
  while (!isOk) {
    result = "";
    for (let i = 0; i < length; i++) {
      result += allowChars[Math.floor(Math.random() * allowChars.length)];
    }

    const numberRule = !mustContainNumber || result.search(/(?=.*[0-9])/) >= 0;
    const lowerCaseRule = !mustContainLowerCase || result.search(/[a-z]/) >= 0;
    const upperCaseRule = !mustContainUpperCase || result.search(/[A-Z]/) >= 0;
    const specialCharsRule =
      !mustContainSpecialChars || /[.,:_\-!?()+$@/*=&]/.test(result);

    isOk = numberRule && lowerCaseRule && upperCaseRule && specialCharsRule;
  }

  return result;
}

export const generateNewPassword = "-generate-";
