import { Dialog, Button } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BackupIcon from "@mui/icons-material/Backup";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  WorkflowTranslatedDataDto,
  WorkflowTranslatedDataPostDto,
  WorkflowType,
} from "../../../../../robotcloud-shared/resource-models";
import { Loading } from "../../../components/Loading";
import {
  ToastHelper,
  TextInput,
  TooltipButton,
  tableGenericHandleKeyboardSubjectManager,
} from "../../../components/news";
import { LanguageSearch } from "../../../components/search/LanguageSearch";
import { WorkflowTranslatedDatasRequests } from "../../../data/workflowTranslatedDatas.request";
import { ErrorHelper } from "../../../uiHelpers/errors.helper";
import "./workflowTranslatedDataDialog.scss";
import { GeneralFunctions } from "../../generalFunctions";
import { SupRemark } from "../../../uiHelpers/SupRemark";

export const WorkflowTranslatedDataDialog = ({
  workflowTranslatedData,
  type,
  idWorkflow,
  onCancel,
  onSaved,
  openPopup,
}: WorkflowTranslatedDataDialogProps) => {
  const { t } = useTranslation();

  const [isWorking, setIsWorking] = useState<boolean>(false);
  const [translatedData, setTranslatedData] =
    useState<WorkflowTranslatedDataDto>(
      workflowTranslatedData
        ? { ...workflowTranslatedData }
        : { ...defaultData, idWorkflow }
    );
  const [originalTranslatedData, setOriginalTranslatedData] =
    useState<WorkflowTranslatedDataDto>(
      workflowTranslatedData
        ? { ...workflowTranslatedData }
        : { ...defaultData, idWorkflow }
    );
  const [pdfContent, setPdfContent] = useState<string>();
  const [pdfFile, setPdfFile] = useState<File>();

  const hiddenFileInput = useRef(null);

  useEffect(() => {
    setTranslatedData(workflowTranslatedData ?? { ...defaultData, idWorkflow });
    setOriginalTranslatedData(
      workflowTranslatedData ?? { ...defaultData, idWorkflow }
    );
    setPdfContent(undefined);
    setPdfFile(undefined);
  }, [idWorkflow, workflowTranslatedData]);

  const [isLoadingPdf, setIsLoadingPdf] = useState<boolean>(false);
  useEffect(() => {
    if (openPopup && workflowTranslatedData && type === "file") {
      setIsLoadingPdf(true);
      WorkflowTranslatedDatasRequests.getPdfContent(
        workflowTranslatedData.id
      ).then((result) => {
        if (result.status === 200) {
          if (result.data.size > 0) {
            setPdfContent(URL.createObjectURL(result.data));
          } else {
            setPdfContent(undefined);
          }
          setIsLoadingPdf(false);
        }
      });
    }
  }, [openPopup, workflowTranslatedData, type]);

  const isEditing = () => {
    return workflowTranslatedData ? true : false;
  };

  useEffect(() => {
    if (!openPopup) {
      setTranslatedData({ ...originalTranslatedData });
      setPdfContent(undefined);
      setPdfFile(undefined);
    }
  }, [openPopup, originalTranslatedData]);

  const handleCancel = () => {
    onCancel();
  };

  const handleSave = async () => {
    setIsWorking(true);

    try {
      let data = null;
      if (isEditing()) {
        data = await WorkflowTranslatedDatasRequests.modify(
          originalTranslatedData,
          translatedData
        );
      } else {
        const workflowTranslatedDataPostDto: WorkflowTranslatedDataPostDto = {
          instructions: translatedData.instructions,
          agreement: translatedData.agreement,
          languageCode: translatedData.languageCode,
          linkUrl: translatedData.linkUrl,
          title: translatedData.title,
          idWorkflow: translatedData.idWorkflow,
        };
        data = await WorkflowTranslatedDatasRequests.create(
          workflowTranslatedDataPostDto
        );
      }

      if (pdfFile) {
        await WorkflowTranslatedDatasRequests.setPdf(data.id, pdfFile);
      }

      setOriginalTranslatedData(data);
      onSaved(data);
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsWorking(false);
  };

  const readFile = (event: any) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      if (e.target!.result !== null) {
        let blob = new Blob([e.target!.result], { type: "application/pdf" });
        setPdfFile(new File([e.target!.result], "file.pdf"));
        const content = URL.createObjectURL(blob);
        setPdfContent(content);
      }
    };
    reader.readAsArrayBuffer(event.target.files[0]);
  };

  useEffect(() => {
    tableGenericHandleKeyboardSubjectManager.setData({
      caller: "WorkflowTranslatedDataDialog",
      handleKeyboard: openPopup,
    });

    return () => {
      tableGenericHandleKeyboardSubjectManager.setData({
        caller: "WorkflowTranslatedDataDialog",
        handleKeyboard: false,
      });
    };
  }, [openPopup]);

  return (
    <Dialog
      className="application-modal workflow-translated-data-dialog"
      open={openPopup}
      fullWidth
      maxWidth="md"
      onClose={handleCancel}
    >
      <div className="modal-content">
        <div className="application-modal-header ">
          <h3>{t("onBoarding.workflow.workflowTranslatedDataPanelTitle")}</h3>
        </div>
        {isWorking ? (
          <div className="application-modal-body">
            <Loading isLoading={isWorking} />
          </div>
        ) : (
          <>
            <div className="application-modal-body">
              <div className="row">
                <div className="col-2 input-label">
                  {t("onBoarding.workflow.workflowTranslatedData.language")}
                  <SupRemark />
                </div>
                <div className="col-10">
                  <LanguageSearch
                    current={translatedData.languageCode}
                    selectionChange={(x) => {
                      if (x) {
                        setTranslatedData({
                          ...translatedData,
                          languageCode: x,
                        });
                      }
                    }}
                  />
                </div>

                <div className="col-2 input-label">
                  {t("onBoarding.workflow.workflowTranslatedData.title")}
                  <SupRemark />
                </div>
                <div className="col-10">
                  <TextInput
                    value={translatedData.title}
                    maxLength={140}
                    ret={(x) => {
                      setTranslatedData({ ...translatedData, title: x });
                    }}
                  />
                </div>

                <div className="col-2 input-label">
                  {t("onBoarding.workflow.workflowTranslatedData.instructions")}
                  <SupRemark />
                </div>
                <div className="col-10">
                  <TextInput
                    multiline
                    maxLength={650}
                    value={translatedData.instructions}
                    ret={(x) =>
                      setTranslatedData({ ...translatedData, instructions: x })
                    }
                  />
                </div>
                {(type === "link" || type === "file") && (
                  <>
                    <div className="col-2 input-label">
                      {t(
                        "onBoarding.workflow.workflowTranslatedData.agreement"
                      )}
                      <SupRemark />
                    </div>
                    <div className="col-10">
                      <TextInput
                        value={translatedData.agreement}
                        maxLength={106}
                        ret={(x) =>
                          setTranslatedData({ ...translatedData, agreement: x })
                        }
                      />
                    </div>
                  </>
                )}
                {type === "link" && (
                  <>
                    <div className="col-2 input-label">
                      {t("onBoarding.workflow.workflowTranslatedData.linkUrl")}
                      <SupRemark />
                    </div>
                    <div className="col-10">
                      <TextInput
                        value={translatedData.linkUrl ?? ""}
                        ret={(x) => {
                          let link = x;
                          if (!link.startsWith("https://")) {
                            link = "https://" + link;
                          }
                          setTranslatedData({
                            ...translatedData,
                            linkUrl: link,
                          });
                        }}
                      />
                    </div>
                  </>
                )}
                {type === "file" && (
                  <>
                    <div className="col-2 input-label">
                      {t("onBoarding.workflow.workflowTranslatedData.pdfFile")}
                      <SupRemark />
                    </div>
                    <div className="col-10">
                      <input
                        type="file"
                        name="myFile"
                        style={{ display: "none" }}
                        accept=".pdf"
                        ref={hiddenFileInput}
                        onChange={readFile}
                      />
                      <TooltipButton
                        tooltip={t("onBoarding.workflow.selectFile") ?? ""}
                        onClick={() => {
                          (hiddenFileInput.current! as any).click();
                        }}
                      >
                        <BackupIcon color="primary" />
                      </TooltipButton>
                      {pdfContent ? (
                        <a href={pdfContent} target="_blank" rel="noreferrer">
                          <VisibilityIcon color="primary" />
                        </a>
                      ) : (
                        <VisibilityIcon color="disabled" />
                      )}
                    </div>
                    <div className="col-12 ">
                      {isLoadingPdf ? (
                        <Loading
                          className="file-container"
                          isLoading={isLoadingPdf}
                        />
                      ) : pdfContent ? (
                        <object
                          aria-label="test"
                          className="border file-container"
                          data={`${pdfContent ?? "#"}#toolbar=0`}
                          type="application/pdf"
                        ></object>
                      ) : (
                        <div className="border file-container"></div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="application-modal-footer">
              <Button
                variant="contained"
                className="button-with-text"
                color="primary"
                disabled={
                  GeneralFunctions.isBlank(translatedData.languageCode) ||
                  GeneralFunctions.isBlank(translatedData.title) ||
                  GeneralFunctions.isBlank(translatedData.instructions) ||
                  (type === "file" && !pdfContent) ||
                  (type === "link" &&
                    GeneralFunctions.isBlank(translatedData.linkUrl)) ||
                  ((type === "file" || type === "link") &&
                    GeneralFunctions.isBlank(translatedData.agreement))
                }
                onClick={handleSave}
              >
                {t("common.buttons.save")}
              </Button>
              <Button
                className="modal-cancel-button button-with-text"
                variant="outlined"
                color="primary"
                onClick={handleCancel}
              >
                {t("common.buttons.cancel")}
              </Button>
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
};

interface WorkflowTranslatedDataDialogProps {
  idWorkflow: string;
  type: WorkflowType;
  workflowTranslatedData?: WorkflowTranslatedDataDto;
  openPopup: boolean;
  onSaved: (workflowTranslatedDataDto?: WorkflowTranslatedDataDto) => void;
  onCancel: () => void;
}

const defaultData: WorkflowTranslatedDataDto = {
  id: "",
  idWorkflow: "",
  languageCode: "en",
  title: "",
  instructions: "",
  agreement: "",
  pdfUrl: "",
  pdfContent: "",
  linkUrl: "",
};
