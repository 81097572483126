import { InputAdornment, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { TooltipButton } from "../../../../../components/news";
import { useTranslation } from "react-i18next";
import { KemaroRobotCredentialsConfigDto } from "../../../../../../../robotcloud-shared/resource-models";
import { PasswordValidated } from "./models";
import { validatePassword } from "./funtions";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";

export const CredentialPasswordInput = (
  props: CredentialPasswordInputProps
) => {
  const { t } = useTranslation();

  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isEditPassword, setIsEditPassword] = useState(false);
  const [validated, setValidated] = useState<PasswordValidated>({
    minLength: false,
    maxLength: false,
    minSpecialChars: false,
    minNumbers: false,
    minAlphabet: false,
  });

  useEffect(() => {
    setValidated(validatePassword(props.value, props.config));
  }, [props]);

  const copyToclipboard = () => {
    navigator.clipboard.writeText(props.value);
  };

  return (
    <>
      <TextField
        type={isShowPassword ? "text" : "password"}
        label=""
        fullWidth
        variant="standard"
        margin="dense"
        hiddenLabel
        value={props.value}
        onChange={(event) => {
          props.setValue(event.target.value.trim());
        }}
        disabled={!isEditPassword}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <TooltipButton
                aria-label={t("passwordInput.passwordVisibility")}
                tooltip={t("passwordInput.passwordVisibility")}
                onClick={() => setIsShowPassword(!isShowPassword)}
                edge="end"
              >
                {isShowPassword ? <Visibility /> : <VisibilityOff />}
              </TooltipButton>

              <TooltipButton
                className="ms-2"
                aria-label={t("common.buttons.copyToclipboard")}
                tooltip={t("common.buttons.copyToclipboard")}
                onClick={copyToclipboard}
                edge="end"
              >
                <ContentCopyOutlinedIcon />
              </TooltipButton>

              {props.canEdit && (
                <TooltipButton
                  className="ms-2"
                  aria-label={t("robot.localCredential.editButtonTooltip")}
                  tooltip={t("robot.localCredential.editButtonTooltip")}
                  onClick={() => {
                    setIsEditPassword(!isEditPassword);
                    if (isEditPassword) props.discardChanges();
                  }}
                  edge="end"
                >
                  {isEditPassword ? <ClearIcon /> : <EditIcon />}
                </TooltipButton>
              )}
            </InputAdornment>
          ),
        }}
      />
      {props.canEdit && (
        <div className="row">
          {props.config.allowEmpty && props.value.length === 0 && (
            <Requirement
              isValid={true}
              text={t("robot.localCredential.rules.empty")}
            />
          )}

          {(!props.config.allowEmpty || props.value.length > 0) && (
            <>
              {props.config.minLength > 0 && (
                <Requirement
                  isValid={validated.minLength}
                  text={t("robot.localCredential.rules.minLength", {
                    min: props.config.minLength,
                  })}
                />
              )}
              {props.config.maxLength > 0 && (
                <Requirement
                  isValid={validated.maxLength}
                  text={t("robot.localCredential.rules.maxLength", {
                    max: props.config.maxLength,
                  })}
                />
              )}

              {props.config.minNumbers > 0 && (
                <Requirement
                  isValid={validated.minNumbers}
                  text={t("robot.localCredential.rules.minNumbers", {
                    min: props.config.minNumbers,
                  })}
                />
              )}
              {props.config.minAlphabet > 0 && (
                <Requirement
                  isValid={validated.minAlphabet}
                  text={t("robot.localCredential.rules.minAlphabet", {
                    min: props.config.minAlphabet,
                  })}
                />
              )}
              {props.config.minSpecialChars > 0 && (
                <Requirement
                  isValid={validated.minSpecialChars}
                  fullWidth={true}
                  text={t("robot.localCredential.rules.minSpecialChars", {
                    min: props.config.minSpecialChars,
                    allowed: props.config.specialChars,
                  })}
                />
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

interface CredentialPasswordInputProps {
  value: string;
  canEdit: boolean;
  config: KemaroRobotCredentialsConfigDto;
  setValue: (value: string) => void;
  discardChanges: () => void;
}

const Requirement = (props: RequirementProps) => (
  <div
    className={`col-12 ${!props.fullWidth ? "col-lg-6" : ""}  mt-1 requirement`}
  >
    {props.isValid && <CheckRoundedIcon color="success" />}
    {!props.isValid && <CloseRoundedIcon color="error" />}

    <span className="ps-1">{props.text}</span>
  </div>
);

interface RequirementProps {
  isValid: boolean;
  text: string;
  fullWidth?: boolean;
}
