import { Autocomplete, NativeSelect, TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import {
  MaxbrainLanguageDto,
  UserAccessType,
  UserMaxbrainConfigDto,
} from "../../../../../robotcloud-shared/resource-models";
import { ToastHelper } from "../../../components/news";
import { ApplicationConfigurationRequest } from "../../../data/applicationConfiguration.request";
import { ErrorHelper } from "../../../uiHelpers/errors.helper";
import AuthorizationContext from "../../auth/authorizationContext";
import { useTranslation } from "react-i18next";
import { userMaxbrainConfigLanguageSubscriptionManager } from "./userMaxbrainConfigSubscriptionManager";

export const UserMaxbrainConfig = (props: UserMaxbrainConfigProps) => {
  const [isFirstTime, setIsFirstTime] = useState(true);
  const { t } = useTranslation();

  const [languages, setLanguages] = useState<MaxbrainLanguageDto[]>([]);
  const [language, setLanguage] = useState<MaxbrainLanguageDto>({
    ...defaultMaxbrainLanguageDto,
  });
  const [current, setCurrent] = useState<UserMaxbrainConfigDto>({
    ...defaultUserMaxbrainConfigDto,
  });
  const [lastCurrent, setLastCurrent] = useState("--");
  const [groups, setGroups] = useState<string[]>([]);

  const { me } = useContext(AuthorizationContext);

  useEffect(() => {
    const userMaxbrainConfigLanguageSubscription =
      userMaxbrainConfigLanguageSubscriptionManager
        .getObservable()
        .subscribe((x) => currentUpdate(x));

    return () => {
      userMaxbrainConfigLanguageSubscription.unsubscribe();
    };
  });

  useEffect(() => {
    const getGroups = async () => {
      try {
        setGroups(
          await ApplicationConfigurationRequest.getKemaroAcademyGroups()
        );
        setLanguages(
          await ApplicationConfigurationRequest.getKemaroAcademyLanguages()
        );
      } catch (error) {
        ToastHelper.errors(ErrorHelper.process(error));
      }
    };

    getGroups();
  }, []);

  useEffect(() => {
    if (languages.length === 0) return;
    if (!isFirstTime) return;

    onCurrentUpdate(
      languages,
      props.userMaxbrainConfig.language,
      defaultUserMaxbrainConfigDto,
      props.userMaxbrainConfig.group
    );

    setIsFirstTime(false);
  }, [props.userMaxbrainConfig, languages, current, isFirstTime]);

  useEffect(() => {
    const strCurrent = JSON.stringify(current);
    if (strCurrent === lastCurrent) {
      return;
    }

    setLastCurrent(strCurrent);

    if (lastCurrent !== "--") {
      props.onChange(current);
    }
  }, [props, current, lastCurrent]);

  const currentUpdate = (lng: string) => {
    onCurrentUpdate(
      languages,
      lng,
      defaultUserMaxbrainConfigDto,
      props.userMaxbrainConfig.group
    );
  };

  const onCurrentUpdate = (
    lngs: MaxbrainLanguageDto[],
    newLng: string,
    def: UserMaxbrainConfigDto,
    grp: string
  ) => {
    const lngSelected = lngs.filter(
      (x) => x.iso === newLng.toLocaleLowerCase()
    )[0];
    const lng = lngSelected ? newLng.toLocaleLowerCase() : def.language;

    setLanguage(lngSelected);

    setCurrent({
      ...defaultUserMaxbrainConfigDto,
      language: lng,
      group: grp,
    });
  };

  return (
    <>
      {props.showTitle && (
        <div className="col-12 mt-3 pt-4 pb-4">
          <h5 className="mb-0">{t("kemaroAcademy.title")}</h5>
        </div>
      )}
      <div className={`col-${props.rowHeaderCols} input-label`}>
        {t("kemaroAcademy.language")}
      </div>
      <div className={`col-${12 - props.rowHeaderCols!}`}>
        <Autocomplete
          size="small"
          freeSolo
          options={languages}
          getOptionLabel={generateLanguageOptions}
          renderOption={(props, lng) => (
            <div
              key={lng.iso}
              className={
                "w-100 autocomplete-template py-2 px-3 " +
                (language.iso === lng.iso ? "autocomplete-template-select" : "")
              }
              onClick={() => {
                setLanguage(lng);
                setCurrent((x) => ({ ...x, language: lng.iso }));
              }}
            >
              <span className="px-2">{lng.displayName}</span>
            </div>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label=""
              placeholder={t("common.typeToSearch")}
              fullWidth
              variant="standard"
              margin="dense"
              hiddenLabel
            />
          )}
          value={language}
          onChange={(event, newValue) => {
            const selected = languages.find((x) => x.displayName === newValue);
            if (selected) setLanguage(selected);
          }}
        />
      </div>

      {me?.userAccessType === "Earlybyte" &&
        props.accessType === "Earlybyte" && (
          <>
            <div className={`col-${props.rowHeaderCols} input-label`}>
              {t("kemaroAcademy.info.group")}
            </div>
            <div
              className={`col-${12 - props.rowHeaderCols!} ${
                props.cssClassGroupContainer
              }`}
            >
              <NativeSelect
                className="w-100"
                value={current.group}
                onChange={(event) =>
                  setCurrent((x) => ({ ...x, group: event.target.value }))
                }
              >
                {groups.map((x) => (
                  <option key={x} value={x}>
                    {x}
                  </option>
                ))}
              </NativeSelect>
            </div>
          </>
        )}
    </>
  );
};

interface UserMaxbrainConfigProps {
  accessType: UserAccessType;
  userMaxbrainConfig: UserMaxbrainConfigDto;
  showTitle?: boolean;
  rowHeaderCols?: number;
  cssClassGroupContainer?: string;
  onChange: (UserMaxbrainConfig: UserMaxbrainConfigDto) => void;
}

UserMaxbrainConfig.defaultProps = {
  showTitle: false,
  rowHeaderCols: 3,
  cssClassGroupContainer: "pt-1",
};

export const defaultUserMaxbrainConfigDto: UserMaxbrainConfigDto = {
  group: "",
  language: "en",
};

const generateLanguageOptions = (
  item: MaxbrainLanguageDto | undefined | string
) => {
  if (typeof item === "string") {
    return item;
  }

  return item && item.iso ? item.displayName : "";
};

const defaultMaxbrainLanguageDto: MaxbrainLanguageDto = {
  iso: "",
  displayName: "",
};
