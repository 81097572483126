import { Card } from "@mui/material";
import { useEffect, useState } from "react";
import { Loading } from "../../../components/Loading";
import { WorkflowTranslatedDatasRequests } from "../../../data/workflowTranslatedDatas.request";
import "./workflowTranslatedDataDialog.scss";

export const WorkflowTranslatedDataFileView = ({
  workflowTranslatedDataId,
}: WorkflowTranslatedDataDialogProps) => {
  const [pdfContent, setPdfContent] = useState<string>();
  const [isLoadingPdf, setIsLoadingPdf] = useState<boolean>(false);

  useEffect(() => {
    if (workflowTranslatedDataId) {
      setIsLoadingPdf(true);
      WorkflowTranslatedDatasRequests.getPdfContent(
        workflowTranslatedDataId
      ).then((result) => {
        if (result.status === 200) {
          if (result.data.size > 0) {
            setPdfContent(URL.createObjectURL(result.data));
          } else {
            setPdfContent(undefined);
          }
          setIsLoadingPdf(false);
        }
      });
    }

    return () => {
      setPdfContent(undefined);
      setIsLoadingPdf(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card className="application-modal workflow-translated-data-dialog">
      <div className="modal-content">
        <div className="application-modal-body">
          <div className="row">
            <div className="col-12 ">
              {isLoadingPdf ? (
                <Loading className="file-container" isLoading={isLoadingPdf} />
              ) : pdfContent ? (
                <object
                  aria-label="test"
                  className="border file-container"
                  data={`${pdfContent ?? "#"}#toolbar=0`}
                  type="application/pdf"
                ></object>
              ) : (
                <div className="border file-container"></div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

interface WorkflowTranslatedDataDialogProps {
  workflowTranslatedDataId: string;
  pdfContent?: string;
}
