import { useTranslation } from "react-i18next";
import {
  TooltipButton,
  tableGenericHandleKeyboardSubjectManager,
} from "../../../../components/news";
import KemaroRobotContext from "../KemaroRobotContext";
import { useContext, useState } from "react";
import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";
import { KemaroRobotTagsModal } from "../KemaroRobotTagsModal";

export const TagsButton = () => {
  const { t } = useTranslation();
  const { currentItem, refreshItems, setViewSideModalOpenChange } =
    useContext(KemaroRobotContext);
  const [openTagPopup, setOpenTagPopup] = useState(false);

  const openTagPopupChange = (value: boolean) => {
    setOpenTagPopup(value);
    setViewSideModalOpenChange(value);
  };

  return (
    <>
      <TooltipButton
        aria-label={t("tags.title")}
        tooltip={t("tags.title")}
        onClick={() => openTagPopupChange(true)}
      >
        <LabelOutlinedIcon color="primary" />
      </TooltipButton>

      {openTagPopup && currentItem!.id && (
        <KemaroRobotTagsModal
          robotId={currentItem!.id}
          tags={currentItem!.tags}
          onRefreshTags={() => refreshItems(currentItem?.id)}
          onClose={() => {
            openTagPopupChange(false);
            tableGenericHandleKeyboardSubjectManager.setData({
              caller: "KemaroRobotTagsModal",
              handleKeyboard: false,
            });
          }}
        />
      )}
    </>
  );
};
