import { KemaroDashboardEconomyCostCalculationReducedDto } from "../../../../../../robotcloud-shared/resource-models";
import { ToastHelper } from "../../../../components/news";
import { KemaroDashboardEconomyCostCalculationsRequests } from "../../../../data/kemaro/kemaroDashboardEconomyCostCalculations.requests";
import { ErrorHelper } from "../../../../uiHelpers/errors.helper";

export const getKemaroDashboardEconomyCostCalculations = async (
  idKemaroDashboardEconomyCostCalculation: string | null
) => {
  const result: KemaroDashboardEconomyCostCalculationReducedDto[] = [];
  if (!idKemaroDashboardEconomyCostCalculation) return result;

  try {
    const costCalculation =
      await KemaroDashboardEconomyCostCalculationsRequests.getByIdReduced(
        idKemaroDashboardEconomyCostCalculation
      );

    result.push(costCalculation);
  } catch (error) {
    ToastHelper.errors(ErrorHelper.process(error));
  }

  return result;
};
