import { Dialog, Button, Switch, TextField, MenuItem } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BackupIcon from "@mui/icons-material/Backup";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  WorkflowPostDto,
  WorkflowReducedDto,
  WorkflowTarget,
  WorkflowType,
} from "../../../../../robotcloud-shared/resource-models";
import { Loading } from "../../../components/Loading";
import {
  ToastHelper,
  TextInput,
  TooltipButton,
  tableGenericHandleKeyboardSubjectManager,
} from "../../../components/news";
import { ErrorHelper } from "../../../uiHelpers/errors.helper";
import "./workflowDialog.scss";
import { GeneralFunctions } from "../../generalFunctions";
import { WorkflowsRequests } from "../../../data/workflows.request";
import OnBoardingContext from "../OnBoardingContext";
import { SupRemark } from "../../../uiHelpers/SupRemark";

export const WorkflowDialog = ({
  target,
  originalWorkflow,
  openPopup,
  onSaved,
  onCancel,
}: WorkflowDialogProps) => {
  const fullWorkflowTypes: WorkflowType[] = [
    "file",
    "link",
    "companyContactCreation",
  ];

  const getWorkflowTypes = (): WorkflowType[] => {
    if (target === "user") {
      return fullWorkflowTypes.filter((x) => x !== "companyContactCreation");
    }
    return [...fullWorkflowTypes];
  };

  const { t } = useTranslation();
  const { process } = useContext(OnBoardingContext);
  const [isWorking, setIsWorking] = useState<boolean>(false);
  const [pdfContent, setPdfContent] = useState<string>();
  const [pdfFile, setPdfFile] = useState<File>();
  const hiddenFileInput = useRef(null);
  const [orderAuto, setOrderAuto] = useState<boolean>(
    originalWorkflow ? false : true
  );

  const defaultData: WorkflowReducedDto = useMemo(() => {
    return {
      id: "",
      name: "",
      displayTitle: "",
      displayInstructions: "",
      displayAgreement: "",
      displayLinkUrl: "",
      enabled: true,
      type: "link",
      abortable: false,
      required: true,
      target,
      idProcess: process?.id ?? "",
      order: 0,
      validFrom: new Date(),
    };
  }, [process?.id, target]);

  const [workflow, setWorkflow] = useState<WorkflowReducedDto>(
    originalWorkflow
      ? { ...originalWorkflow }
      : { ...defaultData, idProcess: process?.id ?? "" }
  );

  useEffect(() => {
    setWorkflow(
      originalWorkflow
        ? { ...originalWorkflow }
        : { ...defaultData, idProcess: process?.id ?? "" }
    );

    setPdfContent(undefined);
    setPdfFile(undefined);
  }, [defaultData, process?.id, originalWorkflow]);

  const [isLoadingPdf, setIsLoadingPdf] = useState<boolean>(false);
  useEffect(() => {
    if (openPopup && workflow?.id && workflow.type === "file") {
      setIsLoadingPdf(true);
      WorkflowsRequests.getPdf(workflow.id).then((result) => {
        if (result.status === 200) {
          if (result.data.size > 0) {
            setPdfContent(URL.createObjectURL(result.data));
          } else {
            setPdfContent(undefined);
          }
          setIsLoadingPdf(false);
        }
      });
    }
  }, [openPopup, workflow.id, workflow.type]);

  const handleCancel = () => {
    onCancel();
  };

  const handleSave = async () => {
    setIsWorking(true);

    try {
      let data = null;
      if (originalWorkflow) {
        data = await WorkflowsRequests.modify(originalWorkflow, workflow);
      } else {
        const workflowPostDto: WorkflowPostDto = {
          ...workflow,
          order: orderAuto ? 0 : workflow.order, // order auto means order 0.
        };
        data = await WorkflowsRequests.create(workflowPostDto);
      }

      if (pdfFile) {
        await WorkflowsRequests.setPdf(data.id, pdfFile);
      }
      onSaved(data);
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsWorking(false);
  };

  const readFile = (event: any) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      if (e.target!.result !== null) {
        let blob = new Blob([e.target!.result], { type: "application/pdf" });
        setPdfFile(new File([e.target!.result], "file.pdf"));
        const content = URL.createObjectURL(blob);
        setPdfContent(content);
      }
    };
    reader.readAsArrayBuffer(event.target.files[0]);
  };

  useEffect(() => {
    tableGenericHandleKeyboardSubjectManager.setData({
      caller: "WorkflowDialog",
      handleKeyboard: openPopup,
    });

    return () => {
      tableGenericHandleKeyboardSubjectManager.setData({
        caller: "WorkflowDialog",
        handleKeyboard: false,
      });
    };
  }, [openPopup]);

  const getMinDate = (): string => {
    const originalDate = new Date(
      originalWorkflow?.validFrom ?? defaultData.validFrom
    );
    const minDate = originalDate < new Date() ? originalDate : new Date();
    return GeneralFunctions.dateToDateString(minDate);
  };

  return (
    <Dialog
      className="application-modal workflow-dialog"
      open={openPopup}
      fullWidth
      maxWidth="lg"
      onClose={handleCancel}
    >
      <div className="modal-content">
        <div className="application-modal-header ">
          <h3>
            {t(
              `onBoarding.workflow.${originalWorkflow ? "edit" : "create"
              }.title` as const
            )}
          </h3>
        </div>
        {isWorking ? (
          <div className="application-modal-body">
            <Loading isLoading={isWorking} />
          </div>
        ) : (
          <>
            <div className="application-modal-body">
              <div className="row">
                <div className="col-3 input-label">
                  {t("onBoarding.workflow.name")}
                  <SupRemark />
                </div>
                <div className="col-9">
                  <TextInput
                    value={workflow.name}
                    ret={(x) => {
                      setWorkflow({ ...workflow, name: x });
                    }}
                  />
                </div>
                <div className="col-3 input-label">
                  {t("onBoarding.workflow.enabled")}
                </div>
                <div className="col-9">
                  <Switch
                    color="primary"
                    checked={workflow.enabled}
                    onChange={(event) => {
                      setWorkflow({
                        ...workflow,
                        enabled: event.target.checked,
                      });
                    }}
                  />
                </div>
                <div className="col-3 input-label">
                  {t("onBoarding.workflow.type")}
                  <SupRemark />
                </div>
                <div className="col-9">
                  <TextField
                    select
                    fullWidth
                    variant="standard"
                    margin="dense"
                    hiddenLabel
                    value={workflow.type}
                    onChange={(x) =>
                      setWorkflow({
                        ...workflow,
                        type: x.target.value as WorkflowType,
                      })
                    }
                  >
                    {getWorkflowTypes().map((item) => {
                      return (
                        <MenuItem key={item} value={item}>
                          {t(`onBoarding.workflow.workflowTypes.${item}` as const)}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
                <div className="col-3 input-label">
                  {t("onBoarding.workflow.displayTitle")}
                  <SupRemark />
                </div>
                <div className="col-9">
                  <TextInput
                    value={workflow.displayTitle}
                    maxLength={140}
                    ret={(x) => {
                      setWorkflow({ ...workflow, displayTitle: x });
                    }}
                  />
                </div>
                <div className="col-3 input-label">
                  {t("onBoarding.workflow.displayInstructions")}
                  <SupRemark />
                </div>
                <div className="col-9">
                  <TextInput
                    multiline
                    maxLength={650}
                    value={workflow.displayInstructions}
                    ret={(x) => {
                      setWorkflow({ ...workflow, displayInstructions: x });
                    }}
                  />
                </div>
                {(workflow.type === "link" || workflow.type === "file") && (
                  <>
                    <div className="col-3 input-label">
                      {t("onBoarding.workflow.displayAgreement")}
                      <SupRemark />
                    </div>
                    <div className="col-9">
                      <TextInput
                        maxLength={106}
                        value={workflow.displayAgreement}
                        ret={(x) => {
                          setWorkflow({ ...workflow, displayAgreement: x });
                        }}
                      />
                    </div>
                  </>
                )}
                {workflow.type === "link" && (
                  <>
                    <div className="col-3 input-label">
                      {t("onBoarding.workflow.displayLinkUrl")}
                      <SupRemark />
                    </div>
                    <div className="col-9">
                      <TextInput
                        value={workflow.displayLinkUrl}
                        ret={(x) => {
                          let link = x;
                          if (!link.startsWith("https://")) {
                            link = "https://" + link;
                          }
                          setWorkflow({ ...workflow, displayLinkUrl: link });
                        }}
                      />
                    </div>
                  </>
                )}
                <div className="col-3 input-label">
                  {t("onBoarding.workflow.validFrom")}
                  <SupRemark />
                </div>
                <div className="col-9">
                  <TextField
                    className="w-100 pt-0"
                    type="date"
                    inputProps={{ min: getMinDate() }}
                    label=""
                    fullWidth
                    variant="standard"
                    margin="dense"
                    hiddenLabel
                    value={GeneralFunctions.dateToDateString(
                      workflow.validFrom
                    )}
                    onChange={(event) => {
                      if (event.target.value) {
                        const newDate =
                          new Date(event.target.value) ?? new Date();

                        setWorkflow({
                          ...workflow,
                          validFrom: newDate,
                        });
                      }
                    }}
                  />
                  {new Date(workflow.validFrom) > new Date() && (
                    <div className="alert alert-warning" role="alert">
                      {t("onBoarding.workflow.validFromFutureWarning")}
                    </div>
                  )}
                </div>
                <div className="col-3 input-label">
                  {t("onBoarding.workflow.required")}
                </div>
                <div className="col-9">
                  <Switch
                    color="primary"
                    checked={workflow.required}
                    onChange={(event) => {
                      setWorkflow({
                        ...workflow,
                        required: event.target.checked,
                      });
                    }}
                  />
                </div>
                {!originalWorkflow && (
                  <>
                    <div className="col-3 input-label">
                      {t("onBoarding.workflow.useNextOrder")}
                    </div>
                    <div className="col-9">
                      <Switch
                        color="primary"
                        checked={orderAuto}
                        onChange={(event) => {
                          setOrderAuto(event.target.checked);
                        }}
                      />
                    </div>
                  </>
                )}

                <div className="col-3 input-label">
                  {t("onBoarding.workflow.order")}
                  {!orderAuto && <SupRemark />}
                </div>
                <div className="col-9">
                  <TextInput
                    disabled={orderAuto}
                    value={workflow.order.toString()}
                    ret={(x) => {
                      const value: number = parseInt(x);
                      if (!isNaN(value))
                        setWorkflow({ ...workflow, order: value });
                    }}
                  />
                </div>
                {workflow.type === "file" && (
                  <>
                    <div className="col-3 input-label">
                      {t("onBoarding.workflow.displayPdf")}
                      <SupRemark />
                    </div>
                    <div className="col-9">
                      <input
                        type="file"
                        name="myFile"
                        style={{ display: "none" }}
                        accept=".pdf"
                        ref={hiddenFileInput}
                        onChange={readFile}
                      />
                      <TooltipButton
                        tooltip={t("onBoarding.workflow.selectFile") ?? ""}
                        onClick={() => {
                          (hiddenFileInput.current! as any).click();
                        }}
                      >
                        <BackupIcon color="primary" />
                      </TooltipButton>
                      {pdfContent ? (
                        <a href={pdfContent} target="_blank" rel="noreferrer">
                          <VisibilityIcon color="primary" />
                        </a>
                      ) : (
                        <VisibilityIcon color="disabled" />
                      )}
                    </div>
                    <div className="col-12 ">
                      {isLoadingPdf ? (
                        <Loading
                          className="file-container"
                          isLoading={isLoadingPdf}
                        />
                      ) : pdfContent ? (
                        <object
                          aria-label="test"
                          className="border file-container"
                          data={`${pdfContent ?? "#"}#toolbar=0`}
                          type="application/pdf"
                        ></object>
                      ) : (
                        <div className="border file-container"></div>
                      )}
                    </div>
                  </>
                )}

                {process?.behaviour === "link" && (
                  <>
                    <div className="col-2 input-label">
                      {t("onBoarding.workflow.abortable")}
                    </div>
                    <div className="col-2">
                      <Switch
                        color="primary"
                        checked={workflow.abortable}
                        onChange={(event) => {
                          setWorkflow({
                            ...workflow,
                            abortable: event.target.checked,
                          });
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="application-modal-footer">
              <Button
                variant="contained"
                className="button-with-text"
                color="primary"
                disabled={
                  GeneralFunctions.isBlank(workflow.name) ||
                  GeneralFunctions.isBlank(workflow.displayTitle) ||
                  GeneralFunctions.isBlank(workflow.displayInstructions) ||
                  GeneralFunctions.isBlank(workflow.type) ||
                  GeneralFunctions.isBlank(workflow.target) ||
                  (workflow.type === "file" && !pdfContent) ||
                  (workflow.type === "link" &&
                    GeneralFunctions.isBlank(workflow.displayLinkUrl)) ||
                  ((workflow.type === "file" || workflow.type === "link") &&
                    GeneralFunctions.isBlank(workflow.displayAgreement))
                }
                onClick={handleSave}
              >
                {t("common.buttons.save")}
              </Button>
              <Button
                className="modal-cancel-button button-with-text"
                variant="outlined"
                color="primary"
                onClick={handleCancel}
              >
                {t("common.buttons.cancel")}
              </Button>
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
};

interface WorkflowDialogProps {
  target: WorkflowTarget;
  originalWorkflow?: WorkflowReducedDto;
  openPopup: boolean;
  onSaved: (workflow?: WorkflowReducedDto) => void;
  onCancel: () => void;
}
