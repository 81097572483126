import {
  ApiLoginCompleteDto,
  ApiUserContainerDto,
  FeatureFlagDto,
  UserPermissionDto,
} from "../../../robotcloud-shared/resource-models";
import { ApplicationService } from "./application.service";
import { MsalConfigType } from "./auth";

export class StorageManagerService {
  static apiTokenContainerKey: string = "ApiTokenContainer";
  static meKey: string = "Me";
  static permissionsKey: string = "Permissions";
  static msalConfTypeStorageKey = "msalConfType";
  static featureFlags = "FeatureFlags";
  static interactionStatusKey = "msal.interaction.status";

  //---- Api Token
  static setApiTokenContainer = async (
    container: ApiUserContainerDto | undefined | null
  ) => {
    if (container) {
      localStorage.setItem(
        StorageManagerService.apiTokenContainerKey,
        JSON.stringify(container)
      );
    } else {
      localStorage.removeItem(StorageManagerService.apiTokenContainerKey);
    }
  };

  static getApiToken = async () => {
    const jsonObject = localStorage.getItem(
      StorageManagerService.apiTokenContainerKey
    );
    if (!jsonObject) {
      return await StorageManagerService.getApiTokenContainerFromApi();
    }

    const container = JSON.parse(jsonObject) as ApiUserContainerDto;
    if (!container || new Date(container.expire).getTime() <= Date.now()) {
      return await StorageManagerService.getApiTokenContainerFromApi();
    }

    return container;
  };

  static getApiTokenRaw = () => {
    const jsonObject = localStorage.getItem(
      StorageManagerService.apiTokenContainerKey
    );

    return !jsonObject ? null : (JSON.parse(jsonObject) as ApiUserContainerDto);
  };

  static getApiTokenContainerFromApi = async () => {
    const id =
      StorageManagerService.getMe()?.currentManufacturerId ?? undefined;
    const container = await ApplicationService.getApiTokenContainer(id);
    StorageManagerService.setApiTokenContainer(container);

    return container;
  };

  //---- Me
  static setMe = (me: ApiLoginCompleteDto | undefined) => {
    if (me) {
      localStorage.setItem(StorageManagerService.meKey, JSON.stringify(me));
      localStorage.setItem(
        StorageManagerService.permissionsKey,
        JSON.stringify(me.permissions)
      );
      const currentConfigType = sessionStorage.getItem(
        StorageManagerService.msalConfTypeStorageKey
      );
      if (currentConfigType)
        StorageManagerService.setMsalConfigType(
          currentConfigType as MsalConfigType
        );
    } else {
      localStorage.removeItem(StorageManagerService.meKey);
      localStorage.removeItem(StorageManagerService.permissionsKey);
    }
  };

  static getMe = () => {
    const jsonObject = localStorage.getItem(StorageManagerService.meKey);
    return jsonObject
      ? (JSON.parse(jsonObject) as ApiLoginCompleteDto)
      : undefined;
  };

  static getPermissions = () => {
    const jsonObject = localStorage.getItem(
      StorageManagerService.permissionsKey
    );
    return jsonObject
      ? (JSON.parse(jsonObject) as UserPermissionDto[])
      : undefined;
  };

  static getFeatureFlags = (): FeatureFlagDto[] | undefined => {
    const jsonObject = localStorage.getItem(StorageManagerService.featureFlags);

    return jsonObject
      ? (JSON.parse(jsonObject) as FeatureFlagDto[])
      : undefined;
  };

  static setFeatureFlags = (featureFlags: FeatureFlagDto[]) => {
    localStorage.setItem(
      StorageManagerService.featureFlags,
      JSON.stringify(featureFlags)
    );
  };

  static getMsalConfigType = () => {
    return StorageManagerService.getMe()
      ? localStorage.getItem(StorageManagerService.msalConfTypeStorageKey)
      : sessionStorage.getItem(StorageManagerService.msalConfTypeStorageKey);
  };

  static setMsalConfigType = (msalConfigType: MsalConfigType) => {
    return StorageManagerService.getMe()
      ? localStorage.setItem(
          StorageManagerService.msalConfTypeStorageKey,
          msalConfigType
        )
      : sessionStorage.setItem(
          StorageManagerService.msalConfTypeStorageKey,
          msalConfigType
        );
  };

  static removeMsalConfigType = () => {
    sessionStorage.removeItem(StorageManagerService.msalConfTypeStorageKey);
    localStorage.removeItem(StorageManagerService.msalConfTypeStorageKey);
  };

  static clearInteractionStatus = () => {
    if (localStorage.getItem(StorageManagerService.interactionStatusKey)) {
      localStorage.removeItem(StorageManagerService.interactionStatusKey);
    }
    if (sessionStorage.getItem(StorageManagerService.interactionStatusKey)) {
      sessionStorage.removeItem(StorageManagerService.interactionStatusKey);
    }
  };

  static getInteractionStatus = (): string | null => {
    return (
      localStorage.getItem(StorageManagerService.interactionStatusKey) ||
      sessionStorage.getItem(StorageManagerService.interactionStatusKey)
    );
  };

  static clearAll = () => {
    localStorage.clear();
    sessionStorage.clear();
  };
}
