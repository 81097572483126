import { Button, Dialog, NativeSelect } from "@mui/material";
import { Stack } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import {
  Language,
  UserCompleteDto,
} from "../../../../robotcloud-shared/resource-models";
import { TextInput } from "../../components/news/TextInput";
import { TimeZoneSearch } from "../../components/search/TimeZoneSearch";
import { MenuHelper } from "../../uiHelpers/menu.helper";
import AuthorizationContext from "../auth/authorizationContext";
import { useTranslation } from "react-i18next";
import { UserMaxbrainConfig } from "../shared/userMaxbrainConfig/UserMaxbrainConfig";
import {
  YesNoOkDialog,
  tableGenericHandleKeyboardSubjectManager,
} from "../../components/news";
import { userMaxbrainConfigLanguageSubscriptionManager } from "../shared/userMaxbrainConfig/userMaxbrainConfigSubscriptionManager";

//TODO change when the other languages will be availables
//const languages: Array<Language> = ["en", "de", "fr", "es", "ru", "pt"];
const languages: Array<Language> = ["en"];

export const ProfileEditModal = (props: profileEditModalProps) => {
  const [user, setUser] = useState<UserCompleteDto>(props.item);
  const [menuOptions, setMenuOptions] = useState<string[]>([]);
  const [maxbrainConfigLanguage, setMaxbrainConfigLanguage] = useState(
    props.item.maxbrainConfig.language
  );
  const [openModalLanguageChange, setOpenModalLanguageChange] = useState(false);

  const { me, isKemaroAcademySection } = useContext(AuthorizationContext);
  const { t } = useTranslation();

  useEffect(() => {
    setUser(props.item);
    setMaxbrainConfigLanguage(props.item.maxbrainConfig.language);

    const options: string[] = [" "];
    options.push(...MenuHelper.getAllowedOptions(me, true));
    setMenuOptions(options);
  }, [props.item, me]);

  useEffect(() => {
    tableGenericHandleKeyboardSubjectManager.setData({
      caller: "ProfileEditModal",
      handleKeyboard: props.openPopup,
    });
  }, [props.openPopup]);

  const handleCancel = () => {
    setUser(props.item);
    props.onCancel();
  };

  const handleSave = () => {
    if (user.maxbrainConfig.language !== maxbrainConfigLanguage) {
      setOpenModalLanguageChange(true);
      return;
    }

    props.onSave(user);
  };

  return (
    <Dialog
      className="application-modal"
      open={props.openPopup}
      maxWidth="md"
      onClose={handleCancel}
    >
      <div className="modal-content">
        <Stack>
          <div className="application-modal-header ">
            <h3>{t("profile.edit.title")}</h3>
          </div>
          <div className="application-modal-body profile-edit-modal">
            <div className="row">
              <div className="col-4 data-div input-label">
                {t("profile.passwordResetEmail")}
              </div>
              <div className="col-8 data-div">
                <TextInput
                  value={user.passwordResetEmailAddress}
                  ret={(x) =>
                    setUser({ ...user, passwordResetEmailAddress: x })
                  }
                />
              </div>

              <div className="col-4 data-div input-label">
                {t("profile.timezone")}
              </div>
              <div className="col-8 data-div">
                <TimeZoneSearch
                  currentId={user.windowsTimeZoneId}
                  selectionChange={(x) =>
                    setUser({
                      ...user,
                      windowsTimeZoneId: x?.timeZoneInfoId ?? "",
                    })
                  }
                />
              </div>

              <div className="col-4 data-div input-label">
                {t("profile.language")}
              </div>
              <div className="col-8 data-div pt-2">
                <NativeSelect
                  value={user?.language ?? " "}
                  className="w-100"
                  onChange={(event) =>
                    setUser({
                      ...user,
                      language: event.target.value as Language,
                    })
                  }
                >
                  {languages.map((x) => (
                    <option key={x} value={x}>
                      {t(`profile.languages.${x}` as const)}
                    </option>
                  ))}
                </NativeSelect>
              </div>

              <div className="col-4 data-div input-label">
                {t("profile.defaultPage")}
              </div>
              <div className="col-8 data-div pt-2">
                <NativeSelect
                  value={user?.defaultPage ?? " "}
                  className="w-100"
                  onChange={(event) =>
                    setUser({ ...user, defaultPage: event.target.value })
                  }
                >
                  {menuOptions.map((x, idx) => (
                    <option key={`${x.replace(" ", "_")}_${idx}`} value={x}>
                      {x === " "
                        ? x
                        :
                            t(`${`profile.edit.defaultPage.${x
                              .replace(/[/ ]/g, "")
                              .replace(/^./, x[0].toLowerCase())}`}` as const)
                          }
                    </option>
                  ))}
                </NativeSelect>
              </div>

              {isKemaroAcademySection && (
                <UserMaxbrainConfig
                  accessType={user.accessType}
                  userMaxbrainConfig={user.maxbrainConfig}
                  onChange={(x) => setUser({ ...user, maxbrainConfig: x })}
                  rowHeaderCols={4}
                  cssClassGroupContainer="pt-2"
                  showTitle={true}
                />
              )}
            </div>
          </div>
          <div className="application-modal-footer pt-5">
            <Button
              variant="contained"
              className="button-with-text"
              color="primary"
              onClick={handleSave}
            >
              {t("common.buttons.save")}
            </Button>

            <Button
              className="modal-cancel-button button-with-text"
              variant="outlined"
              color="primary"
              onClick={handleCancel}
            >
              {t("common.buttons.cancel")}
            </Button>
          </div>
        </Stack>
      </div>

      <YesNoOkDialog
        open={openModalLanguageChange}
        size="md"
        type="yesNo"
        title={t("kemaroAcademy.modifyQuestion.title")}
        message={t("kemaroAcademy.modifyQuestion.text")}
        onYes={() => {
          props.onSave(user);
          setOpenModalLanguageChange(false);
        }}
        onNo={() => {
          userMaxbrainConfigLanguageSubscriptionManager.setData(
            maxbrainConfigLanguage
          );
          setOpenModalLanguageChange(false);
        }}
        isWorking={false}
      />
    </Dialog>
  );
};

export interface profileEditModalProps {
  openPopup: boolean;
  item: UserCompleteDto;
  onSave: (item: UserCompleteDto) => void;
  onCancel: () => void;
}
