import { Fragment, useEffect, useState } from "react";
import {
  KemaroRobotListDto,
  KemaroRobotSearchDto,
} from "../../../../../robotcloud-shared/resource-models";
import { ToastHelper } from "../../news/Toast.helper";
import { ErrorHelper } from "../../../uiHelpers/errors.helper";
import { KemaroRobotRequests } from "../../../data/kemaro/kemaroRobot.request";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import { BaseSearch } from "../BaseSearch";
import useTheme from "@mui/material/styles/useTheme";

export const KemaroRobotSearch = (props: kemaroRobotSearchProps) => {
  const [items, setItems] = useState<KemaroRobotListDto[]>([]);
  const [currentItem, setCurrentItem] = useState<
    KemaroRobotListDto | undefined
  >(undefined);
  const [searchText, setSearchText] = useState("");
  const [lastSearch, setLastSearch] = useState("-");

  useEffect(() => {
    const textSearch: KemaroRobotSearchDto = {
      textToSearch: searchText,
      fromSearch: true,
      id: props.currentId ?? null,
      maxResults: null,
      blackList: props.blackList ?? null,
    };

    const strTextSearch = `${props.customerId ?? "--"}_${JSON.stringify(
      textSearch
    )}`;

    if (lastSearch === strTextSearch) {
      return;
    }

    setLastSearch(strTextSearch);
    getList(textSearch, props.customerId);
  }, [props, searchText, lastSearch]);

  useEffect(() => {
    const newCurrentItem = items.find((x) => x.id === props.currentId);
    if (newCurrentItem?.id !== currentItem?.id) {
      setCurrentItem(newCurrentItem);
      setLastSearch("-");
    }
  }, [props.currentId, items, currentItem]);

  const getList = async (
    ts: KemaroRobotSearchDto,
    customerId: string | undefined
  ) => {
    try {
      const result = await KemaroRobotRequests.getSearchText(
        customerId ?? null,
        ts
      );

      if (result.searchedText === ts.textToSearch) {
        setItems(result.items);
      }
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }
  };

  const selectionChange = (selected: KemaroRobotListDto | undefined) => {
    if (!selected) {
      setCurrentItem(undefined);
      props.selectionChange(undefined);
      return;
    }

    if (!props.clearAfterSelect) {
      setCurrentItem(selected);
    }

    props.selectionChange(selected);

    if (props.clearAfterSelect) {
      setLastSearch("-");
      setSearchText("");
    }
  };

  const theme = useTheme();

  return (
    <BaseSearch
      disableCloseOnSelect={props.clearAfterSelect}
      value={optionLabelHandle(currentItem)}
      options={items}
      getOptionLabel={optionLabelHandle}
      renderOption={(props, x) => (
        <div
          key={x.id}
          className={
            "w-100 autocomplete-template py-2 px-3 " +
            (currentItem?.id === x.id ? "autocomplete-template-select" : "")
          }
          onClick={() => selectionChange(x)}
        >
          <p className="mb-0 p-0">
            <span>{x.name}</span>
            <span className="float-end">{x.countryName}</span>
          </p>
          <p className="mb-0 p-0">
            {x.companyStructure.map((y, idx) => (
              <Fragment key={y}>
                {idx > 0 && (
                  <ArrowForwardIosRoundedIcon
                    className="search-explanation"
                    style={{ fontSize: "12px" }}
                  />
                )}
                <span
                  className="search-explanation"
                  style={{
                    color: theme.palette.swarm?.search?.searchExplanation,
                  }}
                >
                  {y}
                </span>
              </Fragment>
            ))}
          </p>
        </div>
      )}
      searchText={searchText}
      setSearchText={(x) => setSearchText(x)}
    />
  );
};

interface kemaroRobotSearchProps {
  currentId?: string;
  customerId?: string;
  clearAfterSelect?: boolean;
  blackList?: string[] | undefined;
  selectionChange: (select: KemaroRobotListDto | undefined) => void;
}

const optionLabelHandle = (item: KemaroRobotListDto | string | undefined) => {
  if (!item) {
    return "";
  }

  if (typeof item === "string") {
    return item;
  }

  let result = item.name;

  if (item.countryName) {
    result += ` (${item.countryName})`;
  }

  return result;
};
