import {
  ManufacturerReducedDto,
  CompanyRelationRoles,
} from "../../../../../robotcloud-shared/resource-models";
import CloseIcon from "@mui/icons-material/Close";
import Edit from "@mui/icons-material/Edit";
import { TooltipButton } from "../../../components/news/TooltipButton";
import i18n from "i18next";
import { TableGenericColumn } from "../../../components/news/TableGeneric/models";

export const CompanyRelationRolesList: CompanyRelationRoles[] = [
  "Viewer",
  "Editor",
];

export const ManufacturersColumnDefinition = (
  canModify: boolean,
  canDelete: boolean,
  setPopupDto: (dto: ManufacturerReducedDto | undefined) => void,
  setPopupCloseRelationOpen: (isOpen: boolean) => void,
  setPopupModifyOpen: (isOpen: boolean) => void
) => {
  const columnsDefinition: TableGenericColumn[] = [
    {
      title: i18n.t("common.name").toString(),
      property: "name",
      headerClassName: "ps-0 w-50",
      itemClassName: "ps-0 w-50",
    },
    {
      title: i18n.t("common.role").toString(),
      property: "role",
      headerClassName: "w-50",
      itemClassName: "w-50",
    },
    {
      headerClassName: "two-buttons-container",
      itemClassName: "two-buttons-container text-end",
      canSort: false,
      cellTemplate: (item: ManufacturerReducedDto) => (
        <>
          {canModify && (
            <TooltipButton
              aria-label="edit"
              tooltip={i18n.t("viewHeaderButtons.edit").toString()}
              onClick={() => {
                setPopupDto(item);
                setPopupModifyOpen(true);
              }}
            >
              <Edit color="primary" />
            </TooltipButton>
          )}
          {canDelete && (
            <TooltipButton
              tooltip={i18n.t("manufacturer.unlink.tooltip").toString()}
              aria-label="close relation"
              onClick={() => {
                setPopupDto(item);
                setPopupCloseRelationOpen(true);
              }}
            >
              <CloseIcon color="error" />
            </TooltipButton>
          )}
        </>
      ),
    },
  ];

  return columnsDefinition;
};
