import {
  SearchTextDtoOfGuid,
  SearchTextResultDtoOfCurrencyDto,
} from "../../../robotcloud-shared/resource-models";
import { AxiosRequests } from "./axios.requests";
import { BaseRequest } from "./BaseRequest";
import { prepareSearchTextDto } from "./functions";

export class CurrencyRequest extends BaseRequest {
  static controller = "/api/webapp/v1.0/Currencies";

  static getSearchText = async (searchTextDto: SearchTextDtoOfGuid) => {
    const { data } = await AxiosRequests.post(
      `${CurrencyRequest.controller}/search-text`,
      prepareSearchTextDto(searchTextDto)
    );
    return data as SearchTextResultDtoOfCurrencyDto;
  };
}
