import React from "react";
import {
  ApiLoginCompleteDto,
  ManufacturerConfigurationDto,
  ManufacturerReducedDto,
} from "../../../../robotcloud-shared/resource-models";

const AuthorizationContext = React.createContext<authorizationContextData>({
  me: undefined,
  setMe: () => {},

  currentManufacturer: undefined,
  setCurrentManufacturer: () => {},

  allowedManufacturers: undefined,
  setAllowedManufacturers: () => {},

  configuration: undefined,
  setConfiguration: () => {},

  kemaroMissionChange: undefined,
  setKemaroMissionChange: () => {},

  isKemaroAcademySection: false,
  setIsKemaroAcademySection: () => {},

  isManufacturerAdmin: false,
  setIsManufacturerAdmin: () => {},
});

export interface authorizationContextData {
  me: ApiLoginCompleteDto | undefined;
  setMe(me: ApiLoginCompleteDto | undefined): void;

  allowedManufacturers: ManufacturerReducedDto[] | undefined;
  setAllowedManufacturers(allowedManufacturers: ManufacturerReducedDto[]): void;

  currentManufacturer: ManufacturerReducedDto | undefined;
  setCurrentManufacturer(me: ManufacturerReducedDto | undefined): void;

  configuration: ManufacturerConfigurationDto | undefined;
  setConfiguration(
    configuration: ManufacturerConfigurationDto | undefined
  ): void;

  kemaroMissionChange: Date | undefined;
  setKemaroMissionChange(kemaroMissionChange: Date | undefined): void;

  isKemaroAcademySection: boolean;
  setIsKemaroAcademySection(isKemaroAcademy: boolean): void;

  isManufacturerAdmin: boolean;
  setIsManufacturerAdmin(isManufacturerAdmin: boolean): void;
}

export default AuthorizationContext;
