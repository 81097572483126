import { KemaroRobotCredentialsConfigDto } from "../../../../../../../robotcloud-shared/resource-models";
import { PasswordValidated } from "./models";

export const validatePassword = (
  password: string,
  conf: KemaroRobotCredentialsConfigDto
): PasswordValidated => {
  if (password.length === 0 && conf.allowEmpty) {
    return {
      minLength: true,
      maxLength: true,
      minSpecialChars: true,
      minNumbers: true,
      minAlphabet: true,
    };
  }

  return {
    minLength: conf.minLength === 0 || password.length >= conf.minLength,
    maxLength: conf.maxLength === 0 || password.length <= conf.maxLength,
    minSpecialChars:
      conf.minSpecialChars === 0 ||
      password.split("").filter((x) => conf.specialChars.includes(x)).length >=
        conf.minSpecialChars,
    minNumbers:
      conf.minNumbers === 0 ||
      (password.match(/\d/g)?.length ?? 0) >= conf.minNumbers,
    minAlphabet:
      conf.minNumbers === 0 ||
      new RegExp(`\\w{${conf.minAlphabet},}`).test(password),
  };
};

export const isPasswordValid = (
  password: string,
  conf: KemaroRobotCredentialsConfigDto
) => {
  const result = validatePassword(password, conf);
  return (
    result.minLength &&
    result.maxLength &&
    result.minNumbers &&
    result.minAlphabet &&
    result.minSpecialChars
  );
};
