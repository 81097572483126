import DoneIcon from "@mui/icons-material/Done";
import {
  PermissionMatrixSectionDto,
  PermissionMatrixSectionSubRulesDto,
} from "../../../../robotcloud-shared/resource-models";
import useTheme from "@mui/material/styles/useTheme";

export const PermissionsMatrixRulesRow = (
  section: PermissionMatrixSectionDto,
  subRule: PermissionMatrixSectionSubRulesDto,
  indx: number
) => {
  const theme = useTheme();
  return (
    <tr
      key={`subRule_${section.title}_${subRule.section}_${subRule.tag}`}
      style={
        indx % 2 === 0
          ? {
              backgroundColor:
                theme.palette.swarm?.permissionsMatrix?.rowLightBackground,
              height: "40px",
            }
          : {
              backgroundColor:
                theme.palette.swarm?.permissionsMatrix?.rowDarkBackground,
              height: "40px",
            }
      }
    >
      <td className="ps-4">
        {subRule.tag} {subRule.section}
      </td>

      {subRule.allow.map((x, idx) => (
        <th
          key={`subRule_${section.title}_${subRule.section}_${subRule.tag}_${idx}`}
          style={{
            borderLeft:
              theme.palette.swarm?.permissionsMatrix?.containerThBorderLeft,
          }}
        >
          {x && (
            <DoneIcon
              className="permissions-matrix-icon-check"
              sx={{ color: "swarm.permissionsMatrix.iconCheck" }}
            />
          )}
        </th>
      ))}
    </tr>
  );
};
