import { useState } from "react";
import { Button } from "@mui/material";
import { VersionService } from "./versionService";
import { useTranslation } from "react-i18next";

const VersionWarning = (): JSX.Element => {
  const { t } = useTranslation();
  // Version state management.
  const [updatedVersionState, setUpdatedVersionState] =
    useState<boolean>(false);
  // eslint-disable-next-line no-restricted-globals
  addEventListener("onVersionChangedEvent", () => {
    if (!updatedVersionState) {
      setUpdatedVersionState(true);
    }
  });

  const versionService = VersionService.getInstance();
  return (
    <>
      {updatedVersionState && (
        <div className="m-3">
          {t("common.refreshRequired", {
            // Only show version number without the build number. e.g. 1.0.0
            swarmVersion: versionService.getVersion().split("-")[0],
            refreshButtonName: t("common.buttons.refresh"),
          })}
          <Button
            type="button"
            className="ml-3"
            color="info"
            onClick={async (event) => {
              if (event.detail === 1) {
                window.location.reload();
              }
            }}
          >
            {t("common.buttons.refresh")}
          </Button>
        </div>
      )}
    </>
  );
};

export default VersionWarning;
