import { TableGenericColumn } from "../../components/news/TableGeneric/models";
import i18n from "i18next";

export const customFieldColumnDefinition = () => {
  const result: TableGenericColumn[] = [
    {
      title: i18n.t("customFields.title")!,
      property: "title",
    },
    {
      title: i18n.t("customFields.description")!,
      property: "description",
      itemClassName: "col-description text-overflow-ellipsis",
    },
  ];

  return result;
};
