import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ReactElement } from "react";
import "./banner.scss";
import useTheme from "@mui/material/styles/useTheme";

export const Banner = (props: BannerProps) => {
  const banner = useTheme().palette.swarm?.banner;

  const styleWithBorder =
    props.type === "warning"
      ? {
          backgroundColor: banner?.warningBackground,
          borderLeft: banner?.warningBorder,
          borderRight: "0",
        }
      : props.type === "info"
      ? {
          backgroundColor: banner?.infoBackground,
          borderLeft: banner?.infoBorder,
          borderRight: "0",
        }
      : {
          backgroundColor: banner?.errorBackground,
          borderLeft: banner?.errorBorder,
          borderRight: "0",
        };

  const styleWithoutBorder =
    props.type === "warning"
      ? {
          backgroundColor: banner?.warningBackground,
          borderLeft: "0",
        }
      : props.type === "info"
      ? {
          backgroundColor: banner?.infoBackground,
          borderLeft: "0",
        }
      : {
          backgroundColor: banner?.errorBackground,
          borderLeft: "0",
        };

  return (
    <div className="d-flex banner-container">
      <div
        className={`d-flex ps-4 ${props.type} ${props.type}-border`}
        style={styleWithBorder}
      >
        {props.type === "warning" && (
          <ReportProblemOutlinedIcon
            className="align-self-center icon"
            style={{ color: banner?.warningIcon }}
          />
        )}
        {props.type === "info" && (
          <InfoOutlinedIcon
            className="align-self-center icon"
            style={{ color: banner?.infoIcon }}
          />
        )}
      </div>
      <div
        className={`flex-fill py-4 px-4 ${props.type}`}
        style={styleWithoutBorder}
      >
        {props.text}
        {props.children}
      </div>
    </div>
  );
};

interface BannerProps {
  text?: string;
  children?: ReactElement | never[];
  type: "warning" | "info" | "error";
}
