import { DealerDto } from "../../../../../../robotcloud-shared/resource-models";
import { ToastHelper } from "../../../../components/news";
import { DealersRequests } from "../../../../data/dealers.request";
import { ErrorHelper } from "../../../../uiHelpers/errors.helper";

export const getDealers = async (idDealer: string | null) => {
  const result: DealerDto[] = [];
  if (!idDealer) return result;

  try {
    const dealer = await DealersRequests.getById(idDealer);
    result.push(dealer);
  } catch (error) {
    ToastHelper.errors(ErrorHelper.process(error));
  }

  return result;
};
