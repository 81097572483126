import { useTranslation } from "react-i18next";
import {
  DashboardSpHistoryMessageLogsDto,
  DashboardSpMessageLogParametersWithRelatedData,
  MessageLogsErrorDto,
} from "../../../../../../../../robotcloud-shared/resource-models";
import { GeneralFunctions } from "../../../../../generalFunctions";
import { DataDisplayer } from "./DataDisplayer";
import { colorFromStatus } from "./functions";
import useTheme from "@mui/material/styles/useTheme";

export const RightSide = ({ currentItem }: RightSideProps) => {
  if (!currentItem) return <></>;

  return (
    <>
      <div className="row right-side ps-5">
        <Status {...currentItem} />

        <Parameters {...currentItem.parameters} />

        <Error {...currentItem} />
      </div>
    </>
  );
};

interface RightSideProps {
  currentItem: DashboardSpHistoryMessageLogsDto | undefined;
}

const Status = (currentItem: DashboardSpHistoryMessageLogsDto) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <h6>{t("costCalculation.executionHistory.status")}</h6>
      <DataDisplayer
        title={t("costCalculation.executionHistory.start")}
        mainText={GeneralFunctions.dateToTimeDateString(currentItem.start)}
      />
      <DataDisplayer
        title={t("costCalculation.executionHistory.end")}
        mainText={GeneralFunctions.dateToTimeDateString(currentItem.end)}
      />
      <DataDisplayer
        title={t("costCalculation.executionHistory.status")}
        mainText={currentItem.status}
        textColor={colorFromStatus(currentItem.status, theme)}
      />
    </>
  );
};

const Parameters = (
  parameters: DashboardSpMessageLogParametersWithRelatedData | undefined
) => {
  const { t } = useTranslation();

  if (!parameters) return <></>;

  return (
    <>
      <h6 className="mt-3">Parameters</h6>
      <DataDisplayer
        title={t("costCalculation.executionHistory.idOperation")}
        mainText={parameters.idOperation}
      />
      <DataDisplayer
        title={t(
          "costCalculation.executionHistory.idKemaroDashboardEconomyCostCalculationList"
        )}
        mainText={parameters.idKemaroDashboardEconomyCostCalculationList}
        secondaryText={parameters.kemaroDashboardEconomyCostCalculation}
      />
      <DataDisplayer
        title={t("costCalculation.executionHistory.idCustomer")}
        mainText={parameters.idCustomer}
        secondaryText={parameters.customer}
      />
      <DataDisplayer
        title={t("costCalculation.executionHistory.idKemaroRobot")}
        mainText={parameters.idKemaroRobot}
        secondaryText={parameters.kemaroRobot}
      />
    </>
  );
};

const Error = (currentItem: DashboardSpHistoryMessageLogsDto) => {
  const { t } = useTranslation();

  if (!currentItem.error || errorEmpty(currentItem.error)) return <></>;

  return (
    <>
      <h6 className="mt-3">Error</h6>
      <DataDisplayer
        title={t("costCalculation.executionHistory.errorNumber")}
        mainText={currentItem.error.number}
      />
      <DataDisplayer
        title={t("costCalculation.executionHistory.errorSeverity")}
        mainText={currentItem.error.severity}
      />
      <DataDisplayer
        title={t("costCalculation.executionHistory.errorState")}
        mainText={currentItem.error.state}
      />
      <DataDisplayer
        title={t("costCalculation.executionHistory.errorProcedure")}
        mainText={currentItem.error.procedure}
      />
      <DataDisplayer
        title={t("costCalculation.executionHistory.errorLine")}
        mainText={currentItem.error.line}
      />
      <DataDisplayer
        title={t("costCalculation.executionHistory.errorMessage")}
        mainText={currentItem.error.message}
      />
    </>
  );
};

function errorEmpty(error: MessageLogsErrorDto) {
  return (
    !error.number &&
    !error.severity &&
    !error.state &&
    !error.procedure &&
    !error.line &&
    !error.message
  );
}
