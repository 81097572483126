import { useState, useEffect } from "react";
import {
  UserGridDto,
  UserCompleteDto,
  UserFilterCriteriaDto,
} from "../../../robotcloud-shared/resource-models";
import { ToastHelper } from "../components/news";
import { PaginationDto, paginationDtoDefault, OrderDto } from "../data/common";
import { UsersRequests } from "../data/users.requests";
import { ErrorHelper } from "../uiHelpers/errors.helper";
import { QueryParamHelper } from "../uiHelpers/queryParamHelper";
import { GeneralFunctions } from "../views/generalFunctions";

export const useUsers = () => {
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [isFetchingList, setIsFetchingList] = useState(false);
  const [isFetchingItem, setIsFetchingItem] = useState(false);
  const [pagination, setPagination] = useState<PaginationDto>({
    ...paginationDtoDefault,
  });
  const [dataOrder, setDataOrder] = useState<OrderDto>({
    orderColumns: "name",
    order: "Asc",
  });
  const [items, setItems] = useState<UserGridDto[] | undefined>(undefined);
  const [currentId, setCurrentId] = useState<string | undefined>(undefined);
  const [currentItem, setCurrentItem] = useState<UserCompleteDto | undefined>(
    undefined
  );
  const [textToSearch, setTextToSearch] = useState("");
  const [userFromGraphModalOpen, setUserFromGraphModalOpen] = useState(false);
  const [lastSearch, setLastSearch] = useState<string>("--");
  const [userCreateModalOpen, setUserCreateModalOpen] = useState(false);
  const handleGraphClose = async (thereAreNew: boolean) => {
    setUserFromGraphModalOpen(false);
    if (thereAreNew) {
      setPagination({ ...pagination });
    }
  };
  useEffect(() => {
    let filterCriteria: UserFilterCriteriaDto = {
      page: pagination.currentPage,
      maxItemCount: pagination.maxItemCount,
      orderBy: dataOrder.order,
      orderColumns: dataOrder.orderColumns,
      roles: null,
      ids: null,
      tenants: null,
      accessTypes: null,
      textToSearch: textToSearch,
    };

    const strFilterCriteria = JSON.stringify(filterCriteria);
    if (lastSearch !== strFilterCriteria) {
      if (isFirstTime) {
        const qph = new QueryParamHelper(window.location.search);
        const id = qph.getValue("id");
        if (id) {
          filterCriteria.ids = [id];
          setCurrentId(id);
        }
      }

      setLastSearch(strFilterCriteria);
      getList(filterCriteria, currentItem, pagination, false);
    }

    setIsFirstTime(false);
  }, [
    pagination,
    dataOrder,
    textToSearch,
    lastSearch,
    isFirstTime,
    currentItem,
  ]);

  useEffect(() => {
    if (currentId) {
      getUser(currentId, isFetchingItem, currentItem);
    }
  }, [currentId, isFetchingItem, currentItem]);

  const callGetList = async (selectFirstItem: boolean) => {
    let filterCriteria: UserFilterCriteriaDto = {
      page: pagination.currentPage,
      ids: null,
      roles: null,
      maxItemCount: pagination.maxItemCount,
      orderBy: dataOrder.order,
      orderColumns: dataOrder.orderColumns,
      textToSearch: textToSearch,
      accessTypes: null,
      tenants: null,
    };

    getList(filterCriteria, currentItem, pagination, selectFirstItem);
  };

  const getList = async (
    filterCriteria: UserFilterCriteriaDto,
    ci: UserCompleteDto | undefined,
    page: PaginationDto,
    selectFirstItem: boolean
  ) => {
    setIsFetchingList(true);

    try {
      const data = await UsersRequests.getGrid(filterCriteria);
      setItems(data.items);

      if (data.items.length > 0) {
        if (selectFirstItem || !ci || !data.items.some((x) => x.id === ci.id)) {
          setCurrentId(data.items[0].id);
        }
      }

      GeneralFunctions.ChangePagination(page, data, setPagination);
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsFetchingList(false);
  };

  const getUser = async (
    currentId: string,
    isFetchingItem: boolean,
    ci: UserCompleteDto | undefined
  ) => {
    if (isFetchingItem || ci?.id === currentId) return;

    setIsFetchingItem(true);

    try {
      const item = await UsersRequests.getById(currentId!);
      setCurrentItem(item);
      setCurrentId(item.id);
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsFetchingItem(false);
  };

  const handleEdit = async (item: UserCompleteDto) => {
    let result = false;

    try {
      setIsFetchingItem(true);
      await UsersRequests.modifyComplete(currentItem!, item);
      setIsFetchingItem(false);

      setLastSearch("--");
      setDataOrder({ ...dataOrder });
      setCurrentItem(await UsersRequests.getById(item.id));
      result = true;
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
      setIsFetchingItem(false);
    }

    return result;
  };

  const handleDelete = async (item: UserCompleteDto) => {
    let result = false;
    let selectFirstItem = false;

    setIsFetchingItem(true);

    try {
      await UsersRequests.delete(item.id);
      const nextItem = GeneralFunctions.nextItemFromArray<UserGridDto>(
        items,
        item.id
      );

      setCurrentId(nextItem?.id ?? undefined);
      if (nextItem) await getUser(nextItem.id, false, undefined);
      else {
        selectFirstItem = true;
        setCurrentItem(undefined);
      }

      callGetList(selectFirstItem);

      result = true;
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
      setIsFetchingItem(false);
    }

    setIsFetchingItem(false);

    return result;
  };

  const handleCreate = async (userCompleteDto: UserCompleteDto) => {
    setCurrentId(userCompleteDto.id);
    setCurrentItem(userCompleteDto);
    setUserCreateModalOpen(false);
    await callGetList(false);
  };

  const handleUpdate = (newId: string) => {
    setCurrentId(newId);
    if (currentItem) {
      setCurrentItem({ ...currentItem, id: newId });
    }
    setDataOrder({ ...dataOrder });
    setLastSearch("--");
  };

  const textSearchChange = (newTextToSearch: string) => {
    if (newTextToSearch === textToSearch) return;
    setTextToSearch(newTextToSearch);
    pageChange({
      ...pagination,
      currentPage: 1,
    });
  };

  const pageChange = (pagination: PaginationDto) => {
    setCurrentId(undefined);
    setCurrentItem(undefined);
    setPagination(pagination);
  };
  return {
    textSearchChange,
    handleCreate,
    handleEdit,
    handleUpdate,
    handleDelete,
    pageChange,
    items,
    currentItem,
    isFetchingList,
    isFetchingItem,
    pagination,
    dataOrder,
    setDataOrder,
    setCurrentId,
    userFromGraphModalOpen,
    setUserFromGraphModalOpen,
    handleGraphClose,
    userCreateModalOpen,
    setUserCreateModalOpen,
  };
};
