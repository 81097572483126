import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  useTheme,
} from "@mui/material";
import { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Loading } from "../../../../../components/Loading";
import { ToastHelper } from "../../../../../components/news";
import { ErrorHelper } from "../../../../../uiHelpers/errors.helper";
import { KemaroRobotDeploymentHistoryDto } from "../../../../../../../robotcloud-shared/resource-models";
import { kemaroRobotDeploymentGetHistory } from "../../../../../data/kemaro/kemaroRobotDeployment.request";
import { KemaroRobotDeploymentHistoryRow } from "./KemaroRobotDeploymentHistoryRow";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AuthorizationContext from "../../../../auth/authorizationContext";
import "./KemaroRobotDeploymentHistoryModal.scss";
import { useKemaroDeploymentHistoryData } from "../../../../../hooks";

export const KemaroRobotDeploymentHistoryModal = (
  props: KemaroRobotDeploymentHistoryModalProps
) => {
  const { t } = useTranslation();
  const { deploymentHistory, isLoading } = useKemaroDeploymentHistoryData(
    props.kemaroRobotId
  );
  const [expandedRuns, setExpandedRuns] = useState<string[]>([]);
  const { me } = useContext(AuthorizationContext);
  const theme = useTheme();

  const expandedChange = (run: string, currentState: boolean) => {
    if (currentState && expandedRuns.indexOf(run) < 0) {
      const newExpandedRuns = [...expandedRuns, run];
      setExpandedRuns(newExpandedRuns);
    } else if (!currentState) {
      const idx = expandedRuns.indexOf(run);
      if (idx >= 0) {
        var newExpandedRuns = [...expandedRuns];
        newExpandedRuns.splice(idx, 1);
        setExpandedRuns(newExpandedRuns);
      }
    }
  };

  return (
    <Dialog
      className="application-modal"
      open={true}
      maxWidth="md"
      onClose={props.onClose}
    >
      <div className="modal-content">
        <div className="application-modal-header">
          <h3>{t("robotDeployment.historyModal.title")}</h3>
        </div>
        <div className="application-modal-body kemaro-robot-deployment-history-modal">
          {isLoading && (
            <div className="row mt-2 mb-5">
              <Loading isLoading={true} />
            </div>
          )}

          {!isLoading && deploymentHistory && (
            <div className="row">
              {deploymentHistory.map((dHistory, idxH) => {
                const currentName = `${dHistory.text}_${idxH}`;
                const nextName = `${dHistory.text}_${idxH + 1}`;

                const isExpanded = expandedRuns.indexOf(currentName) >= 0;

                return (
                  <Accordion
                    key={`${dHistory.text}_${idxH}`}
                    elevation={0}
                    className={
                      deploymentHistory.length === idxH + 1 ||
                      isExpanded ||
                      expandedRuns.indexOf(nextName) >= 0
                        ? "accordion"
                        : "accordion-with-out-botton"
                    }
                    style={{
                      border:
                        theme.palette.swarm?.kemaroMissions
                          ?.viewRunsAccordionWithoutBottomBorder,
                    }}
                    onChange={(event, newValue) =>
                      expandedChange(currentName, newValue)
                    }
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${idxH}a-content`}
                      id={`panel${idxH}a-header`}
                      style={{
                        borderBottom: isExpanded
                          ? theme.palette.swarm?.kemaroMissions
                              ?.viewRunsAccordionWithoutBottomBorder
                          : "",
                      }}
                    >
                      <table className="table table-borderless mb-0 ">
                        <tbody>
                          <KemaroRobotDeploymentHistoryRow
                            data={dHistory}
                            me={me!}
                            isSummary={true}
                            isExpanded={isExpanded}
                            theme={theme}
                          />
                        </tbody>
                      </table>
                    </AccordionSummary>
                    <AccordionDetails className="accordion-detail d-block">
                      {dHistory.children.map((customer, idxC) => (
                        <Fragment
                          key={`${dHistory.text}_${idxH}_${customer.text}_${idxC}`}
                        >
                          <table className="table table-borderless mb-0">
                            <tbody>
                              <KemaroRobotDeploymentHistoryRow
                                data={customer}
                                me={me!}
                                theme={theme}
                                isCurrentLocation={
                                  !dHistory.endDate && idxH === 0 && idxC === 0
                                }
                              />
                              {customer.children.map((location, idxL) => (
                                <KemaroRobotDeploymentHistoryRow
                                  key={`${dHistory.text}_${idxH}_${customer.text}_${idxC}_${location.text}_${idxL}`}
                                  data={location}
                                  isChild={true}
                                  me={me!}
                                  theme={theme}
                                  isCurrentLocation={
                                    !dHistory.endDate &&
                                    idxH === 0 &&
                                    idxC === 0 &&
                                    idxL === 0
                                  }
                                />
                              ))}
                            </tbody>
                          </table>
                          <Separator
                            idx={idxC}
                            lenght={dHistory.children.length - 1}
                          />
                        </Fragment>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </div>
          )}
        </div>

        <div className="application-modal-footer">
          <Button
            className="modal-cancel-buttonv button-with-text"
            variant="outlined"
            color="primary"
            onClick={props.onClose}
          >
            {t("common.buttons.cancel")}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export interface KemaroRobotDeploymentHistoryModalProps {
  kemaroRobotId: string;
  onClose: () => void;
}

const Separator = (props: SeparatorProps) =>
  props.idx >= props.lenght ? <></> : <div className="my-3 separator"></div>;

interface SeparatorProps {
  idx: number;
  lenght: number;
}
