import { useContext } from "react";
import kemaroLogo from "../../assets/kemaro.png";
import AuthorizationContext from "../auth/authorizationContext";

export const MainPanelFooter = () => {
  const { currentManufacturer } = useContext(AuthorizationContext);

  //TODO change import kemaroLogo from "../../assets/kemaro.png"; by URL

  return (
    <div className="main-panel-footer">
      {currentManufacturer ? (
        <img src={kemaroLogo} width="100%" alt={"Logo"} />
      ) : (
        <></>
      )}

      <p className="text-center mb-0">SWARM</p>

      <p className="text-center">Made with ❤️ by Earlybyte</p>
    </div>
  );
};
