export const DataDisplayer = (props: DataDisplayerProps) => {
  return (
    <>
      <div className="col-3 pb-2">{props.title}</div>
      <div className="col-9 pb-2" style={{ color: props.textColor }}>
        <p className="mb-0">{props.mainText}</p>
        {props.secondaryText && <p className="mb-0">{props.secondaryText}</p>}
      </div>
    </>
  );
};

interface DataDisplayerProps {
  title: string;
  mainText: string | number | null;
  secondaryText?: string | number | null;
  textColor?: string | undefined;
}
