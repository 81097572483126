import { Stack } from "@mui/material";
import {
  CompanyRelationRoles,
  CustomerCompleteDto,
  DealerReducedDto,
} from "../../../../robotcloud-shared/resource-models";
import { YesNoOkDialog } from "../../components/news/YesNoOkDialog";
import { useEffect, useState } from "react";
import { TitleWithAction } from "../../components/news/TitleWithAction";
import { TableGeneric } from "../../components/news/TableGeneric/TableGeneric";
import { OrderDto } from "../../data/common";
import { ToastHelper } from "../../components/news/Toast.helper";
import { ErrorHelper } from "../../uiHelpers/errors.helper";
import { DealersSelectModal } from "../shared/dealers/DealersSelectModal";
import { DealersColumnDefinition } from "../shared/dealers/DealersColumnDefinition";
import { DealerModifyModal } from "../shared/dealers/DealerModifyModal";
import { CustomersDealersRequests } from "../../data/customersDealers.request";
import { PermissionsHelper } from "../../uiHelpers/permissionsHelper";
import { useTranslation } from "react-i18next";
import { TableGenericHelper } from "../../components/news";

export const CustomerViewDealers = (props: customerViewDealersProps) => {
  const { t } = useTranslation();

  const [popupDto, setDto] = useState<DealerReducedDto | undefined>(undefined);
  const [popupCloseRelationOpen, setPopupCloseRelationOpen] = useState(false);
  const [popupSelectDealersOpen, setPopupSelectDealersOpen] = useState(false);
  const [popupSelectDealersCurrent, setPopupSelectDealersCurrent] = useState<
    string[]
  >([]);
  const [popupModifyOpen, setPopupModifyOpen] = useState(false);
  const [dataOrder, setDataOrder] = useState<OrderDto>({
    orderColumns: "name",
    order: "Asc",
  });
  const [isWorking, setIsWorking] = useState(false);
  const [canAdd, setCanAdd] = useState(false);
  const [canModify, setCanModify] = useState(false);
  const [canDelete, setCanDelete] = useState(false);

  useEffect(() => {
    setCanAdd(
      PermissionsHelper.allow("Customers", "Edit", "Dealers", "Ask") &&
        props.canEdit
    );
    setCanModify(
      PermissionsHelper.allow("Customers", "Edit", "Dealers", "Edit") &&
        props.canEdit
    );
    setCanDelete(
      PermissionsHelper.allow("Customers", "Edit", "Dealers", "Remove") &&
        props.canEdit
    );

    TableGenericHelper.localOrder(dataOrder, props.dealers);
  }, [props, dataOrder]);

  const handleAddDealers = () => {
    setPopupSelectDealersCurrent(props.dealers.map((x) => x.id));
    setPopupSelectDealersOpen(true);
  };

  const handleCloseRelationYes = async (dealer: DealerReducedDto) => {
    setIsWorking(true);

    try {
      await CustomersDealersRequests.delete(props.item.id, dealer.id);
      props.update();
      setPopupCloseRelationOpen(false);
      setIsWorking(false);
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
      setIsWorking(false);
    }
  };

  const addDealers = async (dealerIds: string[]) => {
    dealerIds.map(async (x) => {
      try {
        await CustomersDealersRequests.create({
          idCustomer: props.item.id,
          idDealer: x,
          role: "Viewer",
        });
      } catch (error) {
        ToastHelper.errors(ErrorHelper.process(error));
      }

      props.update();
      setPopupSelectDealersOpen(false);
    });
  };

  const handleModifySave = async (role: CompanyRelationRoles) => {
    try {
      setPopupModifyOpen(false);
      await CustomersDealersRequests.modify({
        idDealer: popupDto!.id,
        idCustomer: props.item.id,
        role: role,
      });

      props.update();
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }
  };

  return (
    <Stack className="w-100">
      <TitleWithAction
        title={t("dealer.entitiesName")}
        titleContainerClassName="child-sub-title"
        tooltip={t("dealer.add.tooltip")}
        showButton={canAdd}
        onClick={handleAddDealers}
      />

      {!props.dealers || props.dealers.length === 0 ? (
        <></>
      ) : (
        <>
          <TableGeneric
            tableClassName="w-100"
            columns={DealersColumnDefinition(
              canModify,
              canDelete,
              setDto,
              setPopupCloseRelationOpen,
              setPopupModifyOpen
            )}
            items={props.dealers}
            idProperty="id"
            headerClick={(header, itemsOrder) =>
              TableGenericHelper.localOrder(
                itemsOrder,
                props.dealers!,
                setDataOrder
              )
            }
            itemsOrder={dataOrder}
          ></TableGeneric>

          <YesNoOkDialog
            open={popupCloseRelationOpen}
            size="md"
            type="yesNo"
            title={t("dealer.unlink.title")}
            message={`${t("dealer.unlink.warning")} ${popupDto?.name}?`}
            onYes={() => handleCloseRelationYes(popupDto!)}
            onNo={() => setPopupCloseRelationOpen(false)}
            isWorking={isWorking}
          />

          {popupDto && popupModifyOpen ? (
            <DealerModifyModal
              isOpenModal={popupModifyOpen}
              role={popupDto.role}
              id={popupDto.id}
              onSave={handleModifySave}
              onCancel={() => setPopupModifyOpen(false)}
            />
          ) : (
            <></>
          )}
        </>
      )}

      {canAdd && popupSelectDealersOpen && (
        <DealersSelectModal
          idsToRemove={popupSelectDealersCurrent}
          isOpenModal={popupSelectDealersOpen}
          onSave={async (dealerIds: string[]) => await addDealers(dealerIds)}
          onCancel={() => setPopupSelectDealersOpen(false)}
        />
      )}
    </Stack>
  );
};

export interface customerViewDealersProps {
  dealers: DealerReducedDto[];
  item: CustomerCompleteDto;
  canEdit: boolean;
  update: () => void;
}
