import { useEffect, useState } from "react";
import { WorkflowTranslatedDataDto } from "../../../../robotcloud-shared/resource-models";
import "./notAllowedWorkflow.scss";
import { Tooltip } from "@mui/material";
import { getWorkflowTranslatedData, Workflow } from "./onBoardingService";
import { WorkflowLanguageSelector } from "./WorkflowLanguageSelector";
import { Banner } from "../../components/news";

export const NotAllowedWorkflow = ({
  workflow,
  initialLanguage,
}: NotAllowedWorkflowProps) => {
  const [language, setLanguage] = useState<string>(initialLanguage);
  const [translatedData, setTranslatedData] = useState<
    WorkflowTranslatedDataDto | undefined
  >(getWorkflowTranslatedData(workflow.workflowTranslatedDatas, language));

  useEffect(() => {
    setTranslatedData(
      getWorkflowTranslatedData(workflow.workflowTranslatedDatas, language)
    );
  }, [workflow, language]);

  return (
    <div className="col-12 text-center workflow-component-container 4">
      <div className="col-12">
        <Tooltip
          title={translatedData?.title ?? workflow.displayTitle}
          placement="top"
          arrow
        >
          <h3 className="workflow-component-title col-12">
            {translatedData?.title ?? workflow.displayTitle}
          </h3>
        </Tooltip>
      </div>
      <div>
        {workflow && workflow.workflowTranslatedDatas.length > 0 && (
          <>
            <div className="col-8"></div>
            <div className="col-4">
              <WorkflowLanguageSelector
                workflow={workflow}
                current={language}
                selectionChange={(select: string | undefined) => {
                  if (select) {
                    setLanguage(select);
                  }
                }}
              />
            </div>
          </>
        )}
      </div>

      <div className="col-12">
        <Banner type="info">
          <span className="not-allowed-workflow-component-message text-linebreak">
            {workflow.displayInstructions}
          </span>
        </Banner>
      </div>
    </div>
  );
};

interface NotAllowedWorkflowProps {
  workflow: Workflow;
  initialLanguage: string;
}
