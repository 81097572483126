import { Button, Dialog } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CustomerReducedDto,
  KemaroCostCalculationLocationDto,
  KemaroCostCalculationLocationsUpdateDto,
} from "../../../../../../robotcloud-shared/resource-models";
import { Loading } from "../../../../components/Loading";
import { ToastHelper, TreeVew } from "../../../../components/news";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import { ErrorHelper } from "../../../../uiHelpers/errors.helper";
import { KemaroCompanyStructureRequests } from "../../../../data/kemaro/KemaroCompanyStructure.request";

export const KemaroCostCalculationLocationsModal = (
  props: KemaroCostCalculationLocationsModalProps
) => {
  const [isWorking, setIsWorking] = useState(false);
  const [locations, setLocations] = useState<
    KemaroCostCalculationLocationDto[]
  >([]);
  const [changes, setChanges] = useState<string[]>([]);

  const { t } = useTranslation();

  useEffect(() => {
    setLocations(props.locations);
  }, [props]);

  useEffect(() => {}, [props.isOpenModal]);

  const handleCancel = () => {
    setLocations(props.locations);
    props.onCancel();
  };

  const treeToList = (nodes: KemaroCostCalculationLocationDto[]) => {
    let nodeList: KemaroCostCalculationLocationDto[] = [];

    nodes.forEach((x) => {
      nodeList.push(x);
      if (x.childNodes.length > 0)
        nodeList = nodeList.concat(treeToList(x.childNodes));
    });

    return nodeList;
  };

  const handleSave = async () => {
    setIsWorking(true);

    const locationList = treeToList(locations);

    const toChange: KemaroCostCalculationLocationsUpdateDto = {
      toSet: locationList
        .filter((x) => changes.includes(x.id) && !x.hasCalculation)
        .map((x) => x.id),
      toUnset: locationList
        .filter((x) => changes.includes(x.id) && x.hasCalculation)
        .map((x) => x.id),
    };

    if (toChange.toSet.length === 0 && toChange.toUnset.length === 0) {
      props.onCancel();
      setIsWorking(false);
      return;
    }

    try {
      await KemaroCompanyStructureRequests.updateFromCostCalculation(
        props.costCalculationId,
        toChange
      );
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsWorking(false);
    props.onSave();
  };

  const checkUnCheck = (item: KemaroCostCalculationLocationDto) => {
    const idx = changes.indexOf(item.id);
    if (idx >= 0) {
      changes.splice(idx, 1);
      setChanges([...changes]);
    } else {
      setChanges([...changes, item.id]);
    }
  };

  return (
    <Dialog
      className="application-modal "
      open={props.isOpenModal}
      maxWidth="md"
      onClose={handleCancel}
    >
      <div className="modal-content kemaro-cost-calculation-locations-modal">
        <div className="application-modal-header">
          <h3 className="modal-title">
            {t("costCalculation.locations.entitiesName")}
          </h3>
        </div>
        <div className="application-modal-body">
          {isWorking ? (
            <Loading isLoading={isWorking} />
          ) : (
            <>
              <div className="row py-3">
                <p className="mb-3">
                  {t("costCalculation.locations.customer")}
                  <span className="ps-2">{props.customer.name}</span>
                </p>

                <TreeVew
                  idProperty="id"
                  textProperty="name"
                  childNodesProperty="childNodes"
                  items={locations}
                  template={(item: KemaroCostCalculationLocationDto) => (
                    <table className="table table-borderless mb-0">
                      <tbody>
                        <tr>
                          <td className="p-0 treeview-check-uncheck-td">
                            {getIcon(item, changes, checkUnCheck)}
                          </td>
                          <td className="text-start py-0 align-middle">
                            <p>{item.name}</p>
                            {item.anotherCalculationName && (
                              <p className="light-grey-button">
                                {item.anotherCalculationName}
                              </p>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                />
              </div>
            </>
          )}
        </div>
        {!isWorking && (
          <div className="application-modal-footer">
            <Button
              variant="contained"
              className="button-with-text"
              color="primary"
              onClick={handleSave}
            >
              {t("common.buttons.save")}
            </Button>

            <Button
              className="modal-cancel-button button-with-text"
              variant="outlined"
              color="primary"
              onClick={handleCancel}
            >
              {t("common.buttons.cancel")}
            </Button>
          </div>
        )}
      </div>
    </Dialog>
  );
};

interface KemaroCostCalculationLocationsModalProps {
  costCalculationId: string;
  customer: CustomerReducedDto;
  locations: KemaroCostCalculationLocationDto[];
  isOpenModal: boolean;
  onCancel: () => void;
  onSave: () => void;
}

const getIcon = (
  item: KemaroCostCalculationLocationDto,
  changes: string[],
  iconClick: (item: KemaroCostCalculationLocationDto) => void
) => {
  const hasCalculation =
    changes.indexOf(item.id) >= 0 ? !item.hasCalculation : item.hasCalculation;

  return hasCalculation ? (
    <CheckBoxOutlinedIcon
      className="primary-text cursor-pointer"
      sx={{ color: "swarm.general.primaryText" }}
      onClick={() => iconClick(item)}
    />
  ) : item.anotherCalculationName ? (
    <CheckBoxOutlinedIcon
      className="light-grey-button cursor-pointer"
      sx={{ color: "swarm.general.lightGreyButton" }}
      onClick={() => iconClick(item)}
    />
  ) : (
    <CheckBoxOutlineBlankOutlinedIcon
      className="light-grey-button cursor-pointer"
      sx={{ color: "swarm.general.lightGreyButton" }}
      onClick={() => iconClick(item)}
    />
  );
};
