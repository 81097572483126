import i18n from "i18next";
import en from "../translations/en.json";
import de from "../translations/de.json";
import fr from "../translations/fr.json";
import es from "../translations/es.json";
import ru from "../translations/ru.json";
import pt from "../translations/pt.json";
import { initReactI18next } from "react-i18next";

export const resources = {
  en: { translation: en },
  de: { translation: de },
  fr: { translation: fr },
  es: { translation: es },
  ru: { translation: ru },
  pt: { translation: pt },
} as const;

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: "en",
    debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources,
  });

export default i18n;
