import { TableHead, TableRow, TableCell } from "@mui/material";
import { OrderDto } from "../../../data/common";
import ArrowDropUpOutlinedIcon from "@mui/icons-material/ArrowDropUpOutlined";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import { TableGenericColumn } from "./models";

export const TableGenericHead = (props: TableGenericHeadProps) => {
  let headerCounter = 0;
  const headerKey = (propertyName?: string) => {
    headerCounter++;
    return propertyName ?? `header_${headerCounter}`;
  };

  return (
    <TableHead>
      <TableRow>
        {props.columns.map((item) => (
          <TableCell
            key={headerKey(item.property)}
            className={`${item.headerClassName ?? ""} header-cell`}
            onClick={(x) => {
              if (item.canSort !== false && item.property)
                props.onHeaderClick(item);
            }}
          >
            <table
              className={`w-100 ${
                item.canSort !== false && item.property ? "cursor-pointer" : ""
              }`}
            >
              <tbody>
                <tr>
                  <td>
                    {item.title ?? propertytoTitle(item.property)}
                    {item.titleTemplate}
                  </td>
                  <td className="text-end cursor-pointer">
                    {!props.itemsOrder ||
                    item.property !== props.itemsOrder.orderColumns ? (
                      <></>
                    ) : props.itemsOrder.order === "Asc" ? (
                      <ArrowDropUpOutlinedIcon className="vertical-center" />
                    ) : (
                      <ArrowDropDownOutlinedIcon className="vertical-center" />
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

interface TableGenericHeadProps {
  columns: TableGenericColumn[];
  itemsOrder?: OrderDto;
  onHeaderClick: (item: TableGenericColumn) => void;
}

const propertytoTitle = (propertyName?: string) => {
  if (!propertyName) {
    return "";
  }

  const title = propertyName.charAt(0).toUpperCase() + propertyName.slice(1);
  return title.match(/[A-Z][a-z]+|[0-9]+/g)!.join(" ");
};
