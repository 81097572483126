import { AxiosResponse } from "axios";
import {
  PaginatedListDtoOfProcessReducedDto,
  ProcessCompleteDto,
  ProcessFilterCriteriaDto,
} from "../../../robotcloud-shared/resource-models";
import { AxiosRequests } from "./axios.requests";
import { BaseRequest } from "./BaseRequest";

export class ProcessesRequests extends BaseRequest {
  static controller = "/api/webapp/v1.0/processes";

  static getList = async (filter: ProcessFilterCriteriaDto) => {
    const { data } = await AxiosRequests.get(
      `${ProcessesRequests.controller}?${ProcessesRequests.objectToQueryString(
        filter
      )}`
    );
    return data as PaginatedListDtoOfProcessReducedDto;
  };

  static getPendingProcesses = async (
    includeLinkProcesses: boolean = true
  ): Promise<ProcessCompleteDto[]> => {
    const { data } = await AxiosRequests.get(
      `${ProcessesRequests.controller}/pending?includeLinkProcesses=${includeLinkProcesses}`
    );
    return data as ProcessCompleteDto[];
  };

  static getPendingCompanyComplianceProcesses = async (): Promise<
    ProcessCompleteDto[]
  > => {
    const { data } = await AxiosRequests.get(
      `${ProcessesRequests.controller}/pendingCompanyCompliance`
    );
    return data as ProcessCompleteDto[];
  };

  static getProcessesFromLink = async (
    idLink: string
  ): Promise<ProcessCompleteDto> => {
    const { data } = await AxiosRequests.get(
      `${ProcessesRequests.controller}/fromLink/${idLink}`
    );
    return data as ProcessCompleteDto;
  };

  static abortProcessLink = async (
    processLinkId: string
  ): Promise<AxiosResponse<void>> => {
    return await AxiosRequests.put(
      `${ProcessesRequests.controller}/${processLinkId}/abortProcessLink`
    );
  };

  static createUserMigrationProcessLink = async (): Promise<string> => {
    const result = await AxiosRequests.post(
      `${ProcessesRequests.controller}/createUserMigrationProcessLink`
    );
    return result.data;
  };
}
