import {
  Autocomplete,
  Button,
  Dialog,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import { CompanyRelationRoles } from "../../../../../robotcloud-shared/resource-models";
import { GeneralFunctions } from "../../generalFunctions";
import { CompanyRelationRolesList } from "./DealersColumnDefinition";
import { useTranslation } from "react-i18next";
import { tableGenericHandleKeyboardSubjectManager } from "../../../components/news";
import { SupRemark } from "../../../uiHelpers/SupRemark";

export const DealerModifyModal = (props: dealerModifyModalProps) => {
  const { t } = useTranslation();

  const [role, setRole] = useState<CompanyRelationRoles | null>(null);

  useEffect(() => {
    setRole(props.role);
  }, [props]);

  useEffect(() => {
    tableGenericHandleKeyboardSubjectManager.setData({
      caller: "DealerModifyModal",
      handleKeyboard: props.isOpenModal,
    });
  }, [props.isOpenModal]);

  const handleCancel = () => {
    props.onCancel();
  };

  const handleSave = () => {
    if (role) {
      props.onSave(role);
    }
  };

  return (
    <Dialog
      className="application-modal"
      open={props.isOpenModal}
      maxWidth="md"
      onClose={handleCancel}
    >
      <div className="modal-content">
        <Stack>
          <Box className="application-modal-header">
            <h3>{t("dealer.edit.title")}</h3>
          </Box>
          <Box className="application-modal-body">
            <Grid container>
              <Grid className="input-label" item xs={4}>
                {t("common.role")}
                <SupRemark />
              </Grid>
              <Grid item xs={8}>
                <Autocomplete
                  size="small"
                  freeSolo
                  onChange={(event, newValue) =>
                    setRole(newValue as CompanyRelationRoles | null)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      placeholder=""
                      fullWidth
                      variant="standard"
                      margin="dense"
                      hiddenLabel
                    />
                  )}
                  options={CompanyRelationRolesList.map((x) => x)}
                  value={role}
                />
              </Grid>
            </Grid>
          </Box>
          <Box className="application-modal-footer">
            <Button
              variant="contained"
              className="button-with-text"
              disabled={GeneralFunctions.isBlank(role)}
              color="primary"
              onClick={handleSave}
            >
              {t("common.buttons.save")}
            </Button>

            <Button
              className="modal-cancel-button button-with-text"
              variant="outlined"
              color="primary"
              onClick={handleCancel}
            >
              {t("common.buttons.cancel")}
            </Button>
          </Box>
        </Stack>
      </div>
    </Dialog>
  );
};

interface dealerModifyModalProps {
  isOpenModal: boolean;
  id: string;
  role: CompanyRelationRoles;
  onSave: (role: CompanyRelationRoles) => void;
  onCancel: () => void;
}
