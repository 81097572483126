import { Button, Dialog } from "@mui/material";
import { useEffect } from "react";
import { KemaroRobotCrudDto } from "../../../../../robotcloud-shared/resource-models";
import { Loading } from "../../../components/Loading";
import { useTranslation } from "react-i18next";
import { tableGenericHandleKeyboardSubjectManager } from "../../../components/news";
import { KemaroRobotErrorSummaryModalData } from "./Components";
import { useKemaroRobotErrorSummary } from "../../../hooks";

export const KemaroRobotErrorSummaryModal = (
  props: kemaroRobotErrorSummaryModalProps
) => {
  const { t } = useTranslation();
  const { isLoading, errorSummaryContainer } = useKemaroRobotErrorSummary(
    props.item.id,
    props.openPopup
  );

  useEffect(() => {
    tableGenericHandleKeyboardSubjectManager.setData({
      caller: "KemaroRobotErrorSummaryModal",
      handleKeyboard: props.openPopup,
    });
  }, [props.openPopup]);

  return (
    <Dialog
      className="application-modal kemaro-robot-error-summary-modal"
      open={props.openPopup}
      maxWidth="md"
      onClose={() => props.onCancel()}
    >
      <div className="modal-content">
        <div className="application-modal-header">
          <h3>{t("robot.errorSummary.title")}</h3>
        </div>

        <div className="application-modal-body kemaro-robot-error-summary-body ">
          <div className="row">
            {isLoading && <Loading isLoading={isLoading} />}

            {!isLoading && errorSummaryContainer && (
              <KemaroRobotErrorSummaryModalData
                container={errorSummaryContainer}
              />
            )}
          </div>
        </div>
        <div className="application-modal-footer">
          <Button
            className="modal-cancel-buttonv button-with-text"
            variant="outlined"
            color="primary"
            onClick={() => props.onCancel()}
          >
            {t("common.buttons.cancel")}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export interface kemaroRobotErrorSummaryModalProps {
  openPopup: boolean;
  item: KemaroRobotCrudDto;
  onCancel: () => void;
}
