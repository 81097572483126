import { useContext, useEffect, useState } from "react";
import { StorageManagerService } from "../../data/storageManager.service";
import "./linkWorkflow.scss";
import { Workflow } from "./onBoardingService";
import { JwtPayload, jwtDecode } from "jwt-decode";
import {
  AzureTokenInfoDto,
  UserCompleteDto,
} from "../../../../robotcloud-shared/resource-models";
import { UsersRequests } from "../../data/users.requests";
import AuthorizationContext from "../auth/authorizationContext";
import { UserDataWorkflow } from "./UserDataWorkflow";

interface SwarmJwtPayload extends JwtPayload {
  AzureTokenInfoDto?: AzureTokenInfoDto;
}

export const UserMigrationWorkflow = ({
  workflow,
  initialLanguage,
  onReady,
}: UserMigrationWorkflowProps) => {
  const [token, setToken] = useState<SwarmJwtPayload | undefined>(undefined);
  const { me } = useContext(AuthorizationContext);
  const [user, setUser] = useState<UserCompleteDto | undefined>();
  const [initialUser, setInitialUser] = useState<UserCompleteDto | undefined>();

  useEffect(() => {
    const getToken = async () => {
      const auxToken = await StorageManagerService.getApiToken();
      const decoded: JwtPayload = jwtDecode(auxToken.token);
      setToken(decoded);
    };

    getToken();
  }, []);

  useEffect(() => {
    const setLocalUser = async () => {
      const localUser = await UsersRequests.getById(me?.id!);
      setUser(localUser);
    };

    setLocalUser();
  }, [me]);

  useEffect(() => {
    if (token?.AzureTokenInfoDto && user) {
      const azureTokenInfoDto: AzureTokenInfoDto = JSON.parse(
        token?.AzureTokenInfoDto as unknown as string
      );
      const auxInitialUser: UserCompleteDto = {
        ...user,
        firstName:
          azureTokenInfoDto.firstName && azureTokenInfoDto.firstName > ""
            ? azureTokenInfoDto.firstName
            : user.firstName,
        lastName:
          azureTokenInfoDto.lastName && azureTokenInfoDto.lastName > ""
            ? azureTokenInfoDto.lastName
            : user.lastName,
        primaryEmailAddress: azureTokenInfoDto.email,
        externalId: azureTokenInfoDto.id,
      };
      setInitialUser(auxInitialUser);
    }
  }, [token, user]);

  return (
    <>
      {initialUser && (
        <UserDataWorkflow
          onReady={onReady}
          workflow={workflow}
          initialLanguage={initialLanguage}
          initialUser={initialUser}
        />
      )}
    </>
  );
};

interface UserMigrationWorkflowProps {
  workflow: Workflow;
  onReady: (ready: boolean) => void;
  initialLanguage: string;
}
