import {
  KemaroRobotCredentialsConfigDto,
  KemaroRobotCredentialsDto,
} from "../../../../../../../robotcloud-shared/resource-models";

export interface CredentalContainer {
  config: KemaroRobotCredentialsConfigDto;
  credential: KemaroRobotCredentialsDto;
  originalPassword: string;
  isShowPassword: boolean;
}

export const defaultKemaroRobotCredentialsConfigDto: KemaroRobotCredentialsConfigDto =
  {
    role: "User",
    minLength: 0,
    maxLength: 0,
    allowEmpty: false,
    minSpecialChars: 0,
    minNumbers: 0,
    minAlphabet: 0,
    specialChars: "",
    specialCharsForSelfGeneration: "",
  };

export const defaultKemaroRobotCredentialsDto: KemaroRobotCredentialsDto = {
  role: "User",
  password: "",
  timestamp: null,
};

export const defaultCredentalContainer: CredentalContainer = {
  config: { ...defaultKemaroRobotCredentialsConfigDto },
  credential: { ...defaultKemaroRobotCredentialsDto },
  originalPassword: "",
  isShowPassword: false,
};

export interface PasswordValidated {
  minLength: boolean;
  maxLength: boolean;
  minSpecialChars: boolean;
  minNumbers: boolean;
  minAlphabet: boolean;
}
