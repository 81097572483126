import { Button, Dialog } from "@mui/material";
import Box from "@mui/material/Box";
import { Stack } from "@mui/material";
import { useEffect } from "react";
import { Loading } from "../../../components/Loading";
import { useTranslation } from "react-i18next";
import { tableGenericHandleKeyboardSubjectManager } from "../../../components/news";
import useTheme from "@mui/material/styles/useTheme";
import { useKemaroConnectionParameters } from "../../../hooks";
import { CopyButton } from "./Components";

export const KemaroRobotAccessSettings = (
  props: kemaroRobotAccessSettingsProps
) => {
  const { t } = useTranslation();
  const {
    kemaroConnectionParameters,
    isLoading,
    setKemaroConnectionParameters,
  } = useKemaroConnectionParameters(props.robotId, props.openPopup);

  useEffect(() => {
    tableGenericHandleKeyboardSubjectManager.setData({
      caller: "KemaroRobotAccessSettings",
      handleKeyboard: props.openPopup,
    });
  }, [props.openPopup]);

  const theme = useTheme();

  const toClipboard = (text: string) => navigator.clipboard.writeText(text);

  return (
    <Dialog
      className="application-modal access-settings-modal"
      open={props.openPopup}
      maxWidth="md"
      onClose={() => props.onClose()}
    >
      <div className="modal-content">
        <Stack>
          <Box className="application-modal-header">
            <h3>{t("robot.accessSettings.title")}</h3>
          </Box>
          <Box className="application-modal-body px-5">
            {isLoading ? (
              <Loading isLoading={isLoading} />
            ) : (
              <div
                className="access-settings-container px-2 py-4 row"
                style={{
                  backgroundColor:
                    theme.palette.swarm?.kemaroRobots
                      ?.accessSettingsContainerBackground,
                }}
              >
                <div className="col-12 py-1">
                  <p className="mb-0">{'"CloudApi": {'} </p>
                </div>
                <div className="col-11 py-1">
                  <p className="mb-0 ms-3">"RobotId": "{props.robotId}"</p>
                </div>
                <div className="col-1 text-end">
                  <CopyButton
                    text={"Copy Robot Id"}
                    onClick={() => toClipboard(props.robotId)}
                  />
                </div>

                <div className="col-11 py-1">
                  <p className="mb-0 ms-3">
                    "BearerToken": "{kemaroConnectionParameters?.bearerToken}"
                  </p>
                </div>

                <div className="col-1 text-end">
                  <CopyButton
                    text={"Copy bearer token"}
                    disabled={
                      kemaroConnectionParameters?.bearerToken === undefined ||
                      kemaroConnectionParameters.bearerToken.trim().length === 0
                    }
                    onClick={() =>
                      toClipboard(kemaroConnectionParameters!.bearerToken)
                    }
                  />
                </div>

                <div className="col-12 py-1">
                  <p className="mb-0 ms-3">
                    "BaseUrl": "{kemaroConnectionParameters?.devicesBaseUrl}"
                  </p>
                </div>

                <div className="col-12 py-1">
                  <p className="mb-0">{"}"}</p>
                </div>
              </div>
            )}
          </Box>
          <Box className="application-modal-footer">
            <Button
              variant="contained"
              className="button-with-text"
              color="primary"
              onClick={() => {
                setKemaroConnectionParameters(undefined);
                props.onClose();
              }}
            >
              {t("common.buttons.ok")}
            </Button>
          </Box>
        </Stack>
      </div>
    </Dialog>
  );
};

export interface kemaroRobotAccessSettingsProps {
  openPopup: boolean;
  robotId: string;
  onClose: () => void;
}
