import { Button, Dialog } from "@mui/material";
import { Loading } from "../../../components/Loading";
import { YesNoOkDialog } from "../../../components/news";
import { UserMaxbrainConfig } from "../userMaxbrainConfig/UserMaxbrainConfig";
import { useTranslation } from "react-i18next";
import { userMaxbrainConfigLanguageSubscriptionManager } from "../userMaxbrainConfig/userMaxbrainConfigSubscriptionManager";
import { useMaxbrainUserInfo } from "../../../hooks";

export const MaxbrainUserModal = (props: MaxbrainUserModalProps) => {
  const { t } = useTranslation();

  const {
    isWorking,
    userMaxbrain,
    userMaxbrainConfig,
    userMaxbrainLanguage,
    setUserMaxbrainConfig,
    openModalLanguageChange,
    setOpenModalLanguageChange,
    onModify,
    handleModify,
    handleCreate,
  } = useMaxbrainUserInfo(props.userId, props.isOpenModal, props.onCancel);

  return (
    <Dialog
      className="application-modal"
      open={props.isOpenModal}
      maxWidth="md"
      onClose={props.onCancel}
    >
      <div className="modal-content">
        <div className="application-modal-header">
          <h3 className="modal-title">{t("kemaroAcademy.info.title")}</h3>
        </div>
        <div className="application-modal-body">
          <div className="row">
            {isWorking && (
              <div className="col-12 py-5">
                <Loading isLoading={isWorking} />
              </div>
            )}

            {!isWorking && !userMaxbrain && (
              <div className="col-12 py3">
                <span>{t("kemaroAcademy.info.noInfo")}</span>
              </div>
            )}

            {!isWorking && userMaxbrain && (
              <>
                <div className="col-3 py-2">{t("kemaroAcademy.info.mail")}</div>
                <div className="col-9 py-2">{userMaxbrain.email}</div>

                <div className="col-3 py-2">
                  {t("kemaroAcademy.info.firstName")}
                </div>
                <div className="col-9 py-2">{userMaxbrain.firstName}</div>

                <div className="col-3 py-2">
                  {t("kemaroAcademy.info.lastName")}
                </div>
                <div className="col-9 py-2">{userMaxbrain.lastName}</div>

                <div className="col-3 py-2">
                  {t("kemaroAcademy.info.group")}
                </div>
                <div className="col-9 py-2">{userMaxbrain.userGroup}</div>

                <div className="col-3 py-2">{t("kemaroAcademy.status")}</div>
                <div className="col-9 py-2">{userMaxbrain.status}</div>

                <UserMaxbrainConfig
                  accessType={userMaxbrain.accessType}
                  userMaxbrainConfig={userMaxbrainConfig}
                  onChange={(x) => setUserMaxbrainConfig(x)}
                />
              </>
            )}
          </div>
        </div>

        <YesNoOkDialog
          open={openModalLanguageChange}
          size="md"
          type="yesNo"
          title={t("kemaroAcademy.modifyQuestion.title")}
          message={t("kemaroAcademy.modifyQuestion.text")}
          onYes={() => {
            onModify();
            setOpenModalLanguageChange(false);
          }}
          onNo={() => {
            setUserMaxbrainConfig((x) => ({
              ...x,
              language: userMaxbrainLanguage,
            }));

            userMaxbrainConfigLanguageSubscriptionManager.setData(
              userMaxbrainLanguage
            );
            setOpenModalLanguageChange(false);
          }}
          isWorking={false}
        />

        {!isWorking && (
          <div className="application-modal-footer pt-4">
            {userMaxbrain ? (
              <Button
                variant="contained"
                className="button-with-text"
                color="primary"
                onClick={handleModify}
              >
                {t("common.buttons.save")}
              </Button>
            ) : (
              <Button
                variant="contained"
                className="button-with-text"
                color="primary"
                onClick={handleCreate}
              >
                {t("common.buttons.create")}
              </Button>
            )}

            <Button
              className="modal-cancel-button button-with-text"
              variant="outlined"
              color="primary"
              onClick={props.onCancel}
            >
              {t("common.buttons.cancel")}
            </Button>
          </div>
        )}
      </div>
    </Dialog>
  );
};

interface MaxbrainUserModalProps {
  isOpenModal: boolean;
  userId: string;
  onCancel: () => void;
}
