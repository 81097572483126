import useTheme from "@mui/material/styles/useTheme";
import {
  PermissionMatrixSectionActionDto,
  PermissionMatrixSectionDto,
} from "../../../../robotcloud-shared/resource-models";
import { PermissionsMatrixRulesCell } from "./PermissionsMatrixRulesCell";

export const PermissionsMatrixActionRow = (
  section: PermissionMatrixSectionDto,
  action: PermissionMatrixSectionActionDto,
  indx: number
) => {
  const theme = useTheme();
  return (
    <tr
      key={`action_${section.title}_${action.action}_${action.fieldsString}`}
      style={
        indx % 2 === 0
          ? {
              backgroundColor:
                theme.palette.swarm?.permissionsMatrix?.rowLightBackground,
              height: "40px",
            }
          : {
              backgroundColor:
                theme.palette.swarm?.permissionsMatrix?.rowDarkBackground,
              height: "40px",
            }
      }
    >
      <td className="ps-4">
        {action.action} {section.title}
        {action.userAccessType && ` [${action.userAccessType}]`}
        {action.fieldsString && <p>{action.fieldsString}</p>}
      </td>

      {action.rules.map((x, idx) =>
        PermissionsMatrixRulesCell(idx, section, action, x)
      )}
    </tr>
  );
};
