import { useEffect, useState } from "react";
import {
  KemaroDashboardEconomyCostCalculationCompleteDto,
  KemaroDashboardEconomyCostCalculationParameterDto,
} from "../../../../../../robotcloud-shared/resource-models";
import { TableGenericHelper } from "../../../../components/news";
import { TitleWithAction } from "../../../../components/news/TitleWithAction";
import { PermissionsHelper } from "../../../../uiHelpers/permissionsHelper";
import { KemaroCostCalculationViewParameter } from "./KemaroCostCalculationViewParameter";
import { useTranslation } from "react-i18next";

export const KemaroCostCalculationViewParameters = (
  props: kemaroCostCalculationViewParametersProps
) => {
  const { t } = useTranslation();

  const [canAdd, setCanAdd] = useState(false);

  useEffect(() => {
    setCanAdd(PermissionsHelper.allow("KemaroCostCalculations", "Edit"));
  }, []);

  TableGenericHelper.localOrder(
    { order: "Desc", orderColumns: "from" },
    props.item.parameters
  );

  return (
    <>
      <div className="w-100">
        <TitleWithAction
          title={t("costCalculation.parameters.title")}
          titleContainerClassName="child-sub-title"
          tooltip={t("costCalculation.parameters.add.tooltip")}
          showButton={canAdd}
          onClick={() => props.onAdd()}
        />

        <div className="accordion mt-4" id="kemaroCostCalculationViewParameter">
          {props.item.parameters.map((item) => {
            return (
              <KemaroCostCalculationViewParameter
                key={`accordionItem_${item.id}`}
                currency={props.item.currency.abbreviation}
                parameter={item}
                onCopy={props.onCopy}
                onEdit={props.onEdit}
                onDelete={props.onDelete}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

interface kemaroCostCalculationViewParametersProps {
  item: KemaroDashboardEconomyCostCalculationCompleteDto;
  onAdd: () => void;
  onCopy: (
    costCalculation: KemaroDashboardEconomyCostCalculationParameterDto
  ) => void;
  onEdit: (
    costCalculation: KemaroDashboardEconomyCostCalculationParameterDto
  ) => void;
  onDelete: (
    costCalculation: KemaroDashboardEconomyCostCalculationParameterDto
  ) => void;
}
