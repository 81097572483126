import useTheme from "@mui/material/styles/useTheme";
import { KemaroDashboardEconomyCostCostDto } from "../../../../../../robotcloud-shared/resource-models";
import { ResponsiveBar } from "@nivo/bar";
import { useTranslation } from "react-i18next";

export interface kemaroDashboardEconomyCostChartStackProps {
  data: KemaroDashboardEconomyCostCostDto;
}

export const KemaroDashboardEconomyCostChartStack = (
  props: kemaroDashboardEconomyCostChartStackProps
) => {
  const { t } = useTranslation();

  const data = [
    {
      name: t("common.manual"),
      "Cleaning Cost": props.data.manualCleaningCost,
      "Machine Cost": props.data.manualWeekMachineCost,
      "Service Cost": props.data.manualWeekServiceCost,
      "Cloud Cost": 0,
    },
    {
      name: t("robot.entityName"),
      "Cleaning Cost": 0,
      "Machine Cost": props.data.robotWeekMachineCost,
      "Service Cost": props.data.robotWeekServiceCost,
      "Cloud Cost": props.data.robotWeekCloudCost,
    },
  ];

  const theme = useTheme();
  const colors = [
    theme.palette.swarm?.kemaroDashboardCostChartStack?.color0!,
    theme.palette.swarm?.kemaroDashboardCostChartStack?.color1!,
    theme.palette.swarm?.kemaroDashboardCostChartStack?.color2!,
    theme.palette.swarm?.kemaroDashboardCostChartStack?.color3!,
  ];

  return (
    <div style={{ height: 260 }}>
      <ResponsiveBar
        data={data}
        keys={["Cleaning Cost", "Machine Cost", "Service Cost", "Cloud Cost"]}
        indexBy="name"
        margin={{ top: 20, right: 130, bottom: 25, left: 50 }}
        padding={0.2}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={colors}
        borderColor={{
          from: "color",
          modifiers: [["opacity", 1.6]],
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
        }}
        enableLabel={false}
        labelSkipWidth={11}
        labelSkipHeight={12}
        labelTextColor={{
          from: "color",
          modifiers: [["darker", 0.6]],
        }}
        legends={[
          {
            dataFrom: "keys",
            data: [
              {
                label: t("cost.cloudCost"),
                id: 0,
                color: colors[3],
              },
              {
                label: t("cost.serviceCost"),
                id: 1,
                color: colors[2],
              },
              {
                label: t("cost.machineCost"),
                id: 2,
                color: colors[1],
              },
              {
                label: t("cost.cleaningCost"),
                id: 3,
                color: colors[0],
              },
            ],
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: "left-to-right",
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        role="application"
        ariaLabel="Nivo bar chart demo"
        barAriaLabel={function (e) {
          return (
            e.id + ": " + e.formattedValue + " in country: " + e.indexValue
          );
        }}
      />
    </div>
  );
};
