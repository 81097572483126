import { AxiosRequests } from "./axios.requests";
import { diff, jsonPatchPathConverter } from "just-diff";
import {
  CustomerContactDto,
  CustomerContactPostDto,
  CustomerContactReducedDto,
  SearchTextDtoOfGuid,
  SearchTextResultDtoOfCustomerContactDto,
} from "../../../robotcloud-shared/resource-models";
import { BaseRequest } from "./BaseRequest";
import { prepareSearchTextDto } from "./functions";

export class CustomersContactsRequests extends BaseRequest {
  static controller = "api/webapp/v1.0/Customers/contacts";

  static create = async (postDto: CustomerContactPostDto) => {
    const { data } = await AxiosRequests.post(
      CustomersContactsRequests.controller,
      postDto
    );
    return data as CustomerContactDto;
  };

  static modify = async (
    original: CustomerContactReducedDto,
    modified: CustomerContactReducedDto
  ) => {
    const allowProperties = [
      "/firstName",
      "/sureName",
      "/address",
      "/zipCode",
      "/city",
      "/phone",
      "/email",
      "/idCountry",
      "/description",
    ];

    const jsonPatch = diff(original, modified, jsonPatchPathConverter).filter(
      (x) => allowProperties.includes(x.path)
    );

    const { data } = await AxiosRequests.patch(
      `${CustomersContactsRequests.controller}/${original.id}`,
      jsonPatch
    );
    return data as CustomerContactDto;
  };

  static delete = async (id: string) => {
    return await AxiosRequests.delete(
      `${CustomersContactsRequests.controller}/${id}`
    );
  };

  static getFromCustomer = async (customerId: string) => {
    const { data } = await AxiosRequests.get(
      `${CustomersContactsRequests.controller}/from-customer/${customerId}`
    );
    return data as CustomerContactReducedDto[];
  };

  static getSearchText = async (
    searchTextDto: SearchTextDtoOfGuid,
    customerId: string | null
  ) => {
    const routeParameter = customerId
      ? `/${customerId}/search-text`
      : "/search-text";

    const { data } = await AxiosRequests.post(
      `${CustomersContactsRequests.controller}${routeParameter}`,
      prepareSearchTextDto(searchTextDto)
    );
    return data as SearchTextResultDtoOfCustomerContactDto;
  };
}
