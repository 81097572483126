import { CustomerDealersPostDto } from "../../../robotcloud-shared/resource-models";
import { AxiosRequests } from "./axios.requests";
import { BaseRequest } from "./BaseRequest";

export class CustomersDealersRequests extends BaseRequest {
  static controller = "api/webapp/v1.0/organizations/customersDealers";

  static create = async (postDto: CustomerDealersPostDto) => {
    await AxiosRequests.post(CustomersDealersRequests.controller, postDto);
  };

  static modify = async (postDto: CustomerDealersPostDto) => {
    await AxiosRequests.put(CustomersDealersRequests.controller, postDto);
  };

  static delete = async (customerId: string, dealerId: string) => {
    await AxiosRequests.delete(
      `${CustomersDealersRequests.controller}/${customerId}/${dealerId}`
    );
  };
}
