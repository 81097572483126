import { useTranslation } from "react-i18next";
import { ToastHelper, TooltipButton } from "../../../../components/news";
import { ErrorHelper } from "../../../../uiHelpers/errors.helper";
import { KemaroRobotRequests } from "../../../../data/kemaro/kemaroRobot.request";
import { KemaroRobotFilterCriteriaDto } from "../../../../../../robotcloud-shared/resource-models";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";

export const CsvButton = (props: CsvButtonProps) => {
  const { t } = useTranslation();

  const handleCsv = async () => {
    props.working(true);

    try {
      const csv = await KemaroRobotRequests.csv(props.filter);
      const decodedData = window.atob(csv.content);
      const uInt8Array = new Uint8Array(decodedData.length);

      for (let i = 0; i < decodedData.length; ++i) {
        uInt8Array[i] = decodedData.charCodeAt(i);
      }

      let blob = new Blob([uInt8Array], { type: "application/csv" });

      const linkElement = document.createElement("a");
      linkElement.download = csv.name;
      linkElement.href = URL.createObjectURL(blob);
      linkElement.addEventListener("click", (e) => {
        setTimeout(() => URL.revokeObjectURL(linkElement.href), 30 * 1000);
      });
      linkElement.click();
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    props.working(false);
  };

  return (
    <TooltipButton
      aria-label={t("robot.csv.tooltip")}
      tooltip={t("robot.csv.tooltip")}
      onClick={handleCsv}
    >
      <DownloadOutlinedIcon
        className="primary-text"
        sx={{ color: "swarm.general.primaryText" }}
      />
    </TooltipButton>
  );
};

interface CsvButtonProps {
  filter: KemaroRobotFilterCriteriaDto;
  working: (working: boolean) => void;
}
