import { KemaroRobotDeploymentLocationDto } from "../../../../../../../robotcloud-shared/resource-models";
import { TableGenericColumn } from "../../../../../components/news/TableGeneric/models";
import { GeneralFunctions } from "../../../../generalFunctions";
import i18n from "../../../../../i18n/config";

export const kemaroRobotDeploymentModalColumnsDefinition: TableGenericColumn[] =
  [
    {
      title: i18n.t("robotDeployment.modal.location")!,
      canSort: false,
      property: "locationName",
    },
    {
      title: i18n.t("robotDeployment.modal.start")!,
      property: "startDate",
      headerClassName: "date-time-col",
      canSort: false,
      cellTemplate: (item: KemaroRobotDeploymentLocationDto) => (
        <>{GeneralFunctions.dateToTimeDateString(item.startDate)}</>
      ),
    },
  ];
