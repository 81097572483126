import axios from "axios";
import { acquireAccessToken, authProvider } from "./auth";
import { StorageManagerService } from "./storageManager.service";
import { VersionService } from "../components/news/Version/versionService";

export const BASE_URL = window.location
  ? `${window.location.protocol}//${window.location.host}`
  : "https://localhost:5001";

export const axiosGet = async (url: string, bearerToken?: string) => {
  if (!bearerToken && authProvider) {
    bearerToken = await acquireAccessToken(authProvider);
  }

  return axios.get(url, {
    baseURL: BASE_URL,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      Accept: "application/json",
    },
  });
};

export const axiosPatch = async (
  url: string,
  jsonPatch: any,
  bearerToken?: string
) => {
  if (!bearerToken && authProvider) {
    bearerToken = await acquireAccessToken(authProvider);
  }

  return axios.patch(url, jsonPatch, {
    baseURL: BASE_URL,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      Accept: "application/json",
    },
  });
};

export const axiosPost = async (
  url: string,
  jsonBody: any,
  bearerToken?: string
) => {
  if (!bearerToken && authProvider) {
    bearerToken = await acquireAccessToken(authProvider);
  }

  return axios.post(url, jsonBody, {
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      Accept: "application/json",
    },
  });
};

export const axiosDelete = async (url: string, bearerToken?: string) => {
  if (!bearerToken && authProvider) {
    bearerToken = await acquireAccessToken(authProvider);
  }

  return axios.delete(url, {
    baseURL: BASE_URL,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      Accept: "*/*",
    },
  });
};

export class AxiosRequests {
  static BASE_URL = window.location
    ? `${window.location.protocol}//${window.location.host}`
    : "https://localhost:5001";

  static getWithAzureToken = async (url: string) => {
    const token = authProvider
      ? await acquireAccessToken(authProvider)
      : undefined;

    if (token) {
      return axios.get(url, {
        baseURL: BASE_URL,
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      });
    } else {
      return Promise.reject("No token found");
    }
  };

  static postWithAzureToken = async (url: string, jsonBody: any) => {
    const token = authProvider ? await acquireAccessToken(authProvider) : "";

    return axios.post(url, jsonBody, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    });
  };

  static patchWithAzureToken = async (url: string, jsonPatch: any) => {
    const token = authProvider ? await acquireAccessToken(authProvider) : "";

    return axios.patch(url, jsonPatch, {
      baseURL: BASE_URL,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    });
  };

  static putWithAzureToken = async (url: string, jsonBody: any) => {
    const token = authProvider ? await acquireAccessToken(authProvider) : "";

    return axios.put(url, jsonBody, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    });
  };

  static deleteWithAzureToken = async (url: string) => {
    const token = authProvider ? await acquireAccessToken(authProvider) : "";

    return axios.delete(url, {
      baseURL: BASE_URL,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "*/*",
      },
    });
  };

  static get = async (url: string) => {
    var response = await axios.get(url, {
      baseURL: BASE_URL,
      headers: {
        Authorization: `Bearer ${
          (
            await StorageManagerService.getApiToken()
          ).token
        }`,
        Accept: "application/json",
      },
    });

    const versionService = VersionService.getInstance();
    versionService.setVersion(response.headers["api-current-version"] ?? "");

    return response;
  };

  static getBlob = async (url: string, abortController?: AbortController) => {
    return axios.get(url, {
      responseType: "blob",
      baseURL: BASE_URL,
      headers: {
        Authorization: `Bearer ${
          (await StorageManagerService.getApiToken()).token
        }`,
        Accept: "application/json",
      },
      signal: abortController?.signal,
    });
  };

  static patch = async (url: string, jsonPatch: any) => {
    return axios.patch(url, jsonPatch, {
      baseURL: BASE_URL,
      headers: {
        Authorization: `Bearer ${
          (await StorageManagerService.getApiToken()).token
        }`,
        Accept: "application/json",
      },
    });
  };

  static post = async (url: string, jsonBody?: any) => {
    return axios.post(url, jsonBody, {
      headers: {
        Authorization: `Bearer ${
          (await StorageManagerService.getApiToken()).token
        }`,
        Accept: "application/json",
      },
    });
  };

  static delete = async (url: string) => {
    return axios.delete(url, {
      baseURL: BASE_URL,
      headers: {
        Authorization: `Bearer ${
          (await StorageManagerService.getApiToken()).token
        }`,
        Accept: "*/*",
      },
    });
  };

  static put = async (url: string, jsonBody?: any) => {
    return axios.put(url, jsonBody, {
      headers: {
        Authorization: `Bearer ${
          (await StorageManagerService.getApiToken()).token
        }`,
        Accept: "application/json",
      },
    });
  };

  static postAnonymous = async (url: string, jsonBody?: any) => {
    return axios.post(url, jsonBody, {
      headers: {
        Accept: "application/json",
      },
    });
  };
}
