import { useEffect, useState } from "react";
import { KemaroDashboardEconomyCostCalculationsRequests } from "../../../../../data/kemaro/kemaroDashboardEconomyCostCalculations.requests";
import { DashboardSpResultMessageLogsDto } from "../../../../../../../robotcloud-shared/resource-models";
import { ToastHelper } from "../../../../../components/news";
import { ErrorHelper } from "../../../../../uiHelpers/errors.helper";

export const useDashboardLastExecutionState = () => {
  const [lastState, setLastState] = useState<DashboardSpResultMessageLogsDto>(
    dashboardSpResultMessageLogsDtoDefault
  );

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setLastState(
        await KemaroDashboardEconomyCostCalculationsRequests.lastExecutionState()
      );
    } catch (error) {
      setLastState(dashboardSpResultMessageLogsDtoDefault);
      ToastHelper.errors(ErrorHelper.process(error));
    }
  };

  return { lastState };
};

const dashboardSpResultMessageLogsDtoDefault: DashboardSpResultMessageLogsDto =
  {
    when: new Date(),
    status: "No Data",
  };
