import { createContext, Dispatch, SetStateAction } from "react";
import {
  ProcessLinkDto,
  UserCompleteDto,
} from "../../../../robotcloud-shared/resource-models";

const OnBoardingAssistantContext =
  createContext<OnBoardingAssistantContextData>({
    originalUser: undefined,
    setOriginalUser: () => {},
    newUser: undefined,
    setNewUser: () => {},
    processLink: undefined,
  });

export interface OnBoardingAssistantContextData {
  originalUser: UserCompleteDto | undefined;
  setOriginalUser: Dispatch<SetStateAction<UserCompleteDto | undefined>>;
  newUser: UserCompleteDto | undefined;
  setNewUser: Dispatch<SetStateAction<UserCompleteDto | undefined>>;
  processLink: ProcessLinkDto | undefined;
}

export default OnBoardingAssistantContext;
