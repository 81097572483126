import { useTranslation } from "react-i18next";
import {
  TooltipButton,
  YesNoOkDialog,
  tableGenericHandleKeyboardSubjectManager,
} from "../../../../components/news";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useContext, useEffect, useState } from "react";
import KemaroRobotContext from "../KemaroRobotContext";
import { GeneralFunctions } from "../../../generalFunctions";
import AuthorizationContext from "../../../auth/authorizationContext";
import { NumericFormat } from "react-number-format";
import { kemaroRobotsShowSystemInformationSubjectManager } from "../RxJS";
import useTheme from "@mui/material/styles/useTheme";
import { KemaroConfigurationDto } from "../../../../../../robotcloud-shared/resource-models";

export const SystemInformationButton = () => {
  const { t } = useTranslation();
  const { currentItem } = useContext(KemaroRobotContext);
  const { me } = useContext(AuthorizationContext);
  const [openSystemDetailPopup, setOpenSystemDetailPopup] = useState(false);
  const [heartbeatEvery, setHeartbeatEvery] = useState("");
  const theme = useTheme();
  const { configuration } = useContext(AuthorizationContext);

  useEffect(() => {
    if ((configuration as KemaroConfigurationDto).robots) {
      setHeartbeatEvery(
        GeneralFunctions.secondsToTime(
          (configuration as KemaroConfigurationDto).robots.heartbeatEvery
        )
      );
    }
  }, [configuration]);

  useEffect(() => {
    const kemaroRobotsShowSystemInformationSubscription =
      kemaroRobotsShowSystemInformationSubjectManager
        .getObservable()
        .subscribe(() => showChangeBykey(openSystemDetailPopup));

    return () => {
      kemaroRobotsShowSystemInformationSubscription.unsubscribe();
    };
  }, [openSystemDetailPopup]);

  const showChangeBykey = (showOnOff: boolean) => {
    setOpenSystemDetailPopup(!showOnOff);
    if (showOnOff) {
      tableGenericHandleKeyboardSubjectManager.setData({
        caller: "KemaroRobotViewTitle",
        handleKeyboard: false,
      });
    }
  };

  return (
    <>
      <TooltipButton
        aria-label={t("robot.systemInformation.tooltip")}
        tooltip={t("robot.systemInformation.tooltip")}
        onClick={() => setOpenSystemDetailPopup(true)}
      >
        <InfoOutlinedIcon color="primary" />
      </TooltipButton>

      <YesNoOkDialog
        open={openSystemDetailPopup}
        size="md"
        type="ok"
        title={t("robot.systemInformation.title")}
        onOk={() => setOpenSystemDetailPopup(false)}
      >
        <div className="col-12 kemaro-robot-system-details mt-2">
          <div
            className="p-4 container-with-background w-100"
            style={{
              backgroundColor:
                theme.palette.swarm?.kemaroRobots
                  ?.systemDetailsContainerBackground,
            }}
          >
            <table className="table table-borderless table-transparent w-100 mb-0">
              <tbody>
                <tr>
                  <td>{t("robot.systemInformation.id")}</td>
                  <td>{currentItem!.id}</td>
                </tr>
                <tr>
                  <td>{t("robot.systemInformation.hostname")}</td>
                  <td>{currentItem!.systemDetails.hostname}</td>
                </tr>
                <tr>
                  <td>{t("robot.systemInformation.RSWVersion")}</td>
                  <td>{currentItem!.systemDetails.rswVersion.version}</td>
                </tr>
                <tr>
                  <td>{t("robot.systemInformation.baseboardFirmware")}</td>
                  <td>
                    {currentItem!.systemDetails.baseboardFirmware.version}
                  </td>
                </tr>
                <tr>
                  <td>{t("robot.systemInformation.baseboardHardware")}</td>
                  <td>
                    {currentItem!.systemDetails.baseboardHardware.version}
                  </td>
                </tr>
                <tr>
                  <td>{t("robot.systemInformation.cameraSoftwareVersion")}</td>
                  <td>
                    {currentItem!.systemDetails.cameraSoftwareVersion.version}
                  </td>
                </tr>
                <tr>
                  <td>{t("robot.systemInformation.powerboardHardware")}</td>
                  <td>
                    {currentItem!.systemDetails.powerboardHardware.version}
                  </td>
                </tr>

                <tr>
                  <td>{t("robot.systemInformation.created")}</td>
                  <td>
                    {GeneralFunctions.dateToTimeDateString(
                      currentItem!.created
                    )}
                  </td>
                </tr>
                {(me?.userAccessType === "Earlybyte" ||
                  me?.userAccessType === "Manufacturer") && (
                  <>
                    <tr>
                      <td>
                        {t("robot.systemInformation.cleanedAreaOffset")}
                        <span className="ps-1">
                          [m<sup>2</sup>]
                        </span>
                      </td>
                      <td>
                        <NumericFormat
                          value={currentItem!.offsetCleanedSurface}
                          displayType={"text"}
                          thousandSeparator={"'"}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {t("robot.systemInformation.cleaningTimeOffset")}
                        <span className="ps-1">[mins]</span>
                      </td>
                      <td>{currentItem!.offsetWorkedMinutes}</td>
                    </tr>
                    <tr>
                      <td>{t("robot.systemInformation.heartbeatEvery")}</td>
                      <td>{heartbeatEvery}</td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </YesNoOkDialog>
    </>
  );
};
