import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";
import { TooltipContainer } from "../TooltipContainer";
import { useTranslation } from "react-i18next";
import "./tagsDisplayer.scss";
import useTheme from "@mui/material/styles/useTheme";

export const TagsDisplayer = (props: TagsDisplayerProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <div className="tags-displayer">
      {props.tags.map((x) => (
        <TooltipContainer key={x} text={`${t("tags.tooltip")} ${x}`}>
          <div
            className="badge rounded-pill me-2 mb-2 align-content-center"
            style={{
              color: theme.palette.swarm?.tagsDisplayer?.bagde,
              backgroundColor:
                theme.palette.swarm?.tagsDisplayer?.badgeBackground,
            }}
          >
            <LabelOutlinedIcon color="primary" className="icon me-1" />
            <span>{x}</span>
          </div>
        </TooltipContainer>
      ))}
    </div>
  );
};

interface TagsDisplayerProps {
  tags: string[];
}
