import { useTranslation } from "react-i18next";
import { TooltipButton } from "../../../../components/news";
import { useContext, useEffect, useState } from "react";
import { PermissionsHelper } from "../../../../uiHelpers/permissionsHelper";
import { LocalCredentialModal } from "../Modals";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import AuthorizationContext from "../../../auth/authorizationContext";

export const LocalCredentialButton = () => {
  const { t } = useTranslation();
  const { me } = useContext(AuthorizationContext);

  const [canEdit, setEdit] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setEdit(
      PermissionsHelper.allow(
        "KemaroRobots",
        "Edit",
        "LocalCredential-User",
        "Edit"
      ) ||
        PermissionsHelper.allow(
          "KemaroRobots",
          "Edit",
          "LocalCredential-ServiceAdministrator",
          "Edit"
        )
    );
  }, [me]);

  return (
    <>
      <TooltipButton
        className="button"
        tooltip={
          canEdit
            ? t("robot.localCredential.editTooltip")
            : t("robot.localCredential.viewTooltip")
        }
        onClick={() => setIsModalOpen(true)}
      >
        <LockOutlinedIcon
          className="icon-button-primary-inline"
          color="primary"
        />
      </TooltipButton>

      {isModalOpen && (
        <LocalCredentialModal
          openPopup={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
        />
      )}
    </>
  );
};
