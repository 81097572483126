import { NumericFormat } from "react-number-format";
import { KemaroDashboardEconomyCostWeekResultDto } from "../../../../../../robotcloud-shared/resource-models";
import { KemaroDashboardEconomyCostChartAmortization } from "./KemaroDashboardEconomyCostChartAmortization";
import { KemaroDashboardEconomyCostChartStack } from "./KemaroDashboardEconomyCostChartStack";
import KemaroDashboardCostComparisonModal from "./KemaroDashboardCostComparisonModal";
import { useTranslation } from "react-i18next";

export interface kemaroDashboardEconomyCostProps {
  data: KemaroDashboardEconomyCostWeekResultDto;
}

export const KemaroDashboardEconomyCost = (
  props: kemaroDashboardEconomyCostProps
) => {
  const { t } = useTranslation();

  return (
    <div className="row">
      <div className="col-12 title">{t("cost.title")}</div>
      <div className="col-7">
        <table className="table table-borderless mt-3">
          <tbody>
            <tr>
              <td className="td-description py-0">
                {t("cost.weeklyTotalCost")}
              </td>
              <td className="td-value py-0">
                <NumericFormat
                  value={props.data.cost.weeklyTotalCost}
                  displayType={"text"}
                  thousandSeparator={"'"}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
                {props.data.currency}
              </td>
            </tr>
            <tr>
              <td className="td-description py-0">
                {t("cost.weeklyCostSaving")}
              </td>
              <td className="td-value py-0">
                <NumericFormat
                  value={props.data.cost.weeklyCostSaving}
                  displayType={"text"}
                  thousandSeparator={"'"}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
                {props.data.currency}
              </td>
            </tr>
            <tr>
              <td className="td-description py-0">
                {t("cost.lifetimeTotalCost")}
              </td>
              <td className="td-value py-0">
                <NumericFormat
                  value={props.data.cost.lifetimeTotalCost}
                  displayType={"text"}
                  thousandSeparator={"'"}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
                {props.data.currency}
              </td>
            </tr>
            <tr>
              <td className="td-description py-0">
                {t("cost.lifeTimeCostSaving")}
              </td>
              <td className="td-value py-0">
                <NumericFormat
                  value={props.data.cost.lifetimeCostSaving}
                  displayType={"text"}
                  thousandSeparator={"'"}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
                {props.data.currency}
              </td>
            </tr>
          </tbody>
        </table>

        <KemaroDashboardEconomyCostChartStack data={props.data.cost} />

        <p className="footer-title mt-2">
          {t("cost.costComparison")}
          <KemaroDashboardCostComparisonModal />
        </p>
      </div>

      <div className="col-5">
        <KemaroDashboardEconomyCostChartAmortization data={props.data.cost} />
      </div>
    </div>
  );
};
