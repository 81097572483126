import { useState, useEffect } from "react";
import { KemaroMissionsRequests } from "../data/kemaro/kemaroMissions.request";
import { ToastHelper } from "../components/news/Toast.helper";
import { ErrorHelper } from "../uiHelpers/errors.helper";
import { KemaroMissionErrorSummaryContainerDto } from "../../../robotcloud-shared/resource-models";

export const useKemaroRobotErrorSummary = (robotId?: string |null, openPopup: boolean = false) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorSummaryContainer, setErrorSummaryContainer] = useState<KemaroMissionErrorSummaryContainerDto | undefined>(undefined);

  useEffect(() => {
    if (!openPopup || !robotId) {
      return;
    }

    setIsLoading(true);
    KemaroMissionsRequests.getErrorSummary(robotId)
      .then(data => {
        setErrorSummaryContainer(data);
      })
      .catch(error => {
        setErrorSummaryContainer(undefined);
        ToastHelper.errors(ErrorHelper.process(error));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [robotId, openPopup]);

  return {
    isLoading,
    errorSummaryContainer
  };
};
