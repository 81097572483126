import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Link } from "react-router-dom";
import { useContext } from "react";
import AuthorizationContext from "../../views/auth/authorizationContext";
import { PermissionsHelper } from "../../uiHelpers/permissionsHelper";
import { useTranslation } from "react-i18next";

export const OrganizationTabsNav = (props: tabsNavProps) => {
  const { t } = useTranslation();

  const { me } = useContext(AuthorizationContext);

  const manufacturerLabel =
    me?.userAccessType === "Manufacturer"
      ? me.tenantName
      : t("applicationBar.organization.tabs.manufacturers");

  const dealerLabel =
    me?.userAccessType === "Dealer"
      ? me.tenantName
      : t("applicationBar.organization.tabs.dealers");

  const customerLabel =
    me?.userAccessType === "Customer"
      ? me.tenantName
      : t("applicationBar.organization.tabs.customers");

  const usersLabel = t("applicationBar.organization.tabs.users");

  const allowManufacturerLabel = PermissionsHelper.allow(
    "Manufacturers",
    "View"
  );
  const allowDealerLabel = PermissionsHelper.allow("Dealers", "View");
  const allowCustomerLabel = PermissionsHelper.allow("Customers", "View");
  const allowUsersLabel = PermissionsHelper.allow("Users", "View");

  return (
    <div className="tabs-nav-container">
      <Tabs value={props.currentTab}>
        {allowManufacturerLabel && (
          <Tab
            label={manufacturerLabel}
            value="manufacturers"
            to="/manufacturers"
            component={Link}
          />
        )}
        {allowDealerLabel && (
          <Tab
            label={dealerLabel}
            value="dealers"
            to="/dealers"
            component={Link}
          />
        )}
        {allowCustomerLabel && (
          <Tab
            label={customerLabel}
            value="customers"
            to="/customers"
            component={Link}
          />
        )}
        {allowUsersLabel && (
          <Tab label={usersLabel} value="users" to="/users" component={Link} />
        )}
      </Tabs>
    </div>
  );
};

interface tabsNavProps {
  currentTab: "manufacturers" | "dealers" | "customers" | "users";
}
