import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { CustomFieldContext } from "./CustomFieldContext";
import { TitleWithAction } from "../../components/news";

export const CustomFieldViewBody = () => {
  const { t } = useTranslation();

  const { currentItem } = useContext(CustomFieldContext);

  if (!currentItem) return null;

  return (
    <>
      <table className="talbe table-borderless w-100 mt-4">
        <tbody>
          <tr>
            <td className="col-title py-2">{t("customFields.type")}</td>
            <td>{currentItem.typeText}</td>
          </tr>
          <tr>
            <td className="col-title py-2">{t("customFields.description")}</td>
            <td>{currentItem.description}</td>
          </tr>
        </tbody>
      </table>

      <TitleWithAction
        title={t("customFields.codes")}
        titleContainerClassName="child-sub-title"
        tooltip=""
        showButton={false}
      />

      <div className="row px-2 mt-3 error-codes-alias-list">
        {currentItem.customFieldValues.map((value, idx) => (
          <div
            className={`col-12  p-2 ${idx % 2 === 0 ? "bg-light" : ""}`}
            key={idx}
          >
            <span>{value}</span>
          </div>
        ))}
      </div>
    </>
  );
};
