import { Button, Dialog } from "@mui/material";
import { MaxbrainLogDto } from "../../../../robotcloud-shared/resource-models";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { tableGenericHandleKeyboardSubjectManager } from "../../components/news";

export const MaxbrainLogDetailModal = (props: MaxbrainLogDetailModalProps) => {
  const { t } = useTranslation();

  const [body, setBody] = useState("{}");
  const [responseContent, setResponseContent] = useState("{}");

  useEffect(() => {
    if (
      !!!props.item.responseContent ||
      props.item.responseContent.length === 0
    ) {
      setResponseContent("{}");
    } else if (props.item.responseContent.includes("{")) {
      setResponseContent(props.item.responseContent);
    } else {
      setResponseContent(`{"message":"${props.item.responseContent}"}`);
    }

    if (!!!props.item.body || props.item.body.length === 0) {
      setBody("{}");
    } else if (props.item.body.includes("{")) {
      setBody(props.item.body);
    } else {
      setBody(`{"message":"${props.item.body}"}`);
    }
  }, [props]);

  useEffect(() => {
    tableGenericHandleKeyboardSubjectManager.setData({
      caller: "MaxbrainLogDetailModal",
      handleKeyboard: props.isOpenModal,
    });
  }, [props.isOpenModal]);

  return (
    <Dialog
      className="application-modal maxbrainLog-detail-modal"
      open={props.isOpenModal}
      maxWidth="md"
      onClose={() => props.onCancel()}
    >
      <div className="modal-content">
        <div className="application-modal-header">
          <h3>{t("kemaroAcademy.detail.title")}</h3>
        </div>
        <div className="application-modal-body">
          <div className="row">
            <div className="col-12 mb-3">
              <h5>{t("kemaroAcademy.detail.id")}</h5>
              {props.item.id}
            </div>

            <div className="col-12 mb-3">
              <h5>{t("kemaroAcademy.detail.notes")}</h5>
              {props.item.notes}
            </div>

            <div className="col-12 mb-3">
              <h5>{t("kemaroAcademy.detail.body")}</h5>
              <pre className="json-container">
                {JSON.stringify(JSON.parse(body), null, 2)}
              </pre>
            </div>

            <div className="col-12 mb-3">
              <h5>{t("kemaroAcademy.detail.response")}</h5>
              <pre className="json-container">
                {JSON.stringify(JSON.parse(responseContent), null, 2)}
              </pre>
            </div>
          </div>
        </div>
        <div className="application-modal-footer">
          <Button
            className="modal-cancel-button button-with-text"
            variant="outlined"
            color="primary"
            onClick={() => props.onCancel()}
          >
            {t("common.buttons.close")}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

interface MaxbrainLogDetailModalProps {
  isOpenModal: boolean;
  onCancel: () => void;
  item: MaxbrainLogDto;
}
