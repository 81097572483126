import { Stack } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import {
  DealerCompleteDto,
  DealerContactReducedDto,
  ManufacturerReducedDto,
  UserReducedDto,
} from "../../../../robotcloud-shared/resource-models";
import { Loading } from "../../components/Loading";
import { ToastHelper } from "../../components/news/Toast.helper";
import { DealersContactsRequests } from "../../data/dealersContacts.request";
import { ErrorHelper } from "../../uiHelpers/errors.helper";
import { ManufacturerRequests } from "../../data/manufacturers.Requests";
import { UsersRequests } from "../../data/users.requests";
import { DealerViewContacts } from "./DealerViewContacts";
import { DealerViewManufacturers } from "./DealerViewManufacturers";
import { DealerViewTitle } from "./DealerViewTitle";
import { DealerViewUsers } from "./DealerViewUsers";
import { PermissionsHelper } from "../../uiHelpers/permissionsHelper";
import AuthorizationContext from "../auth/authorizationContext";

export const DealerView = (props: dealerViewProps) => {
  const [contacts, setContacts] = useState<DealerContactReducedDto[]>([]);
  const [users, setUsers] = useState<UserReducedDto[]>([]);
  const [manufacturers, setManufacturers] = useState<ManufacturerReducedDto[]>(
    []
  );
  const [isEditor, setIsEditor] = useState(false);

  const { me } = useContext(AuthorizationContext);

  useEffect(() => {
    setContacts(props.item?.dealerContacts ?? []);
    setUsers(props.item?.users ?? []);
    setManufacturers(props.item?.manufacturers ?? []);
  }, [props.item]);

  /*
  me is used to get the right permissions, avoiding a bug where a new created dealers
  shows up as readonly because of race conditions
  */
  useEffect(() => {
    setIsEditor(
      props.item != null && PermissionsHelper.editorOfDealer(props.item.id)
    );
  }, [props.item, me]);

  if (props.isFetching) {
    return <Loading isLoading={props.isFetching} />;
  }

  if (!props.item) {
    return <></>;
  }

  const updateContacts = async () => {
    try {
      setContacts(await DealersContactsRequests.getFromDealer(props.item!.id));
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }
  };

  const updateUsers = async () => {
    try {
      setUsers(await UsersRequests.getFromDealer(props.item!.id));
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }
  };

  const updateManufacturers = async () => {
    try {
      setManufacturers(
        await ManufacturerRequests.getFromDealer(props.item!.id)
      );
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }
  };

  return (
    <Stack className="dealer-view-container">
      <DealerViewTitle
        item={props.item}
        canEdit={isEditor}
        onEdit={props.onEdit}
        onDelete={props.onDelete}
      />

      <DealerViewManufacturers
        manufacturers={manufacturers}
        canEdit={isEditor}
        item={props.item}
        update={updateManufacturers}
      />

      <DealerViewUsers
        item={props.item}
        canEdit={isEditor}
        users={users}
        update={updateUsers}
      />

      <DealerViewContacts
        item={props.item}
        canEdit={isEditor}
        contacts={contacts}
        update={updateContacts}
      />
    </Stack>
  );
};

export interface dealerViewProps {
  item: DealerCompleteDto | undefined;
  onEdit: (item: DealerCompleteDto) => void;
  onDelete: (item: DealerCompleteDto) => void;
  isFetching: boolean;
}
