import { Switch } from "@mui/material";
import { useTranslation } from "react-i18next";
import { KemaroDashboardEconomyCostCalculationCompleteDto } from "../../../../../../robotcloud-shared/resource-models";
import { NavigateTo } from "../../../../components/news/NavigateTo";

export const KemaroCostCalculationViewHeader = (
  props: kemaroCostCalculationViewProps
) => {
  const { t } = useTranslation();

  return (
    <div className="row mt-4 header-container-data">
      <div className="col-4 col-xl-2">{t("common.currency")}</div>
      <div className="col-8 col-xl-10">
        {props.item.currency.abbreviation} - {props.item.currency.name}
      </div>

      <div className="col-4 col-xl-2">{t("robot.entitiesName")}</div>
      <div className="col-8 col-xl-10">
        <NavigateTo
          text={props.item.robots.toString()}
          shorCut="robots"
          isAvailable={props.item.robots > 0}
          paramters={[
            {
              name: "idKemaroDashboardEconomyCostCalculation",
              value: props.item.id,
            },
          ]}
        />
      </div>

      <div className="col-4 col-xl-2">{t("customer.entitiesName")}</div>
      <div className="col-8 col-xl-10">
        <NavigateTo
          text={props.item.customers.toString()}
          shorCut="customers"
          isAvailable={props.item.customers > 0}
          paramters={[
            {
              name: "idKemaroDashboardEconomyCostCalculation",
              value: props.item.id,
            },
          ]}
        />
      </div>

      <div className="col-4 col-xl-2">{t("common.default")}</div>
      <div className="col-8 col-xl-10 pt-1">
        <Switch
          color="primary"
          checked={props.item.isDefault}
          disabled={true}
        />
      </div>
    </div>
  );
};

interface kemaroCostCalculationViewProps {
  item: KemaroDashboardEconomyCostCalculationCompleteDto;
}
