export interface modalState {
  isOpen: boolean;
  isWorking: boolean;
  title: string;
}

export const defaultModalState: modalState = {
  isOpen: false,
  isWorking: false,
  title: "",
};

export const guidEmpty = "00000000-0000-0000-0000-000000000000";
