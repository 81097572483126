import { useEffect, useState } from "react";
import {
  KemaroDashboardEconomyCostCalculationCompleteDto,
  KemaroDashboardEconomyCostCalculationFilterCriteriaDto,
  KemaroDashboardEconomyCostCalculationListDto,
  KemaroDashboardEconomyCostCalculationParameterDto,
  KemaroDashboardEconomyCostCalculationPostDto,
} from "../../../robotcloud-shared/resource-models";
import { ToastHelper } from "../components/news";
import { OrderDto, PaginationDto, paginationDtoDefault } from "../data/common";
import { KemaroDashboardEconomyCostCalculationParametersRequests } from "../data/kemaro/KemaroDashboardEconomyCostCalculationParameters.requests";
import { KemaroDashboardEconomyCostCalculationsRequests } from "../data/kemaro/kemaroDashboardEconomyCostCalculations.requests";
import { ErrorHelper } from "../uiHelpers/errors.helper";
import { GeneralFunctions } from "../views/generalFunctions";
import { QueryParamHelper } from "../uiHelpers/queryParamHelper";
import { PermissionsHelper } from "../uiHelpers/permissionsHelper";

export const useKemaroCostCalculation = () => {
  const [isFetchingList, setIsFetchingList] = useState(false);
  const [isFetchingItem, setIsFetchingItem] = useState(false);
  const [pagination, setPagination] = useState<PaginationDto>({
    ...paginationDtoDefault,
  });
  const [dataOrder, setDataOrder] = useState<OrderDto>({
    orderColumns: "name",
    order: "Asc",
  });
  const [items, setItems] = useState<
    KemaroDashboardEconomyCostCalculationListDto[] | undefined
  >(undefined);
  const [currentId, setCurrentId] = useState<string | undefined>(undefined);
  const [currentItem, setCurrentItem] = useState<
    KemaroDashboardEconomyCostCalculationCompleteDto | undefined
  >(undefined);
  const [currentRefresh, setCurrentRefresh] = useState(false);
  const [openCreatePopup, setOpenCreatePopup] = useState(false);
  const [createPopupIsWorking, setCreatePopupIsWorking] = useState(false);
  const [textToSearch, setTextToSearch] = useState("");
  const [canAdd, setCanAdd] = useState(false);
  const [canRecalculateDashboards, setCanRecalculateDashboards] =
    useState(false);
  const [lastSearch, setLastSearch] = useState<string>("");

  const [isFirstTime, setIsFirstTime] = useState(true);

  useEffect(() => {
    setCanAdd(PermissionsHelper.allow("KemaroCostCalculations", "Create"));
    setCanRecalculateDashboards(
      PermissionsHelper.allow(
        "KemaroDashboard",
        "View",
        "Recalculate-Dashboards"
      )
    );
  }, []);

  useEffect(() => {
    let filterCriteria: KemaroDashboardEconomyCostCalculationFilterCriteriaDto =
      {
        page: pagination.currentPage,
        maxItemCount: pagination.maxItemCount,
        orderBy: dataOrder.order,
        orderColumns: dataOrder.orderColumns,
        textToSearch: textToSearch,
        id: null,
        ids: null,
      };

    const strFilterCriteria = JSON.stringify(filterCriteria);
    if (lastSearch !== strFilterCriteria) {
      if (isFirstTime) {
        const qph = new QueryParamHelper(window.location.search);
        filterCriteria.id = qph.getValue("id") ?? null;
      }

      setLastSearch(strFilterCriteria);
      getList(filterCriteria, currentItem, pagination, false);
      setIsFirstTime(false);
    }
  }, [
    pagination,
    dataOrder,
    textToSearch,
    lastSearch,
    isFirstTime,
    currentItem,
  ]);

  useEffect(() => {
    if (currentId) {
      getItem(isFetchingItem, currentItem, currentId);
    }
  }, [currentId, currentItem, currentRefresh, isFetchingItem]);

  const callGetList = async (selectFirstItem: boolean) => {
    let filterCriteria: KemaroDashboardEconomyCostCalculationFilterCriteriaDto =
      {
        page: pagination.currentPage,
        id: null,
        maxItemCount: pagination.maxItemCount,
        orderBy: dataOrder.order,
        orderColumns: dataOrder.orderColumns,
        textToSearch: textToSearch,
        ids: null,
      };

    getList(filterCriteria, currentItem, pagination, selectFirstItem);
  };

  const getList = async (
    filterCriteria: KemaroDashboardEconomyCostCalculationFilterCriteriaDto,
    ci: KemaroDashboardEconomyCostCalculationCompleteDto | undefined,
    page: PaginationDto,
    selectFirstItem: boolean
  ) => {
    setIsFetchingList(true);

    try {
      const data = await KemaroDashboardEconomyCostCalculationsRequests.getList(
        filterCriteria
      );
      setItems(data.items);

      if (data.items.length > 0) {
        if (selectFirstItem || !ci || !data.items.some((x) => x.id === ci.id)) {
          setCurrentId(data.items[0].id);
        }
      }

      GeneralFunctions.ChangePagination(page, data, setPagination);

      if (filterCriteria.id) setCurrentId(filterCriteria.id);
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsFetchingList(false);
  };

  const getItem = async (
    isFetching: boolean,
    ci: KemaroDashboardEconomyCostCalculationCompleteDto | undefined,
    id: string
  ) => {
    if (isFetching || ci?.id === id) return;

    setIsFetchingItem(true);

    try {
      const item = await KemaroDashboardEconomyCostCalculationsRequests.getById(
        id!
      );
      setCurrentItem(item);
      setCurrentId(item.id);
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsFetchingItem(false);
  };

  const addParameter = async (
    calculation: KemaroDashboardEconomyCostCalculationCompleteDto,
    parameter: KemaroDashboardEconomyCostCalculationParameterDto
  ) => {
    let result = false;

    setIsFetchingItem(true);

    try {
      await KemaroDashboardEconomyCostCalculationParametersRequests.create(
        calculation.id,
        parameter
      );

      getItem(isFetchingItem, undefined, currentItem?.id ?? "");
      result = true;
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }
    setIsFetchingItem(false);
    return result;
  };

  const editParameter = async (
    original: KemaroDashboardEconomyCostCalculationParameterDto,
    modified: KemaroDashboardEconomyCostCalculationParameterDto
  ) => {
    let result = false;

    setIsFetchingItem(true);

    try {
      await KemaroDashboardEconomyCostCalculationParametersRequests.modify(
        original,
        modified
      );

      setCurrentRefresh(!currentRefresh);
      result = true;
      getItem(isFetchingItem, undefined, currentItem?.id ?? "");
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }
    setIsFetchingItem(false);

    return result;
  };

  const deleteParameter = async (
    parameter: KemaroDashboardEconomyCostCalculationParameterDto
  ) => {
    let result = false;

    setIsFetchingItem(true);

    try {
      await KemaroDashboardEconomyCostCalculationParametersRequests.delete(
        parameter.id
      );

      getItem(isFetchingItem, undefined, currentItem?.id ?? "");
      result = true;
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }
    setIsFetchingItem(false);

    return result;
  };

  const handleCreateSave = async (
    dto: KemaroDashboardEconomyCostCalculationPostDto
  ) => {
    let result = false;

    setCreatePopupIsWorking(true);

    try {
      const item = await KemaroDashboardEconomyCostCalculationsRequests.create(
        dto
      );

      setLastSearch("--");
      setCurrentId(item.id);
      setOpenCreatePopup(false);

      result = true;
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setCreatePopupIsWorking(false);

    return result;
  };

  const handleEditCalculation = async (
    original: KemaroDashboardEconomyCostCalculationPostDto,
    modified: KemaroDashboardEconomyCostCalculationPostDto
  ) => {
    let result = false;

    setIsFetchingItem(true);

    try {
      await KemaroDashboardEconomyCostCalculationsRequests.modify(
        currentId!,
        original,
        modified
      );

      setLastSearch("--");
      getItem(isFetchingItem, undefined, currentItem?.id ?? "");
      result = true;
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }
    setIsFetchingItem(false);

    return result;
  };

  const handleDeleteCalculation = async (
    item: KemaroDashboardEconomyCostCalculationCompleteDto
  ) => {
    let result = false;
    let selectFirstItem = false;

    setIsFetchingItem(true);
    setIsFetchingList(true);

    try {
      await KemaroDashboardEconomyCostCalculationsRequests.delete(item.id);
      const nextItem =
        GeneralFunctions.nextItemFromArray<KemaroDashboardEconomyCostCalculationListDto>(
          items,
          item.id
        );

      setCurrentId(nextItem?.id ?? undefined);
      if (nextItem) await getItem(false, undefined, nextItem.id);
      else {
        setCurrentItem(undefined);
        selectFirstItem = true;
      }

      callGetList(selectFirstItem);

      result = true;
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsFetchingItem(false);
    setIsFetchingList(false);

    return result;
  };

  const handleCopyCalculation = async (
    modified: KemaroDashboardEconomyCostCalculationPostDto
  ) => {
    let result = false;

    setIsFetchingItem(true);

    try {
      const dto = await KemaroDashboardEconomyCostCalculationsRequests.copy(
        currentId!,
        modified
      );

      setLastSearch("--");
      setCurrentId(dto.id);
      result = true;
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsFetchingItem(false);

    return result;
  };

  const textSearchChange = (newTextToSearch: string) => {
    if (newTextToSearch === textToSearch) return;
    setTextToSearch(newTextToSearch);
    pageChange({
      ...pagination,
      currentPage: 1,
    });
  };

  const pageChange = (pagination: PaginationDto) => {
    setCurrentId(undefined);
    setCurrentItem(undefined);
    setPagination(pagination);
  };
  return {
    // Fetching states
    isFetchingList,
    isFetchingItem,
    // Data entities and their manipulation
    items,
    currentItem,
    addParameter,
    editParameter,
    deleteParameter,
    setCurrentId,
    // Popup states and interactions
    openCreatePopup,
    setOpenCreatePopup,
    createPopupIsWorking,
    // UI control states
    canAdd,
    canRecalculateDashboards,
    // Pagination and ordering
    pagination,
    pageChange,
    dataOrder,
    setDataOrder,
    // Search and filtering
    textSearchChange,
    // Event handlers
    handleCreateSave,
    handleEditCalculation,
    handleDeleteCalculation,
    handleCopyCalculation,
};
};
