import {
  PermissionAction,
  PermissionKeyOption,
  PermissionSubRuleTags,
  UserRoles,
} from "../../../robotcloud-shared/resource-models";
import { StorageManagerService } from "../data/storageManager.service";

/**
 * TODO: change this methods for RxJS or another thing similar for refresh when change
 * the me
 * Example when a manufacturer user create new customer in dev the user arrives before
 * the permissions update and is therefore read-only.
 */
export class PermissionsHelper {
  static allow = (
    option: PermissionKeyOption,
    action: PermissionAction,
    section?: string,
    tag?: PermissionSubRuleTags
  ) => {
    const permissions = StorageManagerService.getPermissions();
    if (!permissions) {
      return false;
    }

    const permission = permissions.find(
      (x) => x.option === option && x.action === action
    );

    if (!permission) {
      return false;
    }

    if (section) {
      const subRule = permission.subRules.find((x) => x.section === section);
      if (!subRule) {
        return false;
      }

      if (tag) {
        const subRuleTag = subRule.tags.find((x) => x === tag);
        if (!subRuleTag) {
          return false;
        }
      }
    }

    return true;
  };

  static allowFields = (
    option: PermissionKeyOption,
    action: PermissionAction,
    section?: string,
    tag?: PermissionSubRuleTags
  ) => {
    const permissions = StorageManagerService.getPermissions();
    if (!permissions) {
      return [];
    }

    const permission = permissions.find(
      (x) => x.option === option && x.action === action
    );
    return permission?.fields ?? [];
  };

  static editorOfManufacturer = (manufacturerId: string) => {
    const me = StorageManagerService.getMe();
    if (!me) {
      return false;
    }

    let editor = me.userAccessType === "Earlybyte";
    if (me.userAccessType === "Manufacturer") {
      editor = me.currentManufacturerId === manufacturerId;
    }

    return editor;
  };

  static editorOfDealer = (dealerId: string) => {
    const me = StorageManagerService.getMe();
    if (!me) {
      return false;
    }

    let editor = me.userAccessType === "Earlybyte";
    if (me.userAccessType === "Manufacturer") {
      const dealerRelation = me.dealerRelations.find((x) => x.id === dealerId);
      editor = dealerRelation !== undefined && dealerRelation.role === "Editor";
    } else if (me.userAccessType === "Dealer") {
      editor = dealerId === me.idDealer;
    }

    return editor;
  };

  static editorOfCustomer = (customerId: string) => {
    const me = StorageManagerService.getMe();
    if (!me) {
      return false;
    }

    let editor = me.userAccessType === "Earlybyte";
    if (me.userAccessType === "Manufacturer") {
      const customerRelation = me.customerRelations.find(
        (x) => x.id === customerId
      );
      editor =
        customerRelation !== undefined && customerRelation.role === "Editor";
    } else if (me.userAccessType === "Dealer") {
      const customerRelation = me.customerRelations.find(
        (x) => x.id === customerId
      );
      editor =
        customerRelation !== undefined && customerRelation.role === "Editor";
    } else if (me.userAccessType === "Customer") {
      editor = customerId === me.idCustomer;
    }

    return editor;
  };

  static roleCanModifyRole = (roleA: UserRoles, roleB: UserRoles) => {
    return (
      roleA === "Owner" ||
      roleA === roleB ||
      (roleA === "Administrator" && roleB === "Member")
    );
  };

  static currentManufacturer = () => {
    const me = StorageManagerService.getMe();
    const currentManufacturer =
      me?.manufacturerRelations.filter(
        (x) => x.id === me.currentManufacturerId
      )[0] || undefined;

    if (!currentManufacturer) return "none";

    return currentManufacturer.name.toLowerCase() === "kemaro"
      ? "kemaro"
      : "none";
  };

  static isManufacturerAdmin = () => {
    const me = StorageManagerService.getMe();
    return (
      me?.userAccessType === "Manufacturer" &&
      (me.role === "Administrator" || me.role === "Owner")
    );
  };

  static isDevelop = () => {
    switch (process.env.REACT_APP_STAGE) {
      case "CloudDev": {
        return true;
      }
      case "Development": {
        return true;
      }
      default: {
        return false;
      }
    }
  };

  static capableForManualCreationOfMissions = () => {
    switch (process.env.REACT_APP_STAGE) {
      case "CloudDev": {
        return true;
      }
      case "CloudDemo": {
        return true;
      }
      case "Development": {
        return true;
      }
      default: {
        return false;
      }
    }
  };

  static capableForManualRobotHeartbeat = () => {
    switch (process.env.REACT_APP_STAGE) {
      case "CloudDev": {
        return true;
      }
      case "CloudDemo": {
        return true;
      }
      case "Development": {
        return true;
      }
      default: {
        return false;
      }
    }
  };
}
