import { UserDataWorkflow } from "./UserDataWorkflow";
import { Workflow } from "./onBoardingService";

export const UserCompletionWorkflow = ({
  workflow,
  initialLanguage,
  onReady,
}: UserCompletionWorkflowProps) => {
  return (
    <UserDataWorkflow
      onReady={onReady}
      workflow={workflow}
      initialLanguage={initialLanguage}
    />
  );
};

interface UserCompletionWorkflowProps {
  workflow: Workflow;
  initialLanguage: string;
  onReady: (ready: boolean) => void;
}
