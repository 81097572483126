import { diff, jsonPatchPathConverter } from "just-diff";
import {
  CustomerContactReducedDto,
  CustomerReducedDto,
  KemaroCompanyStructureDto,
  KemaroCompanyStructurePostDto,
  KemaroCostCalculationLocationDto,
  KemaroCostCalculationLocationsUpdateDto,
} from "../../../../robotcloud-shared/resource-models";
import { GeneralFunctions } from "../../views/generalFunctions";
import { AxiosRequests } from "../axios.requests";
import { BaseRequest } from "../BaseRequest";

export class KemaroCompanyStructureRequests extends BaseRequest {
  static controller = "api/webapp/v1.0/Kemaro/CompanyStructure";

  static getStructure = async (idCustomer: string) => {
    const { data } = await AxiosRequests.get(
      `${KemaroCompanyStructureRequests.controller}/${idCustomer}`
    );
    return data as KemaroCompanyStructureDto[];
  };

  static getStructureList = async (customerId: string) => {
    const tree = await KemaroCompanyStructureRequests.getStructure(customerId);
    return KemaroCompanyStructureRequests.treeToList(tree);
  };

  private static treeToList = (nodes: KemaroCompanyStructureDto[]) => {
    let nodeList: KemaroCompanyStructureDto[] = [];

    nodes.forEach((x) => {
      nodeList.push(x);
      if (x.childNodes.length > 0)
        nodeList = nodeList.concat(
          KemaroCompanyStructureRequests.treeToList(x.childNodes)
        );
    });

    return nodeList;
  };

  static create = async (postDto: KemaroCompanyStructurePostDto) => {
    await AxiosRequests.post(
      `${KemaroCompanyStructureRequests.controller}`,
      postDto
    );
  };

  static modified = async (
    idCustomer: string,
    id: string,
    origin: KemaroCompanyStructurePostDto,
    modified: KemaroCompanyStructurePostDto,
    migrateAllMissions: boolean,
    migrateNewerMissions: boolean,
    migrateNewerFromMissions: Date | undefined
  ) => {
    const allowProperties = [
      "/name",
      "/description",
      "/idParentNode",
      "/idCustomerContact",
      "/idKemaroDashboardEconomyCostCalculation",
    ];

    const jsonPatch = diff(origin, modified, jsonPatchPathConverter).filter(
      (x) => allowProperties.includes(x.path)
    );

    let urlToCall = `${KemaroCompanyStructureRequests.controller}/${idCustomer}/${id}?`;

    if (migrateAllMissions) {
      urlToCall += "migrateAllMissions=true";
    } else if (migrateNewerMissions) {
      urlToCall +=
        "migrateNewerMissions=true&migrateNewerFromMissions=" +
        GeneralFunctions.dateToAnsiiDate(migrateNewerFromMissions);
    }

    await AxiosRequests.patch(urlToCall, jsonPatch);
  };

  static delete = async (
    idCustomer: string,
    idKemaroCompanyStructure: string
  ) => {
    await AxiosRequests.delete(
      `${KemaroCompanyStructureRequests.controller}/${idCustomer}/${idKemaroCompanyStructure}`
    );
  };

  static getFromCustomerContact = async (
    customerId: string,
    item: CustomerContactReducedDto
  ) => {
    const { data } = await AxiosRequests.get(
      `${KemaroCompanyStructureRequests.controller}/from-customer-contact/${customerId}/${item.id}`
    );
    return data as string[][];
  };

  static getFromCostCalculation = async (
    costCalculationId: string,
    customerId: string
  ) => {
    const { data } = await AxiosRequests.get(
      `${KemaroCompanyStructureRequests.controller}/from-cost-calculation/${costCalculationId}/${customerId}`
    );
    return data as KemaroCostCalculationLocationDto | null;
  };

  static getFromCostCalculationCustomers = async (
    costCalculationId: string,
    allCustomers: boolean
  ) => {
    const { data } = await AxiosRequests.get(
      `${
        KemaroCompanyStructureRequests.controller
      }/from-cost-calculation/${costCalculationId}/customers${
        allCustomers ? "-all" : ""
      }`
    );
    return data as CustomerReducedDto[];
  };

  static updateFromCostCalculation = async (
    costCalculationId: string,
    locatonToChange: KemaroCostCalculationLocationsUpdateDto
  ) => {
    await AxiosRequests.put(
      `${KemaroCompanyStructureRequests.controller}/from-cost-calculation/${costCalculationId}`,
      locatonToChange
    );
  };
}
