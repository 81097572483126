import { Paper, TablePagination } from "@mui/material";
import React from "react";
import { PaginationDto } from "../../data/common";
import { Loading } from "../Loading";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import * as locales from "@mui/material/locale";
import i18n from "i18next";

interface paginationProps {
  pagination: PaginationDto;
  isFetching: boolean;
  containerClassName?: string;
  onChange?: (pagination: PaginationDto) => void;
}

export const Pagination = (props: paginationProps) => {
  const handleChangePage = (event: unknown, newPage: number) => {
    if (!props.onChange) {
      return;
    }

    props.onChange({
      ...props.pagination,
      currentPage: newPage + 1,
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!props.onChange) {
      return;
    }

    props.onChange({
      ...props.pagination,
      currentPage: 1,
      maxItemCount: parseInt(event.target.value, 10),
    });
  };

  if (props.isFetching) {
    return <Loading isLoading={props.isFetching} size={40} />;
  }
  type SupportedLocales = keyof typeof locales;

  const localesMap = {
    en: "enUS",
    de: "deDE",
    fr: "frFR",
    es: "esES",
    ru: "ruRU",
    pt: "ptPT",
  };
  type localesType = keyof typeof localesMap;
  const currentLocale = localesMap[i18n.language as localesType];
  const themeWithLocale = createTheme(
    {},
    locales[currentLocale as SupportedLocales]
  );

  return (
    <Paper elevation={0} className={props.containerClassName ?? "list-pager"}>
      <ThemeProvider theme={themeWithLocale}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={props.pagination.totalItems}
          rowsPerPage={props.pagination.maxItemCount}
          page={props.pagination.currentPage - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </ThemeProvider>
    </Paper>
  );
};
