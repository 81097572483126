import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import "./customers.scss";
import { CustomerDto } from "../../../../robotcloud-shared/resource-models";
import { TableGeneric } from "../../components/news/TableGeneric/TableGeneric";
import { ApplicationBar } from "../../components/news/ApplicationBar";
import { CrudItemsPanel } from "../../components/news/CrudItemsPanel";
import { Pagination } from "../../components/news/Pagination";
import { CustomerCreateModal } from "./CustomerCreateModal";
import { CustomerView } from "./CustomerView";
import { OrganizationTabsNav } from "../../components/news/OrganizationTabsNav";
import { PermissionsHelper } from "../../uiHelpers/permissionsHelper";
import { UserRoleIcon } from "../../components/news/UserRoleIcon";
import { useTranslation } from "react-i18next";
import { TableGenericColumn } from "../../components/news/TableGeneric/models";
import useTheme from "@mui/material/styles/useTheme";
import { useCustomers } from "../../hooks";

export const Customers = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    // State variables
    me,
    idLocation,
    isLoading,
    isFetchingList,
    isFetchingItem,
    items,
    currentItem,
    dataOrder,
    pagination,
    openCreatePopup,

    // Set state functions
    setCurrentId,
    setDataOrder,
    setOpenCreatePopup,

    // Handler functions
    textSearchChange,
    pageChange,
    handleCreateSave,
    handleEdit,
    handleDelete,
  } = useCustomers();

  const [canAdd, setCanAdd] = useState(false);

  const columnsDefinition: TableGenericColumn[] = [
    {
      title: t("common.name"),
      property: "name",
    },
    {
      canSort: false,
      itemClassName: "user-role-column",
      cellTemplate: (item: CustomerDto) => (
        <UserRoleIcon keyValue={`Icon_${item.id}`} role={item.role} />
      ),
    },
  ];

  useEffect(() => {
    setCanAdd(PermissionsHelper.allow("Customers", "Create"));
  }, []);

  return (
    <Box className="page-content">
      <Box className="page-header">
        <ApplicationBar
          title={t("applicationBar.organization.title")}
          canSearch={me?.userAccessType !== "Customer"}
          search={textSearchChange}
        />
      </Box>

      <OrganizationTabsNav currentTab="customers" />

      <Box className="page-body d-flex customer-body">
        {me?.userAccessType !== "Customer" ? (
          <div
            className="customer-left-side"
            style={{
              borderRight:
                theme.palette.swarm?.customers?.bodyLeftSideBorderRight,
            }}
          >
            <CrudItemsPanel
              title={t("customer.listTitle")}
              createToolTip={t("customer.create.tooltip")}
              containerClassName="customers-list-items"
              isFetching={isFetchingList}
              items={items}
              canCreate={canAdd}
              onCreateClick={() => setOpenCreatePopup(true)}
            >
              <TableGeneric
                tableClassName=""
                showHeader={true}
                columns={columnsDefinition}
                items={items ?? []}
                idProperty="id"
                headerClick={(header, itemsOrder) => setDataOrder(itemsOrder)}
                selectionChanged={(item) => setCurrentId(item.id)}
                itemsOrder={dataOrder}
                selectId={currentItem?.id}
                isAwaiting={isFetchingItem}
                captureKeyboard
              />
            </CrudItemsPanel>

            <Pagination
              containerClassName="customers-list-pager"
              pagination={pagination}
              isFetching={isFetchingList}
              onChange={pageChange}
            />

            {openCreatePopup && (
              <CustomerCreateModal
                openPopup={openCreatePopup}
                isWorking={isLoading}
                onSave={handleCreateSave}
                onCancel={() => setOpenCreatePopup(false)}
              />
            )}
          </div>
        ) : (
          <></>
        )}

        <Box className="customers-view-container">
          <CustomerView
            item={currentItem}
            isFetching={isFetchingItem}
            startIdLocation={idLocation}
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
        </Box>
      </Box>
    </Box>
  );
};
