import { Theme } from "@mui/material/styles";
import { TableGenericColumn } from "../../../../../../components/news/TableGeneric/models";
import { DashboardSpHistoryMessageLogsDto } from "../../../../../../../../robotcloud-shared/resource-models";
import { colorFromStatus } from "./functions";
import { GeneralFunctions } from "../../../../../generalFunctions";
import SquareRoundedIcon from "@mui/icons-material/SquareRounded";

export function ColumnDefinition(
  theme: Theme,
  titles: string[]
): TableGenericColumn[] {
  return [
    {
      cellTemplate: (item: DashboardSpHistoryMessageLogsDto) => (
        <>
          <SquareRoundedIcon
            style={{ color: colorFromStatus(item.status, theme) }}
          />
        </>
      ),
    },
    {
      title: titles[0],
      canSort: false,
      property: "status",
    },
    {
      title: titles[1],
      canSort: false,
      property: "start",
      cellTemplate: (item: DashboardSpHistoryMessageLogsDto) => (
        <>{GeneralFunctions.dateToTimeDateString(item.start)}</>
      ),
    },
    {
      title: titles[2],
      canSort: false,
      property: "end",
      cellTemplate: (item: DashboardSpHistoryMessageLogsDto) => (
        <>{GeneralFunctions.dateToTimeDateString(item.end)}</>
      ),
    },
  ];
}
