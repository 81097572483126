import { KemaroMissionRunDto } from "../../../../../robotcloud-shared/resource-models";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { KemaroMissionViewRunSummary } from "./KemaroMissionViewRunSummary";
import { KemaroMissionViewRunDetail } from "./KemaroMissionViewRunDetail";
import { useTranslation } from "react-i18next";
import useTheme from "@mui/material/styles/useTheme";

export const KemaroMissionViewRun = (props: kemaroMissionViewRunProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  let hasErrors =
    props.run.cleaningErrors && props.run.cleaningErrors.length > 0;
  let status = t("mission.details.statusSuccess");
  let runIcon = (
    <CheckCircleOutlinedIcon
      className="run-success"
      sx={{ color: "swarm.kemaroMissions.viewRunsRunSuccess" }}
    />
  );

  if (hasErrors) {
    status = t("mission.details.statusFail");
    runIcon = (
      <CancelOutlinedIcon
        className="run-error"
        sx={{ color: "swarm.kemaroMissions.viewRunsRunError" }}
      />
    );
  }

  const currentName = `${props.baseName}${props.number}`;
  const nextName = `${props.baseName}${props.number + 1}`;

  return (
    <Accordion
      elevation={0}
      className={
        props.total === props.number ||
        props.expandedRuns.indexOf(currentName) >= 0 ||
        props.expandedRuns.indexOf(nextName) >= 0
          ? "accordion"
          : "accordion-with-out-bottom"
      }
      style={{
        border:
          theme.palette.swarm?.kemaroMissions
            ?.viewRunsAccordionWithoutBottomBorder,
      }}
      onChange={(event, newValue) =>
        props.expandedChange(currentName, newValue)
      }
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${props.number}a-content`}
        id={`panel${props.number}a-header`}
      >
        <KemaroMissionViewRunSummary
          key={"summary_" + props.number}
          current={props.number}
          total={props.total}
          run={props.run}
          icon={runIcon}
          status={status}
          hasErrors={hasErrors}
        />
      </AccordionSummary>
      <AccordionDetails
        className="accordion-detail d-block"
        style={{
          borderBottom:
            theme.palette.swarm?.kemaroMissions?.viewRunsAccordionDetailBorder,
        }}
      >
        <KemaroMissionViewRunDetail
          key={"datail_" + props.number}
          current={props.number}
          total={props.total}
          run={props.run}
          icon={runIcon}
          status={status}
          hasErrors={hasErrors}
          errorCodesAsButton={props.errorCodesAsButton}
        />
      </AccordionDetails>
    </Accordion>
  );
};

interface kemaroMissionViewRunProps {
  baseName: string;
  number: number;
  total: number;
  run: KemaroMissionRunDto;
  expandedRuns: string[];
  errorCodesAsButton: boolean;
  expandedChange: (name: string, value: boolean) => void;
}
