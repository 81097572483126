import PlayCircleOutline from "@mui/icons-material/PlayCircleOutline";
import { Edit, DeleteForever } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LinkIcon from "@mui/icons-material/Link";
import { IconButton, Tooltip } from "@mui/material";
import { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  WorkflowTranslatedDataDto,
  WorkflowTranslatedDataFilterCriteriaDto,
} from "../../../../../robotcloud-shared/resource-models";
import {
  CrudItemsPanel,
  TableGeneric,
  ToastHelper,
  TooltipButton,
  YesNoOkDialog,
} from "../../../components/news";
import { TableGenericColumn } from "../../../components/news/TableGeneric/models";
import {
  PaginationDto,
  paginationDtoDefault,
  OrderDto,
} from "../../../data/common";
import { WorkflowTranslatedDatasRequests } from "../../../data/workflowTranslatedDatas.request";
import { ErrorHelper } from "../../../uiHelpers/errors.helper";
import AuthorizationContext from "../../auth/authorizationContext";
import OnBoardingContext from "../OnBoardingContext";
import { WorkflowTranslatedDataDialog } from "./WorkflowTranslatedDataDialog";
import { WorkflowTranslatedDataFileView } from "./WorkflowTranslatedDataFileView";
import { OnBoardingAssistant } from "../OnBoardingAssistant";
import {
  Process,
  getProcessFromReducedDto,
  getLanguage,
} from "../onBoardingService";

export const WorkflowTranslatedDataPanel = (): JSX.Element => {
  const { t } = useTranslation();
  const { me } = useContext(AuthorizationContext);
  const {
    currentSelectedItem,
    process,
    manufacturerName,
    setCurrentSelectedItemTranslations,
  } = useContext(OnBoardingContext);
  const [isFetchingList, setIsFetchingList] = useState(false);
  const [items, setItems] = useState<WorkflowTranslatedDataDto[] | undefined>(
    undefined
  );
  const [currentItem, setCurrentItem] = useState<
    WorkflowTranslatedDataDto | undefined
  >(undefined);
  const [pagination, setPagination] = useState<PaginationDto>({
    ...paginationDtoDefault,
  });
  const [dataOrder, setDataOrder] = useState<OrderDto>({
    orderColumns: "languageCode",
    order: "Asc",
  });
  const [lastSearch, setLastSearch] = useState("--");
  const [dialogOpened, setDialogOpened] = useState<boolean>(false);
  const [openDeletePopup, setOpenDeletePopup] = useState<boolean>(false);
  const [openDemo, setOpenDemo] = useState(false);

  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noreferrer");
  };

  const columnsDefinition: TableGenericColumn[] = [
    {
      title: t("onBoarding.workflow.workflowTranslatedData.language"),
      property: "languageCode",
      itemClassName: "col-language text-overflow-ellipsis",
      headerClassName: "col-language  text-overflow-ellipsis",
      cellTemplate: (item: WorkflowTranslatedDataDto) => (
        <span>{`${getLanguage(item.languageCode)?.name} (${
          item.languageCode
        }) `}</span>
      ),
    },
    {
      title: t("onBoarding.workflow.workflowTranslatedData.title"),
      property: "title",
      headerClassName: "col-title  text-overflow-ellipsis",
      itemClassName: "col-title text-overflow-ellipsis",
    },
    {
      title: "",
      property: "",
      itemClassName: "text-end tool-bar",
      headerClassName: "tool-bar",
      cellTemplate: (item: WorkflowTranslatedDataDto) => (
        <>
          <TooltipButton
            tooltip={t("onBoarding.demo") ?? ""}
            onClick={() => setOpenDemo(true)}
          >
            <PlayCircleOutline color="primary" />
          </TooltipButton>
          {currentSelectedItem?.type === "file" && (
            <Tooltip
              placement="left"
              title={
                <>
                  <WorkflowTranslatedDataFileView
                    workflowTranslatedDataId={item.id}
                  />
                </>
              }
            >
              <IconButton onClick={() => setCurrentItem(item)}>
                <VisibilityIcon color="primary" />
              </IconButton>
            </Tooltip>
          )}
          {currentSelectedItem?.type === "link" && item.linkUrl && (
            <TooltipButton
              className="ml-4"
              aria-label="edit"
              tooltip={item.linkUrl}
              onClick={() => {
                setCurrentItem(item);
                openInNewTab(item.linkUrl ?? "");
              }}
            >
              <LinkIcon color="primary" />
            </TooltipButton>
          )}
          <TooltipButton
            className="ml-4"
            aria-label="edit"
            tooltip={t("viewHeaderButtons.edit")}
            onClick={() => {
              setCurrentItem(item);
              setDialogOpened(true);
            }}
          >
            <Edit color="primary" />
          </TooltipButton>
          <TooltipButton
            aria-label="delete"
            tooltip={t("viewHeaderButtons.delete")}
            onClick={async () => {
              setCurrentItem(item);
              setOpenDeletePopup(true);
            }}
          >
            <DeleteForever color="error" />
          </TooltipButton>
        </>
      ),
    },
  ];

  const getColumnsDefinition = () => {
    if (
      currentSelectedItem?.type === "file" ||
      currentSelectedItem?.type === "link"
    ) {
      return columnsDefinition;
    } else {
      return columnsDefinition.filter((x) => x.property !== "agreement");
    }
  };

  useEffect(() => {
    const getList = async (
      filterCriteria: WorkflowTranslatedDataFilterCriteriaDto
    ) => {
      setIsFetchingList(true);

      try {
        const data = await WorkflowTranslatedDatasRequests.getList(
          filterCriteria
        );
        setItems(data.items);
        if (data.items.length > 0 && !currentItem)
          setCurrentItem(data.items[0]);

        if (
          pagination.currentPage !== data.currentPage ||
          pagination.totalItems !== data.totalItems
        ) {
          const newPagination = {
            ...pagination,
            currentPage: data.currentPage,
            totalItems: data.totalItems,
          };

          setPagination(newPagination);
        }
      } catch (error) {
        ToastHelper.errors(ErrorHelper.process(error));
      }

      setIsFetchingList(false);
    };

    const filterCriteria: WorkflowTranslatedDataFilterCriteriaDto = {
      page: pagination.currentPage,
      maxItemCount: 9999,
      orderBy: dataOrder.order,
      orderColumns: dataOrder.orderColumns,
      id: null,
      idWorkflow: currentSelectedItem?.id ?? null,
    };

    const strFilterCriteria = JSON.stringify(filterCriteria);
    if (lastSearch === strFilterCriteria) {
      return;
    }

    setLastSearch(strFilterCriteria);

    getList(filterCriteria);
  }, [pagination, dataOrder, lastSearch, currentItem, currentSelectedItem, me]);

  useEffect(() => {
    setCurrentSelectedItemTranslations(items ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const itemClick = (item: WorkflowTranslatedDataDto) => {
    setCurrentItem(item);
  };

  const refresh = () => {
    setLastSearch("--");
  };

  const getDemoProcess = (): Process[] => {
    if (process && currentSelectedItem) {
      return [
        getProcessFromReducedDto(
          process,
          [currentSelectedItem],
          manufacturerName,
          items ?? []
        ),
      ];
    } else {
      return [];
    }
  };

  return (
    <>
      <CrudItemsPanel
        title={t("onBoarding.workflow.workflowTranslatedDataPanelTitle")}
        titleContainerClassName="header-line m-0"
        containerClassName="crud-panel"
        isFetching={isFetchingList}
        items={items}
        canCreate={true}
        createToolTip={t(
          "onBoarding.workflow.workflowTranslatedData.create.tooltip"
        )}
        onCreateClick={() => {
          setCurrentItem(undefined);
          setDialogOpened(true);
        }}
        hideNoItems={true}
      >
        <TableGeneric
          tableClassName="workflow-translated-data-panel"
          columns={getColumnsDefinition()}
          items={items ?? []}
          idProperty="id"
          headerClick={(header, itemsOrder) => setDataOrder(itemsOrder)}
          selectionChanged={itemClick}
          itemsOrder={dataOrder}
        />
      </CrudItemsPanel>
      {currentSelectedItem && dialogOpened && (
        <WorkflowTranslatedDataDialog
          idWorkflow={currentSelectedItem.id}
          workflowTranslatedData={currentItem}
          type={currentSelectedItem.type}
          openPopup={dialogOpened}
          onSaved={(workflowTranslatedDataDto?: WorkflowTranslatedDataDto) => {
            refresh();
            setDialogOpened(false);
          }}
          onCancel={() => setDialogOpened(false)}
        />
      )}
      {currentItem && openDeletePopup && (
        <YesNoOkDialog
          open={openDeletePopup}
          size="md"
          type="yesNo"
          title={t("onBoarding.workflow.workflowTranslatedData.delete.title")}
          message={`${t(
            "onBoarding.workflow.workflowTranslatedData.delete.warning"
          )}`}
          onYes={async () => {
            try {
              await WorkflowTranslatedDatasRequests.delete(currentItem.id);
              setOpenDeletePopup(false);
              refresh();
            } catch (error) {
              ToastHelper.errors(ErrorHelper.process(error));
            }
          }}
          onNo={() => setOpenDeletePopup(false)}
          isWorking={isFetchingList}
        />
      )}
      {openDemo && currentSelectedItem && process && (
        <OnBoardingAssistant
          openPopup={openDemo}
          processLink={undefined}
          isWorking={false}
          demo={true}
          demoLanguage={currentItem?.languageCode}
          processes={getDemoProcess()}
          onClose={() => setOpenDemo(false)}
        />
      )}
    </>
  );
};
