import { useState, useContext, useEffect } from "react";
import {
  ManufacturerReducedDto,
  ManufacturerCompleteDto,
  ManufacturerFilterCriteriaDto,
  ApiLoginCompleteDto,
  ManufacturerPostDto,
} from "../../../robotcloud-shared/resource-models";
import { ToastHelper } from "../components/news";
import { PaginationDto, paginationDtoDefault, OrderDto } from "../data/common";
import { ManufacturerRequests } from "../data/manufacturers.Requests";
import { ErrorHelper } from "../uiHelpers/errors.helper";
import { QueryParamHelper } from "../uiHelpers/queryParamHelper";
import AuthorizationContext from "../views/auth/authorizationContext";
import { GeneralFunctions } from "../views/generalFunctions";

export const useManufacturers = () => {
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [isFetchingList, setIsFetchingList] = useState(false);
  const [isFetchingItem, setIsFetchingItem] = useState(false);
  const [pagination, setPagination] = useState<PaginationDto>({
    ...paginationDtoDefault,
  });
  const [dataOrder, setDataOrder] = useState<OrderDto>({
    orderColumns: "name",
    order: "Asc",
  });
  const [items, setItems] = useState<ManufacturerReducedDto[]>(
    [] as ManufacturerReducedDto[]
  );
  const [currentId, setCurrentId] = useState<string | undefined>(undefined);
  const [currentItem, setCurrentItem] = useState<
    ManufacturerCompleteDto | undefined
  >(undefined);
  const [openCreatePopup, setOpenCreatePopup] = useState(false);
  const [textToSearch, setTextToSearch] = useState("");
  const [lastSearch, setLastSearch] = useState<string>("");

  const { me, currentManufacturer } = useContext(AuthorizationContext);
  useEffect(() => {
    let filterCriteria: ManufacturerFilterCriteriaDto = {
      page: pagination.currentPage,
      id: null,
      maxItemCount: pagination.maxItemCount,
      orderBy: dataOrder.order,
      orderColumns: dataOrder.orderColumns,
      textToSearch: textToSearch,
    };

    const strFilterCriteria = JSON.stringify(filterCriteria);
    if (lastSearch !== strFilterCriteria) {
      setLastSearch(strFilterCriteria);

      if (isFirstTime) {
        const qph = new QueryParamHelper(window.location.search);
        const id = qph.getValue("id");
        if (id) {
          filterCriteria = { ...filterCriteria, id: id };
          setCurrentId(id);
        }
      }

      getList(
        filterCriteria,
        pagination,
        me,
        currentManufacturer,
        currentItem,
        false
      );
    }

    setIsFirstTime(false);
  }, [
    pagination,
    dataOrder,
    textToSearch,
    me,
    currentManufacturer,
    lastSearch,
    isFirstTime,
    currentItem,
  ]);

  useEffect(() => {
    if (currentId && (!currentItem || currentItem?.id !== currentId)) {
      getManufacturer(isFetchingItem, currentItem, currentId);
    }
  }, [currentId, me, currentItem, isFetchingItem]);

  const getManufacturer = async (
    isFetching: boolean,
    ci: ManufacturerCompleteDto | undefined,
    id: string
  ) => {
    if (isFetching || ci?.id === id) return;

    setIsFetchingItem(true);

    try {
      const item = await ManufacturerRequests.getById(id!);
      setCurrentId(item.id);
      setCurrentItem(item);
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsFetchingItem(false);
  };

  const callGetList = async (selectFirstItem: boolean | undefined = false) => {
    let filterCriteria: ManufacturerFilterCriteriaDto = {
      page: pagination.currentPage,
      id: null,
      maxItemCount: pagination.maxItemCount,
      orderBy: dataOrder.order,
      orderColumns: dataOrder.orderColumns,
      textToSearch: textToSearch,
    };

    getList(
      filterCriteria,
      pagination,
      me,
      currentManufacturer,
      currentItem,
      selectFirstItem
    );
  };

  const getList = async (
    filterCriteria: ManufacturerFilterCriteriaDto,
    pag: PaginationDto,
    me: ApiLoginCompleteDto | undefined,
    manufacturer: ManufacturerReducedDto | undefined,
    ci: ManufacturerCompleteDto | undefined,
    selectFirstItem: boolean | undefined
  ) => {
    setIsFetchingList(true);

    try {
      const data = await ManufacturerRequests.getList(filterCriteria);
      setItems(data.items);

      if (data.items.length > 0) {
        if (selectFirstItem || !ci || !data.items.some((x) => x.id === ci.id)) {
          setCurrentId(data.items[0].id);
        }
      }

      GeneralFunctions.ChangePagination(pag, data, setPagination);

      if (
        me?.userAccessType === "Manufacturer" &&
        manufacturer &&
        data.items.some((x) => x.id === manufacturer.id)
      ) {
        setCurrentId(manufacturer.id);
      }
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsFetchingList(false);
  };
  const handleEdit = async (item: ManufacturerCompleteDto) => {
    setIsFetchingItem(true);

    try {
      setCurrentItem(await ManufacturerRequests.modify(currentItem!, item));

      callGetList();
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsFetchingItem(false);
  };

  const handleDelete = async (item: ManufacturerCompleteDto) => {
    setIsFetchingItem(true);
    let selectFirstItem = false;

    try {
      await ManufacturerRequests.softDelete(item.id);
      const nextItem =
        GeneralFunctions.nextItemFromArray<ManufacturerReducedDto>(
          items,
          item.id
        );

      setCurrentId(nextItem?.id ?? undefined);
      if (nextItem) await getManufacturer(false, undefined, nextItem.id);
      else {
        selectFirstItem = true;
        setCurrentItem(undefined);
      }

      callGetList(selectFirstItem);
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsFetchingItem(false);
  };

  const handleCreateSave = async (postDto: ManufacturerPostDto) => {
    setIsFetchingList(true);

    let result = false;
    setOpenCreatePopup(false);

    try {
      const newItem = await ManufacturerRequests.create(postDto);
      setCurrentItem(newItem);
      setCurrentId(newItem.id);

      result = true;

      callGetList();
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsFetchingList(false);
    return result;
  };

  const textSearchChange = (newTextToSearch: string) => {
    if (newTextToSearch === textToSearch) return;
    setTextToSearch(newTextToSearch);
    pageChange({
      ...pagination,
      currentPage: 1,
    });
  };

  const pageChange = (pagination: PaginationDto) => {
    setCurrentId(undefined);
    setCurrentItem(undefined);
    setPagination(pagination);
  };

  return {
    // User and item states
    me,
    items,
    setItems,
    currentItem,
    setCurrentItem,
    currentId,
    setCurrentId,
    // Fetching states
    isFetchingList,
    setIsFetchingList,
    isFetchingItem,
    // Popup states and interactions
    openCreatePopup,
    setOpenCreatePopup,
    // Pagination and ordering
    pagination,
    pageChange,
    dataOrder,
    setDataOrder,
    // Search and filtering
    textSearchChange,
    // Event handlers
    handleCreateSave,
    handleEdit,
    handleDelete,
};
};
