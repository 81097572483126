import { Button, Dialog, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { KemaroDashboardEconomyCostCalculationParameterDto } from "../../../../../../robotcloud-shared/resource-models";
import { Loading } from "../../../../components/Loading";
import { NumericInput } from "../../../../components/news/NumericInput";
import { GeneralFunctions } from "../../../generalFunctions";
import { useTranslation } from "react-i18next";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  TooltipContainer,
  tableGenericHandleKeyboardSubjectManager,
} from "../../../../components/news";

export const KemaroCostCalculationParameterModal = (
  props: kemaroCostCalculationParameterModalProps
) => {
  const { t } = useTranslation();

  const [dto, setDto] =
    useState<KemaroDashboardEconomyCostCalculationParameterDto>({
      ...defaultKemaroDashboardEconomyCostCalculationParameterDto,
    });

  useEffect(() => {
    setDto(
      props.dto ?? {
        ...defaultKemaroDashboardEconomyCostCalculationParameterDto,
      }
    );
  }, [props.dto]);

  useEffect(() => {
    tableGenericHandleKeyboardSubjectManager.setData({
      caller: "KemaroCostCalculationParameterModal",
      handleKeyboard: props.isOpenModal,
    });
  }, [props.isOpenModal]);

  return (
    <Dialog
      className="application-modal"
      open={props.isOpenModal}
      maxWidth="md"
      onClose={() => props.onCancel()}
    >
      <div className="modal-content">
        <div className="application-modal-header">
          <h3 className="modal-title">{props.title}</h3>
        </div>
        <div className="application-modal-body">
          {props.isWorking ? (
            <Loading isLoading={props.isWorking} />
          ) : (
            <>
              <div className="row py-3">
                <div className="col-4 input-label">
                  {t("costCalculation.parameters.validFrom")}
                  <TooltipContainer
                    text={t("costCalculation.parameters.validFromExplanation")}
                  >
                    <InfoOutlinedIcon color="primary" className="pe-2 ms-2" />
                  </TooltipContainer>
                </div>
                <div className="col-4"></div>
                <div className="col-4">
                  <TextField
                    className="w-100 pt-0"
                    type="date"
                    label=""
                    fullWidth
                    variant="standard"
                    margin="dense"
                    hiddenLabel
                    value={GeneralFunctions.dateToAnsiiDate(dto.from)}
                    onChange={(event) =>
                      setDto({
                        ...dto,
                        from: event.target.value
                          ? new Date(event.target.value)
                          : new Date(),
                      })
                    }
                  />
                </div>
              </div>

              <div className="row pt-3 mb-3 column-titles">
                <div className="col-4"></div>
                <div className="col-4 input-label">{t("common.manual")}</div>
                <div className="col-4 input-label">{t("robot.entityName")}</div>
              </div>
              <div className="row pb-3">
                <div className="col-4 input-label">
                  {t("cost.hourlyRate")} [{props.currency}
                  /h]
                </div>
                <div className="col-4">
                  <NumericInput
                    value={dto.manualHourlyRate}
                    decimalPositions={2}
                    ret={(x) => setDto({ ...dto, manualHourlyRate: x })}
                  />
                </div>
                <div className="col-4"></div>

                <div className="col-4 input-label">
                  {t("performance.cleaningPerformance")} [m<sup>2</sup>/h]
                </div>
                <div className="col-4">
                  <NumericInput
                    value={dto.manualCleaningPerformance}
                    ret={(x) =>
                      setDto({ ...dto, manualCleaningPerformance: x })
                    }
                  />
                </div>
                <div className="col-4"></div>

                <div className="col-4 input-label">
                  {t("cost.machineCost")} [{props.currency}]
                </div>
                <div className="col-4">
                  <NumericInput
                    value={dto.manualMachineCost}
                    decimalPositions={2}
                    ret={(x) => setDto({ ...dto, manualMachineCost: x })}
                  />
                </div>
                <div className="col-4">
                  <NumericInput
                    value={dto.robotMachineCost}
                    decimalPositions={2}
                    ret={(x) => setDto({ ...dto, robotMachineCost: x })}
                  />
                </div>

                <div className="col-4 input-label">
                  {t("cost.serviceCost")} [{props.currency}/y]
                </div>
                <div className="col-4">
                  <NumericInput
                    value={dto.manualServiceCost}
                    decimalPositions={2}
                    ret={(x) => setDto({ ...dto, manualServiceCost: x })}
                  />
                </div>
                <div className="col-4">
                  <NumericInput
                    value={dto.robotServiceCost}
                    decimalPositions={2}
                    ret={(x) => setDto({ ...dto, robotServiceCost: x })}
                  />
                </div>

                <div className="col-4 input-label">
                  {t("cost.machineLifetime")} [y]
                </div>
                <div className="col-4">
                  <NumericInput
                    value={dto.manualMachineLifetime}
                    decimalPositions={1}
                    ret={(x) => setDto({ ...dto, manualMachineLifetime: x })}
                  />
                </div>
                <div className="col-4">
                  <NumericInput
                    value={dto.robotMachineLifetime}
                    decimalPositions={1}
                    ret={(x) => setDto({ ...dto, robotMachineLifetime: x })}
                  />
                </div>

                <div className="col-4 input-label">
                  {t("cost.cloudCost")} [{props.currency}/y]
                </div>
                <div className="col-4"></div>
                <div className="col-4">
                  <NumericInput
                    value={dto.robotCloudCost}
                    ret={(x) => setDto({ ...dto, robotCloudCost: x })}
                  />
                </div>

                <div className="col-4 input-label">
                  {t("cost.inefficiencyFactor")} [x]
                </div>
                <div className="col-4"></div>
                <div className="col-4">
                  <NumericInput
                    value={dto.robotInefficiencyFactor}
                    decimalPositions={2}
                    ret={(x) => setDto({ ...dto, robotInefficiencyFactor: x })}
                  />
                </div>
              </div>
            </>
          )}
        </div>
        {props.isWorking ? (
          <div className="application-modal-footer"></div>
        ) : (
          <div className="application-modal-footer">
            <Button
              variant="contained"
              className="button-with-text"
              color="primary"
              onClick={() => props.onSave(dto)}
            >
              {t("common.buttons.save")}
            </Button>

            <Button
              className="modal-cancel-button button-with-text"
              variant="outlined"
              color="primary"
              onClick={() => props.onCancel()}
            >
              {t("common.buttons.cancel")}
            </Button>
          </div>
        )}
      </div>
    </Dialog>
  );
};

interface kemaroCostCalculationParameterModalProps {
  isOpenModal: boolean;
  isWorking: boolean;
  title: string;
  currency: string;
  dto: KemaroDashboardEconomyCostCalculationParameterDto | undefined;
  onSave: (dto: KemaroDashboardEconomyCostCalculationParameterDto) => void;
  onCancel: () => void;
}

const defaultKemaroDashboardEconomyCostCalculationParameterDto: KemaroDashboardEconomyCostCalculationParameterDto =
  {
    id: "",
    idKemaroDashboardEconomyCostCalculation: "",
    from: new Date(),
    manualHourlyRate: 0,
    manualCleaningPerformance: 0,
    manualMachineCost: 0,
    manualServiceCost: 0,
    manualMachineLifetime: 0,
    robotMachineCost: 0,
    robotServiceCost: 0,
    robotMachineLifetime: 0,
    robotCloudCost: 0,
    robotInefficiencyFactor: 0,
    isTheFirstOne: false,
  };
