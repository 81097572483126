import {
  CompanyStructureReducedDto,
  CountryDto,
  CustomerContactDto,
  CustomerDto,
  DealerDto,
  KemaroDashboardEconomyCostCalculationReducedDto,
  KemaroRobotListDto,
  RobotModelReducedDto,
} from "../../../robotcloud-shared/resource-models";
import { BadgeItem } from "../components/news/BadgesPresenter";
import { CustomFieldAutocompleteDto } from "../data/common";
import { GeneralFunctions } from "./generalFunctions";

export class BadgeHelper {
  static kemaroRobotsToBadgeItem = (kemaroRobots: KemaroRobotListDto[]) =>
    kemaroRobots.map((x) => {
      return {
        id: x.id,
        type: "robot",
        text: x.name,
        style: "robot",
        source: x,
      } as BadgeItem;
    });

  static dealersToBadgeItem = (dealers: DealerDto[]) =>
    dealers.map((x) => {
      return {
        id: x.id,
        type: "dealer",
        text: x.name,
        style: "dealer",
        source: x,
      } as BadgeItem;
    });

  static customersToBadgeItem = (customers: CustomerDto[]) =>
    customers.map((x) => {
      return {
        id: x.id,
        type: "customer",
        text: x.name,
        style: "customer",
        source: x,
      } as BadgeItem;
    });

  static customerContactsToBadgeItem = (
    customerContacts: CustomerContactDto[]
  ) =>
    customerContacts.map((x) => {
      return {
        id: x.id,
        type: "customerContact",
        text: `${x.firstName} ${x.sureName}`,
        style: "contact",
        source: x,
      } as BadgeItem;
    });

  static countriesToBadgeItem = (countries: CountryDto[]) =>
    countries.map((x) => {
      return {
        id: x.id,
        type: "country",
        text: x.name,
        style: "country",
        source: x,
      } as BadgeItem;
    });

  static robotModelsToBadgeItem = (countries: RobotModelReducedDto[]) =>
    countries.map((x) => {
      return {
        id: x.id,
        type: "robotModel",
        text: x.name,
        style: "model",
        source: x,
      } as BadgeItem;
    });

  static serialNumbersToBadgeItem = (serialNumbers: string[]) =>
    serialNumbers.map((x) => {
      return {
        id: x,
        type: "serialNumber",
        text: x,
        style: "serial-number",
        source: x,
      } as BadgeItem;
    });

  static namesToBadgeItem = (names: string[]) =>
    names.map((x) => {
      return {
        id: x,
        type: "name",
        text: x,
        style: "name",
        source: x,
      } as BadgeItem;
    });

  static companyStructuresToBadgeItem = (
    companyStructurs: CompanyStructureReducedDto[]
  ) =>
    companyStructurs.map((x) => {
      return {
        id: x.id,
        type: "companyStructure",
        text: x.name,
        style: "company-structure",
        source: x,
      } as BadgeItem;
    });

  static kemaroDashboardEconomyCostCalculationToBadgeItem = (
    countries: KemaroDashboardEconomyCostCalculationReducedDto[]
  ) =>
    countries.map((x) => {
      return {
        id: x.id,
        type: "kemaroCostCalculation",
        text: x.name,
        style: "dashboard-calculation",
        source: x,
      } as BadgeItem;
    });

  static zoneNamesToBadgeItem = (texts: string[]) =>
    texts.map((x) => {
      return {
        id: x,
        type: "zoneNames",
        text: x,
        style: "zone-names",
        source: x,
      } as BadgeItem;
    });

  static roomNamesToBadgeItem = (texts: string[]) =>
    texts.map((x) => {
      return {
        id: x,
        type: "roomNames",
        text: x,
        style: "room-names",
        source: x,
      } as BadgeItem;
    });

  static errorCodeToBadgeItem = (errorCodes: CustomFieldAutocompleteDto[]) =>
    mapCustomField(errorCodes, "errorcodes", "error-codes");

  static stopReasonToBadgeItem = (stopReason: CustomFieldAutocompleteDto[]) =>
    mapCustomField(stopReason, "stopReason", "stop-reason");

  static startReasonToBadgeItem = (startReason: CustomFieldAutocompleteDto[]) =>
    mapCustomField(startReason, "startReason", "start-reason");

  static cleaningModeToBadgeItem = (
    cleaningMode: CustomFieldAutocompleteDto[]
  ) => mapCustomField(cleaningMode, "cleaningMode", "cleaning-mode");
}

function mapCustomField(
  items: CustomFieldAutocompleteDto[],
  type: string,
  style: string
) {
  return items.map((x) => {
    return {
      id: x.code,
      type: type,
      text: !GeneralFunctions.isBlank(x.text) ? x.text : x.code,
      style: style,
      source: x,
    } as BadgeItem;
  });
}
