import { Autocomplete, TextField } from "@mui/material";
import { ReactElement, useState } from "react";
import { GeneralFunctions } from "../../views/generalFunctions";
import { Loading } from "../Loading";
import "./emailSend.scss";
import { useTranslation } from "react-i18next";
import useTheme from "@mui/material/styles/useTheme";

export const EmailSend = (props: emailSendProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [selectedAccount, setSelectedAccount] = useState("");
  const [isWorking, setIsWorking] = useState(false);

  const sendHandle = async () => {
    if (!props.send) {
      return;
    }

    setIsWorking(true);
    await props.send(selectedAccount);
    setIsWorking(false);
  };

  const changeHandle = (newValue: string | null) => {
    setSelectedAccount(newValue ?? "");
  };

  if (isWorking) {
    return <Loading isLoading={isWorking} size={40} />;
  }
  const isDisabled = !GeneralFunctions.emailValidation(selectedAccount);
  return (
    <div className="d-flex email-sender">
      <div className="flex-fill">
        <Autocomplete
          size="small"
          freeSolo
          value={selectedAccount}
          onChange={(event, newValue) => changeHandle(newValue)}
          onInputChange={(event, newValue) => changeHandle(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              label=""
              placeholder={props.placeholder}
              fullWidth
              variant="standard"
              margin="dense"
              hiddenLabel
            />
          )}
          options={(props.emailAccounts ?? []).map((x) => x)}
        />
      </div>

      <div>
        <button
          onClick={sendHandle}
          className={
            "btn action-button ms-4 button-with-text" +
            (props.children ? " me-3" : "")
          }
          style={{
            color:
              theme.palette.swarm?.emailSend?.[
                isDisabled ? "disabled" : "button"
              ],
            background:
              theme.palette.swarm?.emailSend?.[
                isDisabled ? "disabledBackground" : "buttonBackground"
              ],
          }}
          disabled={isDisabled}
          data-hover={"sendmail"}
        >
          <style>{`[data-hover="sendmail"]:hover {
        background: ${theme.palette.swarm?.emailSend?.hoverBackground} !important;
        color: ${theme.palette.swarm?.emailSend?.hover} !important;
    }`}</style>
          {t("common.buttons.send")}
        </button>

        {props.children}
      </div>
    </div>
  );
};

interface emailSendProps {
  emailAccounts?: string[];
  placeholder?: string;
  send?: (account: string) => Promise<void>;
  selectionChange?: (account: string | null) => Promise<void>;
  children?: ReactElement | never[];
}

EmailSend.defaultProps = {
  placeholder: "Type to select an E-mail",
};
