import {
  DashboardEconomyUiConfigurationDto,
  KemaroDashboardEconomyCostStatisticsDto,
} from "../../../../../../robotcloud-shared/resource-models";
import { KemaroDashboardEconomyStatisticsChart } from "./KemaroDashboardEconomyStatisticsChart";
import { useTranslation } from "react-i18next";
import useTheme from "@mui/material/styles/useTheme";
import { NativeSelect } from "@mui/material";

export const KemaroDashboardEconomyStatistics = (
  props: KemaroDashboardEconomyStatisticsProps
) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <div className="col-12 mb-3 pe-4 pe-xxl-0">
      <div
        className="row kemaro-dashboard-economy-statistics"
        style={{
          border: theme.palette.swarm?.kemaroDashboard?.economyStatisticsBorder,
          boxShadow:
            theme.palette.swarm?.kemaroDashboard?.economyStatisticsBoxShadow,
        }}
      >
        <div className="title col-12">{t("performance.title")}</div>
        <div className="weeks-filter col-12 text-end">
          <span>{t("performance.showLast")}</span>
          <NativeSelect
            className="px-3"
            value={props.staticWeeksToShow}
            onChange={(event) =>
              props.staticWeeksToShowChange(parseInt(event.target.value, 10))
            }
          >
            {props.uiConfiguration.staticWeeks.map((x) => (
              <option key={x} value={x}>
                {x}
              </option>
            ))}
          </NativeSelect>
          <span>{t("performance.weeks")}</span>
        </div>

        <div className="col-12 col-xxl-6 mb-5 mt-3">
          <KemaroDashboardEconomyStatisticsChart
            values={props.data.totalAreaCleaned}
            title={t("performance.totalAreaCleaned")}
            subTitle={t("performance.squareMeters")}
            unit={t("performance.squareMeters")}
          />
        </div>

        <div className="col-12 col-xxl-6 mb-5 mt-3">
          <KemaroDashboardEconomyStatisticsChart
            values={props.data.totalTimeInCleaning}
            title={t("performance.totalTimeInCleaning")}
            subTitle={t("performance.hours")}
            unit={t("performance.hours")}
            isTime
          />
        </div>

        <div className="col-12 col-xxl-6 mb-5">
          <KemaroDashboardEconomyStatisticsChart
            values={props.data.cleaningPerformance}
            title={t("performance.cleaningPerformance")}
            subTitle={t("performance.squareMetersPerHour")}
            unit={t("performance.squareMetersPerHour")}
          />
        </div>

        <div className="col-12 col-xxl-6 mb-5">
          <KemaroDashboardEconomyStatisticsChart
            values={props.data.missions}
            title={t("performance.totalMissions")}
            subTitle={t("performance.missions")}
            unit={t("performance.missions")}
          />
        </div>
      </div>
    </div>
  );
};

export interface KemaroDashboardEconomyStatisticsProps {
  uiConfiguration: DashboardEconomyUiConfigurationDto;
  staticWeeksToShow: number;
  data: KemaroDashboardEconomyCostStatisticsDto;
  staticWeeksToShowChange: (week: number) => void;
}
