import LocationOn from "@mui/icons-material/LocationOn";
import { KemaroMissionCompleteDto } from "../../../../../robotcloud-shared/resource-models";
import { TooltipButton } from "../../../components/news/TooltipButton";
import DeleteForever from "@mui/icons-material/DeleteForever";
import { useEffect, useState } from "react";
import { defaultModalState } from "../../../uiHelpers/dtos/modalState";
import { YesNoOkDialog } from "../../../components/news/YesNoOkDialog";
import { PermissionsHelper } from "../../../uiHelpers/permissionsHelper";
import { NavigateTo } from "../../../components/news/NavigateTo";
import { ShareIdButton } from "../../../components/news/ShareIdButton";
import { useTranslation } from "react-i18next";

export const KemaroMissionViewHeader = (
  props: kemaroMissionViewHeaderProps
) => {
  const [deleteModalState, setDeleteModalState] = useState({
    ...defaultModalState,
  });
  const [canDelete, setCanDelete] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    setCanDelete(PermissionsHelper.allow("KemaroMissions", "Delete"));
  }, []);

  if (!props.item.robotSerialNumber) {
    return <></>;
  }

  const handleDelete = async () => {
    setDeleteModalState({ ...defaultModalState, isWorking: true });

    await props.onDelete(props.item);
    setDeleteModalState({ ...defaultModalState, isWorking: false });
  };

  return (
    <div className="row">
      <div className="col-6">
        <div className="mb-0">
          <span className="robot-name">{props.item.robotName}</span>

          <ShareIdButton id={props.item.id ?? ""} />

          {canDelete && (
            <TooltipButton
              aria-label="delete"
              tooltip={t("viewHeaderButtons.delete")}
              onClick={() =>
                setDeleteModalState({ ...deleteModalState, isOpen: true })
              }
            >
              <DeleteForever color="error" />
            </TooltipButton>
          )}
        </div>

        <table>
          <tbody>
            <tr>
              <td className="robot-location">{props.item.robotLocation}</td>
              <td>
                <LocationOn className="navigation-icon" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="col-6 text-end">
        <NavigateTo
          text={t("mission.missionsOfRobot.text")}
          toolTipText={t("mission.missionsOfRobot.tooltip")}
          shorCut="missions"
          className="me-3"
          paramters={[{ name: "idRobot", value: props.item.robotId }]}
          onClick={props.onShowAllmissionsOfRobot}
        />

        <NavigateTo
          text={t("robot.entityName")}
          shorCut="robots"
          toolTipIsSingular={true}
          paramters={[{ name: "id", value: props.item.robotId }]}
        />

        <p className="mb-0 robot-serial-number-title">
          {t("mission.details.serialNumber")}
        </p>
        <span className="robot-serial-number-value">
          # {props.item.robotSerialNumber}
        </span>
      </div>

      <YesNoOkDialog
        open={deleteModalState.isOpen}
        size="md"
        type="yesNo"
        title={t("mission.delete.title")}
        message={t("mission.delete.message")}
        onYes={handleDelete}
        onNo={() => setDeleteModalState({ ...defaultModalState })}
        isWorking={deleteModalState.isWorking}
      />
    </div>
  );
};

interface kemaroMissionViewHeaderProps {
  item: KemaroMissionCompleteDto;
  onDelete: (item: KemaroMissionCompleteDto) => Promise<boolean>;
  onShowAllmissionsOfRobot: () => void;
}
