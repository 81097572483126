import { DashboardSpMessageLogsStatus } from "../../../../../../../../robotcloud-shared/resource-models";
import { Theme } from "@mui/material/styles";

export function colorFromStatus(
  status: DashboardSpMessageLogsStatus,
  theme: Theme
) {
  return status === "Success"
    ? theme.palette.swarm?.general?.sucessColor
    : theme.palette.swarm?.general?.warningColor;
}
