import {
  ApiLoginCompleteDto,
  ApiUserContainerDto,
} from "../../../robotcloud-shared/resource-models";
import { processLinkIdKey } from "../views/mainPanel/MainPanel";
import { AxiosRequests } from "./axios.requests";
import { BaseRequest } from "./BaseRequest";
import { StorageManagerService } from "./storageManager.service";

export class ApplicationService extends BaseRequest {
  static controller = "/api/webapp/v1.0/application";

  static getApiTokenContainer = async (manufacturerId?: string) => {
    const storedConfigType = StorageManagerService.getMsalConfigType();
    let url =
      storedConfigType && storedConfigType === "AzureAdB2C"
        ? `${ApplicationService.controller}/api-token-b2c${
            manufacturerId ? `/${manufacturerId}` : ""
          }`
        : `${ApplicationService.controller}/api-token${
            manufacturerId ? `/${manufacturerId}` : ""
          }`;
    const processLinkId = localStorage.getItem(processLinkIdKey);
    if (processLinkId) {
      url += `?processLinkId=${processLinkId}`;
    }
    const { data } = await AxiosRequests.getWithAzureToken(url);
    return data as ApiUserContainerDto;
  };

  static getMe = async () => {
    const { data } = await AxiosRequests.get(
      `${ApplicationService.controller}/me`
    );
    return data as ApiLoginCompleteDto;
  };
}
