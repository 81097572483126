import { useTranslation } from "react-i18next";
import {
  DashboardCustomerDto,
  DashboardWeekDto,
  KemaroCompanyStructureDto,
} from "../../../../../../robotcloud-shared/resource-models";
import { NavigateTo } from "../../../../components/news";
import { GeneralFunctions } from "../../../generalFunctions";

export const KemaroDashboardEconomyFiltersLinks = (
  props: KemaroDashboardEconomyFiltersLinksProps
) => {
  const { t } = useTranslation();

  return (
    <>
      {props.customer?.id && (
        <NavigateTo
          text={t("customer.entityName")}
          shorCut="customers"
          toolTipIsSingular={true}
          paramters={[{ name: "id", value: props.customer.id }]}
        />
      )}

      {props.customer?.id && props.companyStructure?.id && (
        <NavigateTo
          text={t("dashboard.filter.location")}
          shorCut="customers"
          toolTipText={t("common.navigation.location")}
          isAvailable={props.companyStructure?.id !== null}
          className="ms-3"
          paramters={[
            { name: "id", value: props.customer.id },
            {
              name: "idLocation",
              value: props.companyStructure.id,
            },
          ]}
        />
      )}

      {props.calculationId && props.customer?.id && (
        <NavigateTo
          text={t("robot.entitiesName")}
          shorCut="robots"
          className="ms-3"
          paramters={[
            {
              name: "idKemaroDashboardEconomyCostCalculation",
              value: props.calculationId,
            },
            {
              name: "idCustomer",
              value: props.customer.id,
            },
          ]}
        />
      )}

      {props.customer?.id && props.companyStructure?.id && props.week && (
        <NavigateTo
          text={t("mission.entitiesName")}
          shorCut="missions"
          className="ms-3"
          paramters={[
            { name: "idCustomer", value: props.customer.id },
            {
              name: "idLocation",
              value: props.companyStructure.id,
            },
            {
              name: "includeChildren",
              value: props.companyStructureChildren ? "true" : "false",
            },
            {
              name: "idKemaroDashboardEconomyCostCalculation",
              value:
                props.companyStructure
                  .idKemaroDashboardEconomyCostCalculation ??
                props.companyStructure
                  .idKemaroDashboardEconomyCostCalculationInherited ??
                "",
            },
            {
              name: "from",
              value: GeneralFunctions.dateToDateString(props.week.from),
            },
            {
              name: "to",
              value: GeneralFunctions.dateToDateString(props.week.to),
            },
          ]}
        />
      )}
    </>
  );
};

interface KemaroDashboardEconomyFiltersLinksProps {
  customer: DashboardCustomerDto | undefined;
  companyStructure: KemaroCompanyStructureDto | undefined;
  calculationId: string;
  week: DashboardWeekDto | undefined;
  companyStructureChildren: boolean;
}
