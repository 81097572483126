import { useState } from "react";
import { Fragment } from "react";
import ArrowRight from "@mui/icons-material/ArrowRight";
import ArrowDown from "@mui/icons-material/ArrowDropDown";
import { PermissionMatrixSectionDto } from "../../../../robotcloud-shared/resource-models";
import { PermissionsMatrixActionRow } from "./PermissionsMatrixActionRow";
import { PermissionsMatrixRulesRow } from "./PermissionsMatrixRulesRow";
import "./permissions-matrix.scss";
import { Theme } from "@mui/material";
export const PermissionsMatrixSectionRow = ({
  section,
  length,
  theme,
}: PermissionsMatrixSectionRowProps) => {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <Fragment key={`section_${section.title}`}>
      <tr
        className="permissions-matrix-section-title"
        style={{
          backgroundColor:
            theme.palette.swarm?.permissionsMatrix?.sectionTitleBackground,
          height: "40px",
        }}
      >
        <td colSpan={length} onClick={() => setIsExpanded((prev) => !prev)}>
          {isExpanded ? <ArrowDown /> : <ArrowRight />}
          {section.title}
        </td>
      </tr>

      {isExpanded
        ? section.actions.map((x, idx) =>
            PermissionsMatrixActionRow(section, x, idx)
          )
        : null}
      {isExpanded
        ? section.subRules.map((x, idx) =>
            PermissionsMatrixRulesRow(section, x, idx)
          )
        : null}
    </Fragment>
  );
};

export interface PermissionsMatrixSectionRowProps {
  section: PermissionMatrixSectionDto;
  length: number;
  theme: Theme;
}
