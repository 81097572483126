import "../kemaroDashboard.scss";
import "../../../../styles/general.scss";
import { KemaroDashboardTabs } from "../KemaroDashboardTabs";
import { KemaroDashboardEconomyFilters } from "./KemaroDashboardEconomyFilters";
import { KemaroDashboardEconomyPerformance } from "./KemaroDashboardEconomyPerformance";
import { KemaroDashboardEconomyCost } from "./KemaroDashboardEconomyCost";
import {
  DashboardEconomyFilterDto,
  KemaroDashboardEconomyCostStatisticsDto,
  KemaroDashboardEconomyCostWeekResultDto,
} from "../../../../../../robotcloud-shared/resource-models";
import { Loading } from "../../../../components/Loading";
import { KemaroDashboardEconomyStatistics } from "./kemaroDashboardEconomyStatistics";
import { ApplicationBar } from "../../../../components/news/ApplicationBar";
import { useTranslation } from "react-i18next";
import { ToastHelper } from "../../../../components/news";
import { ErrorHelper } from "../../../../uiHelpers/errors.helper";
import { KemaroDashboardEconomyRequests } from "../../../../data/kemaro/KemaroDashboardEconomy.request";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import useTheme from "@mui/material/styles/useTheme";
import { useState } from "react";
import { useKemaroDashboardEconomyConfigurationUi } from "./hooks/useKemaroDashboardEconomyConfigurationUi";

export const KemaroDashboardEconomy = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [isFetching, setIsFetching] = useState(false);
  const [economyCostWeek, setEconomyCostWeek] = useState<
    KemaroDashboardEconomyCostWeekResultDto | undefined
  >(undefined);
  const [economyCostWeekStatics, setEconomyCostWeekStatics] = useState<
    KemaroDashboardEconomyCostStatisticsDto | undefined
  >(undefined);

  const [lastSearch, setLastSearch] = useState("");

  const [lastFilter, setLastFilter] = useState<
    DashboardEconomyFilterDto | undefined
  >(undefined);

  const [staticWeeksToShow, setStaticWeeksToShow] = useState(20);

  const staticWeeksToShowChange = async (weeks: number) => {
    setStaticWeeksToShow(weeks);
    stadisticWeeksChange(lastFilter, weeks);
  };

  const { uiConfiguration, currentStaticWeeksChange } =
    useKemaroDashboardEconomyConfigurationUi(staticWeeksToShowChange);

  const filterChange = async (
    filter: DashboardEconomyFilterDto | undefined
  ) => {
    if (JSON.stringify(lastFilter) === JSON.stringify(filter)) return;

    setLastFilter(filter);

    if (!filter) {
      setLastSearch("--");
      return;
    }

    const strFilter = JSON.stringify(filter);
    if (lastSearch === strFilter) return;

    setLastSearch(strFilter);
    setIsFetching(true);

    try {
      setEconomyCostWeek(
        await KemaroDashboardEconomyRequests.getEconomyCostWeekly(filter)
      );
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    stadisticWeeksChange(filter, staticWeeksToShow);
    setIsFetching(false);
  };

  const stadisticWeeksChange = async (
    filter: DashboardEconomyFilterDto | undefined,
    weeks: number
  ) => {
    if (!filter) return;

    try {
      setEconomyCostWeekStatics(
        await KemaroDashboardEconomyRequests.getEconomyCostWeeklyStatics(
          weeks,
          filter
        )
      );
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }
  };

  const cleanData = () => {
    filterChange({ ...lastFilter!, idCustomer: "" });
  };

  const noScroll = !isFetching && economyCostWeek?.hasNoData;

  return (
    <div className="page-content">
      <div className="page-header">
        <ApplicationBar
          title={t("dashboard.title")}
          canSearch={false}
        ></ApplicationBar>
      </div>
      <div className={`page-body ${noScroll ? "no-vertical-scroll" : ""}`}>
        <KemaroDashboardTabs currentTab="Economy" />
        <div className="px-3 mt-2">
          <div className="col-12">
            <KemaroDashboardEconomyFilters
              usedLocationIds={economyCostWeek?.locationIds ?? []}
              filterChange={filterChange}
              staticWeeksToShow={staticWeeksToShow}
              hasNoData={economyCostWeek?.hasNoData}
              onClean={cleanData}
            />
          </div>

          {isFetching && (
            <div className="w-100 mt-5">
              <Loading isLoading={isFetching} />
            </div>
          )}

          {!isFetching && economyCostWeek?.hasNoData && (
            <div
              className="row no-data-container"
              style={{
                backgroundColor:
                  theme.palette.swarm?.kemaroDashboard
                    ?.noDataContainerBackground,
              }}
            >
              <span
                className="no-data ms-4"
                style={{
                  color:
                    theme.palette.swarm?.kemaroDashboard?.noDataContainerNoData,
                  backgroundColor:
                    theme.palette.swarm?.kemaroDashboard
                      ?.noDataContainerNoDataBackground,
                  border:
                    theme.palette.swarm?.kemaroDashboard
                      ?.noDataContainerNoDataBorder,
                }}
              >
                <InfoOutlinedIcon
                  className="icon me-3"
                  style={{
                    color:
                      theme.palette.swarm?.kemaroDashboard
                        ?.noDataContainerNoDataIcon,
                  }}
                />
                {t("dashboard.noDataLine")}
              </span>
            </div>
          )}

          {!isFetching && economyCostWeek && (
            <div className="row mt-3 mx-0">
              <div className="col-12 col-xxl-6 py-0 pe-4">
                <div className="col-12 mb-4">
                  <KemaroDashboardEconomyPerformance
                    title={t("dashboard.weeklyPerformance")}
                    subtitle={t("dashboard.prospected")}
                    data={economyCostWeek.weeklyPerformance}
                    isCurrentWeek={true}
                    filter={lastFilter!}
                  />
                </div>

                <div className="col-12">
                  <KemaroDashboardEconomyPerformance
                    title={t("dashboard.lifetimePerformance")}
                    data={economyCostWeek.lifePerformance}
                    isCurrentWeek={false}
                    filter={lastFilter!}
                  />
                </div>
              </div>

              <div className="col-12 mt-4 pe-4 col-xxl-6 mt-xxl-0 pe-xxl-0">
                <div
                  className="col-12 h-100 kemaro-dashboard-economy-cost"
                  style={{
                    border:
                      theme.palette.swarm?.kemaroDashboard?.economyCostssBorder,
                    boxShadow:
                      theme.palette.swarm?.kemaroDashboard
                        ?.economyCostsBoxShadow,
                  }}
                >
                  <KemaroDashboardEconomyCost data={economyCostWeek} />
                </div>
              </div>

              {uiConfiguration && economyCostWeekStatics && (
                <div className="col-12 ps-4 mt-4">
                  <div className="col-12">
                    <KemaroDashboardEconomyStatistics
                      uiConfiguration={uiConfiguration}
                      staticWeeksToShow={staticWeeksToShow}
                      data={economyCostWeekStatics}
                      staticWeeksToShowChange={currentStaticWeeksChange}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
