import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Switch } from "@mui/material";
import { GeneralFunctions } from "../../generalFunctions";
import OnBoardingContext from "../OnBoardingContext";
import { Loading } from "../../../components/Loading";
import { WorkflowsRequests } from "../../../data/workflows.request";
import "./workflowViewInformation.scss";

export const WorkflowViewInformation = (): JSX.Element => {
  const { t } = useTranslation();
  const { currentSelectedItem, process } = useContext(OnBoardingContext);
  const [pdfContent, setPdfContent] = useState<string>();
  const [isLoadingPdf, setIsLoadingPdf] = useState<boolean>(false);
  useEffect(() => {
    if (currentSelectedItem?.id && currentSelectedItem?.type === "file") {
      setLabelCols("col-4");
      setInputCols("col-8");
      if (!isLoadingPdf) {
        setIsLoadingPdf(true);
        WorkflowsRequests.getPdf(currentSelectedItem.id).then((result) => {
          if (result.status === 200) {
            if (result.data.size > 0) {
              setPdfContent(URL.createObjectURL(result.data));
            } else {
              setPdfContent(undefined);
            }
            setIsLoadingPdf(false);
          }
        });
      }
    } else {
      setLabelCols("col-2");
      setInputCols("col-10");
      setPdfContent(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedItem?.id, currentSelectedItem?.type]);

  const [labelCols, setLabelCols] = useState<string>("col-4");
  const [valueCols, setInputCols] = useState<string>("col-8");

  return (
    <>
      {currentSelectedItem && (
        <div className="workflow-view-information row header-container-data">
          <div
            className={`${
              currentSelectedItem.type === "file" ? "col-6" : "col-12"
            } mt-4 header-container-data`}
          >
            <div className="row">
              <div className={`${labelCols} pt-2`}>
                {t("onBoarding.workflow.enabled")}
              </div>
              <div className={`${valueCols}`}>
                <Switch
                  color="primary"
                  checked={currentSelectedItem.enabled}
                  disabled={true}
                />
              </div>
            </div>
            <div className="row pt-2">
              <div className={`${labelCols}`}>
                {t("onBoarding.workflow.type")}
              </div>
              <div className={`${valueCols}`}>
                {t(
                  `onBoarding.workflow.workflowTypes.${currentSelectedItem.type}` as const
                )}
              </div>
            </div>
            <div className="row pt-3">
              <div className={`${labelCols}`}>
                {t("onBoarding.workflow.displayTitle")}
              </div>
              <div className={`${valueCols}`}>
                {currentSelectedItem.displayTitle}
              </div>
            </div>
            <div className="row pt-3">
              <div
                className={`${
                  currentSelectedItem.type === "file" ? "col-4" : "col-2"
                } align-self-center`}
              >
                {t("onBoarding.workflow.displayInstructions")}
              </div>
              <div
                className={`${
                  currentSelectedItem.type === "file" ? "col-8" : "col-10"
                } align-self-center`}
              >
                {currentSelectedItem.displayInstructions}
              </div>
            </div>
            {(currentSelectedItem.type === "link" ||
              currentSelectedItem.type === "file") && (
              <div className="row pt-3">
                <div
                  className={`${
                    currentSelectedItem.type === "file" ? "col-4" : "col-2"
                  } align-self-center`}
                >
                  {t("onBoarding.workflow.displayAgreement")}
                </div>
                <div
                  className={`${
                    currentSelectedItem.type === "file" ? "col-8" : "col-10"
                  } align-self-center`}
                >
                  {currentSelectedItem.displayAgreement}
                </div>
              </div>
            )}
            {currentSelectedItem.type === "link" && (
              <div className="row pt-3">
                <div className={`${labelCols}`}>
                  {t("onBoarding.workflow.displayLinkUrl")}
                </div>
                <div className={`${valueCols}`} style={{ maxWidth: "200px" }}>
                  {currentSelectedItem.displayLinkUrl}
                </div>
              </div>
            )}
            <div className="row pt-3">
              <div className={`${labelCols} `}>
                {t("onBoarding.workflow.validFrom")}
              </div>
              <div className={`${valueCols}`}>
                <span>
                  {currentSelectedItem.validFrom &&
                    GeneralFunctions.dateToDateString(
                      currentSelectedItem.validFrom
                    )}
                </span>
              </div>
            </div>
            <div className="row pt-2">
              <div className={`${labelCols} pt-2`}>
                {t("onBoarding.workflow.required")}
              </div>
              <div className={`${valueCols}`}>
                <Switch
                  color="primary"
                  checked={currentSelectedItem.required}
                  disabled={true}
                />
              </div>
            </div>
            {process?.behaviour === "link" && (
              <>
                <div className={`${labelCols} pt-2`}>
                  {t("onBoarding.workflow.abortable")}
                </div>
                <div className={`${valueCols}`}>
                  <Switch
                    color="primary"
                    checked={currentSelectedItem.abortable}
                    disabled={true}
                  />
                </div>
              </>
            )}
            <div className="row pt-1">
              <div className={`${labelCols} `}>
                {t("onBoarding.workflow.order")}
              </div>
              <div className={`${valueCols}`}>{currentSelectedItem.order}</div>
            </div>
          </div>
          {currentSelectedItem.type === "file" && (
            <div className="col-6 mt-4 header-container-data">
              <div className="col-12 ">
                {isLoadingPdf ? (
                  <Loading
                    className="file-container"
                    isLoading={isLoadingPdf}
                  />
                ) : pdfContent ? (
                  <object
                    aria-label="test"
                    className="border file-container"
                    data={`${pdfContent ?? "#"}#toolbar=0`}
                    type="application/pdf"
                  ></object>
                ) : (
                  <div className="border file-container"></div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
