import {
  CustomFieldDto,
  CustomFieldFilterCriteriaDto,
  CustomFieldSearchTextDto,
  CustomFieldTypeDto,
  PaginatedListDtoOfCustomFieldListDto,
  SearchTextResultDtoOfCustomFieldListDto,
} from "../../../robotcloud-shared/resource-models";
import { AxiosRequests } from "./axios.requests";
import { BaseRequest } from "./BaseRequest";
import { prepareSearchTextDto } from "./functions";

export class CustomFieldRequests extends BaseRequest {
  static controller = "api/webapp/v1.0/customFields";

  static getTypeList = async () => {
    const { data } = await AxiosRequests.get(
      `${CustomFieldRequests.controller}/types`
    );
    return data as CustomFieldTypeDto[];
  };

  static getList = async (filterCriteria: CustomFieldFilterCriteriaDto) => {
    const { data } = await AxiosRequests.get(
      `${
        CustomFieldRequests.controller
      }?${CustomFieldRequests.objectToQueryString(filterCriteria)}`
    );
    return data as PaginatedListDtoOfCustomFieldListDto;
  };

  static getById = async (id: string) => {
    const { data } = await AxiosRequests.get(
      `${CustomFieldRequests.controller}/${id}`
    );
    return data as CustomFieldDto;
  };

  static create = async (dto: CustomFieldDto) => {
    const { data } = await AxiosRequests.post(
      CustomFieldRequests.controller,
      dto
    );
    return data as CustomFieldDto;
  };

  static modify = async (modified: CustomFieldDto) => {
    await AxiosRequests.put(
      `${CustomFieldRequests.controller}/${modified.id}`,
      modified
    );
  };

  static delete = async (id: string) => {
    return await AxiosRequests.delete(
      `${CustomFieldRequests.controller}/${id}`
    );
  };

  static getSearchText = async (searchTextDto: CustomFieldSearchTextDto) => {
    const { data } = await AxiosRequests.post(
      `${CustomFieldRequests.controller}/search-text`,
      prepareSearchTextDto(searchTextDto)
    );
    return data as SearchTextResultDtoOfCustomFieldListDto;
  };
}
