import { KemaroRobotCleanedSurfaceChartDto } from "../../../../../robotcloud-shared/resource-models";
import { CalendarTooltipProps, ResponsiveCalendar } from "@nivo/calendar";
import SquareRoundedIcon from "@mui/icons-material/SquareRounded";
import { ChartSize } from "./Models";

export const KemaroRobotViewCleanedAreaHistoryChart = ({
  chartSize,
  cleanedSurfaceChartDto,
  className,
}: KemaroRobotViewCleanedAreaHistoryChartProps) => {
  const colorScaleFn = (value: number | { valueOf(): number }) => {
    let idx: number = value as number;
    if (idx < 0) idx = 0;

    return cleanedSurfaceChartDto?.colorRules[idx].color ?? "#eeeeee";
  };

  colorScaleFn.ticks = () => [];

  return (
    <div
      className={className}
      style={{
        height: `${chartSize.height}px`,
        width: `${chartSize.width - 30}px`,
      }}
    >
      <ResponsiveCalendar
        data={cleanedSurfaceChartDto.days}
        from={cleanedSurfaceChartDto.startDate}
        to={cleanedSurfaceChartDto.endDate}
        emptyColor="#eeeeee"
        colorScale={colorScaleFn}
        margin={{ top: 0, right: -20, bottom: 0, left: 0 }}
        yearSpacing={50}
        monthSpacing={10}
        monthBorderWidth={0}
        monthBorderColor="#ffffff"
        monthLegendPosition="after"
        daySpacing={2}
        dayBorderWidth={0}
        dayBorderColor="#ffffff"
        tooltip={(item) => toolTipHandel(item, cleanedSurfaceChartDto)}
      />
    </div>
  );
};

interface KemaroRobotViewCleanedAreaHistoryChartProps {
  chartSize: ChartSize;
  cleanedSurfaceChartDto: KemaroRobotCleanedSurfaceChartDto;
  className: string;
}

const toolTipHandel = (
  item: CalendarTooltipProps,
  cleanedSurfaceChartDto: KemaroRobotCleanedSurfaceChartDto
) => {
  const day = cleanedSurfaceChartDto!.days.find((x) => x.day === item.day);
  return (
    <div className="bg-white px-2 py-1 tooltip-container">
      {
        <>
          <SquareRoundedIcon
            className="pe-2"
            style={{
              color: cleanedSurfaceChartDto!.colorRules[day!.value].color,
            }}
          />

          {item.day}

          <span className="ps-2 fw-bold">{day!.cleanedSurface}</span>

          <span className="ps-2">
            m<sup>2</sup>
          </span>
        </>
      }
    </div>
  );
};
