import { useEffect, useState } from "react";
import {
  KemaroDashboardEconomyCostCalculationCompleteDto,
  KemaroDashboardEconomyCostCalculationPostDto,
} from "../../../../../../robotcloud-shared/resource-models";
import { TooltipButton } from "../../../../components/news/TooltipButton";
import { ViewHeader } from "../../../../components/news/ViewHeader";
import { useTranslation } from "react-i18next";

import Edit from "@mui/icons-material/Edit";
import DeleteForever from "@mui/icons-material/DeleteForever";
import { KemaroCostCalculationModal } from "./KemaroCostCalculationModal";
import { YesNoOkDialog } from "../../../../components/news/YesNoOkDialog";
import { PermissionsHelper } from "../../../../uiHelpers/permissionsHelper";
import StarRateIcon from "@mui/icons-material/StarRate";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";

export const KemaroCostCalculationViewTitle = (
  props: kemaroCostCalculationViewTitleProps
) => {
  const { t } = useTranslation();

  const [canAdd, setCanAdd] = useState(false);
  const [canModify, setCanModify] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [isEditPopupWorking, setIsEditPopupWorking] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [openCopyPopup, setOpenCopyPopup] = useState(false);
  const [isCopyPopupWorking, setIsCopyPopupWorking] = useState(false);

  useEffect(() => {
    setCanAdd(PermissionsHelper.allow("KemaroCostCalculations", "Create"));
    setCanModify(PermissionsHelper.allow("KemaroCostCalculations", "Edit"));
    setCanDelete(PermissionsHelper.allow("KemaroCostCalculations", "Delete"));
  }, []);

  const handleModifySave = async (
    modified: KemaroDashboardEconomyCostCalculationPostDto
  ) => {
    setIsEditPopupWorking(true);

    const result = await props.onModify(modified);
    if (result) {
      setOpenEditPopup(false);
    }

    setIsEditPopupWorking(false);
    return result;
  };

  const handleDelete = async () => {
    setIsEditPopupWorking(true);

    const result = await props.onDelete(props.item);
    if (result) {
      setOpenDeletePopup(false);
    }

    setIsEditPopupWorking(false);

    return result;
  };

  const handleCopySave = async (
    modified: KemaroDashboardEconomyCostCalculationPostDto
  ) => {
    setIsCopyPopupWorking(true);

    const result = await props.onCopy({ ...modified });
    if (result) {
      setOpenCopyPopup(false);
    }

    setIsCopyPopupWorking(false);
    return result;
  };

  return (
    <>
      <ViewHeader
        title={props.item.name}
        titleIcon={
          props.item.isDefault ? (
            <StarRateIcon
              className="primary-text"
              sx={{ color: "swarm.general.primaryText" }}
            />
          ) : undefined
        }
        copyLinkId={props.item.id}
        canEdit={false}
        canDelete={false}
      >
        <div className="d-flex flex-row justify-content-between">
          <div>
            {canAdd && (
              <TooltipButton
                aria-label="copy"
                tooltip={t("costCalculation.copy.tooltip")}
                onClick={() => setOpenCopyPopup(true)}
              >
                <FileCopyOutlinedIcon color="primary" />
              </TooltipButton>
            )}

            {canModify && (
              <TooltipButton
                aria-label="edit"
                tooltip={t("costCalculation.edit.tooltip")}
                onClick={() => setOpenEditPopup(true)}
              >
                <Edit color="primary" />
              </TooltipButton>
            )}

            {canDelete && (
              <TooltipButton
                aria-label="delete"
                tooltip={t("costCalculation.delete.tooltip")}
                onClick={() => setOpenDeletePopup(true)}
              >
                <DeleteForever color="error" />
              </TooltipButton>
            )}
          </div>
        </div>
      </ViewHeader>

      {openEditPopup && (
        <KemaroCostCalculationModal
          title={t("costCalculation.edit.title")}
          isOpenModal={openEditPopup}
          onSave={(dto) => handleModifySave(dto)}
          onCancel={() => setOpenEditPopup(false)}
          isWorking={isEditPopupWorking}
          dto={props.item}
        />
      )}

      {openCopyPopup && (
        <KemaroCostCalculationModal
          title={t("costCalculation.copy.title")}
          isOpenModal={openCopyPopup}
          onSave={(dto) => handleCopySave(dto)}
          onCancel={() => setOpenCopyPopup(false)}
          isWorking={isCopyPopupWorking}
          dto={props.item}
          isCopy={true}
        />
      )}

      <YesNoOkDialog
        open={openDeletePopup}
        size="md"
        type="yesNo"
        title={t("costCalculation.delete.title")}
        message={`${t("costCalculation.delete.warning")} ${props.item.name}?`}
        onYes={handleDelete}
        onNo={() => setOpenDeletePopup(false)}
        isWorking={isEditPopupWorking}
      />
    </>
  );
};

interface kemaroCostCalculationViewTitleProps {
  item: KemaroDashboardEconomyCostCalculationCompleteDto;
  onCopy: (
    modified: KemaroDashboardEconomyCostCalculationPostDto
  ) => Promise<boolean>;
  onModify: (
    modified: KemaroDashboardEconomyCostCalculationPostDto
  ) => Promise<boolean>;
  onDelete: (
    dto: KemaroDashboardEconomyCostCalculationCompleteDto
  ) => Promise<boolean>;
}
