import {
  MaxbrainLogFilterCriteriaDto,
  PaginatedListDtoOfMaxbrainLogDto,
} from "../../../robotcloud-shared/resource-models";
import { AxiosRequests } from "./axios.requests";
import { BaseRequest } from "./BaseRequest";

export class MaxbrainLogRequest extends BaseRequest {
  static controller = "api/webapp/v1.0/MaxbrainLog";

  static getList = async (filter: MaxbrainLogFilterCriteriaDto) => {
    const { data } = await AxiosRequests.get(
      `${
        MaxbrainLogRequest.controller
      }?${MaxbrainLogRequest.objectToQueryString(filter)}`
    );
    return data as PaginatedListDtoOfMaxbrainLogDto;
  };
}
