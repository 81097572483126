import {
  EventMessage,
  EventType,
  AuthenticationResult,
  AuthError,
} from "@azure/msal-browser";

import { TokenClaims } from "@azure/msal-common";
import { useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import {
  ApiLoginCompleteDto,
  ManufacturerReducedDto,
} from "../../../robotcloud-shared/resource-models";
import { getAuthRequest, b2cPolicies } from "../data/auth";
import { compareIssuingPolicy } from "../data/claimUtils";
import { MenuHelper } from "../uiHelpers/menu.helper";
import { ForgotPasswordConfirm } from "./auth/forgotPasswordConfirm";
import { Login } from "./auth/login";
import { Logout } from "./auth/logout";
import { MainPanel } from "./mainPanel/MainPanel";
import { UnassignedPanel } from "./auth/unassigned/UnassignedPanel";
import { BASE_URL } from "../data/axios.requests";

export const Pages = ({
  manufacturers,
  onManufacturerChange,
  me,
  initialUrl,
}: PagesProps) => {
  /**
   * useMsal is hook that returns the PublicClientApplication instance,
   * an array of all accounts currently signed in and an inProgress value
   * that tells you what msal is currently doing. For more, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
   */
  const { instance } = useMsal();
  useEffect(() => {
    const callbackId = instance.addEventCallback((event: EventMessage) => {
      if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
        event.payload
      ) {
        /**
         * For the purpose of setting an active account for UI update, we want to consider only the auth
         * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy
         * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
         * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
         */
        if (
          compareIssuingPolicy(
            (event.payload as AuthenticationResult).idTokenClaims,
            b2cPolicies.names.editProfile
          )
        ) {
          // retrieve the account from initial sing-in to the app
          const originalSignInAccount = instance
            .getAllAccounts()
            .find(
              (account) =>
                account &&
                account!.idTokenClaims &&
                account!.idTokenClaims.oid ===
                  (
                    (event.payload as AuthenticationResult)
                      .idTokenClaims as TokenClaims
                  ).oid &&
                account!.idTokenClaims.sub ===
                  (
                    (event.payload as AuthenticationResult)
                      .idTokenClaims as TokenClaims
                  ).sub &&
                compareIssuingPolicy(
                  account.idTokenClaims,
                  b2cPolicies.names.signUpSignIn
                )
            );

          let signUpSignInFlowRequest = {
            authority: b2cPolicies.authorities.signUpSignIn.authority,
            account: originalSignInAccount,
          };

          // // silently login again with the signUpSignIn policy
          instance.ssoSilent(signUpSignInFlowRequest);
        }

        /**
         * Below we are checking if the user is returning from the reset password flow.
         * If so, we will ask the user to reauthenticate with their new password.
         * If you do not want this behavior and prefer your users to stay signed in instead,
         * you can replace the code below with the same pattern used for handling the return from
         * profile edit flow
         */
        if (
          compareIssuingPolicy(
            (event.payload as AuthenticationResult).idTokenClaims,
            b2cPolicies.names.forgotPassword
          )
        ) {
          let signUpSignInFlowRequest = {
            authority: b2cPolicies.authorities.signUpSignIn.authority,
            scopes: [...getAuthRequest().scopes],
          };
          instance.loginRedirect(signUpSignInFlowRequest);
        }
      }

      if (event.eventType === EventType.LOGIN_FAILURE) {
        // Check for forgot password error
        // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
        if (
          event.error &&
          (event.error as AuthError).errorMessage.includes("AADB2C90118")
        ) {
          const resetPasswordRequest = {
            authority: b2cPolicies.authorities.forgotPassword.authority,
            scopes: [],
          };
          instance.loginRedirect(resetPasswordRequest);
        }
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
    // eslint-disable-next-line
  }, [instance]);

  const appRoutes = me ? MenuHelper.getRoutes(me) : [];
  const defaultRoute = MenuHelper.getDefaultRoute(me);
  const initialPath = new URL(initialUrl ?? "")?.pathname;
  const initialPathIsAllowed = appRoutes.some((x) => x.url === initialPath);

  return (
    <>
      {me ? (
        <>
          <MainPanel
            manufacturers={manufacturers}
            onManufacturerChange={onManufacturerChange}
          />

          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/unassigned" element={<UnassignedPanel />} />
            <Route
              path="/forgot-password-confirm"
              element={<ForgotPasswordConfirm />}
            />

            {me.userAccessType !== "Unassigned" &&
              appRoutes
                .filter((x) => x.allow)
                .map((x) => {
                  return (
                    <Route
                      key={x.url}
                      path={x.url}
                      element={x.component}
                    ></Route>
                  );
                })}

            <Route
              path="/"
              element={
                initialPathIsAllowed && initialUrl ? (
                  <Navigate to={initialUrl.replace(BASE_URL, "")} />
                ) : (
                  <Navigate to={defaultRoute?.url} />
                )
              }
            />
          </Routes>
        </>
      ) : (
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/unassigned" element={<UnassignedPanel />} />
          <Route
            path="/forgot-password-confirm"
            element={<ForgotPasswordConfirm />}
          />
        </Routes>
      )}
    </>
  );
};

export interface PagesProps {
  manufacturers: ManufacturerReducedDto[];
  onManufacturerChange: (manufacturer: ManufacturerReducedDto) => void;
  me: ApiLoginCompleteDto | undefined;
  initialUrl?: string;
}
