import { useEffect, useState } from "react";
import { ManufacturerReducedDto } from "../../../../robotcloud-shared/resource-models";
import { ErrorHelper } from "../../uiHelpers/errors.helper";
import { ManufacturerRequests } from "../../data/manufacturers.Requests";
import { ToastHelper } from "../news/Toast.helper";
import { BaseSearch } from "./BaseSearch";

export const ManufacturerSearch = (props: manufacturerSearchSearchProps) => {
  const [items, setItems] = useState<ManufacturerReducedDto[]>([]);
  const [currentItem, setCurrentItem] = useState<
    ManufacturerReducedDto | undefined
  >(undefined);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    getList(props?.currentId);
  }, [props, searchText]);

  const getList = async (currentId: string | undefined) => {
    const key = "manufacturers";

    try {
      let manufacturers = JSON.parse(
        sessionStorage.getItem(key) ?? "[]"
      ) as ManufacturerReducedDto[];
      if (manufacturers.length === 0) {
        const result = await ManufacturerRequests.getList({
          page: 1,
          maxItemCount: 10000,
          orderBy: "Asc",
          orderColumns: "name",
          id: null,
          textToSearch: "",
        });

        manufacturers = result.items;
        sessionStorage.setItem(key, JSON.stringify(manufacturers));
      }

      setItems(manufacturers);

      if (!currentId) return;

      const newCurrentItem = manufacturers.find((x) => x.id === currentId);
      if (newCurrentItem) setCurrentItem(newCurrentItem);
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }
  };

  const selectionChange = (newValue: string | null) => {
    if (!newValue) {
      setCurrentItem(undefined);
      props.selectionChange(undefined);
      return;
    }

    const selected = items.find((x) => x.name === newValue);

    if (!props.clearAfterSelect) {
      setCurrentItem(selected);
    }

    props.selectionChange(selected);

    if (props.clearAfterSelect) {
      setSearchText("");
    }
  };

  return (
    <BaseSearch
      disableCloseOnSelect={props.clearAfterSelect}
      value={currentItem?.name}
      options={items.map((x) => x.name)}
      searchText={searchText}
      setSearchText={(x) => setSearchText(x)}
      onChange={(x) => selectionChange(x)}
    />
  );
};

interface manufacturerSearchSearchProps {
  currentId?: string;
  clearAfterSelect?: boolean;
  selectionChange: (select: ManufacturerReducedDto | undefined) => void;
}
