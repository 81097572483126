import { UserReducedDto } from "../../../../../robotcloud-shared/resource-models";
import { TooltipButton } from "../../../components/news/TooltipButton";
import { StorageManagerService } from "../../../data/storageManager.service";
import { PermissionsHelper } from "../../../uiHelpers/permissionsHelper";
import KeyIcon from "@mui/icons-material/Key";
import Edit from "@mui/icons-material/Edit";
import DeleteForever from "@mui/icons-material/DeleteForever";
import MenuBookTwoToneIcon from "@mui/icons-material/MenuBookTwoTone";
import i18n from "i18next";
import { TableGenericColumn } from "../../../components/news/TableGeneric/models";
import { theme } from "../../../theme/presets/default";

export const UsersColumnDefinition = (props: UsersColumnDefinitionProps) => {
  const me = StorageManagerService.getMe();

  const resetPasswordIsDisabled = (item: UserReducedDto) => {
    return (
      !item.userName?.endsWith("@eb8.ch") ||
      (!item.hasPasswordResetEmailAddress &&
        (me!.userAccessType !== item.userAccessType ||
          PermissionsHelper.roleCanModifyRole(me!.role, item.role)))
    );
  };

  let columnsDefinition: TableGenericColumn[] = [
    {
      title: i18n.t("common.name").toString(),
      property: "name",
      headerClassName: "ps-0",
      itemClassName: "ps-0",
      cellTemplate: (item: UserReducedDto) => (
        <span
          className={item.enabled ? "" : " user-disabled"}
          style={
            item.enabled
              ? {}
              : { color: theme.palette.swarm?.general?.userDisabled }
          }
        >
          {item.name}
        </span>
      ),
    },
    {
      title: `${i18n.t("user.userName").toString()} / ${i18n
        .t("user.primaryEmailAddress")
        .toString()}`,
      cellTemplate: (item: UserReducedDto) => (
        <span
          className={item.enabled ? "" : " user-disabled"}
          style={
            item.enabled
              ? {}
              : { color: theme.palette.swarm?.general?.userDisabled }
          }
        >
          {item.userName?.length > 0 ? item.userName : item.primaryEmailAddress}
        </span>
      ),
    },
    {
      title: i18n.t("common.role").toString(),
      property: "role",
      cellTemplate: (item: UserReducedDto) => (
        <span
          className={item.enabled ? "" : " user-disabled"}
          style={
            item.enabled
              ? {}
              : { color: theme.palette.swarm?.general?.userDisabled }
          }
        >
          {item.role}
        </span>
      ),
    },
    {
      headerClassName: "four-buttons-container",
      itemClassName: "four-buttons-container  text-end",
      canSort: false,
      cellTemplate: (item: UserReducedDto) => (
        <>
          {props.isKemaroAcademyEnable && (
            <TooltipButton
              aria-label={props.kemaroAcademyConfigurationTooltip}
              tooltip={props.kemaroAcademyConfigurationTooltip}
              onClick={async () => {
                if (await props.setCurrentUser(item)) {
                  props.setKemaroAcademyModalOpen(true);
                }
              }}
            >
              <MenuBookTwoToneIcon color="primary" />
            </TooltipButton>
          )}
          {props.canResetPassword && (
            <TooltipButton
              disabled={resetPasswordIsDisabled(item)}
              tooltip={i18n.t("user.resetPassword.tooltip").toString()}
              onClick={async () => {
                if (await props.setCurrentUser(item)) {
                  props.setPasswordResetModalOpen(true);
                }
              }}
            >
              {resetPasswordIsDisabled(item) ? (
                <KeyIcon color="disabled" />
              ) : (
                <KeyIcon color="primary" />
              )}
            </TooltipButton>
          )}

          {props.canModify &&
            (me!.userAccessType !== item.userAccessType ||
              PermissionsHelper.roleCanModifyRole(me!.role, item.role)) && (
              <TooltipButton
                aria-label="edit"
                tooltip={i18n.t("viewHeaderButtons.edit").toString()}
                onClick={async () => {
                  if (await props.setCurrentUser(item)) {
                    props.setModifyModalOpen(true);
                  }
                }}
              >
                <Edit color="primary" />
              </TooltipButton>
            )}
          {props.canDelete &&
            (me!.userAccessType !== item.userAccessType ||
              PermissionsHelper.roleCanModifyRole(me!.role, item.role)) && (
              <TooltipButton
                aria-label="delete user"
                tooltip={i18n.t("user.delete.tooltip").toString()}
                onClick={async () => {
                  if (await props.setCurrentUser(item)) {
                    props.setDeleteModalOpen(true);
                  }
                }}
              >
                <DeleteForever color="error" />
              </TooltipButton>
            )}
        </>
      ),
    },
  ];

  return columnsDefinition;
};

export interface UsersColumnDefinitionProps {
  canResetPassword: boolean;
  canModify: boolean;
  canDelete: boolean;
  isKemaroAcademyEnable: boolean;
  kemaroAcademyConfigurationTooltip: string;
  setCurrentUser: (item: UserReducedDto) => Promise<boolean>;
  setPasswordResetModalOpen: (isOpen: boolean) => void;
  setModifyModalOpen: (isOpen: boolean) => void;
  setDeleteModalOpen: (isOpen: boolean) => void;
  setKemaroAcademyModalOpen: (isOpen: boolean) => void;
}
