import DeleteForever from "@mui/icons-material/DeleteForever";
import Edit from "@mui/icons-material/Edit";
import { TooltipButton } from "../../../components/news/TooltipButton";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import { CustomerContactReducedDto } from "../../../../../robotcloud-shared/resource-models";
import i18n from "i18next";
import { TableGenericColumn } from "../../../components/news/TableGeneric/models";

export const ContactColumnDefinition = (
  canModify: boolean,
  canDelete: boolean,
  setContactModalTitle: (title: string) => void,
  setContactModalContact: (item: ContactReducedDto) => void,
  setContactModalOpen: (isOpen: boolean) => void,
  onDelete: (item: ContactReducedDto) => void,
  contacts?: ContactReducedDto[],
  onInCompanyStructure?: (item: ContactReducedDto) => void
) => {
  let columnsDefinition: TableGenericColumn[] = [
    {
      title: i18n.t("common.name").toString(),
      property: "completeName",
      headerClassName: "ps-0",
      itemClassName: "ps-0",
    },
    {
      title: i18n.t("contact.address").toString(),
      property: "address",
      cellTemplate: (item: ContactReducedDto) => {
        return (
          <>
            <p
              className="line-height-one"
              dangerouslySetInnerHTML={{
                __html: item.address.replaceAll("\n", "<br/>"),
              }}
            />
            <p className="line-height-one">{`${item.zipCode}${
              item.zipCode ? " " : ""
            }${item.city}`}</p>
            <p className="line-height-one">{item.country}</p>
          </>
        );
      },
    },
    {
      title: i18n.t("contact.phoneEmail").toString(),
      property: "phone",
      cellTemplate: (item: ContactReducedDto) => (
        <>
          <p className="line-height-one">{item.phone}</p>
          <p className="line-height-one">{item.email}</p>
        </>
      ),
    },
  ];

  if (contacts && contacts.some((x) => x.description)) {
    columnsDefinition.push({
      title: i18n.t("contact.description").toString(),
      property: "description",
    });
  }

  columnsDefinition.push({
    headerClassName: "three-buttons-container",
    itemClassName: "three-buttons-container text-end",
    canSort: false,
    cellTemplate: (item: ContactReducedDto) => (
      <>
        {item.inCompanyStructure && onInCompanyStructure !== null && (
          <TooltipButton
            aria-label="in company structure"
            tooltip={i18n.t("contact.inCompany").toString()}
            onClick={() => onInCompanyStructure!(item)}
          >
            <AccountTreeOutlinedIcon color="primary" />
          </TooltipButton>
        )}

        {canModify && (
          <TooltipButton
            aria-label="edit"
            tooltip={i18n.t("contact.edit.tooltip").toString()}
            onClick={() => {
              setContactModalTitle(i18n.t("contact.edit.title"));
              setContactModalContact(item);
              setContactModalOpen(true);
            }}
          >
            <Edit color="primary" />
          </TooltipButton>
        )}

        {canDelete && (
          <TooltipButton
            aria-label="delete"
            tooltip={i18n.t("contact.delete.tooltip").toString()}
            onClick={() => onDelete(item)}
          >
            <DeleteForever color="error" />
          </TooltipButton>
        )}
      </>
    ),
  });

  return columnsDefinition;
};

export interface ContactReducedDto extends CustomerContactReducedDto {}
