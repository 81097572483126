// Generated singletone class as described in https://refactoring.guru/design-patterns/singleton/typescript/example
export class VersionService {
  private static instance: VersionService;
  private onVersionChangedEvent: Event;

  private constructor() {
    this.onVersionChangedEvent = new CustomEvent("onVersionChangedEvent", {
      detail: {},
      bubbles: true,
      cancelable: true,
      composed: false,
    });
  }

  public static getInstance(): VersionService {
    if (!VersionService.instance) {
      VersionService.instance = new VersionService();
    }
    return VersionService.instance;
  }

  public getVersion(): string {
    return process.env.REACT_APP_UI_VERSION ?? "";
  }

  public setVersion(newVersion: string): void {
    // Check if version changed. On Dev System Old Version can be a substring of new Version which is valid.
    const versionChanged = !newVersion.includes(this.getVersion());

    if (versionChanged) {
      dispatchEvent(this.onVersionChangedEvent);
    }
  }
}
