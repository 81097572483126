import { Autocomplete, Button, Switch, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import {
  CustomFieldListDto,
  KemaroCleaningErrorDto,
  KemaroMissionRunPostDto,
} from "../../../../../../robotcloud-shared/resource-models";
import {
  NumericInput,
  TextInput,
  ToastHelper,
  TooltipButton,
} from "../../../../components/news";
import { KemaroRobotSearch } from "../../../../components/search/kemaro/KemaroRobotSearch";
import { KemaroMissionsRequests } from "../../../../data/kemaro/kemaroMissions.request";
import { ErrorHelper } from "../../../../uiHelpers/errors.helper";
import { GeneralFunctions } from "../../../generalFunctions";
import SettingsEthernetRoundedIcon from "@mui/icons-material/SettingsEthernetRounded";
import { KemaroMissionImage } from "./KemaroMissionImage";
import { useTranslation } from "react-i18next";
import useTheme from "@mui/material/styles/useTheme";
import { SupRemark } from "../../../../uiHelpers/SupRemark";
import { defaultError, defaultRunV2 } from "./models";
import { KemaroMissionRunShow } from "./KemaroMissionRunShow";
import { AutocompleteCustomField } from "./AutocompleteCustomField";
import { KemaroMissionsRunInputData } from "./KemaroMissionsRunInputData";

export const KemaroMissionsNewBody = (props: KemaroMissionsNewBodyProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [robotId, setRobotId] = useState("");
  const [runs, setRuns] = useState<KemaroMissionRunPostDto[]>([]);
  const [run, setRun] = useState<KemaroMissionRunPostDto>({
    ...defaultRunV2,
  });
  const [error, setError] = useState<KemaroCleaningErrorDto>({
    ...defaultError,
  });
  const [useDefaultMap, setUseDefaultMap] = useState(true);
  const [map, setMap] = useState<string | undefined>(undefined);
  const [startReason, setStartReason] = useState<
    CustomFieldListDto | undefined
  >(undefined);
  const [stopReason, setStopReason] = useState<CustomFieldListDto | undefined>(
    undefined
  );
  const [currentIana, setCurrentIana] = useState<string>("");

  useEffect(() => {
    setDefaultRun();
    setError({ ...defaultError, timeStamp: new Date() });
  }, []);

  const errorFill = async () => {
    const errorCode =
      props.errorCodes[Math.floor(Math.random() * props.errorCodes.length)];
    setError({
      ...error,
      errorCode: errorCode.title,
      mapPosition2D: {
        x: Math.random() * 2,
        y: Math.random() * 2,
      },
    });
  };

  const setDefaultRun = () => {
    var start = new Date();
    start.setMinutes(start.getMinutes() - Math.floor(Math.random() * 15));
    start.setSeconds(0);
    start.setMilliseconds(0);

    setRun({
      ...defaultRunV2,
      cleanedAreaM2: Math.floor(Math.random() * 100) + 80,
      freeAreaM2: Math.floor(Math.random() * 100),
      batteryPercentageStart: 100,
      batteryPercentageEnd: Math.floor(Math.random() * 90),
      timestampCleaningStart: start,
      timestampCleaningEnd: new Date(
        GeneralFunctions.dateToDateTimeUi(new Date())
      ),
    });
  };

  const handleAddRun = () => {
    if (error.errorCode) {
      run.cleaningErrors = [error];
    }

    if (startReason) {
      run.startReason = startReason.values[0];
    }

    if (stopReason) {
      run.stopReason = stopReason.values[0];
    }

    if (run.cleanedAreaM2 === 0) {
      run.cleanedAreaM2 = null;
    }

    if (run.freeAreaM2 === 0) {
      run.freeAreaM2 = null;
    }

    setRuns([...runs, run]);
    setDefaultRun();
    setError({ ...defaultError, timeStamp: new Date() });
    setStartReason(undefined);
    setStopReason(undefined);
  };

  const clearUi = () => {
    setRuns([]);
    setDefaultRun();
    setError({ ...defaultError, timeStamp: new Date() });
    setMap(undefined);
    setUseDefaultMap(true);
    setStartReason(undefined);
    setStopReason(undefined);
  };

  const handleSave = async () => {
    props.setIsWorking(true);

    try {
      const data = await KemaroMissionsRequests.postMission(
        robotId,
        useDefaultMap,
        {
          map: map ?? "",
          runs: runs,
          timezone: currentIana,
        }
      );

      props.onCreated(data);
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    props.setIsWorking(false);
    clearUi();
  };

  return (
    <>
      <div className="application-modal-body kemaro-missions-new-body">
        <div className="row">
          <div className="col-6">
            <div className="row">
              <div className="col-12">
                <KemaroRobotSearch
                  currentId={robotId}
                  selectionChange={(x) => setRobotId(x?.id ?? "")}
                />
              </div>
              <KemaroMissionsRunInputData
                title={t("mission.add.iana")}
                isMandatory={true}
              >
                <Autocomplete
                  className="auto-complete"
                  size="small"
                  freeSolo
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      placeholder={t("common.typeToSearch")}
                      fullWidth
                      variant="standard"
                      hiddenLabel
                    />
                  )}
                  options={props.ianaList}
                  onInputChange={(event, newValue) => setCurrentIana(newValue)}
                  value={currentIana}
                />
              </KemaroMissionsRunInputData>

              <div className="col-12 mt-4">
                <h4>{t("mission.add.run")}</h4>
              </div>

              <KemaroMissionsRunInputData
                title={t("mission.add.cleaningMode")}
                isMandatory={true}
              >
                <AutocompleteCustomField
                  items={props.cleaningMode}
                  propertyToShow={"title"}
                  value={run.cleaningMode ?? " "}
                  onChange={(x) => setRun({ ...run, cleaningMode: x })}
                />
              </KemaroMissionsRunInputData>

              <KemaroMissionsRunInputData title={t("mission.add.roomId")}>
                <TextInput
                  value={run.roomId ?? " "}
                  ret={(x) => setRun({ ...run, roomId: x })}
                />
              </KemaroMissionsRunInputData>

              <KemaroMissionsRunInputData title={t("mission.add.roomName")}>
                <TextInput
                  value={run.roomName ?? " "}
                  ret={(x) => setRun({ ...run, roomName: x })}
                />
              </KemaroMissionsRunInputData>

              <KemaroMissionsRunInputData title={t("mission.add.zoneId")}>
                <TextInput
                  value={run.zoneId ?? " "}
                  ret={(x) => setRun({ ...run, zoneId: x })}
                />
              </KemaroMissionsRunInputData>

              <KemaroMissionsRunInputData title={t("mission.add.zoneName")}>
                <TextInput
                  value={run.zoneName ?? " "}
                  ret={(x) => setRun({ ...run, zoneName: x })}
                />
              </KemaroMissionsRunInputData>

              <KemaroMissionsRunInputData title={t("mission.add.startReason")}>
                <AutocompleteCustomField
                  items={props.missionStartStopReason}
                  propertyToShow={"title"}
                  value={run.startReason ?? " "}
                  onChange={(x) => {
                    setRun({ ...run, startReason: x });
                    setStartReason(
                      props.missionStartStopReason.filter(
                        (y) => y.title === x
                      )[0]
                    );
                  }}
                />
              </KemaroMissionsRunInputData>

              <KemaroMissionsRunInputData title={t("mission.add.stopReason")}>
                <AutocompleteCustomField
                  items={props.missionStartStopReason}
                  propertyToShow={"title"}
                  value={run.stopReason ?? " "}
                  onChange={(x) => {
                    setRun({ ...run, stopReason: x });
                    setStopReason(
                      props.missionStartStopReason.filter(
                        (y) => y.title === x
                      )[0]
                    );
                  }}
                />
              </KemaroMissionsRunInputData>

              <KemaroMissionsRunInputData
                title={t("mission.add.cleaningStart")}
                isMandatory={true}
              >
                <TextField
                  className="w-100 pt-0"
                  type="datetime-local"
                  label=""
                  fullWidth
                  variant="standard"
                  margin="dense"
                  hiddenLabel
                  value={GeneralFunctions.dateToDateTimeUi(
                    run.timestampCleaningStart
                  )}
                  onChange={(event) =>
                    setRun({
                      ...run,
                      timestampCleaningStart: event.target.value
                        ? new Date(event.target.value)
                        : new Date(),
                    })
                  }
                />
              </KemaroMissionsRunInputData>

              <KemaroMissionsRunInputData
                title={t("mission.add.cleaningEnd")}
                isMandatory={true}
              >
                <TextField
                  className="w-100 pt-0"
                  type="datetime-local"
                  label=""
                  fullWidth
                  variant="standard"
                  margin="dense"
                  hiddenLabel
                  value={GeneralFunctions.dateToDateTimeUi(
                    run.timestampCleaningEnd
                  )}
                  onChange={(event) =>
                    setRun({
                      ...run,
                      timestampCleaningEnd: event.target.value
                        ? new Date(event.target.value)
                        : new Date(),
                    })
                  }
                />
              </KemaroMissionsRunInputData>

              {run.timestampCleaningStart.getTime() >
                run.timestampCleaningEnd.getTime() && (
                <div
                  className="col-12 ui-error text-center"
                  style={{
                    backgroundColor:
                      theme.palette.swarm?.kemaroMissions
                        ?.newBodyUiErrorBackground,
                  }}
                >
                  {t("mission.add.cleaningStartWarning")}
                </div>
              )}

              <div className="col-6 input-label">
                {t("mission.add.cleanedArea")} M<sup>2</sup>
              </div>
              <div className="col-6">
                <NumericInput
                  value={run.cleanedAreaM2 ?? 0}
                  ret={(x) => setRun({ ...run, cleanedAreaM2: x })}
                />
              </div>

              <div className="col-6 input-label">
                {t("mission.add.freeArea")} M<sup>2</sup>
              </div>
              <div className="col-6">
                <NumericInput
                  value={run.freeAreaM2 ?? 0}
                  ret={(x) => setRun({ ...run, freeAreaM2: x })}
                />
              </div>

              <div className="col-6 input-label">
                {t("mission.add.batteryStart")}
                <SupRemark />
              </div>
              <div className="col-6">
                <NumericInput
                  value={run.batteryPercentageStart}
                  minValue={0}
                  maxValue={100}
                  ret={(x) => setRun({ ...run, batteryPercentageStart: x })}
                />
              </div>
              <div className="col-6 input-label">
                {t("mission.add.batteryEnd")}
                <SupRemark />
              </div>
              <div className="col-6">
                <NumericInput
                  value={run.batteryPercentageEnd}
                  minValue={0}
                  maxValue={100}
                  ret={(x) => setRun({ ...run, batteryPercentageEnd: x })}
                />
              </div>
              {run.batteryPercentageEnd > 0 &&
                run.batteryPercentageEnd >= run.batteryPercentageStart && (
                  <div
                    className="col-12 ui-error text-center"
                    style={{
                      backgroundColor:
                        theme.palette.swarm?.kemaroMissions
                          ?.newBodyUiErrorBackground,
                    }}
                  >
                    {t("mission.add.batteryEndWarning")}
                  </div>
                )}

              <div className="col-12 mt-4">
                <h4>
                  <span className="me-3">{t("mission.add.error")}</span>
                  <TooltipButton
                    className="button"
                    tooltip={t("mission.add.generateErrorTooltip")}
                    onClick={() => errorFill()}
                  >
                    <SettingsEthernetRoundedIcon color="primary" />
                  </TooltipButton>
                </h4>
              </div>
              <div className="col-6 input-label">
                {t("mission.add.timeStamp")}
              </div>
              <div className="col-6">
                <TextField
                  className="w-100 pt-0"
                  type="datetime-local"
                  label=""
                  fullWidth
                  variant="standard"
                  margin="dense"
                  hiddenLabel
                  value={GeneralFunctions.dateToDateTimeUi(error.timeStamp)}
                  onChange={(event) =>
                    setError({
                      ...error,
                      timeStamp: event.target.value
                        ? new Date(event.target.value)
                        : new Date(),
                    })
                  }
                />
              </div>
              <div className="col-6 input-label">
                {t("mission.add.errorCode")}
              </div>
              <div className="col-6">
                <AutocompleteCustomField
                  items={props.errorCodes}
                  propertyToShow={"title"}
                  value={error.errorCode ?? " "}
                  onChange={(x) => setError({ ...error, errorCode: x })}
                />
              </div>

              <div className="col-6 input-label">X</div>
              <div className="col-6">
                <NumericInput
                  value={error.mapPosition2D!.x}
                  decimalPositions={10}
                  ret={(value) => {
                    const mp = { ...error.mapPosition2D!, x: value };
                    setError({ ...error, mapPosition2D: mp });
                  }}
                />
              </div>

              <div className="col-6 input-label">Y</div>
              <div className="col-6">
                <NumericInput
                  value={error.mapPosition2D!.y}
                  decimalPositions={10}
                  ret={(value) => {
                    const mp = { ...error.mapPosition2D!, y: value };
                    setError({ ...error, mapPosition2D: mp });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-6">
            {!useDefaultMap && (
              <KemaroMissionImage image={map} setImage={setMap} />
            )}

            <div className="row">
              <div className="col-12 text-center">
                <Switch
                  color="primary"
                  checked={useDefaultMap}
                  onChange={(event) => setUseDefaultMap(event.target.checked)}
                />
                <span>{t("mission.add.useDefaultMap")}</span>
              </div>
            </div>

            {runs.map((x, idx) => (
              <KemaroMissionRunShow key={idx} data={x} />
            ))}
          </div>
        </div>
      </div>

      <div className="application-modal-footer">
        <div className="row">
          <div className="col-6">
            <Button
              variant="contained"
              className="button-with-text"
              disabled={
                GeneralFunctions.isBlank(run.cleaningMode) ||
                (run.cleanedAreaM2 && run.cleanedAreaM2 <= 0) ||
                (run.freeAreaM2 && run.freeAreaM2 <= 0) ||
                run.batteryPercentageStart <= 0 ||
                run.batteryPercentageEnd < 0 ||
                run.timestampCleaningStart.getTime() >
                  run.timestampCleaningEnd.getTime() ||
                run.batteryPercentageEnd >= run.batteryPercentageStart
              }
              color="primary"
              onClick={handleAddRun}
            >
              {t("mission.add.addRunButton")}
            </Button>
          </div>

          <div className="col-6">
            <Button
              variant="contained"
              className="button-with-text"
              disabled={
                GeneralFunctions.isBlank(robotId) ||
                GeneralFunctions.isBlank(currentIana) ||
                runs.length === 0
              }
              color="primary"
              onClick={handleSave}
            >
              {t("common.buttons.save")}
            </Button>

            <Button
              className="modal-cancel-button button-with-text"
              variant="outlined"
              color="primary"
              onClick={props.handleCancel}
            >
              {t("common.buttons.cancel")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

interface KemaroMissionsNewBodyProps {
  errorCodes: any[];
  missionStartStopReason: CustomFieldListDto[];
  cleaningMode: CustomFieldListDto[];
  ianaList: string[];
  setIsWorking(isWorking: boolean): void;
  onCreated: (id: string) => void;
  onCancel: () => void;
  handleCancel: () => void;
}
