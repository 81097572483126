import { Fragment, useEffect, useState } from "react";
import {
  CompanyStructureReducedDto,
  SearchTextDtoOfGuid,
} from "../../../../robotcloud-shared/resource-models";
import { CompanyStructureRequests } from "../../data/companyStructure.request";
import { ErrorHelper } from "../../uiHelpers/errors.helper";
import { ToastHelper } from "../news/Toast.helper";
import { BaseSearch } from "./BaseSearch";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import "./search.scss";
import useTheme from "@mui/material/styles/useTheme";
import { Theme } from "@mui/material";

export const CompanyStructureSearch = (props: CompanyStructureSearchProps) => {
  const [items, setItems] = useState<CompanyStructureReducedDto[]>([]);
  const [currentItem, setCurrentItem] = useState<
    CompanyStructureReducedDto | undefined
  >(undefined);
  const [searchText, setSearchText] = useState("");
  const [lastSearch, setLastSearch] = useState("-");

  useEffect(() => {
    const textSearch: SearchTextDtoOfGuid = {
      textToSearch: searchText,
      id: props.currentId ?? null,
      maxResults: null,
      blackList: props.blackList ?? null,
    };

    const strTextSearch = JSON.stringify(textSearch);
    if (lastSearch === strTextSearch) {
      return;
    }

    setLastSearch(strTextSearch);
    getList(props.customerId, textSearch, searchText);
  }, [props, searchText, lastSearch]);

  const getList = async (
    customerId: string | null,
    textSearch: SearchTextDtoOfGuid,
    st: string
  ) => {
    try {
      const result = await CompanyStructureRequests.getSearchText(
        customerId,
        textSearch
      );

      if (result.searchedText === st) {
        setItems(result.items);
      }
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }
  };

  const selectionChange = (newValue: CompanyStructureReducedDto | null) => {
    if (!newValue) {
      setCurrentItem(undefined);
      props.selectionChange(undefined);
      return;
    }

    if (!props.clearAfterSelect) {
      setCurrentItem(newValue);
    }

    props.selectionChange(newValue);

    if (props.clearAfterSelect) {
      setLastSearch("-");
      setSearchText("");
    }
  };

  if (props.currentId) {
    const newCurrentItem = items.find((x) => x.id === props.currentId);
    if (newCurrentItem?.id !== currentItem?.id) {
      setCurrentItem(newCurrentItem);
    }
  }
  const theme = useTheme();

  return (
    <BaseSearch
      disableCloseOnSelect={props.clearAfterSelect}
      value={currentItem ?? defaultCompanyStructureReducedDto}
      options={items}
      getOptionLabel={generateOption}
      disabled={props.disabled}
      renderOption={(propsRender, x) => (
        <div
          key={x.id}
          className={
            "w-100 autocomplete-template py-2 px-3 " +
            (currentItem?.id === x.id ? "autocomplete-template-select" : "")
          }
          style={
            currentItem?.id === x.id
              ? {
                  backgroundColor:
                    theme.palette.swarm?.general
                      ?.autocompleteTemplateSelectBackground + " !important",
                }
              : {
                  backgroundColor:
                    theme.palette.swarm?.general
                      ?.autocompleteTemplateBackground,
                }
          }
          onClick={() => selectionChange(x)}
        >
          <p className="mb-0">{x.name}</p>

          {props.shortBreadcrumbs && <ShortBreadcrumbs dto={x} theme={theme} />}
          {!props.shortBreadcrumbs && (
            <CompleteBreadcrumbs dto={x} theme={theme} />
          )}
        </div>
      )}
      searchText={searchText}
      setSearchText={(x) => setSearchText(x)}
      onChange={(x) => selectionChange(x)}
    />
  );
};

interface CompanyStructureSearchProps {
  customerId: string | null;
  currentId?: string;
  clearAfterSelect?: boolean;
  disabled?: boolean;
  shortBreadcrumbs?: boolean;
  blackList?: string[] | undefined;
  selectionChange: (select: CompanyStructureReducedDto | undefined) => void;
}

const generateOption = (
  item: CompanyStructureReducedDto | undefined | string
) => {
  if (typeof item === "string") {
    return item;
  }

  return item && item.id ? `${item.name} [ ${item.customer} ]` : "";
};

const defaultCompanyStructureReducedDto: CompanyStructureReducedDto = {
  id: "",
  name: "",
  customer: "",
  parents: [],
};

const CompleteBreadcrumbs = ({ dto, theme }: BreadcrumbsProps) => (
  <p
    className="mb-0 search-explanation"
    style={{ color: theme.palette.swarm?.search?.searchExplanation }}
  >
    <span>{dto.customer}</span>

    {dto.parents.map((p) => (
      <Fragment key={p}>
        <ArrowForwardIosRoundedIcon className="px-1 icon" />
        {p}
      </Fragment>
    ))}
  </p>
);

const ShortBreadcrumbs = ({ dto, theme }: BreadcrumbsProps) => (
  <p
    className="mb-0 search-explanation"
    style={{ color: theme.palette.swarm?.search?.searchExplanation }}
  >
    {dto.parents.map(
      (p, idx) =>
        idx > 0 && (
          <Fragment key={p}>
            {idx > 1 && <ArrowForwardIosRoundedIcon className="px-1 icon" />}
            {p}
          </Fragment>
        )
    )}
  </p>
);

interface BreadcrumbsProps {
  dto: CompanyStructureReducedDto;
  theme: Theme;
}
