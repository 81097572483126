import { kemaroRobotsFilter } from "../Models";
import { getCompanyStructures } from "./GetCompanyStructures";
import { getCustomers } from "./GetCustomers";
import { getDealers } from "./GetDealers";
import { getKemaroDashboardEconomyCostCalculations } from "./GetKemaroDashboardEconomyCostCalculations";
import { getRobotModels } from "./GetRobotModels";

export const processParameters = async (
  idKemaroDashboardEconomyCostCalculation: string | null,
  idRobotModel: string | null,
  idCustomer: string | null,
  idDealer: string | null,
  idCompanyStructureBranch: string | null,
  id: string | null,
  filter: kemaroRobotsFilter,
  setFilter: (filter: kemaroRobotsFilter) => void,
  setIsProcessParameters: (isProcessParameters: boolean) => void
) => {
  const newFilter: kemaroRobotsFilter = {
    ...filter,
    ids: id ? [id] : [],
    kemaroDashboardEconomyCostCalculations:
      await getKemaroDashboardEconomyCostCalculations(
        idKemaroDashboardEconomyCostCalculation
      ),
    robotModels: await getRobotModels(idRobotModel),
    customers: await getCustomers(idCustomer),
    dealers: await getDealers(idDealer),
    companyStructures: await getCompanyStructures(idCompanyStructureBranch),
  };

  if (JSON.stringify(newFilter) !== JSON.stringify(filter)) {
    setFilter(newFilter);
  }

  setIsProcessParameters(false);
};
