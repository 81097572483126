import useTheme from "@mui/material/styles/useTheme";
import "./badgesPresenter.scss";
import { TooltipContainer } from "./TooltipContainer";
import { alpha, Theme } from "@mui/material/styles";

var theme: Theme;

export function BadgesPresenter(props: badgesPresenterProps) {
  theme = useTheme();

  return (
    <div
      className={`badges-presenter d-flex flex-wrap ${props.className}`}
      key="BadgesPresenter"
    >
      {props.items.map((x) =>
        x.canClick === undefined || x.canClick ? (
          <TooltipContainer text="remove" key={`tooltip_${x.type}_${x.id}`}>
            <span
              key={itemKey(x)}
              className={`badge rounded-pill mb-2 me-2 py-1 cursor-pointer ${getStyle(
                x.style
              )}`}
              style={getColors(x.style)}
              data-hover={itemKey(x)}
              onClick={() => {
                if (x.canClick === undefined || x.canClick) {
                  props.itemClick(x);
                }
              }}
            >
              <style>{`[data-hover=${itemKey(x)}]:hover {
                  background: ${alpha(
                    getColors(x.style).color!,
                    0.1
                  )}}`}</style>
              {x.text}
            </span>
          </TooltipContainer>
        ) : (
          <div key={`${x.type}_${x.id}`}>
            <span
              className={`badge rounded-pill mb-2 me-2 py-1 ${getStyle(
                x.style
              )}`}
              style={getColors(x.style)}
              onClick={() => {
                if (x.canClick === undefined || x.canClick) {
                  props.itemClick(x);
                }
              }}
            >
              {x.text}
            </span>
          </div>
        )
      )}
    </div>
  );
}

const itemKey = (item: any) => `${item.type}_${item.id.replaceAll(" ", "_")}`;

const getStyle = (style: StyleOptions | undefined) => {
  if (!style) {
    return "";
  }

  return !style ? `` : `rounded-pill-${style}`;
};

const getColors = (style: StyleOptions | undefined) => {
  const bP = theme.palette.swarm?.badgesPresenter;
  const styleName =
    (style || " ") in colorOptions ? colorOptions[style || " "] : "";

  return {
    color: bP?.[`roundedPill${styleName}` as keyof typeof bP],
    borderColor: bP?.[`roundedPill${styleName}Border` as keyof typeof bP],
  };
};

interface badgesPresenterProps {
  items: BadgeItem[];
  itemClick: (item: BadgeItem) => void;
  className?: string;
}

export interface BadgeItem {
  id: string;
  type: string;
  text: string;
  canClick?: boolean;
  style?: StyleOptions;
  source?: any;
}

const colorOptions = {
  " ": "",
  robot: "Robot",
  "serial-number": "Serial",
  name: "Name",
  model: "Model",
  customer: "Customer",
  dealer: "Dealer",
  contact: "Contact",
  manufacturer: "Manufacturer",
  mission: "Mission",
  status: "Status",
  country: "Country",
  "dashboard-calculation": "Dashboard",
  various: "Various",
  "text-search": "Search",
  "company-structure": "Company",
  "error-codes": "Error",
  "stop-reason": "StopReason",
  "start-reason": "StartReason",
  "cleaning-mode": "CleaningMode",
  "zone-names": "ZoneNames",
  "room-names": "RoomNames",
  tag: "Tag",
};

type StyleOptions =
  | "robot"
  | "serial-number"
  | "name"
  | "model"
  | "customer"
  | "dealer"
  | "contact"
  | "manufacturer"
  | "mission"
  | "status"
  | "country"
  | "dashboard-calculation"
  | "various"
  | "text-search"
  | "company-structure"
  | "error-codes"
  | "stop-reason"
  | "start-reason"
  | "cleaning-mode"
  | "zone-names"
  | "room-names"
  | "tag";
