import Box from "@mui/material/Box";
import PlaylistAdd from "@mui/icons-material/PlaylistAdd";
import { Loading } from "../Loading";
import { TooltipButton } from "./TooltipButton";
import { ReactElement } from "react";

export const TitleWithAction = (props: titleWithActionProps) => {
  if (props.isFetching) {
    return <Loading isLoading={props.isFetching} size={30} />;
  }

  return (
    <Box className={props.titleContainerClassName}>
      <span>{props.title}</span>
      {props.showButton && props.children && props.children}
      {props.showButton && !props.children && (
        <TooltipButton onClick={props.onClick} tooltip={props.tooltip}>
          <PlaylistAdd color="primary" fontSize="medium" />
        </TooltipButton>
      )}
    </Box>
  );
};

export interface titleWithActionProps {
  title: string;
  titleContainerClassName?: string;
  tooltip: string;
  isFetching?: boolean;
  showButton?: boolean;
  children?: ReactElement | never[] | null;
  onClick?: () => void;
}

TitleWithAction.defaultProps = {
  showButton: true,
  isFetching: false,
};
