import { Box, Button, Dialog, Grid, Stack, useTheme } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { SwarmRemoteManufacturerConnectionDto } from "../../../../../robotcloud-shared/resource-models";
import { TooltipCopyButton } from "../../../components/news/TooltipCopyButton";
import { TooltipToogleVisibilyButton } from "../../../components/news/TooltipToogleVisibilyButton";
import { RobotConnectionConfigFileButton } from "./RobotConnectionConfigFileButton";
import { RobotConnectionServiceUrlLink } from "./RobotConnectionServiceUrlLink";

export const RobotConnectionModal = ({
  robotConnection,
  onClose,
}: RobotConnectionModalProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(true);
  const changeShowPassword = (isVisible: boolean) => {
    setShowPassword(isVisible);
  };
  return (
    <Dialog
      className="application-modal"
      open={true}
      maxWidth="md"
      onClose={onClose}
    >
      <div className="modal-content">
        <Stack>
          <Box className="application-modal-header">
            <h3>{t("robot.connection.modal.title")}</h3>
          </Box>
          <Box className="application-modal-body">
            {robotConnection && (
              <Grid
                container
                className="p-3 bg-white"
                sx={{
                  backgroundColor:
                    theme.palette.swarm?.robots?.connectionBackground,
                }}
              >
                <Grid item xs={3} className="mt-2">
                  {t("robot.connection.serviceUrls")}
                </Grid>
                <Grid item xs={9} className="mt-2">
                  {robotConnection.serviceUrls.map((url) => (
                    <RobotConnectionServiceUrlLink key={url} url={url} />
                  ))}
                </Grid>

                <Grid item xs={3} className="mt-2">
                  {t("robot.connection.vncUser")}
                </Grid>
                <Grid item xs={7} className="mt-2">
                  {robotConnection.vncUser}
                </Grid>
                <Grid item xs={2} className="d-flex justify-content-end">
                  <TooltipCopyButton textToCopy={robotConnection.vncUser} />
                </Grid>

                <Grid item xs={3} className="mt-2">
                  {t("robot.connection.vncPassword")}
                </Grid>
                <Grid item xs={7} className="mt-2">
                  {showPassword
                    ? robotConnection.vncPassword
                    : "*".repeat(robotConnection.vncPassword.length)}
                </Grid>
                <Grid item xs={2} className="d-flex justify-content-end">
                  <TooltipToogleVisibilyButton
                    initialValue={false}
                    onShowPasswordChanged={changeShowPassword}
                  />
                  <TooltipCopyButton
                    className="ms-3"
                    textToCopy={robotConnection.vncPassword}
                  />
                </Grid>

                <Grid item xs={3} className="mt-2">
                  {t("robot.connection.modal.networkAccess")}
                </Grid>
                <Grid item xs={9} className="d-flex justify-content-end">
                  <RobotConnectionConfigFileButton
                    robotId={robotConnection.externalId}
                  />
                </Grid>
              </Grid>
            )}
          </Box>
          <Box className="application-modal-footer">
            <Button
              className="modal-cancel-buttonv button-with-text"
              variant="outlined"
              color="primary"
              onClick={onClose}
            >
              {t("common.buttons.close")}
            </Button>
          </Box>
        </Stack>
      </div>
    </Dialog>
  );
};

export interface RobotConnectionModalProps {
  robotConnection: SwarmRemoteManufacturerConnectionDto;
  onClose: () => void;
}
