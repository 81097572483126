import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface IDashBoardTabs {
  currentTab: string;
}

export const KemaroDashboardTabs = (props: IDashBoardTabs) => {
  const { t } = useTranslation();

  return (
    <>
      <Tabs value={props.currentTab}>
        <Tab
          label={t("applicationBar.dashboard.tabs.economy")}
          value="Economy"
          to="/kemaro-dashboard-economy"
          component={Link}
        />
      </Tabs>
      <Box borderTop={"2px solid rgba(0,0,0,0.2)"} />
    </>
  );
};
