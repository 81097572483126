import { useEffect, useState } from "react";
import { KemaroMissionRunDto } from "../../../../../robotcloud-shared/resource-models";
import { KemaroMissionViewRun } from "./KemaroMissionViewRun";
import { useTranslation } from "react-i18next";

export const KemaroMissionViewRuns = (props: kemaroMissionViewRunsProps) => {
  const [expandedRuns, setExpandedRuns] = useState<string[]>([]);
  const [oldReportId, setOldReportId] = useState<string>(props.reportId);
  const { t } = useTranslation();

  useEffect(() => {
    if (oldReportId !== props.reportId) {
      setExpandedRuns([]);
      setOldReportId(props.reportId);
    }
  }, [props, oldReportId]);

  if (!props.runs || props.runs.length === 0) {
    return <></>;
  }

  let runNumber = 0;
  let runTotal = props.runs.length;

  const setExpandedChange = (run: string, currentState: boolean) => {
    if (currentState && expandedRuns.indexOf(run) < 0) {
      const newExpandedRuns = [...expandedRuns, run];
      setExpandedRuns(newExpandedRuns);
    } else if (!currentState) {
      const idx = expandedRuns.indexOf(run);
      if (idx >= 0) {
        var newExpandedRuns = [...expandedRuns];
        newExpandedRuns.splice(idx, 1);
        setExpandedRuns(newExpandedRuns);
      }
    }
  };

  return (
    <div className="runs-container">
      <p className="title mb-3">{t("mission.viewRuns.runs")}</p>

      <div className="accordion-container">
        {props.runs.map((run) => {
          runNumber += 1;
          return (
            <KemaroMissionViewRun
              key={`${props.reportId}_run_${runNumber}`}
              total={runTotal}
              number={runNumber}
              baseName={`${props.reportId}_run_`}
              run={run}
              expandedRuns={expandedRuns}
              errorCodesAsButton={props.errorCodesAsButton}
              expandedChange={setExpandedChange}
            />
          );
        })}
      </div>
    </div>
  );
};

interface kemaroMissionViewRunsProps {
  reportId: string;
  runs: KemaroMissionRunDto[];
  errorCodesAsButton: boolean;
}
