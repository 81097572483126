import { useContext } from "react";
import { Pagination } from "../../../components/news";
import KemaroRobotContext from "./KemaroRobotContext";
import { PaginationDto } from "../../../data/common";

export const KemaroRobotsLeftSideFooter = (
  props: KemaroRobotsLeftSideFooterProps
) => {
  const { pagination, setPagination, setCurrentItem } =
    useContext(KemaroRobotContext);

  const pageChangeHandle = (pagination: PaginationDto) => {
    setPagination(pagination);
    setCurrentItem(undefined);
  };

  return (
    <Pagination
      containerClassName="kemaro-robot-list-pager"
      pagination={pagination}
      isFetching={props.isFetchingList}
      onChange={(x) => pageChangeHandle(x)}
    />
  );
};

interface KemaroRobotsLeftSideFooterProps {
  isFetchingList: boolean;
}
