export const KemaroMissionRunShowData = (
  props: KemaroMissionRunShowDataProps
) => {
  const hasValue =
    (typeof props.value === "number" && props.value > 0) ||
    (typeof props.value === "string" && props.value.length > 0);

  return (
    <div className={`col-${props.fullWidht ? "12" : 6} show-data`}>
      <p className="title mb-0" style={{ color: props.titleColor }}>
        {props.text}
      </p>
      {hasValue && <p className="value mb-1">{props.value}</p>}
      {!hasValue && <p className="value mb-1">-NULL-</p>}
    </div>
  );
};

interface KemaroMissionRunShowDataProps {
  titleColor: string | undefined;
  text: string;
  value: string | number | null;
  fullWidht?: boolean;
}
