import Box from "@mui/material/Box";
import { UserGridDto } from "../../../../robotcloud-shared/resource-models";
import { ApplicationBar } from "../../components/news/ApplicationBar";
import { CrudItemsPanel } from "../../components/news/CrudItemsPanel";
import { OrganizationTabsNav } from "../../components/news/OrganizationTabsNav";
import { Pagination } from "../../components/news/Pagination";
import { TableGeneric } from "../../components/news/TableGeneric/TableGeneric";
import { PermissionsHelper } from "../../uiHelpers/permissionsHelper";
import { UsersCreateModal } from "../shared/users/UsersCreateModal";
import PersonSearchRoundedIcon from "@mui/icons-material/PersonSearchRounded";

import "./users.scss";
import { UserView } from "./UserView";
import { TooltipButton } from "../../components/news/TooltipButton";
import { UserFromGraphModal } from "./UserFromGraphModal";
import { useTranslation } from "react-i18next";
import { TableGenericColumn } from "../../components/news/TableGeneric/models";
import useTheme from "@mui/material/styles/useTheme";
import { useUsers } from "../../hooks";

export const Users: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const {
    textSearchChange,
    handleCreate,
    handleEdit,
    handleUpdate,
    handleDelete,
    pageChange,
    items,
    currentItem,
    isFetchingList,
    isFetchingItem,
    pagination,
    dataOrder,
    setDataOrder,
    setCurrentId,
    userFromGraphModalOpen,
    setUserFromGraphModalOpen,
    handleGraphClose,
    userCreateModalOpen,
    setUserCreateModalOpen,
  } = useUsers();

  const columnsDefinition = () => {
    let columnsDefinition: TableGenericColumn[] = [
      {
        title: t("common.name"),
        property: "name",
        cellTemplate: (item: UserGridDto) => (
          <span
            className={item.enabled ? "" : " user-disabled"}
            style={
              item.enabled
                ? {}
                : { color: theme.palette.swarm?.general?.userDisabled }
            }
          >
            {item.name}
          </span>
        ),
      },
      {
        title: `${t("user.userName")} / ${t("user.primaryEmailAddress")}`,
        cellTemplate: (item: UserGridDto) => (
          <span
            className={item.enabled ? "" : " user-disabled"}
            style={
              item.enabled
                ? {}
                : { color: theme.palette.swarm?.general?.userDisabled }
            }
          >
            {item.userName?.length > 0
              ? item.userName
              : item.primaryEmailAddress}
          </span>
        ),
      },
      {
        title: t("user.accessType"),
        property: "accessType",
        cellTemplate: (item: UserGridDto) => (
          <span
            className={item.enabled ? "" : " user-disabled"}
            style={
              item.enabled
                ? {}
                : { color: theme.palette.swarm?.general?.userDisabled }
            }
          >
            {item.accessType}
          </span>
        ),
      },
      {
        title: t("user.accessName"),
        property: "accessName",
        canSort: false,
        cellTemplate: (item: UserGridDto) => (
          <span
            className={item.enabled ? "" : " user-disabled"}
            style={
              item.enabled
                ? {}
                : { color: theme.palette.swarm?.general?.userDisabled }
            }
          >
            {item.accessName}
          </span>
        ),
      },
    ];

    return columnsDefinition;
  };

  return (
    <Box className="page-content">
      <Box className="page-header">
        <ApplicationBar
          title={t("applicationBar.organization.title")}
          search={textSearchChange}
        />
      </Box>

      <OrganizationTabsNav currentTab="users" />

      <Box className="page-body d-flex users-body">
        <div className="users-left-side">
          <CrudItemsPanel
            title={t("user.listTitle")}
            titleRightSide={
              <TooltipButton
                tooltip={t("user.addGraph.tooltip")}
                onClick={() => setUserFromGraphModalOpen(true)}
              >
                <PersonSearchRoundedIcon color="primary" />
              </TooltipButton>
            }
            createToolTip={t("user.create.tooltip")}
            containerClassName="users-list-items"
            isFetching={isFetchingList}
            items={items}
            canCreate={PermissionsHelper.allow("Users", "Create")}
            onCreateClick={() => setUserCreateModalOpen(true)}
          >
            <TableGeneric
              tableClassName=""
              showHeader={true}
              columns={columnsDefinition()}
              items={items ?? []}
              idProperty="id"
              headerClick={(header, itemsOrder) => setDataOrder(itemsOrder)}
              selectionChanged={(item) => setCurrentId(item.id)}
              itemsOrder={dataOrder}
              selectId={currentItem?.id}
              isAwaiting={isFetchingItem}
              captureKeyboard
            />
          </CrudItemsPanel>

          <Pagination
            containerClassName="users-list-pager"
            pagination={pagination}
            isFetching={isFetchingList}
            onChange={pageChange}
          />

          {userCreateModalOpen && (
            <UsersCreateModal
              isOpenModal={userCreateModalOpen}
              accessType={undefined}
              accessId={undefined}
              onCancel={() => setUserCreateModalOpen(false)}
              onSaved={handleCreate}
            />
          )}

          {userFromGraphModalOpen && (
            <UserFromGraphModal
              isOpenModal={userFromGraphModalOpen}
              onClose={handleGraphClose}
            />
          )}
        </div>

        <Box className="view-container">
          <UserView
            item={currentItem}
            isFetching={isFetchingItem}
            onEdit={handleEdit}
            onUpdate={handleUpdate}
            onDelete={handleDelete}
          />
        </Box>
      </Box>
    </Box>
  );
};
