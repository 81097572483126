import { useEffect } from "react";
import { Dialog } from "@mui/material";
import { CustomerContactReducedDto } from "../../../../../robotcloud-shared/resource-models";
import { tableGenericHandleKeyboardSubjectManager } from "../../../components/news";
import { ContactsView, contactsViewProps } from "./ContactsView";

export const ContactsModal = (props: contactsModalProps) => {
  useEffect(() => {
    tableGenericHandleKeyboardSubjectManager.setData({
      caller: "ContactsModal",
      handleKeyboard: props.isOpenModal,
    });
  }, [props.isOpenModal]);

  return (
    <Dialog
      className="application-modal"
      open={props.isOpenModal}
      maxWidth="md"
    >
      <ContactsView
        title={props.title}
        onCancel={props.onCancel}
        onSave={props.onSave}
        contact={props.contact}
      />
    </Dialog>
  );
};

interface contactsModalProps extends contactsViewProps {
  isOpenModal: boolean;
}

export interface ContactReducedDto extends CustomerContactReducedDto {}
