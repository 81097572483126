import { useEffect, useState } from "react";
import { KemaroDashboardEconomyCostCalculationsRequests } from "../../../../../data/kemaro/kemaroDashboardEconomyCostCalculations.requests";
import { DashboardSpHistoryMessageLogsDto } from "../../../../../../../robotcloud-shared/resource-models";
import { ToastHelper } from "../../../../../components/news";
import { ErrorHelper } from "../../../../../uiHelpers/errors.helper";

export const useDashboardExecutionHistory = (modalOpen: boolean) => {
  const [isGettingExecutionHistory, setIsGettingExecutionHistory] =
    useState(false);
  const [executionHistory, setExecutionHistory] = useState<
    DashboardSpHistoryMessageLogsDto[]
  >([]);

  useEffect(() => {
    if (!modalOpen) {
      setExecutionHistory([]);
      setIsGettingExecutionHistory(false);
      return;
    }

    getData();
  }, [modalOpen]);

  const getData = async () => {
    setIsGettingExecutionHistory(true);

    try {
      setExecutionHistory(
        await KemaroDashboardEconomyCostCalculationsRequests.executionHistory()
      );
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsGettingExecutionHistory(false);
  };

  return { isGettingExecutionHistory, executionHistory };
};
