import { OrderDto } from "../../../data/common";

export class TableGenericHelper {
  static localOrder = (
    itemsOrder: OrderDto,
    items: any[],
    setOrder?: (order: OrderDto) => void
  ) => {
    let valueForUpper = 1;
    let valueForLower = -1;

    if (itemsOrder.order === "Desc") {
      valueForUpper = -1;
      valueForLower = 1;
    }

    items = items.sort((a: any, b: any) =>
      a[itemsOrder.orderColumns].toLowerCase() >
      b[itemsOrder.orderColumns].toLowerCase()
        ? valueForUpper
        : valueForLower
    );

    if (setOrder) {
      setOrder({
        orderColumns: itemsOrder.orderColumns,
        order: itemsOrder.order,
      });
    }
  };
}
