// hooks/useSignalRConnection.ts
import { useState, useEffect, SetStateAction, Dispatch } from "react";
import { HubConnection, HubConnectionState } from "@microsoft/signalr";
import { signalRHubEnum } from "../views/enums/signalRHubEnum";
import {
  ApiLoginCompleteDto,
  ManufacturerReducedDto,
} from "../../../robotcloud-shared/resource-models";
import { ErrorHelper } from "../uiHelpers/errors.helper";
import { ToastHelper } from "../components/news";

export const useSignalRConnection = (
  setCurrentManufacturer: Dispatch<
    SetStateAction<ManufacturerReducedDto | undefined>
  >,
  me?: ApiLoginCompleteDto,
  currentManufacturer?: ManufacturerReducedDto
) => {
  const [signalRConnection, setSignalRConnection] =
    useState<HubConnection | null>(null);
  const [connectionIds, setConnectionIds] = useState<string>("--");
  const [kemaroMissionChange, setKemaroMissionChange] = useState<
    Date | undefined
  >(undefined);

  useEffect(() => {
    if (
      !signalRConnection ||
      signalRConnection.state === HubConnectionState.Connected
    ) {
      return;
    }

    signalRConnection
      .start()
      .then(() => {
        signalRConnection.on(signalRHubEnum.UpdateToken, () => {
          setCurrentManufacturer((state) => {
            const manufacturer: ManufacturerReducedDto = {
              id: state?.id ?? "",
              name: state?.name ?? "",
              role: state?.role ?? "Viewer",
            };

            return manufacturer;
          });
        });

        signalRConnection.on(signalRHubEnum.KemaroMissionChange, () => {
          setKemaroMissionChange(new Date());
        });
      })
      .catch((e) => ToastHelper.errors(ErrorHelper.process(e)));
  }, [signalRConnection]);

  return {
    signalRConnection,
    setSignalRConnection,
    kemaroMissionChange,
    setKemaroMissionChange,
  };
};
