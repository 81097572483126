import { MenuItem, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import {
  OnBoardingLanguage,
  onBoardingLanguages,
  Workflow,
} from "./onBoardingService";

const defaultLanguage = { code: "--", name: "Default language" };

export const WorkflowLanguageSelector = ({
  workflow,
  selectionChange,
  current,
}: WorkflowLanguageSelectorProps) => {
  const onSelectionChange = (newValue: string | null) => {
    if (!newValue) {
      selectionChange(undefined);
      return;
    }

    const selected = onBoardingLanguages.find((x) => x.code === newValue);
    selectionChange(selected?.code ?? "--");
  };

  const [orderedLanguages, setOrderedLanguages] = useState<
    OnBoardingLanguage[]
  >([defaultLanguage]);

  useEffect(() => {
    if (!workflow || workflow.workflowTranslatedDatas?.length === 0) {
      setOrderedLanguages([defaultLanguage]);
    } else {
      const newLanguages: OnBoardingLanguage[] = [defaultLanguage];
      workflow.workflowTranslatedDatas.forEach((workflowTranslatedData) => {
        var onBoardingLanguage = onBoardingLanguages.find(
          (x) => x.code === workflowTranslatedData.languageCode
        );
        if (onBoardingLanguage) {
          newLanguages.push(onBoardingLanguage);
        }
      });
      setOrderedLanguages(newLanguages);
    }
  }, [workflow]);

  return (
    <TextField
      select
      fullWidth
      variant="standard"
      margin="dense"
      hiddenLabel
      value={current ?? "--"}
      onChange={(x) => onSelectionChange(x.target.value)}
    >
      {orderedLanguages.sort().map((item) => {
        return (
          <MenuItem key={item.code} value={item.code}>
            {item.name}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

interface WorkflowLanguageSelectorProps {
  workflow: Workflow;
  current?: string;
  selectionChange: (select: string | undefined) => void;
}
