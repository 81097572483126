import { useState, useEffect } from "react";
import { KemaroRobotConnectionDto } from "../../../robotcloud-shared/resource-models";
import { ToastHelper } from "../components/news";
import { KemaroRobotRequests } from "../data/kemaro/kemaroRobot.request";

export const useKemaroConnectionParameters = (
  robotId: string,
  shouldFetch: boolean
) => {
  const [kemaroConnectionParameters, setKemaroConnectionParameters] = useState<
    KemaroRobotConnectionDto | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (shouldFetch && robotId) {
      setIsLoading(true);
      KemaroRobotRequests.getConnectionParameters(robotId)
        .then((result) => {
          setKemaroConnectionParameters(result);
        })
        .catch((error) => {
          ToastHelper.error(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [robotId, shouldFetch]);
  return {
    kemaroConnectionParameters,
    isLoading,
    setKemaroConnectionParameters,
  };
};

export default useKemaroConnectionParameters;
