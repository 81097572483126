import { useTranslation } from "react-i18next";
import { GeneralFunctions } from "../../../../generalFunctions";
import { CredentialPasswordInput } from "./CredentialPasswordInput";
import { CredentalContainer } from "./models";

export const CredentialDisplayer = (props: CredentialDisplayerProps) => {
  const { t } = useTranslation();

  const passwordChange = (newPassword: string) => {
    props.setCredentalContainer({
      ...props.credentalContainer,
      credential: {
        ...props.credentalContainer.credential,
        password: newPassword,
      },
    });
  };

  return (
    <>
      <div className="col-12 mb-2 pb-1 credential-title">{props.roleName}</div>

      <div className="col-4 pt-3">{t("robot.localCredential.password")}</div>
      <div className="col-8">
        <CredentialPasswordInput
          value={props.credentalContainer.credential.password}
          config={props.credentalContainer.config}
          canEdit={props.canEdit}
          setValue={passwordChange}
          discardChanges={() =>
            passwordChange(props.credentalContainer.originalPassword)
          }
        />
      </div>

      {props.credentalContainer.credential.timestamp && (
        <>
          <div className="col-4 pt-3">
            {t("robot.localCredential.timeStamp")}
          </div>
          <div className="col-8 pt-3">
            {GeneralFunctions.dateToTimeDateString(
              props.credentalContainer.credential.timestamp
            )}
          </div>
        </>
      )}
    </>
  );
};

interface CredentialDisplayerProps {
  roleName: string;
  canEdit: boolean;
  credentalContainer: CredentalContainer;
  setCredentalContainer: (credentalContainer: CredentalContainer) => void;
}
