import { Button, Dialog, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { ManufacturerCompleteDto } from "../../../../robotcloud-shared/resource-models";
import { TextInput } from "../../components/news/TextInput";
import { GeneralFunctions } from "../generalFunctions";
import { useTranslation } from "react-i18next";
import { tableGenericHandleKeyboardSubjectManager } from "../../components/news";
import { SupRemark } from "../../uiHelpers/SupRemark";

export const ManufacturerEditModal = (props: manufacturerEditModalProps) => {
  const { t } = useTranslation();

  const [editedDealer, setEditedDealer] = useState<ManufacturerCompleteDto>(
    props.item
  );

  useEffect(() => {
    setEditedDealer(props.item);
  }, [props.item]);

  useEffect(() => {
    tableGenericHandleKeyboardSubjectManager.setData({
      caller: "ManufacturerEditModal",
      handleKeyboard: props.openPopup,
    });
  }, [props.openPopup]);

  const editName = (newName: string) => {
    const newEditedDealer = {
      ...editedDealer,
      name: newName,
    };

    setEditedDealer(newEditedDealer);
  };

  const handleCancel = () => {
    setEditedDealer(props.item);
    props.onCancel();
  };

  return (
    <Dialog
      className="application-modal"
      open={props.openPopup}
      maxWidth="md"
      onClose={handleCancel}
    >
      <div className="modal-content">
        <Stack>
          <Box className="application-modal-header">
            <h3>{t("manufacturer.edit.title")}</h3>
          </Box>
          <Box className="application-modal-body">
            <Grid container>
              <Grid className="input-label" item xs={4}>
                {t("common.name")}
                <SupRemark />
              </Grid>
              <Grid item xs={8}>
                <TextInput value={editedDealer.name} ret={editName} />
              </Grid>
            </Grid>
          </Box>
          <Box className="application-modal-footer">
            <Button
              variant="contained"
              className="button-with-text"
              disabled={GeneralFunctions.isBlank(editedDealer.name)}
              color="primary"
              onClick={() => props.onSave(editedDealer)}
            >
              {t("common.buttons.save")}
            </Button>

            <Button
              className="modal-cancel-button button-with-text"
              variant="outlined"
              color="primary"
              onClick={handleCancel}
            >
              {t("common.buttons.cancel")}
            </Button>
          </Box>
        </Stack>
      </div>
    </Dialog>
  );
};

export interface manufacturerEditModalProps {
  openPopup: boolean;
  item: ManufacturerCompleteDto;
  onSave: (item: ManufacturerCompleteDto) => void;
  onCancel: () => void;
}
