import { createContext } from "react";
import {
  KemaroRobotCrudDto,
  KemaroRobotFilterCriteriaDto,
  KemaroRobotListDto,
} from "../../../../../robotcloud-shared/resource-models";
import {
  kemaroRobotsFilter,
  kemaroRobotsFilterDefault,
} from "./Models/kemaroRobotsFilter";
import {
  OrderDto,
  PaginationDto,
  orderDtoDefault,
  paginationDtoDefault,
} from "../../../data/common";

const KemaroRobotContext = createContext<KemaroRobotContextData>({
  currentItem: undefined,
  setCurrentItem: () => {},
  currentItemIsFeching: false,
  setCurrentItemIsFeching: () => {},
  refreshItem: () => {},
  refreshItems: (
    idToShow?: string | undefined | null,
    refreshCurrent?: boolean | undefined | null,
    selectFirstItem?: boolean | undefined | null
  ) => {},
  filter: { ...kemaroRobotsFilterDefault },
  setFilter: () => {},
  pagination: { ...paginationDtoDefault },
  setPagination: () => {},
  dataOrder: { ...orderDtoDefault },
  setDataOrder: () => {},
  getById: async () => {},
  getList: () => {},
  viewSideModalOpenChange: false,
  setViewSideModalOpenChange: () => {},
  getNextItem: () => undefined,
});

export interface KemaroRobotContextData {
  currentItem: KemaroRobotCrudDto | undefined;
  setCurrentItem(item: KemaroRobotCrudDto | undefined): void;
  currentItemIsFeching: boolean;
  setCurrentItemIsFeching(isFeching: boolean): void;
  refreshItem(): void;
  refreshItems(
    idToShow?: string | undefined | null,
    refreshCurrent?: boolean | undefined | null,
    selectFirstItem?: boolean | undefined | null
  ): void;
  filter: kemaroRobotsFilter;
  setFilter(filter: kemaroRobotsFilter): void;
  pagination: PaginationDto;
  setPagination(pagination: PaginationDto): void;
  dataOrder: OrderDto;
  setDataOrder(order: OrderDto): void;
  getById(currentId: string): Promise<void>;
  getList(
    conditions: KemaroRobotFilterCriteriaDto,
    page: PaginationDto,
    currentItem: KemaroRobotCrudDto | undefined,
    setPage: (page: PaginationDto) => void
  ): void;
  viewSideModalOpenChange: boolean;
  setViewSideModalOpenChange(currentId: boolean): void;
  getNextItem(itemId: string): KemaroRobotListDto | undefined;
}

export default KemaroRobotContext;
