import { Loading } from "../../../components/Loading";
import { useTranslation } from "react-i18next";
import { NativeSelect } from "@mui/material";
import { KemaroRobotViewCleanedSurfaceChartFooter } from "./KemaroRobotViewCleanedSurfaceChartFooter";
import { KemaroRobotViewCleanedAreaHistoryChart } from "./KemaroRobotViewCleanedAreaHistoryChart";
import { KemaroRobotCleaningDataTotalsDtoType } from "../../../../../robotcloud-shared/resource-models";
import { useKemaroRobotCleanedSurfaceYears } from "./hooks/useKemaroRobotCleanedSurfaceYears";
import { useKemaroRobotCleanedSurfaceChart } from "./hooks/useKemaroRobotCleanedSurfaceChart";

export const KemaroRobotViewCleanedAreaHistory = (
  props: KemaroRobotViewCleanedAreaHistoryProps
) => {
  const { t } = useTranslation();

  const {
    isFetching: isFetchingYears,
    years,
    currentYear,
    setCurrentYear,
  } = useKemaroRobotCleanedSurfaceYears(props.dataTotal);

  const {
    isFetching: isFetchingChart,
    cleanedSurfaceChartDto,
    chartSizeXl,
    chartSizeXxl,
  } = useKemaroRobotCleanedSurfaceChart(currentYear, props.dataTotal);

  const isFeching = isFetchingYears || isFetchingChart;

  const colorScaleFn = (value: number | { valueOf(): number }) => {
    let idx: number = value as number;
    if (idx < 0) idx = 0;

    return cleanedSurfaceChartDto?.colorRules[idx].color ?? "#eeeeee";
  };

  colorScaleFn.ticks = () => [];

  return (
    <>
      <div className=" d-none d-xl-block container-fluid ps-0 ms-0">
        <div className="row">
          <div className="col-4 text-start">
            <span className="mb-0 title">
              {t("robot.cleaningInformation.cleanedAreaHistory")}
            </span>
          </div>

          {isFeching && <Loading isLoading={isFeching} />}

          {!isFeching && (
            <div className="col-8 d-flex justify-content-end">
              <span className="fw-bold ps-4 pe-3 align-self-center">
                {t("robot.cleaningInformation.yearsAvailable")}
              </span>
              <NativeSelect
                onChange={(event) =>
                  setCurrentYear(parseInt(event.target.value, 10))
                }
                value={currentYear}
              >
                {years.map((x) => (
                  <option key={x} value={x}>
                    {x}
                  </option>
                ))}
              </NativeSelect>
            </div>
          )}
        </div>
      </div>

      {/* These divs have the same width as the chart.
      Its function is that the component has the same width when it has or not chart. */}
      <div className="container d-none d-xl-block ">
        {isFeching && (
          <>
            <div
              className="d-none d-xl-block d-xxl-none"
              style={{
                height: "20px",
                width: `${chartSizeXl.width}px`,
              }}
            ></div>

            <div
              className="d-none d-xxl-block"
              style={{
                height: "20px",
                width: `${chartSizeXxl.width}px`,
              }}
            ></div>
          </>
        )}
        <div className="row">
          {!isFeching && cleanedSurfaceChartDto && (
            <KemaroRobotViewCleanedAreaHistoryChart
              className="col justify-self-center"
              chartSize={chartSizeXxl}
              cleanedSurfaceChartDto={cleanedSurfaceChartDto}
            />
          )}

          {!isFeching && (
            <KemaroRobotViewCleanedSurfaceChartFooter
              cleanedSurfaceChartDto={cleanedSurfaceChartDto}
            />
          )}
        </div>
      </div>
    </>
  );
};

interface KemaroRobotViewCleanedAreaHistoryProps {
  dataTotal: KemaroRobotCleaningDataTotalsDtoType;
}
