import { CustomerCompleteDto } from "../../../../robotcloud-shared/resource-models";
import { useEffect, useState } from "react";
import { YesNoOkDialog } from "../../components/news/YesNoOkDialog";
import { ViewHeader } from "../../components/news/ViewHeader";
import { CustomerEditModal } from "./CustomerEditModal";
import { PermissionsHelper } from "../../uiHelpers/permissionsHelper";
import { NavigateTo } from "../../components/news/NavigateTo";
import { useTranslation } from "react-i18next";

export const CustomerViewTitle = (props: customerViewTitleProps) => {
  const { t } = useTranslation();

  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [isWorking, setIsWorking] = useState(false);
  const [canModify, setCanModify] = useState(false);
  const [canDelete, setCanDelete] = useState(false);

  useEffect(() => {
    setCanModify(PermissionsHelper.allow("Customers", "Edit") && props.canEdit);

    setCanDelete(
      PermissionsHelper.allow("Customers", "Delete") && props.canEdit
    );
  }, [props]);

  const handleDeleteYes = () => {
    setIsWorking(true);
    props.onDelete(props.item);
    setOpenDeletePopup(false);
    setIsWorking(false);
  };

  return (
    <>
      <ViewHeader
        title={props.item.name}
        copyLinkId={props.item.id}
        canEdit={canModify}
        canDelete={canDelete}
        onEdit={() => setOpenEditPopup(true)}
        onDelete={() => setOpenDeletePopup(true)}
      >
        <div className="w-100 ps-3 text-end">
          <NavigateTo
            text={t("robot.entitiesName")}
            shorCut="robots"
            paramters={[{ name: "idCustomer", value: props.item.id }]}
          />
        </div>
      </ViewHeader>

      {openEditPopup && (
        <CustomerEditModal
          openPopup={openEditPopup}
          item={props.item}
          onSave={(item) => props.onEdit(item)}
          onCancel={() => setOpenEditPopup(false)}
        />
      )}

      <YesNoOkDialog
        open={openDeletePopup}
        size="md"
        type="yesNo"
        title={t("customer.delete.title")}
        message={`${t("customer.delete.warning")} ${props.item.name}?`}
        onYes={handleDeleteYes}
        onNo={() => setOpenDeletePopup(false)}
        isWorking={isWorking}
      />
    </>
  );
};

export interface customerViewTitleProps {
  item: CustomerCompleteDto;
  canEdit: boolean;
  onEdit: (item: CustomerCompleteDto) => void;
  onDelete: (item: CustomerCompleteDto) => void;
}
