import { t } from "i18next";
import { KemaroMissionFilterCriteriaTimezone } from "../../../../../robotcloud-shared/resource-models";

export interface KemaroMissionFilterCriteriaTimezoneDto {
  value: KemaroMissionFilterCriteriaTimezone;
  text: string;
}

export const displayTimeList: KemaroMissionFilterCriteriaTimezoneDto[] = [
  {
    value: "RobotLocalTime",
    text: t("mission.filter.displayTime.robotLocal"),
  },
  {
    value: "Utc",
    text: t("mission.filter.displayTime.utc"),
  },
  {
    value: "UserLocalTime",
    text: t("mission.filter.displayTime.userTimezone"),
  },
];
