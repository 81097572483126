import * as React from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
  useMsalAuthentication,
} from "@azure/msal-react";
import {
  InteractionStatus,
  InteractionType,
  PublicClientApplication,
} from "@azure/msal-browser";
import { getAuthRequest } from "../../data/auth";
import { Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { StorageManagerService } from "../../data/storageManager.service";

export const Login: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { inProgress, instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const { login, error } = useMsalAuthentication(
    InteractionType.Silent,
    getAuthRequest()
  );

  useEffect(() => {
    if (error) {
      if (error.errorCode === "consent_required") {
        StorageManagerService.removeMsalConfigType();
      } else {
        if (!isAuthenticated && inProgress === InteractionStatus.None) {
          login(InteractionType.Redirect, getAuthRequest());
        }
      }
    }
  }, [error]);

  useEffect(() => {
    if (
      StorageManagerService.getInteractionStatus() ||
      inProgress !== InteractionStatus.None ||
      isAuthenticated ||
      !(instance instanceof PublicClientApplication)
    ) {
      return;
    }

    login(InteractionType.Redirect, getAuthRequest());
  }, [isAuthenticated, inProgress, instance]);

  return (
    <>
      <AuthenticatedTemplate>
        <Navigate to="/" />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        {t("loginScreen.loginMessage")}
      </UnauthenticatedTemplate>
    </>
  );
};
