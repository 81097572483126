import "./mainPanel.scss";
import { MainPanelFooter } from "./MainPanelFooter";
import { MainPanelManufacturers } from "./MainPanelManufacturers";
import { MainPanelMenu } from "./MainPanelMenu";
import { MainPanelMe } from "./MainPanelMe";
import { ManufacturerReducedDto } from "../../../../robotcloud-shared/resource-models";
import { useContext, useEffect, useState } from "react";
import { OnBoardingAssistant } from "../onBoarding";
import { Route, Routes } from "react-router-dom";
import AuthorizationContext from "../auth/authorizationContext";
import useTheme from "@mui/material/styles/useTheme";
import { UnassignedPanel } from "../auth/unassigned/UnassignedPanel";
import { useOnboardingProcesses } from "../../hooks";

export const processLinkIdKey = "ProcessLinkId";

export const MainPanel = (props: mainPanelProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isOpenOnBoardingAssistant, setIsOpenOnBoardingAssistant] =
    useState<boolean>(false);
  const { me, allowedManufacturers } = useContext(AuthorizationContext);
  const { pendingProcesses, processLink } = useOnboardingProcesses();

  useEffect(() => {
    setIsOpenOnBoardingAssistant(pendingProcesses.length > 0);
  }, [pendingProcesses.length]);

  const onCloseOnBoardingAssistant = () => {
    setIsOpenOnBoardingAssistant(false);
  };

  const theme = useTheme();
  const isUnasigned = () => {
    return !me?.currentManufacturerId;
  };

  return (
    <>
      {me && isOpenOnBoardingAssistant && (
        <OnBoardingAssistant
          openPopup={isOpenOnBoardingAssistant}
          isWorking={false}
          processes={pendingProcesses}
          processLink={processLink}
          onClose={onCloseOnBoardingAssistant}
        />
      )}

      {me && isUnasigned() && (
        <>
          <Routes>
            <Route path="/" element={<></>} />
            <Route path="/login" element={<></>} />
            <Route path="/logout" element={<></>} />
          </Routes>
          <UnassignedPanel />
        </>
      )}

      {me && !isUnasigned() && (
        <div
          className={"main-panel " + (isOpen ? "" : "main-panel-small")}
          style={{ borderRight: theme.palette.swarm?.mainPanel?.borderRight }}
        >
          <MainPanelMe isOpen={isOpen} onIsOpenChange={setIsOpen} />
          {isOpen && allowedManufacturers && allowedManufacturers.length > 0 ? (
            <MainPanelManufacturers
              manufacturers={props.manufacturers}
              onManufacturerChange={props.onManufacturerChange}
            />
          ) : (
            <></>
          )}
          <MainPanelMenu isOpen={isOpen} />
          {isOpen ? <MainPanelFooter /> : <></>}
        </div>
      )}
    </>
  );
};

export interface mainPanelProps {
  manufacturers: ManufacturerReducedDto[];
  onManufacturerChange: (manufacturer: ManufacturerReducedDto) => void;
}
