import { createContext } from "react";
import {
  OrderDto,
  PaginationDto,
  orderDtoDefault,
  paginationDtoDefault,
} from "../../data/common";
import {
  CustomFieldDto,
  CustomFieldListDto,
  CustomFieldTypeDto,
} from "../../../../robotcloud-shared/resource-models";

export const CustomFieldContext = createContext<CustomFieldContextData>({
  isFetchingList: false,
  isFetchingItem: false,
  pagination: { ...paginationDtoDefault },
  pageChange: (pagination: PaginationDto) => {},
  currentType: undefined,
  setCurrentType: (currentType: CustomFieldTypeDto | undefined) => {},
  currentItem: undefined,
  setCurrentItem: (item: CustomFieldDto | undefined) => {},
  refreshItem: async () => {},
  currentItemIsFeching: false,
  dataOrder: { ...orderDtoDefault },
  setDataOrder: (order: OrderDto) => {},
  items: [],
  currentId: undefined,
  setCurrentId: (id: string | undefined) => {},
  refreshList: async () => {},
  deleteCurrentItem: async () => {
    return false;
  },
});

export interface CustomFieldContextData {
  isFetchingList: boolean;
  isFetchingItem: boolean;
  pagination: PaginationDto;
  pageChange(pagination: PaginationDto): void;
  currentType: CustomFieldTypeDto | undefined;
  setCurrentType(currentType: CustomFieldTypeDto | undefined): void;
  currentItem: CustomFieldDto | undefined;
  setCurrentItem(item: CustomFieldDto | undefined): void;
  refreshItem(): Promise<void>;
  currentItemIsFeching: boolean;
  dataOrder: OrderDto;
  setDataOrder(order: OrderDto): void;
  items: CustomFieldListDto[];
  currentId: string | undefined;
  setCurrentId(id: string | undefined): void;
  refreshList(): Promise<void>;
  deleteCurrentItem(): Promise<boolean>;
}
