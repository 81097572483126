import { useTranslation } from "react-i18next";
import { TooltipContainer } from "../../../../../components/news";

export const RowTextWtihTooltip = ({
  isSummary,
  isExpanded,
  isChild,
  text,
}: RowTextWtihTooltipProps) => {
  const { t } = useTranslation();

  if (isSummary) {
    return (
      <TooltipContainer text={t("dealer.entitiesName")}>
        <span className={isExpanded ? "dealer" : ""}>{text}</span>
      </TooltipContainer>
    );
  }

  if (!isChild) {
    return (
      <TooltipContainer text={t("customer.entitiesName")}>
        <span>{text}</span>
      </TooltipContainer>
    );
  }

  return (
    <TooltipContainer text={t("customer.customerLocation")}>
      <span>{text}</span>
    </TooltipContainer>
  );
};

interface RowTextWtihTooltipProps {
  isSummary: boolean;
  isExpanded: boolean;
  isChild: boolean;
  text: string;
}
