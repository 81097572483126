import { Button } from "@mui/material";
import { t } from "i18next";
import { SwarmRemoteRequests } from "../../../data/swarmRemote.request";
import { ToastHelper, TooltipButton } from "../../../components/news";
import { ErrorHelper } from "../../../uiHelpers/errors.helper";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

export const RobotConnectionConfigFileButton = ({
  robotId,
}: RobotConnectionConfigFileButtonProps) => {
  const downloadFile = async () => {
    if (!robotId) return;

    try {
      const response = await SwarmRemoteRequests.getConnectionConfigFile(
        robotId
      );

      const fileUrl = URL.createObjectURL(response.data);
      const a = document.createElement("a");
      a.href = fileUrl ?? "";
      a.download = "wg0.conf";
      a.target = "_blank";
      a.click();
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }
  };

  const toClipboard = async () => {
    if (!robotId) return;

    try {
      const response = await SwarmRemoteRequests.getConnectionConfigText(
        robotId
      );

      navigator.clipboard.writeText(response);
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }
  };

  return (
    <>
      <Button onClick={downloadFile}>
        {t("robot.connection.modal.downloadConfig")}
      </Button>

      <TooltipButton
        aria-label={t("robot.connection.modal.toClipboard")}
        tooltip={t("robot.connection.modal.toClipboard")}
        onClick={toClipboard}
      >
        <ContentCopyIcon color="primary" />
      </TooltipButton>
    </>
  );
};

interface RobotConnectionConfigFileButtonProps {
  robotId: string;
}
