import * as React from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { StorageManagerService } from "../../data/storageManager.service";

export const Logout: React.FunctionComponent = () => {
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  React.useEffect(() => {
    const exit = async () => {
      const currentAccount = instance.getActiveAccount();
      const msalConfigType = StorageManagerService.getMsalConfigType();
      if (msalConfigType === "AzureAdB2C") {
        await instance.clearCache();
        //https://stackoverflow.com/questions/68301896/azure-ad-b2c-log-out-of-social-account-azure-ad-using-oidc
        //https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/logout.md
        await instance.logoutRedirect({
          account: currentAccount,
          postLogoutRedirectUri:
            "https://login.microsoftonline.com/common/oauth2/logout",
        });
      } else {
        await instance.clearCache();
        await instance.logoutRedirect();
      }
    };

    if (isAuthenticated && inProgress === "none") {
      exit();
    }
  }, [instance, isAuthenticated, inProgress]);

  return <span>{"authentication.loggingOut"}</span>;
};
