import { useEffect, useState } from "react";
import { WorkflowTranslatedDataDto } from "../../../../robotcloud-shared/resource-models";
import "./linkWorkflow.scss";
import { Checkbox, Tooltip } from "@mui/material";
import { getWorkflowTranslatedData, Workflow } from "./onBoardingService";
import { WorkflowLanguageSelector } from "./WorkflowLanguageSelector";
import { Banner } from "../../components/news";

export const LinkWorkflow = ({
  workflow,
  initialLanguage,
  onAccepting,
}: LinkWorkflowProps) => {
  const [language, setLanguage] = useState<string>("--");
  const [translatedData, setTranslatedData] = useState<
    WorkflowTranslatedDataDto | undefined
  >(getWorkflowTranslatedData(workflow.workflowTranslatedDatas, language));
  const [checked, setChecked] = useState<boolean>(false);

  useEffect(() => {
    onAccepting(checked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  useEffect(() => {
    if (workflow) {
      const translation = getWorkflowTranslatedData(
        workflow.workflowTranslatedDatas,
        initialLanguage
      );
      setTranslatedData(translation);
      setChecked(false);
      if (translation) {
        setLanguage(initialLanguage);
      } else {
        setLanguage("--");
      }
    }
  }, [workflow, initialLanguage]);

  useEffect(() => {
    if (workflow) {
      const translation = getWorkflowTranslatedData(
        workflow.workflowTranslatedDatas,
        language
      );
      setTranslatedData(translation);
      setChecked(false);
    }
  }, [workflow, language]);

  return (
    <div className="col-12 workflow-component-container 3">
      <div className="d-flex col-12">
        <Tooltip
          title={translatedData?.title ?? workflow.displayTitle}
          placement="top"
          arrow
        >
          <h3 className="workflow-component-title col-12">
            {translatedData?.title ?? workflow.displayTitle}
          </h3>
        </Tooltip>
      </div>

      {workflow && workflow.workflowTranslatedDatas.length > 0 && (
        <>
          <div className="col-8"></div>
          <div className="col-4">
            <WorkflowLanguageSelector
              workflow={workflow}
              current={language}
              selectionChange={(select: string | undefined) => {
                if (select) {
                  setLanguage(select);
                }
              }}
            />
          </div>
        </>
      )}

      <div className="col-12 mt-2">
        <Banner type="info">
          <span id="link-workflow-component-message">
            {translatedData?.instructions ?? workflow.displayInstructions}
          </span>
        </Banner>
      </div>

      <div id="link-ref">
        <a
          href={translatedData?.linkUrl ?? workflow.displayLinkUrl}
          target="_blank"
          rel="noreferrer"
        >
          {translatedData?.linkUrl ?? workflow.displayLinkUrl}
        </a>
      </div>
      <div id="link-accept">
        <Tooltip
          title={translatedData?.agreement ?? workflow.displayAgreement}
          placement="top"
          arrow
        >
          <div className="text-overflow-ellipsis accept-conditions col-11">
            {translatedData?.agreement ?? workflow.displayAgreement}
          </div>
        </Tooltip>
        <div className="col-1 text-end">
          <Checkbox
            className="pe-0"
            color="primary"
            disableRipple
            checked={checked}
            onChange={(event) => setChecked(event.target.checked)}
          />
        </div>
      </div>
    </div>
  );
};

interface LinkWorkflowProps {
  workflow: Workflow;
  initialLanguage: string;
  onAccepting: (ready: boolean) => void;
}
