import i18n from "i18next";
import useTheme from "@mui/material/styles/useTheme";

export const NoItems = () => {
  const pColor = useTheme().palette.swarm?.general?.noResultsP;

  return (
    <div className="no-results mt-4">
      <p className="mb-0 w-100 text-center" style={{ color: pColor }}>
        {i18n.t("common.noResult") as string}
      </p>
      <p className="mb-0 w-100 text-center" style={{ color: pColor }}>
        {i18n.t("common.changeFilter") as string}
      </p>
    </div>
  );
};
