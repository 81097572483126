import { diff, jsonPatchPathConverter } from "just-diff";
import {
  DashboardEconomyCostCalculationSearchTextDto,
  DashboardSpHistoryMessageLogsDto,
  DashboardSpResultMessageLogsDto,
  KemaroDashboardEconomyCostCalculationCompleteDto,
  KemaroDashboardEconomyCostCalculationDto,
  KemaroDashboardEconomyCostCalculationFilterCriteriaDto,
  KemaroDashboardEconomyCostCalculationPostDto,
  KemaroDashboardEconomyCostCalculationReducedDto,
  PaginatedListDtoOfKemaroDashboardEconomyCostCalculationListDto,
  SearchTextResultDtoOfKemaroDashboardEconomyCostCalculationListDto,
} from "../../../../robotcloud-shared/resource-models";
import { AxiosRequests } from "../axios.requests";
import { BaseRequest } from "../BaseRequest";
import { prepareSearchTextDto } from "../functions";

export class KemaroDashboardEconomyCostCalculationsRequests extends BaseRequest {
  static controller = "api/webapp/v1.0/Kemaro/DashboardEconomyCostCalculations";

  static getSearchText = async (
    customerId: string | null,
    searchTextDto: DashboardEconomyCostCalculationSearchTextDto
  ) => {
    const routeParameter = customerId
      ? `/${customerId}/search-text`
      : "/search-text";

    const { data } = await AxiosRequests.post(
      `${KemaroDashboardEconomyCostCalculationsRequests.controller}${routeParameter}`,
      prepareSearchTextDto(searchTextDto)
    );
    return data as SearchTextResultDtoOfKemaroDashboardEconomyCostCalculationListDto;
  };

  static getList = async (
    filterCriteria: KemaroDashboardEconomyCostCalculationFilterCriteriaDto
  ) => {
    const params =
      KemaroDashboardEconomyCostCalculationsRequests.objectToQueryString(
        filterCriteria
      );

    const { data } = await AxiosRequests.get(
      `${KemaroDashboardEconomyCostCalculationsRequests.controller}?${params}`
    );
    return data as PaginatedListDtoOfKemaroDashboardEconomyCostCalculationListDto;
  };

  static getById = async (id: string) => {
    const { data } = await AxiosRequests.get(
      `${KemaroDashboardEconomyCostCalculationsRequests.controller}/${id}`
    );
    return data as KemaroDashboardEconomyCostCalculationCompleteDto;
  };

  static getByIdReduced = async (id: string) => {
    const { data } = await AxiosRequests.get(
      `${KemaroDashboardEconomyCostCalculationsRequests.controller}/${id}/reduced`
    );
    return data as KemaroDashboardEconomyCostCalculationReducedDto;
  };

  static copy = async (
    id: string,
    dto: KemaroDashboardEconomyCostCalculationPostDto
  ) => {
    const { data } = await AxiosRequests.post(
      `${KemaroDashboardEconomyCostCalculationsRequests.controller}/copy/${id}`,
      dto
    );

    return data as KemaroDashboardEconomyCostCalculationDto;
  };

  static create = async (dto: KemaroDashboardEconomyCostCalculationPostDto) => {
    const { data } = await AxiosRequests.post(
      KemaroDashboardEconomyCostCalculationsRequests.controller,
      dto
    );
    return data as KemaroDashboardEconomyCostCalculationDto;
  };

  static modify = async (
    id: string,
    originalDto: KemaroDashboardEconomyCostCalculationPostDto,
    modifiedDto: KemaroDashboardEconomyCostCalculationPostDto
  ) => {
    const allowedProperties = [
      "/name",
      "/idCustomer",
      "/idCountry",
      "/idCurrency",
    ];

    const jsonPatch = diff(
      originalDto,
      modifiedDto,
      jsonPatchPathConverter
    ).filter((x) => allowedProperties.includes(x.path));

    await AxiosRequests.patch(
      `${KemaroDashboardEconomyCostCalculationsRequests.controller}/${id}`,
      jsonPatch
    );
  };

  static delete = async (id: string) => {
    await AxiosRequests.delete(
      `${KemaroDashboardEconomyCostCalculationsRequests.controller}/${id}`
    );
  };

  static recalculateDashboards = async () => {
    await AxiosRequests.post(
      `${KemaroDashboardEconomyCostCalculationsRequests.controller}/recalculate-dashboards`
    );
  };

  static lastExecutionState = async () => {
    const { data } = await AxiosRequests.post(
      `${KemaroDashboardEconomyCostCalculationsRequests.controller}/last-execution-state`
    );
    return data as DashboardSpResultMessageLogsDto;
  };

  static executionHistory = async () => {
    const { data } = await AxiosRequests.post(
      `${KemaroDashboardEconomyCostCalculationsRequests.controller}/execution-history`
    );
    return data as DashboardSpHistoryMessageLogsDto[];
  };
}
