import { AxiosResponse } from "axios";
import { SwarmRemoteManufacturerConnectionDto } from "../../../robotcloud-shared/resource-models";
import { AxiosRequests } from "./axios.requests";
import { BaseRequest } from "./BaseRequest";

export class SwarmRemoteRequests extends BaseRequest {
  static controller = "api/webapp/v1.0/swarmRemote";

  static getConnectionByRobotId = async (robotId: string) => {
    const { data } = await AxiosRequests.get(
      `${SwarmRemoteRequests.controller}/${robotId}`
    );
    return data as SwarmRemoteManufacturerConnectionDto;
  };

  static getConnectionConfigFile = async (
    robotId: string
  ): Promise<AxiosResponse> => {
    return await AxiosRequests.getBlob(
      `${SwarmRemoteRequests.controller}/${robotId}/wg-config-file`
    );
  };

  static getConnectionConfigText = async (robotId: string) => {
    const { data } = await AxiosRequests.get(
      `${SwarmRemoteRequests.controller}/${robotId}/wg-config-text`
    );

    return data as string;
  };
}
