import { KemaroMissionErrorSummaryContainerDto } from "../../../../../../robotcloud-shared/resource-models";
import { KemaroRobotErrorSummaryModalDataSection } from "./KemaroRobotErrorSummaryModalDataSection";

export const KemaroRobotErrorSummaryModalData = (
  props: KemaroRobotErrorSummaryModalDataProps
) => {
  const twoErrorsCategory =
    props.container.recent.length > 0 && props.container.old.length > 0
      ? "mt-3"
      : "";

  return (
    <>
      {props.container.recent.length > 0 && (
        <KemaroRobotErrorSummaryModalDataSection
          days={props.container.recentDaysToAnalyze}
          items={props.container.recent}
          titleContainerClass="recent-title"
        />
      )}

      {props.container.old.length > 0 && (
        <KemaroRobotErrorSummaryModalDataSection
          days={props.container.totalDaysToAnalyze}
          items={props.container.old}
          titleContainerClass={`old-title ${twoErrorsCategory}`}
        />
      )}
    </>
  );
};

interface KemaroRobotErrorSummaryModalDataProps {
  container: KemaroMissionErrorSummaryContainerDto;
}
