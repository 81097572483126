import { useContext } from "react";
import { useTranslation } from "react-i18next";
import KemaroRobotContext from "../KemaroRobotContext";
import { TooltipContainer } from "../../../../components/news";
import WifiIcon from "@mui/icons-material/Wifi";
import WifiOffIcon from "@mui/icons-material/WifiOff";

export const OnlineDisplayer = () => {
  const { t } = useTranslation();
  const { currentItem } = useContext(KemaroRobotContext);

  return (
    <>
      {currentItem!.isOnline && (
        <TooltipContainer text={t("robot.isOnline.yes")}>
          <WifiIcon
            className="me-2"
            sx={{ color: "swarm.kemaroRobots.onThreshold" }}
          />
        </TooltipContainer>
      )}

      {!currentItem!.isOnline && (
        <TooltipContainer text={t("robot.isOnline.no")}>
          <WifiOffIcon
            className="me-2"
            sx={{ color: "swarm.kemaroRobots.outThreshold" }}
          />
        </TooltipContainer>
      )}
    </>
  );
};
