import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog } from "@mui/material";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { kemaroMissionsShowMapSubjectManager } from "./RxJS";
import { tableGenericHandleKeyboardSubjectManager } from "../../../components/news";
import useTheme from "@mui/material/styles/useTheme";

export const KemaroMissionViewMap = (props: kemaroMissionViewMapProps) => {
  const [openPopup, setOpenPopup] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();

  useEffect(() => {
    const showMapSubjectManagerSubscription =
      kemaroMissionsShowMapSubjectManager.getObservable().subscribe(() => {
        if (!!props.map) setOpenPopup((value) => !value);
      });

    return () => {
      showMapSubjectManagerSubscription.unsubscribe();
    };
  }, [props.map]);

  useEffect(() => {
    tableGenericHandleKeyboardSubjectManager.setData({
      caller: "KemaroMissionViewMap",
      handleKeyboard: openPopup,
    });
  }, [openPopup]);

  let content = (
    <span
      className="no-map"
      style={{ color: theme.palette.swarm?.kemaroMissions?.viewSummaryNoMap }}
    >
      {t("mission.viewMap.noMap")}
    </span>
  );
  let url = "";

  if (!!props.map) {
    const decodedData = window.atob(props.map);
    const uInt8Array = new Uint8Array(decodedData.length);

    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }

    let blob = new Blob([uInt8Array], { type: "image/jpg" });
    url = URL.createObjectURL(blob);

    content = (
      <img
        className="cursor-pointer image-small"
        src={url}
        alt="mission map"
        onClick={() => setOpenPopup(true)}
      />
    );
  }

  return (
    <>
      <Box className="map-container">{content}</Box>

      <Dialog
        open={openPopup}
        maxWidth="xl"
        onClose={() => setOpenPopup(false)}
      >
        <div className="application-modal-body">
          <img src={url} alt="map" className="image-big" />
        </div>

        <div
          className="close-modal cursor-pointer"
          onClick={() => setOpenPopup(false)}
        >
          <CloseIcon
            sx={{
              color: "swarm.general.closeModal",
              "&:hover": {
                color: "swarm.general.closeModalHover",
                backgroundColor: "swarm.general.closeModalHoverBackground",
              },
            }}
          />
        </div>
      </Dialog>
    </>
  );
};

interface kemaroMissionViewMapProps {
  map: string;
}
