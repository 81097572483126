import { Autocomplete, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  CustomFieldListDto,
  CustomFieldTypes,
} from "../../../../robotcloud-shared/resource-models";
import { useEffect, useState } from "react";
import { CustomFieldRequests } from "../../data/customField.request";
import { ToastHelper } from "../news";
import { ErrorHelper } from "../../uiHelpers/errors.helper";
import { GeneralFunctions } from "../../views/generalFunctions";
import { CustomFieldAutocompleteDto } from "../../data/common";

export const CustomFieldAutocomplete = (
  props: CustomFieldAutocompleteProps
) => {
  const { t } = useTranslation();

  const [allItems, setAllItems] = useState<CustomFieldListDto[]>([]);
  const [items, setItems] = useState<CustomFieldListDto[]>([]);
  const [inputText, setInputText] = useState("");

  useEffect(() => {
    getData(props.type);
  }, [props.type]);

  useEffect(() => {
    if (!props.blackList) {
      setItems(allItems);
      return;
    }

    setItems(
      allItems.filter(
        (x) =>
          !props.blackList!.some((y) =>
            x.values.some((z) => z.toUpperCase() === y.toUpperCase())
          )
      )
    );
  }, [allItems, props.blackList]);

  const getData = async (t: CustomFieldTypes) => {
    try {
      const data = await CustomFieldRequests.getList({
        type: t,
        id: null,
        textToSearch: null,
        page: 1,
        maxItemCount: 10000,
        orderBy: null,
        orderColumns: null,
      });

      setAllItems(data.items);
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }
  };

  const valueSelected = (
    text: string,
    itemSelected: CustomFieldListDto | undefined
  ) => {
    if (!GeneralFunctions.isBlank(text)) {
      const item = items.find(
        (x) =>
          x.values.some((y) =>
            GeneralFunctions.stringEqualUnSensitive(y, text)
          ) ??
          items.find((x) =>
            GeneralFunctions.stringEqualUnSensitive(x.description, text)
          )
      );

      if (item) {
        props.onChange({
          text: item.title,
          code: item.values[0],
        });
      } else {
        props.onChange({
          text: "",
          code: text,
        });
      }

      if (props.clearAfterSelect) {
        setTimeout(() => {
          setInputText("");
        }, 100);
      }

      return;
    }

    if (itemSelected) {
      props.onChange({
        text: itemSelected.title,
        code: itemSelected.values[0],
      });

      if (props.clearAfterSelect) {
        setTimeout(() => {
          setInputText("");
        }, 100);
      }
    }
  };

  return (
    <Autocomplete
      disableCloseOnSelect={props.clearAfterSelect}
      className="auto-complete custom-field-autocomplete"
      size="small"
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          label=""
          placeholder={t("common.typeToSearch")}
          fullWidth
          variant="standard"
          hiddenLabel
        />
      )}
      options={items}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.title
      }
      onKeyDown={(event) => {
        if (event.key !== "Enter") return;
        valueSelected(inputText, undefined);
      }}
      onInputChange={(event, newValue) => setInputText(newValue)}
      onChange={(event, newValue) => {
        if (!newValue) {
          return;
        }

        if (typeof newValue === "string") {
          valueSelected(newValue as string, undefined);
        } else {
          valueSelected("", newValue as CustomFieldListDto);
        }
      }}
      value={inputText}
    />
  );
};

interface CustomFieldAutocompleteProps {
  type: CustomFieldTypes;
  clearAfterSelect?: boolean;
  blackList?: string[] | undefined;
  onChange: (newValue: CustomFieldAutocompleteDto) => void;
}
