import { CustomerContactReducedDto } from "../../../../robotcloud-shared/resource-models";
import Button from "@mui/material/Button/Button";
import Dialog from "@mui/material/Dialog/Dialog";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { tableGenericHandleKeyboardSubjectManager } from "../../components/news";
import { LocationsDisplayer } from "../shared/locationsDisplayer";

export const CustomerViewContactsInCompanyStrcutureModal = (
  props: CustomerViewContactsInCompanyStrcutureModalProps
) => {
  const { t } = useTranslation();

  useEffect(() => {
    tableGenericHandleKeyboardSubjectManager.setData({
      caller: "CustomerViewContactsInCompanyStrcutureModal",
      handleKeyboard: props.openPopup,
    });
  }, [props.openPopup]);

  return (
    <Dialog
      key={`CustomerViewContactsInCompanyStrcutureModalProps_Dialog_${Math.random()}`}
      className="application-modal"
      open={props.openPopup}
      maxWidth="md"
      onClose={() => props.onCancel()}
    >
      <div className="modal-content">
        <div className="application-modal-header">
          {props.contact && (
            <h3 className="col-12">
              <p className="mb-0">
                {`${props.contact.firstName} ${props.contact.sureName}`}
              </p>
            </h3>
          )}
        </div>
        <div className="application-modal-body">
          <div className="row pt-2">
            <LocationsDisplayer locations={props.routes} />
          </div>
        </div>
        <div className="application-modal-footer">
          <Button
            className="modal-cancel-button button-with-text"
            variant="outlined"
            color="primary"
            onClick={() => props.onCancel()}
          >
            {t("common.buttons.close")}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

interface CustomerViewContactsInCompanyStrcutureModalProps {
  contact: CustomerContactReducedDto | undefined;
  routes: string[][] | undefined;
  openPopup: boolean;
  onCancel: () => void;
}
