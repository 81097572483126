import { Fragment } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export const LocationsDisplayer = ({ locations }: LocationsDisplayerProps) => (
  <div className="row">
    {locations?.map((x, idx) => (
      <div key={`div_${idx}`} className="col-12 mb-3">
        {x.map((node, idxNode) => (
          <Fragment key={`${`frag_${idx}_${Math.random()}`}}`}>
            {idxNode > 0 && (
              <ChevronRightIcon sx={{ color: "swarm.general.primaryText" }} />
            )}
            <span>{node}</span>
          </Fragment>
        ))}
      </div>
    ))}
  </div>
);

interface LocationsDisplayerProps {
  locations: string[][] | undefined;
}
