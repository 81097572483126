import { KemaroMissionCompleteDto } from "../../../../../robotcloud-shared/resource-models";

import WatchLaterOutlined from "@mui/icons-material/WatchLaterOutlined";
import MoreTimeOutlinedIcon from "@mui/icons-material/MoreTimeOutlined";
import TabUnselectedOutlinedIcon from "@mui/icons-material/TabUnselectedOutlined";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import { GeneralFunctions } from "../../generalFunctions";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import AuthorizationContext from "../../auth/authorizationContext";
import { PermissionsHelper } from "../../../uiHelpers/permissionsHelper";

export const KemaroMissionViewSummaryInfo = (
  props: kemaroMissionViewSummaryInfoProps
) => {
  const { t } = useTranslation();
  const { me } = useContext(AuthorizationContext);

  const [viewShowtimezone, setViewShowtimezone] = useState(false);

  useEffect(() => {
    setViewShowtimezone(
      PermissionsHelper.allow("KemaroMissions", "View", "ViewShowtimezone")
    );
  }, [me]);

  if (!props.item?.robotSerialNumber) {
    return <></>;
  }

  let totalCleanedArea = 0;

  props.item.runs.forEach((x) => {
    totalCleanedArea += x?.cleanedAreaM2 ?? 0;
  });

  return (
    <table className="summary-table w-100">
      <tbody>
        <tr>
          <td className="summary-title-column py-2">
            {t("mission.details.startTime")}
          </td>
          <td className="summary-value-column py-2 pe-2">
            {GeneralFunctions.dateToTimeDateString(
              props.item.startTime ?? "2022-01-01"
            )}
          </td>
          <td>
            <WatchLaterOutlined />
          </td>
        </tr>
        <tr>
          <td className="summary-title-column py-2">
            {t("mission.details.endTime")}
          </td>
          <td className="summary-value-column py-2 pe-2">
            {GeneralFunctions.dateToTimeDateString(
              props.item.stopTime ?? "2022-01-01"
            )}
          </td>
          <td>
            <WatchLaterOutlined />
          </td>
        </tr>
        {viewShowtimezone && (
          <tr>
            <td className="summary-title-column py-2">
              {t("mission.details.timeZone")}
            </td>
            <td className="summary-value-column py-2 pe-2">
              {props.item.timezone}
            </td>
            <td>
              <MoreTimeOutlinedIcon />
            </td>
          </tr>
        )}

        <tr>
          <td className="summary-title-column py-2">
            {t("mission.details.cleaningDuration")}
          </td>
          <td className="summary-value-column py-2 pe-2">
            {GeneralFunctions.durationToHoursMinutesString(
              props.item.cleaningDuration
            )}
          </td>
          <td>
            <WatchLaterOutlined />
          </td>
        </tr>
        <tr>
          <td className="summary-title-column py-2">
            {t("mission.details.totalCleanedArea")}
          </td>
          <td className="summary-value-column py-2 pe-2">
            {totalCleanedArea} m<sup>2</sup>
          </td>
          <td>
            <TabUnselectedOutlinedIcon />
          </td>
        </tr>
        <tr>
          <td className="summary-title-column py-2">
            {t("mission.details.cleaningPerformance")}
          </td>
          <td className="summary-value-column py-2 pe-2">
            {props.item.cleaningPerformance} m<sup>2</sup>/h
          </td>
          <td>
            <AnalyticsOutlinedIcon />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

interface kemaroMissionViewSummaryInfoProps {
  item: KemaroMissionCompleteDto | undefined;
}
