import { ReactElement } from "react";
import { PermissionsHelper } from "./permissionsHelper";
import { RobotModels } from "../views/robotModels/RobotModels";
import { KemaroCostCalculation } from "../views/kemaro/administration/kemaroCostCalculation/KemaroCostCalculation";
import { KemaroDashboardEconomy } from "../views/kemaro/dashboards/kemaroDashboardEconomy/KemaroDashboardEconomy";
import { KemaroMissions } from "../views/kemaro/missions/KemaroMissions";
import { KemaroRobots } from "../views/kemaro/robots/KemaroRobots";
import { Profile } from "../views/profile/Profile";
import { Manufacturers } from "../views/manufacturers/Manufacturers";
import { Dealers } from "../views/dealers/Dealers";
import { Customers } from "../views/customers/Customers";
import { Users } from "../views/users/Users";
import { ApiLoginDto } from "../../../robotcloud-shared/resource-models";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import AssignmentOutlined from "@mui/icons-material/AssignmentOutlined";
import Business from "@mui/icons-material/Business";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import MenuBookTwoToneIcon from "@mui/icons-material/MenuBookTwoTone";
import { ManufacturersType } from "./manufacturersType";
import { MaxbrainLog } from "../views/maxbrainLog/MaxbrainLog";
import { PermissionsMatrix } from "../views/permissionsMatrix";
import { OnBoarding } from "../views/onBoarding/OnBoarding";
import { CustomFields } from "../views/customFields/CustomFields";

export class MenuHelper {
  static getRoutes = (me: ApiLoginDto | undefined) => {
    const appRoutes: navigateRoutes[] = [
      {
        url: "/users",
        path: "Organizations / Users",
        shortCut: "users",
        component: <Users />,
        allow: PermissionsHelper.allow("Users", "View"),
        namufacturer: "none",
        isDefault: false,
        canBeUserDefalt: true,
        menu: undefined,
      },
      {
        url: "/customers",
        path: "Organizations / Customers",
        shortCut: "customers",
        component: <Customers />,
        allow: PermissionsHelper.allow("Customers", "View"),
        namufacturer: "none",
        isDefault: false,
        canBeUserDefalt: true,
        menu:
          me?.userAccessType === "Customer"
            ? {
                order: 3,
                optionGroup: "user",
                icon: <Business />,
                text: "Organization",
                tabPaths: ["/dealers", "/customers", "/users"],
              }
            : undefined,
      },
      {
        url: "/dealers",
        path: "Organizations / Dealers",
        shortCut: "dealers",
        component: <Dealers />,
        allow: PermissionsHelper.allow("Dealers", "View"),
        namufacturer: "none",
        isDefault: false,
        canBeUserDefalt: true,
        menu:
          me?.userAccessType === "Dealer"
            ? {
                order: 3,
                optionGroup: "user",
                icon: <Business />,
                text: "Organization",
                tabPaths: ["/dealers", "/customers", "/users"],
              }
            : undefined,
      },
      {
        url: "/manufacturers",
        path: "Organizations / Manufacturers",
        shortCut: "manufacturers",
        component: <Manufacturers />,
        allow: PermissionsHelper.allow("Manufacturers", "View"),
        namufacturer: "none",
        isDefault: false,
        canBeUserDefalt: true,
        menu:
          (me?.userAccessType === "Earlybyte" ||
            me?.userAccessType === "Manufacturer") &&
          me?.currentManufacturerName.toLowerCase() !== "swarm"
            ? {
                order: 3,
                optionGroup: "user",
                icon: <Business />,
                text: "Organization",
                tabPaths: ["/dealers", "/customers", "/users"],
              }
            : undefined,
      },
      {
        url: "/profile",
        path: "Profile",
        shortCut: "profile",
        component: <Profile />,
        allow: true,
        namufacturer: "none",
        isDefault: false,
        canBeUserDefalt: true,
        menu: {
          order: 0,
          optionGroup: "default",
          icon: <PersonRoundedIcon />,
          text: "Profile",
          tabPaths: [],
        },
      },
      {
        url: "/custom-fields",
        path: "Administration / Custom Fields",
        shortCut: "custom-fields",
        component: <CustomFields />,
        allow: PermissionsHelper.allow("CustomFields", "View"),
        namufacturer: "none",
        isDefault: false,
        canBeUserDefalt: true,
        menu: undefined,
      },
      {
        url: "/robot-models",
        path: "Administration / Robot models",
        shortCut: "robot-models",
        component: <RobotModels />,
        allow: PermissionsHelper.allow("RobotModels", "View"),
        namufacturer: "none",
        isDefault: false,
        canBeUserDefalt: true,
        menu: undefined,
      },
      {
        url: "/permissions",
        path: "Administration / Permissions",
        shortCut: "permissions",
        component: <PermissionsMatrix />,
        allow: PermissionsHelper.allow("Permissions", "View"),
        namufacturer: "none",
        isDefault: false,
        canBeUserDefalt: true,
        menu: undefined,
      },
    ];

    if (
      me?.userAccessType === "Earlybyte" ||
      PermissionsHelper.isManufacturerAdmin()
    ) {
      appRoutes.push({
        url: "/kemaro-academy-log",
        path: "Administration / Kemaro Academy log",
        shortCut: "kemaro-academy-log",
        component: <MaxbrainLog />,
        allow: PermissionsHelper.allow("MaxbrainLog", "View"),
        namufacturer: "none",
        isDefault: false,
        canBeUserDefalt: true,
        menu: undefined,
      });
    }

    if (
      me?.userAccessType === "Earlybyte" ||
      PermissionsHelper.isManufacturerAdmin()
    ) {
      appRoutes.push({
        url: "/on-boarding",
        path: "Administration / OnBoarding",
        shortCut: "on-boarding",
        component: <OnBoarding />,
        allow: PermissionsHelper.allow("OnBoarding", "View"),
        namufacturer: "none",
        isDefault: false,
        canBeUserDefalt: true,
        menu: undefined,
      });
    }

    if (PermissionsHelper.currentManufacturer() === "kemaro") {
      appRoutes.push(...MenuHelper.getKemaroRoutes(me));
    }

    return appRoutes;
  };

  static getAllowedOptions = (
    me: ApiLoginDto | undefined,
    canBeUserDefalt: boolean | undefined = undefined
  ) => {
    return MenuHelper.getRoutes(me)
      .filter(
        (x) =>
          x.allow &&
          (canBeUserDefalt === undefined ||
            x.canBeUserDefalt === canBeUserDefalt)
      )
      .map((x) => x.path)
      .filter((item, idx, list) => list.indexOf(item) == idx)
      .sort();
  };

  static getDefaultRoute = (me: ApiLoginDto | undefined) => {
    const routes = MenuHelper.getRoutes(me);

    const defaultRoute =
      routes.filter((x) => x.path === (me?.defaultPage ?? "--"))[0] ??
      routes.filter((x) => x.isDefault)[0];

    return defaultRoute;
  };

  static getGroupOption = (
    me: ApiLoginDto | undefined,
    group: optionGroups
  ) => {
    return MenuHelper.getRoutes(me).filter(
      (x) => x.menu?.optionGroup === group && x.allow
    );
  };

  static getByShortCut = (me: ApiLoginDto | undefined, shorCut: shortCuts) => {
    return (
      MenuHelper.getRoutes(me).filter(
        (x) => x.allow && x.shortCut === shorCut
      )[0] || undefined
    );
  };

  private static getKemaroRoutes = (me: ApiLoginDto | undefined) => {
    const routes: navigateRoutes[] = [
      {
        url: "/kemaro-robots",
        path: "Robots",
        shortCut: "robots",
        component: <KemaroRobots />,
        allow: PermissionsHelper.allow("KemaroRobots", "View"),
        namufacturer: "kemaro",
        isDefault: true,
        canBeUserDefalt: true,
        menu: {
          optionGroup: "user",
          order: 0,
          icon: <PrecisionManufacturingIcon />,
          text: "Robots",
          tabPaths: [],
        },
      },
      {
        url: "/kemaro-missions",
        path: "Missions",
        shortCut: "missions",
        component: <KemaroMissions />,
        allow: PermissionsHelper.allow("KemaroMissions", "View"),
        namufacturer: "kemaro",
        isDefault: false,
        canBeUserDefalt: true,
        menu: {
          optionGroup: "user",
          order: 1,
          icon: <AssignmentOutlined />,
          text: "Missions",
          tabPaths: [],
        },
      },
      {
        url: "/kemaro-dashboard-economy",
        path: "Dashboard / Economy",
        shortCut: "dashboard-economy",
        component: <KemaroDashboardEconomy />,
        allow: PermissionsHelper.allow("KemaroDashboard", "View"),
        namufacturer: "kemaro",
        isDefault: false,
        canBeUserDefalt: true,
        menu: {
          optionGroup: "user",
          order: 2,
          icon: <AnalyticsOutlinedIcon />,
          text: "Dashboard",
          tabPaths: [],
        },
      },
      {
        url: "/kemaro-cost-calculation",
        path: "Administration / Cost",
        shortCut: "cost-calculation",
        component: <KemaroCostCalculation />,
        allow: PermissionsHelper.allow("KemaroCostCalculations", "View"),
        namufacturer: "kemaro",
        isDefault: false,
        canBeUserDefalt: true,
        menu: {
          order: 3,
          optionGroup: "user",
          icon: <TuneRoundedIcon />,
          text: "Administration",
          tabPaths: [
            "/error-codes",
            "/robot-models",
            "/cleaning-modes",
            "/permissions",
            "/kemaro-academy-log",
            "/custom-field",
          ],
        },
      },
      {
        url: "/permissions",
        path: "Administration / Permissions",
        shortCut: "permissions",
        component: <PermissionsMatrix />,
        allow: PermissionsHelper.allow("Permissions", "View"),
        namufacturer: "none",
        isDefault: false,
        canBeUserDefalt: true,
        menu: undefined,
      },
      {
        url: PermissionsHelper.isDevelop()
          ? "https://kemaro.dev.maxbrain.io/"
          : "https://academy.kemaro.ch/",
        path: "KEMARO Academy",
        shortCut: "none",
        component: <KemaroCostCalculation />,
        allow: !!me,
        namufacturer: "kemaro",
        isDefault: false,
        canBeUserDefalt: false,
        menu: {
          order: 10,
          optionGroup: "default",
          icon: <MenuBookTwoToneIcon />,
          text: "KEMARO Academy",
          tabPaths: [],
        },
      },
      {
        url: "/on-boarding",
        path: "Administration / OnBoarding",
        shortCut: "on-boarding",
        component: <OnBoarding />,
        allow: PermissionsHelper.allow("OnBoarding", "View"),
        namufacturer: "none",
        isDefault: false,
        canBeUserDefalt: true,
        menu: undefined,
      },
    ];

    return routes;
  };
}

type optionGroups = undefined | "user" | "default";

export type shortCuts =
  | "none"
  | "users"
  | "customers"
  | "dealers"
  | "manufacturers"
  | "profile"
  | "error-codes"
  | "robot-models"
  | "robots"
  | "missions"
  | "dashboard-economy"
  | "cost-calculation"
  | "kemaro-academy-log"
  | "cleaning-modes"
  | "permissions"
  | "on-boarding"
  | "custom-fields";

export interface navigateRoutes {
  /* URL */
  url: string;

  /* Application Path */
  path: string;

  shortCut: shortCuts;

  /* Component to show */
  component: ReactElement;

  /* Can go to the option */
  allow: boolean;

  /* Is used for filter the options of current manufacturer */
  namufacturer: ManufacturersType;

  /* Set the dafult page of a manufacturer */
  isDefault: boolean;

  /* How to display in menu */
  menu: navigateRoutesMenu | undefined;

  /*Can be set as default for a user*/
  canBeUserDefalt: boolean;
}

interface navigateRoutesMenu {
  /* The menu group */
  optionGroup: optionGroups;

  icon: ReactElement;

  text: string;

  order: number;

  /*  */
  tabPaths?: string[];
}
