import {
  TitleWithAction,
  TooltipButton,
  TreeVew,
} from "../../../../components/news";
import { KemaroCostCalculationLocationDto } from "../../../../../../robotcloud-shared/resource-models";
import { Loading } from "../../../../components/Loading";
import { Autocomplete, Switch, TextField } from "@mui/material";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import { KemaroCostCalculationLocationsModal } from "./KemaroCostCalculationLocationsModal";
import Edit from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";
import useTheme from "@mui/material/styles/useTheme";
import { useKemaroCustomers } from "../../../../hooks";

export const KemaroCostCalculationLocations = (
  props: KemaroCostCalculationLocationsProps
) => {
  const { t } = useTranslation();
  const {
    isFetchingCustomers,
    allCustomers,
    setAllCustomers,
    customers,
    customerSelected,
    setCustomerSelected,
    isFetchingLocations,
    locations,
    isModalOpen,
    setIsModalOpen,
    handleSave,
  } = useKemaroCustomers(props.idCostCalculation);

  const theme = useTheme();

  return (
    <div className="col-12 locations-container">
      <TitleWithAction
        title={t("costCalculation.locations.title")}
        titleContainerClassName="child-sub-title"
        tooltip={t("costCalculation.locations.tooltip")}
        showButton={false}
      />

      <div className="row">
        {isFetchingCustomers ? (
          <div className="col-12 py-5">
            <Loading isLoading={isFetchingCustomers} />
          </div>
        ) : (
          <div className="col-12">
            <div className="row">
              <div className="col-4 col-xl-2 input-label">
                {t("costCalculation.locations.customer")}
              </div>
              <div className="col-8 col-xl-6">
                <Autocomplete
                  size="small"
                  freeSolo
                  value={customerSelected?.name ?? ""}
                  onChange={(event, newValue) =>
                    setCustomerSelected(
                      customers.find((x) => x.name === newValue)
                    )
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      placeholder={t("common.typeToSearch")}
                      fullWidth
                      variant="standard"
                      margin="dense"
                      hiddenLabel
                    />
                  )}
                  options={customers.map((x) => x.name)}
                />
              </div>
              <div className="col-12 col-xl-4">
                <table className="table table-borderless all-customers mb-0">
                  <tbody>
                    <tr>
                      <td className="text-end align-middle">
                        {t("costCalculation.locations.configuredCustomers")}
                      </td>
                      <td className="switch-container">
                        <Switch
                          color="primary"
                          checked={allCustomers}
                          onChange={(event) =>
                            setAllCustomers(event.target.checked)
                          }
                        />
                      </td>
                      <td className="text-start align-middle w-0 no-wrap">
                        {t("costCalculation.locations.allCustomers")}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            {isFetchingLocations && <Loading isLoading={isFetchingLocations} />}
            {!isFetchingLocations && locations && customerSelected && (
              <>
                <TitleWithAction
                  title={t("costCalculation.locations.assignment.title")}
                  titleContainerClassName="child-sub-title child-sub-title-tree mt-0"
                  tooltip="Edit"
                  showButton={true}
                >
                  <TooltipButton
                    tooltip={t("costCalculation.locations.assignment.tooltip")}
                    className="ms-2"
                    onClick={() => setIsModalOpen(true)}
                  >
                    <Edit color="primary" />
                  </TooltipButton>
                </TitleWithAction>

                <TreeVew
                  idProperty="id"
                  textProperty="name"
                  childNodesProperty="childNodes"
                  items={locations}
                  template={(item: KemaroCostCalculationLocationDto) => (
                    <table className="table table-borderless mb-0">
                      <tbody>
                        <tr>
                          <td className="p-0 treeview-check-uncheck-td">
                            {item.hasCalculation ? (
                              <CheckBoxOutlinedIcon
                                className="primary-text"
                                sx={{ color: "swarm.general.primaryText" }}
                              />
                            ) : item.anotherCalculationName ? (
                              <CheckBoxOutlinedIcon
                                className="light-grey-button"
                                sx={{
                                  color: "swarm.general.lightGreyButton",
                                  "&:hover": {
                                    color: "swarm.general.lightGreyButtonHover",
                                  },
                                }}
                              />
                            ) : (
                              <CheckBoxOutlineBlankOutlinedIcon
                                className="light-grey-button"
                                sx={{
                                  color: "swarm.general.lightGreyButton",
                                  "&:hover": {
                                    color: "swarm.general.lightGreyButtonHover",
                                  },
                                }}
                              />
                            )}
                          </td>
                          <td className="text-start py-0 align-middle">
                            <p>
                              {item.name}
                              {item.anotherCalculationName && (
                                <span
                                  className="light-grey-button ms-2"
                                  style={{
                                    color:
                                      theme.palette.swarm?.general
                                        ?.lightGreyButton,
                                  }}
                                  data-hover={"lightgreybutton"}
                                >
                                  <style>{`[data-hover="lightgreybutton"]:hover {
        color: ${theme.palette.swarm?.general?.lightGreyButtonHover} !important
    }`}</style>
                                  ({item.anotherCalculationName})
                                </span>
                              )}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                />
                {isModalOpen && (
                  <KemaroCostCalculationLocationsModal
                    costCalculationId={props.idCostCalculation}
                    customer={customerSelected}
                    locations={locations}
                    isOpenModal={isModalOpen}
                    onCancel={() => setIsModalOpen(false)}
                    onSave={handleSave}
                  />
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

interface KemaroCostCalculationLocationsProps {
  idCostCalculation: string;
}
