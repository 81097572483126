import { useEffect, useState } from "react";
import {
  DashboardEconomyCostCalculationSearchTextDto,
  KemaroDashboardEconomyCostCalculationListDto,
} from "../../../../../robotcloud-shared/resource-models";
import { ErrorHelper } from "../../../uiHelpers/errors.helper";
import { KemaroDashboardEconomyCostCalculationsRequests } from "../../../data/kemaro/kemaroDashboardEconomyCostCalculations.requests";
import { ToastHelper } from "../../news/Toast.helper";
import { BaseSearch } from "../BaseSearch";

export const KemaroDashboardEconomyCostCalculationSearch = (
  props: kemaroDashboardEconomyCostCalculationSearchProps
) => {
  const [items, setItems] = useState<
    KemaroDashboardEconomyCostCalculationListDto[]
  >([]);
  const [currentItem, setCurrentItem] = useState<
    KemaroDashboardEconomyCostCalculationListDto | undefined
  >(kemaroDashboardEconomyCostCalculationListDtoDefault);
  const [searchText, setSearchText] = useState("");
  const [lastSearch, setLastSearch] = useState("-");

  useEffect(() => {
    getList(searchText, props, lastSearch);
  }, [props, searchText, lastSearch]);

  useEffect(() => {
    const newCurrentItem = items.find((x) => x.id === props.currentId);
    if (newCurrentItem?.id !== currentItem?.id) {
      setCurrentItem(newCurrentItem);
    }
  }, [props.currentId, items, currentItem]);

  const getList = async (
    ts: string,
    p: kemaroDashboardEconomyCostCalculationSearchProps,
    lts: string
  ) => {
    try {
      const textSearch: DashboardEconomyCostCalculationSearchTextDto = {
        id: p.currentId ?? null,
        textToSearch: ts,
        maxResults: null,
        customerContactId: p.contactPersonId ?? null,
        blackList: p.blackList ?? null,
      };

      const strTextSearch = `${p.customerId ?? "--"}_${JSON.stringify(
        textSearch
      )}`;

      if (lts === strTextSearch) {
        return;
      }

      setLastSearch(lts);

      const result =
        await KemaroDashboardEconomyCostCalculationsRequests.getSearchText(
          p.customerId ?? null,
          textSearch
        );

      if (result.searchedText === ts.trim()) {
        setItems(result.items);
      }
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }
  };

  const handleSelectionChange = (
    calculation: KemaroDashboardEconomyCostCalculationListDto | undefined
  ) => {
    if (!props.clearAfterSelect) {
      setCurrentItem(calculation ?? undefined);
    }

    props.selectionChange(calculation ?? undefined);

    if (props.clearAfterSelect) {
      setSearchText("");
    }
  };

  return (
    <BaseSearch
      disableCloseOnSelect={props.clearAfterSelect}
      value={currentItem ?? kemaroDashboardEconomyCostCalculationListDtoDefault}
      options={items}
      getOptionLabel={(x) => (typeof x === "string" ? x : x.name.trim())}
      renderOption={(props, x) => (
        <div
          key={x.id}
          className={
            "w-100 autocomplete-template py-2 px-3 " +
            (currentItem?.id === x.id ? "autocomplete-template-select" : "")
          }
          onClick={() => handleSelectionChange(x)}
        >
          <p className="mb-0">{x.name}</p>
        </div>
      )}
      searchText={searchText}
      setSearchText={(x) => setSearchText(x)}
      onChange={(x) =>
        handleSelectionChange(x as KemaroDashboardEconomyCostCalculationListDto)
      }
      disabled={props.disabled}
    />
  );
};

interface kemaroDashboardEconomyCostCalculationSearchProps {
  customerId?: string;
  contactPersonId?: string;
  currentId?: string;
  clearAfterSelect?: boolean;
  blackList?: string[] | undefined;
  selectionChange: (
    select: KemaroDashboardEconomyCostCalculationListDto | undefined
  ) => void;
  disabled?: boolean;
}

const kemaroDashboardEconomyCostCalculationListDtoDefault: KemaroDashboardEconomyCostCalculationListDto =
  {
    id: "",
    name: "",
    currency: "",
    robots: 0,
    customers: 0,
    isDefault: false,
  };
