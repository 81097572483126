import { useTranslation } from "react-i18next";
import KemaroRobotContext from "../KemaroRobotContext";
import { useContext } from "react";

export const ErrorSummaryTooltip = () => {
  const { t } = useTranslation();
  const { currentItem } = useContext(KemaroRobotContext);

  if (currentItem!.errorSummary.state === "No")
    return (
      <table className="tool-tip-text">
        <tbody>
          <tr>
            <td className="no-wrap text-center">
              {t("robot.errorSummary.noErrors")}
            </td>
          </tr>
        </tbody>
      </table>
    );

  if (currentItem!.errorSummary.state === "Old")
    return (
      <table className="tool-tip-text">
        <tbody>
          <tr>
            <td className="no-wrap text-center">
              {t("robot.errorSummary.oldQuantity", {
                oldQuantity: currentItem!.errorSummary.oldQuantity,
              })}
            </td>
          </tr>
          <tr>
            <td className="no-wrap text-center">
              {t("robot.errorSummary.totalDays", {
                totalDays: currentItem!.errorSummaryTotalDaysToAnalyze,
              })}
            </td>
          </tr>
        </tbody>
      </table>
    );

  return (
    <table className="tool-tip-text">
      <tbody>
        <tr>
          <td className="no-wrap text-center">
            {t("robot.errorSummary.totalQuantity", {
              totalQuantity:
                currentItem!.errorSummary.recentQuantity +
                currentItem!.errorSummary.oldQuantity,
              totalDays: currentItem!.errorSummaryTotalDaysToAnalyze,
            })}
          </td>
        </tr>
        <tr>
          <td className="no-wrap text-center">
            <strong>
              {t("robot.errorSummary.recentQuantity", {
                recentQuantity: currentItem!.errorSummary.recentQuantity,
              })}
              {currentItem!.errorSummaryRecentDaysToAnalyze > 1
                ? t("robot.errorSummary.recentDays", {
                    recentDays: currentItem!.errorSummaryRecentDaysToAnalyze,
                  })
                : t("robot.errorSummary.lastDay")}
            </strong>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
