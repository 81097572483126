import useTheme from "@mui/material/styles/useTheme";
import { KemaroMissionRunPostDto } from "../../../../../../robotcloud-shared/resource-models";
import { GeneralFunctions } from "../../../generalFunctions";
import { useTranslation } from "react-i18next";
import { KemaroMissionRunShowData } from "./KemaroMissionRunShowData";

export const KemaroMissionRunShow = (props: KemaroMissionRunShowProps) => {
  const { t } = useTranslation();

  const kemaroMissionsColors = useTheme().palette.swarm?.kemaroMissions;
  const titleColor = kemaroMissionsColors?.newBodyTitle;

  return (
    <div className="row run">
      <KemaroMissionRunShowData
        titleColor={titleColor}
        text={t("mission.add.cleaningMode")}
        value={props.data.cleaningMode}
        fullWidht={true}
      />

      <KemaroMissionRunShowData
        titleColor={titleColor}
        text={t("mission.add.roomId")}
        value={props.data.roomId}
      />

      <KemaroMissionRunShowData
        titleColor={titleColor}
        text={t("mission.add.zoneId")}
        value={props.data.zoneId}
      />

      <KemaroMissionRunShowData
        titleColor={titleColor}
        text={t("mission.add.zoneName")}
        value={props.data.zoneName}
        fullWidht={true}
      />

      <KemaroMissionRunShowData
        titleColor={titleColor}
        text={t("mission.add.startReason")}
        value={props.data.startReason}
      />

      <KemaroMissionRunShowData
        titleColor={titleColor}
        text={t("mission.add.stopReason")}
        value={props.data.stopReason}
      />

      <KemaroMissionRunShowData
        titleColor={titleColor}
        text={t("mission.add.cleaningStart")}
        value={GeneralFunctions.dateToDateTimeUi(
          props.data.timestampCleaningStart
        )}
      />

      <KemaroMissionRunShowData
        titleColor={titleColor}
        text={t("mission.add.cleaningEnd")}
        value={GeneralFunctions.dateToDateTimeUi(
          props.data.timestampCleaningEnd
        )}
      />

      <KemaroMissionRunShowData
        titleColor={titleColor}
        text={t("mission.add.cleanedArea")}
        value={props.data.cleanedAreaM2}
      />

      <KemaroMissionRunShowData
        titleColor={titleColor}
        text={t("mission.add.freeArea")}
        value={props.data.freeAreaM2}
      />

      <KemaroMissionRunShowData
        titleColor={titleColor}
        text={t("mission.add.batteryStart")}
        value={props.data.batteryPercentageStart}
      />
      <KemaroMissionRunShowData
        titleColor={titleColor}
        text={t("mission.add.batteryEnd")}
        value={props.data.batteryPercentageEnd}
      />

      {props.data.cleaningErrors.length > 0 && (
        <>
          <div
            className="col-12 error m2-1"
            style={{
              backgroundColor: kemaroMissionsColors?.newBodyErrorBackground,
            }}
          >
            {props.data.cleaningErrors.map((x, idx) => (
              <div className="row" key={`error_${idx}`}>
                <div className="col-6 show-data">
                  <p className="title mb-0" style={{ color: titleColor }}>
                    {t("mission.add.errorCode")}
                  </p>
                  <p className="value mb-1">{x.errorCode}</p>
                </div>

                <div className="col-6 show-data">
                  <p className="title mb-0" style={{ color: titleColor }}>
                    {t("mission.add.timeStamp")}
                  </p>
                  <p className="value mb-1">
                    {GeneralFunctions.dateToDateTimeUi(x.timeStamp)}
                  </p>
                </div>

                <div className="col-6 show-data">
                  <p className="title mb-0" style={{ color: titleColor }}>
                    X
                  </p>
                  <p className="value mb-1">{x.mapPosition2D?.x}</p>
                </div>

                <div className="col-6 show-data">
                  <p className="title mb-0" style={{ color: titleColor }}>
                    Y
                  </p>
                  <p className="value mb-1">{x.mapPosition2D?.y}</p>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

interface KemaroMissionRunShowProps {
  data: KemaroMissionRunPostDto;
}
