import { useContext, useEffect, useState } from "react";
import { ViewHeader } from "../../../components/news/ViewHeader";
import { PermissionsHelper } from "../../../uiHelpers/permissionsHelper";
import AuthorizationContext from "../../auth/authorizationContext";
import { NavigateTo } from "../../../components/news/NavigateTo";
import { useTranslation } from "react-i18next";
import KemaroRobotContext from "./KemaroRobotContext";
import {
  AccessSettingsButton,
  DeleteButton,
  ErrorSummaryButton,
  HeartbeatButton,
  LastContactDisplayer,
  LocalCredentialButton,
  OnlineDisplayer,
  SystemInformationButton,
  TagsButton,
} from "./Components";
import { KemaroRobotNameModal } from "./Modals";
import {
  TagsDisplayer,
  tableGenericHandleKeyboardSubjectManager,
} from "../../../components/news";
import { RobotDeploymentsButton } from "./Components/RobotDeploymentsButton";
import { RobotDeploymentsHistoryButton } from "./Components/RobotDeploymentsHistoryButton";
import { RobotConnectionButton } from "../../robots/RobotConnection";
import { FeatureFlagHelper } from "../../../uiHelpers/featureFlagsHelper";
import useTheme from "@mui/material/styles/useTheme";

export const KemaroRobotViewTitle = ({
  connectionModalOpen,
  setConnectionModalOpen,
}: {
  connectionModalOpen: boolean;
  setConnectionModalOpen: (open: boolean) => void;
}) => {
  const { t } = useTranslation();

  const [canModify, setCanModify] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [canHeartbeat, setCanHeartbeat] = useState(false);
  const [canAccessSettings, setCanAccessSettings] = useState(false);
  const [canModifyTags, setCanModifyTags] = useState(false);
  const [canSeeLocalCredential, setCanSeeLocalCredential] = useState(false);
  const [canRobotAssignments, setCanRobotAssignments] = useState(true);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [canSeeRobotConnection, setCanSeeRobotConnection] = useState(false);
  const [showTags, setShowTags] = useState(false);

  const { me } = useContext(AuthorizationContext);
  const { setViewSideModalOpenChange, currentItem } =
    useContext(KemaroRobotContext);
  const theme = useTheme();

  useEffect(() => {
    setCanModify(
      PermissionsHelper.allow("KemaroRobots", "Edit", "change-name")
    );
    setCanAccessSettings(
      PermissionsHelper.allow("KemaroRobots", "Edit", "AccessSettings")
    );
    setCanDelete(PermissionsHelper.allow("KemaroRobots", "Delete"));
    setCanHeartbeat(
      me?.userAccessType === "Earlybyte" &&
        PermissionsHelper.capableForManualRobotHeartbeat()
    );

    setCanModifyTags(PermissionsHelper.allow("KemaroRobots", "View", "Tags"));

    setCanSeeLocalCredential(
      PermissionsHelper.allow("KemaroRobots", "Edit", "LocalCredential")
    );

    setCanRobotAssignments(
      PermissionsHelper.allow(
        "KemaroRobots",
        "Edit",
        "Robot-Dealer-Assignment"
      ) ||
        PermissionsHelper.allow(
          "KemaroRobots",
          "Edit",
          "Robot-Customer-Deployment"
        ) ||
        PermissionsHelper.allow(
          "KemaroRobots",
          "Edit",
          "Robot-Customer-Deployment-Location"
        )
    );
    setCanSeeRobotConnection(
      PermissionsHelper.allow("Robots", "View", "RobotConnection") &&
        FeatureFlagHelper.isFeatureEnabled("KemaroSwarmRemote")
    );
  }, [me, currentItem]);

  useEffect(() => {
    setShowTags(PermissionsHelper.allow("KemaroRobots", "View", "Tags"));
  }, [me]);

  const openEditPopupChange = (value: boolean) => {
    setViewSideModalOpenChange(value);
    tableGenericHandleKeyboardSubjectManager.setData({
      caller: "KemaroRobotNameModal",
      handleKeyboard: value,
    });
    setOpenEditPopup(value);
  };

  const headerRow = (
    <ViewHeader
      title={currentItem!.name}
      canEdit={canModify}
      onEdit={() => openEditPopupChange(true)}
      canDelete={false}
      copyLinkId={currentItem!.id ?? undefined}
    />
  );

  const buttonsRow = (
    <>
      <SystemInformationButton />

      <ErrorSummaryButton />

      {canAccessSettings && <AccessSettingsButton />}

      {canSeeLocalCredential && <LocalCredentialButton />}

      {canModifyTags && <TagsButton />}

      {canDelete && <DeleteButton />}

      {canHeartbeat && <HeartbeatButton />}

      {canRobotAssignments && <RobotDeploymentsButton />}

      {currentItem!.hasActivationDate && <RobotDeploymentsHistoryButton />}

      {canSeeRobotConnection && currentItem?.id && (
        <RobotConnectionButton
          robotId={currentItem.id}
          connectionModalOpen={connectionModalOpen}
          setConnectionModalOpen={setConnectionModalOpen}
        />
      )}
    </>
  );

  const tagsRow = showTags && (
    <div className="kemaro-robot-tags">
      <TagsDisplayer tags={currentItem!.tags} />
    </div>
  );

  const connectionRow = (
    <>
      <span
        className="robot-model px-2 py-1 me-4"
        style={{
          color: theme.palette.swarm?.kemaroRobots?.robotModel,
          border: theme.palette.swarm?.kemaroRobots?.robotModelBorder,
        }}
      >
        {currentItem!.robotModelName}
      </span>
      <OnlineDisplayer />
      <LastContactDisplayer />
    </>
  );

  const robotNameModal = openEditPopup && (
    <KemaroRobotNameModal onClose={() => openEditPopupChange(false)} />
  );

  const dashboardAndMissionRow = (
    <>
      {currentItem!.idKemaroDashboardEconomyCostCalculation && (
        <NavigateTo
          text={t("dashboard.entityName")}
          shorCut="dashboard-economy"
          className="pe-3 mt-2"
          paramters={[
            { name: "customerId", value: currentItem!.idCustomer! },
            {
              name: "companyStructureId",
              value: currentItem!.idCompanyStructure ?? "",
            },
          ]}
        />
      )}

      <NavigateTo
        text={t("mission.entitiesName")}
        shorCut="missions"
        className="mt-2"
        paramters={[{ name: "idRobot", value: currentItem!.id! }]}
      />
    </>
  );

  return (
    <div className="container-fluid ps-0 ms-0">
      <div className="row mx-0 px-0">
        <div className="col mx-0 px-0">
          <div className="container ps-0 ms-0">
            <div className="py-0 my-0 ms-2">{headerRow}</div>
            <div className="ps-0 ms-0">{buttonsRow}</div>
            <div className="py-2 ms-2">{connectionRow}</div>
            <div className="pt-2 ms-2">{tagsRow}</div>
          </div>
        </div>

        <div
          className="col-1 d-flex ps-2 pe-0 mx-0 d-flex align-items-start justify-content-between "
          style={{ minWidth: "14rem" }}
        >
          {dashboardAndMissionRow}
        </div>

        {robotNameModal}
      </div>
    </div>
  );
};
