import {
  CustomerContactDto,
  SearchTextDtoOfGuid,
} from "../../../../robotcloud-shared/resource-models";
import { CustomersContactsRequests } from "../../data/customersContacts.request";
import { BaseSearch } from "./BaseSearch";
import "./search.scss";
import useTheme from "@mui/material/styles/useTheme";
import { useTextSearch } from "../../hooks";

export const CustomerContactSearch = (props: customerContactSearchProps) => {
  const searchTextWrapper = (
    searchTextDto: SearchTextDtoOfGuid,
    manufacturerId?: string | null
  ) => {
    // Convert undefined to null to match the expected type of CustomersContactsRequests.getSearchText
    return CustomersContactsRequests.getSearchText(
      searchTextDto,
      manufacturerId ?? null
    );
  };
  const {
    items,
    currentItem,
    searchText,
    setSearchText,
    handleSelectionChange,
  } = useTextSearch<
    CustomerContactDto,
    { searchedText: string | null; items: CustomerContactDto[] }
  >(
    searchTextWrapper,
    [],
    !!props.clearAfterSelect,
    undefined,
    props.blackList,
    props.currentId,
    props.selectionChange,
    props.customerId ?? undefined
  );

  const theme = useTheme();

  return (
    <BaseSearch
      disableCloseOnSelect={props.clearAfterSelect}
      value={currentItem ?? customerContactDtoDefault}
      options={items}
      getOptionLabel={generateOption}
      renderOption={(props, x) => (
        <div
          key={x.id}
          className={
            "w-100 autocomplete-template py-2 px-3 " +
            (currentItem?.id === x.id ? "autocomplete-template-select" : "")
          }
          onClick={() => handleSelectionChange(x.id, "id")}
        >
          <p className="mb-0">
            <span>{`${x.firstName} ${x.sureName}`}</span>
            <span className="float-end">{x.country?.name}</span>
          </p>

          <p
            className="mb-0 search-explanation"
            style={{ color: theme.palette.swarm?.search?.searchExplanation }}
          >
            {x.customer?.name}
          </p>
        </div>
      )}
      searchText={searchText}
      setSearchText={(x) => setSearchText(x)}
      onChange={(x) => handleSelectionChange(x!.id, "id")}
    />
  );
};

interface customerContactSearchProps {
  customerId: string | null;
  currentId?: string;
  clearAfterSelect?: boolean;
  blackList?: string[] | undefined;
  selectionChange: (selected: CustomerContactDto | undefined) => void;
}

const customerContactDtoDefault: CustomerContactDto = {
  id: "",
  firstName: "",
  sureName: "",
  address: "",
  zipCode: "",
  city: "",
  phone: "",
  email: "",
  description: "",
  idCustomer: "",
  customer: null,
  idCountry: "",
  country: null,
};

const generateOption = (item: CustomerContactDto | string | undefined) => {
  if (typeof item === "string") return item;

  return item && item.id
    ? `${item.firstName} ${item.sureName} ` + (item.country?.name ?? "")
    : "";
};
