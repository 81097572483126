import { t } from "i18next";
import {
  WorkflowTranslatedDataDto,
  Language,
  WorkflowCompleteDto,
  ProcessCompleteDto,
  ProcessTranslatedDataDto,
  ProcessReducedDto,
  WorkflowReducedDto,
  ApiLoginCompleteDto,
} from "../../../../robotcloud-shared/resource-models";
import { ProcessesRequests } from "../../data/processes.request";
import { UsersRequests } from "../../data/users.requests";

export const getWorkflowTranslatedData = (
  workflowTranslatedDatas: WorkflowTranslatedDataDto[],
  language: string
) => {
  const dataInLanguage = workflowTranslatedDatas.find(
    (x) => x.languageCode === language
  );
  if (dataInLanguage) {
    return dataInLanguage;
  }
  return undefined;
};

export const getProcessTranslatedData = (
  processTranslatedDatas: ProcessTranslatedDataDto[],
  language: string
) => {
  const dataInLanguage = processTranslatedDatas.find(
    (x) => x.languageCode === language
  );
  if (dataInLanguage) {
    return dataInLanguage;
  } else {
    return undefined;
  }
};

export const getWorkFlowFromDto = (
  dto: WorkflowCompleteDto,
  manufacturer: string,
  language: string
): Workflow => {
  const translatedData = getWorkflowTranslatedData(
    dto.workflowTranslatedDatas,
    language
  );
  const workflow = {
    ...dto,
    isVisited: false,
    manufacturer: manufacturer,
    translatedTitle: translatedData?.title ?? dto.displayTitle,
    translatedInstructions:
      translatedData?.instructions ?? dto.displayInstructions,
    isAddedByComplianceRequirements: false,
  };
  return workflow;
};

export const getProcessFromDto = (
  dto: ProcessCompleteDto,
  language: string
): Process => {
  const translatedData = getProcessTranslatedData(
    dto.processTranslatedDatas,
    language
  );
  const process: Process = {
    ...dto,
    translatedTitle: translatedData?.title ?? dto.displayTitle,
    workflows: [],
  };
  dto.workflows.forEach((workflowCompleteDto: WorkflowCompleteDto) => {
    process.workflows.push(
      getWorkFlowFromDto(
        workflowCompleteDto,
        dto.manufacturer?.name ?? "SWARM",
        language
      )
    );
  });

  return process;
};

export const getPendingProcesses = async (
  language: string,
  includeLinkProcesses: boolean = true
): Promise<Process[]> => {
  const dtos = await ProcessesRequests.getPendingProcesses(
    includeLinkProcesses
  );
  const processes: Process[] = [];
  dtos.forEach((processCompleteDto: ProcessCompleteDto) => {
    processes.push(getProcessFromDto(processCompleteDto, language));
  });

  return processes;
};

export const getPendingCompanyComplianceProcesses = async (
  apiLoginCompleteDto: ApiLoginCompleteDto
): Promise<Process[]> => {
  const dtos = await ProcessesRequests.getPendingCompanyComplianceProcesses();
  const processes: Process[] = [];
  let administrators = "";
  if (dtos.length >= 1) {
    // Get administrator or owner users to build the instructions
    if (apiLoginCompleteDto.idCustomer) {
      const users = await UsersRequests.getFromCustomer(
        apiLoginCompleteDto.idCustomer,
        ["Administrator", "Owner"]
      );
      administrators = users
        .flatMap((u) => String.fromCodePoint(0x2022) + " " + u.name)
        .join("\n");
    } else {
      if (apiLoginCompleteDto.idDealer) {
        const users = await UsersRequests.getFromDealer(
          apiLoginCompleteDto.idDealer,
          ["Administrator", "Owner"]
        );
        administrators = users
          .flatMap((u) => String.fromCodePoint(0x2022) + " " + u.name)
          .join("\n");
      }
    }
    const displayInstructions = t(
      "onBoarding.workflow.notAllowedInstructions",
      {
        application: apiLoginCompleteDto.currentManufacturerName,
        administrators,
      }
    );
    // Adding instructions and type to workflows.
    dtos.forEach((processCompleteDto: ProcessCompleteDto) => {
      const process = getProcessFromDto(
        processCompleteDto,
        apiLoginCompleteDto.language ?? "en"
      );
      process.workflows.forEach((workflow) => {
        workflow.isNotAllowedWorkflow = true;
        workflow.displayInstructions = displayInstructions;
        workflow.required = true;
        workflow.abortable = false;
      });
      processes.push(process);
    });
  }
  return processes;
};

export const getProcessesFromLink = async (
  idLink: string,
  language: Language
): Promise<Process> => {
  const dto = await ProcessesRequests.getProcessesFromLink(idLink);
  return getProcessFromDto(dto, language);
};

export const addPendingComplianceWorkflows = async (
  process: Process,
  language: string
): Promise<boolean> => {
  let result = false;
  const pendingComplianceWorkflowToVisit = process.workflows.find(
    (x) => x.isVisited === false && x.isAddedByComplianceRequirements === true
  );
  if (!pendingComplianceWorkflowToVisit) {
    // Only ask for compliance processes if there are no pending compliance processes
    const pendingProcesses = await getPendingProcesses(
      language,
      false //not include linked processes, only compliance.
    );
    const previousProcessWorkflows: Workflow[] = [...process.workflows];
    process.workflows = previousProcessWorkflows.filter(
      (x) => x.isVisited === true && x.isAddedByComplianceRequirements === false
    );
    pendingProcesses.forEach((currentProcess: Process) => {
      currentProcess.workflows.forEach((workflow: Workflow) => {
        // Add the workflow if it is not already there.
        if (process.workflows.findIndex((x) => x.id === workflow.id) === -1) {
          result = true;
          workflow.isAddedByComplianceRequirements = true;
          process.workflows.push(workflow);
        }
      });
    });
    previousProcessWorkflows
      .filter(
        (x) =>
          x.isVisited === false && x.isAddedByComplianceRequirements === false
      )
      .forEach((workflow) => {
        process.workflows.push(workflow);
      });
  }
  return result;
};

export const getWorkFlowFromReducedDto = (
  dto: WorkflowReducedDto,
  manufacturer: string
): Workflow => {
  const workflow = {
    ...dto,
    isVisited: false,
    manufacturer: manufacturer,
    translatedTitle: dto.displayTitle,
    translatedInstructions: dto.displayInstructions,
    isAddedByComplianceRequirements: false,
    workflowTranslatedDatas: [],
  };
  return workflow;
};

export const getProcessFromReducedDto = (
  dto: ProcessReducedDto,
  workflowDtos: WorkflowReducedDto[],
  manufacturerName: string,
  workflowTranslatedData: WorkflowTranslatedDataDto[]
): Process => {
  const process: Process = {
    ...dto,
    translatedTitle: dto.displayTitle,
    processLinks: [],
    processTranslatedDatas: [],
    workflows: [],
    manufacturer: {
      id: dto.idManufacturer ?? "",
      name: manufacturerName,
      role: "Viewer",
    },
  };
  workflowDtos.forEach((workflowReducedDto: WorkflowReducedDto) => {
    const workflow = getWorkFlowFromReducedDto(
      workflowReducedDto,
      manufacturerName ?? "SWARM"
    );
    workflow.workflowTranslatedDatas = workflowTranslatedData;
    // if (workflow.id === workflowTranslatedData?.idWorkflow) {
    //   workflow.translatedInstructions = workflowTranslatedData.instructions;
    //   workflow.translatedTitle = workflowTranslatedData.title ?? "";
    //   workflow.workflowTranslatedDatas.push(workflowTranslatedData);
    // }
    process.workflows.push(workflow);
  });

  return process;
};

export const getProcessFromCompleteDto = (
  dto: ProcessReducedDto,
  workflowDtos: WorkflowCompleteDto[],
  manufacturerName: string,
  language: string
): Process => {
  const process: Process = {
    ...dto,
    translatedTitle: dto.displayTitle,
    processLinks: [],
    processTranslatedDatas: [],
    workflows: [],
    manufacturer: {
      id: dto.idManufacturer ?? "",
      name: manufacturerName,
      role: "Viewer",
    },
  };
  workflowDtos.forEach((workflowCompleteDto: WorkflowCompleteDto) => {
    const workflow = getWorkFlowFromDto(
      workflowCompleteDto,
      manufacturerName ?? "SWARM",
      language
    );
    process.workflows.push(workflow);
  });

  return process;
};

export interface Workflow extends WorkflowCompleteDto {
  isVisited: boolean;
  isAddedByComplianceRequirements: boolean;
  translatedTitle: string;
  translatedInstructions: string;
  manufacturer: string;
  isNotAllowedWorkflow?: boolean;
}

export interface Process extends ProcessCompleteDto {
  translatedTitle: string;
  workflows: Workflow[];
}

export interface OnBoardingLanguage {
  code: string;
  name: string;
}

export const onBoardingLanguages: OnBoardingLanguage[] = [
  { code: "aa", name: "Afar" },
  { code: "ab", name: "Abkhazian" },
  { code: "ae", name: "Avestan" },
  { code: "af", name: "Afrikaans" },
  { code: "ak", name: "Akan" },
  { code: "am", name: "Amharic" },
  { code: "an", name: "Aragonese" },
  { code: "ar", name: "Arabic" },
  { code: "as", name: "Assamese" },
  { code: "av", name: "Avaric" },
  { code: "ay", name: "Aymara" },
  { code: "az", name: "Azerbaijani" },
  { code: "ba", name: "Bashkir" },
  { code: "be", name: "Belarusian" },
  { code: "bg", name: "Bulgarian" },
  { code: "bi", name: "Bislama" },
  { code: "bm", name: "Bambara" },
  { code: "bn", name: "Bengali" },
  { code: "bo", name: "Tibetan" },
  { code: "br", name: "Breton" },
  { code: "bs", name: "Bosnian" },
  { code: "ca", name: "Catalan, Valencian" },
  { code: "ce", name: "Chechen" },
  { code: "ch", name: "Chamorro" },
  { code: "co", name: "Corsican" },
  { code: "cr", name: "Cree" },
  { code: "cs", name: "Czech" },
  { code: "cu", name: "Church Slavic, Old Slavonic,  Old Church Slavonic" },
  { code: "cv", name: "Chuvash" },
  { code: "cy", name: "Welsh" },
  { code: "da", name: "Danish" },
  { code: "de", name: "German" },
  { code: "dv", name: "Divehi, Dhivehi, Maldivian" },
  { code: "dz", name: "Dzongkha" },
  { code: "ee", name: "Ewe" },
  { code: "el", name: "Greek, Modern (1453-)" },
  { code: "en", name: "English" },
  { code: "eo", name: "Esperanto" },
  { code: "es", name: "Spanish, Castilian" },
  { code: "et", name: "Estonian" },
  { code: "eu", name: "Basque" },
  { code: "fa", name: "Persian" },
  { code: "ff", name: "Fulah" },
  { code: "fi", name: "Finnish" },
  { code: "fj", name: "Fijian" },
  { code: "fo", name: "Faroese" },
  { code: "fr", name: "French" },
  { code: "fy", name: "Western Frisian" },
  { code: "ga", name: "Irish" },
  { code: "gd", name: "Gaelic, Scomttish Gaelic" },
  { code: "gl", name: "Galician" },
  { code: "gn", name: "Guarani" },
  { code: "gu", name: "Gujarati" },
  { code: "gv", name: "Manx" },
  { code: "ha", name: "Hausa" },
  { code: "he", name: "Hebrew" },
  { code: "hi", name: "Hindi" },
  { code: "ho", name: "Hiri Motu" },
  { code: "hr", name: "Croatian" },
  { code: "ht", name: "Haitian, Haitian Creole" },
  { code: "hu", name: "Hungarian" },
  { code: "hy", name: "Armenian" },
  { code: "hz", name: "Herero" },
  {
    code: "ia",
    name: "Interlingua (International Auxiliary Language Association)",
  },
  { code: "id", name: "Indonesian" },
  { code: "ie", name: "Interlingue, Occidental" },
  { code: "ig", name: "Igbo" },
  { code: "ii", name: "Sichuan Yi, Nuosu" },
  { code: "ik", name: "Inupiaq" },
  { code: "io", name: "Ido" },
  { code: "is", name: "Icelandic" },
  { code: "it", name: "Italian" },
  { code: "iu", name: "Inuktitut" },
  { code: "ja", name: "Japanese" },
  { code: "jv", name: "Javanese" },
  { code: "ka", name: "Georgian" },
  { code: "kg", name: "Kongo" },
  { code: "ki", name: "Kikuyu, Gikuyu" },
  { code: "kj", name: "Kuanyama, Kwanyama" },
  { code: "kk", name: "Kazakh" },
  { code: "kl", name: "Kalaallisut, Greenlandic" },
  { code: "km", name: "Central Khmer" },
  { code: "kn", name: "Kannada" },
  { code: "ko", name: "Korean" },
  { code: "kr", name: "Kanuri" },
  { code: "ks", name: "Kashmiri" },
  { code: "ku", name: "Kurdish" },
  { code: "kv", name: "Komi" },
  { code: "kw", name: "Cornish" },
  { code: "ky", name: "Kirghiz, Kyrgyz" },
  { code: "la", name: "Latin" },
  { code: "lb", name: "Luxembourgish, Letzeburgesch" },
  { code: "lg", name: "Ganda" },
  { code: "li", name: "Limburgan, Limburger, Limburgish" },
  { code: "ln", name: "Lingala" },
  { code: "lo", name: "Lao" },
  { code: "lt", name: "Lithuanian" },
  { code: "lu", name: "Luba-Katanga" },
  { code: "lv", name: "Latvian" },
  { code: "mg", name: "Malagasy" },
  { code: "mh", name: "Marshallese" },
  { code: "mi", name: "Maori" },
  { code: "mk", name: "Macedonian" },
  { code: "ml", name: "Malayalam" },
  { code: "mn", name: "Mongolian" },
  { code: "mr", name: "Marathi" },
  { code: "ms", name: "Malay" },
  { code: "mt", name: "Maltese" },
  { code: "my", name: "Burmese" },
  { code: "na", name: "Nauru" },
  { code: "nb", name: "Norwegian Bokmål" },
  { code: "nd", name: "North Ndebele" },
  { code: "ne", name: "Nepali" },
  { code: "ng", name: "Ndonga" },
  { code: "nl", name: "Dutch, Flemish" },
  { code: "nn", name: "Norwegian Nynorsk" },
  { code: "no", name: "Norwegian" },
  { code: "nr", name: "South Ndebele" },
  { code: "nv", name: "Navajo, Navaho" },
  { code: "ny", name: "Chichewa, Chewa, Nyanja" },
  { code: "oc", name: "Occitan (post 1500)" },
  { code: "oj", name: "Ojibwa" },
  { code: "om", name: "Oromo" },
  { code: "or", name: "Oriya" },
  { code: "os", name: "Ossetian, Ossetic" },
  { code: "pa", name: "Panjabi, Punjabi" },
  { code: "pi", name: "Pali" },
  { code: "pl", name: "Polish" },
  { code: "ps", name: "Pushto, Pashto" },
  { code: "pt", name: "Portuguese" },
  { code: "qu", name: "Quechua" },
  { code: "rm", name: "Romansh" },
  { code: "rn", name: "Rundi" },
  { code: "ro", name: "Romanian, Moldavian, Moldovan" },
  { code: "ru", name: "Russian" },
  { code: "rw", name: "Kinyarwanda" },
  { code: "sa", name: "Sanskrit" },
  { code: "sc", name: "Sardinian" },
  { code: "sd", name: "Sindhi" },
  { code: "se", name: "Northern Sami" },
  { code: "sg", name: "Sango" },
  { code: "si", name: "Sinhala, Sinhalese" },
  { code: "sk", name: "Slovak" },
  { code: "sl", name: "Slovenian" },
  { code: "sm", name: "Samoan" },
  { code: "sn", name: "Shona" },
  { code: "so", name: "Somali" },
  { code: "sq", name: "Albanian" },
  { code: "sr", name: "Serbian" },
  { code: "ss", name: "Swati" },
  { code: "st", name: "Sotho, Southern" },
  { code: "su", name: "Sundanese" },
  { code: "sv", name: "Swedish" },
  { code: "sw", name: "Swahili" },
  { code: "ta", name: "Tamil" },
  { code: "te", name: "Telugu" },
  { code: "tg", name: "Tajik" },
  { code: "th", name: "Thai" },
  { code: "ti", name: "Tigrinya" },
  { code: "tk", name: "Turkmen" },
  { code: "tl", name: "Tagalog" },
  { code: "tn", name: "Tswana" },
  { code: "to", name: "Tonga (Tonga Islands)" },
  { code: "tr", name: "Turkish" },
  { code: "ts", name: "Tsonga" },
  { code: "tt", name: "Tatar" },
  { code: "tw", name: "Twi" },
  { code: "ty", name: "Tahitian" },
  { code: "ug", name: "Uighur, Uyghur" },
  { code: "uk", name: "Ukrainian" },
  { code: "ur", name: "Urdu" },
  { code: "uz", name: "Uzbek" },
  { code: "ve", name: "Venda" },
  { code: "vi", name: "Vietnamese" },
  { code: "vo", name: "Volapük" },
  { code: "wa", name: "Walloon" },
  { code: "wo", name: "Wolof" },
  { code: "xh", name: "Xhosa" },
  { code: "yi", name: "Yiddish" },
  { code: "yo", name: "Yoruba" },
  { code: "za", name: "Zhuang, Chuang" },
  { code: "zh", name: "Chinese" },
  { code: "zu", name: "Zulu" },
];

export const getOnboardingLanguageOrderedByName = (): OnBoardingLanguage[] => {
  return onBoardingLanguages.sort((a, b) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLocaleLowerCase();
    if (nameA > nameB) {
      return 1;
    }
    if (nameA < nameB) {
      return -1;
    }
    return 0;
  });
};

export const getLanguage = (code: string): OnBoardingLanguage | undefined => {
  return onBoardingLanguages.find((x) => x.code === code);
};
