import { useState, useEffect, useContext } from "react";
import { ToastHelper } from "../../../../components/news/Toast.helper";
import { KemaroRobotRequests } from "../../../../data/kemaro/kemaroRobot.request";
import { ErrorHelper } from "../../../../uiHelpers/errors.helper";
import KemaroRobotContext from "../KemaroRobotContext";
import { KemaroRobotCleaningDataTotalsDtoType } from "../../../../../../robotcloud-shared/resource-models";

export const useKemaroRobotCleanedSurfaceYears = (
  currentCleaningDataTotal: KemaroRobotCleaningDataTotalsDtoType
) => {
  const [isFetching, setIsFetching] = useState(true);

  const [years, setYears] = useState<number[]>([]);
  const [currentYear, setCurrentYear] = useState(0);

  const { currentItem } = useContext(KemaroRobotContext);

  useEffect(() => {
    if (!currentItem?.id) return;

    getData(currentItem.id, currentCleaningDataTotal);
  }, [currentItem, currentCleaningDataTotal]);

  const getData = async (
    id: string,
    totalType: KemaroRobotCleaningDataTotalsDtoType
  ) => {
    setIsFetching(true);

    try {
      const data = await KemaroRobotRequests.cleanedSurfaceChartYears(
        id,
        totalType
      );
      setYears(data.years);
      setCurrentYear(
        years && years.length > 0 ? years[0] : new Date().getFullYear()
      );
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsFetching(false);
  };

  return {
    isFetching,
    years,
    currentYear,
    setCurrentYear,
  };
};
