import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { CountryDto } from "../../../../robotcloud-shared/resource-models";
import { useTranslation } from "react-i18next";
import { useCountries } from "../../hooks";

export const CountrySearch = (props: countrySearchProps) => {
  const [currentItem, setCurrentItem] = useState<CountryDto | undefined>(
    undefined
  );
  const { t } = useTranslation();

  const { countries } = useCountries();
  if (!countries) {
    return <></>;
  }

  if (props.currentId) {
    const newCurrentItem = countries.find((x) => x.id === props.currentId);
    if (newCurrentItem?.id !== currentItem?.id) {
      setCurrentItem(newCurrentItem);
    }
  }

  const selectionChange = (newValue: string | null) => {
    if (!newValue) {
      setCurrentItem(undefined);
      props.selectionChange(undefined);
      return;
    }

    const selected = countries.find((x) => x.name === newValue);
    if (!props.clearAfterSelect) {
      setCurrentItem(selected);
    }

    props.selectionChange(selected);

    if (props.clearAfterSelect) {
      setCurrentItem(undefined);
    }
  };

  return (
    <Autocomplete
      disabled={props.disabled}
      disableCloseOnSelect={props.clearAfterSelect}
      size="small"
      freeSolo
      value={currentItem?.name ?? ""}
      onChange={(event, newValue) => selectionChange(newValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          label=""
          placeholder={t("common.typeToSearch")}
          fullWidth
          variant="standard"
          margin="dense"
          hiddenLabel
        />
      )}
      options={countries
        .filter((x) => !props.blackList || !props.blackList.includes(x.id))
        .map((x) => x.name)}
    />
  );
};

interface countrySearchProps {
  currentId?: string;
  clearAfterSelect?: boolean;
  blackList?: string[] | undefined;
  disabled?: boolean;
  selectionChange: (select: CountryDto | undefined) => void;
}
