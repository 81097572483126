import { FeatureFlagName } from "../../../robotcloud-shared/resource-models";
import { StorageManagerService } from "../data/storageManager.service";

export class FeatureFlagHelper {
  static isFeatureEnabled = (featureFlagName: FeatureFlagName): boolean => {
    const featureFlags = StorageManagerService.getFeatureFlags();
    if (featureFlags) {
      const featureFlag = featureFlags.find((x) => x.name === featureFlagName);
      if (featureFlag) {
        return featureFlag.isEnabled;
      }
    }
    return false;
  };
}
