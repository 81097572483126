import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import "./manufacturers.scss";
import { TableGeneric } from "../../components/news/TableGeneric/TableGeneric";
import { CrudItemsPanel } from "../../components/news/CrudItemsPanel";
import { Pagination } from "../../components/news/Pagination";
import { ApplicationBar } from "../../components/news/ApplicationBar";
import { ManufacturerCreateModal } from "./ManufacturerCreateModal";
import { ManufacturerView } from "./ManufacturerView";
import { PermissionsHelper } from "../../uiHelpers/permissionsHelper";
import { OrganizationTabsNav } from "../../components/news/OrganizationTabsNav";
import { useTranslation } from "react-i18next";
import { TableGenericColumn } from "../../components/news/TableGeneric/models";
import { useManufacturers } from "../../hooks";

export const Manufacturers = () => {
  const { t } = useTranslation();

  const {
    // User and item states
    me,
    items,
    currentItem,
    setCurrentId,
    // Fetching states
    isFetchingList,
    setIsFetchingList,
    isFetchingItem,
    // Popup states and interactions
    openCreatePopup,
    setOpenCreatePopup,
    // Pagination and ordering
    pagination,
    pageChange,
    dataOrder,
    setDataOrder,
    // Search and filtering
    textSearchChange,
    // Event handlers
    handleCreateSave,
    handleEdit,
    handleDelete,
  } = useManufacturers();
  const columnsDefinition: TableGenericColumn[] = [
    {
      title: t("common.name"),
      property: "name",
    },
  ];
  const [canAdd, setCanAdd] = useState(false);

  useEffect(() => {
    setCanAdd(PermissionsHelper.allow("Manufacturers", "Create"));
  }, []);

  return (
    <Box className="page-content">
      <Box className="page-header">
        <ApplicationBar
          title={t("applicationBar.organization.title")}
          canSearch={me?.userAccessType !== "Manufacturer"}
          search={textSearchChange}
        />
      </Box>

      <OrganizationTabsNav currentTab="manufacturers" />

      <Box className="page-body d-flex manufacturers-body">
        {me?.userAccessType !== "Manufacturer" ? (
          <div className="manufacturers-left-side">
            <CrudItemsPanel
              title={t("manufacturer.listTitle")}
              createToolTip={t("manufacturer.create.tooltip")}
              containerClassName="manufacturers-list-items"
              isFetching={isFetchingList}
              items={items}
              canCreate={canAdd}
              onCreateClick={() => setOpenCreatePopup(true)}
            >
              <TableGeneric
                showHeader={true}
                columns={columnsDefinition}
                items={items ?? []}
                idProperty="id"
                headerClick={(header, itemsOrder) => setDataOrder(itemsOrder)}
                selectionChanged={(item) => setCurrentId(item.id)}
                itemsOrder={dataOrder}
                selectId={currentItem?.id}
                isAwaiting={isFetchingItem}
                captureKeyboard
              />
            </CrudItemsPanel>

            <Pagination
              containerClassName="manufacturers-list-pager"
              pagination={pagination}
              isFetching={isFetchingList}
              onChange={pageChange}
            />

            {openCreatePopup && (
              <ManufacturerCreateModal
                title={t("manufacturer.create.title")}
                openPopup={openCreatePopup}
                onSave={handleCreateSave}
                onCancel={() => setOpenCreatePopup(false)}
              />
            )}
          </div>
        ) : (
          <></>
        )}

        <Box className="view-container">
          <ManufacturerView
            item={currentItem}
            isFetching={isFetchingItem}
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
        </Box>
      </Box>
    </Box>
  );
};
