import { ReactElement } from "react";

export const KemaroDashboardEconomyFiltersFilter = (
  props: KemaroDashboardEconomyFiltersFilterProps
) => {
  return (
    <div className={props.cssContainerClass}>
      <table className="table table-borderless mb-0">
        <tbody>
          <tr>
            <td className="filter-title ps-0">
              <span className="default-text no-wrap">{props.text}</span>
            </td>
            <td className="w-100 pe-0 pb-0">{props.children}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

interface KemaroDashboardEconomyFiltersFilterProps {
  cssContainerClass: string;
  text: string;
  children?: ReactElement | never[];
}
