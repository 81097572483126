export class BaseRequest {
  static objectToQueryString = (object: any) => {
    let result = "";
    let isfirst = true;

    Object.keys(object).forEach((propertyName) => {
      if (object[propertyName]) {
        if (Array.isArray(object[propertyName])) {
          object[propertyName].forEach((y: any) => {
            result += BaseRequest.objectToQueryStringAdd(
              propertyName,
              y,
              isfirst
            );
            isfirst = false;
          });
        } else {
          result += BaseRequest.objectToQueryStringAdd(
            propertyName,
            object[propertyName],
            isfirst
          );
          isfirst = false;
        }
      }
    });

    return result;
  };

  private static objectToQueryStringAdd = (
    propertyName: string,
    value: any,
    isFirst: boolean
  ) => {
    return (
      `${isFirst ? "" : "&"}${propertyName}=` +
      (value instanceof Date ? BaseRequest.dateToString(value) : value)
    );
  };

  static dateToString(date: Date) {
    return (
      date.getFullYear().toString().padStart(4, "0") +
      "-" +
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      date.getDate().toString().padStart(2, "0") +
      "T" +
      date.getHours().toString().padStart(2, "0") +
      ":" +
      date.getMinutes().toString().padStart(2, "0") +
      ":" +
      date.getSeconds().toString().padStart(2, "0")
    );
  }
}
