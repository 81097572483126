// hooks/useKemaroAcademySection.ts
import { useState, useEffect } from "react";
import { ApplicationConfigurationRequest } from "../data/applicationConfiguration.request";
import { ErrorHelper } from "../uiHelpers/errors.helper";
import { ToastHelper } from "../components/news";
import { BrowserAuthError } from "@azure/msal-browser";
import {
  ApiLoginCompleteDto,
  FeatureFlagFilterCriteriaDto,
} from "../../../robotcloud-shared/resource-models";
import { PermissionsHelper } from "../uiHelpers/permissionsHelper";
import { FeatureFlagsRequests } from "../data/featureFlags.request";
import { StorageManagerService } from "../data/storageManager.service";

export const useKemaroAcademySection = (me?: ApiLoginCompleteDto) => {
  const [isKemaroAcademySection, setIsKemaroAcademySection] =
    useState<boolean>(false);
  const [isManufacturerAdmin, setIsManufacturerAdmin] = useState(false);

  useEffect(() => {
    const updateIsKemaroAcademySection = async () => {
      try {
        setIsKemaroAcademySection(
          await ApplicationConfigurationRequest.getKemaroAcademyUsersEnable()
        );
      } catch (error) {
        const browserAuthError = error as BrowserAuthError;
        if (!browserAuthError) ToastHelper.errors(ErrorHelper.process(error));
      }
    };

    if (me) {
      const filterCriteria: FeatureFlagFilterCriteriaDto = {
        id: null,
        isEnabled: null,
        name: null,
        page: 1,
        maxItemCount: 9000,
        orderBy: "Asc",
        orderColumns: "name",
      };
      FeatureFlagsRequests.getList(filterCriteria)
        .then((response) => {
          StorageManagerService.setFeatureFlags(response.items);
        })
        .catch((error) => {
          ToastHelper.errors(ErrorHelper.process(error));
        });
      updateIsKemaroAcademySection();
      setIsManufacturerAdmin(
        me?.userAccessType === "Earlybyte" ||
          PermissionsHelper.isManufacturerAdmin()
      );
    }
  }, [me]);

  return {
    isKemaroAcademySection,
    setIsKemaroAcademySection,
    isManufacturerAdmin,
    setIsManufacturerAdmin,
  };
};
