import { useState, useEffect } from "react";
import { KemaroRobotDeploymentHistoryDto } from "../../../robotcloud-shared/resource-models";
import { ToastHelper } from "../components/news";
import { kemaroRobotDeploymentGetHistory } from "../data/kemaro/kemaroRobotDeployment.request";
import { ErrorHelper } from "../uiHelpers/errors.helper";

export const useKemaroDeploymentHistoryData = (kemaroRobotId: string) => {
  const [isLoading, setIsLoading] = useState(true);
  const [deploymentHistory, setDeploymentHistory] = useState<KemaroRobotDeploymentHistoryDto[]>([]);

  useEffect(() => {
    if (!kemaroRobotId) return;
    setIsLoading(true);
    kemaroRobotDeploymentGetHistory(kemaroRobotId)
      .then((data) => setDeploymentHistory(data))
      .catch((error) => ToastHelper.errors(ErrorHelper.process(error)))
      .finally(() => setIsLoading(false));
  }, [kemaroRobotId]);

  return { deploymentHistory, isLoading };
};
