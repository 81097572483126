import { useTheme } from "@mui/material";
import { CrudItemsPanel, TableGeneric } from "../../components/news";
import { useTranslation } from "react-i18next";
import { Pagination } from "../../components/news/Pagination";
import { useContext, useState } from "react";
import { CustomFieldContext } from "./CustomFieldContext";
import { CustomFieldTypeSelector } from "./CustomFieldTypeSelector";
import { customFieldColumnDefinition } from "./CustomFieldColumnDefinition";
import { CustomFieldModal } from "./CustomFieldModal";

export const CustomFieldLeftSide = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const {
    isFetchingList,
    pagination,
    pageChange,
    items,
    dataOrder,
    setDataOrder,
    setCurrentId,
    currentItem,
    isFetchingItem,
    currentType,
  } = useContext(CustomFieldContext);

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  return (
    <div
      className="custom-fields-left-side"
      style={{
        borderRight: theme.palette.swarm?.customField?.bodyLeftSideBorderRight,
      }}
    >
      <CrudItemsPanel
        title=""
        titleLeftSide={<CustomFieldTypeSelector />}
        createToolTip={t("customFields.create.tooltip")}
        containerClassName="custom-fields-list-items"
        isFetching={isFetchingList}
        items={items}
        canCreate={true}
        onCreateClick={() => setIsCreateModalOpen(true)}
      >
        <TableGeneric
          columns={customFieldColumnDefinition()}
          items={items ?? []}
          idProperty="id"
          headerClick={(header, itemsOrder) => setDataOrder(itemsOrder)}
          selectionChanged={(item) => setCurrentId(item.id)}
          itemsOrder={dataOrder}
          selectId={currentItem?.id}
          isAwaiting={isFetchingItem}
          captureKeyboard
        />
      </CrudItemsPanel>

      <Pagination
        containerClassName="custom-fields-list-pager"
        pagination={pagination}
        isFetching={isFetchingList}
        onChange={pageChange}
      />

      {isCreateModalOpen && (
        <CustomFieldModal
          type={currentType!}
          title={t("customFields.create.tooltip")}
          onCancel={() => setIsCreateModalOpen(false)}
          dto={undefined}
        />
      )}
    </div>
  );
};
