import { useState, useEffect, useContext } from "react";
import { KemaroRobotCleaningDataTotalsDto } from "../../../../../../robotcloud-shared/resource-models";
import { kemaroRobotTotals } from "../../../../data/kemaro/kemaroRobotDeployment.request";
import { ToastHelper } from "../../../../components/news";
import { ErrorHelper } from "../../../../uiHelpers/errors.helper";
import KemaroRobotContext from "../KemaroRobotContext";

const useRobotCleaningDataTotals = () => {
  const { currentItem } = useContext(KemaroRobotContext);
  const robotId = currentItem?.id;
  const [robotCleaningDataTotals, setRobotCleaningDataTotals] = useState<
    KemaroRobotCleaningDataTotalsDto[]
  >([]);
  const [errorRobotCleaningDataTotals, setErrorRobotCleaningDataTotals] =
    useState<boolean>(false);
  const [loadingRobotCleaningDataTotals, setLoadingRobotCleaningDataTotals] =
    useState<boolean>(false);

  useEffect(() => {
    if (robotId) {
      fetchDeployments(robotId);
    }
  }, [robotId]);

  const fetchDeployments = async (robotId: string) => {
    setLoadingRobotCleaningDataTotals(true);
    setErrorRobotCleaningDataTotals(false);
    try {
      const response = await kemaroRobotTotals(robotId);
      if (!response) {
        throw new Error("Network response was not ok");
      }
      setRobotCleaningDataTotals(response);
    } catch (err: any) {
      ToastHelper.errors(ErrorHelper.process(err));
      setErrorRobotCleaningDataTotals(true);
    } finally {
      setLoadingRobotCleaningDataTotals(false);
    }
  };

  return {
    robotCleaningDataTotals,
    errorRobotCleaningDataTotals,
    loadingRobotCleaningDataTotals,
  };
};

export default useRobotCleaningDataTotals;
