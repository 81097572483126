import { useContext } from "react";
import OnBoardingContext from "../OnBoardingContext";
import { WorkflowTranslatedDataPanel } from "./WorkflowTranslatedDataPanel";
import { WorkflowViewInformation } from "./WorkflowViewInformation";
import { WorkflowViewTitle } from "./WorkflowViewTitle";

export const WorkflowView = () => {
  const { currentSelectedItem } = useContext(OnBoardingContext);

  if (!currentSelectedItem) {
    return <></>;
  }

  return (
    <>
      <WorkflowViewTitle />
      <WorkflowViewInformation />
      <WorkflowTranslatedDataPanel />
    </>
  );
};
