import { useContext, useState, useEffect } from "react";
import {
  KemaroRobotCredentialsConfigDto,
  KemaroRobotCredentialsDto,
} from "../../../robotcloud-shared/resource-models";
import { KemaroRobotRequests } from "../data/kemaro/kemaroRobot.request";
import KemaroRobotContext from "../views/kemaro/robots/KemaroRobotContext";
import {
  CredentalContainer,
  defaultKemaroRobotCredentialsConfigDto,
} from "../views/kemaro/robots/Modals/LocalCredentialModal/models";
import { ToastHelper } from "../components/news";
import { ErrorHelper } from "../uiHelpers/errors.helper";

interface UseCredentialManagementReturn {
  credentialContainer: CredentalContainer | undefined;
  setCredentialContainer: (
    credentialContainer: CredentalContainer | undefined
  ) => void;
  setOriginalPassword: (password: string) => void;
  fetchCredentials: () => void;
  hasChanges: boolean;
}

export const useCredentialManagement = (
  role: string
): UseCredentialManagementReturn => {
  const { currentItem } = useContext(KemaroRobotContext);
  const [credentialContainer, setCredentialContainer] = useState<
    CredentalContainer | undefined
  >();
  const [originalPassword, setOriginalPassword] = useState<string>("");

  const fetchCredentials = async () => {
    if (!currentItem) return;

    try {
      const credentialsConfig: KemaroRobotCredentialsConfigDto[] =
        await KemaroRobotRequests.getLocalCredentialConfig();

      const credentials: KemaroRobotCredentialsDto[] =
        await KemaroRobotRequests.getLocalCredential(currentItem.id!);

      const credential = credentials.find((c) => c.role === role);
      const config = credentialsConfig.find((c) => c.role === role) ?? {
        ...defaultKemaroRobotCredentialsConfigDto,
      };

      if (credential) {
        setOriginalPassword(credential.password);
        setCredentialContainer({
          config: config,
          credential: credential,
          originalPassword: credential.password,
          isShowPassword: false,
        });
      }
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }
  };

  useEffect(() => {
    fetchCredentials();
  }, [currentItem]);

  const hasChanges =
    originalPassword !== credentialContainer?.credential.password;

  return {
    credentialContainer,
    setCredentialContainer,
    setOriginalPassword,
    fetchCredentials,
    hasChanges,
  };
};
