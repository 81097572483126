import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import { Theme } from "@mui/material";

export const CustomerIconDisplayer = ({
  isCustomer,
  isCurrentLocation,
  theme,
}: CustomerIconDisplayerProps) => {
  if (!isCustomer) return <></>;

  if (isCurrentLocation) {
    return (
      <CorporateFareIcon
        className="customers-icon me-2"
        color="primary"
        // style={{ verticalAlign: "text-top" }}
      />
    );
  } else {
    return (
      <CorporateFareIcon
        className="customers-icon me-2"
        style={{
          color: theme.palette.swarm?.general?.secondaryTitle,
          //   verticalAlign: "text-top",
        }}
      />
    );
  }
};

interface CustomerIconDisplayerProps {
  isCustomer: boolean;
  isCurrentLocation: boolean;
  theme: Theme;
}
