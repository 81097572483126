import { ReactElement } from "react";
import { IconButton, useTheme } from "@mui/material";
import { TooltipContainer, toolTipPlacement } from "./TooltipContainer";
import { SxProps, Theme } from "@mui/material/styles";
import { Link } from "react-router-dom";

export const TooltipButton = (props: tooltipButtonProps) => {
  return (
    <TooltipContainer
      text={
        props.disabled ? props.tooltipDisable ?? props.tooltip : props.tooltip
      }
      content={props.tooltipContent}
      className={props.className}
      placement={props.tooltipPlacement}
      enterDelay={1000}
      leaveDelay={200}
    >
      <>
        {props.type === "button" && <TooltipButtonButton {...props} />}
        {props.type === "link" && <TooltipButtonLink {...props} />}
      </>
    </TooltipContainer>
  );
};

interface tooltipButtonProps {
  tooltip?: string;
  tooltipDisable?: string;
  tooltipContent?: ReactElement;
  tooltipPlacement?: toolTipPlacement;

  onClick?: () => void;
  children: ReactElement | never[] | null;
  size?: "medium" | "small" | "large" | undefined;
  edge?: "start" | "end" | false | undefined;
  className?: string;
  disabled?: boolean;
  type?: TooltipButtonType;
  sx?: SxProps<Theme>;
}

TooltipButton.defaultProps = {
  disabled: false,
  type: "button",
};

type TooltipButtonType = "button" | "link";

const TooltipButtonButton = (props: tooltipButtonProps) => (
  <>
    <IconButton
      color="inherit"
      aria-label="filter"
      onClick={props.onClick}
      size={props.size}
      edge={props.edge}
      className={props.className}
      disabled={props.disabled}
      sx={props.sx}
    >
      {props.children}
    </IconButton>
    <span></span>
  </>
);

const TooltipButtonLink = (props: tooltipButtonProps) => {
  const theme = useTheme();
  return (
    <Link
      className={`${props.className}`}
      style={{ color: theme.palette.swarm?.navigateTo?.to }}
      to=""
      onClick={() => {
        if (props.onClick) props.onClick();
      }}
    >
      {props.children}
    </Link>
  );
};
