import { alpha, createTheme, darken } from "@mui/material/styles";
import { AppTheme } from "..";

// colors from variables.scss

const main_theme_color = "#c83771"; // earlybyte PINK
const main_theme_color_transparent = alpha(main_theme_color, 0.3);
const color_01 = alpha(main_theme_color, 0.1);
const color_13 = darken(main_theme_color, 0.3);
const current_row_background_color = alpha(main_theme_color, 0.15);

const line_color = "rgba(0, 0, 0, 0.12)";
const line_style = `1px solid ${line_color}`;
const light_grey = "rgba(0, 0, 0, 0.38)";

const menu_item_color = "rgba(0, 0, 0, 0.87)";
const menu_item_color_icon = "#686d73";
const menu_item_hover_background = "#f5f5f5";
const menu_item_current_background = color_01;
const menu_item_current_background_hover = alpha(main_theme_color, 0.15);

const button_color = "white";
const button_background = main_theme_color;
const button_color_hover = "white";
const button_background_hover = color_13;
const button_color_disabled = "white";
const button_background_disabled = "#a4a4a4";

const button_icon_primary_color = main_theme_color;
const button_icon_disabled_color = "#9f9f9f";
const button_icon_error_color = "rgb(211, 47, 47)";

const robot_model_border = `2px solid ${main_theme_color}`;
const highlight_color_robot = "#f16c24";
const highlight_color_serial_number = "#a36b62";
const highlight_color_name = "#934d4d";
const highlight_color_company = "#7b7b7b";
const highlight_color_model = "#2e48f5";
const highlight_color_customer = "#78b84b";
const highlight_color_dealer = "#e1a626";
const highlight_color_contact = "#0ec96f";
const highlight_color_manufacturer = "#9e41b8";
const highlight_color_mission = "#7a8a49";
const highlight_color_status = main_theme_color;
const highlight_color_country = "#10acb1";
const highlight_color_dashboard_calculation = "#54699f";
const highlight_color_text_search = "#4d4d4d";
const highlight_color_various = "#9246f5";
const highlight_color_error_codes = "#fb4141";
const highlight_color_tag = "#118ff3";
const highlight_color_stop_reason = "#232323";
const highlight_color_start_reason = "#5500c3";
const highlight_color_cleaning_mode = "#312c57";
const highlight_color_room_name = "#336939";
const highlight_color_zone_name = "#695433";

const earlybyte_pink = "#c83771";

const user_role_icon_color = "rgba(0, 0, 0, 0.2)";
const recent_bug = "#fca015";
const run_lines = "1px solid #d8d8d8";
const transparent = "rgba(255, 255, 255, 0.0)";
const background_transparent = "rgba(17, 143, 243, 0.08)";
const white = "#fff";
const black = "#000";
const gray = "#808080";
const red = "#ff0000";
const texts_grey = "rgba(0, 0, 0, 0.60)";

// Theme with palettes of standard and swarm color groups

const { palette } = createTheme();

export const theme: AppTheme = {
  palette: {
    mode: "light",

    swarm: {
      // generate <light>, <dark> and <contrastText> tokens for <swarm> color
      ...palette.augmentColor({ color: { main: "#ff0000" } }),
      name: "default",

      app: {
        header: white,
        headerBackground: "#282c34",
        link: "#61dafb",
        warningIcon: "rgba(255, 106, 0, 1.0)",
      },

      applicationBar: {
        container: white,
        containerBackground: main_theme_color,
        button: white,
        searchInput: white,
        searchInputContainerBackground: "rgba(255, 255, 255, 0.15)",
        searchInputBackground: transparent,
        warningBoder: white,
        warningBackground: background_transparent,
      },

      auth: {
        loginButton: white,
        loginButtonBackground: main_theme_color,
        loginButtonHover: white,
        loginButtonHoverBackground: "#2a6dd4",
        warning: color_13,
        warningBackground: "rgb(255, 244, 229)",
        warningBorder: "1px solid rgb(255, 152, 0)",
        warningIcon: "rgb(255, 152, 0)",
      },

      banner: {
        warningBorder: "2px solid #fcb414",
        warningBackground: "#fcb41426",
        warningIcon: "#ff6a00",
        infoBorder: "2px solid #0288d1",
        infoBackground: "#0288d126",
        infoIcon: "#047cbd",
        errorBorder: "2px solid #ff0000",
        errorBackground: "#ff000026",
        errorIcon: "#ff0000",
      },

      badgesPresenter: {
        buttonRemoveBackground: "rgba(0, 0, 0, 0.04)",
        roundedPill: main_theme_color,
        roundedPillBorder: main_theme_color,
        roundedPillHoverBackground: background_transparent,
        roundedPillRobot: highlight_color_robot,
        roundedPillRobotBorder: highlight_color_robot,
        roundedPillRobotHoverBackground: alpha(highlight_color_robot, 0.1),
        roundedPillSerial: highlight_color_serial_number,
        roundedPillSerialBorder: highlight_color_serial_number,
        roundedPillSerialHoverBackground: alpha(highlight_color_robot, 0.1),
        roundedPillName: highlight_color_name,
        roundedPillNameBorder: highlight_color_name,
        roundedPillNameHoverBackground: alpha(highlight_color_name, 0.1),
        roundedPillCompany: highlight_color_company,
        roundedPillCompanyBorder: highlight_color_company,
        roundedPillCompanyHoverBackground: alpha(highlight_color_company, 0.1),
        roundedPillModel: highlight_color_model,
        roundedPillModelBorder: highlight_color_model,
        roundedPillModelHoverBackground: alpha(highlight_color_model, 0.1),
        roundedPillCustomer: highlight_color_customer,
        roundedPillCustomerBorder: highlight_color_customer,
        roundedPillCustomerHoverBackground: alpha(
          highlight_color_customer,
          0.1
        ),
        roundedPillDealer: highlight_color_dealer,
        roundedPillDealerBorder: highlight_color_dealer,
        roundedPillDealerHoverBackground: alpha(highlight_color_dealer, 0.1),
        roundedPillContact: highlight_color_contact,
        roundedPillContactBorder: highlight_color_contact,
        roundedPillContactHoverBackground: alpha(highlight_color_contact, 0.1),
        roundedPillManufacturer: highlight_color_manufacturer,
        roundedPillManufacturerBorder: highlight_color_manufacturer,
        roundedPillManufacturerHoverBackground: alpha(
          highlight_color_manufacturer,
          0.1
        ),
        roundedPillMission: highlight_color_mission,
        roundedPillMissionBorder: highlight_color_mission,
        roundedPillMissionHoverBackground: alpha(highlight_color_mission, 0.1),
        roundedPillStatus: highlight_color_status,
        roundedPillStatusBorder: highlight_color_status,
        roundedPillStatusHoverBackground: alpha(highlight_color_status, 0.1),
        roundedPillCountry: highlight_color_country,
        roundedPillCountryBorder: highlight_color_country,
        roundedPillCountryHoverBackground: alpha(highlight_color_country, 0.1),
        roundedPillDashboard: highlight_color_dashboard_calculation,
        roundedPillDashboardBorder: highlight_color_dashboard_calculation,
        roundedPillDashboardHoverBackground: alpha(
          highlight_color_dashboard_calculation,
          0.1
        ),
        roundedPillVarious: highlight_color_various,
        roundedPillVariousBorder: highlight_color_various,
        roundedPillVariousHoverBackground: alpha(highlight_color_various, 0.1),
        roundedPillSearch: highlight_color_text_search,
        roundedPillSearchBorder: highlight_color_text_search,
        roundedPillSearchHoverBackground: alpha(
          highlight_color_text_search,
          0.1
        ),
        roundedPillError: highlight_color_error_codes,
        roundedPillErrorBorder: highlight_color_error_codes,
        roundedPillErrorHoverBackground: alpha(
          highlight_color_error_codes,
          0.1
        ),
        roundedPillTag: highlight_color_tag,
        roundedPillTagBorder: highlight_color_tag,
        roundedPillTagHoverBackground: alpha(highlight_color_tag, 0.1),

        roundedPillStopReason: highlight_color_stop_reason,
        roundedPillStopReasonBorder: highlight_color_stop_reason,
        roundedPillStopReasonHoverBackground: alpha(
          highlight_color_stop_reason,
          0.1
        ),

        roundedPillStartReason: highlight_color_start_reason,
        roundedPillStartReasonBorder: highlight_color_start_reason,
        roundedPillStartReasonHoverBackground: alpha(
          highlight_color_start_reason,
          0.1
        ),

        roundedPillCleaningMode: highlight_color_cleaning_mode,
        roundedPillCleaningModeBorder: highlight_color_cleaning_mode,
        roundedPillCleaningModeHoverBackground: alpha(
          highlight_color_cleaning_mode,
          0.1
        ),

        roundedPillRoomNames: highlight_color_room_name,
        roundedPillRoomNamesBorder: highlight_color_room_name,
        roundedPillRoomNamesHoverBackground: alpha(
          highlight_color_room_name,
          0.1
        ),

        roundedPillZoneNames: highlight_color_zone_name,
        roundedPillZoneNamesBorder: highlight_color_zone_name,
        roundedPillZoneNamesHoverBackground: alpha(
          highlight_color_zone_name,
          0.1
        ),
      },

      baseSearch: {
        searchExplantionIcon: "#c3c3c3",
        clearButtonIcon: "#c3c3c3",
      },

      bootstrap: {
        accordionItemNotFirstBorderTop: line_style,
        accordionButtonNotCollapsedBackground: transparent,
      },

      companyStructureView: {
        currentNodeIcon: main_theme_color,
        currentNodeInherited: light_grey,
        dataInherited: gray,
        modalDataInherited: gray,
      },

      customers: {
        bodyLeftSideBorderRight: line_style,
        bodyListPagerBorderTop: line_style,
      },

      customField: {
        bodyLeftSideBorderRight: line_style,
        bodyListPagerBorderTop: line_style,
        modalBodyBorderBottom: line_style,
      },

      dealers: {
        bodyLeftSideBorderRight: line_style,
        bodyListPagerBorderTop: line_style,
      },

      emailSend: {
        button: button_color,
        buttonBackground: button_background,
        hover: button_color_hover,
        hoverBackground: button_background_hover,
        disabled: button_color_disabled,
        disabledBackground: button_background_disabled,
      },

      fileWorkflow: {
        fileTitle: main_theme_color,
        fileTitleBackground: "rgba(25, 118, 210, 0.08)",
      },

      general: {
        pageFooterBorderTop: line_style,
        closeModal: main_theme_color, // !important
        closeModalHover: white, // !important
        closeModalHoverBackground: main_theme_color, // !important
        lightGreyButton: light_grey, // // !important
        lightGreyButtonHover: main_theme_color, // !important
        primaryText: main_theme_color,
        supRemark: red,
        userDisabled: light_grey,
        bannerYellowBackground: "#fcb41426",
        bannerYellowBorderLeft: "#fcb414",
        noResultsP: light_grey,
        autocompleteTemplateBackground: transparent,
        autocompleteTemplateDisable: button_icon_disabled_color,
        autocompleteTemplateHoverBackground: "#f5f5f5",
        autocompleteTemplateSelectBackground: "#edf4fb", // !important
        currentRowBacground: current_row_background_color,
        noBackground: transparent, // !important
        borderWhite1: white,
        columnTitlesBorderBottom: line_style,
        textDisabled: light_grey,
        iconButtonDisabled: button_icon_disabled_color,
        iconButtonPrimary: button_icon_primary_color,
        iconButtonError: button_icon_error_color,
        earlybytePinkColor: earlybyte_pink,
        secondaryTitle: texts_grey,
        sucessColor: "#46a05a",
        warningColor: "#fca015",
        errorColor: "#da0f34",
      },

      inputTextList: {
        bagde: main_theme_color,
        badgeBorder: `1px solid ${main_theme_color}`,
      },

      kemaroCostCalculation: {
        bodyLeftSideBorderRight: line_style,
        bodyListItemsStartTd: main_theme_color,
        bodyListItemsDefaultText: main_theme_color,
        bodyListPagerBorderTop: line_style,
      },

      kemaroDashboard: {
        economyPerformanceBorder: line_style,
        economyPerformanceBoxShadow: `0px 0px 5px ${line_color}`,
        economyStatisticsBorder: line_style,
        economyStatisticsBoxShadow: `0px 0px 5px ${line_color}`,
        economyCostssBorder: line_style,
        economyCostsBoxShadow: `0px 0px 5px ${line_color}`,
        noDataContainerBackground: line_color,
        noDataContainerNoData: "rgb(95, 33, 32)",
        noDataContainerNoDataBackground: white,
        noDataContainerNoDataBorder: "1px solid rgb(239, 83, 80)",
        noDataContainerNoDataIcon: "rgb(239, 83, 80)",
        robotsModalDotColor: main_theme_color,
        robotsModalDecommissioned: button_background_disabled,
      },

      kemaroMissions: {
        bodyLeftSideBorderRight: line_style,
        bodyLeftSideSuccess: "#46a05a",
        bodyLeftSideError: "#da0f34",
        bodyLeftSideInternal: light_grey,
        bodyListPagerBorderTop: line_style,
        viewSummaryBackground: background_transparent,
        viewSummaryNoMap: red,
        viewCustomerDealerBorderBottom: line_style,
        viewRunsAccordionWithoutBottomBackground: transparent,
        viewRunsAccordionWithoutBottomBorder: run_lines,
        viewRunsAccordionAccordionBackground: transparent,
        viewRunsAccordionAccordionBorder: run_lines,
        viewRunsAccordionDetailBackground: transparent,
        viewRunsAccordionDetailBorder: run_lines,
        viewRunsRunHeaderTable: "rgba(0, 0, 0, 0.87)",
        viewRunsRunDetailTable: "rgba(0, 0, 0, 0.87)",
        viewRunsRunSuccess: "#46a05a",
        viewRunsRunError: "#da0f34",
        sendReportBorderTop: line_style,
        sendReportActionButton: button_color,
        sendReportActionButtonBackground: button_background,
        sendReportActionButtonHover: button_color_hover,
        sendReportActionButtonBackgroundHover: button_background_hover,
        sendReportActionButtonDisabled: button_background_disabled,
        sendReportActionButtonBackgroundDisabled: button_background_disabled,
        missionError: "#5f2120",
        missionErrorBorder: "1px solid #ef5350", // same 01
        missionErrorIcon: "#ef5350", // same 01
        newBodyTitle: light_grey,
        newBodyErrorBackground: "rgba(255,0,0, 0.04)",
        newBodyUiErrorBackground: "rgba(255,0,0, 0.06)",
      },

      kemaroRobots: {
        leftSideBorderRight: line_style,
        listPagerBorderTop: line_style,
        companyStructureParents: black,
        companyStructureIcon: main_theme_color,
        childParameter: main_theme_color,
        robotModel: main_theme_color,
        robotModelBorder: robot_model_border,
        krciContainerBorder: line_color,
        cleanedAreaHistoryLink: menu_item_color,
        cleanedAreaHistoryLinkCurrent: main_theme_color,
        onThreshold: "#46a05a",
        outThreshold: light_grey,
        bugOld: light_grey,
        bugRecent: recent_bug,
        accessSettingsContainerBackground: background_transparent,
        systemDetailsContainerBackground: background_transparent,
        errorSummaryBodyRecentTitle: recent_bug,
        errorSummaryBodyOldTitle: light_grey,
        errorSummaryBodyErrorContainer: white,
        errorSummaryBodyErrorContainerNotLastChild: light_grey,
        modalWarningIcon: "rgb(255, 106, 0)",
        accessManagementModalBodyDividerBorderBottom: line_style,
      },

      kemaroRobotTagsList: {
        badge: main_theme_color,
        badgeBorder: `1px solid ${main_theme_color}`,
      },

      mainPanel: {
        borderRight: line_style,
        meBorderBottom: line_style,
        manufacturersBorderBottom: line_style,
        menuLiIcon: menu_item_color_icon,
        menuA: menu_item_color,
        menuLiHoverBackground: menu_item_hover_background,
        menuCurrentOptionBackground: menu_item_current_background,
        menuCurrentOptionA: main_theme_color,
        menuCurrentOptionLiIcon: main_theme_color,
        menuCurrentOptionHoverBackground: menu_item_current_background_hover,
        smallSpanIcon: menu_item_color_icon,
      },

      manufacturers: {
        bodyLeftSideBorderRight: line_style,
        bodyListPagerBorderTop: line_style,
      },

      maxbrain: {
        bodySuccess: "#46a05a",
        bodyError: "#da0f34",
        bodyLeftSideBorderRight: line_style,
        bodyListPagerBorderTop: line_style,
      },

      navigateTo: {
        to: main_theme_color,
        icon: main_theme_color,
        hover: main_theme_color,
      },

      onBoardingAssistant: {
        workflowsListBorderRight: line_style,
        workflowLabel: "rgba(0, 0, 0, 0.38)",
        workflowVisited: black,
        currentWorkflowLabel: main_theme_color,
        currentWorkflowLabelBackground: color_01,
      },

      permissionsMatrix: {
        containerThBorderLeft: "1px solid #ddd",
        containerTheadBackground: "#d8c2cc",
        filler: "rgba(1, 1, 1, 0)",
        sectionTitleBackground: "#f6cbde",
        rowLightBackground: "#fff",
        rowDarkBackground: "#eee",
        iconCheck: "rgb(1, 180, 1)",
      },

      profile: {
        bodySeparatorBorderBottom: line_style,
        passwordModalErrorMessage: red,
      },

      robotModels: {
        bodyLeftSideBorderRight: line_style,
        modalFloatButtonHoverBackground: "rgba(0,0,0, 0.25)",
      },

      robots: {
        connectionBackground: background_transparent,
      },

      search: {
        searchExplanation: "gray",
      },

      tagsDisplayer: {
        bagde: main_theme_color,
        badgeBackground: alpha(button_background, 0.15),
      },

      toast: {
        toastifyErrorBackground: "rgb(211, 47, 47)",
        toastifySuccessBackground: "rgb(46, 125, 50)",
        toastifyWarningBackground: "rgb(237, 108, 2)",
        toastifyInfoBackground: "rgb(2, 136, 209)",
      },

      treeView: {
        iconButtonBackground: transparent,
        nodeHeaderHoverBackground: "rgba(0, 0, 0, 0.03)",
        nodeHeaderSelectedBackground: alpha(main_theme_color, 0.1),
        acccordeonButton:
          "invert(25%) sepia(20%) saturate(7211%) hue-rotate(313deg) brightness(102%) contrast(80%)",
        //above property generated in https://codepen.io/sosuke/pen/Pjoqqp
      },

      userModals: {
        notificationConfigurationSendTest: main_theme_color,
        notificationConfigurationSendTestHoverBackground:
          main_theme_color_transparent,
      },

      userRoleIcon: user_role_icon_color,

      users: {
        bodyLeftSideBorderRight: line_style,
        bodyListPagerBorderTop: line_style,
      },

      // colors that were defined directly in *.tsx files

      loading: {
        puffLoader: main_theme_color,
      },

      kemaroDashboardAmortization: {
        background: "#00000004",
      },

      kemaroDashboardStatisticsChart: {
        responsiveLine: main_theme_color,
      },

      kemaroDashboardCostChartStack: {
        color0: "#300d1b",
        color1: "#701f3f",
        color2: "#a02c5a",
        color3: main_theme_color,
      },
    },

    primary: {
      main: main_theme_color,
    },

    secondary: {
      main: "#9c27b0",
      light: "#ba68c8",
      dark: "#7b1fa2",
      contrastText: "#fff",
    },

    success: {
      main: "#2e7d32",
      light: "#4caf50",
      dark: "#1b5e20",
      contrastText: "#fff",
    },

    info: {
      main: "#0288d1",
      light: "#ff9800",
      dark: "#e65100",
      contrastText: "#fff",
    },

    warning: {
      main: "#ed6c02",
      light: "#ff9800",
      dark: "#e65100",
      contrastText: "#fff",
    },

    error: {
      main: "#d32f2f",
      light: "#ef5350",
      dark: "#c62828",
      contrastText: "#fff",
    },

    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.6)",
      disabled: "rgba(0, 0, 0, 0.38)",
    },

    background: {
      default: white,
      paper: "#fff",
    },

    common: {
      black: "#000",
      white: "#fff",
    },

    action: {
      selected: "rgba(17, 143, 243, 0.08)",
      disabled: button_icon_disabled_color,
    },

    divider: line_color,
  },
  // typography moved from App.tsx
  typography: {
    h1: {
      fontSize: "24px",
    },
    h2: {
      fontSize: "20px",
      fontWeight: 600,
    },
    h3: {
      fontSize: "16px",
    },
  },
};
