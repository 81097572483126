import CloseIcon from "@mui/icons-material/Close";
import Edit from "@mui/icons-material/Edit";
import { NotificationConfigurationDto } from "../../../../../robotcloud-shared/resource-models";
import { TableGeneric } from "../../../components/news/TableGeneric/TableGeneric";
import { TitleWithAction } from "../../../components/news/TitleWithAction";
import { Switch } from "@mui/material";
import { UserNotificationConfigurationsModal } from "./UserNotificationConfigurationsModal";
import { useState } from "react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { YesNoOkDialog } from "../../../components/news/YesNoOkDialog";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import { TooltipButton } from "../../../components/news/TooltipButton";
import { TableGenericColumn } from "../../../components/news/TableGeneric/models";

export const UserNotificationConfigurations = (
  props: userNotificationConfigurationsProps
) => {
  const [modalTitle, setModalTitle] = useState("");
  const [notification, setNotification] =
    useState<NotificationConfigurationDto>({ ...notificationEmpty });
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isWorking, setIsWorking] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [testModalOpen, setTestModalOpen] = useState(false);
  const { t } = useTranslation();

  const handelSave = async (item: NotificationConfigurationDto) => {
    setIsWorking(true);
    if (await props.onSave(item, isNew)) {
      setIsOpenModal(false);
    }
    setIsWorking(false);
  };

  const handleEdit = (item: NotificationConfigurationDto) => {
    setIsNew(false);
    setModalTitle(t("userNotificationConfigurations.edit.title"));
    setNotification({ ...item });
    setIsOpenModal(true);
  };

  const handleDelete = (item: NotificationConfigurationDto) => {
    setNotification({ ...item });
    setDeleteModalOpen(true);
  };

  const handleNotificationConfigurationDelete = async () => {
    setIsWorking(true);

    if (await props.onDelete(notification)) {
      setDeleteModalOpen(false);
    }

    setIsWorking(false);
  };

  const handleTest = (item: NotificationConfigurationDto) => {
    setNotification({ ...item });
    setTestModalOpen(true);
  };

  const handleNotificationConfigurationTest = async () => {
    setIsWorking(true);

    await props.onTest(notification);

    setTestModalOpen(false);
    setIsWorking(false);
  };

  return (
    <>
      <div className="w-100">
        <TitleWithAction
          title={t("userNotificationConfigurations.title")}
          titleContainerClassName="child-sub-title"
          tooltip={t("userNotificationConfigurations.create.tooltip")}
          showButton={true}
          onClick={() => {
            setIsNew(true);
            setModalTitle(t("userNotificationConfigurations.create.title"));
            setNotification({ ...notificationEmpty });
            setIsOpenModal(true);
          }}
        />

        {!props.notifications || props.notifications.length === 0 ? (
          <></>
        ) : (
          <TableGeneric
            tableClassName="w-100"
            columns={columnsDefinition(handleEdit, handleDelete, handleTest)}
            items={props.notifications!}
            idProperty="emailAddress"
          ></TableGeneric>
        )}

        {isOpenModal && (
          <UserNotificationConfigurationsModal
            title={modalTitle}
            notification={notification}
            notifications={props.notifications}
            isNew={isNew}
            isOpenModal={isOpenModal}
            isWorking={isWorking}
            onSave={handelSave}
            onCancel={() => setIsOpenModal(false)}
          />
        )}

        <YesNoOkDialog
          open={deleteModalOpen}
          size="md"
          type="yesNo"
          title={t("userNotificationConfigurations.delete.title")}
          message={t("userNotificationConfigurations.delete.warning", {
            address: notification.emailAddress,
          })}
          onYes={() => handleNotificationConfigurationDelete()}
          onNo={() => setDeleteModalOpen(false)}
          isWorking={isWorking}
        />

        <YesNoOkDialog
          open={testModalOpen}
          size="md"
          type="yesNo"
          title={t("userNotificationConfigurations.test.title")}
          message={t("userNotificationConfigurations.test.warning", {
            address: notification.emailAddress,
          })}
          onYes={() => handleNotificationConfigurationTest()}
          onNo={() => setTestModalOpen(false)}
          isWorking={isWorking}
        />
      </div>
    </>
  );
};

export interface userNotificationConfigurationsProps {
  notifications: NotificationConfigurationDto[];
  onSave: (
    item: NotificationConfigurationDto,
    isNew: boolean
  ) => Promise<boolean>;
  onDelete: (item: NotificationConfigurationDto) => Promise<boolean>;
  onTest: (item: NotificationConfigurationDto) => Promise<void>;
}

const notificationEmpty: NotificationConfigurationDto = {
  autoSendComplete: false,
  autoSendFail: false,
  emailAddress: "",
};

const columnsDefinition = (
  editItem: (item: NotificationConfigurationDto) => void,
  deleteItem: (item: NotificationConfigurationDto) => void,
  testItem: (item: NotificationConfigurationDto) => void
) => {
  const columns: TableGenericColumn[] = [
    {
      title: i18next.t("userNotificationConfigurations.mailAddress").toString(),
      property: "emailAddress",
      headerClassName: "ps-0 w-100",
      itemClassName: "ps-0 w-100",
    },
    {
      title: i18next
        .t("userNotificationConfigurations.missionFailed")
        .toString(),
      property: "autoSendFail",
      canSort: false,
      cellTemplate: (item: NotificationConfigurationDto) => (
        <Switch disabled={true} color="primary" checked={item.autoSendFail} />
      ),
    },
    {
      title: i18next
        .t("userNotificationConfigurations.missionComplete")
        .toString(),
      property: "autoSendComplete",
      cellTemplate: (item: NotificationConfigurationDto) => (
        <Switch
          disabled={true}
          color="primary"
          checked={item.autoSendComplete}
        />
      ),
    },
    {
      title: i18next
        .t("userNotificationConfigurations.test.columnTitle")
        .toString(),
      cellTemplate: (item: NotificationConfigurationDto) => (
        <TooltipButton
          aria-label="edit"
          tooltip={i18next
            .t("userNotificationConfigurations.test.tooltip")
            .toString()}
          onClick={() => testItem(item)}
        >
          <MailOutlineOutlinedIcon color="primary" />
        </TooltipButton>
      ),
    },
    {
      headerClassName: "two-buttons-container",
      itemClassName: "two-buttons-container text-end",
      canSort: false,
      cellTemplate: (item: NotificationConfigurationDto) => (
        <>
          <TooltipButton
            aria-label="edit"
            tooltip={i18next
              .t("userNotificationConfigurations.edit.tooltip")
              .toString()}
            onClick={() => editItem(item)}
          >
            <Edit color="primary" />
          </TooltipButton>
          <TooltipButton
            aria-label="delete"
            tooltip={i18next
              .t("userNotificationConfigurations.delete.tooltip")
              .toString()}
            onClick={() => deleteItem(item)}
          >
            <CloseIcon color="error" />
          </TooltipButton>
        </>
      ),
    },
  ];

  return columns;
};
