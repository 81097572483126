import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { useEffect, useState } from "react";
import { Loading } from "../../components/Loading";
import { TextInput } from "../../components/news/TextInput";
import { ToastHelper } from "../../components/news/Toast.helper";
import { UsersRequests } from "../../data/users.requests";
import { ErrorHelper } from "../../uiHelpers/errors.helper";
import { GeneralFunctions } from "../generalFunctions";
import { useTranslation } from "react-i18next";
import { tableGenericHandleKeyboardSubjectManager } from "../../components/news";

export const ForgotPasswordModal = (props: forgotPasswordModalProps) => {
  const { t } = useTranslation();

  const [isFetching, setIsFetching] = useState(false);
  const [swarmUserName, setSwarmUserName] = useState("");

  useEffect(() => {
    tableGenericHandleKeyboardSubjectManager.setData({
      caller: "ForgotPasswordModal",
      handleKeyboard: props.openPopup,
    });
  }, [props.openPopup]);

  const resetHandle = async () => {
    setIsFetching(true);

    try {
      await UsersRequests.prepareResetPasswordBySwarmUsername(swarmUserName);
      ToastHelper.success(t("loginScreen.forgotPassword.messageEmail"));
      props.onCancel();
      setSwarmUserName("");
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsFetching(false);
  };

  return (
    <Dialog
      className="application-modal"
      open={props.openPopup}
      maxWidth="md"
      onClose={() => props.onCancel()}
    >
      <div className="modal-content">
        <div className="application-modal-header">
          <h3>{t("loginScreen.forgotPassword.title")}</h3>
        </div>

        <div className="application-modal-body">
          <div className="row">
            {isFetching ? (
              <Loading isLoading={isFetching} />
            ) : (
              <div className="col-12">
                <p>{t("loginScreen.forgotPassword.swarmUserName")}</p>
                <TextInput
                  placeholder="your_user_name@eb8.ch"
                  value={swarmUserName}
                  ret={(x) => setSwarmUserName(x)}
                />
                <p></p>
              </div>
            )}
          </div>
        </div>
        <div className="application-modal-footer">
          {!isFetching && (
            <>
              <Button
                variant="contained"
                className="button-with-text"
                color="primary"
                disabled={GeneralFunctions.isBlank(swarmUserName)}
                onClick={resetHandle}
              >
                {t("common.buttons.reset")}
              </Button>

              <Button
                className="modal-cancel-button button-with-text"
                variant="outlined"
                color="primary"
                onClick={() => props.onCancel()}
              >
                {t("common.buttons.cancel")}
              </Button>
            </>
          )}
        </div>
      </div>
    </Dialog>
  );
};

interface forgotPasswordModalProps {
  openPopup: boolean;
  onCancel: () => void;
}
