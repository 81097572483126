import PlaylistAdd from "@mui/icons-material/PlaylistAdd";
import { ReactElement } from "react";
import { Loading } from "../Loading";
import { TooltipButton } from "./TooltipButton";
import { NoItems } from "./NoItems";

export function CrudItemsPanel<T>(props: crudItemsPanelProps<T>) {
  return (
    <div
      className={props.containerClassName}
      onClick={() => {
        props.onClick && props.onClick();
      }}
    >
      <div className={props.titleContainerClassName}>
        {props.titleLeftSide}
        {props.title.length > 0 && <span className="pe-2">{props.title}</span>}
        {props.canCreate && (
          <TooltipButton
            onClick={() => props.onCreateClick()}
            tooltip={props.createToolTip ?? ""}
          >
            <PlaylistAdd color="primary" fontSize="medium" />
          </TooltipButton>
        )}
        {props.titleRightSide}
      </div>

      {props.isFetching ? (
        <Loading isLoading={props.isFetching} />
      ) : (
        <>
          {!props.items || props.items.length === 0 ? (
            <>{!props.hideNoItems && <NoItems />}</>
          ) : (
            props.children
          )}
        </>
      )}
    </div>
  );
}

interface crudItemsPanelProps<T> {
  containerClassName: string;
  title: string;
  titleContainerClassName?: string;
  titleLeftSide?: ReactElement | never[];
  titleRightSide?: ReactElement | never[];
  isFetching: boolean;
  addControl?: React.ReactNode;
  items: T[] | undefined;
  children: ReactElement | never[];
  canCreate: boolean;
  createToolTip?: string;
  hideNoItems?: boolean;
  onCreateClick: () => void;
  onClick?: () => void;
}

CrudItemsPanel.defaultProps = {
  titleContainerClassName: "header-line",
};
