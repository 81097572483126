import { KemaroMissionRunDto } from "../../../../../robotcloud-shared/resource-models";
import TimerOutlined from "@mui/icons-material/TimerOutlined";
import TabUnselectedOutlinedIcon from "@mui/icons-material/TabUnselectedOutlined";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import { GeneralFunctions } from "../../generalFunctions";
import useTheme from "@mui/material/styles/useTheme";

export const KemaroMissionViewRunSummary = (
  props: kemaroMissionViewRunSummaryProps
) => {
  const theme = useTheme();
  const hasCleanedArea = (props.run?.cleanedAreaM2 ?? 0) > 0;
  const hasCleaningPerformance = props.run.cleaningPerformance > 0;

  return (
    <table
      className="run-header-table w-100"
      style={{
        color: theme.palette.swarm?.kemaroMissions?.viewRunsRunHeaderTable,
      }}
    >
      <tbody>
        <tr>
          <td className="run-header-icon-cell">{props.icon}</td>
          <td className="ps-2 pt-1">
            {props.run.cleaningModeDisplayName}
            {props.run.zoneName && ` (${props.run.zoneName})`}
          </td>
          <td className="td-8 pe-2">
            {GeneralFunctions.durationToHoursMinutesString(
              GeneralFunctions.calculateDuration(
                props.run.timestampCleaningStart,
                props.run.timestampCleaningEnd
              )
            )}
          </td>
          <td className="run-header-icon-cell">
            <TimerOutlined className="run-header-icon" />
          </td>
          <td className="td-6 pe-2">
            {hasCleanedArea && (
              <>
                {props.run.cleanedAreaM2} m<sup>2</sup>
              </>
            )}
          </td>
          <td className="run-header-icon-cell">
            {hasCleanedArea && (
              <TabUnselectedOutlinedIcon className="run-header-icon" />
            )}
          </td>
          <td className="td-8 pe-2">
            {hasCleaningPerformance && (
              <>
                {props.run.cleaningPerformance} m<sup>2</sup>/h
              </>
            )}
          </td>
          <td className="run-header-icon-cell">
            {hasCleaningPerformance && (
              <AnalyticsOutlinedIcon className="run-header-icon" />
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

interface kemaroMissionViewRunSummaryProps {
  current: number;
  total: number;
  run: KemaroMissionRunDto;
  icon: any;
  status: string;
  hasErrors: boolean;
}
