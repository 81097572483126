import { useContext } from "react";
import { ManufacturerReducedDto } from "../../../../robotcloud-shared/resource-models";
import { Loading } from "../../components/Loading";
import AuthorizationContext from "../auth/authorizationContext";
import useTheme from "@mui/material/styles/useTheme";

export const MainPanelManufacturers = (props: mainPanelManufacturersProps) => {
  const { currentManufacturer } = useContext(AuthorizationContext);

  const theme = useTheme();

  return (
    <div
      className="main-panel-manufacturers py-2"
      style={{
        borderBottom: theme.palette.swarm?.mainPanel?.manufacturersBorderBottom,
      }}
    >
      {props.manufacturers && currentManufacturer ? (
        <select
          className="form-select"
          aria-label="Default select example"
          value={currentManufacturer?.id}
          onChange={(event) =>
            props.onManufacturerChange(
              props.manufacturers.find((x) => x.id === event.target.value)!
            )
          }
        >
          {props.manufacturers.map((x) => (
            <option key={x.id} value={x.id}>
              {x.name}
            </option>
          ))}
        </select>
      ) : (
        <Loading isLoading={true} size={30} />
      )}
    </div>
  );
};

export interface mainPanelManufacturersProps {
  manufacturers: ManufacturerReducedDto[];
  onManufacturerChange: (manufacturer: ManufacturerReducedDto) => void;
}
