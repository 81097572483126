import { Stack } from "@mui/material";
import {
  CustomerCompleteDto,
  UserListDto,
  UserReducedDto,
} from "../../../../robotcloud-shared/resource-models";
import { YesNoOkDialog } from "../../components/news/YesNoOkDialog";
import { useContext, useEffect, useState } from "react";
import { TitleWithAction } from "../../components/news/TitleWithAction";
import { TableGeneric } from "../../components/news/TableGeneric/TableGeneric";
import { OrderDto } from "../../data/common";
import { TableGenericHelper } from "../../components/news";
import { ToastHelper } from "../../components/news/Toast.helper";
import { ErrorHelper } from "../../uiHelpers/errors.helper";
import { UsersRequests } from "../../data/users.requests";
import { UsersCreateModal } from "../shared/users/UsersCreateModal";
import { UsersModifyModal } from "../shared/users/UsersModifyModal";
import {
  UsersColumnDefinition,
  UsersColumnDefinitionProps,
} from "../shared/users/UsersColumnDefinition";
import { PermissionsHelper } from "../../uiHelpers/permissionsHelper";
import AuthorizationContext from "../auth/authorizationContext";
import { StorageManagerService } from "../../data/storageManager.service";
import { ApplicationService } from "../../data/application.service";
import { useTranslation } from "react-i18next";
import { MaxbrainUserModal } from "../shared/maxbrainUserModal/MaxbrainUserModal";

export const CustomerViewUsers = (props: customerViewUsersProps) => {
  const { t } = useTranslation();

  const [userCreateModalOpen, setUserCreateModalOpen] = useState(false);
  const [userModifyModalOpen, setUserModifyModalOpen] = useState(false);
  const [userModalUser, setUserModalUser] = useState<UserListDto | undefined>(
    undefined
  );
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [dataOrder, setDataOrder] = useState<OrderDto>({
    orderColumns: "name",
    order: "Asc",
  });
  const [isWorking, setIsWorking] = useState(false);
  const [modalIsWorking, setModalIsWorking] = useState(false);
  const [canAdd, setCanAdd] = useState(false);
  const [canModify, setCanModify] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [canResetPassword, setCanResetPassword] = useState(false);
  const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] =
    useState(false);
  const [isOpenKemaroAcademyUserModal, setIsOpenKemaroAcademyUserModal] =
    useState(false);
  const {
    currentManufacturer,
    me,
    isKemaroAcademySection,
    isManufacturerAdmin,
  } = useContext(AuthorizationContext);

  useEffect(() => {
    setCanAdd(
      PermissionsHelper.allow("Customers", "Edit", "Users", "Add") &&
        props.canEdit
    );

    setCanModify(
      PermissionsHelper.allow("Customers", "Edit", "Users", "Edit") &&
        props.canEdit
    );
    setCanDelete(
      PermissionsHelper.allow("Customers", "Edit", "Users", "Delete") &&
        props.canEdit
    );
    setCanResetPassword(
      PermissionsHelper.allow("Customers", "Edit", "Users", "ResetPassword") &&
        props.canEdit
    );

    TableGenericHelper.localOrder(dataOrder, props.users);
  }, [props, dataOrder]);

  const handleDeleteUser = async (item: UserListDto) => {
    setDeleteModalOpen(false);
    setIsWorking(true);

    try {
      await UsersRequests.deleteCustomerUser(item.id);
      props.update();
      setIsWorking(false);
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
      setIsWorking(false);
    }
  };

  const searchModalUser = async (item: UserReducedDto) => {
    let result = false;

    try {
      setUserModalUser(await UsersRequests.getListItem(item.id));
      result = true;
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    return result;
  };

  const modifyUserSave = async (item: UserListDto) => {
    setModalIsWorking(true);

    try {
      await UsersRequests.modifyCustomerUser(userModalUser!, item);

      props.update();
      setUserModifyModalOpen(false);
      props.update();

      if (userModalUser!.id === me!.id) {
        StorageManagerService.setApiTokenContainer(
          await ApplicationService.getApiTokenContainer(currentManufacturer!.id)
        );
      }
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setModalIsWorking(false);
  };

  const handleResetPassword = async (item: UserListDto) => {
    setIsWorking(true);

    try {
      await UsersRequests.resetPassword(item.id, true);
      props.update();
      setIsWorking(false);
      ToastHelper.success(t("user.resetPassword.successMessage"));
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
      setIsWorking(false);
    }

    setIsResetPasswordModalOpen(false);
  };

  const usersColumnDefinition: UsersColumnDefinitionProps = {
    canResetPassword: canResetPassword,
    canModify: canModify,
    canDelete: canDelete,
    isKemaroAcademyEnable: isKemaroAcademySection && isManufacturerAdmin,
    setCurrentUser: searchModalUser,
    setPasswordResetModalOpen: setIsResetPasswordModalOpen,
    setModifyModalOpen: setUserModifyModalOpen,
    setDeleteModalOpen: setDeleteModalOpen,
    setKemaroAcademyModalOpen: setIsOpenKemaroAcademyUserModal,
    kemaroAcademyConfigurationTooltip: t("kemaroAcademy.configurationTooltip"),
  };

  return (
    <>
      <Stack className="w-100">
        <TitleWithAction
          title={t("user.entitiesName")}
          titleContainerClassName="child-sub-title"
          showButton={canAdd}
          tooltip={t("user.create.tooltip")}
          onClick={() => setUserCreateModalOpen(true)}
        />

        {props.users && props.users.length > 0 && (
          <TableGeneric
            tableClassName="w-100"
            columns={UsersColumnDefinition(usersColumnDefinition)}
            items={props.users!}
            idProperty="id"
            headerClick={(header, itemsOrder) =>
              TableGenericHelper.localOrder(
                itemsOrder,
                props.users!,
                setDataOrder
              )
            }
            itemsOrder={dataOrder}
          ></TableGeneric>
        )}

        <UsersCreateModal
          isOpenModal={userCreateModalOpen}
          accessType={"Customer"}
          accessId={props.item.id}
          onCancel={() => setUserCreateModalOpen(false)}
          onSaved={() => {
            setUserCreateModalOpen(false);
            props.update();
          }}
        />

        {userModalUser && (
          <>
            {userModifyModalOpen && (
              <UsersModifyModal
                user={userModalUser}
                isOpenModal={userModifyModalOpen}
                isWorking={modalIsWorking}
                onCancel={() => setUserModifyModalOpen(false)}
                onSaved={modifyUserSave}
              />
            )}

            <YesNoOkDialog
              open={isResetPasswordModalOpen}
              size="md"
              type="yesNo"
              title={t("user.resetPassword.title")}
              message={`${t("user.resetPassword.warningSend")} ${
                userModalUser?.userName
              }?`}
              yesText="Reset"
              noText="Cancel"
              onYes={() => handleResetPassword(userModalUser!)}
              onNo={() => setIsResetPasswordModalOpen(false)}
              isWorking={isWorking}
            />

            <YesNoOkDialog
              open={deleteModalOpen}
              size="md"
              type="yesNo"
              title={t("user.delete.title")}
              onYes={() => handleDeleteUser(userModalUser!)}
              onNo={() => setDeleteModalOpen(false)}
              isWorking={isWorking}
            >
              <div className="col-12 mt-4">
                {t("user.delete.warningHard1")}
                <strong className="px-1">
                  {t("user.delete.warningHard2")}
                </strong>
                {`${t("user.delete.warningHard3")} ${
                  userModalUser!.firstName
                } ${userModalUser!.lastName}?`}
              </div>
            </YesNoOkDialog>
          </>
        )}

        {isKemaroAcademySection &&
          isManufacturerAdmin &&
          userModalUser &&
          isOpenKemaroAcademyUserModal && (
            <MaxbrainUserModal
              isOpenModal={isOpenKemaroAcademyUserModal}
              userId={userModalUser.id}
              onCancel={() => setIsOpenKemaroAcademyUserModal(false)}
            />
          )}
      </Stack>
    </>
  );
};

export interface customerViewUsersProps {
  users: UserReducedDto[];
  item: CustomerCompleteDto;
  canEdit: boolean;
  update: () => void;
}
