import {
  PaginatedListDtoOfRobotModelListDto,
  RobotModelCompleteDto,
  RobotModelFilterCriteriaDto,
  RobotModelImageDto,
  RobotModelListDto,
  RobotModelPostDto,
  SearchTextDtoOfGuid,
  SearchTextResultDtoOfRobotModelListDto,
} from "../../../robotcloud-shared/resource-models";
import { AxiosRequests } from "./axios.requests";
import { BaseRequest } from "./BaseRequest";
import { diff, jsonPatchPathConverter } from "just-diff";
import { prepareSearchTextDto } from "./functions";

export class RobotModelRequest extends BaseRequest {
  static controller = "api/webapp/v1.0/organizations/RobotModel";

  static getImage = async (idRobotModel: string) => {
    const { data } = await AxiosRequests.get(
      `${RobotModelRequest.controller}/${idRobotModel}/image`
    );
    return data as RobotModelImageDto;
  };

  static getImageForce = async (idRobotModel: string) => {
    const { data } = await AxiosRequests.get(
      `${RobotModelRequest.controller}/${idRobotModel}/image-force`
    );
    return data as RobotModelImageDto;
  };

  static getSearchText = async (
    manufacturerId: string,
    searchTextDto: SearchTextDtoOfGuid
  ) => {
    if (!manufacturerId) {
      const result: SearchTextResultDtoOfRobotModelListDto = {
        items: [],
        searchedText: null,
        totalItems: 0,
      };

      return result;
    }

    const { data } = await AxiosRequests.post(
      `${RobotModelRequest.controller}/${manufacturerId}/search-text`,
      prepareSearchTextDto(searchTextDto)
    );
    return data as SearchTextResultDtoOfRobotModelListDto;
  };

  static getList = async (filterCriteria: RobotModelFilterCriteriaDto) => {
    const { data } = await AxiosRequests.get(
      `${RobotModelRequest.controller}?${RobotModelRequest.objectToQueryString(
        filterCriteria
      )}`
    );
    return data as PaginatedListDtoOfRobotModelListDto;
  };

  static getById = async (id: string) => {
    const { data } = await AxiosRequests.get(
      `${RobotModelRequest.controller}/${id}`
    );
    return data as RobotModelCompleteDto;
  };

  static create = async (dto: RobotModelPostDto) => {
    const { data } = await AxiosRequests.post(
      RobotModelRequest.controller,
      dto
    );
    return data as RobotModelListDto;
  };

  static modify = async (
    originalDto: RobotModelListDto,
    modifiedDto: RobotModelPostDto
  ) => {
    const allowProperties = ["/name", "/image", "/showImage"];

    const jsonPatch = diff(
      originalDto,
      modifiedDto,
      jsonPatchPathConverter
    ).filter((x) => allowProperties.includes(x.path));

    const { data } = await AxiosRequests.patch(
      `${RobotModelRequest.controller}/${originalDto.id}`,
      jsonPatch
    );
    return data as RobotModelListDto;
  };

  static delete = async (id: string) => {
    return await AxiosRequests.delete(`${RobotModelRequest.controller}/${id}`);
  };
}
