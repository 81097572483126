import { AxiosRequests } from "./axios.requests";

import {
  MeChangePasswordDto,
  NotificationConfigurationDto,
  PaginatedListDtoOfUserGridDto,
  PaginatedListDtoOfUserReducedDto,
  TestEmailDto,
  UserCompleteDto,
  UserDto,
  UserFilterCriteriaDto,
  UserListDto,
  UserMaxbrainConfigDto,
  UserMaxbrainInfoDto,
  UserMigrateAccountDto,
  UserPostDto,
  UserResetPasswordResultDto,
  UserRoles,
} from "../../../robotcloud-shared/resource-models";

import { diff, jsonPatchPathConverter } from "just-diff";
import { BaseRequest } from "./BaseRequest";

export class UsersRequests extends BaseRequest {
  static meEndPoint = "api/webapp/v1.0/Users/me";
  static controller = "api/webapp/v1.0/organizations/Users";
  static graphController = "api/webapp/v1.0/graphUsers";

  static create = async (postDto: UserPostDto) => {
    const { data } = await AxiosRequests.post(
      `${UsersRequests.controller}`,
      postDto
    );
    return data as UserCompleteDto;
  };

  static modifyList = async (original: UserListDto, modified: UserListDto) => {
    const allowProperties = [
      "/firstName",
      "/lastName",
      "/enabled",
      "/accessId",
      "/accessType",
      "/role",
    ];

    const jsonPatch = diff(original, modified, jsonPatchPathConverter).filter(
      (x) => allowProperties.includes(x.path)
    );

    const { data } = await AxiosRequests.patch(
      `${UsersRequests.controller}/${original.id}`,
      jsonPatch
    );
    return data as UserDto;
  };

  static getFromManufacturer = async (manufacturerId: string) => {
    const filter: UserFilterCriteriaDto = {
      accessTypes: ["Manufacturer"],
      tenants: [manufacturerId],
      textToSearch: null,
      page: 1,
      ids: null,
      roles: null,
      maxItemCount: 1000,
      orderBy: "Asc",
      orderColumns: null,
    };

    return UsersRequests.getList(filter);
  };

  static getList = async (filterCriteria: UserFilterCriteriaDto) => {
    const { data } = await AxiosRequests.get(
      `${UsersRequests.controller}?${UsersRequests.objectToQueryString(
        filterCriteria
      )}`
    );
    return (data as PaginatedListDtoOfUserReducedDto).items;
  };

  static deleteManufacturerUser = async (id: string) => {
    const { data } = await AxiosRequests.delete(
      `${UsersRequests.controller}/manufacturer/${id}`
    );
    return data as UserDto;
  };

  static modifyManufacturerUser = async (
    original: UserListDto,
    modified: UserListDto
  ) => {
    const allowProperties = ["/firstName", "/lastName", "/enabled", "/role"];

    const jsonPatch = diff(original, modified, jsonPatchPathConverter).filter(
      (x) => allowProperties.includes(x.path)
    );

    const { data } = await AxiosRequests.patch(
      `${UsersRequests.controller}/manufacturer/${original.id}`,
      jsonPatch
    );
    return data as UserDto;
  };

  static deleteDealerUser = async (id: string) => {
    const { data } = await AxiosRequests.delete(
      `${UsersRequests.controller}/dealer/${id}`
    );
    return data as UserDto;
  };

  static modifyDealerUser = async (
    original: UserListDto,
    modified: UserListDto
  ) => {
    const allowProperties = ["/firstName", "/lastName", "/enabled", "/role"];

    const jsonPatch = diff(original, modified, jsonPatchPathConverter).filter(
      (x) => allowProperties.includes(x.path)
    );

    const { data } = await AxiosRequests.patch(
      `${UsersRequests.controller}/dealer/${original.id}`,
      jsonPatch
    );
    return data as UserDto;
  };

  static deleteCustomerUser = async (id: string) => {
    const { data } = await AxiosRequests.delete(
      `${UsersRequests.controller}/customer/${id}`
    );
    return data as UserDto;
  };

  static modifyCustomerUser = async (
    original: UserListDto,
    modified: UserListDto
  ) => {
    const allowProperties = ["/firstName", "/lastName", "/enabled", "/role"];

    const jsonPatch = diff(original, modified, jsonPatchPathConverter).filter(
      (x) => allowProperties.includes(x.path)
    );

    const { data } = await AxiosRequests.patch(
      `${UsersRequests.controller}/customer/${original.id}`,
      jsonPatch
    );
    return data as UserDto;
  };

  static modifyComplete = async (
    original: UserCompleteDto,
    modified: UserCompleteDto
  ) => {
    const allowProperties = [
      "/firstName",
      "/lastName",
      "/enabled",
      "/accessId",
      "/accessType",
      "/role",
      "/language",
      "/windowsTimeZoneId",
      "/passwordResetEmailAddress",
      "/externalId",
    ];

    const jsonPatch = diff(original, modified, jsonPatchPathConverter).filter(
      (x) => allowProperties.includes(x.path)
    );

    const { data } = await AxiosRequests.patch(
      `${UsersRequests.controller}/${original.id}`,
      jsonPatch
    );
    return data as UserDto;
  };

  static delete = async (id: string) => {
    return await AxiosRequests.delete(`${UsersRequests.controller}/${id}`);
  };

  static resetPassword = async (id: string, sendEmail: boolean) => {
    const { data } = await AxiosRequests.post(
      `${UsersRequests.controller}/reset-password/${id}/${sendEmail}`
    );
    return data as UserResetPasswordResultDto;
  };

  static prepareResetPasswordBySwarmUsername = async (
    swarmUserName: string
  ) => {
    await AxiosRequests.postAnonymous(
      `${UsersRequests.controller}/prepare-reset-password-by-swarm-user-name/${swarmUserName}`
    );
  };

  static resetPasswordByAlternativeKey = async (alternativeKey: string) => {
    await AxiosRequests.postAnonymous(
      `${UsersRequests.controller}/reset-password-by-alternative-key/${alternativeKey}`
    );
  };

  static resetPasswordByAlternativeKeyCancel = async (
    alternativeKey: string
  ) => {
    await AxiosRequests.postAnonymous(
      `${UsersRequests.controller}/reset-password-by-alternative-key/${alternativeKey}/cancel`
    );
  };

  static resetPasswordByAlternativeKeyValidate = async (
    alternativeKey: string
  ) => {
    const { data } = await AxiosRequests.postAnonymous(
      `${UsersRequests.controller}/reset-password-by-alternative-key/${alternativeKey}/validate`
    );
    return data as boolean;
  };

  static putNotificationConfiguration = async (
    user: UserCompleteDto,
    items: NotificationConfigurationDto[]
  ) => {
    await AxiosRequests.put(
      `${UsersRequests.controller}/notification-configuration/${user.id}`,
      items
    );
  };

  static postSendEmailTest = async (test: TestEmailDto) => {
    await AxiosRequests.post(
      `${UsersRequests.controller}/send-test-email`,
      test
    );
  };

  static putMeNotificationConfiguration = async (
    items: NotificationConfigurationDto[]
  ) => {
    await AxiosRequests.put(
      `${UsersRequests.controller}/me/notification-configuration`,
      items
    );
  };

  static migrateAccount = async (
    userMigrateAccountDto: UserMigrateAccountDto
  ) => {
    const { data } = await AxiosRequests.put(
      `${UsersRequests.controller}/me/migrate-from`,
      userMigrateAccountDto
    );
    return data;
  };

  static modifyMe = async (
    original: UserCompleteDto,
    modified: UserCompleteDto
  ) => {
    const allowProperties = [
      "/firstName",
      "/lastName",
      "/language",
      "/windowsTimeZoneId",
      "/passwordResetEmailAddress",
      "/defaultPage",
      "/language",
      "/maxbrainConfig/language",
      "/maxbrainConfig/group",
    ];

    const jsonPatch = diff(original, modified, jsonPatchPathConverter).filter(
      (x) => allowProperties.includes(x.path)
    );

    const { data } = await AxiosRequests.patch(
      `${UsersRequests.controller}/me`,
      jsonPatch
    );
    return data as UserDto;
  };

  static modifyCompleteMe = async (
    original: UserCompleteDto,
    modified: UserCompleteDto
  ) => {
    const allowProperties = ["/firstName", "/lastName", "/windowsTimeZoneId"];

    const jsonPatch = diff(original, modified, jsonPatchPathConverter).filter(
      (x) => allowProperties.includes(x.path)
    );

    const { data } = await AxiosRequests.patch(
      `${UsersRequests.controller}/complete-me`,
      jsonPatch
    );
    return data as UserDto;
  };

  static getGrid = async (filterCriteria: UserFilterCriteriaDto) => {
    const { data } = await AxiosRequests.get(
      `${UsersRequests.controller}/grid?${UsersRequests.objectToQueryString(
        filterCriteria
      )}`
    );
    return data as PaginatedListDtoOfUserGridDto;
  };

  static getFromDealer = async (
    dealerId: string,
    roles: UserRoles[] | null = null
  ) => {
    const filter: UserFilterCriteriaDto = {
      accessTypes: ["Dealer"],
      tenants: [dealerId],
      textToSearch: null,
      ids: null,
      roles,
      page: 1,
      maxItemCount: 1000,
      orderBy: "Asc",
      orderColumns: null,
    };

    return UsersRequests.getList(filter);
  };

  static getFromCustomer = async (
    customerId: string,
    roles: UserRoles[] | null = null
  ) => {
    const filter: UserFilterCriteriaDto = {
      accessTypes: ["Customer"],
      tenants: [customerId],
      textToSearch: null,
      roles,
      page: 1,
      maxItemCount: 1000,
      ids: null,
      orderBy: "Asc",
      orderColumns: null,
    };

    return UsersRequests.getList(filter);
  };

  static getListItem = async (id: string) => {
    const { data } = await AxiosRequests.get(
      `${UsersRequests.controller}/${id}/list`
    );
    return data as UserListDto;
  };

  static getById = async (id: string) => {
    const { data } = await AxiosRequests.get(
      `${UsersRequests.controller}/${id}`
    );
    return data as UserCompleteDto;
  };

  static putResetPassword = async (resetPassword: MeChangePasswordDto) => {
    const { data } = await AxiosRequests.putWithAzureToken(
      `${UsersRequests.controller}/me/reset-password`,
      resetPassword
    );
    return data as UserCompleteDto;
  };

  static getMeNotificationConfigurations = async () => {
    const { data } = await AxiosRequests.get(
      `${UsersRequests.controller}/me/notification-configurations`
    );
    return data as NotificationConfigurationDto[];
  };

  static postGraphToSql = async (graphUserId: string) => {
    const { data } = await AxiosRequests.post(
      `${UsersRequests.graphController}/${graphUserId}/create-sql`
    );
    return data as UserDto;
  };

  static getUserMaxbrainInfo = async (id: string) => {
    const { data } = await AxiosRequests.get(
      `${UsersRequests.controller}/${id}/kemaro-academy`
    );
    return data as UserMaxbrainInfoDto;
  };

  static postUserMaxbrainInfo = async (
    id: string,
    userMaxbrainConfig: UserMaxbrainConfigDto
  ) => {
    const { data } = await AxiosRequests.post(
      `${UsersRequests.controller}/${id}/kemaro-academy`,
      userMaxbrainConfig
    );
    return data as UserMaxbrainInfoDto;
  };

  static putUserMaxbrainInfo = async (
    id: string,
    userMaxbrainConfig: UserMaxbrainConfigDto
  ) => {
    const { data } = await AxiosRequests.put(
      `${UsersRequests.controller}/${id}/kemaro-academy`,
      userMaxbrainConfig
    );
    return data as UserMaxbrainInfoDto;
  };
}
