import { Stack } from "@mui/material";
import {
  CustomerCompleteDto,
  CustomerContactReducedDto,
} from "../../../../robotcloud-shared/resource-models";
import { YesNoOkDialog } from "../../components/news/YesNoOkDialog";
import { useEffect, useState } from "react";
import { TitleWithAction } from "../../components/news/TitleWithAction";
import { TableGeneric } from "../../components/news/TableGeneric/TableGeneric";
import { OrderDto } from "../../data/common";
import { ContactsModal } from "../shared/contacts/ContactsModal";
import { ToastHelper } from "../../components/news/Toast.helper";
import { ErrorHelper } from "../../uiHelpers/errors.helper";
import { ContactColumnDefinition } from "../shared/contacts/ContactColumnDefinition";
import { CustomersContactsRequests } from "../../data/customersContacts.request";
import { PermissionsHelper } from "../../uiHelpers/permissionsHelper";
import { KemaroCompanyStructureRequests } from "../../data/kemaro/KemaroCompanyStructure.request";
import { CustomerViewContactsInCompanyStrcutureModal } from "./CustomerViewContactsInCompanyStrcutureModal";
import { useTranslation } from "react-i18next";
import { Banner, TableGenericHelper } from "../../components/news";
import { LocationsDisplayer } from "../shared/locationsDisplayer";

export const CustomerViewContacts = (props: customerViewContactsProps) => {
  const { t } = useTranslation();

  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [contactModalTitle, setContactModalTitle] = useState("");
  const [contactModalContact, setContactModalContact] = useState<
    CustomerContactReducedDto | undefined
  >(undefined);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteModalItem, setDeleteModalItem] = useState<
    CustomerContactReducedDto | undefined
  >(undefined);
  const [dataOrder, setDataOrder] = useState<OrderDto>({
    orderColumns: "completeName",
    order: "Asc",
  });
  const [isWorking, setIsWorking] = useState(false);
  const [canAdd, setCanAdd] = useState(false);
  const [canModify, setCanModify] = useState(false);
  const [canDelete, setCanDelete] = useState(false);

  const [modalUseInNodeOpen, setModalUseInNodeOpen] = useState(false);
  const [modalUseInNodeRoutes, setModalUseInNodeRoutes] = useState<
    string[][] | undefined
  >([]);
  const [modalUseInNodeContact, setModalUseInNodeContact] = useState<
    CustomerContactReducedDto | undefined
  >(undefined);

  useEffect(() => {
    setCanAdd(
      PermissionsHelper.allow("Customers", "Edit", "Contacts", "Add") &&
        props.canEdit
    );
    setCanModify(
      PermissionsHelper.allow("Customers", "Edit", "Contacts", "Edit") &&
        props.canEdit
    );
    setCanDelete(
      PermissionsHelper.allow("Customers", "Edit", "Contacts", "Delete") &&
        props.canEdit
    );

    TableGenericHelper.localOrder(dataOrder, props.contacts!);
  }, [props, dataOrder]);

  const handleContactSave = async (item: CustomerContactReducedDto) => {
    let result = false;

    try {
      if (contactModalContact) {
        await CustomersContactsRequests.modify(contactModalContact, item);
      } else {
        await CustomersContactsRequests.create({
          ...item,
          idCustomer: props.item.id,
        });
      }

      setContactModalOpen(false);
      result = true;
      props.update();
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    return result;
  };

  const handleContactDelete = async (item: CustomerContactReducedDto) => {
    setDeleteModalOpen(false);
    setIsWorking(true);

    try {
      await CustomersContactsRequests.delete(item.id);
      props.update();
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsWorking(false);
  };

  const handleInCompanyStructure = async (item: CustomerContactReducedDto) => {
    try {
      const nodeList =
        await KemaroCompanyStructureRequests.getFromCustomerContact(
          props.item.id,
          item
        );
      if (nodeList.length > 0) {
        setModalUseInNodeRoutes(nodeList);
        setModalUseInNodeContact(item);
        setModalUseInNodeOpen(true);
      }
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }
  };

  const handleDeleteClick = async (item: CustomerContactReducedDto) => {
    setDeleteModalItem(item);
    setDeleteModalOpen(true);

    setIsWorking(true);

    try {
      const nodeList =
        await KemaroCompanyStructureRequests.getFromCustomerContact(
          props.item.id,
          item
        );

      setModalUseInNodeRoutes(nodeList.length > 0 ? nodeList : undefined);
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsWorking(false);
  };

  return (
    <>
      <Stack className="w-100">
        <TitleWithAction
          title={t("contact.listTitle")}
          titleContainerClassName="child-sub-title"
          tooltip={t("contact.create.tooltip")}
          showButton={canAdd}
          onClick={() => {
            setContactModalTitle(t("contact.create.title"));
            setContactModalContact(undefined);
            setContactModalOpen(true);
          }}
        />

        {props.contacts.length > 0 && (
          <TableGeneric
            tableClassName="w-100"
            columns={ContactColumnDefinition(
              canModify,
              canDelete,
              setContactModalTitle,
              setContactModalContact,
              setContactModalOpen,
              handleDeleteClick,
              props.contacts,
              handleInCompanyStructure
            )}
            items={props.contacts!}
            idProperty="id"
            headerClick={(header, itemsOrder) =>
              TableGenericHelper.localOrder(
                itemsOrder,
                props.contacts!,
                setDataOrder
              )
            }
            itemsOrder={dataOrder}
          ></TableGeneric>
        )}

        {contactModalOpen && (
          <ContactsModal
            title={contactModalTitle}
            isOpenModal={contactModalOpen}
            contact={contactModalContact}
            onCancel={() => setContactModalOpen(false)}
            onSave={(contact) => handleContactSave(contact)}
          />
        )}

        <YesNoOkDialog
          open={deleteModalOpen}
          size="md"
          type="yesNo"
          title={t("contact.delete.title")}
          onYes={() => handleContactDelete(deleteModalItem!)}
          onNo={() => setDeleteModalOpen(false)}
          isWorking={isWorking}
        >
          <>
            {!isWorking && (
              <>
                {!modalUseInNodeRoutes && (
                  <p className="mb-3 mt-2">
                    {t("contact.delete.warning")}{" "}
                    {deleteModalItem?.completeName}?
                  </p>
                )}

                {modalUseInNodeRoutes && (
                  <Banner type={"warning"}>
                    <>
                      <p className="mb-3">{t("contact.delete.inLocations")}</p>
                      <LocationsDisplayer locations={modalUseInNodeRoutes} />
                      <p className="mt-3">
                        {t("contact.delete.warningInLocations")}
                      </p>
                    </>
                  </Banner>
                )}
              </>
            )}
          </>
        </YesNoOkDialog>

        {modalUseInNodeOpen && (
          <CustomerViewContactsInCompanyStrcutureModal
            key={`CustomerViewContactsInCompanyStrcutureModal_${Math.random()}`}
            contact={modalUseInNodeContact}
            routes={modalUseInNodeRoutes}
            openPopup={modalUseInNodeOpen}
            onCancel={() => setModalUseInNodeOpen(false)}
          />
        )}
      </Stack>
    </>
  );
};

export interface customerViewContactsProps {
  contacts: CustomerContactReducedDto[];
  item: CustomerCompleteDto;
  canEdit: boolean;
  update: () => void;
}
