import { Button, Dialog, Radio } from "@mui/material";
import { Stack } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import {
  CompanyStructureReducedDto,
  CustomerContactDto,
  CustomerDto,
  DealerDto,
  KemaroConfigurationDto,
  KemaroDashboardEconomyCostCalculationReducedDto,
  RobotModelReducedDto,
} from "../../../../../robotcloud-shared/resource-models";
import { Multiselect } from "../../../components/news/Multiselect";
import { CompanyStructureSearch } from "../../../components/search/CompanyStructureSearch";
import { CustomerContactSearch } from "../../../components/search/CustomerContactSearch";
import { CustomerSearch } from "../../../components/search/CustomerSearch";
import { DealerSearch } from "../../../components/search/DealerSearch";
import { KemaroDashboardEconomyCostCalculationSearch } from "../../../components/search/kemaro/KemaroDashboardEconomyCostCalculationSearch";
import { KemaroRobotNameSearch } from "../../../components/search/kemaro/KemaroRobotNameSearch";
import { KemaroRobotSerialNumberSearch } from "../../../components/search/kemaro/KemaroRobotSerialNumberSearch";
import { RobotModelSearch } from "../../../components/search/RobotModelSearch";
import AuthorizationContext from "../../auth/authorizationContext";
import { BadgeHelper } from "../../badgeHelper";
import { GeneralFunctions } from "../../generalFunctions";
import { useTranslation } from "react-i18next";
import { tableGenericHandleKeyboardSubjectManager } from "../../../components/news";
import { PermissionsHelper } from "../../../uiHelpers/permissionsHelper";
import { kemaroTagsRegExp, kemaroTagsRegExpInvalid } from "../KemaroContants";
import { KemaroRobotTagsList } from "../../../components/search/kemaro/KemaroRobotTagsList";
import {
  kemaroRobotsFilter,
  kemaroRobotsFilterDefault,
} from "./Models/kemaroRobotsFilter";

export const KemaroRobotsFilter = (props: kemaroRobotsFilterProps) => {
  const { t } = useTranslation();

  const [filter, setFilter] = useState<kemaroRobotsFilter>({
    ...kemaroRobotsFilterDefault,
  });

  const [isShowErrorSummary, setIsShowErrorSummary] = useState(false);
  const [isShowTag, setIsShowTag] = useState(false);

  const { me, configuration } = useContext(AuthorizationContext);
  const kemaroConfiguration = configuration as KemaroConfigurationDto;

  useEffect(() => {
    setIsShowErrorSummary(
      !!me &&
        (me.userAccessType === "Earlybyte" ||
          me.userAccessType === "Manufacturer")
    );
    setIsShowTag(PermissionsHelper.allow("KemaroRobots", "View", "Tags"));
  }, [me]);

  useEffect(() => {
    setFilter(props.filter);
  }, [props.filter]);

  useEffect(() => {
    tableGenericHandleKeyboardSubjectManager.setData({
      caller: "KemaroRobotsFilter",
      handleKeyboard: props.open,
    });
  }, [props.open]);

  const cancelHandle = () => {
    setFilter(props.filter);
    props.cancel();
  };

  return (
    <Dialog
      className="application-modal"
      open={props.open}
      onClose={cancelHandle}
      maxWidth={"md"}
    >
      <div className="modal-content">
        <Stack>
          <div className="application-modal-header">
            <h3 className="modal-title">{t("robot.filter.title")}</h3>
          </div>
          <div className="application-modal-body">
            <div className="container">
              <div className="row">
                <div className="mb-3 col-3 input-label">
                  {t("robot.filter.serialNumbers")}
                </div>

                <div className="mb-3 col-9">
                  <Multiselect
                    items={BadgeHelper.serialNumbersToBadgeItem(
                      filter.serialNumbers
                    )}
                    itemRemove={(x) =>
                      setFilter({
                        ...filter,
                        serialNumbers: GeneralFunctions.arrayStringRemove(
                          filter.serialNumbers,
                          x.source as string
                        ),
                      })
                    }
                  >
                    <KemaroRobotSerialNumberSearch
                      clearAfterSelect
                      blackList={filter.serialNumbers}
                      selectionChange={(x) =>
                        setFilter({
                          ...filter,
                          serialNumbers: GeneralFunctions.arrayStringAdd(
                            filter.serialNumbers,
                            x
                          ),
                        })
                      }
                    />
                  </Multiselect>
                </div>

                <div className="mb-3 col-3 input-label">
                  {t("robot.filter.names")}
                </div>

                <div className="mb-3 col-9">
                  <Multiselect
                    items={BadgeHelper.namesToBadgeItem(filter.names)}
                    itemRemove={(x) =>
                      setFilter({
                        ...filter,
                        names: GeneralFunctions.arrayStringRemove(
                          filter.names,
                          x.source as string
                        ),
                      })
                    }
                  >
                    <KemaroRobotNameSearch
                      clearAfterSelect
                      blackList={filter.names}
                      selectionChange={(x) =>
                        setFilter({
                          ...filter,
                          names: GeneralFunctions.arrayStringAdd(
                            filter.names,
                            x
                          ),
                        })
                      }
                    />
                  </Multiselect>
                </div>

                <div className="mb-3 col-3 input-label">
                  {t("robot.filter.models")}
                </div>

                <div className="mb-3 col-9">
                  <Multiselect
                    items={BadgeHelper.robotModelsToBadgeItem(
                      filter.robotModels
                    )}
                    itemRemove={(x) =>
                      setFilter({
                        ...filter,
                        robotModels: GeneralFunctions.arrayRemove(
                          filter.robotModels,
                          x.source as RobotModelReducedDto
                        ),
                      })
                    }
                  >
                    <RobotModelSearch
                      manufacturerId={me?.currentManufacturerId ?? ""}
                      clearAfterSelect
                      blackList={filter.robotModels.map((x) => x.id)}
                      selectionChange={(x) =>
                        setFilter({
                          ...filter,
                          robotModels: GeneralFunctions.arrayAdd(
                            filter.robotModels,
                            x
                          ),
                        })
                      }
                    />
                  </Multiselect>
                </div>

                <div className="mb-3 col-3 input-label">
                  {t("robot.filter.dealers")}
                </div>

                <div className="mb-3 col-9">
                  <Multiselect
                    items={BadgeHelper.dealersToBadgeItem(filter.dealers)}
                    itemRemove={(x) =>
                      setFilter({
                        ...filter,
                        dealers: GeneralFunctions.arrayRemove(
                          filter.dealers,
                          x.source as DealerDto
                        ),
                      })
                    }
                  >
                    <DealerSearch
                      clearAfterSelect
                      blackList={filter.dealers.map((x) => x.id)}
                      selectionChange={(x) =>
                        setFilter({
                          ...filter,
                          dealers: GeneralFunctions.arrayAdd(filter.dealers, x),
                        })
                      }
                    />
                  </Multiselect>
                </div>

                <div className="mb-3 col-3 input-label">
                  {t("robot.filter.customers")}
                </div>

                <div className="mb-3 col-9">
                  <Multiselect
                    items={BadgeHelper.customersToBadgeItem(filter.customers)}
                    itemRemove={(x) =>
                      setFilter({
                        ...filter,
                        customers: GeneralFunctions.arrayRemove(
                          filter.customers,
                          x.source as CustomerDto
                        ),
                      })
                    }
                  >
                    <CustomerSearch
                      clearAfterSelect
                      blackList={filter.customers.map((x) => x.id)}
                      selectionChange={(x) =>
                        setFilter({
                          ...filter,
                          customers: GeneralFunctions.arrayAdd(
                            filter.customers,
                            x
                          ),
                        })
                      }
                    />
                  </Multiselect>
                </div>

                <div className="mb-3 col-3 input-label">
                  {t("robot.filter.companyStructure")}
                </div>

                <div className="mb-3 col-9">
                  <Multiselect
                    items={BadgeHelper.companyStructuresToBadgeItem(
                      filter.companyStructures
                    )}
                    itemRemove={(x) =>
                      setFilter({
                        ...filter,
                        companyStructures: GeneralFunctions.arrayRemove(
                          filter.companyStructures,
                          x.source as CompanyStructureReducedDto
                        ),
                      })
                    }
                  >
                    <CompanyStructureSearch
                      clearAfterSelect
                      customerId={null}
                      blackList={filter.companyStructures.map((x) => x.id)}
                      selectionChange={(x) =>
                        setFilter({
                          ...filter,
                          companyStructures: GeneralFunctions.arrayAdd(
                            filter.companyStructures,
                            x
                          ),
                        })
                      }
                    />
                  </Multiselect>
                </div>

                <div className="mb-3 col-3 input-label">
                  {t("robot.filter.customerContacts")}
                </div>

                <div className="mb-3 col-9">
                  <Multiselect
                    items={BadgeHelper.customerContactsToBadgeItem(
                      filter.customerContacts
                    )}
                    itemRemove={(x) =>
                      setFilter({
                        ...filter,
                        customerContacts: GeneralFunctions.arrayRemove(
                          filter.customerContacts,
                          x.source as CustomerContactDto
                        ),
                      })
                    }
                  >
                    <CustomerContactSearch
                      customerId={null}
                      clearAfterSelect
                      blackList={filter.customerContacts.map((x) => x.id)}
                      selectionChange={(x) =>
                        setFilter({
                          ...filter,
                          customerContacts: GeneralFunctions.arrayAdd(
                            filter.customerContacts,
                            x
                          ),
                        })
                      }
                    />
                  </Multiselect>
                </div>

                <div className="mb-3 col-3 input-label">
                  {t("robot.filter.costCalculations")}
                </div>

                <div className="mb-3 col-9">
                  <Multiselect
                    items={BadgeHelper.kemaroDashboardEconomyCostCalculationToBadgeItem(
                      filter.kemaroDashboardEconomyCostCalculations
                    )}
                    itemRemove={(x) =>
                      setFilter({
                        ...filter,
                        kemaroDashboardEconomyCostCalculations:
                          GeneralFunctions.arrayRemove(
                            filter.kemaroDashboardEconomyCostCalculations,
                            x.source as KemaroDashboardEconomyCostCalculationReducedDto
                          ),
                      })
                    }
                  >
                    <KemaroDashboardEconomyCostCalculationSearch
                      clearAfterSelect
                      blackList={filter.kemaroDashboardEconomyCostCalculations.map(
                        (x) => x.id
                      )}
                      selectionChange={(x) =>
                        setFilter({
                          ...filter,
                          kemaroDashboardEconomyCostCalculations:
                            GeneralFunctions.arrayAdd(
                              filter.kemaroDashboardEconomyCostCalculations,
                              x
                            ),
                        })
                      }
                    />
                  </Multiselect>
                </div>

                <div className="mb-3 col-3 input-label">
                  {t("robot.filter.onLine.title")}
                </div>

                <div className="mb-3 col-9">
                  <Radio
                    className="px-0"
                    checked={filter.status === "All"}
                    onChange={() =>
                      setFilter({
                        ...filter,
                        status: "All",
                      })
                    }
                    name="status-radio-buttons"
                    color="primary"
                  />
                  <span className="ms-1 me-3 align-middle">
                    {t("robot.filter.onLine.all")}
                  </span>

                  <Radio
                    className="px-0"
                    checked={filter.status === "OnLine"}
                    onChange={() =>
                      setFilter({
                        ...filter,
                        status: "OnLine",
                      })
                    }
                    name="status-radio-buttons"
                    color="primary"
                  />
                  <span className="ms-1 me-3 align-middle">
                    {t("robot.filter.onLine.onLine")}
                  </span>

                  <Radio
                    className="px-0"
                    checked={filter.status === "TimedOut"}
                    onChange={() =>
                      setFilter({
                        ...filter,
                        status: "TimedOut",
                      })
                    }
                    name="status-radio-buttons"
                    color="primary"
                  />
                  <span className="ms-1 me-3 align-middle">
                    {t("robot.filter.onLine.timedOut")}
                  </span>
                </div>

                {isShowErrorSummary && (
                  <>
                    <div className="mb-3 col-3 input-label">
                      {t("robot.filter.errorStatus.title")}
                    </div>

                    <div className="mb-3 col-9">
                      <Radio
                        className="px-0"
                        checked={filter.errorStatus === "All"}
                        onChange={() =>
                          setFilter({
                            ...filter,
                            errorStatus: "All",
                          })
                        }
                        name="status-radio-buttons"
                        color="primary"
                      />
                      <span className="ms-1 me-3 align-middle">
                        {t("robot.filter.errorStatus.all")}
                      </span>

                      <Radio
                        className="px-0"
                        checked={filter.errorStatus === "Current"}
                        onChange={() =>
                          setFilter({
                            ...filter,
                            errorStatus: "Current",
                          })
                        }
                        name="status-radio-buttons"
                        color="primary"
                      />
                      <span className="ms-1 me-3 align-middle">
                        {!kemaroConfiguration ||
                        kemaroConfiguration.robots.errorSummary
                          .recentDaysToAnalyze === 0
                          ? t("robot.filter.errorStatus.today")
                          : t("robot.filter.errorStatus.days", {
                              daysNumber:
                                kemaroConfiguration.robots.errorSummary
                                  .recentDaysToAnalyze,
                            })}
                      </span>

                      <Radio
                        className="px-0"
                        checked={filter.errorStatus === "Old"}
                        onChange={() =>
                          setFilter({
                            ...filter,
                            errorStatus: "Old",
                          })
                        }
                        name="status-radio-buttons"
                        color="primary"
                      />
                      <span className="ms-1 me-3 align-middle">
                        {t("robot.filter.errorStatus.days", {
                          daysNumber:
                            kemaroConfiguration?.robots.errorSummary
                              .totalDaysToAnalyze ?? 0,
                        })}
                      </span>

                      <Radio
                        className="px-0"
                        checked={filter.errorStatus === "None"}
                        onChange={() =>
                          setFilter({
                            ...filter,
                            errorStatus: "None",
                          })
                        }
                        name="status-radio-buttons"
                        color="primary"
                      />
                      <span className="ms-1 me-3 align-middle">
                        {t("robot.filter.errorStatus.none")}
                      </span>
                    </div>
                  </>
                )}

                <div className="mb-3 col-3 input-label">
                  {t("robot.filter.deploymentStatus.title")}
                </div>

                <div className="mb-3 col-9">
                  <Radio
                    className="px-0"
                    checked={filter.deploymentStatus === "All"}
                    onChange={() =>
                      setFilter({
                        ...filter,
                        deploymentStatus: "All",
                      })
                    }
                    name="status-radio-buttons"
                    color="primary"
                  />
                  <span className="ms-1 me-3 align-middle">
                    {t("robot.filter.deploymentStatus.all")}
                  </span>

                  <Radio
                    className="px-0"
                    checked={filter.deploymentStatus === "Active"}
                    onChange={() =>
                      setFilter({
                        ...filter,
                        deploymentStatus: "Active",
                      })
                    }
                    name="status-radio-buttons"
                    color="primary"
                  />
                  <span className="ms-1 me-3 align-middle">
                    {t("robot.filter.deploymentStatus.active")}
                  </span>

                  <Radio
                    className="px-0"
                    checked={filter.deploymentStatus === "Inactive"}
                    onChange={() =>
                      setFilter({
                        ...filter,
                        deploymentStatus: "Inactive",
                      })
                    }
                    name="status-radio-buttons"
                    color="primary"
                  />
                  <span className="ms-1 me-3 align-middle">
                    {t("robot.filter.deploymentStatus.inactive")}
                  </span>
                </div>

                {isShowTag && (
                  <>
                    <div className="mb-3 col-3 input-label">
                      {t("tags.title")}
                    </div>

                    <div className="mb-3 col-9">
                      <KemaroRobotTagsList
                        addToolTip={t("tags.add")}
                        removeTootTip={t("tags.remove")}
                        tags={filter.tags}
                        itemsShowAs="InLine"
                        regularExpression={kemaroTagsRegExp}
                        invalidText={kemaroTagsRegExpInvalid}
                        onChange={(x) =>
                          setFilter({
                            ...filter,
                            tags: x,
                          })
                        }
                        canAddTag={false}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="application-modal-footer">
            <Button
              variant="contained"
              className="button-with-text"
              color="primary"
              onClick={() => props.apply(filter)}
            >
              {t("common.buttons.apply")}
            </Button>

            <Button
              className="modal-cancel-button button-with-text"
              variant="outlined"
              color="primary"
              onClick={cancelHandle}
            >
              {t("common.buttons.cancel")}
            </Button>
          </div>
        </Stack>
      </div>
    </Dialog>
  );
};

export interface kemaroRobotsFilterProps {
  open: boolean;
  filter: kemaroRobotsFilter;
  apply: (filter: kemaroRobotsFilter) => void;
  cancel: () => void;
}
