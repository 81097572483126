import {
  KemaroMissionFilterCriteriaDto,
  KemaroMissionFilterCriteriaTimezone,
} from "../../../../../robotcloud-shared/resource-models";
import { BadgeItem, applicationBarRef } from "../../../components/news";
import {
  CustomFieldAutocompleteDto,
  OrderDto,
  PaginationDto,
} from "../../../data/common";
import { GeneralFunctions } from "../../generalFunctions";
import { kemaroMissionsFilter } from "./KemaroMissionsFilterModal";

export const filterUpdate = (
  filter: kemaroMissionsFilter,
  item: BadgeItem,
  refAapplicationBar: React.RefObject<applicationBarRef>
) => {
  if (item.type === "robot") {
    GeneralFunctions.arrayRemove(
      filter.kemaroRobots,
      filter.kemaroRobots.filter((x) => x.id === item.id)[0]
    );
  }

  if (item.type === "dealer") {
    GeneralFunctions.arrayRemove(
      filter.dealers,
      filter.dealers.filter((x) => x.id === item.id)[0]
    );
  }

  if (item.type === "customer") {
    GeneralFunctions.arrayRemove(
      filter.customers,
      filter.customers.filter((x) => x.id === item.id)[0]
    );
  }

  if (item.type === "customerContact") {
    GeneralFunctions.arrayRemove(
      filter.customerContacts,
      filter.customerContacts.filter((x) => x.id === item.id)[0]
    );
  }

  if (item.type === "status") {
    filter.status = null;
  }

  if (item.type === "textToSearch") {
    filter.textToSearch = "";
    if (refAapplicationBar.current) {
      refAapplicationBar.current.reset();
    }
  }

  if (item.type === "company-structure") {
    GeneralFunctions.arrayRemove(
      filter.companyStructures,
      filter.companyStructures.filter((x) => x.id === item.id)[0]
    );
  }

  if (item.type === "dashboard-calculation") {
    GeneralFunctions.arrayRemove(
      filter.kemaroDashboardEconomyCostCalculations,
      filter.kemaroDashboardEconomyCostCalculations.filter(
        (x) => x.id === item.id
      )[0]
    );
  }

  if (item.type === "tag") {
    GeneralFunctions.arrayRemove(
      filter.tags,
      filter.tags.filter((x) => x === item.id)[0]
    );
  }

  if (item.type === "errorCode") {
    GeneralFunctions.arrayCustomFieldAutocompleteDtoRemove(
      filter.errorCodes,
      filter.errorCodes.filter((x) => x.code === item.id)[0]
    );
  }

  if (item.type === "cleaningModes") {
    GeneralFunctions.arrayCustomFieldAutocompleteDtoRemove(
      filter.cleaningModes,
      filter.cleaningModes.filter((x) => x.code === item.id)[0]
    );
  }

  if (item.type === "startReasons") {
    GeneralFunctions.arrayCustomFieldAutocompleteDtoRemove(
      filter.startReasons,
      filter.startReasons.filter((x) => x.code === item.id)[0]
    );
  }

  if (item.type === "stopReasons") {
    GeneralFunctions.arrayCustomFieldAutocompleteDtoRemove(
      filter.stopReasons,
      filter.stopReasons.filter((x) => x.code === item.id)[0]
    );
  }

  if (item.type === "zoneNames") {
    GeneralFunctions.arrayStringRemove(
      filter.zoneNames,
      filter.zoneNames.filter((x) => x === item.id)[0]
    );
  }

  if (item.type === "roomNames") {
    GeneralFunctions.arrayStringRemove(
      filter.roomNames,
      filter.roomNames.filter((x) => x === item.id)[0]
    );
  }

  return filter;
};

export const customFieldAutocompleteDtoToString = (
  item: CustomFieldAutocompleteDto
) => (GeneralFunctions.isBlank(item.text) ? item.code : item.text);

export const getCustomFieldValues = (items: CustomFieldAutocompleteDto[]) =>
  items.filter((x) => GeneralFunctions.isBlank(x.text)).map((x) => x.code);

export const getCustomFieldTexts = (items: CustomFieldAutocompleteDto[]) =>
  items.filter((x) => !GeneralFunctions.isBlank(x.text)).map((x) => x.text);

export const filterToBadgeItems = (
  filter: kemaroMissionsFilter,
  allowTextsearch: boolean
) => {
  const result: BadgeItem[] = [];

  filter.kemaroRobots.forEach((x) =>
    result.push({
      id: x.id,
      type: "robot",
      text: x.name,
      style: "robot",
    })
  );

  filter.dealers.forEach((x) =>
    result.push({
      id: x.id,
      type: "dealer",
      text: x.name,
      style: "dealer",
    })
  );

  filter.customers.forEach((x) =>
    result.push({
      id: x.id,
      type: "customer",
      text: x.name,
      style: "customer",
    })
  );

  filter.customerContacts.forEach((x) =>
    result.push({
      id: x.id,
      type: "customerContact",
      text: `${x.firstName} ${x.sureName}`,
      style: "contact",
    })
  );

  result.push({
    id: "startDate",
    type: "date",
    text: GeneralFunctions.dateToDateString(filter.startDate),
    style: "various",
    canClick: false,
  });

  result.push({
    id: "stopDate",
    type: "date",
    text: GeneralFunctions.dateToDateString(filter.stopDate),
    style: "various",
    canClick: false,
  });

  if (filter.status) {
    result.push({
      id: "status",
      type: "status",
      text: filter.status,
      style: "status",
    });
  }

  if (filter.textToSearch && allowTextsearch) {
    result.push({
      id: "textToSearch",
      type: "textToSearch",
      text: filter.textToSearch,
      style: "text-search",
    });
  }

  filter.companyStructures.forEach((x) =>
    result.push({
      id: x.id,
      type: "company-structure",
      text: x.name,
      style: "company-structure",
    })
  );

  filter.kemaroDashboardEconomyCostCalculations.forEach((x) =>
    result.push({
      id: x.id,
      type: "dashboard-calculation",
      text: x.name,
      style: "dashboard-calculation",
    })
  );

  filter.tags.forEach((x) =>
    result.push({
      id: x,
      type: "tag",
      text: x,
      style: "tag",
    })
  );

  filter.errorCodes.forEach((x) =>
    result.push({
      id: x.code,
      type: "errorCode",
      text: customFieldAutocompleteDtoToString(x),
      style: "error-codes",
    })
  );

  filter.cleaningModes.forEach((x) =>
    result.push({
      id: x.code,
      type: "cleaningModes",
      text: customFieldAutocompleteDtoToString(x),
      style: "cleaning-mode",
    })
  );

  filter.startReasons.forEach((x) =>
    result.push({
      id: x.code,
      type: "startReasons",
      text: customFieldAutocompleteDtoToString(x),
      style: "start-reason",
    })
  );

  filter.stopReasons.forEach((x) =>
    result.push({
      id: x.code,
      type: "stopReasons",
      text: customFieldAutocompleteDtoToString(x),
      style: "stop-reason",
    })
  );

  filter.zoneNames.forEach((x) =>
    result.push({
      id: x,
      type: "zoneNames",
      text: x,
      style: "zone-names",
    })
  );

  filter.roomNames.forEach((x) =>
    result.push({
      id: x,
      type: "roomNames",
      text: x,
      style: "room-names",
    })
  );

  return result;
};

export const filterToFilterCriteria = (
  ids: string[],
  filter: kemaroMissionsFilter,
  pagination: PaginationDto,
  order: OrderDto,
  allowTextsearch: boolean,
  timezone: KemaroMissionFilterCriteriaTimezone
) => {
  const result: KemaroMissionFilterCriteriaDto = {
    page: pagination.currentPage,
    maxItemCount: pagination.maxItemCount,
    orderBy: order.order,
    orderColumns: order.orderColumns,
    ids: ids,
    robotIds: GeneralFunctions.getIdsFrom(filter.kemaroRobots),
    dealerIds: GeneralFunctions.getIdsFrom(filter.dealers),
    customerIds: GeneralFunctions.getIdsFrom(filter.customers),
    customerContactIds: GeneralFunctions.getIdsFrom(filter.customerContacts),
    textToSearch: allowTextsearch ? filter.textToSearch : "",
    startTimeFrom: filter.startDate ?? null,
    startTimeTo: filter.stopDate ?? null,
    status: filter.status,
    errorCodesValues: getCustomFieldValues(filter.errorCodes),
    errorCodesTexts: getCustomFieldTexts(filter.errorCodes),
    startReasonValues: getCustomFieldValues(filter.startReasons),
    startReasonTexts: getCustomFieldTexts(filter.startReasons),
    stopReasonValues: getCustomFieldValues(filter.stopReasons),
    stopReasonTexts: getCustomFieldTexts(filter.stopReasons),
    cleaningModeTexts: getCustomFieldValues(filter.cleaningModes),
    cleaningModeValues: getCustomFieldTexts(filter.cleaningModes),
    roomNames: filter.roomNames,
    zoneNames: filter.zoneNames,
    companyStructureIds: GeneralFunctions.getIdsFrom(filter.companyStructures),
    includeChildrenNodes: filter.includeChildrenNodes,
    kemaroDashboardCostCalculationIds: GeneralFunctions.getIdsFrom(
      filter.kemaroDashboardEconomyCostCalculations
    ),
    tags: filter.tags,
    displayTimezone: timezone,
  };

  return result;
};
