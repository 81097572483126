import { Button, Dialog } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Loading } from "../../../components/Loading";
import {
  Banner,
  ToastHelper,
  tableGenericHandleKeyboardSubjectManager,
} from "../../../components/news";
import { KemaroRobotRequests } from "../../../data/kemaro/kemaroRobot.request";
import { ErrorHelper } from "../../../uiHelpers/errors.helper";
import { kemaroTagsRegExp, kemaroTagsRegExpInvalid } from "../KemaroContants";
import { KemaroRobotTagsList } from "../../../components/search/kemaro/KemaroRobotTagsList";

export const KemaroRobotTagsModal = (props: KemaroRobotTagsModalProps) => {
  const [isWorking, setIsWorking] = useState(false);
  const [tags, setTags] = useState<string[]>([]);

  const { t } = useTranslation();

  useEffect(() => {
    setTags(props.tags);
  }, [props.tags]);

  useEffect(() => {
    tableGenericHandleKeyboardSubjectManager.setData({
      caller: "KemaroRobotTagsModal",
      handleKeyboard: true,
    });
  }, []);

  const handleSave = async () => {
    setIsWorking(true);

    try {
      await KemaroRobotRequests.tags(props.robotId, tags);
      props.onRefreshTags(tags);
      props.onClose();
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsWorking(false);
  };

  return (
    <Dialog
      className="application-modal"
      open={true}
      maxWidth="md"
      onClose={() => props.onClose()}
    >
      <div className="modal-content">
        <div className="application-modal-header">
          <h3>{t("tags.modalTitle")}</h3>
        </div>
        <div className="application-modal-body kemro-robot-tag-modal">
          {isWorking && (
            <div className="row mt-2 mb-5">
              <Loading isLoading={true} />
            </div>
          )}

          {!isWorking && (
            <div className="row">
              <Banner type={"warning"} text={t("tags.allowedCharacters")} />

              <div className="data-div input-label col-4 mt-3">
                {t("tags.title")}
              </div>
              <div className="data-div col-8 mt-3">
                <KemaroRobotTagsList
                  addToolTip={t("tags.add")}
                  removeTootTip={t("tags.remove")}
                  canAddTag={true}
                  tags={tags}
                  regularExpression={kemaroTagsRegExp}
                  invalidText={kemaroTagsRegExpInvalid}
                  onChange={setTags}
                  placeHolderText={t("tags.placeHolder")}
                />
              </div>
            </div>
          )}
        </div>
        {!isWorking && (
          <div className="application-modal-footer">
            <Button
              variant="contained"
              className="button-with-text me-3"
              color="primary"
              onClick={handleSave}
            >
              {t("common.buttons.save")}
            </Button>

            <Button
              className="modal-cancel-buttonv button-with-text"
              variant="outlined"
              color="primary"
              onClick={() => props.onClose()}
            >
              {t("common.buttons.cancel")}
            </Button>
          </div>
        )}
      </div>
    </Dialog>
  );
};

interface KemaroRobotTagsModalProps {
  robotId: string;
  tags: string[];
  onRefreshTags: (tags: string[]) => void;
  onClose: () => void;
}
