import React, { useState, useEffect } from "react";
import { InputAdornment, TextField } from "@mui/material";
import { UsersRequests } from "../data/users.requests";
import { ErrorHelper } from "../uiHelpers/errors.helper";
import { ToastHelper } from "./news/Toast.helper";

export const InputUserName = ({ usernamePostfix, ret }: inputUserNameProps) => {
  const [currentValue, setCurrentValue] = React.useState("");
  const [isInvalidUserName, setIsInvalidUserName] = useState(false);

  useEffect(() => {
    const emailRegex: RegExp =
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    const invalidString = usernamePostfix
      ? currentValue.length < 4 || /[^a-zA-Z0-9'.\-_!#^~]/.test(currentValue)
      : !emailRegex.test(currentValue);

    if (invalidString) {
      setIsInvalidUserName(true);
      ret("");
      return;
    }

    const validate = async () => {
      try {
        const userName = `${currentValue}${usernamePostfix}`;
        const result = await UsersRequests.getList({
          page: 1,
          maxItemCount: 1000,
          orderBy: null,
          orderColumns: null,
          accessTypes: null,
          tenants: null,
          ids: null,
          roles: null,
          textToSearch: userName,
        });
        setIsInvalidUserName(result.length > 0);
        ret(result.length > 0 ? "" : userName);
      } catch (error) {
        ToastHelper.errors(ErrorHelper.process(error));
      }
    };

    validate();
    // We only want to validate when the user has entered a username
    // when adding the usernamePostfix and ret to the dependency array we get an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue]);

  return (
    <>
      <TextField
        type="text"
        label=""
        color={isInvalidUserName ? "error" : "success"}
        fullWidth
        variant="standard"
        margin="dense"
        hiddenLabel
        onChange={(event) => {
          setCurrentValue(event.target.value);
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <strong>{usernamePostfix}</strong>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};

export type inputUserNameProps = {
  ret: (newValue: string) => void;
  usernamePostfix: string;
};
