import { useState } from "react";
import {
  KemaroDashboardEconomyCostCalculationCompleteDto,
  KemaroDashboardEconomyCostCalculationParameterDto,
  KemaroDashboardEconomyCostCalculationPostDto,
} from "../../../../../../robotcloud-shared/resource-models";
import { Loading } from "../../../../components/Loading";
import { YesNoOkDialog } from "../../../../components/news/YesNoOkDialog";
import { GeneralFunctions } from "../../../generalFunctions";
import { KemaroCostCalculationLocations } from "./KemaroCostCalculationLocations";
import { KemaroCostCalculationParameterModal } from "./KemaroCostCalculationParameterModal";
import { KemaroCostCalculationViewHeader } from "./KemaroCostCalculationViewHeader";
import { KemaroCostCalculationViewParameters } from "./KemaroCostCalculationViewParameters";
import { KemaroCostCalculationViewTitle } from "./KemaroCostCalculationViewTitle";
import { useTranslation } from "react-i18next";

export const KemaroCostCalculationView = (
  props: kemaroCostCalculationViewProps
) => {
  const { t } = useTranslation();

  const [isWorkingParameterModal, setIsWorkingParameterModal] = useState(false);
  const [isOpenAddParameterModal, setIsOpenAddParameterModal] = useState(false);
  const [isOpenCopyParameterModal, setIsOpenCopyParameterModal] =
    useState(false);
  const [isOpenEditParameterModal, setIsOpenEditParameterModal] =
    useState(false);
  const [isOpenDeleteParameterModal, setIsOpenDeleteParameterModal] =
    useState(false);
  const [dtoParameterModal, setDtoParameterModal] = useState<
    KemaroDashboardEconomyCostCalculationParameterDto | undefined
  >(undefined);

  const cancelParameterModal = () => {
    setIsWorkingParameterModal(false);
    setIsOpenAddParameterModal(false);
    setIsOpenCopyParameterModal(false);
    setIsOpenEditParameterModal(false);
    setIsOpenDeleteParameterModal(false);
    setDtoParameterModal(undefined);
  };

  const addParameter = async (
    parameter: KemaroDashboardEconomyCostCalculationParameterDto
  ) => {
    const result = await props.addParameter(props.item, parameter);
    if (result) {
      cancelParameterModal();
    }
  };

  const editParameter = async (
    original: KemaroDashboardEconomyCostCalculationParameterDto,
    modified: KemaroDashboardEconomyCostCalculationParameterDto
  ) => {
    const result = await props.editParameter(original, modified);
    if (result) {
      cancelParameterModal();
    }
  };

  const deleteParameter = async () => {
    const result = await props.deleteParameter(dtoParameterModal!);
    if (result) {
      cancelParameterModal();
    }
  };

  const editCalculation = async (
    modified: KemaroDashboardEconomyCostCalculationPostDto
  ) => {
    const result = await props.editCalculation(props.item, modified);
    if (result) {
      cancelParameterModal();
    }

    return result;
  };

  const copyCalculation = async (
    modified: KemaroDashboardEconomyCostCalculationPostDto
  ) => {
    const result = await props.copyCalculation({
      ...modified,
      isDefault: false,
    });
    if (result) {
      cancelParameterModal();
    }

    return result;
  };

  if (props.isFetching) {
    return <Loading isLoading={props.isFetching} />;
  }

  return (
    <>
      <KemaroCostCalculationViewTitle
        item={props.item}
        onCopy={(dto) => copyCalculation(dto)}
        onModify={(dto) => editCalculation(dto)}
        onDelete={(dto) => props.deleteCalculation(dto)}
      />

      <KemaroCostCalculationViewHeader item={props.item} />

      <KemaroCostCalculationViewParameters
        item={props.item}
        onAdd={() => setIsOpenAddParameterModal(true)}
        onCopy={(dto) => {
          setDtoParameterModal(dto);
          setIsOpenCopyParameterModal(true);
        }}
        onEdit={(dto) => {
          setDtoParameterModal(dto);
          setIsOpenEditParameterModal(true);
        }}
        onDelete={(dto) => {
          setDtoParameterModal(dto);
          setIsOpenDeleteParameterModal(true);
        }}
      />

      <KemaroCostCalculationLocations idCostCalculation={props.item.id} />

      {isOpenAddParameterModal && (
        <KemaroCostCalculationParameterModal
          isOpenModal={isOpenAddParameterModal}
          isWorking={isWorkingParameterModal}
          title={t("costCalculation.parameters.add.title")}
          currency={props.item.currency.abbreviation}
          dto={dtoParameterModal}
          onSave={(parameter) => addParameter(parameter)}
          onCancel={cancelParameterModal}
        />
      )}

      {isOpenCopyParameterModal && (
        <KemaroCostCalculationParameterModal
          isOpenModal={isOpenCopyParameterModal}
          isWorking={isWorkingParameterModal}
          title={t("costCalculation.parameters.copy.title")}
          currency={props.item.currency.abbreviation}
          dto={dtoParameterModal}
          onSave={(parameter) => addParameter(parameter)}
          onCancel={cancelParameterModal}
        />
      )}

      {isOpenEditParameterModal && (
        <KemaroCostCalculationParameterModal
          isOpenModal={isOpenEditParameterModal}
          isWorking={isWorkingParameterModal}
          title={t("costCalculation.parameters.edit.title")}
          currency={props.item.currency.abbreviation}
          dto={dtoParameterModal}
          onSave={(parameter) => editParameter(dtoParameterModal!, parameter)}
          onCancel={cancelParameterModal}
        />
      )}

      <YesNoOkDialog
        open={isOpenDeleteParameterModal}
        size="md"
        type="yesNo"
        title={t("costCalculation.parameters.delete.title")}
        message={`${t(
          "costCalculation.parameters.delete.warning"
        )} ${GeneralFunctions.dateToDateString(dtoParameterModal?.from)}?`}
        onYes={() => deleteParameter()}
        onNo={() => cancelParameterModal()}
        isWorking={false}
      />
    </>
  );
};

interface kemaroCostCalculationViewProps {
  item: KemaroDashboardEconomyCostCalculationCompleteDto;
  isFetching: boolean;
  copyCalculation: (
    modified: KemaroDashboardEconomyCostCalculationPostDto
  ) => Promise<boolean>;
  editCalculation: (
    original: KemaroDashboardEconomyCostCalculationPostDto,
    modified: KemaroDashboardEconomyCostCalculationPostDto
  ) => Promise<boolean>;
  deleteCalculation: (
    dto: KemaroDashboardEconomyCostCalculationCompleteDto
  ) => Promise<boolean>;
  addParameter: (
    calculation: KemaroDashboardEconomyCostCalculationCompleteDto,
    parameter: KemaroDashboardEconomyCostCalculationParameterDto
  ) => Promise<boolean>;
  editParameter: (
    original: KemaroDashboardEconomyCostCalculationParameterDto,
    modified: KemaroDashboardEconomyCostCalculationParameterDto
  ) => Promise<boolean>;
  deleteParameter: (
    parameter: KemaroDashboardEconomyCostCalculationParameterDto
  ) => Promise<boolean>;
}
