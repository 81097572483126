import { CustomerDto } from "../../../../../../robotcloud-shared/resource-models";
import { ToastHelper } from "../../../../components/news";
import { CustomersRequests } from "../../../../data/customers.request";
import { ErrorHelper } from "../../../../uiHelpers/errors.helper";

export const getCustomers = async (idCustomer: string | null) => {
  const result: CustomerDto[] = [];
  if (!idCustomer) return result;

  try {
    const customer = await CustomersRequests.getById(idCustomer);
    result.push(customer);
  } catch (error) {
    ToastHelper.errors(ErrorHelper.process(error));
  }

  return result;
};
