import { useContext, useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { Loading } from "../../../components/Loading";
import { GeneralFunctions } from "../../generalFunctions";
import { KemaroRobotViewCleanedAreaHistory } from "./KemaroRobotViewCleanedAreaHistory";
import { PermissionsHelper } from "../../../uiHelpers/permissionsHelper";
import { NavigateTo } from "../../../components/news/NavigateTo";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import { useTranslation } from "react-i18next";
import AuthorizationContext from "../../auth/authorizationContext";
import KemaroRobotContext from "./KemaroRobotContext";
import { LocationBreadcrumbs } from "./Components";
import useTheme from "@mui/material/styles/useTheme";
import { KemaroRobotCleaningDataTotalsDtoType } from "../../../../../robotcloud-shared/resource-models";
import RobotCleaningDataTotalsTypesSearch from "../../../components/search/RobotCleaningDataTotalsTypesSearch";
import useRobotCleaningDataTotals from "./hooks/useRobotCleaningDataTotals";
import { useRobotModelImage } from "../../../hooks";

export const KemaroRobotViewInformation = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    robotCleaningDataTotals,
    errorRobotCleaningDataTotals,
    loadingRobotCleaningDataTotals,
  } = useRobotCleaningDataTotals();

  const { imageRobotModel, imageRobotModelShow, isFetchingRobotModel } =
    useRobotModelImage();
  const [totals, setTotals] =
    useState<KemaroRobotCleaningDataTotalsDtoType>("Deployment");
  const [showTags, setShowTags] = useState(false);

  const { me } = useContext(AuthorizationContext);
  const { currentItem } = useContext(KemaroRobotContext);

  useEffect(() => {
    setShowTags(PermissionsHelper.allow("KemaroRobots", "View", "Tags"));
  }, [me]);

  const informationTable = (
    <table className="table table-borderless w-100 kemaro-robot-info mt-0 mb-0">
      <tbody>
        <tr>
          <td className="td-label">{t("robot.serialNumber")}</td>
          <td className="td-data pb-2">{currentItem!.serialNumber}</td>
        </tr>

        <tr>
          <td className="td-label">{t("robot.installationDate.text")}</td>
          <td className="td-data pb-2">
            {currentItem!.installationDate &&
              GeneralFunctions.dateToDateString(currentItem!.installationDate)}
          </td>
        </tr>
        <tr>
          <td className="td-label">{t("robot.dealer")}</td>
          <td className="td-data">
            <NavigateTo
              text={currentItem!.dealerName ?? ""}
              shorCut="dealers"
              toolTipIsSingular={true}
              isAvailable={currentItem!.idDealer !== null}
              paramters={[{ name: "id", value: currentItem!.idDealer ?? "" }]}
            />
          </td>
        </tr>
        <tr>
          <td className="td-label">{t("robot.customer")}</td>
          <td className="td-data">
            <NavigateTo
              text={currentItem!.customerName ?? ""}
              shorCut="customers"
              toolTipIsSingular={true}
              isAvailable={currentItem!.idCustomer !== null}
              paramters={[{ name: "id", value: currentItem!.idCustomer ?? "" }]}
            />
          </td>
        </tr>
        <tr>
          <td className="td-label">{t("robot.companyStructure")}</td>
          <td className="td-data">
            {currentItem!.companyStructure && (
              <>
                <LocationBreadcrumbs />

                <NavigateTo
                  text={currentItem!.companyStructure.name}
                  shorCut="customers"
                  toolTipText={t("common.navigation.location")}
                  isAvailable={currentItem!.idCustomer !== null}
                  paramters={[
                    {
                      name: "id",
                      value: currentItem!.idCustomer ?? "",
                    },
                    {
                      name: "idLocation",
                      value: currentItem!.companyStructure.id,
                    },
                  ]}
                />
              </>
            )}
          </td>
        </tr>
        <tr>
          <td className="td-label">
            <SubdirectoryArrowRightIcon
              className="child-parameter me-1"
              sx={{ color: "swarm.kemaroRobots.childParameter" }}
            />
            {t("robot.costCalculation")}
          </td>
          <td className="td-data">
            <NavigateTo
              text={
                currentItem!.kemaroDashboardEconomyCostCalculationName ?? ""
              }
              shorCut="cost-calculation"
              toolTipIsSingular={true}
              isAvailable={
                currentItem!.idKemaroDashboardEconomyCostCalculation !== null
              }
              paramters={[
                {
                  name: "id",
                  value:
                    currentItem!.idKemaroDashboardEconomyCostCalculation ?? "",
                },
              ]}
              notAvailableChildren={
                <p className="pb-2">
                  {currentItem!.kemaroDashboardEconomyCostCalculationName ?? ""}
                </p>
              }
            />
          </td>
        </tr>
      </tbody>
    </table>
  );

  const robotModel = isFetchingRobotModel ? (
    <Loading isLoading={isFetchingRobotModel} />
  ) : (
    imageRobotModelShow && (
      <img
        className="cursor-pointer robot-image px-0 mx-auto"
        src={imageRobotModel}
        alt="Robot model"
      />
    )
  );

  return (
    <>
      <div className="container-fluid px-0 mx-0 mt-3">
        <div className="row mx-0 px-0 mt-0 justify-content-between ">
          <div className="col  mb-0 pb-0">{informationTable}</div>

          <div
            className="col-3 mb-0 px-0 mx-0 d-flex align-items-end flex-column"
            style={{ minWidth: "13rem" }}
          >
            <div className="robot-image-container px-0 ">{robotModel}</div>
          </div>
        </div>
      </div>

      <div className="container-fluid px-0 mx-0">
        <div className="row mx-0 px-0 mt-0 mb-3">
          <RobotCleaningDataTotalsTypesSearch
            value={totals}
            onChange={(value: KemaroRobotCleaningDataTotalsDtoType) => {
              setTotals(value);
            }}
            robotCleaningDataTotals={robotCleaningDataTotals}
            loading={loadingRobotCleaningDataTotals}
            error={errorRobotCleaningDataTotals}
          />
          {loadingRobotCleaningDataTotals && (
            <div className="col-12 col-xxl-6 mb-4 kemaro-robot-cleaning-information mt-2">
              <Loading isLoading={isFetchingRobotModel} />
            </div>
          )}
        </div>
      </div>

      {!loadingRobotCleaningDataTotals && (
        <div className="container-fluid px-0 mx-0">
          <div className="row mx-0 px-0 mt-0 mb-4">
            <div className="col-8 col-xxl-6 mb-1 kemaro-robot-cleaning-information mt-2">
              <div
                className="w-100 krci-container pt-3 pb-2"
                style={{
                  borderColor:
                    theme.palette.swarm?.kemaroRobots?.krciContainerBorder,
                }}
              >
                <p className="krci-title">
                  {t("robot.cleaningInformation.totalOperatingTime")}
                </p>
                <p className="mb-0">
                  <span className="krci-value ms-2">
                    {Math.floor(
                      robotCleaningDataTotals.find((x) => x.type === totals)
                        ?.workedHours ?? 0
                    )}
                  </span>
                  <span className="krci-label">{t("common.hours")}</span>
                </p>
              </div>
            </div>

            <div className="col-8 col-xxl-6 mb-1 kemaro-robot-cleaning-information mt-2">
              <div
                className="w-100 krci-container pt-3 pb-2"
                style={{
                  borderColor:
                    theme.palette.swarm?.kemaroRobots?.krciContainerBorder,
                }}
              >
                <p className="krci-title">
                  {t("robot.cleaningInformation.totalCleanedArea")}
                </p>
                <p className="mb-0">
                  <span className="krci-value">
                    <NumericFormat
                      value={
                        robotCleaningDataTotals.find((x) => x.type === totals)
                          ?.totalCleanedArea ?? 0
                      }
                      displayType={"text"}
                      thousandSeparator={"'"}
                    />
                  </span>
                  <span className="krci-label">
                    m<sup>2</sup>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="mb-4 kemaro-robot-cleaned-area-history">
        <KemaroRobotViewCleanedAreaHistory dataTotal={totals} />
      </div>

      <div className="col-12 mb-4 kemaro-robot-customer-contact">
        <p className="mb-0 title">{t("robot.customerContact.titel")}</p>
        {currentItem!.customerContact && (
          <table className="table table-borderless w-100">
            <tbody>
              <tr>
                <td className="td-label">
                  {t("robot.customerContact.completeName")}
                </td>
                <td>{currentItem!.customerContact.completeName}</td>
              </tr>
              <tr>
                <td className="td-label">
                  {t("robot.customerContact.address")}
                </td>
                <td>
                  <p
                    className="mb-0"
                    dangerouslySetInnerHTML={{
                      __html: currentItem!.customerContact.address.replaceAll(
                        "\n",
                        ", "
                      ),
                    }}
                  ></p>
                  <p className="mb-0">{currentItem!.customerContact.country}</p>
                </td>
              </tr>
              <tr>
                <td className="td-label">{t("robot.customerContact.phone")}</td>
                <td>{currentItem!.customerContact.phone}</td>
              </tr>
              <tr>
                <td className="td-label">{t("robot.customerContact.email")}</td>
                <td>{currentItem!.customerContact.email}</td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};
