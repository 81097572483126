import { SwarmThemeContext } from "../SwarmThemeProvider";
import ToggleButton from "@mui/material/ToggleButton";
import useTheme from "@mui/material/styles/useTheme";
import React from "react";

const ColorThemeSwitch = () => {
  const theme = useTheme();
  const colorMode = React.useContext(SwarmThemeContext);
  return (
    <ToggleButton
      value={"check"}
      color={"primary"}
      style={{ borderRadius: "50px", border: "none" }}
      onChange={colorMode.toggleColorTheme}
    >
      {theme.palette.swarm?.name}
    </ToggleButton>
  );
};

export default ColorThemeSwitch;
