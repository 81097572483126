import { useEffect, useState } from "react";
import { Button, Dialog, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import { ManufacturerReducedDto } from "../../../../../robotcloud-shared/resource-models";
import { ManufacturerRequests } from "../../../data/manufacturers.Requests";
import { ToastHelper } from "../../../components/news/Toast.helper";
import { ErrorHelper } from "../../../uiHelpers/errors.helper";
import { TableGeneric } from "../../../components/news/TableGeneric/TableGeneric";
import {
  TableGenericHelper,
  tableGenericHandleKeyboardSubjectManager,
} from "../../../components/news";
import { OrderDto } from "../../../data/common";
import { Loading } from "../../../components/Loading";
import { useTranslation } from "react-i18next";
import { TableGenericColumn } from "../../../components/news/TableGeneric/models";

export const ManufacturersSelectModal = (
  props: manufacturersSelectModalProps
) => {
  const { t } = useTranslation();
  const [manufacturers, setManufacturers] = useState<ManufacturerReducedDto[]>(
    []
  );
  const [isWorking, setIsWorking] = useState(false);
  const [manufacturersSelected, setManufacturersSelected] = useState<
    ManufacturerReducedDto[]
  >([]);
  const [dataOrder, setDataOrder] = useState<OrderDto>({
    orderColumns: "name",
    order: "Asc",
  });
  const [dataOrderSelected, setDataOrderSelected] = useState<OrderDto>({
    orderColumns: "name",
    order: "Asc",
  });

  const columnsDefinitionLeft: TableGenericColumn[] = [
    {
      title: t("manufacturer.select.unselected"),
      property: "name",
      headerClassName: "ps-0",
      itemClassName: "ps-0",
    },
  ];

  const columnsDefinitionRight: TableGenericColumn[] = [
    {
      title: t("manufacturer.select.toAdd"),
      property: "name",
      headerClassName: "ps-0",
      itemClassName: "ps-0",
    },
  ];

  useEffect(() => {
    const getList = async () => {
      try {
        setIsWorking(true);
        const result = await ManufacturerRequests.getList({
          page: 1,
          id: null,
          maxItemCount: 200,
          orderBy: "Asc",
          orderColumns: "name",
          textToSearch: "",
        });
        setIsWorking(false);
        setManufacturers(
          result.items.filter((x) => !props.idsToRemove.some((y) => y === x.id))
        );
        setManufacturersSelected([]);
      } catch (error) {
        ToastHelper.errors(ErrorHelper.process(error));
      }
    };

    getList();
  }, [props.idsToRemove]);

  useEffect(() => {
    tableGenericHandleKeyboardSubjectManager.setData({
      caller: "ManufacturersSelectModal",
      handleKeyboard: props.isOpenModal,
    });
  }, [props.isOpenModal]);

  TableGenericHelper.localOrder(dataOrder, manufacturers);
  TableGenericHelper.localOrder(dataOrderSelected, manufacturersSelected);

  const select = (item: ManufacturerReducedDto) => {
    setManufacturersSelected([...manufacturersSelected, item]);

    let newManufacturers = [...manufacturers];
    const idx = newManufacturers.indexOf(item);
    if (idx !== -1) {
      newManufacturers.splice(idx, 1);
      setManufacturers(newManufacturers);
    }
  };

  const unselect = (item: ManufacturerReducedDto) => {
    setManufacturers([...manufacturers, item]);

    let newManufacturersSelected = [...manufacturersSelected];
    const idx = newManufacturersSelected.indexOf(item);
    if (idx !== -1) {
      newManufacturersSelected.splice(idx, 1);
      setManufacturersSelected(newManufacturersSelected);
    }
  };

  const handleSave = async () => {
    setIsWorking(true);
    await props.onSave(manufacturersSelected.map((x) => x.id));
    setIsWorking(false);
  };

  return (
    <Dialog
      className="application-modal"
      open={props.isOpenModal}
      maxWidth="md"
      onClose={() => props.onCancel()}
    >
      <div className="modal-content">
        <Stack>
          <Box className="application-modal-header">
            <h3>{t("manufacturer.select.title")}</h3>
          </Box>
          <Box className="application-modal-body">
            {isWorking ? (
              <Loading isLoading={isWorking} />
            ) : (
              <table className="w-100">
                <tbody>
                  <tr>
                    <td className="w-50 v-top">
                      {manufacturers !== undefined && (
                        <TableGeneric
                          tableClassName="w-100"
                          columns={columnsDefinitionLeft}
                          items={manufacturers}
                          idProperty="id"
                          headerClick={(header, itemsOrder) =>
                            TableGenericHelper.localOrder(
                              itemsOrder,
                              manufacturers,
                              setDataOrder
                            )
                          }
                          selectionChanged={select}
                          itemsOrder={dataOrder}
                        />
                      )}
                    </td>
                    <td className="one-button-container"></td>
                    <td className="w-50 v-top">
                      <TableGeneric
                        tableClassName="w-100"
                        columns={columnsDefinitionRight}
                        items={manufacturersSelected}
                        idProperty="id"
                        headerClick={(header, itemsOrder) =>
                          TableGenericHelper.localOrder(
                            itemsOrder,
                            manufacturersSelected,
                            setDataOrderSelected
                          )
                        }
                        selectionChanged={unselect}
                        itemsOrder={dataOrder}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </Box>
          {isWorking ? (
            <Box className="application-modal-footer"></Box>
          ) : (
            <Box className="application-modal-footer">
              <Button
                variant="contained"
                className="button-with-text"
                color="primary"
                onClick={() => handleSave()}
              >
                {t("common.buttons.save")}
              </Button>

              <Button
                className="modal-cancel-button button-with-text"
                variant="outlined"
                color="primary"
                onClick={(x) => props.onCancel()}
              >
                {t("common.buttons.cancel")}
              </Button>
            </Box>
          )}
        </Stack>
      </div>
    </Dialog>
  );
};

interface manufacturersSelectModalProps {
  idsToRemove: string[];
  isOpenModal: boolean;
  onSave: (manufacturerIds: string[]) => Promise<void>;
  onCancel: () => void;
}
