import { useEffect, useState } from "react";
import { OrderDto, PaginationDto, paginationDtoDefault } from "../data/common";
import {
  RobotModelCompleteDto,
  RobotModelFilterCriteriaDto,
  RobotModelListDto,
  RobotModelPostDto,
} from "../../../robotcloud-shared/resource-models";
import { defaultModalState } from "../uiHelpers/dtos/modalState";
import { QueryParamHelper } from "../uiHelpers/queryParamHelper";
import { RobotModelRequest } from "../data/robotModel.request";
import { ErrorHelper } from "../uiHelpers/errors.helper";
import { GeneralFunctions } from "../views/generalFunctions";
import { ToastHelper } from "../components/news";

export const useRobotModels = () => {
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [textToSearch, setTextToSearch] = useState("");
  const [pagination, setPagination] = useState<PaginationDto>({
    ...paginationDtoDefault,
  });
  const [dataOrder, setDataOrder] = useState<OrderDto>({
    orderColumns: "name",
    order: "Asc",
  });
  const [isFetchingList, setIsFetchingList] = useState(false);
  const [isFetchingItem, setIsFetchingItem] = useState(false);
  const [items, setItems] = useState<RobotModelListDto[] | undefined>(
    undefined
  );
  const [currentId, setCurrentId] = useState<string | undefined>(undefined);
  const [currentItem, setCurrentItem] = useState<
    RobotModelCompleteDto | undefined
  >(undefined);
  const [currentItemImage, setCurrentItemImage] = useState("");
  const [lastSearch, setLastSearch] = useState("--");
  const [createModalState, setCreateModalState] = useState({
    ...defaultModalState,
  });

  useEffect(() => {
    let filterCriteria: RobotModelFilterCriteriaDto = {
      page: pagination.currentPage,
      maxItemCount: pagination.maxItemCount,
      orderBy: dataOrder.order,
      orderColumns: dataOrder.orderColumns,
      id: null,
      textToSearch: textToSearch,
    };

    const strFilterCriteria = JSON.stringify(filterCriteria);
    if (lastSearch === strFilterCriteria) {
      return;
    }

    if (isFirstTime) {
      const qph = new QueryParamHelper(window.location.search);
      const id = qph.getValue("id");
      if (id) {
        filterCriteria.id = id;
        setCurrentId(id);
      }
    }

    setLastSearch(strFilterCriteria);

    getList(filterCriteria, pagination, currentItem, false);
    setIsFirstTime(false);
  }, [
    pagination,
    dataOrder,
    textToSearch,
    lastSearch,
    isFirstTime,
    currentItem,
  ]);

  useEffect(() => {
    if (currentId) {
      getItem(isFetchingItem, currentItem, currentId);
    }
  }, [currentId, currentItem, isFetchingItem]);

  const getItem = async (
    isFetching: boolean,
    ci: RobotModelCompleteDto | undefined,
    id: string
  ) => {
    if (isFetching || ci?.id === id) return;

    setIsFetchingItem(true);

    try {
      const item = await RobotModelRequest.getById(id);
      setCurrentId(item.id);
      setCurrentItem(item);
      setCurrentItemImage("");
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    try {
      const data = await RobotModelRequest.getImageForce(id!);

      if (data.image) {
        setCurrentItemImage(GeneralFunctions.imageStringToUrl(data.image));
      }
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsFetchingItem(false);
  };

  const callGetList = async (selectFirstItem: boolean) => {
    let filterCriteria: RobotModelFilterCriteriaDto = {
      page: pagination.currentPage,
      maxItemCount: pagination.maxItemCount,
      orderBy: dataOrder.order,
      orderColumns: dataOrder.orderColumns,
      id: null,
      textToSearch: textToSearch,
    };

    await getList(filterCriteria, pagination, currentItem, selectFirstItem);
  };

  const getList = async (
    filterCriteria: RobotModelFilterCriteriaDto,
    page: PaginationDto,
    ci: RobotModelCompleteDto | undefined,
    selectFirstItem: boolean
  ) => {
    setIsFetchingList(true);

    try {
      const data = await RobotModelRequest.getList(filterCriteria);
      setItems(data.items);

      if (data.items.length > 0) {
        if (selectFirstItem || !ci || !data.items.some((x) => x.id === ci.id)) {
          setCurrentId(data.items[0].id);
        }
      }

      GeneralFunctions.ChangePagination(page, data, setPagination);
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsFetchingList(false);
  };

  const createRobotModel = async (dto: RobotModelPostDto) => {
    let result = false;
    setCreateModalState({ ...createModalState, isWorking: true });

    try {
      const item = await RobotModelRequest.create(dto);

      setLastSearch("--");
      setCurrentId(item.id);

      result = true;
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setCreateModalState({
      ...createModalState,
      isWorking: false,
      isOpen: false,
    });

    return result;
  };

  const modifyEntity = async (
    original: RobotModelCompleteDto,
    modified: RobotModelPostDto
  ) => {
    let result = false;

    try {
      const item = await RobotModelRequest.modify(original, modified);

      setLastSearch("--");
      setCurrentId(item.id);
      await getItem(false, undefined, original.id);

      result = true;
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    return result;
  };

  const deleteEntity = async (item: RobotModelCompleteDto) => {
    let result = false;
    let selectFirstItem = false;

    try {
      await RobotModelRequest.delete(item.id);

      const nextItem = GeneralFunctions.nextItemFromArray<RobotModelListDto>(
        items,
        item.id
      );

      setCurrentId(nextItem?.id ?? undefined);
      if (nextItem) await getItem(false, undefined, nextItem.id);
      else {
        selectFirstItem = true;
        setCurrentItem(undefined);
      }

      callGetList(selectFirstItem);

      result = true;
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    return result;
  };

  const textSearchChange = (newTextToSearch: string) => {
    if (newTextToSearch === textToSearch) return;
    setTextToSearch(newTextToSearch);
    pageChange({
      ...pagination,
      currentPage: 1,
    });
  };

  const pageChange = (pagination: PaginationDto) => {
    setCurrentId(undefined);
    setCurrentItem(undefined);
    setPagination(pagination);
  };
  return{
    textSearchChange,
    createRobotModel,
    modifyEntity,
    deleteEntity,
    pageChange,
    items,
    currentItem,
    currentItemImage,
    isFetchingList,
    isFetchingItem,
    pagination,
    dataOrder,
    setDataOrder,
    setCurrentId,
    createModalState,
    setCreateModalState,}
};
