import { Stack } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import {
  CustomerCompleteDto,
  CustomerContactReducedDto,
  DealerReducedDto,
  ManufacturerReducedDto,
  UserReducedDto,
} from "../../../../robotcloud-shared/resource-models";
import { Loading } from "../../components/Loading";
import { ToastHelper } from "../../components/news/Toast.helper";
import { ErrorHelper } from "../../uiHelpers/errors.helper";
import { ManufacturerRequests } from "../../data/manufacturers.Requests";
import { UsersRequests } from "../../data/users.requests";
import { CustomerViewContacts } from "./CustomerViewContacts";
import { CustomerViewManufacturers } from "./CustomerViewManufacturers";
import { CustomerViewTitle } from "./CustomerViewTitle";
import { CustomerViewUsers } from "./CustomerViewUsers";
import { CustomerViewDealers } from "./CustomerViewDealers";
import { DealersRequests } from "../../data/dealers.request";
import { CustomersContactsRequests } from "../../data/customersContacts.request";
import { PermissionsHelper } from "../../uiHelpers/permissionsHelper";
import { CompanyStructureView } from "../shared/companyStructureView/CompanyStructureView";
import { updateCustomerContactsSubscriptionManager } from "./subscriptionManager";
import { updateTreeSubscriptionManager } from "../shared/companyStructureView/subscriptionManager";
import AuthorizationContext from "../auth/authorizationContext";

export const CustomerView = (props: customerViewProps) => {
  const [contacts, setContacts] = useState<CustomerContactReducedDto[]>([]);
  const [users, setUsers] = useState<UserReducedDto[]>([]);
  const [manufacturers, setManufacturers] = useState<ManufacturerReducedDto[]>(
    []
  );
  const [dealers, setDealers] = useState<DealerReducedDto[]>([]);
  const [isEditor, setIsEditor] = useState(false);

  const { me } = useContext(AuthorizationContext);

  /*
  me is used to get the right permissions, avoiding a bug where a new created customers
  shows up as readonly because of race conditions
  */
  useEffect(() => {
    setContacts(props.item?.customerContacts ?? []);
    setUsers(props.item?.users ?? []);
    setManufacturers(props.item?.manufacturers ?? []);
    setDealers(props.item?.dealers ?? []);

    setIsEditor(
      props.item != null && PermissionsHelper.editorOfCustomer(props.item.id)
    );
  }, [props.item, me]);

  useEffect(() => {
    const updateCustomerContactsSubscription =
      updateCustomerContactsSubscriptionManager
        .getObservable()
        .subscribe((x) => updateContacts());

    return () => {
      updateCustomerContactsSubscription.unsubscribe();
    };
  });

  if (props.isFetching) {
    return <Loading isLoading={props.isFetching} />;
  }

  if (!props.item) {
    return <></>;
  }

  const updateContacts = async () => {
    try {
      setContacts(
        await CustomersContactsRequests.getFromCustomer(props.item!.id)
      );
      updateTreeSubscriptionManager.setData(true);
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }
  };

  const updateUsers = async () => {
    try {
      setUsers(await UsersRequests.getFromCustomer(props.item!.id));
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }
  };

  const updateManufacturers = async () => {
    try {
      setManufacturers(
        await ManufacturerRequests.getFromCustomer(props.item!.id)
      );
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }
  };

  const updateDealers = async () => {
    try {
      setDealers(await DealersRequests.getFromCustomer(props.item!.id));
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }
  };

  return (
    <Stack>
      <CustomerViewTitle
        item={props.item}
        canEdit={isEditor}
        onEdit={props.onEdit}
        onDelete={props.onDelete}
      />

      <CustomerViewManufacturers
        manufacturers={manufacturers}
        item={props.item}
        canEdit={isEditor}
        update={updateManufacturers}
      />

      <CustomerViewDealers
        dealers={dealers}
        item={props.item}
        canEdit={isEditor}
        update={updateDealers}
      />

      <CustomerViewUsers
        item={props.item}
        users={users}
        canEdit={isEditor}
        update={updateUsers}
      />

      <CustomerViewContacts
        item={props.item}
        contacts={contacts}
        canEdit={isEditor}
        update={updateContacts}
      />

      <CompanyStructureView
        idCompany={props.item.id}
        canEdit={isEditor}
        startIdLocation={props.startIdLocation}
      />

      <div className="mt-5"></div>
    </Stack>
  );
};

interface customerViewProps {
  item: CustomerCompleteDto | undefined;
  startIdLocation: string;
  onEdit: (item: CustomerCompleteDto) => void;
  onDelete: (item: CustomerCompleteDto) => void;
  isFetching: boolean;
}
