import { CustomFieldAutocompleteDto, PaginationDto } from "../data/common";

export class GeneralFunctions {
  static durationToHoursMinutesString = (minutes: number) => {
    const m = minutes % 60;
    const h = (minutes - m) / 60;

    return h > 0 ? `${h} h ${Math.round(m)} min` : `${Math.round(m)} min`;
  };

  static minutosToHourDotMins = (minutes: number) => {
    const m = minutes % 60;
    const h = (minutes - m) / 60;

    return `${h}`.padStart(2, "0") + ":" + `${Math.round(m)}`.padStart(2, "0");
  };

  static durationToDayHoursMinutesString = (minutes: number) => {
    var seconds = minutes * 60;
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor((seconds % (3600 * 24)) / 3600);
    var m = Math.floor((seconds % 3600) / 60);

    var dDisplay = d > 0 ? d + " d " : "";
    var hDisplay = h > 0 ? h + " h " : "";
    var mDisplay = m > 0 ? m + " min" : "";

    return dDisplay + hDisplay + mDisplay;
  };

  static isBlank = (str: string | null | undefined) => {
    ///^\s*$/.test(str)
    return !!!str || str.trim().length === 0;
  };

  static dateToTimeDateString = (
    parameter: Date | string | null | undefined
  ) => {
    if (!parameter) {
      return "";
    }

    const date = new Date(parameter);

    return (
      date.toTimeString().slice(0, 5) +
      " " +
      `${date.getFullYear()}-` +
      `${(date.getMonth() + 1).toString().padStart(2, "0")}-` +
      `${date.getDate().toString().padStart(2, "0")}`
    );
  };

  static dateToDateString = (parameter: Date | string | null | undefined) => {
    if (!parameter) {
      return "";
    }

    const date = new Date(parameter);

    return (
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      date.getDate().toString().padStart(2, "0")
    );
  };

  static dateToAnsii = (parameter: Date | string | null | undefined) => {
    if (!parameter) {
      return "";
    }

    const date = new Date(parameter);

    return (
      date.getFullYear().toString().padStart(4, "0") +
      (date.getMonth() + 1).toString().padStart(2, "0") +
      date.getDate().toString().padStart(2, "0") +
      date.getHours().toString().padStart(2, "0") +
      date.getMinutes().toString().padStart(2, "0") +
      date.getSeconds().toString().padStart(2, "0")
    );
  };

  static dateToDateTimeUi = (parameter: Date | string | null | undefined) => {
    if (!parameter) {
      return "";
    }

    const date = new Date(parameter);

    return (
      date.getFullYear().toString().padStart(4, "0") +
      "-" +
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      date.getDate().toString().padStart(2, "0") +
      "T" +
      date.getHours().toString().padStart(2, "0") +
      ":" +
      date.getMinutes().toString().padStart(2, "0")
    );
  };

  static dateToAnsiiDate = (parameter: Date | string | null | undefined) => {
    if (!parameter) {
      return "";
    }

    const date = new Date(parameter);

    return (
      date.getFullYear().toString().padStart(4, "0") +
      "-" +
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      date.getDate().toString().padStart(2, "0")
    );
  };

  static calculateDuration(startTime: Date, endTime: Date) {
    return Math.floor(
      Math.abs(new Date(endTime).getTime() - new Date(startTime).getTime()) /
        1000 /
        60
    );
  }

  static monthDifference(
    startDate: Date | undefined,
    endDate: Date | undefined
  ) {
    if (!startDate || !endDate) {
      return 0;
    }

    return (
      endDate.getMonth() -
      startDate.getMonth() +
      12 * (endDate.getFullYear() - startDate.getFullYear())
    );
  }

  static emailValidation(email: string) {
    const regex =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    return !email || regex.test(email) === false ? false : true;
  }

  static arrayAdd<T>(items: T[], newItem: T | undefined) {
    if (!newItem) {
      return items;
    }

    const itemWithId = newItem as any as itemWithId;
    const arrayItem = (items as any[] as itemWithId[]).filter(
      (x) => x.id === itemWithId.id
    )[0];
    if (arrayItem) {
      return items;
    }

    const idx = items.indexOf(newItem);
    if (idx !== -1) {
      return items;
    }

    return [...items, newItem];
  }

  static arrayRemove<T>(items: T[], item: T | undefined) {
    if (!item) {
      return items;
    }

    const itemWithId = item as any as itemWithId;
    const arrayItem = (items as any[] as itemWithId[]).filter(
      (x) => x.id === itemWithId.id
    )[0];
    if (!arrayItem) {
      return items;
    }

    const idx = (items as any[] as itemWithId[]).indexOf(arrayItem);
    if (idx === -1) {
      return items;
    }

    items.splice(idx, 1);
    return [...items];
  }

  static arrayStringAdd(items: string[], newItem: string | undefined) {
    if (!newItem) {
      return items;
    }

    const idx = items.indexOf(newItem);
    if (idx !== -1) {
      return items;
    }

    return [...items, newItem];
  }

  static arrayStringRemove(items: string[], item: string | undefined) {
    if (!item) {
      return items;
    }

    const idx = items.indexOf(item);
    if (idx === -1) {
      return items;
    }

    items.splice(idx, 1);
    return [...items];
  }

  static arrayCustomFieldAutocompleteDtoAdd(
    items: CustomFieldAutocompleteDto[],
    newItem: CustomFieldAutocompleteDto | undefined
  ) {
    if (!newItem) {
      return items;
    }

    const arrayItem = items.find((x) => x.code === newItem.code);
    if (arrayItem) {
      return items;
    }

    const idx = items.indexOf(newItem);
    if (idx !== -1) {
      return items;
    }

    return [...items, newItem];
  }

  static arrayCustomFieldAutocompleteDtoRemove(
    items: CustomFieldAutocompleteDto[],
    item: CustomFieldAutocompleteDto | undefined
  ) {
    if (!item) {
      return items;
    }

    const arrayItem = items.find((x) => x.code === item.code);
    if (!arrayItem) {
      return items;
    }

    const idx = items.indexOf(arrayItem);
    if (idx === -1) {
      return items;
    }

    items.splice(idx, 1);
    return [...items];
  }

  static getIdsFrom = (items: itemWithId[]) => {
    if (!items || items.length === 0) {
      return null;
    }

    return items.map((x) => x.id);
  };

  static getStringOrNull = (value: string | undefined | null) => {
    return value && value.trim().length > 0 ? value.trim() : null;
  };

  static imageStringToUrl = (image: string) => {
    return "data:image/png;base64," + image;
  };

  static getQueryParamValue = (queryString: string, paramName: string) => {
    const params = (
      queryString.startsWith("?") ? queryString.substring(1) : queryString
    ).split("&");

    const param = params.filter((x) =>
      x.toLocaleLowerCase().startsWith(paramName.toLocaleLowerCase())
    )[0];

    return !param || !param.includes("=") ? undefined : param.split("=")[1];
  };

  static secondsToTime = (seconds: number) => {
    const h = Math.floor(seconds / (60 * 60));
    const m = Math.floor((seconds - h * 60 * 60) / 60);
    const s = seconds - h * 60 * 60 - m * 60;

    return (
      (h > 0 ? `${h.toString().padStart(2, "0")} h ` : "") +
      (m > 0 ? `${m.toString().padStart(2, "0")} min ` : "") +
      (s > 0 ? `${s.toString().padStart(2, "0")} sec ` : "")
    );
  };

  static nextItemFromArray<T>(items: T[] | undefined, id: string) {
    if (!items || items.length <= 1) return undefined;

    const item = items.filter((x: any) => x.id === id)[0];
    if (!item) return undefined;

    const idx = items.indexOf(item);
    return idx >= 0 && idx + 1 < items.length
      ? items[idx + 1]
      : items[items.length - 2];
  }

  static getValidatedPage(page: PaginationDto, totalItems: number) {
    let p: PaginationDto = {
      ...page,
      totalItems: totalItems,
    };

    if (
      (p.currentPage - 1) * p.maxItemCount + 1 > p.totalItems &&
      p.totalItems > 0
    )
      p.currentPage = p.currentPage - 1;

    return p;
  }

  static ChangePagination(
    currentPage: PaginationDto,
    newPage: PageValues,
    set: (page: PaginationDto) => void
  ) {
    if (
      currentPage.currentPage === newPage.currentPage &&
      currentPage.totalItems === newPage.totalItems
    )
      return;

    let p: PaginationDto = {
      ...currentPage,
      currentPage: newPage.currentPage,
      totalItems: newPage.totalItems,
    };

    if (
      (p.currentPage - 1) * p.maxItemCount + 1 > p.totalItems &&
      p.totalItems > 0
    )
      p.currentPage = p.currentPage - 1;

    set(p);
  }

  static stringEqualUnSensitive(textA: string, textB: string) {
    if (!textA && !textB) return true;
    if (!textA || !textB) return false;

    return (
      textA.toLocaleUpperCase().trim() === textB.toLocaleLowerCase().trim()
    );
  }
}

export interface itemWithId {
  id: string;
}

interface PageValues {
  currentPage: number;
  totalItems: number;
}
