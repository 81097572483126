import { Theme } from "@mui/material";
import {
  ApiLoginCompleteDto,
  KemaroRobotDeploymentHistoryDto,
} from "../../../../../../../robotcloud-shared/resource-models";
import { TooltipContainer } from "../../../../../components/news";
import { GeneralFunctions } from "../../../../generalFunctions";

import { LocationIconDisplayer } from "./LocationIconDisplayer";
import { CustomerIconDisplayer } from "./CustomerIconDisplayer";
import { RowTextWtihTooltip } from "./RowTextWtihTooltip";

export const KemaroRobotDeploymentHistoryRow = (
  props: KemaroRobotDeploymentHistoryRowProps
) => (
  <tr>
    <td className={`p-0 company-name-td ${props.isChild ? "ps-3" : ""}`}>
      <LocationIconDisplayer
        isChild={props.isChild}
        isCurrentLocation={props.isCurrentLocation}
        theme={props.theme}
      />

      <CustomerIconDisplayer
        theme={props.theme}
        isCustomer={!props.isSummary && !props.isChild}
        isCurrentLocation={props.isCurrentLocation ?? false}
      />

      <RowTextWtihTooltip
        isSummary={props.isSummary ?? false}
        isExpanded={props.isExpanded ?? false}
        isChild={props.isChild ?? false}
        text={props.data.text}
      />
    </td>
    <td className="p-0 td-start-end-date align-bottom">
      {allowToViewDealerDates(props.isSummary ?? false, props.me) && (
        <TooltipContainer
          text={GeneralFunctions.dateToTimeDateString(props.data.startDate)}
          enterDelay={1000}
          leaveDelay={200}
        >
          <span>{GeneralFunctions.dateToDateString(props.data.startDate)}</span>
        </TooltipContainer>
      )}

      {props.data.endDate &&
        allowToViewDealerDates(props.isSummary ?? false, props.me) && (
          <>
            <span className="px-2">→</span>
            <TooltipContainer
              text={GeneralFunctions.dateToTimeDateString(props.data.endDate)}
              enterDelay={1000}
              leaveDelay={200}
            >
              <span>
                {GeneralFunctions.dateToDateString(props.data.endDate)}
              </span>
            </TooltipContainer>
          </>
        )}
    </td>
    <td className={props.isSummary ? "" : "empty-td"}></td>
  </tr>
);

function allowToViewDealerDates(isSummary: boolean, me: ApiLoginCompleteDto) {
  return !isSummary || me.userAccessType !== "Customer";
}

interface KemaroRobotDeploymentHistoryRowProps {
  data: KemaroRobotDeploymentHistoryDto;
  me: ApiLoginCompleteDto;
  isChild?: boolean;
  isSummary?: boolean;
  isExpanded?: boolean;
  theme: Theme;
  isCurrentLocation?: boolean;
}
