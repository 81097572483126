import { useState, useEffect } from "react";
import { CountryDto } from "../../../robotcloud-shared/resource-models";
import { ToastHelper } from "../components/news";
import { CountriesRequest } from "../data/countries.request";
import { ErrorHelper } from "../uiHelpers/errors.helper";

export const useCountries = () => {
  const [countries, setCountries] = useState<CountryDto[] | undefined>(
    undefined
  );

  const storageKey = "countries";

  useEffect(() => {
    const getList = async () => {
      try {
        const countriesStorage = sessionStorage.getItem(storageKey);
        if (!countriesStorage) {
          const result = await CountriesRequest.getList({
            textToSearch: null,
            id: null,
            maxResults: 10000,
          });

          setCountries(result.items);
          sessionStorage.setItem(storageKey, JSON.stringify(result.items));
        } else if (!countries || countries.length === 0) {
          setCountries(JSON.parse(countriesStorage));
        }
      } catch (error) {
        ToastHelper.errors(ErrorHelper.process(error));
      }
    };

    getList();
  }, [countries]);
  return { countries };
};
