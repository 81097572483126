import { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { MenuHelper, navigateRoutes } from "../../uiHelpers/menu.helper";
import AuthorizationContext from "../auth/authorizationContext";
import Logout from "@mui/icons-material/Logout";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Theme, useTheme } from "@mui/material/styles";
import { TooltipContainer } from "../../components/news/TooltipContainer";

var theme: Theme;

export const MainPanelMenu = (props: mainPanelMenuProps) => {
  const { i18n } = useTranslation();
  theme = useTheme();

  const [pathname, setPathname] = useState("");
  const [defaultOptions, setDefaultOptions] = useState<navigateRoutes[]>([]);
  const [userOptions, setUserOptions] = useState<navigateRoutes[]>([]);

  const { me } = useContext(AuthorizationContext);

  const location = useLocation();
  useEffect(() => {
    setPathname(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    setDefaultOptions(MenuHelper.getGroupOption(me, "default"));
    setUserOptions(
      MenuHelper.getGroupOption(me, "user").sort((a, b) =>
        a.menu!.order > b.menu!.order ? 1 : -1
      )
    );
  }, [me, i18n.language]);

  return (
    <div className="main-panel-menu">
      <div className="d-flex align-items-start flex-column h-100">
        <div className="mb-auto w-100">
          <ul className="mb-0 py-2">
            {userOptions.map((option) =>
              generateLi(pathname, option, props.isOpen)
            )}
          </ul>
        </div>

        <div className="w-100">
          <ul className="mb-0 py-2">
            {defaultOptions.map((option) =>
              generateLi(pathname, option, props.isOpen)
            )}

            {generateLogout(props.isOpen)}
          </ul>
        </div>
      </div>
    </div>
  );
};

interface mainPanelMenuProps {
  isOpen: boolean;
}

const isCurrentOption = (url: string, menuItem: navigateRoutes) => {
  return (
    url.startsWith(menuItem.url) ||
    menuItem.menu?.tabPaths?.some((x) => url.startsWith(x))
  );
};

const generateLi = (
  pathname: string,
  option: navigateRoutes,
  isOpen: boolean
) => {
  return (
    <li
      className={`px-3 py-2 ${
        isCurrentOption(pathname, option) ? "current-option" : ""
      }`}
      data-hover={
        isCurrentOption(pathname, option) ? "currentmenuitem" : "menuitem"
      }
      style={
        isCurrentOption(pathname, option)
          ? {
              backgroundColor:
                theme.palette.swarm?.mainPanel?.menuCurrentOptionBackground,
            }
          : {}
      }
      key={option.url}
    >
      <style>{`[data-hover=${
        isCurrentOption(pathname, option) ? "currentmenuitem" : "menuitem"
      }]:hover {
        background: ${
          theme.palette.swarm?.mainPanel?.[
            isCurrentOption(pathname, option)
              ? "menuCurrentOptionHoverBackground"
              : "menuLiHoverBackground"
          ]
        } !important;"}
    }`}</style>

      {option.url.trim().toLocaleLowerCase().startsWith("http") ? (
        <a href={option.url} rel="noreferrer" target="_blank">
          {isOpen ? (
            <table className="w-100">
              <tbody>
                <tr>
                  <td
                    className="li-icon"
                    style={{
                      color:
                        theme.palette.swarm?.mainPanel?.[
                          isCurrentOption(pathname, option)
                            ? "menuCurrentOptionLiIcon"
                            : "menuLiIcon"
                        ],
                    }}
                  >
                    {option.menu?.icon}
                  </td>
                  <td
                    className="pt-1"
                    style={{
                      color:
                        theme.palette.swarm?.mainPanel?.[
                          isCurrentOption(pathname, option)
                            ? "menuCurrentOptionA"
                            : "menuA"
                        ],
                    }}
                  >
                    {
                      i18n.t(
                        `sideMenu.${option.menu?.text
                          ?.toLowerCase()
                          .replace(/\s/g, "")}`
                      ) as string
                    }
                  </td>
                </tr>
              </tbody>
            </table>
          ) : (
            <span
              className="span-icon"
              style={{
                color:
                  theme.palette.swarm?.mainPanel?.[
                    isCurrentOption(pathname, option)
                      ? "menuCurrentOptionLiIcon"
                      : "menuLiIcon"
                  ],
              }}
            >
              <TooltipContainer
                text={
                  i18n.t(
                    `sideMenu.${option.menu?.text
                      ?.toLowerCase()
                      .replace(/\s/g, "")}`
                  ) as string
                }
                placement={"right-end"}
                enterDelay={1000}
                leaveDelay={200}
              >
                {option.menu?.icon ?? <></>}
              </TooltipContainer>
            </span>
          )}
        </a>
      ) : (
        <Link to={option.url}>
          {isOpen ? (
            <table className="w-100">
              <tbody>
                <tr>
                  <td
                    className="li-icon"
                    style={{
                      color:
                        theme.palette.swarm?.mainPanel?.[
                          isCurrentOption(pathname, option)
                            ? "menuCurrentOptionLiIcon"
                            : "menuLiIcon"
                        ],
                    }}
                  >
                    {option.menu?.icon}
                  </td>
                  <td
                    className="pt-1"
                    style={{
                      color:
                        theme.palette.swarm?.mainPanel?.[
                          isCurrentOption(pathname, option)
                            ? "menuCurrentOptionA"
                            : "menuA"
                        ],
                    }}
                  >
                    {
                      i18n.t(
                        `sideMenu.${option.menu?.text
                          ?.toLowerCase()
                          .replace(/\s/g, "")}`
                      ) as string
                    }
                  </td>
                </tr>
              </tbody>
            </table>
          ) : (
            <span
              className="span-icon"
              style={{
                color:
                  theme.palette.swarm?.mainPanel?.[
                    isCurrentOption(pathname, option)
                      ? "menuCurrentOptionLiIcon"
                      : "menuLiIcon"
                  ],
              }}
            >
              <TooltipContainer
                text={
                  i18n.t(
                    `sideMenu.${option.menu?.text
                      ?.toLowerCase()
                      .replace(/\s/g, "")}`
                  ) as string
                }
                placement={"right-end"}
                enterDelay={1000}
                leaveDelay={200}
              >
                {option.menu?.icon ?? <></>}
              </TooltipContainer>
            </span>
          )}
        </Link>
      )}
    </li>
  );
};

const generateLogout = (isOpen: boolean) => {
  return (
    <li className="px-3 py-2" data-hover="menuitem">
      <style>{`[data-hover="menuitem"]:hover {
        background: ${theme.palette.swarm?.mainPanel?.menuLiHoverBackground}
    }`}</style>
      <Link to="/logout">
        {isOpen ? (
          <table className="w-100">
            <tbody>
              <tr>
                <td
                  className="li-icon"
                  style={{
                    color: theme.palette.swarm?.mainPanel?.menuLiIcon,
                  }}
                >
                  <Logout />
                </td>
                <td
                  style={{
                    color: theme.palette.swarm?.mainPanel?.menuA,
                  }}
                >
                  {i18n.t("sideMenu.logout") as string}
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <span
            className="span-icon"
            style={{
              color: theme.palette.swarm?.mainPanel?.menuLiIcon,
            }}
          >
            <TooltipContainer
              text={i18n.t("sideMenu.logout") as string}
              placement={"right-end"}
              enterDelay={1000}
              leaveDelay={200}
            >
              <Logout />
            </TooltipContainer>
          </span>
        )}
      </Link>
    </li>
  );
};
