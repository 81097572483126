import useTheme from "@mui/material/styles/useTheme";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CustomFieldDto,
  CustomFieldTypeDto,
} from "../../../../robotcloud-shared/resource-models";
import {
  InputTextList,
  TextInput,
  ToastHelper,
  tableGenericHandleKeyboardSubjectManager,
} from "../../components/news";
import { Button, Dialog } from "@mui/material";
import { Loading } from "../../components/Loading";
import { SupRemark } from "../../uiHelpers/SupRemark";
import { GeneralFunctions } from "../generalFunctions";
import { ErrorData, ErrorHelper } from "../../uiHelpers/errors.helper";
import { CustomFieldRequests } from "../../data/customField.request";
import { CustomFieldContext } from "./CustomFieldContext";

export const CustomFieldModal = (props: CustomFieldModalProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const {
    setCurrentId,
    setCurrentItem,
    refreshList,
    refreshItem,
    currentType,
  } = useContext(CustomFieldContext);

  const [isWorking, setIsWorking] = useState(false);
  const [dto, setDto] = useState<CustomFieldDto>({
    ...customFieldDtoDefault,
  });
  const [customFieldCodeTexts, setCustomFieldCodeTexts] = useState<string[]>(
    []
  );

  useEffect(() => {
    tableGenericHandleKeyboard(true);
  }, []);

  useEffect(() => {
    updateDtoState(props.dto);
  }, [props.dto]);

  const updateDtoState = (dto: CustomFieldDto | undefined) => {
    if (dto) {
      setDto(dto);
      setCustomFieldCodeTexts([...dto.customFieldValues]);
    } else {
      setDto({ ...customFieldDtoDefault });
      setCustomFieldCodeTexts([]);
    }
  };

  const processError = (error: unknown) => {
    const errorData = (error as any).response.data.validations as ErrorData[];
    if (!errorData) {
      ToastHelper.errors(ErrorHelper.process(error));
      return;
    }

    const newErrors = errorData.map((x) => {
      if (!x.message.includes("|")) return x;

      const sections = x.message.split("|");

      return {
        property: t("customFields.explainedError.title", {
          code: sections[0],
        }),
        message: t("customFields.explainedError.message", {
          title: sections[1],
          type: currentType?.displayText,
        }),
      };
    });
    ToastHelper.errors(newErrors);
  };

  const handleSave = async () => {
    setIsWorking(true);

    try {
      if (props.dto) await modify();
      else await create();

      handleCancel();
    } catch (error) {
      processError(error);
    }

    setIsWorking(false);
  };

  const create = async () => {
    const postDto: CustomFieldDto = {
      ...dto,
      type: props.type.type,
      customFieldValues: customFieldCodeTexts,
    };

    const item = await CustomFieldRequests.create(postDto);

    setCurrentId(item.id);
    setCurrentItem(item);
    await refreshList();
  };

  const modify = async () => {
    const postDto: CustomFieldDto = {
      ...dto,
      type: props.type.type,
      customFieldValues: customFieldCodeTexts,
    };

    await CustomFieldRequests.modify(postDto);
    await refreshList();
    await refreshItem();
  };

  const handleCancel = () => {
    tableGenericHandleKeyboard(false);

    updateDtoState(props.dto);
    props.onCancel();
  };

  return (
    <Dialog
      className="application-modal"
      open={true}
      maxWidth="md"
      onClose={handleCancel}
    >
      <div className="modal-content">
        <div className="application-modal-header">
          <h3>{props.title}</h3>
        </div>
        {isWorking && (
          <div className="application-modal-body custom-fields-modal-body">
            <Loading isLoading={isWorking} />
          </div>
        )}
        {!isWorking && (
          <>
            <div className="application-modal-body custom-fields-modal-body">
              <div className="row">
                <div className="col-4 input-label">
                  {t("customFields.type")}
                </div>
                <div className="col-8 input-label pb-2">
                  {props.type.displayText}
                </div>

                <div className="col-4 input-label">
                  {t("customFields.title")}
                  <SupRemark />
                </div>
                <div className="col-8">
                  <TextInput
                    value={dto.title}
                    ret={(x) => setDto({ ...dto, title: x })}
                  />
                </div>

                <div className="col-4 input-label">
                  {t("customFields.description")}
                  <SupRemark />
                </div>
                <div className="col-8">
                  <TextInput
                    multiline={true}
                    value={dto.description}
                    ret={(x) => setDto({ ...dto, description: x })}
                  />
                </div>

                <div
                  className="col-12 pt-3 border-bottom"
                  style={{
                    borderBottom:
                      theme.palette.swarm?.customField?.modalBodyBorderBottom,
                  }}
                >
                  {t("customFields.codes")}
                  <SupRemark />
                </div>

                <div className="col-12">
                  <InputTextList
                    addToolTip={t("customFields.buttons.addErrorCode")}
                    removeTootTip={t("customFields.buttons.delErrorCode")}
                    texts={customFieldCodeTexts}
                    onChange={(texts) => setCustomFieldCodeTexts(texts)}
                  />
                </div>
              </div>
            </div>
            <div className="application-modal-footer">
              <Button
                variant="contained"
                className="button-with-text"
                disabled={
                  GeneralFunctions.isBlank(dto.title) ||
                  GeneralFunctions.isBlank(dto.description) ||
                  customFieldCodeTexts.length === 0
                }
                color="primary"
                onClick={handleSave}
              >
                {t("common.buttons.save")}
              </Button>

              <Button
                className="modal-cancel-button button-with-text"
                variant="outlined"
                color="primary"
                onClick={handleCancel}
              >
                {t("common.buttons.cancel")}
              </Button>
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
};

interface CustomFieldModalProps {
  type: CustomFieldTypeDto;
  title: string;
  onCancel: () => void;
  dto: CustomFieldDto | undefined;
}

const customFieldDtoDefault: CustomFieldDto = {
  id: "",
  title: "",
  description: "",
  customFieldValues: [],
  type: "MissionStartStopReason",
  typeText: "",
};

function tableGenericHandleKeyboard(value: boolean) {
  tableGenericHandleKeyboardSubjectManager.setData({
    caller: "CustomFieldModal",
    handleKeyboard: value,
  });
}
