import { ErrorResultDto } from "../../../robotcloud-shared/resource-models";

export class ErrorHelper {
  static process = (error: any) => {
    if (error.response) {
      if (error.response.data.validations) {
        return error.response.data.validations as ErrorData[];
      }

      if (error.response.data) {
        return processErrorData(error.response.data);
      }

      if (error.response.statusText) {
        const result: ErrorData[] = [
          {
            property: "",
            message: `${error.response.statusText}`,
          },
        ];

        return result;
      }

      const result: ErrorData[] = [
        {
          property: "",
          message: `${error.response}`,
        },
      ];

      return result;
    }

    if (error.message) {
      const result: ErrorData[] = [
        {
          property: "",
          message: `${error.message}`,
        },
      ];

      return result;
    }

    const result: ErrorData[] = [
      {
        property: "",
        message: "ERROR UNKNOWN",
      },
    ];

    return result;
  };
}

function processErrorData(data: any) {
  const result: ErrorData[] = [];

  if (data.status) {
    if (data.status === 401) {
      result.push({
        property: "",
        message: "Unauthorized",
      });
    } else if (data.status === 403) {
      result.push({
        property: "",
        message: "Forbidden",
      });
    }
  }

  if (data as ErrorResultDto) {
    const errorResultDto = data as ErrorResultDto;
    if (errorResultDto.detail && errorResultDto.detail.length > 0) {
      result.push({
        property: "",
        message: errorResultDto.detail,
      });
    }

    if (
      errorResultDto.innerMessages &&
      errorResultDto.innerMessages.length > 0
    ) {
      errorResultDto.innerMessages.map((x) =>
        result.push({
          property: "",
          message: `${x}`,
        })
      );
    }
  } else if ((data.innerMessages as string[]).length > 0) {
    (data.innerMessages as string[]).map((x) =>
      result.push({
        property: "",
        message: `${x}`,
      })
    );
  } else if (data.error) {
    result.push({
      property: "",
      message: data.error.message,
    });
  } else if (data.errors) {
    Object.keys(data.errors).forEach((prop) => {
      if (data.errors[prop] as string[]) {
        (data.errors[prop] as string[]).map((x) =>
          result.push({
            property: prop,
            message: `${x}`,
          })
        );
      }
    });
  } else {
    result.push({
      property: "",
      message: `${data}`,
    });
  }

  return result;
}

export interface ErrorData {
  property: string;
  message: string;
}
