import { KemaroRobotFilterCriteriaDto } from "../../../../../../robotcloud-shared/resource-models";
import { OrderDto, PaginationDto } from "../../../../data/common";
import { GeneralFunctions } from "../../../generalFunctions";
import { kemaroRobotsFilter } from "../Models";

export const filterToFilterCriteria = (
  filter: kemaroRobotsFilter,
  pagination: PaginationDto,
  order: OrderDto
): KemaroRobotFilterCriteriaDto => ({
  page: pagination.currentPage,
  maxItemCount: pagination.maxItemCount,
  orderBy: order.order,
  orderColumns: order.orderColumns,
  ids: filter.ids,
  serialNumbers: filter.serialNumbers,
  names: filter.names,
  robotModelIds: GeneralFunctions.getIdsFrom(filter.robotModels),
  dealerIds: GeneralFunctions.getIdsFrom(filter.dealers),
  customerIds: GeneralFunctions.getIdsFrom(filter.customers),
  customerContactIds: GeneralFunctions.getIdsFrom(filter.customerContacts),
  kemaroDashboardEconomyCostCalculationIds: GeneralFunctions.getIdsFrom(
    filter.kemaroDashboardEconomyCostCalculations
  ),
  textToSearch: filter.textToSearch,
  kemaroCompanyStructureIds: GeneralFunctions.getIdsFrom(
    filter.companyStructures
  ),
  status: filter.status,
  errorStatus: filter.errorStatus,
  deploymentStatus: filter.deploymentStatus,
  tags: filter.tags,
});
