import { KemaroDashboardEconomyCostCostDto } from "../../../../../../robotcloud-shared/resource-models";
import { ResponsivePie } from "@nivo/pie";
import { useTranslation } from "react-i18next";
import { TooltipContainer } from "../../../../components/news";
import useTheme from "@mui/material/styles/useTheme";

export interface kemaroDashboardEconomyCostChartAmortizationProps {
  data: KemaroDashboardEconomyCostCostDto;
}

export const KemaroDashboardEconomyCostChartAmortization = (
  props: kemaroDashboardEconomyCostChartAmortizationProps
) => {
  const { t } = useTranslation();

  const percentToUse = props.data.amortizationCurrentPercent / 100;
  const radius = percentToUse >= 1 ? 100 : percentToUse;

  const amotizationTimes =
    Math.round(props.data.amortizationCurrentPercent) / 100;

  const data = [
    {
      id: "c",
      label: "c",
      value: radius,
    },
    {
      id: "d",
      label: "c",
      value: 1 - radius,
    },
  ];

  const theme = useTheme();

  return (
    <div className="text-center">
      <div className="text-center pie-container">
        <div className="text-center pie-div">
          <ResponsivePie
            {...commonProperties}
            data={data}
            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
            innerRadius={0.9}
            fit={false}
            colors={[
              theme.palette.primary.main,
              theme.palette.swarm?.kemaroDashboardAmortization?.background!,
            ]}
            enableArcLinkLabels={false}
            enableArcLabels={false}
            isInteractive={false}
          />
        </div>

        <div className="pie-amotization">
          <p className="amortization-pie-inner-text">{amotizationTimes}</p>

          <div className="amortization-pie-inner-title pt-2">
            <TooltipContainer text={t("cost.roiTooltip")}>
              <span>{t("cost.roi")}</span>
            </TooltipContainer>
          </div>
        </div>
      </div>

      <div className="w-100 cost-efficiency-container">
        <div className="cost-efficiency">
          <TooltipContainer text={t("cost.costEfficiencyValueTooltip")}>
            <span>{props.data.costEfficiency}</span>
          </TooltipContainer>
        </div>
        <div className="footer-title">
          <TooltipContainer text={t("cost.costEfficiencyTooltip")}>
            <span>{t("cost.costEfficiency")}</span>
          </TooltipContainer>
        </div>
      </div>
    </div>
  );
};

const commonProperties = {
  height: 200,
  position: "absolute",
};
