import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import KemaroRobotContext from "../KemaroRobotContext";
import {
  TooltipButton,
  tableGenericHandleKeyboardSubjectManager,
} from "../../../../components/news";
import HistoryIcon from "@mui/icons-material/History";
import { KemaroRobotDeploymentHistoryModal } from "../Modals";

export const RobotDeploymentsHistoryButton = () => {
  const { t } = useTranslation();
  const { setViewSideModalOpenChange, currentItem } =
    useContext(KemaroRobotContext);

  const [openModal, setOpenModal] = useState(false);

  const openPopupChange = (value: boolean) => {
    tableGenericHandleKeyboardSubjectManager.setData({
      caller: "KemaroRobotNameModal",
      handleKeyboard: value,
    });

    setOpenModal(value);
    setViewSideModalOpenChange(value);
  };

  return (
    <>
      <TooltipButton
        aria-label={t("robotDeployment.button.tooltip")}
        tooltip={t("robotDeployment.button.tooltip")}
        onClick={() => openPopupChange(true)}
      >
        <HistoryIcon color="primary" />
      </TooltipButton>

      {openModal && (
        <KemaroRobotDeploymentHistoryModal
          kemaroRobotId={currentItem!.id!}
          onClose={() => openPopupChange(false)}
        />
      )}
    </>
  );
};
