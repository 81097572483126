import { useTranslation } from "react-i18next";
import {
  BadgeItem,
  BadgesPresenter,
  CrudItemsPanel,
  TableGeneric,
  applicationBarRef,
  tableGenericHandleKeyboardSubjectManager,
} from "../../../components/news";
import { useContext, useEffect, useRef, useState } from "react";
import AuthorizationContext from "../../auth/authorizationContext";
import { PermissionsHelper } from "../../../uiHelpers/permissionsHelper";
import { CsvButton } from "./Components";
import { columnsDefinition } from "./KemaroRobotsColumnsDefinition";
import KemaroRobotContext from "./KemaroRobotContext";
import {
  KemaroConfigurationDto,
  KemaroRobotFilterCriteriaDto,
  KemaroRobotPaginatedListDto,
} from "../../../../../robotcloud-shared/resource-models";
import { kemaroRobotsFilter } from "./Models";
import {
  filterToBadgeItems,
  filterToFilterCriteria,
  filterUpdate,
  processParameters,
} from "./Functions";
import { useSearchParams } from "react-router-dom";
import { KemaroRobotsLeftSideFooter } from "./KemaroRobotsLeftSideFooter";
import useTheme from "@mui/material/styles/useTheme";
import { KemaroRobotModal } from "./Modals/KemaroRobotModal";

export const KemaroRobotsLeftSide = (props: KemaroRobotsLeftSideProps) => {
  const { t } = useTranslation();
  const { me, configuration } = useContext(AuthorizationContext);
  const kemaroConfiguration = configuration as KemaroConfigurationDto;
  const {
    getById,
    pagination,
    setPagination,
    dataOrder,
    setDataOrder,
    getList,
    currentItem,
    currentItemIsFeching,
    setViewSideModalOpenChange,
  } = useContext(KemaroRobotContext);

  const [searchParams] = useSearchParams();
  const [isFirtTime, setIsFirtTime] = useState(true);

  const [isProcessParameters, setIsProcessParameters] = useState(true);
  const [lastSearch, setLastSearch] = useState("--");

  useEffect(() => {
    setCanAdd(PermissionsHelper.allow("KemaroRobots", "Create"));
    setCanGenerateCsv(
      PermissionsHelper.allow("KemaroRobots", "View", "DownloadCsv")
    );
  }, [me]);

  useEffect(() => {
    if (!isFirtTime) return;

    setIsFirtTime(false);
    processParameters(
      searchParams.get("idKemaroDashboardEconomyCostCalculation"),
      searchParams.get("idRobotModel"),
      searchParams.get("idCustomer"),
      searchParams.get("idDealer"),
      searchParams.get("idCompanyStructureBranch"),
      searchParams.get("id"),
      props.filter,
      props.setFilter,
      setIsProcessParameters
    );
  }, [searchParams, props, isFirtTime]);

  useEffect(() => {
    if (isProcessParameters) {
      return;
    }

    const conditions = filterToFilterCriteria(
      props.filter,
      pagination,
      dataOrder
    );

    const strConditions = JSON.stringify(conditions);

    if (lastSearch === strConditions) return;

    setLastSearch(strConditions);
    getList(conditions, pagination, currentItem, setPagination);
    setFilterBadgeItems(filterToBadgeItems(props.filter, kemaroConfiguration));
  }, [
    pagination,
    setPagination,
    dataOrder,
    props.filter,
    lastSearch,
    isProcessParameters,
    kemaroConfiguration,
    getList,
    currentItem,
  ]);

  const [canGenerateCsv, setCanGenerateCsv] = useState(false);
  const [canAdd, setCanAdd] = useState(false);

  const [openCreatePopup, setOpenCreatePopup] = useState(false);

  const refApplicationBar = useRef<applicationBarRef>(null);
  const [filterBadgeItems, setFilterBadgeItems] = useState<BadgeItem[]>([]);

  const onOpenCreatePopupChange = (value: boolean) => {
    setViewSideModalOpenChange(value);
    setOpenCreatePopup(value);
    tableGenericHandleKeyboardSubjectManager.setData({
      caller: "KemaroRobotModal",
      handleKeyboard: value,
    });
    props.setOpenCreatePopup(value);
  };

  const theme = useTheme();

  return (
    <div
      className="kemaro-robot-left-side"
      style={{
        borderRight: theme.palette.swarm?.kemaroRobots?.leftSideBorderRight,
      }}
    >
      {props.data && (
        <>
          <CrudItemsPanel
            title={t("robot.panelTitle")}
            titleRightSide={
              <>
                {canGenerateCsv && (
                  <CsvButton
                    filter={props.filterCriteria}
                    working={props.setIsFetchingList}
                  />
                )}

                <BadgesPresenter
                  className="mt-2"
                  items={filterBadgeItems}
                  itemClick={(x) =>
                    props.setFilter({
                      ...filterUpdate(props.filter, x, refApplicationBar),
                    })
                  }
                />
              </>
            }
            createToolTip={t("robot.create.tooltip")}
            containerClassName="kemaro-robot-list-items"
            isFetching={props.isFetchingList}
            items={props.data.items}
            canCreate={canAdd}
            onCreateClick={() => onOpenCreatePopupChange(true)}
          >
            <TableGeneric
              tableClassName=""
              showHeader={true}
              columns={columnsDefinition(
                props.data.errorSummaryTotalDaysToAnalyze,
                props.data.errorSummaryRecentDaysToAnalyze
              )}
              items={props.data.items ?? []}
              idProperty="id"
              headerClick={(header, itemsOrder) => setDataOrder(itemsOrder)}
              selectionChanged={(item) => getById(item.id)}
              itemsOrder={dataOrder}
              selectId={currentItem?.id}
              isAwaiting={currentItemIsFeching}
              captureKeyboard
            />
          </CrudItemsPanel>

          <KemaroRobotsLeftSideFooter isFetchingList={props.isFetchingList} />

          {openCreatePopup && (
            <KemaroRobotModal onClose={() => onOpenCreatePopupChange(false)} />
          )}
        </>
      )}
    </div>
  );
};

interface KemaroRobotsLeftSideProps {
  filterCriteria: KemaroRobotFilterCriteriaDto;
  filter: kemaroRobotsFilter;
  setFilter: (filter: kemaroRobotsFilter) => void;
  isFetchingList: boolean;
  setIsFetchingList: (isFetchingList: boolean) => void;
  data: KemaroRobotPaginatedListDto | undefined;
  setOpenCreatePopup: (open: boolean) => void;
}
