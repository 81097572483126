import {
  CompanyStructureAddressDto,
  CompanyStructureFilterCriteriaDto,
  CompanyStructureReducedDto,
  CompanyStructureReducedNodeDto,
  SearchTextDto,
  SearchTextResultDtoOfCompanyStructureReducedDto,
} from "../../../robotcloud-shared/resource-models";
import { GeneralFunctions } from "../views/generalFunctions";
import { AxiosRequests } from "./axios.requests";
import { BaseRequest } from "./BaseRequest";
import { prepareSearchTextDto } from "./functions";

export class CompanyStructureRequests extends BaseRequest {
  static controller = "/api/webapp/v1.0/companyStructure";

  static getSearchText = async (
    customerId: string | null,
    searchTextDto: SearchTextDto
  ) => {
    const routeParameter = GeneralFunctions.isBlank(customerId)
      ? "/search-text"
      : `/${customerId}/search-text`;

    const { data } = await AxiosRequests.post(
      `${CompanyStructureRequests.controller}${routeParameter}`,
      prepareSearchTextDto(searchTextDto)
    );
    return data as SearchTextResultDtoOfCompanyStructureReducedDto;
  };

  static getList = async (filter: CompanyStructureFilterCriteriaDto) => {
    const params = CompanyStructureRequests.objectToQueryString(filter);

    const { data } = await AxiosRequests.get(
      `${CompanyStructureRequests.controller}?${params}`
    );
    return data as CompanyStructureReducedDto[];
  };

  static getBranch = async (idCompanyStructure: string) => {
    const { data } = await AxiosRequests.get(
      `${CompanyStructureRequests.controller}/${idCompanyStructure}`
    );
    return data as CompanyStructureReducedDto[];
  };

  static getTreeSearchText = async (customerId: string) => {
    const { data } = await AxiosRequests.get(
      `${CompanyStructureRequests.controller}/${customerId}/structure`
    );
    return data as CompanyStructureReducedNodeDto[];
  };

  static getSortedList = async (customerId: string) => {
    const tree = await CompanyStructureRequests.getTreeSearchText(customerId);
    return CompanyStructureRequests.treeToList(tree);
  };

  static getCustomerRoot = async (customerId: string) => {
    const { data } = await AxiosRequests.get(
      `${CompanyStructureRequests.controller}/${customerId}/root`
    );
    return data as CompanyStructureReducedDto;
  };

  private static treeToList = (nodes: CompanyStructureReducedNodeDto[]) => {
    let nodeList: CompanyStructureReducedNodeDto[] = [];

    nodes.forEach((x) => {
      nodeList.push(x);
      if (x.childNodes.length > 0)
        nodeList = nodeList.concat(
          CompanyStructureRequests.treeToList(x.childNodes)
        );
    });

    return nodeList;
  };

  static upsertAddress = async (
    companyStructureId: string,
    dto: CompanyStructureAddressDto
  ) => {
    return await AxiosRequests.put(
      `${CompanyStructureRequests.controller}/${companyStructureId}/address`,
      dto
    );
  };

  static deleteAddress = async (companyStructureId: string) => {
    return await AxiosRequests.delete(
      `${CompanyStructureRequests.controller}/${companyStructureId}/address`
    );
  };
}
