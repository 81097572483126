import { BadgeItem, applicationBarRef } from "../../../../components/news";
import { GeneralFunctions } from "../../../generalFunctions";
import { kemaroRobotsFilter } from "../Models";

export const filterUpdate = (
  filter: kemaroRobotsFilter,
  item: BadgeItem,
  refAapplicationBar: React.RefObject<applicationBarRef>
) => {
  if (item.type === "serialNumber") {
    GeneralFunctions.arrayStringRemove(
      filter.serialNumbers,
      filter.serialNumbers.filter((x) => x === (item.id as string))[0]
    );
  }

  if (item.type === "name") {
    GeneralFunctions.arrayStringRemove(
      filter.names,
      filter.names.filter((x) => x === (item.id as string))[0]
    );
  }

  if (item.type === "companyStructure") {
    GeneralFunctions.arrayRemove(
      filter.companyStructures,
      filter.companyStructures.filter((x) => x.id === item.id)[0]
    );
  }

  if (item.type === "robotModel") {
    GeneralFunctions.arrayRemove(
      filter.robotModels,
      filter.robotModels.filter((x) => x.id === item.id)[0]
    );
  }

  if (item.type === "dealer") {
    GeneralFunctions.arrayRemove(
      filter.dealers,
      filter.dealers.filter((x) => x.id === item.id)[0]
    );
  }

  if (item.type === "customer") {
    GeneralFunctions.arrayRemove(
      filter.customers,
      filter.customers.filter((x) => x.id === item.id)[0]
    );
  }

  if (item.type === "customerContact") {
    GeneralFunctions.arrayRemove(
      filter.customerContacts,
      filter.customerContacts.filter((x) => x.id === item.id)[0]
    );
  }

  if (item.type === "kemaroDashboardEconomyCostCalculations") {
    GeneralFunctions.arrayRemove(
      filter.kemaroDashboardEconomyCostCalculations,
      filter.kemaroDashboardEconomyCostCalculations.filter(
        (x) => x.id === item.id
      )[0]
    );
  }

  if (item.type === "textToSearch") {
    filter.textToSearch = "";
    if (refAapplicationBar.current) {
      refAapplicationBar.current.reset();
    }
  }

  if (item.type === "status") {
    filter.status = "All";
  }

  if (item.type === "errorStatus") {
    filter.errorStatus = "All";
  }

  if (item.type === "deploymentStatus") {
    filter.deploymentStatus = "All";
  }

  if (item.type === "tag") {
    GeneralFunctions.arrayRemove(
      filter.tags,
      filter.tags.filter((x) => x === item.id)[0]
    );
  }

  return filter;
};
