import { diff, jsonPatchPathConverter } from "just-diff";
import {
  EncodedFileDto,
  KemaroRobotCleanedSurfaceChartDto,
  KemaroRobotCleaningDataTotalsDtoType,
  KemaroRobotCompleteDto,
  KemaroRobotConnectionDto,
  KemaroRobotCredentialsConfigDto,
  KemaroRobotCredentialsDto,
  KemaroRobotCrudDto,
  KemaroRobotDto,
  KemaroRobotEconomyDashBoardListDto,
  KemaroRobotFilterCriteriaDto,
  KemaroRobotPaginatedListDto,
  KemaroRobotPostDto,
  KemaroRobotSearchDto,
  KemaroRobotsCleanedSurfaceChartFiltersDto,
  SearchTextDto,
  SearchTextDtoOfString,
  SearchTextResultDtoOfKemaroRobotListDto,
  SearchTextResultDtoOfString,
} from "../../../../robotcloud-shared/resource-models";
import { GeneralFunctions } from "../../views/generalFunctions";
import { AxiosRequests } from "../axios.requests";
import { BaseRequest } from "../BaseRequest";
import { prepareSearchTextDto } from "../functions";

export class KemaroRobotRequests extends BaseRequest {
  static controller = "api/webapp/v1.0/kemaro/robots";

  static create = async (postDto: KemaroRobotPostDto) => {
    const { data } = await AxiosRequests.post(
      `${KemaroRobotRequests.controller}`,
      postDto
    );
    return data as KemaroRobotDto;
  };

  static getList = async (filterCriteria: KemaroRobotFilterCriteriaDto) => {
    const url = `${
      KemaroRobotRequests.controller
    }?${KemaroRobotRequests.objectToQueryString(filterCriteria)}`;

    const { data } = await AxiosRequests.get(url);
    return data as KemaroRobotPaginatedListDto;
  };

  static getById = async (id: string) => {
    const { data } = await AxiosRequests.get(
      `${KemaroRobotRequests.controller}/${id}`
    );
    return data as KemaroRobotCrudDto;
  };

  static getConnectionParameters = async (id: string) => {
    const { data } = await AxiosRequests.get(
      `${KemaroRobotRequests.controller}/${id}/connection-parameters`
    );
    return data as KemaroRobotConnectionDto;
  };

  static editName = async (robotDto: KemaroRobotCrudDto, name: string) => {
    await AxiosRequests.put(
      `${KemaroRobotRequests.controller}/${robotDto.id}/name/${name}`
    );
  };

  static modify = async (
    originalDto: KemaroRobotCrudDto,
    newDto: KemaroRobotCrudDto,
    migrateAllMissions: boolean,
    migrateNewerMissions: boolean,
    migrateNewerFromMissions: Date | null
  ) => {
    const allowProperties = [
      "/name",
      "/offsetCleanedSurface",
      "/offsetWorkedMinutes",
      "/idRobotModel",
      "/idDealer",
      "/idCustomer",
      "/idCompanyStructure",
    ];

    const jsonPatch = diff(originalDto, newDto, jsonPatchPathConverter).filter(
      (x) => allowProperties.includes(x.path)
    );

    let urlToCall = `${KemaroRobotRequests.controller}/${originalDto.id}?`;

    if (migrateAllMissions) {
      urlToCall += "migrateAllMissions=true";
    } else if (migrateNewerMissions) {
      urlToCall +=
        "migrateNewerMissions=true&migrateNewerFromMissions=" +
        GeneralFunctions.dateToAnsiiDate(migrateNewerFromMissions);
    }

    const { data } = await AxiosRequests.patch(urlToCall, jsonPatch);

    return data as KemaroRobotCompleteDto;
  };

  static delete = async (id: string) => {
    await AxiosRequests.delete(`${KemaroRobotRequests.controller}/${id}/soft`);
  };

  static getSearchText = async (
    customerId: string | null,
    searchTextDto: KemaroRobotSearchDto
  ) => {
    const routeParameter = customerId
      ? `/${customerId}/search-text`
      : "/search-text";

    const { data } = await AxiosRequests.post(
      `${KemaroRobotRequests.controller}${routeParameter}`,
      prepareSearchTextDto(searchTextDto)
    );
    return data as SearchTextResultDtoOfKemaroRobotListDto;
  };

  static getSearchTextSerialNumbers = async (
    customerId: string | null,
    searchTextDto: SearchTextDtoOfString
  ) => {
    const routeParameter = customerId
      ? `/${customerId}/search-serial-numbers`
      : "/search-serial-numbers";

    const { data } = await AxiosRequests.post(
      `${KemaroRobotRequests.controller}${routeParameter}`,
      prepareSearchTextDto(searchTextDto)
    );
    return data as SearchTextResultDtoOfString;
  };

  static getSearchTextNames = async (
    customerId: string | null,
    searchTextDto: SearchTextDtoOfString
  ) => {
    const routeParameter = customerId
      ? `/${customerId}/search-names`
      : "/search-names";

    const { data } = await AxiosRequests.post(
      `${KemaroRobotRequests.controller}${routeParameter}`,
      prepareSearchTextDto(searchTextDto)
    );
    return data as SearchTextResultDtoOfString;
  };

  static getSearchTextLocations = async (
    customerId: string | null,
    searchTextDto: SearchTextDto
  ) => {
    const params = KemaroRobotRequests.objectToQueryString(searchTextDto);
    const routeParameter = customerId
      ? `/${customerId}/search-locations`
      : "/search-locations";

    const { data } = await AxiosRequests.get(
      `${KemaroRobotRequests.controller}${routeParameter}?${params}`
    );
    return data as SearchTextResultDtoOfString;
  };

  static cleanedSurfaceChartYears = async (
    id: string,
    totalType: KemaroRobotCleaningDataTotalsDtoType
  ) => {
    const { data } = await AxiosRequests.get(
      `${KemaroRobotRequests.controller}/${id}/cleaned-surface-chart-years/${totalType}`
    );
    return data as KemaroRobotsCleanedSurfaceChartFiltersDto;
  };

  static cleanedSurfaceChart = async (
    id: string,
    year: number,
    totalType: KemaroRobotCleaningDataTotalsDtoType
  ) => {
    const { data } = await AxiosRequests.get(
      `${KemaroRobotRequests.controller}/${id}/cleaned-surface-chart/${year}/${totalType}`
    );
    return data as KemaroRobotCleanedSurfaceChartDto;
  };

  static getFromCalculation = async (id: string) => {
    const { data } = await AxiosRequests.get(
      `${KemaroRobotRequests.controller}/list-from-calculation/${id}`
    );
    return data as KemaroRobotEconomyDashBoardListDto[];
  };

  static putHeartbeat = async (id: string) => {
    await AxiosRequests.put(
      `${KemaroRobotRequests.controller}/${id}/heartbeat`
    );
  };

  static installationDateAdd = async (id: string, when: Date) => {
    await AxiosRequests.put(
      `${
        KemaroRobotRequests.controller
      }/installation-date/${id}/${GeneralFunctions.dateToAnsiiDate(when)}`
    );
  };

  static installationDateRemove = async (id: string) => {
    await AxiosRequests.delete(
      `${KemaroRobotRequests.controller}/installation-date/${id}`
    );
  };

  static csv = async (filterCriteria: KemaroRobotFilterCriteriaDto) => {
    const url = `${
      KemaroRobotRequests.controller
    }/csv?${KemaroRobotRequests.objectToQueryString(filterCriteria)}`;

    const { data } = await AxiosRequests.get(url);
    return data as EncodedFileDto;
  };

  static tags = async (robotId: string, tags: string[]) => {
    const url = `${KemaroRobotRequests.controller}/${robotId}/tags`;
    await AxiosRequests.put(url, tags);
  };

  static getTagList = async () => {
    const url = `${KemaroRobotRequests.controller}/tags-list`;

    const { data } = await AxiosRequests.get(url);
    return data as string[];
  };

  static getLocalCredentialConfig = async () => {
    const { data } = await AxiosRequests.get(
      `${KemaroRobotRequests.controller}/local-credential-config`
    );
    return data as KemaroRobotCredentialsConfigDto[];
  };

  static getLocalCredential = async (id: string) => {
    const { data } = await AxiosRequests.get(
      `${KemaroRobotRequests.controller}/${id}/local-credential`
    );
    return data as KemaroRobotCredentialsDto[];
  };

  static putLocalCredential = async (
    id: string,
    credentials: KemaroRobotCredentialsDto[]
  ) => {
    await AxiosRequests.put(
      `${KemaroRobotRequests.controller}/${id}/local-credential`,
      credentials
    );
  };
}
