import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { TimezoneReducedDto } from "../../../../robotcloud-shared/resource-models";
import { ErrorHelper } from "../../uiHelpers/errors.helper";
import { TimeZonesRequest } from "../../data/timeZones.request";
import { ToastHelper } from "../news/Toast.helper";
import { useTranslation } from "react-i18next";

export const TimeZoneSearch = (props: windowsTimeZoneSearchProps) => {
  const { t } = useTranslation();

  const [items, setItems] = useState<TimezoneReducedDto[] | undefined>(
    undefined
  );
  const [currentItem, setCurrentItem] = useState<
    TimezoneReducedDto | undefined
  >(undefined);

  useEffect(() => {
    const getList = async () => {
      try {
        const result = await TimeZonesRequest.getList();
        setItems(result);
      } catch (error) {
        ToastHelper.errors(ErrorHelper.process(error));
      }
    };

    getList();
  }, []);

  if (!items) {
    return <></>;
  }

  if (props.currentId) {
    const newCurrentItem = items.find(
      (x) => x.timeZoneInfoId === props.currentId
    );
    if (newCurrentItem?.timeZoneInfoId !== currentItem?.timeZoneInfoId) {
      setCurrentItem(newCurrentItem);
    }
  }

  const selectionChange = (newValue: string | null) => {
    if (!newValue) {
      setCurrentItem(undefined);
      props.selectionChange(undefined);
      return;
    }

    const selected = items.find((x) => x.displayName === newValue);
    if (!props.clearAfterSelect) {
      setCurrentItem(selected);
    }

    props.selectionChange(selected);
    if (props.clearAfterSelect) {
      setCurrentItem(undefined);
    }
  };

  return (
    <Autocomplete
      disableCloseOnSelect={props.clearAfterSelect}
      size="small"
      freeSolo
      value={currentItem?.displayName ?? ""}
      onChange={(event, newValue) => selectionChange(newValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          label=""
          placeholder={t("common.typeToSearch")}
          fullWidth
          variant="standard"
          margin="dense"
          hiddenLabel
        />
      )}
      options={items.map((x) => x.displayName)}
    />
  );
};

interface windowsTimeZoneSearchProps {
  currentId?: string;
  clearAfterSelect?: boolean;
  selectionChange: (select: TimezoneReducedDto | undefined) => void;
}
