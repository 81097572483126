import { useTranslation } from "react-i18next";
import KemaroRobotContext from "../KemaroRobotContext";
import { useContext } from "react";
import { ToastHelper, TooltipButton } from "../../../../components/news";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import { KemaroRobotRequests } from "../../../../data/kemaro/kemaroRobot.request";
import { ErrorHelper } from "../../../../uiHelpers/errors.helper";

export const HeartbeatButton = () => {
  const { t } = useTranslation();
  const { currentItem, refreshItems } = useContext(KemaroRobotContext);

  const heartbeatHandle = async () => {
    let result = false;

    try {
      await KemaroRobotRequests.putHeartbeat(currentItem!.id!);
      refreshItems(currentItem!.id);
      result = true;
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    return result;
  };

  return (
    <TooltipButton
      aria-label="DEV: heartbeat"
      tooltip={t("robot.heartbeat.tooltip")}
      onClick={heartbeatHandle}
    >
      <FavoriteBorderOutlinedIcon
        className="earlybyte-pink-color"
        sx={{ color: "swarm.general.earlybytePinkColor" }}
      />
    </TooltipButton>
  );
};
