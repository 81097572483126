import { Button } from "@mui/material";
import { useRef } from "react";
import placeholderImage from "../../../../assets/Placeholder_view.png";
import CloudUploadRoundedIcon from "@mui/icons-material/CloudUploadRounded";
import { useTranslation } from "react-i18next";

export const KemaroMissionImage = (props: KemaroMissionImageProps) => {
  const { t } = useTranslation();

  const hiddenFileInput = useRef(null);

  const readFile = (event: any) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      const fileContent = e.target!.result;
      props.setImage(!fileContent ? "" : fileContent.toString());
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  return (
    <div className="row mb-3">
      <div className="col-12 map-container">
        <input
          type="file"
          name="myFile"
          style={{ display: "none" }}
          ref={hiddenFileInput}
          onChange={readFile}
        />

        <div className="align-self-center text-center">
          <img
            className="img-fluid img-map"
            src={props.image ?? placeholderImage}
            alt="Mission map"
          />
        </div>
      </div>

      <div className="col-12">
        <Button
          className="w-100 text-center mt-2"
          variant="outlined"
          color="primary"
          onClick={() => (hiddenFileInput.current! as any).click()}
        >
          <CloudUploadRoundedIcon color="primary" />
          <span className="ms-3">{t("mission.add.chooseImage")}</span>
        </Button>
      </div>
    </div>
  );
};

interface KemaroMissionImageProps {
  image: string | undefined;
  setImage: (image: string) => void;
}
