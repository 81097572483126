import { Autocomplete, TextField } from "@mui/material";
import { t } from "i18next";
import { useState } from "react";
import { Language } from "../../../../robotcloud-shared/resource-models";
import {
  getOnboardingLanguageOrderedByName,
  OnBoardingLanguage,
  onBoardingLanguages,
} from "../../views/onBoarding/onBoardingService";

export const LanguageSearch = (props: languageSearchProps) => {
  const selectionChange = (newValue: string | null) => {
    if (!newValue) {
      props.selectionChange(undefined);
      return;
    }

    const selected = onBoardingLanguages.find((x) => x.name === newValue);
    props.selectionChange(selected?.code);
  };

  const [orderedLanguages] = useState<OnBoardingLanguage[]>(
    getOnboardingLanguageOrderedByName()
  );

  const getLanguageNameFromCode = (code?: string) => {
    if (!code) return undefined;
    const language = orderedLanguages.find((x) => x.code === code);
    if (language) {
      return language.name;
    } else {
      return undefined;
    }
  };

  const [language] = useState<string | undefined>(
    getLanguageNameFromCode(props.current)
  );

  return (
    <Autocomplete
      disableCloseOnSelect={props.clearAfterSelect}
      size="small"
      freeSolo
      value={language ?? "English"}
      onChange={(event, newValue) => {
        if (newValue) {
          selectionChange(newValue);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label=""
          fullWidth
          variant="standard"
          margin="dense"
          hiddenLabel
          onFocus={(e) => {
            if (e.target && e.target instanceof HTMLInputElement) {
              e.target.select();
            }
          }}
          onClick={(e) => {
            if (e.target && e.target instanceof HTMLInputElement) {
              (e.target as HTMLInputElement).select();
            }
          }}
        />
      )}
      options={orderedLanguages.map((x) => x.name)}
    />
  );
};

interface languageSearchProps {
  current?: string;
  clearAfterSelect?: boolean;
  selectionChange: (select: string | undefined) => void;
}

export interface SearchTextResultDtoOfLanguage {
  items: Language[];
  searchedText: string | null;
  totalItems: number;
}
