import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Link } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import AuthorizationContext from "../../auth/authorizationContext";
import { PermissionsHelper } from "../../../uiHelpers/permissionsHelper";
import { useTranslation } from "react-i18next";

export const KemaroAdministrationTabsNav = (
  props: kemaroAdministrationTabsNavProps
) => {
  const { t } = useTranslation();

  const [isPermissions, setIsPermissions] = useState(true);
  const [isBoarding, setIsBoarding] = useState(true);
  const { isKemaroAcademySection, isManufacturerAdmin } =
    useContext(AuthorizationContext);

  useEffect(() => {
    setIsPermissions(PermissionsHelper.allow("Permissions", "View"));
    setIsBoarding(PermissionsHelper.allow("OnBoarding", "View"));
  }, []);

  return (
    <div className="tabs-nav-container">
      <Tabs value={props.currentTab}>
        <Tab
          label={t("applicationBar.administration.tabs.cost")}
          value="kemaro-cost-calculation"
          to="/kemaro-cost-calculation"
          component={Link}
        />
        <Tab
          label={t("applicationBar.administration.tabs.customFields")}
          value="custom-fields"
          to="/custom-fields"
          component={Link}
        />
        <Tab
          label={t("applicationBar.administration.tabs.robotModels")}
          value="robot-models"
          to="/robot-models"
          component={Link}
        />

        {isPermissions && (
          <Tab
            label={t("applicationBar.administration.tabs.permissions")}
            value="permissions"
            to="/permissions"
            component={Link}
          />
        )}

        {isKemaroAcademySection && isManufacturerAdmin ? (
          <Tab
            label={t("applicationBar.administration.tabs.kemaroAcademyLog")}
            value="kemaro-academy-log"
            to="/kemaro-academy-log"
            component={Link}
          />
        ) : (
          <Tab label="" value="kemaro-academy-log" to="" component={Link} />
        )}

        {isBoarding && isManufacturerAdmin ? (
          <Tab
            label={t("applicationBar.administration.tabs.onBoarding")}
            value="on-boarding"
            to="/on-boarding"
            component={Link}
          />
        ) : (
          <Tab label="" value="on-boarding" to="" component={Link} />
        )}
      </Tabs>
    </div>
  );
};

interface kemaroAdministrationTabsNavProps {
  currentTab:
    | "kemaro-cost-calculation"
    | "error-codes"
    | "robot-models"
    | "cleaning-modes"
    | "permissions"
    | "kemaro-academy-log"
    | "on-boarding"
    | "custom-fields";
}
