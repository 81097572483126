import { AxiosRequests } from "./axios.requests";
import { diff, jsonPatchPathConverter } from "just-diff";
import {
  DealerContactDto,
  DealerContactPostDto,
  DealerContactReducedDto,
  SearchTextDtoOfGuid,
  SearchTextResultDtoOfDealerContactDto,
} from "../../../robotcloud-shared/resource-models";
import { BaseRequest } from "./BaseRequest";
import { prepareSearchTextDto } from "./functions";

export class DealersContactsRequests extends BaseRequest {
  static controller = "api/webapp/v1.0/Dealers/contacts";

  static create = async (postDto: DealerContactPostDto) => {
    const { data } = await AxiosRequests.post(
      DealersContactsRequests.controller,
      postDto
    );
    return data as DealerContactDto;
  };

  static modify = async (
    original: DealerContactReducedDto,
    modified: DealerContactReducedDto
  ) => {
    const allowProperties = [
      "/firstName",
      "/sureName",
      "/address",
      "/zipCode",
      "/city",
      "/phone",
      "/email",
      "/idCountry",
      "/description",
    ];

    const jsonPatch = diff(original, modified, jsonPatchPathConverter).filter(
      (x) => allowProperties.includes(x.path)
    );

    const { data } = await AxiosRequests.patch(
      `${DealersContactsRequests.controller}/${original.id}`,
      jsonPatch
    );
    return data as DealerContactDto;
  };

  static delete = async (id: string) => {
    return await AxiosRequests.delete(
      `${DealersContactsRequests.controller}/${id}`
    );
  };

  static getFromDealer = async (dealerId: string) => {
    const { data } = await AxiosRequests.get(
      `${DealersContactsRequests.controller}/from-dealer/${dealerId}`
    );
    return data as DealerContactReducedDto[];
  };

  static getSearchText = async (
    dealerId: string | null,
    searchTextDto: SearchTextDtoOfGuid
  ) => {
    if (!dealerId) {
      const result: SearchTextResultDtoOfDealerContactDto = {
        items: [],
        searchedText: null,
        totalItems: 0,
      };

      return result;
    }

    const routeParameter = dealerId
      ? `/${dealerId}/search-text`
      : "/search-text";

    const { data } = await AxiosRequests.post(
      `${DealersContactsRequests.controller}${routeParameter}`,
      prepareSearchTextDto(searchTextDto)
    );
    return data as SearchTextResultDtoOfDealerContactDto;
  };
}
