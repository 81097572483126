import { ReactElement } from "react";
import Edit from "@mui/icons-material/Edit";
import DeleteForever from "@mui/icons-material/DeleteForever";
import "./viewHeader.scss";
import { TooltipButton } from "./TooltipButton";
import { ShareIdButton } from "./ShareIdButton";
import { useTranslation } from "react-i18next";

export const ViewHeader = (props: viewHeaderProps) => {
  const { t } = useTranslation();

  return (
    <div className={`view-header ${props.className ?? "px-0"}`}>
      <table className="w-100">
        <tbody>
          <tr>
            {props.titleIcon && <td className="w-0 pe-2">{props.titleIcon}</td>}

            <td className="w-0">
              <span className={"title"}>{props.title}</span>
            </td>
            <td className="w-0 no-wrap">
              {props.copyLinkId && <ShareIdButton id={props.copyLinkId} />}

              {props.canEdit && (
                <TooltipButton
                  aria-label="edit"
                  tooltip={t("viewHeaderButtons.edit")}
                  onClick={props.onEdit}
                >
                  <Edit color="primary" />
                </TooltipButton>
              )}

              {props.canDelete && (
                <TooltipButton
                  aria-label="delete"
                  tooltip={t("viewHeaderButtons.delete")}
                  onClick={props.onDelete}
                >
                  <DeleteForever color="error" />
                </TooltipButton>
              )}
            </td>
            <td>{props.children}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export interface viewHeaderProps {
  title: string;
  titleIcon?: ReactElement | never[] | null;
  copyLinkId?: string;
  canEdit?: boolean;
  canDelete?: boolean;
  canDemo?: boolean;
  children: ReactElement | never[] | null;
  onEdit?: () => void;
  onDelete?: () => void;
  onDemo?: () => void;
  className?: string;
}

ViewHeader.defaultProps = {
  canEdit: true,
  canDelete: true,
  children: null,
  titleIcon: null,
};
