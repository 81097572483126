import {
  KemaroCompanyStructureDto,
  KemaroCompanyStructurePostDto,
} from "../../../../../robotcloud-shared/resource-models";
import { TooltipContainer, TreeVew } from "../../../components/news";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import { ManufacturersType } from "../../../uiHelpers/manufacturersType";
import { CompanyStructureKemaroModal } from "./CompanyStructureKemaroModal";
import { CompanyStructure } from "./CompanyStructureView";
import { useTranslation } from "react-i18next";

export const CompanyStructureTreeView = (
  props: CompanyStructureTreeViewProps
) => {
  const { t } = useTranslation();

  return (
    <>
      <TreeVew
        idProperty="id"
        textProperty="name"
        childNodesProperty="childNodes"
        items={props.kemaroCompanyStructure}
        nodeClick={(x) => props.onSelect(x)}
        template={(item: KemaroCompanyStructureDto) => (
          <table className="table table-borderless mb-0">
            <tbody>
              <tr>
                <td className="p-0 pe-2">{item.name}</td>
                {(item.robotsInNode > 0 || item.robotsInBranch > 0) && (
                  <td className="p-0 icon-td align-middle">
                    {item.robotsInNode > 0 && (
                      <TooltipContainer text={props.toolTip(item)}>
                        <PrecisionManufacturingIcon color="primary" />
                      </TooltipContainer>
                    )}
                    {item.robotsInNode === 0 && item.robotsInBranch > 0 && (
                      <TooltipContainer text={props.toolTip(item)}>
                        <PrecisionManufacturingIcon color="primary" />
                      </TooltipContainer>
                    )}
                  </td>
                )}

                {item.hasInformation !== "No" && (
                  <td className="p-0 icon-td align-middle">
                    {item.hasInformation === "Yes" && (
                      <TooltipContainer text={t("company.configOnLocation")}>
                        <InfoOutlinedIcon
                          className="current-node-icon ms-1"
                          sx={{
                            color: "swarm.companyStructureView.currentNodeIcon",
                          }}
                        />
                      </TooltipContainer>
                    )}
                    {item.hasInformation === "Inherited" && (
                      <TooltipContainer text={t("company.configInSublocation")}>
                        <InfoOutlinedIcon
                          className="current-node-inherited ms-1"
                          sx={{
                            color:
                              "swarm.companyStructureView.currentNodeInherited",
                          }}
                        />
                      </TooltipContainer>
                    )}
                  </td>
                )}
              </tr>
            </tbody>
          </table>
        )}
      />

      {props.manufacturer === "kemaro" && props.modalOpen && (
        <CompanyStructureKemaroModal
          modalOpen={props.modalOpen}
          modalWorking={props.modalWorking}
          idCompany={props.idCompany}
          nodeList={props.nodeList as KemaroCompanyStructureDto[]}
          kemaroCompanyStructure={undefined}
          onCancel={props.onCancel}
          onCreate={props.onCreate}
        />
      )}
    </>
  );
};

interface CompanyStructureTreeViewProps {
  idCompany: string;
  kemaroCompanyStructure: KemaroCompanyStructureDto[];
  kemaroCompanyStructureCurrent: KemaroCompanyStructureDto | undefined;
  manufacturer: ManufacturersType;
  nodeList: CompanyStructure[];
  modalOpen: boolean;
  modalWorking: boolean;
  onSelect: (item: KemaroCompanyStructureDto | undefined) => void;
  toolTip: (item: KemaroCompanyStructureDto) => string;
  onCreate: (dto: KemaroCompanyStructurePostDto) => Promise<boolean>;
  onCancel: () => void;
}
