import { KemaroConfigurationDto } from "../../../../robotcloud-shared/resource-models";
import { AxiosRequests } from "../axios.requests";
import { BaseRequest } from "../BaseRequest";

export class KemaroManufacturersRequests extends BaseRequest {
  static controller = "api/webapp/v1.0/Kemaro/Manufacturers";

  static getConfiguration = async () => {
    const { data } = await AxiosRequests.get(
      `${KemaroManufacturersRequests.controller}/configuration`
    );
    return data as KemaroConfigurationDto;
  };
}
