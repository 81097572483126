import { ReactElement } from "react";
import { SupRemark } from "../../../../uiHelpers/SupRemark";

export const KemaroMissionsRunInputData = ({
  title,
  isMandatory,
  children,
}: KemaroMissionsRunInputDataProps) => (
  <>
    <div className="col-6 input-label">
      {title}
      {isMandatory && <SupRemark />}
    </div>
    <div className="col-6">{children}</div>
  </>
);

interface KemaroMissionsRunInputDataProps {
  title: string;
  isMandatory?: boolean | undefined | null;
  children: ReactElement | ReactElement[] | never[];
}
