import { createContext } from "react";
import {
  ProcessReducedDto,
  WorkflowReducedDto,
  WorkflowTarget,
  WorkflowTranslatedDataDto,
} from "../../../../robotcloud-shared/resource-models";

const OnBoardingContext = createContext<OnBoardingContextData>({
  process: undefined,
  currentSelectedItem: undefined,
  setCurrentSelectedItem: () => {},
  currentSelectedItemTranslations: [],
  setCurrentSelectedItemTranslations: () => {},
  refreshItems: () => {},
  manufacturerName: "",
  currentTarget: "user",
  setCurrentTarget: () => {},
});

export interface OnBoardingContextData {
  process?: ProcessReducedDto;
  currentSelectedItem?: WorkflowReducedDto;
  currentSelectedItemTranslations: WorkflowTranslatedDataDto[];
  setCurrentSelectedItem: (item: WorkflowReducedDto | undefined) => void;
  setCurrentSelectedItemTranslations: (
    items: WorkflowTranslatedDataDto[]
  ) => void;
  refreshItems: (target: WorkflowTarget) => void;
  manufacturerName: string;
  currentTarget: WorkflowTarget;
  setCurrentTarget: (target: WorkflowTarget) => void;
}

export default OnBoardingContext;
