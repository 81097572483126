import { useTranslation } from "react-i18next";
import {
  Banner,
  ToastHelper,
  TooltipButton,
  YesNoOkDialog,
} from "../../../../../components/news";
import { useState } from "react";
import SyncOutlinedIcon from "@mui/icons-material/SyncOutlined";
import { KemaroDashboardEconomyCostCalculationsRequests } from "../../../../../data/kemaro/kemaroDashboardEconomyCostCalculations.requests";
import { ErrorHelper } from "../../../../../uiHelpers/errors.helper";

export const RecalculateDashboardsButton = () => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);
  const [isWorking, setIsWorking] = useState(false);

  const changeShowModal = (newValue: boolean) => {
    setShowModal(newValue);
    setIsWorking(false);
  };

  const recalculateDashboard = async () => {
    setIsWorking(true);

    try {
      await KemaroDashboardEconomyCostCalculationsRequests.recalculateDashboards();
      changeShowModal(false);
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsWorking(false);
  };

  return (
    <>
      <TooltipButton
        aria-label={t("costCalculation.recalculateDashboard.tooltip")}
        tooltip={t("costCalculation.recalculateDashboard.tooltip")}
        onClick={() => setShowModal(true)}
      >
        <SyncOutlinedIcon
          className="earlybyte-pink-color"
          sx={{ color: "swarm.general.earlybytePinkColor" }}
        />
      </TooltipButton>

      {showModal && (
        <YesNoOkDialog
          open={true}
          size="md"
          type="yesNo"
          title={t("costCalculation.recalculateDashboard.tooltip")}
          isWorking={isWorking}
          onNo={() => changeShowModal(false)}
          onYes={() => recalculateDashboard()}
        >
          <>
            {!isWorking && (
              <Banner type="info">
                <p className="py-2">
                  {t("costCalculation.recalculateDashboard.body")}
                </p>
              </Banner>
            )}
          </>
        </YesNoOkDialog>
      )}
    </>
  );
};
