import {
  KemaroRobotDeploymentContainerDto,
  KemaroRobotDeploymentHistoryDto,
  KemaroRobotDeploymentPostDto,
  KemaroRobotCleaningDataTotalsDto,
} from "../../../../robotcloud-shared/resource-models";
import { AxiosRequests } from "../axios.requests";

const kemaroRobotDeploymentController =
  "api/webapp/v1.0/Kemaro/RobotDeployment";

export const kemaroRobotDeploymentGetFromKemaroRobot = async (
  kemaroRobotId: string
) => {
  const { data } = await AxiosRequests.get(
    `${kemaroRobotDeploymentController}/from-kemaro-robot/${kemaroRobotId}`
  );
  return data as KemaroRobotDeploymentContainerDto;
};

export const kemaroRobotDeploymentSave = async (
  postDto: KemaroRobotDeploymentPostDto
) => {
  const { data } = await AxiosRequests.post(
    kemaroRobotDeploymentController,
    postDto
  );
  return data as KemaroRobotDeploymentContainerDto;
};

export const kemaroRobotDeploymentGetHistory = async (
  kemaroRobotId: string
) => {
  const { data } = await AxiosRequests.get(
    `${kemaroRobotDeploymentController}/history/${kemaroRobotId}`
  );
  return data as KemaroRobotDeploymentHistoryDto[];
};

export const kemaroRobotTotals = async (kemaroRobotId: string) => {
  const { data } = await AxiosRequests.get(
    `${kemaroRobotDeploymentController}/robot-cleaning-data-totals/${kemaroRobotId}`
  );
  return data as KemaroRobotCleaningDataTotalsDto[];
};
