import { NativeSelect } from "@mui/material";
import {
  KemaroRobotCleaningDataTotalsDto,
  KemaroRobotCleaningDataTotalsDtoType,
} from "../../../../robotcloud-shared/resource-models";
import { useTranslation } from "react-i18next";

const RobotCleaningDataTotalsTypesSearch = ({
  value,
  onChange,
  robotCleaningDataTotals,
  selectClassName,
  loading,
  error,
}: RobotCleaningDataTotalsTypesSearchProps) => {
  const { t } = useTranslation();

  const robotTotalsTypes: KemaroRobotCleaningDataTotalsDtoType[] | undefined =
    robotCleaningDataTotals.map((x) => x.type);

  return (
    <>
      {robotCleaningDataTotals.length > 1 && (
        <>
          <div className="col-4 ps-0 pt-1">
            <span className="mb-0 title">
              {t("robot.cleaningInformation.title")}
            </span>
          </div>

          <div className="col-8 text-end">
            <strong>{t("robotCleaningDataTotals.title")}</strong>
            <NativeSelect
              disabled={loading || error}
              className={selectClassName ?? "ms-2 p-1"}
              value={value}
              onChange={(event) => {
                onChange(
                  (event.target.value ??
                    "Deployment") as KemaroRobotCleaningDataTotalsDtoType
                );
              }}
            >
              {robotTotalsTypes.map((x) => (
                <option key={x} value={x}>
                  {t(`robotCleaningDataTotals.${x}` as const)}
                </option>
              ))}
            </NativeSelect>
          </div>
        </>
      )}
    </>
  );
};

export default RobotCleaningDataTotalsTypesSearch;

interface RobotCleaningDataTotalsTypesSearchProps {
  value: KemaroRobotCleaningDataTotalsDtoType;
  onChange: (value: KemaroRobotCleaningDataTotalsDtoType) => void;
  robotCleaningDataTotals: KemaroRobotCleaningDataTotalsDto[];
  selectClassName?: string;
  loading: boolean;
  error: boolean;
}
