import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { Loading } from "../../components/Loading";
import { IconButton } from "@mui/material";
import { useContext } from "react";
import AuthorizationContext from "../auth/authorizationContext";
import useTheme from "@mui/material/styles/useTheme";

export const MainPanelMe = (props: mainPanelMeProps) => {
  const { me } = useContext(AuthorizationContext);

  const theme = useTheme();
  return (
    <div
      className="main-panel-me py-2"
      style={{
        borderBottom: theme.palette.swarm?.mainPanel?.meBorderBottom,
      }}
    >
      <table className="w-100 w-100">
        <tbody>
          <tr>
            <td>
              {me ? (
                <>
                  {props.isOpen ? (
                    <>
                      <p className="mb-0 user-name no-wrap">{me?.name}</p>
                      <p className="mb-0 tenant-name no-wrap">
                        {me?.tenantName}
                      </p>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Loading isLoading={true} size={20} />
              )}
            </td>
            <td className="text-end">
              <IconButton onClick={() => props.onIsOpenChange(!props.isOpen)}>
                {props.isOpen ? <ChevronLeft /> : <ChevronRight />}
              </IconButton>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export interface mainPanelMeProps {
  isOpen: boolean;
  onIsOpenChange: (isOpen: boolean) => void;
}
