import { useEffect, useRef } from "react";

const noop = (): void => {
  // do nothing.
};

/**
 * React hook to do something every interval.
 * @param callback
 * @param delay Delay in millisecondy, if `false`, the callback won't be called.
 * @param immediate Should the callback also be called immediately?
 */
export function useInterval(
  callback: () => void,
  delay: number | false,
  immediate?: boolean
): void {
  const savedCallback = useRef(noop);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  });

  // Execute callback if immediate is set.
  useEffect(() => {
    if (!immediate) return;
    if (delay === false) return;

    savedCallback.current();
  }, [delay, immediate]);

  // Set up the interval.
  useEffect(() => {
    if (delay === false) return undefined;

    const tick = (): void => savedCallback.current();

    const id = setInterval(tick, delay);

    return () => clearInterval(id);
  }, [delay]);
}
