import useTheme from "@mui/material/styles/useTheme";
import { useTranslation } from "react-i18next";
import { TableGeneric } from "../../../../../../components/news";
import { ColumnDefinition } from "./ColumnDefinition";
import { DashboardSpHistoryMessageLogsDto } from "../../../../../../../../robotcloud-shared/resource-models";

export const LeftSide = ({
  executionHistory,
  currentItem,
  setCurrentItem,
}: LeftSideProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <div className="d-flex left-side">
      <TableGeneric
        columns={ColumnDefinition(theme, [
          t("costCalculation.executionHistory.status"),
          t("costCalculation.executionHistory.start"),
          t("costCalculation.executionHistory.end"),
        ])}
        items={executionHistory ?? []}
        idProperty="id"
        selectionChanged={(item) => setCurrentItem(item)}
        selectId={currentItem?.id}
      />
    </div>
  );
};

interface LeftSideProps {
  executionHistory: DashboardSpHistoryMessageLogsDto[];
  currentItem: DashboardSpHistoryMessageLogsDto | undefined;
  setCurrentItem: (item: DashboardSpHistoryMessageLogsDto) => void;
}
