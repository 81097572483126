import { Button, Dialog } from "@mui/material";
import { useEffect, useState } from "react";
import { Loading } from "../../components/Loading";
import { TextInput } from "../../components/news/TextInput";
import { ToastHelper } from "../../components/news/Toast.helper";
import { UsersRequests } from "../../data/users.requests";
import { ErrorHelper } from "../../uiHelpers/errors.helper";
import { GeneralFunctions } from "../generalFunctions";
import { useTranslation } from "react-i18next";
import { tableGenericHandleKeyboardSubjectManager } from "../../components/news";
import { SupRemark } from "../../uiHelpers/SupRemark";

export const UserFromGraphModal = (props: userFromGraphModalProps) => {
  const { t } = useTranslation();

  const [userId, setUserId] = useState("");
  const [isWorking, setIsWorking] = useState(false);
  const [thereAreNew, setThereAreNew] = useState(false);

  useEffect(() => {
    tableGenericHandleKeyboardSubjectManager.setData({
      caller: "UserFromGraphModal",
      handleKeyboard: props.isOpenModal,
    });
  }, [props.isOpenModal]);

  const handleAddUser = async () => {
    setIsWorking(true);

    try {
      const user = await UsersRequests.postGraphToSql(userId);
      ToastHelper.success(
        t("user.addGraph.successMessage", {
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
        })
      );
      setUserId("");
      setThereAreNew(true);
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsWorking(false);
  };

  const closeHandle = () => {
    props.onClose(thereAreNew);
    setThereAreNew(false);
    setUserId("");
  };

  return (
    <Dialog
      className="application-modal"
      open={props.isOpenModal}
      maxWidth="md"
      onClose={closeHandle}
    >
      <div className="modal-content">
        <div>
          <div className="application-modal-header">
            <h3 className="modal-title">{t("user.addGraph.title")}</h3>
          </div>
          <div className="application-modal-body">
            {isWorking ? (
              <Loading isLoading={isWorking} />
            ) : (
              <div className="row">
                <div className="col-4 input-label">
                  {t("user.userId")}
                  <SupRemark />
                </div>
                <div className="col-8">
                  <TextInput value={userId} ret={setUserId} />
                </div>
              </div>
            )}
          </div>
          {isWorking ? (
            <div className="application-modal-footer"></div>
          ) : (
            <div className="application-modal-footer">
              <Button
                variant="contained"
                className="button-with-text"
                disabled={GeneralFunctions.isBlank(userId)}
                color="primary"
                onClick={handleAddUser}
              >
                {t("user.addGraph.buttonAddUser")}
              </Button>

              <Button
                className="modal-cancel-button button-with-text"
                variant="outlined"
                color="primary"
                onClick={closeHandle}
              >
                {t("common.buttons.close")}
              </Button>
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export interface userFromGraphModalProps {
  isOpenModal: boolean;
  onClose: (thereAreNew: boolean) => void;
}
