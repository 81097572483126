import { KemaroConfigurationDto } from "../../../../../../robotcloud-shared/resource-models";
import { BadgeItem } from "../../../../components/news";
import { kemaroRobotsFilter } from "../Models";
import i18n from "i18next";

export const filterToBadgeItems = (
  filter: kemaroRobotsFilter,
  kemaroConfiguration: KemaroConfigurationDto | undefined
) => {
  const result: BadgeItem[] = [];

  filter.serialNumbers.forEach((x) =>
    result.push({
      id: x,
      type: "serialNumber",
      text: x,
      style: "serial-number",
    })
  );

  filter.names.forEach((x) =>
    result.push({
      id: x,
      type: "name",
      text: x,
      style: "name",
    })
  );

  filter.companyStructures.forEach((x) =>
    result.push({
      id: x.id,
      type: "companyStructure",
      text: x.name,
      style: "company-structure",
    })
  );

  filter.robotModels.forEach((x) =>
    result.push({
      id: x.id,
      type: "robotModel",
      text: x.name,
      style: "model",
    })
  );

  filter.dealers.forEach((x) =>
    result.push({
      id: x.id,
      type: "dealer",
      text: x.name,
      style: "dealer",
    })
  );

  filter.customers.forEach((x) =>
    result.push({
      id: x.id,
      type: "customer",
      text: x.name,
      style: "customer",
    })
  );

  filter.customerContacts.forEach((x) =>
    result.push({
      id: x.id,
      type: "customerContact",
      text: `${x.firstName} ${x.sureName}`,
      style: "contact",
    })
  );

  filter.kemaroDashboardEconomyCostCalculations.forEach((x) =>
    result.push({
      id: x.id,
      type: "kemaroDashboardEconomyCostCalculations",
      text: x.name,
      style: "dashboard-calculation",
    })
  );

  if (filter.textToSearch) {
    result.push({
      id: "textToSearch",
      type: "textToSearch",
      text: filter.textToSearch,
      style: "text-search",
    });
  }

  if (filter.status === "OnLine")
    result.push({
      id: "status",
      type: "status",
      text: i18n.t("robot.filter.onLine.onLine")!,
      style: "text-search",
    });

  if (filter.status === "TimedOut")
    result.push({
      id: "status",
      type: "status",
      text: i18n.t("robot.filter.onLine.timedOut")!,
      style: "text-search",
    });

  if (filter.errorStatus === "Current") {
    const text =
      !kemaroConfiguration ||
      kemaroConfiguration.robots.errorSummary.recentDaysToAnalyze === 0
        ? i18n.t("robot.filter.errorStatus.today")
        : i18n.t("robot.filter.errorStatus.days", {
            daysNumber:
              kemaroConfiguration.robots.errorSummary.recentDaysToAnalyze,
          });

    result.push({
      id: "errorStatus",
      type: "errorStatus",
      text: text,
      style: "text-search",
    });
  }

  if (filter.errorStatus === "Old")
    result.push({
      id: "errorStatus",
      type: "errorStatus",
      text: i18n.t("robot.filter.errorStatus.days", {
        daysNumber:
          kemaroConfiguration?.robots.errorSummary.totalDaysToAnalyze ?? 0,
      })!,
      style: "text-search",
    });

  if (filter.errorStatus === "None")
    result.push({
      id: "errorStatus",
      type: "errorStatus",
      text: i18n.t("robot.filter.errorStatus.none")!,
      style: "text-search",
    });

  if (filter.deploymentStatus === "Active")
    result.push({
      id: "deploymentStatus",
      type: "deploymentStatus",
      text: i18n.t("robot.filter.deploymentStatus.activeBadge")!,
      style: "text-search",
    });

  if (filter.deploymentStatus === "Inactive")
    result.push({
      id: "deploymentStatus",
      type: "deploymentStatus",
      text: i18n.t("robot.filter.deploymentStatus.inactiveBadge")!,
      style: "text-search",
    });

  filter.tags.forEach((x) =>
    result.push({
      id: x,
      type: "tag",
      text: x,
      style: "tag",
    })
  );

  return result;
};
