import { CompanyStructureReducedDto } from "../../../../../../robotcloud-shared/resource-models";
import { ToastHelper } from "../../../../components/news";
import { CompanyStructureRequests } from "../../../../data/companyStructure.request";
import { ErrorHelper } from "../../../../uiHelpers/errors.helper";

export const getCompanyStructures = async (
  idCompanyStructureBranch: string | null
) => {
  let result: CompanyStructureReducedDto[] = [];
  if (!idCompanyStructureBranch) return result;

  try {
    const companyStructures = await CompanyStructureRequests.getBranch(
      idCompanyStructureBranch
    );
    result = companyStructures;
  } catch (error) {
    ToastHelper.errors(ErrorHelper.process(error));
  }

  return result;
};
