import { useContext, useEffect, useState } from "react";
import { ViewHeader, YesNoOkDialog } from "../../components/news";
import { CustomFieldContext } from "./CustomFieldContext";
import { PermissionsHelper } from "../../uiHelpers/permissionsHelper";
import { defaultModalState } from "../../uiHelpers/dtos/modalState";
import { useTranslation } from "react-i18next";
import { CustomFieldModal } from "./CustomFieldModal";

export const CustomFieldViewTitle = () => {
  const { t } = useTranslation();

  const { currentItem, currentType, deleteCurrentItem } =
    useContext(CustomFieldContext);

  const [canModify, setCanModify] = useState(true);
  const [canDelete, setCanDelete] = useState(true);
  const [isEditModalState, setIsEditModalState] = useState(false);
  const [deleteModalState, setDeleteModalState] = useState({
    ...defaultModalState,
  });

  useEffect(() => {
    setCanModify(PermissionsHelper.allow("CustomFields", "Edit"));
    setCanDelete(PermissionsHelper.allow("CustomFields", "Delete"));
  }, []);

  if (!currentItem) return null;

  const handleDelete = async () => {
    setDeleteModalState({ ...defaultModalState, isWorking: true });

    if (await deleteCurrentItem())
      setDeleteModalState({ ...defaultModalState });
    else setDeleteModalState({ ...defaultModalState, isWorking: false });
  };

  return (
    <ViewHeader
      title={currentItem.title}
      copyLinkId={currentItem.id}
      canEdit={canModify}
      canDelete={canDelete}
      onEdit={() => setIsEditModalState(true)}
      onDelete={() =>
        setDeleteModalState({ ...deleteModalState, isOpen: true })
      }
    >
      <>
        {isEditModalState && (
          <CustomFieldModal
            type={currentType!}
            title={t("customFields.edit.title")}
            onCancel={() => setIsEditModalState(false)}
            dto={currentItem}
          />
        )}

        <YesNoOkDialog
          open={deleteModalState.isOpen}
          size="md"
          type="yesNo"
          title={t("customFields.delete.title")}
          message={`${t("customFields.delete.warning")} ${currentItem.title}?`}
          onYes={handleDelete}
          onNo={() => setDeleteModalState({ ...defaultModalState })}
          isWorking={deleteModalState.isWorking}
        />
      </>
    </ViewHeader>
  );
};
