import { useEffect, useState } from "react";
import { ToastHelper } from "../components/news";
import { KemaroCompanyStructureRequests } from "../data/kemaro/KemaroCompanyStructure.request";
import { ErrorHelper } from "../uiHelpers/errors.helper";
import { CustomerReducedDto, KemaroCostCalculationLocationDto } from "../../../robotcloud-shared/resource-models";

export const useKemaroCustomers = ( idCostCalculation: string) => {
    const [isFetchingCustomers, setIsFetchingCustomers] = useState(false);
    const [allCustomers, setAllCustomers] = useState(false);
    const [customers, setCustomers] = useState<CustomerReducedDto[]>([]);
    const [customerSelected, setCustomerSelected] = useState<
      CustomerReducedDto | undefined
    >(undefined);

    const [isFetchingLocations, setIsFetchingLocations] = useState(false);
    const [locations, setLocations] = useState<
      KemaroCostCalculationLocationDto[]
    >([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
      const getCustomers = async () => {
        setIsFetchingCustomers(true);

        try {
          setCustomers(
            await KemaroCompanyStructureRequests.getFromCostCalculationCustomers(
              idCostCalculation,
              allCustomers
            )
          );
        } catch (error) {
          ToastHelper.errors(ErrorHelper.process(error));
        }

        setIsFetchingCustomers(false);
      };

      getCustomers();
    }, [idCostCalculation, allCustomers]);

    useEffect(() => {
      if (!customerSelected) {
        setLocations([]);
        return;
      }

      getNodes(idCostCalculation, customerSelected!.id);
    }, [idCostCalculation, customerSelected]);

    const getNodes = async (costCalculationId: string, customerId: string) => {
      setIsFetchingLocations(true);

      try {
        const tmp = await KemaroCompanyStructureRequests.getFromCostCalculation(
          costCalculationId,
          customerId
        );

        setLocations([tmp!]);
      } catch (error) {
        ToastHelper.errors(ErrorHelper.process(error));
      }

      setIsFetchingLocations(false);
    };

    const handleSave = async () => {
      setIsModalOpen(false);
      getNodes(idCostCalculation, customerSelected!.id);
    };
    return {
      isFetchingCustomers,
      allCustomers,
      setAllCustomers,
      customers,
      customerSelected,
      setCustomerSelected,
      isFetchingLocations,
      locations,
      isModalOpen,
      setIsModalOpen,
      handleSave,
    };
}
