import { Autocomplete, Button, Dialog, Radio, TextField } from "@mui/material";
import { useState, useEffect, useContext } from "react";
import {
  KemaroCompanyStructureDto,
  KemaroCompanyStructurePostDto,
} from "../../../../../robotcloud-shared/resource-models";
import { Loading } from "../../../components/Loading";
import { TextInput } from "../../../components/news/TextInput";
import { CustomerContactSearch } from "../../../components/search/CustomerContactSearch";
import { KemaroDashboardEconomyCostCalculationSearch } from "../../../components/search/kemaro/KemaroDashboardEconomyCostCalculationSearch";
import { updateCustomerContactsSubscriptionManager } from "../../customers/subscriptionManager";
import { GeneralFunctions } from "../../generalFunctions";
import { updateTreeSubscriptionManager } from "./subscriptionManager";
import { useTranslation } from "react-i18next";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import StarRateIcon from "@mui/icons-material/StarRate";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import AuthorizationContext from "../../auth/authorizationContext";
import { PermissionsHelper } from "../../../uiHelpers/permissionsHelper";
import { tableGenericHandleKeyboardSubjectManager } from "../../../components/news";
import useTheme from "@mui/material/styles/useTheme";
import { SupRemark } from "../../../uiHelpers/SupRemark";

export const CompanyStructureKemaroModal = (
  props: CompanyStructureKemaroModalProps
) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [currentDto, setCurrentDto] = useState<KemaroCompanyStructurePostDto>(
    KemaroCompanyStructurePostDtoDefault
  );
  const [nodesList, setNodesList] = useState<KemaroCompanyStructureDto[]>([]);
  const [nodeCurrent, setNodeCurrent] = useState<
    KemaroCompanyStructureDto | undefined
  >(undefined);
  const [isRoot, setIsRoot] = useState(false);
  const [allowMissionMigration, setAllowMissionMigration] = useState(false);
  const [allowUpdateMissions, setAllowUpdateMissions] = useState(false);
  const [originalUpdateMissionsKey, setOriginalUpdateMissionsKey] =
    useState("-");
  const [migrateOption, setMigrateOption] = useState("n");
  const [migrateNewerFromMissions, setMigrateNewerFromMissions] = useState<
    Date | undefined
  >(new Date());
  const [allowOnlyCustomerContact, setAllowOnlyCustomerContact] =
    useState(true);
  const { me } = useContext(AuthorizationContext);

  useEffect(() => {
    setAllowMissionMigration(
      PermissionsHelper.allow(
        "Customers",
        "Edit",
        "CompanyStructure.MigrateMissions"
      )
    );

    setAllowOnlyCustomerContact(
      PermissionsHelper.allow(
        "Customers",
        "Edit",
        "CompanyStructure.CustomerContact"
      )
    );
  }, [me]);

  useEffect(() => {
    setNodesList(props.nodeList as KemaroCompanyStructureDto[]);
  }, [props.nodeList]);

  useEffect(() => {
    if (props.kemaroCompanyStructure === undefined) {
      setCurrentDto({ ...KemaroCompanyStructurePostDtoDefault });
      setIsRoot(false);
    } else {
      setCurrentDto(
        KemaroCompanyStructureDtoToPostDto(props.kemaroCompanyStructure)
      );
      setIsRoot(props.kemaroCompanyStructure.isRoot);
      setOriginalUpdateMissionsKey(
        getAllowUpdateMissionsKey(props.kemaroCompanyStructure)
      );
    }

    setNodeCurrent(
      props.nodeList.find(
        (x) => x.id === props.kemaroCompanyStructure?.idParentNode
      )
    );
  }, [props.kemaroCompanyStructure, props.nodeList]);

  useEffect(() => {
    if (props.kemaroCompanyStructure === undefined) {
      setAllowUpdateMissions(false);
      return;
    }

    setAllowUpdateMissions(
      originalUpdateMissionsKey !== getAllowUpdateMissionsKey(currentDto)
    );
  }, [props.kemaroCompanyStructure, currentDto, originalUpdateMissionsKey]);

  useEffect(() => {
    tableGenericHandleKeyboardSubjectManager.setData({
      caller: "CompanyStructureKemaroModal",
      handleKeyboard: props.modalOpen,
    });
  }, [props.modalOpen]);

  const handleCancel = () => {
    if (props.kemaroCompanyStructure === undefined) {
      setCurrentDto(KemaroCompanyStructurePostDtoDefault);
    } else {
      setCurrentDto(
        KemaroCompanyStructureDtoToPostDto(props.kemaroCompanyStructure)
      );
    }
    setNodeCurrent(
      props.nodeList.find(
        (x) => x.id === props.kemaroCompanyStructure?.idParentNode
      )
    );

    props.onCancel();
  };

  const handleNew = async () => {
    if (props.onCreate === null) {
      return true;
    }

    const dto: KemaroCompanyStructurePostDto = {
      ...currentDto,
      idCompany: props.idCompany,
    };

    const result = await props.onCreate!(dto);
    if (result) {
      updateCustomerContactsSubscriptionManager.setData(true);
      handleCancel();
    }
  };

  const handleModify = async () => {
    if (!props.onModify) {
      return true;
    }

    const originDto = KemaroCompanyStructureDtoToPostDto(
      props.kemaroCompanyStructure!
    );

    const modifiedDto: KemaroCompanyStructurePostDto = {
      ...currentDto,
      idCompany: props.idCompany,
    };

    const result = await props.onModify(
      originDto,
      modifiedDto,
      allowMissionMigration && allowUpdateMissions && migrateOption === "a",
      allowMissionMigration && allowUpdateMissions && migrateOption === "n",
      migrateNewerFromMissions
    );

    if (result) {
      updateTreeSubscriptionManager.setData(true);
      updateCustomerContactsSubscriptionManager.setData(true);
      handleCancel();
    }
  };

  const parentNodeChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: string | KemaroCompanyStructureDto | null
  ) => {
    const node = nodesList.find(
      (x) =>
        x.name.toLowerCase().trim() ===
        (newValue as KemaroCompanyStructureDto)?.name.toLowerCase().trim()
    );
    setCurrentDto({ ...currentDto, idParentNode: node?.id ?? null });
    setNodeCurrent(node);
  };

  const nodeSelect = (node: KemaroCompanyStructureDto | null) => {
    setCurrentDto({ ...currentDto, idParentNode: node?.id ?? null });
    setNodeCurrent(node ?? undefined);
  };

  return (
    <Dialog
      className="application-modal"
      open={props.modalOpen}
      maxWidth="md"
      onClose={handleCancel}
    >
      <div className="modal-content">
        <div className="application-modal-header">
          <h3>{t("company.locationTitle")}</h3>
        </div>
        {props.modalWorking && (
          <div className="application-modal-body py-5">
            <Loading isLoading={props.modalWorking} />
          </div>
        )}
        {!props.modalWorking && (
          <>
            <div className="application-modal-body">
              <div className="row">
                <div className="input-label col-4">
                  {t("common.name")}
                  <SupRemark />
                </div>
                <div className="col-8">
                  <TextInput
                    disabled={isRoot || allowOnlyCustomerContact}
                    value={currentDto.name}
                    ret={(x) => setCurrentDto({ ...currentDto, name: x })}
                  />
                </div>
                <div className="input-label col-4">
                  {t("company.locationDescription")}
                </div>
                <div className="col-8">
                  <TextInput
                    disabled={allowOnlyCustomerContact}
                    value={currentDto.description}
                    ret={(x) =>
                      setCurrentDto({ ...currentDto, description: x ?? "" })
                    }
                  />
                </div>
                <div className="input-label col-4">
                  {t("company.parentLocation")}
                </div>
                <div className="col-8">
                  <Autocomplete
                    size="small"
                    freeSolo
                    disabled={isRoot || allowOnlyCustomerContact}
                    options={nodesList}
                    getOptionLabel={generateOption}
                    renderOption={(props, x) => (
                      <div
                        key={x.id}
                        className={
                          "w-100 autocomplete-template py-2 px-3 " +
                          (currentDto?.idParentNode === x.id
                            ? "autocomplete-template-select"
                            : "")
                        }
                        onClick={() => nodeSelect(x)}
                      >
                        <span
                          style={{ paddingLeft: `${x.nestingLevel * 20}px` }}
                        >
                          {x.name}
                        </span>
                      </div>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label=""
                        placeholder={t("common.typeToSearch")}
                        fullWidth
                        variant="standard"
                        margin="dense"
                        hiddenLabel
                      />
                    )}
                    value={nodeCurrent ?? defautlKemaroCompanyStructureDto}
                    onChange={parentNodeChange}
                  />
                </div>

                <div className="input-label col-4">
                  {t("company.contactInformation")}
                </div>
                <div className="col-8">
                  <CustomerContactSearch
                    customerId={props.idCompany}
                    currentId={currentDto.idCustomerContact ?? undefined}
                    selectionChange={(x) =>
                      setCurrentDto({
                        ...currentDto,
                        idCustomerContact: x?.id ?? "",
                      })
                    }
                  />
                  <p>
                    <span
                      className="modal-data-inherited"
                      style={{
                        color:
                          theme.palette.swarm?.companyStructureView
                            ?.modalDataInherited,
                      }}
                    >
                      {props.kemaroCompanyStructure?.customerContactInherited}{" "}
                    </span>
                    {props.kemaroCompanyStructure
                      ?.customerContactInheritedNode && (
                      <span
                        className="modal-data-inherited ms-2"
                        style={{
                          color:
                            theme.palette.swarm?.companyStructureView
                              ?.modalDataInherited,
                        }}
                      >
                        [
                        <PushPinOutlinedIcon className="original-node-icon-pin" />
                        {
                          props.kemaroCompanyStructure
                            ?.customerContactInheritedNode
                        }
                        ]
                      </span>
                    )}
                  </p>
                </div>

                <div className="input-label col-4">
                  {t("company.costCalculation")}
                </div>

                <div className="col-8">
                  <KemaroDashboardEconomyCostCalculationSearch
                    customerId={props.idCompany}
                    contactPersonId={currentDto.idCustomerContact ?? undefined}
                    currentId={
                      currentDto.idKemaroDashboardEconomyCostCalculation ??
                      undefined
                    }
                    selectionChange={(x) =>
                      setCurrentDto({
                        ...currentDto,
                        idKemaroDashboardEconomyCostCalculation: x?.id ?? "",
                      })
                    }
                    disabled={allowOnlyCustomerContact}
                  />
                  <p>
                    <span className="modal-data-inherited">
                      {
                        props.kemaroCompanyStructure
                          ?.kemaroDashboardEconomyCostCalculationInherited
                      }
                    </span>
                    {props.kemaroCompanyStructure
                      ?.kemaroDashboardEconomyCostCalculationInheritedNode && (
                      <span
                        className="modal-data-inherited ms-2"
                        style={{
                          color:
                            theme.palette.swarm?.companyStructureView
                              ?.modalDataInherited,
                        }}
                      >
                        [
                        {props.kemaroCompanyStructure
                          ?.kemaroDashboardEconomyCostCalculationDefault ? (
                          <StarRateIcon className="original-node-icon-start" />
                        ) : (
                          <PushPinOutlinedIcon className="original-node-icon-pin" />
                        )}
                        {
                          props.kemaroCompanyStructure
                            ?.kemaroDashboardEconomyCostCalculationInheritedNode
                        }
                        ]
                      </span>
                    )}
                  </p>
                </div>

                {allowMissionMigration && allowUpdateMissions && (
                  <>
                    <div className="col-12 my-3">
                      <table
                        className="table table-borderless mb-0 banner-yellow py-3"
                        style={{
                          backgroundColor:
                            theme.palette.swarm?.general
                              ?.bannerYellowBackground,
                          borderLeftColor:
                            theme.palette.swarm?.general
                              ?.bannerYellowBorderLeft,
                        }}
                      >
                        <tbody>
                          <tr>
                            <td className="align-middle ps-4">
                              <ReportProblemOutlinedIcon className="warning-icon" />
                            </td>
                            <td className="w-100 py-4 px-4">
                              {t("company.change.explanation")}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col-12">
                      <table className="table table-borderless">
                        <tbody>
                          <tr>
                            <td colSpan={2}>
                              <p>{t("robot.change.howProceed")}</p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Radio
                                checked={migrateOption === "a"}
                                onChange={() => setMigrateOption("a")}
                                value="a"
                                name="radio-buttons"
                                inputProps={{ "aria-label": "A" }}
                              />
                            </td>
                            <td className="align-middle w-100">
                              {t("robot.change.migrateAll")}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Radio
                                checked={migrateOption === "n"}
                                onChange={() => setMigrateOption("n")}
                                value="n"
                                name="radio-buttons"
                                inputProps={{ "aria-label": "N" }}
                              />
                            </td>
                            <td className="align-middle pt-1">
                              <table className="table mb-0 table-borderless">
                                <tbody>
                                  <tr>
                                    <td className="align-middle no-wrap ps-0">
                                      {t("robot.change.migrateNewer")}
                                    </td>
                                    <td style={{ width: "10em" }}>
                                      <TextField
                                        className="pt-0 my-0"
                                        type="date"
                                        label=""
                                        fullWidth
                                        variant="standard"
                                        margin="dense"
                                        hiddenLabel
                                        style={{ width: "10em" }}
                                        value={GeneralFunctions.dateToAnsiiDate(
                                          migrateNewerFromMissions
                                        )}
                                        onChange={(event) =>
                                          setMigrateNewerFromMissions(
                                            event.target.value
                                              ? new Date(event.target.value)
                                              : undefined
                                          )
                                        }
                                      />
                                    </td>
                                    <td className="w-100"></td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="application-modal-footer">
              <Button
                variant="contained"
                className="button-with-text me-3"
                disabled={GeneralFunctions.isBlank(currentDto.name)}
                color="primary"
                onClick={() =>
                  props.kemaroCompanyStructure === undefined
                    ? handleNew()
                    : handleModify()
                }
              >
                {t("common.buttons.save")}
              </Button>

              <Button
                className="modal-cancel-buttonv button-with-text"
                variant="outlined"
                color="primary"
                onClick={handleCancel}
              >
                {t("common.buttons.cancel")}
              </Button>
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
};

interface CompanyStructureKemaroModalProps {
  modalOpen: boolean;
  modalWorking: boolean;
  idCompany: string;
  kemaroCompanyStructure: KemaroCompanyStructureDto | undefined;
  nodeList: KemaroCompanyStructureDto[];
  onCancel: () => void;
  onCreate?: (dto: KemaroCompanyStructurePostDto) => Promise<boolean>;
  onModify?: (
    origin: KemaroCompanyStructurePostDto,
    modified: KemaroCompanyStructurePostDto,
    migrateAllMissions: boolean,
    migrateNewerMissions: boolean,
    migrateNewerFromMissions: Date | undefined
  ) => Promise<boolean>;
}

const KemaroCompanyStructurePostDtoDefault: KemaroCompanyStructurePostDto = {
  idCompany: "",
  name: "",
  description: "",
  idParentNode: null,
  idCustomerContact: null,
  idKemaroDashboardEconomyCostCalculation: null,
};

const KemaroCompanyStructureDtoToPostDto = (dto: KemaroCompanyStructureDto) => {
  const result: KemaroCompanyStructurePostDto = {
    idCompany: dto.idCustomer,
    name: dto.name,
    description: dto.description,
    idParentNode: dto.idParentNode,
    idCustomerContact: dto.idCustomerContact,
    idKemaroDashboardEconomyCostCalculation:
      dto.idKemaroDashboardEconomyCostCalculation,
  };

  return result;
};

const generateOption = (
  item: KemaroCompanyStructureDto | undefined | string
) => {
  if (typeof item === "string") {
    return item;
  }

  return item && item.id ? item.name : "";
};

const getAllowUpdateMissionsKey = (
  kemaroCompanyStructure: allowUpdateMissionsKey
) =>
  `${kemaroCompanyStructure.idParentNode}|${kemaroCompanyStructure.idKemaroDashboardEconomyCostCalculation}`;

const defautlKemaroCompanyStructureDto: KemaroCompanyStructureDto = {
  id: "",
  idCustomer: "",
  name: "",
  description: "",
  idParentNode: null,
  childNodes: [],
  idCustomerContact: null,
  idKemaroDashboardEconomyCostCalculation: null,
  customerContact: "",
  kemaroDashboardEconomyCostCalculation: "",
  idCustomerContactInherited: null,
  idKemaroDashboardEconomyCostCalculationInherited: null,
  customerContactInherited: "",
  kemaroDashboardEconomyCostCalculationInherited: "",
  customerContactInheritedNode: "",
  kemaroDashboardEconomyCostCalculationInheritedNode: "",
  robotsInNode: 0,
  robotsInBranch: 0,
  hasInformation: "No",
  nestingLevel: 0,
  hasDependentData: false,
  isRoot: false,
  kemaroDashboardEconomyCostCalculationDefault: false,
  address: null,
};

interface allowUpdateMissionsKey {
  idParentNode: string | null;
  idKemaroDashboardEconomyCostCalculation: string | null;
}
