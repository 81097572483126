import useTheme from "@mui/material/styles/useTheme";
import { KemaroMissionCompleteDto } from "../../../../../robotcloud-shared/resource-models";
import { NavigateTo } from "../../../components/news/NavigateTo";
import { KemaroMissionViewMap } from "./KemaroMissionViewMap";
import { KemaroMissionViewSummaryInfo } from "./KemaroMissionViewSummaryInfo";
import { useTranslation } from "react-i18next";

export const KemaroMissionViewSummary = (
  props: kemaroMissionViewSummaryProps
) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <div
      className="summary-container mt-4 "
      style={{
        backgroundColor:
          theme.palette.swarm?.kemaroMissions?.viewSummaryBackground,
      }}
    >
      {!props.item.customer && !props.item.dealer ? (
        <></>
      ) : (
        <div
          className="w-100 customer-dealer-container mb-3 pb-3"
          style={{
            borderBottom:
              theme.palette.swarm?.kemaroMissions
                ?.viewCustomerDealerBorderBottom,
          }}
        >
          <table>
            <tbody>
              {props.item.customer && (
                <tr>
                  <td className="pe-3 pb-3">
                    {t("mission.viewSummary.customer")}
                  </td>
                  <td className="pb-3">
                    <NavigateTo
                      text={props.item.customer.name}
                      shorCut="customers"
                      className="pt-2"
                      toolTipIsSingular={true}
                      isAvailable={props.item.customer.id.length > 0}
                      paramters={[
                        { name: "id", value: props.item.customer.id },
                      ]}
                    />
                  </td>
                </tr>
              )}
              {props.item.dealer && (
                <tr>
                  <td className="pe-3">{t("mission.viewSummary.dealer")}</td>
                  <td>
                    <NavigateTo
                      text={props.item.dealer.name}
                      toolTipIsSingular={true}
                      className="pt-2"
                      shorCut="dealers"
                      isAvailable={props.item.dealer.id.length > 0}
                      paramters={[{ name: "id", value: props.item.dealer.id }]}
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
      <div className="d-flex">
        <div className="flex-grow-1">
          <p className="title mb-2">{t("mission.viewSummary.summary")}</p>

          <div className="w-100 ps-3">
            <KemaroMissionViewSummaryInfo item={props.item} />
          </div>
        </div>

        <div className="ms-3">
          <KemaroMissionViewMap map={props.item.map} />
        </div>
      </div>
    </div>
  );
};

interface kemaroMissionViewSummaryProps {
  item: KemaroMissionCompleteDto;
}
