import { diff, jsonPatchPathConverter } from "just-diff";
import {
  CustomerCompleteDto,
  CustomerDto,
  CustomerFilterCriteriaDto,
  CustomerPostDto,
  PaginatedListDtoOfCustomerDto,
  SearchTextDtoOfGuid,
  SearchTextResultDtoOfCustomerDto,
} from "../../../robotcloud-shared/resource-models";
import { AxiosRequests } from "./axios.requests";
import { BaseRequest } from "./BaseRequest";
import { prepareSearchTextDto } from "./functions";

export class CustomersRequests extends BaseRequest {
  static controller = "/api/webapp/v1.0/organizations/customers";

  static getList = async (filterCriteria: CustomerFilterCriteriaDto) => {
    const { data } = await AxiosRequests.get(
      `${CustomersRequests.controller}?${CustomersRequests.objectToQueryString(
        filterCriteria
      )}`
    );
    return data as PaginatedListDtoOfCustomerDto;
  };

  static getByIdComplete = async (id: string) => {
    const { data } = await AxiosRequests.get(
      `${CustomersRequests.controller}/${id}/complete`
    );
    return data as CustomerCompleteDto;
  };

  static getById = async (id: string) => {
    const { data } = await AxiosRequests.get(
      `${CustomersRequests.controller}/${id}`
    );
    return data as CustomerDto;
  };

  static modify = async (
    originalDto: CustomerCompleteDto,
    newDto: CustomerCompleteDto
  ) => {
    const allowProperties = ["/name"];

    const jsonPatch = diff(originalDto, newDto, jsonPatchPathConverter).filter(
      (x) => allowProperties.includes(x.path)
    );

    const { data } = await AxiosRequests.patch(
      `${CustomersRequests.controller}/${originalDto.id}`,
      jsonPatch
    );
    return data as CustomerCompleteDto;
  };

  static create = async (postDto: CustomerPostDto) => {
    const { data } = await AxiosRequests.post(
      CustomersRequests.controller,
      postDto
    );
    return data as CustomerDto;
  };

  static dealersAdd = async (id: string, dealerIds: string[]) => {
    const jsonPatch = [
      {
        path: "/idsDealersAdd",
        op: "add",
        value: dealerIds,
      },
    ];

    const { data } = await AxiosRequests.patch(
      `${CustomersRequests.controller}/${id}`,
      jsonPatch
    );
    return data as CustomerCompleteDto;
  };

  static dealersRemove = async (id: string, dealerIds: string[]) => {
    const jsonPatch = [
      {
        path: "/idsDealersRemove",
        op: "add",
        value: dealerIds,
      },
    ];

    const { data } = await AxiosRequests.patch(
      `${CustomersRequests.controller}/${id}`,
      jsonPatch
    );
    return data as CustomerCompleteDto;
  };

  static getSearchText = async (searchTextDto: SearchTextDtoOfGuid) => {
    const { data } = await AxiosRequests.post(
      `${CustomersRequests.controller}/search-text`,
      prepareSearchTextDto(searchTextDto)
    );
    return data as SearchTextResultDtoOfCustomerDto;
  };

  static softDelete = async (id: string) => {
    await AxiosRequests.delete(`${CustomersRequests.controller}/${id}/soft`);
  };
}
